<template>
  <div class="inline-confirmation">
    <div class="size-text-14px semibold-weight text-center bottom-15">
      <slot></slot>
    </div>

    <div class="d-flex flex-center col-gap-20 buttons-container">
      <div v-for="button in buttons" :key="button.label">
        <button type="button" :class="['fit-content', button.class]" @click="select(button.value)">
          {{ button.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InlineConfirmation',

  props: {
    buttons: {
      // An array of objects, each with label, value, and class properties.
      type: Array,
      required: true
    }
  },

  methods: {
    select(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .inline-confirmation {
    padding: 20px 30px 20px 26px;
    border: 1px solid $medium-gray;
    border-left: 4px solid $k-blue;
    border-radius: $border-radius-large;
    background-color: $bg-color;
  }

  .buttons-container {
    button {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
</style>
