<template>
  <div :class="['status-pill', { 'closed': closed }]">
    <slot>
      {{ closed ? ' Closed' : 'Open' }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'StatusPill',

  props: {
    closed: {
      type: Boolean,
      required: true
    }
  }
};
</script>
