<template>
  <priori-modal
    ref="modal"
    modal-id="activate-user"
    :backdrop="false"
    @on-modal-close="onCloseModal"
    title="Reactivate User">
    <template #default>
      <div class="bottom-20">
        Reactivating this user will give them access to the Scout platform.
      </div>

      <div class="bottom-30">
        <strong>Are you sure you would like to activate this user?</strong>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <loading-button name="submit" lb-class="primary-btn-blue" @lb-click="save()">Yes, reactivate</loading-button>
        </div>

        <div class="col-sm-4 top-20-xs">
          <button type="button" class="secondary-btn-blue" @click="closeModal">No, Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'ReactivateClient',

  components: {
    LoadingButton,
    PrioriModal
  },

  mixins: [
    interactionModes
  ],

  props: {
    onSave: {
      type: Function,
      default: () => {}
    },

    onCloseModal: {
      type: Function,
      default: () => {}
    },

    selectedClientId: {
      type: Number,
      default: null
    },

    showModal: {
      type: Boolean,
      default: null
    }
  },

  watch: {
    showModal(showModal) {
      if (showModal) {
        this.openModal();
      }
    }
  },

  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },

    save() {
      LoadingService.loading('submit');

      this.onSave({ id: this.selectedClientId, active: true }).then(() => {
        this.closeModal();
      }).finally(() => {
        LoadingService.done('submit');
      });
    },

    closeModal() {
      this.$refs.modal.dismissModal();
      this.onCloseModal();
    }
  }
};
</script>
