<template>
  <button type="button" class="round-button" @click="toggleFunc">
    <svg-icon v-if="isFavorite" name="star-filled" class="base-icon"></svg-icon>
    <svg-icon v-else name="star" class="base-icon"></svg-icon>
  </button>
</template>

<script>
export default {
  props: {
    toggleFunc: {
      type: Function,
      required: true
    },

    isFavorite: {
      type: Boolean,
      required: true
    }
  }
};
</script>
