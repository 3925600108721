<template>
  <div class="titlebar lawyer-titlebar">
    <div class="bottom-20">
      <div class="row tight-columns vertical-center-not-xs">
        <div class="col-sm-10">
          <div class="inline-block right-20-not-xs">
            <svg-icon name="law-firm" class="firm-icon large"></svg-icon>
          </div>

          <div class="text-img-adjacent">
            <div :class="['medium-page-heading', 'text-ellipsis', {'text-italic': firm.isBillingFirm()}]">
              {{ firm.name }}
            </div>

            <div class="size-text-16px text-ellipsis">
              {{ firm.firmSize }}
            </div>

            <div class="top-10" v-if="firm.scoutTags.length">
              <scout-tags-list-with-modal
                :scout-tags="firm.scoutTags"
                :firm-name="firm.name">
              </scout-tags-list-with-modal>
            </div>
          </div>
        </div>

        <div class="col-sm-2 top-30-xs d-flex flex-end-not-xs">
          <div class="text-center">
            <add-to-list-button
              :resource-lists="resourceLists"
              :is-lawyer="false"
              :on-submit="addToList">
            </add-to-list-button>
          </div>

          <div class="text-center left-15">
            <div class="center-block bottom-5">
              <favorite-button
                :toggle-func="toggleFavorite"
                :is-favorite="isFavorite">
              </favorite-button>
            </div>
            Favorite
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-40">
      <hr class="no-margin">
    </div>

    <div class="titlebar-switcher">
      <a href="" :class="{ 'selected': isTabSelected('overview') }" @click.prevent="selectTab('overview')">Practice Overview</a>

      <a href="" :class="{ 'selected': isTabSelected('matters') }" @click.prevent="selectTab('matters')" v-if="!isFeatureLab">Relationship History</a>

      <a href="" :class="{ 'selected': isTabSelected('reviews') }" @click.prevent="selectTab('reviews')">Reviews</a>

      <a href="" :class="{ 'selected': isTabSelected('lawyers') }" @click.prevent="selectTab('lawyers')" v-if="!isFeatureLab">Lawyers</a>

      <a href="" :class="{ 'selected': isTabSelected('notes') }" @click.prevent="selectTab('notes')">Notes & Documents</a>
    </div>
  </div>
</template>

<script>
import AddToListButton from 'vue-app/scout/shared/lists/add-to-list-button.vue';
import FavoriteButton from 'vue-app/scout/shared/favorite-button.vue';
import FavoriteFirm from 'resources/scout/favorite-firm.js';
import ScoutTagsListWithModal from 'vue-app/scout/shared/scout-tags/scout-tags-list-with-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FirmTitlebar',

  components: {
    AddToListButton,
    FavoriteButton,
    ScoutTagsListWithModal,
    SvgIcon
  },

  props: {
    addToList: {
      type: Function,
      required: true
    },

    firm: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    },

    selectTab: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser', currentProduct: 'currentProduct' }),

    isFavorite() {
      return this.currentClient.favoriteFirms.includes(this.firm.id);
    },

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    }
  },

  methods: {
    toggleFavorite() {
      if (this.isFavorite) {
        return FavoriteFirm.delete({ firm_bridge_id: this.firm.id }).then(() => {
          this.currentClient.favoriteFirms = this.currentClient.favoriteFirms.filter(id => id !== this.firm.id);
        });
      }
      else {
        FavoriteFirm.save({ firm_bridge_id: this.firm.id }).then(() => {
          this.currentClient.favoriteFirms.push(this.firm.id);
        });
      }
    },

    isTabSelected(tab) {
      return this.selectedTab === tab;
    }
  }
};
</script>
