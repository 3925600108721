import axios from 'axios';
import BaseResource from 'resources/base-resource';

class DataLock extends BaseResource {
  static baseUrl = '/data_locks/:id.json';

  static queryData(params) {
    return axios.get('/data_locks/index_data.json', { params: params });
  }
}

export default DataLock;
