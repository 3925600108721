<template>
  <div>
    <div :class="['bold-weight tight-lines', { 'disabled-text' : !question.isIncluded }]">
      {{ question.questionText }}
      <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
    </div>

    <div :class="{ 'disabled-text' : !question.isIncluded }" v-if="hasAnswer">
      {{ formattedRange }}
    </div>

    <div v-else :class="['missing-text', { 'disabled-text' : !question.isIncluded }]">
      No answer provided
    </div>
  </div>
</template>

<script>
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import { compact, startCase } from 'lodash';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';

export default {
  name: 'QuestionAnswerRange',

  components: {
    ScoutTag
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    answer: {
      type: Object,
      required: false
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasAnswer() {
      return this.answer && this.answer.value && this.answer.value.length;
    },

    rangeMin() {
      return this.answer.value[0];
    },

    rangeMax() {
      return this.answer.value[1];
    },

    formattedRange() {
      return compact([CurrencyFilter.filter(this.rangeMin), CurrencyFilter.filter(this.rangeMax)]).join(' - ');
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  }
};
</script>
