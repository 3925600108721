<template>
  <div :class="boxClass">
    <slot name="icon"></slot>
    <span><span class="size-text-13px bold-weight">{{ header }}:</span> <span>{{ message }}</span></span>
  </div>
</template>

<script>
export default {
  name: 'ColorMessageBar',

  props: {
    color: {
      type: String,
      required: true
    },

    header: {
      type: String,
      required: true
    },

    message: {
      type: String,
      required: true
    }
  },

  computed: {
    boxClass() {
      return {
        yellow: 'yellow-message-box',
        green: 'green-message-box'
      }[this.color];
    }
  }
};
</script>

<style scoped lang="scss">
  .base-icon {
    flex-shrink: 0;
  }
</style>
