<template>
  <priori-modal ref="rfqMarketplaceResponseModal" modal-id="rfq-marketplace-response-modal" modal-size="lg">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal" class="darker-gray-link size-text-21px bold-weight">{{ name }}</a>
    </template>

    <template #title>
      Marketplace {{ accountType }}: <span class="purple-text">{{ name }}</span>
    </template>

    <div class="vertical-center-not-xs lawyer-profile-panel">
      <proposal-lawyer-basic-details
        :possible-lawyer="possibleLawyer"
        :is-preview="true"
        :is-marketplace-rfq="true">
      </proposal-lawyer-basic-details>
    </div>

    <hr>

    <div class="box-content">
      <proposal-lawyer-details
        :possible-lawyer="possibleLawyer"
        :inside-the-gate="false"
        :is-marketplace-rfq-lawyer="true">
      </proposal-lawyer-details>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import ProposalLawyerBasicDetails from 'vue-app/marketplace/client/proposals/proposal-lawyer-basic-details.vue';
import ProposalLawyerDetails from 'vue-app/marketplace/client/proposals/proposal-lawyer-details.vue';
import { startCase } from 'lodash';

export default {
  name: 'RfqMarketplaceResponseModal',

  components: {
    PrioriModal,
    ProposalLawyerDetails,
    ProposalLawyerBasicDetails
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    addedAsFirm: {
      type: Boolean,
      required: true
    },

    name: {
      type: String,
      required: true
    }
  },
  computed: {
    accountType() {
      return this.addedAsFirm ? 'Law Firm' : this.lawyerAccountTypeDisplay;
    },

    lawyerAccountType() {
      return this.possibleLawyer.lawyer.accountType;
    },

    lawyerAccountTypeDisplay() {
      return this.lawyerAccountType === 'admin' ? 'Lawyer' : startCase(this.lawyerAccountType);
    }
  }
};
</script>
