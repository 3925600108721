<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a :href="`/admin-app/clients/${client.id}`" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Cancel</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="medium-page-heading bottom-30">
            Credit for: {{ client.fullName }}
          </div>

          <div class="row bottom-30">
            <div class="col-sm-8 col-md-6">
              <div class="shadowed-box">
                <div class="box-content">
                  <new-credit-form :on-save="submitNewCredit"></new-credit-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import NewCreditForm from 'vue-app/admin/clients/new-credit-form.vue';

export default {
  name: 'NewCredit',

  components: {
    SvgIcon,
    NewCreditForm
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  methods: {
    submitNewCredit(creditParams) {
      this.onSave({ ...creditParams, clientId: this.client.id });
    }
  }
};
</script>
