<template>
  <div>
    <interface-note
      v-if="lawyer.scoutReviewsNote"
      class="bottom-40"
      :note-text="lawyer.scoutReviewsNote">
    </interface-note>

    <div class="row d-flex-not-xs">
      <div class="col-sm-4">
        <boxed-content title="Average Recommendation Score" class="height-100" content-class="symmetrical">
          <div class="vertical-center-not-xs center-justified bottom-20">
            <lawyer-recommendation-score :matter-count="lawyer.matterCount" :reviews-summary="lawyer.reviewsSummary"></lawyer-recommendation-score>
          </div>

          <a :href="`/scout-company/lawyers/${lawyer.id}/reviews/new`" class="primary-btn-blue smaller" target="_self">Leave a Review</a>
        </boxed-content>
      </div>

      <div class="col-sm-4 top-30-xs">
        <boxed-content title="Performance" class="height-100" content-class="symmetrical">
          <loading-section name="review-template">
            <performance-score-with-top-label :scores="lawyer.reviewsSummary.scoreAverages" :review-template="reviewTemplate" v-if="reviewTemplate"></performance-score-with-top-label>
          </loading-section>
        </boxed-content>
      </div>

      <div class="col-sm-4 top-30-xs">
        <boxed-content title="Total Matters By Dollars Spent" class="height-100" content-class="symmetrical">
          <lawyer-total-matters :lawyer="lawyer"></lawyer-total-matters>
          <template v-if="toolTipText" #tooltip>
            <svg-icon id="lawyer-spend-tooltip" name="info" class="base-icon"></svg-icon>
            <tooltip target="#lawyer-spend-tooltip" custom-class="tooltip-white" placement="bottom" :text="toolTipText"></tooltip>
          </template>
        </boxed-content>
      </div>
    </div>

    <div class="top-30">
      <loading-section name="review-template">
        <lawyer-matters
          v-if="reviewTemplate"
          :lawyer="lawyer"
          :timekeepings="timekeepings"
          :general-reviews="generalReviews"
          :review-template="reviewTemplate"
          :on-apply-filters="onApplyFilters"
          :practice-area-options="practiceAreaOptions"
          :filters-data="filtersData"
          :matter-manager-options="matterManagerOptions">
        </lawyer-matters>
      </loading-section>
    </div>
  </div>
</template>

<script>
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import PerformanceScoreWithTopLabel from 'vue-app/scout/shared/performance-score-with-top-label.vue';
import LawyerTotalMatters from 'vue-app/scout/shared/lawyers/lawyer-total-matters.vue';
import LawyerMatters from 'vue-app/scout/client/lawyers/reviews/lawyer-matters.vue';
import LawyerRecommendationScore from 'vue-app/scout/client/lawyers/reviews/lawyer-recommendation-score.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ScoutLawyerReviewTemplate from 'resources/scout/client/scout-lawyer-review-template.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'LawyerReviews',

  components: {
    LoadingSection,
    SvgIcon,
    BoxedContent,
    LawyerMatters,
    LawyerRecommendationScore,
    LawyerTotalMatters,
    PerformanceScoreWithTopLabel,
    InterfaceNote
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    timekeepings: {
      type: Array,
      required: true
    },

    generalReviews: {
      type: Array,
      required: true
    },

    practiceAreaOptions: {
      type: Array,
      required: true
    },

    onApplyFilters: {
      type: Function,
      required: false
    },

    filtersData: {
      type: Object,
      required: true
    },

    matterManagerOptions: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      reviewTemplate: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    toolTipText() {
      return this.currentClient.workspace.scoutLawyersMattersSpendTooltipText;
    }
  },

  created() {
    LoadingService.loading('review-template');

    ScoutLawyerReviewTemplate.inWorkspace().then((template) => {
      this.reviewTemplate = template;
      LoadingService.done('review-template');
    });
  }
};
</script>
