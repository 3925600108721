<template>
  <div class="grid-row matching-detail">
    <div class="gd">
      {{ search.adminUser.fullName }}
    </div>

    <div class="gd">
      <div>
        {{ formattedDate(search.createdAt) }}
      </div>

      <div>
        {{ formattedTime(search.createdAt) }}
      </div>
    </div>

    <div class="gd size-text-12px">
      <div class="row">
        <div class="col-sm-4">
          <div class="semibold-weight">
            Page:
          </div>

          <div class="bottom-10">
            {{ search.page }}
          </div>
        </div>

        <div class="col-sm-8">
          <div class="semibold-weight">
            Includes:
          </div>

          <div class="bottom-10">
            {{ includes }}
          </div>
        </div>
      </div>

      <div v-if="hasKeywords">
        <div class="semibold-weight">
          Keywords ({{ keywordLogic }}):
        </div>

        <div class="bottom-10">
          {{ keywords }}
        </div>
      </div>

      <div v-for="(filter, name) in lawyerFilters" :key="name">
        <span class="semibold-weight">{{ formattedName(name) }}{{ formattedOperator(filter) }}:</span> {{ formattedFilter(filter) }}
      </div>

      <div class="size-text-13px missing-text bottom-10" v-if="clearedFilters">
        -- Filters Cleared --
      </div>
    </div>

    <div class="gd request-lawyers">
      <div class="lawyer size-text-12px bottom-5" v-for="lawyer in search.lawyers" :key="lawyer.id">
        <a :href="`/admin-app/lawyers/${lawyer.id}`" :class="lawyer.matchGroup || 'untracked'" target="_blank">{{ lawyerName(lawyer) }}</a>
      </div>

      <div class="size-text-13px missing-text" v-if="!search.lawyers.length">
        -- No Results --
      </div>
    </div>

    <div class="gd">
      {{ relevanceScore }}%
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import { isEmpty, map, round, startCase, upperCase, join, compact } from 'lodash';

export default {
  name: 'MatchingDetail',

  props: {
    search: {
      type: Object,
      required: true
    },

    lawyerHits: {
      type: Array,
      required: true
    }
  },

  computed: {
    searchParams() {
      return this.search.searchParams;
    },

    lawyerFilters() {
      return { ...this.searchParams.scores, ...this.searchParams.filters };
    },

    includes() {
      return map(this.searchParams.includes, startCase).join('; ');
    },

    hasKeywords() {
      return this.searchParams.keywords.length;
    },

    keywords() {
      return this.searchParams.keywords.join('; ');
    },

    keywordLogic() {
      return upperCase(this.searchParams.keywordLogic);
    },

    clearedFilters() {
      return isEmpty(this.lawyerFilters);
    },

    relevanceScore() {
      let score = (this.lawyerHits.length / this.search.lawyers.length * 100) || 0;
      return round(score, 1);
    }
  },

  methods: {
    formattedDate(date) {
      return DateFilter.filter(date);
    },

    formattedTime(date) {
      return DateFilter.shortTime(date);
    },

    formattedName(filterName) {
      return startCase(filterName);
    },

    formattedOperator(filter) {
      return Array.isArray(filter) && filter.length > 1 ? ' (or)' : '';
    },

    formattedFilter(filter) {
      if (Array.isArray(filter)) {
        return this.termsFilter(filter);
      }
      else if (filter.gte || filter.lte) {
        return this.rangeFilter(filter);
      }
      else if (typeof (filter) === 'boolean') {
        return this.boolFilter(filter);
      }
      else {
        return filter;
      }
    },

    termsFilter(filter) {
      return map(filter, (t) => { return startCase(t); }).join('; ');
    },

    rangeFilter(filter) {
      // TODO: change cents to dollars?
      return (filter.gte || 'Not Selected') + ' - ' + (filter.lte || 'Not Selected');
    },

    boolFilter(filter) {
      return startCase(filter);
    },

    lawyerName(lawyer) {
      return join(compact([lawyer.firstName, lawyer.middleName, lawyer.lastName]), ' ');
    }
  }
};
</script>
