<template>
  <div class="rating-bar-container">
    <div class="rating-bar">
      <div :class="['rating-fill', fillColorClass]" ref="rating" :style="fillBarStyle" v-if="hasRating">
        <div :id="`legend-dot-${legendId}`" class="dot"></div>
      </div>

      <div class="no-data-text" v-else>No data</div>
    </div>

    <div class="range-bar" :style="rangeBarStyle" v-if="hasRating"></div>

    <div class="diamond" :style="compScoreStyle" v-if="hasCompScore"></div>

    <div :id="`legend-diamond-${legendId}`" class="diamond-tooltip-overlay" :style="compScoreStyle" v-if="hasCompScore"></div>

    <rating-bar-tooltip
      :target="`#legend-dot-${legendId}`"
      :score-label="ratingLabel"
      :comp-label="compLabel"
      :range-label="rangeLabel"
      :score="rating"
      :comp-score="compScore"
      :range-min="rangeMin"
      :range-max="rangeMax">
    </rating-bar-tooltip>

    <rating-bar-tooltip
      :target="`#legend-diamond-${legendId}`"
      :score-label="ratingLabel"
      :comp-label="compLabel"
      :range-label="rangeLabel"
      :score="rating"
      :comp-score="compScore"
      :range-min="rangeMin"
      :range-max="rangeMax">
    </rating-bar-tooltip>
  </div>
</template>

<script>
import RatingBarTooltip from './rating-bar-tooltip.vue';
import { uniqueId } from 'lodash';

export default {
  name: 'RatingBarWithRange',

  components: {
    RatingBarTooltip
  },

  props: {
    rating: {
      type: Number,
      required: true
    },

    maxRating: {
      type: Number,
      required: true
    },

    rangeMin: {
      type: Number,
      required: true
    },

    rangeMax: {
      type: Number,
      required: true
    },

    compScore: {
      type: Number,
      required: true
    },

    ratingLabel: {
      type: String,
      required: true
    },

    compLabel: {
      type: String,
      required: true
    },

    rangeLabel: {
      type: String,
      required: true
    },

    fillColorClass: {
      type: String,
      default: null
    }
  },

  computed: {
    hasRating() {
      return this.rating > 0;
    },

    hasCompScore() {
      return this.compScore > 0;
    },

    fillBarWidthPercent() {
      return this.rating / this.maxRating * 100;
    },

    fillBarStyle() {
      return { width: `${this.fillBarWidthPercent}%` };
    },

    rangeLeftPercent() {
      return this.rangeMin / this.maxRating * 100;
    },

    rangeWidthPercent() {
      return (this.rangeMax - this.rangeMin) / this.maxRating * 100;
    },

    rangeBarStyle() {
      return { left: `${this.rangeLeftPercent}%`, width: `${this.rangeWidthPercent}%` };
    },

    compScoreLeftPercent() {
      return this.compScore / this.maxRating * 100;
    },

    compScoreStyle() {
      // NOTE: 4px offset is half the width of the diamond
      return { left: `calc(${this.compScoreLeftPercent}% - 4px)` };
    },

    legendId() {
      return uniqueId();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rating-bar-container {
    position: relative;

    .rating-bar, .rating-fill {
      position: relative;
      z-index: 1;

      .dot {
        position: absolute;
        top: 50%;
        right: 0;
        width: 18px;
        height: 18px;
        transform: translate(50%, -50%);
        background: $k-blue;
        border: 3px solid $white;
        border-radius: 50%;
      }

      &.blue-purple-background .dot {
        background: $k-blue;
      }

      &.light-to-dark-blue-background .dot {
        background: $k-purple;
      }

      .no-data-text {
        position: absolute;
        top: -6px;
        width: 100%;
        text-align: center;
        font-size: 11px;
        font-style: italic;
        color: $pill-gray;
      }
    }

    .range-bar {
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      height: 23px;
      background-color: $grey-3;
      border-radius: $border-radius-small;
      z-index: 0;
    }

    .diamond {
      position: absolute;
      top: -10px;
      width: 8px;
      height: 8px;
      background-color: $pill-gray;
      transform: rotate(45deg);
      border-radius: 1px;
    }

    .diamond-tooltip-overlay {
      position: absolute;
      top: -10px;
      width: 8px;
      height: 8px;
      background-color: transparent;
      transform: rotate(45deg);
      border-radius: 1px;
      z-index: 2;
    }
  }
</style>
