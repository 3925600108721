<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-3 text-uppercase">
          D&I Statistics
        </div>

        <div :class="['size-text-12px normal-weight text-right-not-xs', { 'col-sm-8': canEditProfile, 'col-sm-9': !canEditProfile }]">
          <a href="" :class="['header-link', { 'selected': isSelected('allLawyers') }]" @click.prevent="selectCountAttribute('allLawyers')">All Lawyers</a>

          <a href="" :class="['header-link', { 'selected': isSelected('partners') }]" @click.prevent="selectCountAttribute('partners')">Partners</a>

          <a href="" :class="['header-link', { 'selected': isSelected('otherLawyers') }]" @click.prevent="selectCountAttribute('otherLawyers')">Other Lawyers</a>
        </div>

        <div class="col-sm-1 text-right-not-xs" v-if="canEditProfile">
          <edit-firm-diversity :firm="firm" :selected-count-attribute="selectedCountAttribute" :on-save="save"></edit-firm-diversity>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="firm-diversity">
        <display-rules-data-wrapper :data="selectedStatistic">
          <firm-diversity-detail :statistic="selectedStatistic" :di-statistic-extra="firm.diStatisticExtra"></firm-diversity-detail>
        </display-rules-data-wrapper>
      </loading-section>
    </div>
  </div>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import FirmDiversityDetail from './firm-diversity-detail';
import EditFirmDiversity from './edit-firm-diversity';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'FirmDiversity',

  components: {
    DisplayRulesDataWrapper,
    FirmDiversityDetail,
    EditFirmDiversity
  },

  mixins: [displayRules],

  props: {
    firm: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      selectedCountAttribute: 'allLawyers'
    };
  },

  computed: {
    selectedStatistic() {
      switch (this.selectedCountAttribute) {
        case 'partners':
          return this.firm.diStatistics.partners;

        case 'otherLawyers':
          return this.firm.diStatistics.otherLawyers;

        default:
          return this.firm.diStatistics.allLawyers;
      }
    },

    displayComponent: function () {
      const { allLawyers, partners, otherLawyers } = this.firm.diStatistics;
      return this.anyDataIsPresent([allLawyers, partners, otherLawyers]) || this.canEditProfile;
    }
  },

  methods: {
    selectCountAttribute: function (attribute) {
      this.selectedCountAttribute = attribute;
    },

    isSelected: function (attribute) {
      return this.selectedCountAttribute === attribute;
    },

    save(firmEdited) {
      if (!this.canEditProfile) { return; }

      LoadingService.loading('firm-diversity');

      this.onSave(firmEdited,
        () => {
          LoadingService.done('firm-diversity');
        },
        () => {
          LoadingService.done('firm-diversity');
        }
      );
    }
  }
};
</script>
