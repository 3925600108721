<template>
  <div class="admin-grid matching-grid bottom-10">
    <div class="grid-head">
      <div class="gh">
        Request
      </div>

      <div class="gh">
        Type
      </div>

      <div class="gh">
        Client
      </div>

      <div class="gh">
        Project Information
      </div>

      <div class="gh">
        Total Searches
      </div>

      <div class="gh">
        Lawyer Matches
      </div>

      <div class="gh">
        % Relevance
      </div>
    </div>

    <div class="grid-row">
      <div class="gd">
        <div class="size-text-12px semibold-weight">
          ID
        </div>

        <div class="bottom-10">
          <a :href="`/admin-app/requests/${request.id}`" class="request-id" target="_blank">{{ request.id }}</a>
        </div>

        <div class="size-text-12px semibold-weight">
          Submitted
        </div>

        <div class="bottom-10">
          {{ formattedDate(request.createdAt) }}
        </div>

        <div class="size-text-12px semibold-weight">
          Status
        </div>

        <div>
          {{ requestStatus }}
          <span v-if="request.closeType">({{ closeType }})</span>
        </div>
      </div>

      <div class="gd">
        {{ requestType }}
      </div>

      <div class="gd">
        <div class="size-text-12px semibold-weight">
          Name
        </div>

        <div class="wrap-word bottom-10">
          <a :href="request.requester.adminViewPath" target="_blank" v-if="requesterIsFullClient">{{ request.requester.fullName }}</a>
          <span v-else>{{ request.requester.fullName }}</span>
        </div>

        <div class="size-text-12px semibold-weight">
          Company
        </div>

        <div class="wrap-word">
          {{ request.clientCompany || '&mdash;' }}
        </div>
      </div>

      <div class="gd">
        <div class="size-text-12px semibold-weight">
          Project Name
        </div>

        <div class="wrap-word bottom-10">
          <a :href="`/admin-app/requests/${request.id}`" target="_blank">{{ request.nameForLawyersReference }}</a>
        </div>

        <div class="size-text-12px semibold-weight">
          Management Fee
        </div>

        <div>
          {{ request.managementFeePercent }}%
        </div>
      </div>

      <div class="gd">
        {{ request.searches.length }}
      </div>

      <div class="gd request-lawyers">
        <div class="lawyer size-text-12px bottom-5" v-for="lawyer in allLawyers" :key="lawyer.id">
          <a :href="`/admin-app/lawyers/${lawyer.id}`" :class="matchGroupFor(lawyer)" target="_blank">{{ lawyerName(lawyer) }}</a>
        </div>
      </div>

      <div class="gd">
        {{ relevanceScore }}%
      </div>
    </div>

    <div class="detail-grid" v-if="showDetails">
      <div class="grid-head">
        <div class="gh">
          Admin
        </div>

        <div class="gh">
          Date/Time
        </div>

        <div class="gh">
          Filters
        </div>

        <div class="gh">
          Lawyer Matches
        </div>

        <div class="gh">
          % Relevance
        </div>
      </div>

      <matching-detail
        :search="search"
        :lawyer-hits="lawyerHits[search.id]"
        v-for="search in request.searches"
        :key="search.id">
      </matching-detail>
    </div>

    <div class="expand-btn" @click="toggleDetails">
      <span :class="['expand-icon', (showDetails ? 'minus-sign' : 'plus-sign')]"></span>
    </div>
  </div>
</template>

<script>
import MatchingDetail from './matching-detail.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';
import { each, find, flatMap, flatten, includes, round, startCase, uniq, uniqBy, values, join, compact } from 'lodash';

export default {
  name: 'MatchingItem',

  components: {
    MatchingDetail
  },

  props: {
    request: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showDetails: false,
      unsentStatuses: ['tracked', 'invited', 'applied', 'unsent']
    };
  },

  computed: {
    requestType() {
      return startCase(this.request.requestType);
    },

    requestStatus() {
      return startCase(this.request.status);
    },

    closeType() {
      return startCase(this.request.closeType);
    },

    requesterIsFullClient() {
      return this.request.requester.klass === 'Client';
    },

    allLawyers() {
      return uniqBy(flatMap(this.request.searches, 'lawyers'), 'id');
    },

    possibleLawyers() {
      return this.request.allPossibleLawyers;
    },

    lawyerHits() {
      let pl = null;
      let lawyerHits = {};

      each(this.request.searches, (s) => {
        lawyerHits[s.id] = [];

        each(s.lawyers, (lawyer) => {
          pl = this.possibleLawyerFor(lawyer);

          if (pl) {
            lawyerHits[s.id].push(lawyer.id);

            // TODO: it would be better not to modify the lawyer object here
            // (I fixed it in this component but to remove this, we need to do
            // the same in MatchingDetail)
            if (includes(this.unsentStatuses, pl.status)) {
              lawyer.matchGroup = (pl.status === 'unsent' ? 'unsent' : 'applied');
            }
            else {
              lawyer.matchGroup = 'sent';
            }
          }
        });
      });

      return lawyerHits;
    },

    relevanceScore() {
      let score = uniq(flatten(values(this.lawyerHits))).length / this.allLawyers.length * 100;
      return round(score || 0, 1);
    }
  },

  methods: {
    formattedDate(date) {
      return DateFilter.filter(date);
    },

    toggleDetails() {
      this.showDetails = !this.showDetails;
    },

    possibleLawyerFor(lawyer) {
      return find(this.possibleLawyers, (p) => { return !p.lawFirmId && p.lawyerId === lawyer.id; });
    },

    matchGroupFor(lawyer) {
      const pl = this.possibleLawyerFor(lawyer);

      if (!pl) {
        return 'untracked';
      }
      else if (includes(this.unsentStatuses, pl.status)) {
        return (pl.status === 'unsent' ? 'unsent' : 'applied');
      }
      else {
        return 'sent';
      }
    },

    lawyerName(lawyer) {
      return join(compact([lawyer.firstName, lawyer.middleName, lawyer.lastName]), ' ');
    }
  }
};
</script>
