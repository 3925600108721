<template>
  <div class="sidenav-content">
    <div class="container-fluid">
      <div class="padded-container">
        <div class="medium-page-heading bottom-30">
          Account Settings
        </div>

        <div class="row bottom-30">
          <div class="col-md-8 bottom-20">
            <div class="shadowed-box">
              <div class="box-content normal-weight size-text-13px">
                <div class="bottom-30">
                  <div class="bottom-10"><a href="/lawyer-app/account-info">Email Address</a></div>
                  <div class="bottom-10"><a href="/lawyer-app/phone-numbers">Phone Numbers</a></div>
                  <div v-if="!ssoEnabled">
                    <change-password-modal
                      :resource="lawyer"
                      :on-save="onSavePassword"
                      :prompt="'Password'"
                      :password-authentication="passwordAuthentication"
                      class="bottom-10">
                    </change-password-modal>
                    <div class="bottom-10"><a href="/lawyer-app/two-factor-authentication">Two-Factor Authentication</a></div>
                  </div>
                </div>

                <div class="bottom-30">
                  <div class="bottom-10" v-if="lawyerEligibleForBankAccounts">
                    <a href="/lawyer-app/bank-accounts">Bank Accounts</a>
                  </div>
                  <div class="bottom-10" v-if="lawyerEligibleForMalpractice">
                    <a href="/lawyer-app/malpractice-insurance">Malpractice Insurance</a>
                  </div>
                  <div class="bottom-10">
                    <a href="/lawyer-app/time-zone">Time Zone</a>
                  </div>
                  <div class="bottom-10">
                    <a href="/lawyer-app/invoice-settings">Invoice Display</a>
                  </div>
                  <div class="bottom-10" v-if="!lawyerIsAdmin">
                    <a href="/lawyer-app/references">References</a>
                  </div>
                  <div class="bottom-10" v-if="!lawyerIsAdmin">
                    <a href="/lawyer-app/review-invites">Review Invitations</a>
                  </div>
                </div>

                <div>
                  <div class="bottom-10" v-if="nonAlspLawyer">
                    <a href="/lawyer-app/code-of-conduct">View Attorney Code of Conduct</a>
                  </div>
                  <div class="bottom-10" v-if="nonAlspLawyer">
                    <a href="/lawyer-app/terms">View Attorney Terms of Service</a>
                  </div>
                  <div><a href="/lawyer-app/tax-information">Tax Information</a></div>
                </div>
              </div>
            </div>
          </div>

          <div id="widget-container" class="col-sm-6 col-md-4">
            <identity-verification-needed v-if="lawyerRequiresIdentityVerification"></identity-verification-needed>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IdentityVerificationNeeded from 'vue-app/marketplace/lawyer/settings/identity-verification-needed.vue';
import ChangePasswordModal from 'vue-app/marketplace/shared/change-password-modal.vue';

export default {
  name: 'MarketplaceLawyerSettingsRoot',

  components: {
    ChangePasswordModal,
    IdentityVerificationNeeded
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    ssoEnabled: {
      type:    Boolean,
      default: false
    },

    passwordAuthentication: {
      type: Function,
      required: true
    },

    onSavePassword: {
      type: Function,
      required: true
    }
  },

  computed: {
    lawyerIsAdmin() {
      return this.lawyer && this.lawyer.accountType === 'admin';
    },

    nonAlspLawyer() {
      return this.lawyer && !this.lawyer.alspAccount;
    },

    lawyerEligibleForBankAccounts() {
      return (this.lawyer.address && this.lawyer.address.country === 'United States') ||
        this.lawyer.getsCrossBorderPayouts;
    },

    lawyerEligibleForMalpractice() {
      return !this.lawyerIsAdmin && this.lawyer.lawyerProfileId && this.lawyer.status !== 'applying';
    },

    lawyerRequiresIdentityVerification() {
      return this.lawyer.status !== 'applying' &&
        this.lawyer.status !== 'application_complete' &&
        this.lawyer.stripeIdentityVerificationStatus === 'not_submitted' &&
        this.lawyerEligibleForBankAccounts;
    }
  }
};
</script>
