<template>
  <div>
    <div class="size-text-15px bold-weight">
      <slot></slot>
    </div>

    <div class="top-5 bottom-15">
      <hr class="no-margin">
    </div>
  </div>
</template>
<script>

export default {
  name: 'UnderlinedHeader'
};
</script>
