<template>
  <div class="titlebar-with-sections split-60-40">
    <div class="titlebar">
      <span class="user-pic-container right-20"><profile-photo :user="client"></profile-photo></span>

      <div class="search-result-row">
        <div class="row tight-columns vertical-center">
          <div class="col-sm-10">
            <div class="size-text-24px text-ellipsis bottom-5">
              {{ client.fullName }}
            </div>

            <div class="size-text-13px text-ellipsis">
              <span class="semibold-weight">{{ client.businessName }}</span>
              <span v-if="client.role" class="semibold-weight">&mdash; {{ client.role }}</span>
              <span v-if="client.deactivatedAt" class="red-text text-italic">(Deactivated)</span>
            </div>
          </div>

          <div class="col-xs-6 col-sm-2 text-right top-20-xs">
            <dropdown-menu width="larger" icon-name="caret-down">
              <li class="menu-item" role="menuitem">
                <a href="" @click.prevent="messageClient"><svg-icon name="message" class="base-icon"></svg-icon>Message</a>
              </li>

              <li class="menu-item" role="menuitem">
                <a href="" @click.prevent="impersonate"><svg-icon name="profile" class="base-icon"></svg-icon>Impersonate</a>
              </li>
            </dropdown-menu>

            <impersonate-modal
              :user="client"
              target="_self"
              :modal-trigger="impersonateClient"
              :on-cancel="cancelImpersonation">
            </impersonate-modal>
          </div>
        </div>
      </div>
    </div>

    <div class="titlebar-switcher extra-padding text-center">
      <a href="" :class="{ 'selected': selectedTab === 'profile' }" @click.prevent="onSelect({ tabName: 'profile', identifier: 'main' })">Profile</a>
      <a href="" :class="{ 'selected': selectedTab === 'notes' }" @click.prevent="onSelect({ tabName: 'notes', identifier: 'main' })">Notes</a>
      <a href="" :class="{ 'selected': selectedTab === 'history' }" @click.prevent="onSelect({ tabName: 'history', identifier: 'main' })">History</a>
    </div>
  </div>
</template>

<script>
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';

export default {
  name: 'ClientAccountTitlebar',

  components: {
    ImpersonateModal,
    DropdownMenu
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    onSelect: {
      type: Function,
      default: () => {}
    },

    selectedTab: {
      type: String,
      required: true
    },

    sendMessageService: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      impersonateClient: false
    };
  },

  methods: {
    cancelImpersonation() {
      this.impersonateClient = false;
    },

    impersonate() {
      this.impersonateClient = true;
    },

    messageClient() {
      this.sendMessageService.modal([this.client]);
    }
  }
};
</script>
