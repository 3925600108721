<template>
  <div class="wired-box">
    <div :class="['box-header vertical-center bold-weight', { 'empty': !expandable || !isExpanded }]">
      <button type="button" class="pseudo-link-blue expand-btn right-10" @click="toggleDetails" :disabled="!expandable">
        <svg-icon class="base-icon" :name="isExpanded ? 'minimize' : 'expand'"></svg-icon>
      </button>

      <span class="text-uppercase size-text-12px flex-4">
        {{ title }}
      </span>

      <span class="text-right semibold-weight size-text-13px flex-4">
        <slot name="links"></slot>
      </span>
    </div>

    <div :class="{ 'with-scrollable': isExpanded && scrollable }">
      <div class="box-content" v-uiv-scrollable-dropdown v-if="isExpanded">
        <slot name="box-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown.js';

export default {
  name: 'ExpandableBox',

  components: {
    SvgIcon
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    title: {
      type: String,
      required: true
    },

    expandable: {
      type: Boolean,
      default: true
    },

    openByDefault: {
      type: Boolean,
      default: false
    },

    expandedByParent: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      controlledLocally: true,
      expandedLocally: this.openByDefault || this.expandedByParent
    };
  },

  computed: {
    isExpanded() {
      return this.controlledLocally ? this.expandedLocally : this.expandedByParent;
    }
  },

  watch: {
    expandedByParent() {
      this.expandedLocally = this.expandedByParent;
      this.controlledLocally = false;
    }
  },

  methods: {
    toggleDetails() {
      this.expandedLocally = !this.expandedLocally;
      this.controlledLocally = true;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .expand-btn, .expand-btn .base-icon {
    width: 20px;
    height: 20px;
  }

  .wired-box {
    .box-header {
      &.empty {
        border-bottom: none;
        border-radius: $border-radius-large;
      }
    }

    .box-content {
      max-height: 570px;
      overflow-x: hidden;
      overflow-y: scroll;

      @media (min-width: 768px) {
        max-height: 360px;
      }
    }

    .with-scrollable {
      height: fit-content;

      &.scrolled-up::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
        border-bottom-right-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
        background: linear-gradient(180deg, rgba($white, 0) 0%, $white 100%);
        pointer-events: none;
      }

      &.scrolled-down::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, $white 0%, rgba($white, 0) 100%);
        pointer-events: none;
        z-index: 1;
      }
    }
  }
</style>
