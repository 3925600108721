<template>
  <div>
    <div class="bottom-30">
      <div class="bottom-20 text-italic pill-gray-text size-text-12px">
        Please use the fields below to input the diversity data that you would like to display on your law firm’s profile. If your firm reports this diversity data in a different format, please reach out to your Priori representative to discuss.
      </div>

      <div class="row tight-columns text-nowrap text-center bottom-15 bold-weight">
        <div class="col-sm-8 col-sm-offset-4">
          <div class="row">
            <div class="col-xs-3 ethnicity-column">
              Female
            </div>

            <div class="col-xs-3 ethnicity-column">
              Male
            </div>

            <div class="col-xs-3 ethnicity-column">
              Non-Binary
            </div>

            <div class="col-xs-3 ethnicity-column">
              Not Disclosed
            </div>
          </div>
        </div>
      </div>

      <div class="row tight-columns vertical-center-not-xs bottom-15" v-for="ethnicity in statistic.ethnicityStatisticsAttributes" :key="`${ethnicity.name}-${statistic.statisticType}`">
        <div class="col-sm-4 text-right-not-xs right-15">
          <label class="normal-weight">{{ ethnicity.name }}</label>
        </div>

        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-3 ethnicity-column">
              <input type="number" min="0" step="1" v-model="ethnicity.countFemale" class="form-control bottom-5 text-center">
            </div>

            <div class="col-xs-3 ethnicity-column">
              <input type="number" min="0" step="1" v-model="ethnicity.countMale" class="form-control bottom-5 text-center">
            </div>

            <div class="col-xs-3 ethnicity-column">
              <input type="number" min="0" step="1" v-model="ethnicity.countNonBinary" class="form-control bottom-5 text-center">
            </div>

            <div class="col-xs-3 ethnicity-column">
              <input type="number" min="0" step="1" v-model="ethnicity.countNotDisclosed" class="form-control bottom-5 text-center">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-30">
      <div class="small-bold-header">
        Other Diversity Statistics
      </div>

      <hr>

      <div class="bottom-20 text-italic pill-gray-text size-text-12px">
        Please use the fields below to input additional diversity data that you would like to display on your law firm’s profile. If your firm reports this diversity data in a different format, please reach out to your Priori representative to discuss.
      </div>

      <div class="form-group">
        <label for="countLgbtq">LGBTQ+ Individuals</label>
        <input type="number" min="0" step="1" id="countLgbtq" v-model="statistic.countLgbtq" class="form-control input">
      </div>

      <div class="form-group">
        <label for="countWithDisabilities">Individuals with Disabilities</label>
        <input type="number" min="0" step="1" id="countWithDisabilities" v-model="statistic.countWithDisabilities" class="form-control input">
      </div>

      <div class="form-group">
        <label for="countVeterans">Veterans</label>
        <input type="number" min="0" step="1" id="countVeterans" v-model="statistic.countVeterans" class="form-control input">
      </div>

      <div class="form-group">
        <label for="amlawDiversityRank">Am Law Diversity Scorecard Rank</label>
        <input type="number" min="0" step="1" id="amLawDiversityRank" v-model="statistic.amlawDiversityRank" class="form-control input">
      </div>
    </div>

    <div>
      <div class="small-bold-header">
        DATA BACKGROUND
      </div>

      <hr>

      <div class="bottom-20 text-italic pill-gray-text size-text-12px">
        Please use the field below to provide any context or other information about the diversity data included above. This contextual note will appear alongside your diversity data on the firm profile.
      </div>

      <div class="bottom-5">
        <textarea name="message" maxlength="200" class="form-control" rows="6" v-model="diStatisticExtra.dataBackground"></textarea>
      </div>

      <div class="pill-gray-text">
        Limited to 200 characters
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditFirmDiversityStatistic',

  props: {
    statistic: {
      type: Object,
      required: true
    },

    diStatisticExtra: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  label {
    line-height: 1.3;
  }

  input[type="number"] {
    padding-right: 11px;
    padding-left: 11px;
  }

  .input {
    width: 30%;
  }

  .ethnicity-column {
    padding-right: 5px;
    padding-left: 5px;
  }
</style>
