<template>
  <boxed-content id="lawyer-permissions-settings" :title="'Users'" class="settings-box">
    <template #links>
      <a href="/scout-firm/settings/users" class="size-text-13px">View/Edit</a>
    </template>

    <div class="row tight-columns">
      <div class="col-sm-8">
        <div class="bold-weight size-text-14px bottom-5">
          View/Manage Users
        </div>

        <div class="dark-gray-text">
          Admins can create, edit, and deactivate users and set their permissions. Lawyer users can only search for lawyers within the network and edit their profile information.
        </div>
      </div>

      <div class="col-sm-4 text-right">
        <div class="bottom-5">
          {{ firm.adminUsersCount }} Admin {{ pluralize('User', firm.adminUsersCount) }}
        </div>

        <div>
          {{ firm.lawyerUsersCount }} Lawyer {{ pluralize('User', firm.lawyerUsersCount) }}
        </div>
      </div>
    </div>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';

export default {
  name: 'UsersSummary',

  components: {
    BoxedContent
  },

  props: {
    firm: {
      type: Object,
      required: true
    }
  },

  methods: {
    pluralize(word, count) {
      return this.$pluralize(word, count);
    }
  }
};
</script>
