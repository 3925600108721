<template>
  <button type="button" class="semibold-weight pseudo-link-blue" @click="onClick">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'PseudoLinkButton',

  props: {
    text: {
      type: String,
      required: true
    },

    onClick: {
      type: Function,
      required: true
    }
  }
};
</script>
