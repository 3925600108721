<template>
  <div>
    <scout-titlebar
      title="My Account">
      <template #actions>
        <button type="button" class="titlebar-button secondary-btn-blue" @click="logout">Sign Out</button>
      </template>
    </scout-titlebar>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-10 col-md-8">
              <div class="bottom-30" v-if="currentClient">
                <account-info
                  :on-save="onSave"
                  :password-authentication="passwordAuthentication"
                  :update-password="updateClientPassword">
                </account-info>
              </div>

              <div class="bottom-30" v-if="!isFeatureLab">
                <notification-settings
                  :on-save="onSave">
                </notification-settings>
              </div>

              <div v-if="!ssoEnabled && authenticatable">
                <two-factor-authentication-settings
                  :save-changes="saveMfaChanges"
                  :verify-otp="verifyOtp"
                  :send-one-time-password="sendOneTimePassword"
                  :password-authentication="passwordAuthentication"
                  :authenticatable="authenticatable"
                  :section-title="'Account Security'">
                </two-factor-authentication-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountInfo from 'vue-app/scout/client/account/account-info.vue';
import NotificationSettings from 'vue-app/scout/client/account/notification-settings.vue';
import ScoutTitlebar from 'vue-app/scout/shared/scout-titlebar.vue';
import TwoFactorAuthenticationSettings from 'vue-app/shared/components/two-factor-authentication/two-factor-authentication-settings.vue';
import Client from 'resources/client.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import TwoFactorAuthenticatable from 'resources/two-factor-authenticatable.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'ScoutClientAccountIndex',

  components: {
    NotificationSettings,
    AccountInfo,
    ScoutTitlebar,
    TwoFactorAuthenticationSettings
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      authenticatable: this.initData.authenticatable
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentProduct: 'currentProduct', currentClient: 'currentUser' }),

    ssoEnabled() {
      return this.currentClient.workspace.ssoEnabled;
    },

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    }
  },

  methods: {
    ...mapActions(useCurrentUserStore, { setCurrentUser: 'setCurrentUser' }),

    logout() {
      document.getElementById('hidden-logout-button').click();
    },

    onSave(clientAttributes) {
      return Client.update({ id: clientAttributes.id, client: clientAttributes })
        .then(client => {
          this.setCurrentUser(client);

          // TODO: Updates currentClient on Angular rootScope. This can be removed once off Angular.
          this.initData.updateAngularRootScope(client);
        })
        .catch(() => {
          NotificationService.error('Unable to save changes. Please try again.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },

    updateClientPassword(clientAttributes) {
      return Client.updatePassword({ id: this.currentClient.id, client: clientAttributes });
    },

    passwordAuthentication(password) {
      return TwoFactorAuthenticatable.passwordVerification({ password })
        .then((response) => {
          NotificationService.clear();
          return response.data;
        });
    },

    saveMfaChanges(authenticatable, successFn) {
      return TwoFactorAuthenticatable.update(authenticatable)
        .then(response => {
          this.authenticatable = response;
          NotificationService.success('Changes were successfully saved');
          successFn();
        })
        .catch(() => {
          NotificationService.error('Changes could not be saved');
        });
    },

    sendOneTimePassword() {
      return TwoFactorAuthenticatable.sendOneTimePassword({})
        .then(() => {
          NotificationService.clear();
        })
        .catch(() => {
          NotificationService.error('Could not send one time password');
        });
    },

    verifyOtp(otpAttempt, otpStrategy) {
      return TwoFactorAuthenticatable.verifyOtp({ otpAttempt, otpStrategy })
        .then(response => {
          this.authenticatable = { ...response, validOtp: true };
        })
        .catch(() => {
          this.authenticatable.validOtp = false;
        });
    }
  }
};
</script>
