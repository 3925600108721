<template>
  <div>
    <div class="row tight-columns">
      <div class="col-xs-10">
        <div class="semibold-weight text-ellipsis size-text-14px">
          {{ savedSearch.name }}
        </div>

        <div class="dark-gray-text size-text-12px top-5">
          {{ creatorFullName }}<span class="left-5 right-5 bold-weight">&#8231;</span>{{ visibility }}
        </div>
      </div>

      <div class="col-xs-2 text-right">
        <dropdown-menu :compact="true">
          <li class="menu-item" role="menuitem">
            <a :href="viewPath" target="_blank">View</a>
          </li>

          <li class="menu-item" role="menuitem" v-if="savedSearch.currentUserIsCreator">
            <saved-search-modal ref="editModal" :saved-search="savedSearch" :on-saved="onSaved">
              <a href="" class="edit" @click.prevent="openEditModal">Edit Details</a>
            </saved-search-modal>
          </li>

          <li class="menu-item" role="menuitem" v-if="savedSearch.currentUserIsCreator">
            <searches-delete-list-item :saved-search="savedSearch" :on-removed="onRemoved"></searches-delete-list-item>
          </li>
        </dropdown-menu>
      </div>
    </div>

    <hr>
  </div>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import SearchesDeleteListItem from './searches-delete-list-item.vue';
import SavedSearchModal from './saved-search-modal.vue';

export default {
  name: 'SearchesListItem',

  components: {
    DropdownMenu,
    SearchesDeleteListItem,
    SavedSearchModal
  },

  props: {
    savedSearch: {
      type: Object,
      required: true
    },

    onRemoved: {
      type: Function,
      required: true
    },

    onSaved: {
      type: Function,
      required: true
    }
  },

  computed: {
    visibility() {
      return this.savedSearch.visibility === 'shared' ? 'Shared' : 'Private';
    },

    querystringParams() {
      return new URLSearchParams(window.location.search);
    },

    viewPath() {
      const requestId = this.querystringParams.get('counsel_request_id');
      const basePath = requestId ? `/admin-app/requests/${requestId}/search` : '/admin-app/lawyers/search';

      return `${basePath}/${this.savedSearch.id}`;
    },

    creatorFullName() {
      if (this.savedSearch.currentUserIsCreator) {
        return `${this.savedSearch.creatorFullName} (You)`;
      }
      else {
        return this.savedSearch.creatorFullName;
      }
    }
  },

  methods: {
    openEditModal() {
      this.$refs.editModal.openModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .saved-search-item:last-of-type hr {
    display: none;
  }

  .edit {
    display: block;
  }
</style>
