import BaseResource from 'resources/base-resource';
import axios from 'axios';

class RFQLawFirmQuote extends BaseResource {
  static baseUrl = '/scout/rfq_law_firm_quotes/:id.json';

  static getFromResourceList(params) {
    return axios.get('/scout/rfq_law_firm_quotes/get_from_resource_list.json', { params });
  }

  static scoutAdminsContacted(params) {
    return axios.post('/scout/rfq_law_firm_quotes/scout_admins_contacted.json', params);
  }

  static decline(id, reasonForDecline) {
    return axios.put(`/scout/rfq_law_firm_quotes/${id}/decline.json`, { reasonForDecline: reasonForDecline });
  }

  static submit(data) {
    return axios.put(`/scout/rfq_law_firm_quotes/${data.id}/submit.json`, data);
  }

  static destroyAll(params) {
    return axios.delete('/scout/rfq_law_firm_quotes/destroy_all.json', { params });
  }

  static addAdditionalRecipients(params) {
    return axios.put(`/scout/rfq_law_firm_quotes/${params.id}/additional_recipients/bulk_create.json`, params);
  }

  static deleteAdditionalRecipient(lawFirmQuoteId, additionalRecipientId) {
    return axios.delete(`/scout/rfq_law_firm_quotes/${lawFirmQuoteId}/additional_recipients/${additionalRecipientId}.json`);
  }
}

export default RFQLawFirmQuote;
