<template>
  <div class="shadowed-box size-text-13px">
    <div class="box-header text-uppercase">
      Legacy Opportunity
    </div>

    <div class="box-content symmetrical">
      <div class="bottom-20">
        <div class="bottom-20 semibold-weight">
          {{ opportunity.postTitle }}<span v-if="opportunity.location"> ({{ opportunity.location }})</span>
        </div>

        <div class="bottom-10">
          <span class="semibold-weight">Practice Area:</span> {{ opportunity.legacyPracticeArea || '—' }}
        </div>

        <div class="bottom-10">
          <span class="semibold-weight">Type:</span> {{ opportunityType || '—' }}
        </div>

        <div class="bottom-10">
          <span class="semibold-weight">Status:</span> {{ opportunity.displayStatus || '—' }}
        </div>

        <div class="bottom-10">
          <span class="semibold-weight">Additional Status Details:</span> {{ opportunity.additionalStatusDetails || '—' }}
        </div>

        <div>
          <div class="semibold-weight bottom-5">Description:</div>
          <div v-html="opportunity.opportunityDescription"></div>
        </div>

        <hr>

        <div v-for="(question, index) in questions" :key="question.id" class="list-spacing-10">
          <div class="semibold-weight">
            Question {{ index + 1 }} ({{ question.questionType }})
          </div>

          <div class="top-2">
            {{ question.questionText }}
          </div>
        </div>
      </div>

      <div class="text-italic">
        Legacy opportunity published to CMS<span v-if="publishedAt"> on {{ publishedDate }}</span>. Please submit a product ticket if you want to remove the legacy designation.
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'OpportunityLegacy',

  props: {
    opportunity: {
      type: Object,
      required: true
    },

    publishedAt: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      sites: {
        onsite: 'On-Site',
        remote: 'Remote',
        hybrid: 'Hybrid'
      }
    };
  },

  computed: {
    opportunityType() {
      return this.sites[this.opportunity.site] || this.opportunity.site;
    },

    questions() {
      return this.opportunity.opportunityQuestions;
    },

    publishedDate() {
      return DateTime.fromISO(this.publishedAt).toLocaleString(DateTime.DATE_SHORT);
    }
  }
};
</script>
