<template>
  <div class="vertical-center scout-tag-row">
    <div class="scout-tag-editing-col">
      <div class="row tight-columns vertical-center-not-xs semibold-weight">
        <div v-if="canReorder" class="col-sm-9">
          <span class="vertical-middle right-10"><svg-icon name="drag-and-drop" class="base-icon"></svg-icon></span>
          <span class="vertical-middle">{{ scoutTag.position }}.<scout-tag class="left-5" :scout-tag="scoutTag"></scout-tag></span>
        </div>
        <div v-else class="col-sm-9">
          <scout-tag class="wrappable-tag" :scout-tag="scoutTag"></scout-tag>
        </div>

        <div class="col-sm-3 text-right-not-xs">
          <scout-tag-editing-modal
            class="top-20-xs"
            :scout-tag="scoutTag"
            :on-update="onUpdate"
            :practice-areas="practiceAreas">
          </scout-tag-editing-modal>
        </div>
      </div>
    </div>

    <scout-tag-delete-modal
      class="left-20"
      :scout-tag="scoutTag"
      :on-delete="onDelete">
    </scout-tag-delete-modal>
  </div>
</template>

<script>
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import ScoutTagEditingModal from 'vue-app/scout/client/settings/scout-tag-editing-modal.vue';
import ScoutTagDeleteModal from 'vue-app/scout/client/settings/scout-tag-delete-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ScoutTagEditingRow',

  components: {
    ScoutTag,
    ScoutTagEditingModal,
    ScoutTagDeleteModal,
    SvgIcon
  },

  props: {
    scoutTag: {
      type: Object,
      required: true
    },

    onUpdate: {
      type: Function,
      required: true
    },

    onDelete: {
      type: Function,
      required: true
    },

    canReorder: {
      type: Boolean,
      default: true
    },

    practiceAreas: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .wrappable-tag {
    display: block;
    text-wrap: auto;
    width: fit-content;
  }

  .scout-tag-row {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }

  .scout-tag-editing-col {
    width: 100%;
    padding: 15px 10px;
    border: 1px solid $k-gray;
    border-radius: $border-radius-base;
    line-height: 1.3;

    @media (min-width: $screen-sm-min) {
      padding: 15px 20px 15px 15px;
    }
  }
</style>
