<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': canEditProfile }]">
          Leadership &amp; Billable Credit Initiatives
        </div>

        <div class="col-sm-3 text-right-not-xs" v-if="canEditProfile">
          <edit-firm-leadership
            :firm="firm"
            :billable-display-data="billableDisplayData"
            :leadership-display-data="leadershipDisplayData"
            :on-save="save"
            :tag-present="tagPresent">
          </edit-firm-leadership>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="firm-leadership">
        <section-wrapper class="separated-list-items" :data="firm.billableCreditTaggings" :editing-allowed="canEditProfile">
          <div class="bold-weight bottom-10">
            Billable Credit Initiatives:
          </div>

          <data-wrapper :data="firm.billableCreditTaggings">
            <div v-for="(tagData, internalName) in billableDisplayData" :key="internalName">
              <div class="panel-item bottom-10 left-15-not-xs" v-if="tagPresent(internalName)">
                <div>
                  {{ title(internalName) }}
                </div>

                <div>
                  Yes
                </div>
              </div>
            </div>
          </data-wrapper>
        </section-wrapper>

        <section-wrapper class="separated-list-items" :data="firm.leadershipTaggings" :editing-allowed="canEditProfile">
          <div class="bold-weight bottom-10">
            Dedicated Staff:
          </div>

          <data-wrapper :data="firm.leadershipTaggings">
            <div v-for="(tagData, internalName) in leadershipDisplayData" :key="internalName">
              <div class="panel-item bottom-10 left-15-not-xs" v-if="tagPresent(internalName)">
                <div>
                  {{ title(internalName) }}
                </div>

                <div>
                  Yes
                </div>
              </div>
            </div>
          </data-wrapper>
        </section-wrapper>
      </loading-section>
    </div>
  </div>
</template>

<script>
import { concat } from 'lodash';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditFirmLeadership from './edit-firm-leadership';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'FirmLeadership',

  components: {
    SectionWrapper,
    DataWrapper,
    EditFirmLeadership
  },

  mixins: [displayRules],

  props: {
    firm: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      billableDisplayData: {
        BC01: 'Pro Bono',
        BC02: 'Community Service',
        BC03: 'Innovation Initiatives',
        BC04: 'Diversity & Inclusion Work'
      },

      leadershipDisplayData: {
        FL01: 'Diversity Program/Initiative',
        FL02: 'Pro Bono Program/Initiative',
        FL03: 'Discovery/Legal Technology Practice',
        FL04: 'Innovation Program/Initiative'
      }
    };
  },

  computed: {
    concatenatedData: function () {
      return concat(this.firm.billableCreditTaggings, this.firm.leadershipTaggings);
    },

    displayComponent: function () {
      return this.anyDataIsPresent([this.firm.leadershipTaggings, this.firm.billableCreditTaggings]) || this.canEditProfile;
    },

    displayData: function () {
      return {
        ...this.leadershipDisplayData,
        ...this.billableDisplayData
      };
    }
  },

  methods: {
    title: function (internalName) {
      return this.displayData[internalName];
    },

    tagPresent(internalName) {
      return this.concatenatedData.includes(internalName);
    },

    save: function (firmEdited, success) {
      if (!this.canEditProfile) { return; }

      LoadingService.loading('firm-leadership');
      this.onSave(firmEdited,
        () => {
          LoadingService.done('firm-leadership');
          if (success) { success(); }
        },
        () => {
          LoadingService.done('firm-leadership');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  div {
    line-height: 1.4;
  }

  .panel-item {
    column-gap: 10px;
    display: flex;
    justify-content: space-between;
  }
</style>
