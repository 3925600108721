<template>
  <div>
    <div @click.prevent="openModal">
      <slot></slot>
    </div>

    <modal v-model="modalOpen" size="md" :header="false" :footer="false" :backdrop="false" append-to-body>
      <modal-close-button :on-dismiss="cancel"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-regular-text extra-padded">
          <validation-observer v-slot="{ handleSubmit }">
            <form role="form" name="form" class="a-form" novalidate>
              <div class="bottom-30">
                <div class="yellow-message-box bottom-20">
                  <svg-icon name="exclamation" class="base-icon warning-icon right-10"></svg-icon>
                  <span class="message">Warning: You are about to impersonate a user.</span>
                </div>

                <div class="bottom-15">
                  Impersonating users allows you to see and modify user data, some of which may be sensitive. This is a powerful tool, and should only be used as a measure of last resort in conjunction with the direction of your supervisor.
                </div>

                <div class="bottom-30">
                  If you wish to proceed, please provide the reason you are impersonating this user below. Please note that the reason will be logged, along with your session and viewable by senior admins.  You will be logged out of impersonation mode after 1 hour. If you need more time, you can impersonate again.
                </div>

                <div>
                  <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                    <label for="reason">
                      Select an impersonation reason <span class="inline-help required">*</span>
                    </label>

                    <dropdown-select
                      ref="dropdown"
                      id-label="reason"
                      v-model="reason"
                      :options="reasonOptions"
                      value-key="id"
                      label-key="name"
                      @change="changeReason"
                      :invalid="errors.length > 0">
                    </dropdown-select>
                  </validation-provider>
                </div>

                <div class="top-20" v-if="isOtherReasonSelected">
                  <validation-provider :rules="validationRules" :mode="passiveAggressive" v-slot="{ errors }">
                    <label for="other-reason">
                      Enter an impersonation reason <span class="inline-help required">*</span>
                    </label>

                    <input type="text" ref="otherReason" name="otherReason" id="other-reason" v-model="otherReason" :class="['form-control', { 'has-error': errors.length }]" autocomplete="off" maxlength="150" placeholder="e.g. “I am helping a lawyer edit their info”">
                  </validation-provider>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <loading-button name="submit" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(save)">Impersonate</loading-button>
                </div>

                <div class="col-sm-6 col-md-6 top-10-xs">
                  <button type="button" class="secondary-btn-blue a-button-size" @click.prevent="cancel">Cancel</button>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import ImpersonationLog from 'resources/admin/impersonation-log';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'ImpersonateModal',

  components: {
    ModalCloseButton,
    SvgIcon,
    DropdownSelect,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    modalToggle,
    interactionModes
  ],

  props: {
    user: {
      type: Object,
      required: true
    },

    target: {
      type: String,
      default: '_self'
    },

    modalTrigger: {
      type: Boolean,
      default: false
    },

    onCancel: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      otherReason: null,
      reason: null,
      reasonOptions: [
        'Updating Profile Info',
        'User Guidance & Troubleshooting',
        'Billing / Invoice Support',
        'Project Support',
        'Other'
      ]
    };
  },

  computed: {
    validationRules() {
      return this.isOtherReasonSelected ? 'required' : null;
    },

    isOtherReasonSelected() {
      return this.reason === 'Other';
    },

    isFormValid() {
      if (this.reason === null) {
        return false;
      }
      else if (this.isOtherReasonSelected) {
        return this.otherReason && this.otherReason.trim().length;
      }
      else {
        return true;
      }
    }
  },

  watch: {
    modalTrigger(open) {
      if (open) {
        this.openModal();
      }
      else {
        this.closeModal();
      }
    }
  },

  methods: {
    changeReason() {
      if (!this.isOtherReasonSelected) {
        this.otherReason = null;
      }
    },

    reset() {
      this.$refs.dropdown.reset();
      this.otherReason = null;
      this.reason = null;

      LoadingService.done('submit');
    },

    save() {
      const impersonationLog = {
        userId: this.user.id,
        userType: this.user.klass,
        reason: this.otherReason ? `Other - ${this.otherReason}` : this.reason
      };

      LoadingService.loading('submit');

      ImpersonationLog.save({ impersonationLog: impersonationLog })
        .then((response) => {
          this.cancel();
          window.open(response.url, this.target);
        })
        .finally(() => {
          LoadingService.done('submit');
        });
    },

    cancel() {
      this.closeModal();
      this.reset();
      this.onCancel();
    }
  }
};
</script>
