<template>
  <form role="form" name="form" class="a-form" novalidate>
    <label :for="`${question.key}-input`">{{ formLabel }}</label>

    <div class="row bottom-20">
      <div class="col-sm-7">
        <div class="row tight-columns">
          <div class="col-xs-8">
            <input type="text" :name="`${question.key}Input`" :id="`${question.key}-input`" class="form-control" placeholder="Add a term" autocomplete="nope" v-model="newOption">
          </div>

          <div class="col-xs-4">
            <button type="button" :id="`${question.key}-add`" class="nv-button-blue a-button-size" @click="addOption" :disabled="!canAddOption">Add</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { some, startCase } from 'lodash';

export default {
  name: 'ScreeningTermAdd',

  props: {
    question: {
      type: Object,
      required: true
    },

    onAdd: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      newOption: ''
    };
  },

  computed: {
    formLabel() {
      return startCase(this.question.key);
    },

    optionAlreadyPresent() {
      return some(this.question.value.options, (option) => {
        return option.toLowerCase() === this.newOption.toLowerCase();
      });
    },

    canAddOption() {
      return this.newOption.length && !this.optionAlreadyPresent;
    }
  },

  methods: {
    addOption() {
      if (!this.canAddOption) { return; }

      this.onAdd(this.question, this.newOption);
      this.newOption = '';
    }
  }
};
</script>
