<template>
  <div class="row md-columns vertical-center-not-xs">
    <div class="col-sm-5 col-md-4 col-lg-3">
      <form role="form" name="filterForm" class="a-form" novalidate>
        <typeahead-vertical
          id="typeahead-filter"
          ref="typeaheadFilter"
          :placeholder="placeholderText || 'Search...'"
          :options="options"
          :option-label-key="optionLabelKey"
          :option-value-key="optionValueKey"
          :force-select="true"
          :disabled="isApplying"
          v-model="selectedOption"
          @input="selectOption">
        </typeahead-vertical>
      </form>
    </div>

    <div class="col-sm-7 top-10-xs" v-if="!isApplying">
      <span class="semibold-weight">{{ resultsCount }}</span>
      {{ resultsCount === 1 ? 'result' : 'results' }}

      <span :class="{ 'left-10': resultsCount }" v-if="selectedOption">
        <a href="" @click.prevent="resetFilter">(Clear Search)</a>
      </span>
    </div>
  </div>
</template>

<script>
import TypeaheadVertical from 'src/vue-app/shared/components/typeahead-vertical.vue';

export default {
  components: {
    TypeaheadVertical
  },

  props: {
    options: {
      type: Array,
      required: true
    },

    optionLabelKey: {
      type: String,
      required: false
    },

    optionValueKey: {
      type: String,
      required: false
    },

    placeholderText: {
      type: String,
      default: ''
    },

    resultsCount: {
      type: Number,
      default: 0
    },

    onApplyFilter: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedOption: null,
      isApplying: false
    };
  },

  methods: {
    selectOption() {
      this.isApplying = true;

      this.applyFilter();
    },

    resetFilter() {
      this.$refs.typeaheadFilter.reset();
      this.selectedOption = null;

      this.applyFilter();
    },

    applyFilter() {
      const vueInstance = this;

      this.onApplyFilter(this.selectedOption).then(() => {
        vueInstance.isApplying = false;
      });
    }
  }
};
</script>
