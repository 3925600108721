<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Additional Project Details
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <a :href="editPath" class="size-text-13px normal-weight" target="_blank">Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Project Type
        </div>

        <div class="col-sm-8">
          {{ projectTypeString || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30" v-if="fromInteractive && peopleRequest">
        <div class="col-sm-4 semibold-weight">
          Duration
        </div>

        <div class="col-sm-8">
          <span>{{ counselRequestDuration || '&mdash;' }}</span>
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Time Commitment
        </div>

        <div class="col-sm-8">
          {{ counselRequest.timeCommitment || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Add'l Time Info
        </div>

        <div class="col-sm-8">
          {{ counselRequest.timeCommitmentDetails || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Est. Start Date
        </div>

        <div class="col-sm-8">
          {{ counselRequestStartDate || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Est. End Date
        </div>

        <div class="col-sm-8">
          {{ endDate || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          On-site Required?
        </div>

        <div class="col-sm-8 text-capitalize">
          {{ counselRequest.isOnsite || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30" v-if="fromInteractive && peopleRequest">
        <div class="col-sm-4 semibold-weight">
          On-site Details
        </div>

        <div class="col-sm-8">
          {{ counselRequest.onsiteDetails || '&mdash;' }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          On-site Location
        </div>

        <div class="col-sm-8">
          {{ counselRequest.onsiteLocation || '&mdash;' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';

export default {
  name: 'RequestDetails',

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  },

  computed: {
    editPath: function () {
      return `/law_simplified/counsel_requests/${this.counselRequest.id}/edit/#project-details`;
    },

    fromInteractive: function () {
      return this.counselRequest.fromInteractive();
    },

    projectType: function () {
      return this.counselRequest.projectType;
    },

    peopleRequest: function () {
      if (!this.projectType) { return null; }

      return this.projectType.slug !== 'advisory';
    },

    projectTypeString: function () {
      if (!this.projectType) { return null; }

      return this.peopleRequest ? 'People' : 'Project';
    },

    startDate: function () {
      return DateFilter.filter(this.counselRequest.startDate);
    },

    endDate: function () {
      return DateFilter.filter(this.counselRequest.endDate);
    },

    counselRequestDuration: function () {
      if (this.counselRequest.isOngoing) {
        return 'Ongoing Engagement';
      }
      else {
        return this.counselRequest.duration;
      }
    },

    counselRequestStartDate: function () {
      if (this.counselRequest.isAsap) {
        return 'As Soon As Possible';
      }
      else {
        return this.startDate;
      }
    }
  }
};
</script>
