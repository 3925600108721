<template>
  <dropdown-menu width="larger" icon-name="caret-down">
    <li class="menu-item" role="menuitem">
      <a href="" @click.prevent="messageLawyer"><svg-icon name="message" class="base-icon"></svg-icon>Message Lawyer</a>
    </li>

    <li class="menu-item" role="menuitem" v-if="canAddToProposal">
      <a href="" @click.prevent="addLawyerFirmToProposal"><svg-icon name="add-item" class="base-icon"></svg-icon>Add Law Firm</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a href="" @click.prevent="impersonate"><svg-icon name="profile" class="base-icon"></svg-icon>Impersonate</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a :href="`/admin-app/lawyers/${lawyer.id}/projects/new`" target="_self"><svg-icon name="writing" class="base-icon"></svg-icon>Open Project (Silent)</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a :href="`/admin-app/lawyers/${lawyer.id}/review`"><svg-icon name="ribbon" class="base-icon"></svg-icon>Add Review</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a :href="`/admin-app/lawyers/${lawyer.id}/application/${lawyer.lawyerApplicationId}`"><svg-icon name="resume" class="base-icon"></svg-icon>View Application</a>
    </li>
  </dropdown-menu>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';

export default {
  name: 'AccountActions',

  components: {
    DropdownMenu
  },

  props: {
    messageLawyer: {
      type: Function,
      required: true
    },

    addLawyerFirmToProposal: {
      type: Function,
      required: true
    },

    impersonate: {
      type: Function,
      required: true
    },

    lawyer: {
      type: Object,
      required: true
    },

    canAddToProposal: {
      type: Boolean,
      default: false
    }
  }
};
</script>
