const ListToggle = {
  data: function () {
    return {
      showAll: false,
      collapsedShowCount: 5
    };
  },

  computed: {
    dataToDisplay: function () {
      return this.showAll ? this.listData : this.listData.slice(0, this.collapsedShowCount);
    }
  },

  methods: {
    toggleFullData: function () {
      this.showAll = !this.showAll;
    }
  }
};

export default ListToggle;
