import { render, staticRenderFns } from "./welcome-banner.vue?vue&type=template&id=d354d3ea&scoped=true"
import script from "./welcome-banner.vue?vue&type=script&lang=js"
export * from "./welcome-banner.vue?vue&type=script&lang=js"
import style0 from "./welcome-banner.vue?vue&type=style&index=0&id=d354d3ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d354d3ea",
  null
  
)

export default component.exports