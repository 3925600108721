<template>
  <div>
    <slot name="header">
      <div v-if="header" class="header">
        {{ header }}
      </div>
    </slot>

    <slot name="subHeader">
      <div v-if="subHeader" class="subheader">
        <i>{{ subHeader }}</i>
      </div>
    </slot>

    <slot name="input">
      <div class="row bottom-10">
        <div class="col-sm-6">
          <add-tag-input ref="addTagInput" :on-add="onAdd"></add-tag-input>
        </div>
      </div>
    </slot>

    <slot name="tag-list">
      <div>
        <tag-list :tags="arrayData" :value-fn="tagValueFn" :on-remove="onRemove"></tag-list>
      </div>
    </slot>
  </div>
</template>

<script>
import AddTagInput from 'vue-app/scout/shared/add-tag-input.vue';
import TagList from 'vue-app/scout/shared/tag-list.vue';

export default {
  name: 'InputWithTagList',

  components: {
    AddTagInput,
    TagList
  },

  props: {
    header: {
      type: String,
      default: ''
    },

    subHeader: {
      type: String,
      default: ''
    },

    arrayData: {
      type: Array,
      required: true
    },

    tagValueFn: {
      type: Function,
      default: null
    }
  },

  methods: {
    onAdd(text) {
      this.$emit('add-string', text);
    },

    onRemove(index) {
      this.$emit('remove-string', index);
    },

    resetTag() {
      this.$refs.addTagInput.resetTag();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .header {
    border-bottom: 1px solid $k-light-gray;
    font-weight: 700;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  .subheader {
    color: $pill-gray;
    font-size: 12px;
    margin-bottom: 15px;
  }
</style>
