<template>
  <div>
    <pill-switcher
      ref="pillSwitcher"
      :default-option-value="defaultOptionValue"
      :options="options"
      :on-select="askForConfirmation">
      <template #note>
        <slot name="note"></slot>
      </template>
    </pill-switcher>

    <inline-confirmation
      v-if="renderConfirmation"
      class="top-15"
      @change="confirm"
      :buttons="[
        { label: 'Yes', value: true, class: 'secondary-btn-blue' },
        { label: 'No', value: false, class: 'primary-btn-white' }
      ]">
      <slot name="confirmation-message"></slot>
    </inline-confirmation>
  </div>
</template>

<script>
import PillSwitcher from 'vue-app/shared/components/pill-switcher.vue';
import InlineConfirmation from 'src/vue-app/scout/shared/inline-confirmation.vue';

export default {
  name: 'PillSwitcherWithConfirmation',

  components: {
    InlineConfirmation,
    PillSwitcher
  },

  props: {
    options: {
      type:     Array,
      required: true
    },

    defaultOptionValue: {
      type:     String,
      default:  null
    },

    onSelect: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      optionToConfirm: null,
      renderConfirmation: false
    };
  },

  methods: {
    askForConfirmation(option) {
      this.optionToConfirm    = option;
      this.renderConfirmation = true;
    },

    confirm(confirmed) {
      this.renderConfirmation = false;

      if (confirmed) {
        this.onSelect(this.optionToConfirm);
      }
      else {
        this.$refs.pillSwitcher.select(this.$refs.pillSwitcher.previousOption, false);
      }
    },

    reset() {
      this.optionToConfirm = null,
      this.renderConfirmation = false;
      this.$refs.pillSwitcher.reset();
    }
  }
};
</script>
