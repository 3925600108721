<template>
  <div class="shadowed-box">
    <div class="box-content">
      <div class="bottom-15">
        <div class="semibold-weight">
          Impersonate Client
        </div>

        <div>
          <impersonate-modal
            :user="client"
            target="_self">
            <a href="">Impersonate</a>
          </impersonate-modal>
        </div>
      </div>

      <div>
        <div class="semibold-weight">
          Message Client
        </div>

        <div>
          <a href="" @click.prevent="messageClient">Send Message</a>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="bottom-15">
        <div class="semibold-weight">
          Client Since
        </div>

        <div>
          {{ formattedDate(client.createdAt) }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Account Confirmed
        </div>

        <div>
          {{ formattedDate(client.confirmedAt) }}
          <a href="" @click.prevent="onComposeEmail" v-if="!client.confirmedAt">Resend Instructions</a>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Account Type
        </div>

        <div>
          {{ client.userType }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Salesforce Contact ID
        </div>

        <div>
          <a :href="`https://priori.my.salesforce.com/${client.contactId}`" target="_blank" v-if="client.contactId">{{ client.contactId }}</a>
          <span v-else>&mdash;</span>
        </div>
      </div>

      <div>
        <div class="semibold-weight">
          Salesforce Lead ID
        </div>

        <div>
          <a :href="`https://priori.my.salesforce.com/${client.leadId}`" target="_blank" v-if="client.leadId">{{ client.leadId }}</a>
          <span v-else>&mdash;</span>
        </div>
      </div>
    </div>

    <div class="box-footer text-center">
      <a :href="`/law_simplified/clients/${client.id}/edit`" class="size-text-13px normal-weight" target="_blank">Edit</a>
    </div>
  </div>
</template>

<script>
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import NullableValueFilter from 'vue-app/shared/lib/nullable-value-filter.js';

export default {
  name: 'ClientAccountStatus',

  components: {
    ImpersonateModal
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    onComposeEmail: {
      type: Function,
      required: true
    },

    sendMessageService: {
      type: Object,
      required: true
    }
  },

  methods: {
    messageClient() {
      return this.sendMessageService.modal([this.client]);
    },

    formattedDate(date) {
      return this.valueOrDefault(DateFilter.filter(date));
    },

    valueOrDefault(value) {
      return NullableValueFilter.defaultValue(value);
    }
  }
};
</script>
