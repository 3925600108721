<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label :for="id">{{ label }} <span class="red-text" v-if="required">*</span></label>

    <select :id="id" :class="['form-control', { 'has-error': errors.length }]" @change="onChange($event.target.value)" :value="value">
      <option disabled selected value="">
        {{ placeholder }}
      </option>

      <option v-for="year in years" :value="year" :key="year" :selected="year == value">
        {{ year }}
      </option>
    </select>

    <div class="error-text top-5" v-if="errors.length">
      {{ errors[0] }}
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes from '../mixins/interaction-modes';

export default {
  name: 'YearSelectVertical',

  components: {
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    id: {
      type: String,
      required: false
    },

    label: {
      type: String,
      required: true
    },

    placeholder: {
      type: String,
      required: false
    },

    value: {
      type: Number,
      default: null
    },

    endingYear: {
      type: Number,
      default: new Date().getFullYear()
    },

    startingYear: {
      type: Number,
      default: new Date().getFullYear() - 100
    },

    rules: {
      type: String,
      default: null
    }
  },

  computed: {
    required: function () {
      return this.rules?.includes('required');
    },

    years: function () {
      return Array.from({ length: (this.endingYear - this.startingYear) + 1 }, (_, i) => this.endingYear - i);
    }
  },

  methods: {
    onChange: function (value) {
      this.$emit('input', _.toNumber(value));
    }
  }
};
</script>
