<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div :class="viewingAsClient ? 'col-sm-5' : 'col-sm-4'">
      <suggested-lawyer-name-plate :suggested-lawyer="suggestedLawyer"></suggested-lawyer-name-plate>
    </div>

    <div :class="[(viewingAsClient ? 'col-sm-4' : 'col-sm-5'), 'missing-text size-text-12px tight-lines top-10-xs']">
      {{ presenceText }}
    </div>

    <div class="col-sm-3 text-right-not-xs">
      {{ formattedRate }}
    </div>
  </div>
</template>

<script>
import SuggestedLawyerNamePlate from 'vue-app/scout/shared/rfqs/suggested-lawyer-nameplate.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'SuggestedLawyer',

  components: {
    SuggestedLawyerNamePlate
  },

  props: {
    suggestedLawyer: {
      type: Object,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    suggestedByClient() {
      return this.suggestedLawyer.suggestedBy === 'client';
    },

    suggestedByPriori() {
      return this.suggestedLawyer.suggestedBy === 'priori';
    },

    includedInQuote() {
      return this.suggestedLawyer.includedInQuote;
    },

    clientText() {
      return this.viewingAsClient ? 'you' : 'client';
    },

    firmText() {
      return this.viewingAsClient ? 'law firm' : 'you';
    },

    presenceText() {
      if (this.suggestedByPriori) {
        return 'Marketplace';
      }
      else if (!this.suggestedByClient) {
        return `Suggested by ${this.firmText}`;
      }
      else if (this.includedInQuote) {
        return `Suggested by ${this.clientText} and ${this.firmText} agreed`;
      }
      else {
        return `Suggested by ${this.clientText}; ${this.firmText} marked not a good fit for this project`;
      }
    },

    rate() {
      return this.suggestedLawyer.proposedHourlyRate;
    },

    formattedRate() {
      return this.rate ? `${CurrencyFilter.filter(this.rate)}/hr` : '—';
    }
  }
};
</script>
