<template>
  <div class="size-text-13px">
    <div class="size-text-18px">
      {{ reference.response.contact.firstName }} {{ reference.response.contact.lastName }}
    </div>

    <hr class="kenny-hr">

    <div class="row bottom-10">
      <div class="col-sm-4 semibold-weight">
        Email
      </div>

      <div class="col-sm-8 text-ellipsis">
        {{ reference.response.contact.email }}
      </div>
    </div>

    <div class="row bottom-10">
      <div class="col-sm-4 semibold-weight">
        Phone
      </div>

      <div class="col-sm-8 text-ellipsis">
        {{ reference.response.contact.phone }}
      </div>
    </div>

    <div class="row bottom-10">
      <div class="col-sm-4 semibold-weight">
        Company/Firm
      </div>

      <div class="col-sm-8 text-ellipsis">
        {{ reference.response.contact.company }}
      </div>
    </div>

    <div class="row bottom-10">
      <div class="col-sm-4 semibold-weight">
        Title
      </div>

      <div class="col-sm-8 text-ellipsis">
        {{ reference.response.contact.title }}
      </div>
    </div>

    <div class="row bottom-10" v-if="reference.relationship">
      <div class="col-sm-4 semibold-weight">
        Relationship
      </div>

      <div class="col-sm-8 text-ellipsis">
        {{ reference.relationship }}
      </div>
    </div>

    <div class="row bottom-10">
      <div class="col-sm-4 semibold-weight">
        Responded
      </div>

      <div class="col-sm-8 text-ellipsis">
        <span v-if="reference.respondedOn">{{ respondedOn }}</span>
        <span v-else>&mdash;</span>
      </div>
    </div>

    <hr class="kenny-hr">

    <div class="left-20 list-spacing-20" v-for="(question, index) in reference.response.questions">
      <label class="numbered-label semibold-weight">
        <span class="label-number">{{ index + 1 }}.</span>
        <span class="label-content">{{ question.question }}</span>
      </label>

      <div class="left-30">
        {{ question.answer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferenceDetail',

  props: {
    reference: {
      type: Object,
      required: true
    }
  },

  computed: {
    respondedOn() {
      let date = this.reference.respondedOn.split('-');
      return [date[1], date[2], date[0]].join('/');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
