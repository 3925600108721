<template>
  <div>
    <div class="breadcrumbs-container with-buttons">
      <div class="container">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-xs-12 col-sm-8 col-md-9">
            <a :href="`/scout-company/law-firms/${firmBridge.id}/reviews`" class="back-link" target="_self"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Cancel & Exit</a>
          </div>

          <div class="col-xs-10 col-sm-4 col-md-3 top-20-xs">
            <loading-button name="saveDraft" :lb-class="['secondary-btn-blue transparent', { 'loading': isSavingDraft }]" @lb-click="saveDraft">Save & Complete Later</loading-button>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-4">
              <firm-card :firm="firmBridge"></firm-card>
            </div>

            <div class="col-sm-8 top-30-xs">
              <div class="shadowed-box">
                <div class="box-content">
                  <div class="size-text-24px bottom-10">
                    Submit a Law Firm Review
                  </div>

                  <div class="bottom-30">
                    {{ instructions }}
                  </div>

                  <firm-review-form
                    :firm="firmBridge"
                    :review="review"
                    :practice-area-names="practiceAreaNames"
                    :scout-matters="scoutMatters"
                    :template="template"
                    :create-review="createReview">
                  </firm-review-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirmCard from 'vue-app/scout/client/reviews/firm-card.vue';
import FirmReviewForm from 'vue-app/scout/client/reviews/firm-review-form.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ScoutReview from 'resources/scout/scout-review.js';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { map, uniq } from 'lodash';

export default {
  name: 'ReviewsNewLawFirm',

  components: {
    FirmCard,
    FirmReviewForm,
    LoadingButton,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      firmBridge: this.initData.firmBridge,
      template: this.initData.template,
      practiceAreaNames: [],
      scoutMatters: [],
      review: new ScoutReview(
        {
          resourceType: 'Scout::LawFirmBridge',
          resourceId: this.initData.firmBridge.id,
          scoutMatterId: this.initData.selectedMatterId
        },
        this.initData.template
      )
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    instructions() {
      if (this.currentProduct.isFeatureLab) {
        return 'Only your colleagues may access your reviews on Feature Lab. No users outside of your company have access to your reviews on the platform.';
      }
      else {
        return 'Only your colleagues may access your reviews on Scout. No Scout users outside of your company have access to your reviews on the platform.';
      }
    },

    isSavingDraft() {
      return LoadingService.isLoading('saveDraft');
    }
  },

  mounted() {
    this.currentProduct.queryPracticeAreas().then((practiceAreas) => {
      this.practiceAreaNames = uniq(map(practiceAreas, 'name')).sort();
    });

    if (this.currentProduct.isScout) {
      LoadingService.loading('scoutMatters');
      ScoutFirmBridge.scoutMatters(this.firmBridge.id).then((matters) => {
        this.scoutMatters = matters;
        LoadingService.done('scoutMatters');
      });
    }
  },

  methods: {
    saveDraft() {
      LoadingService.loading('saveDraft');

      this.review.save({ publish: false })
        .then(() => {
          window.location = '/scout-company/reviews/drafts';
        })
        .finally(() => {
          LoadingService.done('saveDraft');
        });
    },

    createReview() {
      LoadingService.loading('submitReview');

      this.review.save({ publish: true })
        .then(() => {
          window.location = `/scout-company/reviews?showReviewSuccessModal=true&submittedReviewId=${this.review.id}`;
        })
        .catch(() => {
          NotificationService.error('Something went wrong while submitting your review.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() => {
          LoadingService.done('submitReview');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .secondary-btn-blue.transparent.loading {
    color: $white;
    background-color: $k-blue;
  }
</style>
