<template>
  <div class="row tight-columns">
    <div class="col-sm-4 clickable" @click="viewLawyer">
      <lawyer-name-plate :lawyer="lawyer" :hide-firm="true"></lawyer-name-plate>
    </div>

    <div class="col-sm-7">
      <div class="row match-row tight-columns vertical-center-not-xs">
        <div class="col-sm-4 text-center">
          <match-tag :score="lawyer.matchScore" v-if="!searchIsClear"></match-tag>
          <span v-else>&mdash;</span>
        </div>

        <div class="col-sm-8 text-center">
          {{ location || '&mdash;' }}
        </div>
      </div>
    </div>

    <div class="col-sm-1 text-right-not-xs">
      <dropdown-menu :compact="true" class="top-5">
        <li class="menu-item" role="menuitem">
          <a :href="`/scout-firm/clients/${workspace.id}/lawyers/${lawyer.id}`" target="_blank">View Profile</a>
        </li>

        <li class="menu-item" role="menuitem">
          <modal-add-to-list
            :resource-lists="resourceLists"
            :resource="lawyer"
            :on-create-list="onCreateList"
            :on-add-to-list="onAddToList">
          </modal-add-to-list>
        </li>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import MatchTag from 'vue-app/scout/shared/match-tag.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import ModalAddToList from 'vue-app/scout/shared/searches/modal-add-to-list.vue';

export default {
  name: 'SearchLawyerScoped',

  components: {
    LawyerNamePlate,
    MatchTag,
    DropdownMenu,
    ModalAddToList
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    lawyer: {
      type: Object,
      required: true
    },

    onView: {
      type: Function,
      required: true
    },

    searchIsClear: {
      type: Boolean,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    onCreateList: {
      type: Function,
      required: true
    },

    onAddToList: {
      type: Function,
      required: true
    }
  },

  computed: {
    location() {
      if (!this.lawyer.address) { return null; }

      return this.lawyer.address.state || this.lawyer.address.country;
    }
  },

  methods: {
    viewLawyer() {
      this.onView(this.lawyer);
    }
  }
};
</script>
