<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Credits
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <a :href="`/admin-app/clients/${client.id}/new-credit`" class="size-text-13px normal-weight">New Credit</a>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical" id="client-credits">
      <table class="table auto-height no-margin" v-if="hasCredits">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Remaining</th>
            <th class="text-right">Management Fee Only</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="credit in client.credits" :key="credit.id">
            <td>{{ formattedCurrency(credit.dollars) }}</td>
            <td>{{ formattedCurrency(credit.unusedDollars) }}</td>
            <td class="text-right">{{ credit.managementFeeOnly ? 'Yes' : '' }}</td>
          </tr>
        </tbody>
      </table>

      <div class="size-text-base gray-text text-center" v-if="!hasCredits">
        No credits available
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from 'vue-app/shared/mixins/currency-filter.js';

export default {
  name: 'ClientCredits',

  mixins: [currencyFilter],

  props: {
    client: {
      type:     Object,
      required: true
    }
  },

  computed: {
    hasCredits() {
      return this.client.credits && this.client.credits.length > 0;
    }
  }
};
</script>
