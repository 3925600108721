import Opportunity from 'src/resources/opportunity.js';
import { without, isEmpty } from 'lodash';
import Vue from 'vue';

class OpportunitiesSearchService {
  constructor() {
    this.initialize();
  }

  initialize() {
    const opportunitiesSearchData = this.getSessionStorage();

    this.searchLogic          = opportunitiesSearchData?.searchLogic || { filters: {} };
    this.selectedFilterCounts = opportunitiesSearchData?.selectedFilterCounts || {};
    this.dirty                = opportunitiesSearchData?.dirty || false;

    this.setSessionStorage();
  }

  applySearch() {
    this.updateSelectedFilterCounts();

    if (!isEmpty(this.searchLogic.filters)) { this.dirty = true; }

    this.setSessionStorage();

    return Opportunity.query({ search_params: JSON.stringify(this.searchLogic) });
  }

  addSearchParam(field, searchParam) {
    const previousValues = this.searchLogic.filters[field] || [];
    Vue.set(this.searchLogic.filters, field, [...previousValues, searchParam]);
  }

  removeSearchParam(field, searchParam) {
    Vue.set(this.searchLogic.filters, field, without(this.searchLogic.filters[field], searchParam));

    if (this.searchLogic.filters[field].length === 0) {
      this.unsetSearchParam(field);
    }
  }

  unsetSearchParam(field) {
    Vue.delete(this.searchLogic.filters, field);
  }

  resetSearch() {
    this.clearSessionStorage();
    this.initialize();
  }

  updateSelectedFilterCounts() {
    Object.keys(this.searchLogic.filters).forEach(field => {
      Vue.set(this.selectedFilterCounts, field, this.searchLogic.filters[field].length);
    });

    Object.keys(this.selectedFilterCounts).forEach(field => {
      if (!this.searchLogic.filters[field]) {
        Vue.delete(this.selectedFilterCounts, field);
      }
    });
  }

  selectedOptionsCount(field) {
    return this.selectedFilterCounts[field] || 0;
  }

  setSessionStorage() {
    const opportunitiesSearchData = {
      searchLogic: this.searchLogic,
      selectedFilterCounts: this.selectedFilterCounts,
      dirty: this.dirty
    };

    sessionStorage.setItem('opportunitiesSearchData', JSON.stringify(opportunitiesSearchData));
  }

  getSessionStorage() {
    return JSON.parse(sessionStorage.getItem('opportunitiesSearchData'));
  }

  clearSessionStorage() {
    sessionStorage.removeItem('opportunitiesSearchData');
  }
}

export default OpportunitiesSearchService;
