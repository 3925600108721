<template>
  <div>
    <div class="titlebar-sm">
      <div class="medium-page-heading top-2">
        Account Settings
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row" v-if="authenticatable">
            <div class="col-sm-8">
              <two-factor-authentication-settings
                :authenticatable="authenticatable"
                :send-one-time-password="sendOneTimePassword"
                :password-authentication="passwordAuthentication"
                :verify-otp="verifyOtp"
                :save-changes="saveChanges">
              </two-factor-authentication-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoFactorAuthenticationSettings from 'vue-app/shared/components/two-factor-authentication/two-factor-authentication-settings.vue';

export default {
  name: 'AdminSettings',

  components: {
    TwoFactorAuthenticationSettings
  },

  props: {
    authenticatable: {
      type: Object,
      required: true
    },

    sendOneTimePassword: {
      type: Function,
      required: true
    },

    passwordAuthentication: {
      type: Function,
      required: true
    },

    verifyOtp: {
      type: Function,
      required: true
    },

    saveChanges: {
      type: Function,
      required: true
    }
  }
};
</script>
