<template>
  <div class="shadowed-box">
    <div v-if="showTemplateHeading" class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-8 text-uppercase">
          Template Questions
        </div>

        <div class="col-sm-4 text-right-not-xs" v-if="!isNew && !isStandardTemplate">
          <div v-if="localIsEditing" class="d-flex flex-end gap-10">
            <loading-button name="saveEdit" lb-class="pseudo-link-blue" :dark="true" @lb-click="saveEdit">Save</loading-button>

            <button type="button" class="pseudo-link semibold-weight" @click="cancelEdit">Cancel</button>
          </div>

          <button v-else type="button" class="pseudo-link-blue" @click="startEdit">Edit Questions</button>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="list-spacing-30" v-for="group in orderedQuestionGroups" :key="group.id">
        <div class="size-text-15px bold-weight bottom-10 row tight-columns">
          <div class="col-sm-10 d-flex">
            {{ group.name }}

            <div v-if="isRatesAndPricing(group)">
              <span class="info-icon left-5 vertical-center"><svg-icon id="rates-and-pricing-tooltip" name="info" class="base-icon smaller text-top"></svg-icon></span>

              <tooltip
                target="#rates-and-pricing-tooltip"
                placement="top"
                custom-class="tooltip-white"
                text="These questions power the rate comparison and suggested lawyer features in RFPs and cannot be removed from templates.">
              </tooltip>
            </div>
          </div>

          <div class="col-sm-2 flex-end vertical-center-not-xs top-10-xs">
            <rfq-template-group-form
              v-if="isCustomGroup(group) && isEditable"
              :group="group"
              :is-editing="true"
              :existing-groups="orderedQuestionGroups">
            </rfq-template-group-form>
          </div>
        </div>

        <div v-if="hasQuestions(group)">
          <ordered-question-group
            :group="group"
            :is-editing="isEditing"
            :is-editable="isEditable">
          </ordered-question-group>
        </div>

        <div v-else class="padded-container text-center empty-list bottom-30">
          You have not added any questions. <button type="button" @click="removeSection(group)" class="pseudo-link-blue pill-gray-text semibold-weight">Remove Section</button>
        </div>

        <rfq-template-question-form
          v-if="isEditable"
          class="top-10"
          :group="group"
          :is-editing="false">
        </rfq-template-question-form>
      </div>

      <rfq-template-group-form
        v-if="isEditable"
        class="top-10"
        :existing-groups="orderedQuestionGroups">
      </rfq-template-group-form>

      <div v-if="isEditingExistingTemplate" class="row tight-columns top-30">
        <div class="col-sm-3">
          <loading-button name="saveEdit" lb-class="primary-btn-blue" @lb-click="saveEdit">Save</loading-button>
        </div>

        <div class="col-sm-2 top-20-xs">
          <button type="button" class="nv-button-white" @click="cancelEdit">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqTemplateQuestionForm from 'vue-app/scout/client/settings/rfqs/templates/new/question-form.vue';
import RfqTemplateGroupForm from 'vue-app/scout/client/settings/rfqs/templates/new/group-form.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { mapActions, mapState } from 'pinia';
import { orderBy } from 'lodash';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import { scrollToTop } from 'misc/ui-helpers.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import OrderedQuestionGroup from 'vue-app/scout/client/settings/rfqs/templates/ordered-question-group.vue';

export default {
  name: 'RfqTemplateQuestions',

  components: {
    RfqTemplateQuestionForm,
    RfqTemplateGroupForm,
    OrderedQuestionGroup,
    LoadingButton,
    SvgIcon
  },

  props: {
    isNew: {
      type: Boolean,
      default: true
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    showTemplateHeading: {
      type: Boolean,
      default: true
    },

    template: {
      type: Object,
      default: () => ({})
    },

    questionGroups: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      persistedRfqTemplate: null,
      maxStandardQuestionsPosition: 4,
      ratesAndPricingPosition: 2,
      localQuestionGroups: [...this.questionGroups],
      localIsEditing: this.isEditing
    };
  },
  computed: {
    ...mapState(useRfqTemplateStore, {
      rfqTemplate: 'rfqTemplate'
    }),

    orderedQuestionGroups() {
      if (this.localIsEditing) {
        if (this.localQuestionGroups && this.localQuestionGroups.length !== 0) {
          return this.parentQuestionGroups;
        }
        else {
          return this.editableQuestionGroups;
        }
      }
      else {
        return this.persistedQuestionGroups;
      }
    },

    isEditable() {
      return this.isNew || this.localIsEditing;
    },

    isEditingExistingTemplate() {
      return !this.isNew && this.localIsEditing;
    },

    isStandardTemplate() {
      return this.rfqTemplate.id === null && this.rfqTemplate.name === 'Standard Questions';
    },

    // for the case that we pass in question groups from the parent
    parentQuestionGroups() {
      return orderBy(
        this.localQuestionGroups.filter(group => group.isShown === true || group.position > this.maxStandardQuestionsPosition),
        'position'
      );
    },

    editableQuestionGroups() {
      return orderBy(
        this.rfqTemplate.questionGroupTemplatesAttributes.filter(group => group.isShown === true || group.position > this.maxStandardQuestionsPosition),
        'position'
      );
    },

    persistedQuestionGroups() {
      return orderBy(
        this.template.questionGroupTemplatesAttributes.filter(group => group.isShown === true || group.position > this.maxStandardQuestionsPosition),
        'position'
      );
    }
  },

  watch: {
    questionGroups: {
      handler(newValue) {
        this.localQuestionGroups = newValue;
      },
      deep: true
    }
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      removeGroup: 'removeGroup',
      updateGroup: 'updateGroup',
      setRfqTemplate: 'setRfqTemplate',
      updateTemplate: 'updateTemplate'
    }),

    isCustomGroup(group) {
      return group.position > this.maxStandardQuestionsPosition;
    },

    hasQuestions(group) {
      return group.questionTemplatesAttributes.filter(q => !q._destroy).length > 0;
    },

    isRatesAndPricing(group) {
      return group.position === this.ratesAndPricingPosition;
    },

    removeSection(group) {
      this.isStandardTemplateGroup(group) ? this.hideStandardTemplateGroup(group) : this.removeGroup(group);
    },

    hideStandardTemplateGroup(group) {
      const updatedGroup = {
        ...group,
        isShown: false
      };
      this.updateGroup(updatedGroup);
    },

    isStandardTemplateGroup(group) {
      return group.position <= this.maxStandardQuestionsPosition;
    },

    startEdit() {
      this.persistedRfqTemplate = structuredClone(this.template);
      this.localIsEditing = true;
    },

    saveEdit() {
      LoadingService.loading('saveEdit');
      this.updateTemplate(this.rfqTemplate)
        .then((response) => {
          this.setRfqTemplate(response);
          this.localIsEditing = false;
          NotificationService.success('This template has been successfully saved.');
        })
        .catch(() => {
          NotificationService.error('Failed to update template. Please try again.');
        })
        .finally(() => {
          scrollToTop();
          LoadingService.done('saveEdit');
        });
    },

    cancelEdit() {
      this.setRfqTemplate(this.persistedRfqTemplate);
      this.localIsEditing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .empty-list {
    border-radius: $border-radius-large;
    background-color: $k-lighter-gray;
    color: $pill-gray;
  }

  .blue-accent {
    background: $blue-10;
    padding: 10px 20px 20px 20px;
    border-radius: $border-radius-large;
  }
</style>
