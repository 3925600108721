<template>
  <div class="clearfix">
    <div class="size-text-11px dark-gray-text semibold-weight text-right bottom-5 right-30">
      {{ notifiedAt }}
    </div>

    <div class="notification">
      <!-- TODO -->
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'Notification',

  components: {
  },

  props: {
    receipt: {
      type: Object,
      required: true
    }
  },

  computed: {
    message() {
      return this.receipt.message;
    },

    notificationCode() {
      return this.message.notificationCode;
    },

    metadata() {
      return this.message.meta;
    },

    notifiedAt() {
      return DateFilter.shortTime(this.message.createdAt);
    }
  }
};
</script>
