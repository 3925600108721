<template>
  <div>
    <div class="small-bold-header">
      Languages
    </div>

    <hr class="margin-top-5">

    <div class="row tight-columns">
      <div class="flex col-sm-6 bottom-15" v-for="(language, index) in languages" :key="`language_${language.name}_${index}`">
        <div>
          <a href="" @click.prevent="removeLanguage(language, index)"><svg-icon name="minus-circle" class="base-icon remove-icon-spacing"></svg-icon></a>
        </div>

        <div class="width-86">
          <dropdown-select
            label="Language"
            id-label="language"
            :initial-label="language.name"
            :options="languageOptions"
            @input="(item) => updateLanguage(item, language)">
          </dropdown-select>
        </div>
      </div>
    </div>

    <div>
      <a href="" class="semibold-weight" @click.prevent="addLanguage">+ {{ languages.length ? 'Add Another' : 'Add Language' }}</a>
    </div>
  </div>
</template>

<script>
import SharedLists from 'vue-app/shared/services/shared-lists-service';

export default {
  name: 'EditLawyerLanguages',

  props: {
    languages: {
      type: Array,
      required: true
    },

    languagesToDestroy: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      languageOptions: SharedLists.listOfLanguages()
    };
  },

  methods: {
    addLanguage: function () {
      this.languages.push({ name: '', level: 'Fluent' });
    },

    removeLanguage: function (language, index) {
      if (language.id) {
        this.languagesToDestroy.push({ id: language.id, '_destroy': true });
      }

      this.languages.splice(index, 1);
    },

    updateLanguage: function (selection, languageObject) {
      languageObject.name = selection;
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
  }

  .width-86 {
    width: 86%;
  }

  .remove-icon-spacing {
    margin-top: 40px;
    margin-right: 10px;

    @media (min-width: 768px) {
      margin-top: 38px;
    }
  }

  .margin-top-5 {
    margin-top: 5px;
  }
</style>
