<template>
  <div class="row">
    <div class="col-sm-8">
      <data-disclaimer-box :firm-name="lawyer.firmName" :lawyer-name="lawyer.fullName"></data-disclaimer-box>
    </div>

    <div class="col-sm-4 top-30-xs">
      <lawyer-law-firm-status
        class="bottom-30"
        :lawyer="lawyer">
      </lawyer-law-firm-status>

      <ghost-lawyer-contact class="bottom-30" :lawyer="lawyer"></ghost-lawyer-contact>

      <ghost-lawyer-general :lawyer="lawyer"></ghost-lawyer-general>
    </div>
  </div>
</template>

<script>
import GhostLawyerContact from 'vue-app/scout/shared/lawyers/ghost-lawyer-contact.vue';
import GhostLawyerGeneral from 'vue-app/scout/shared/lawyers/ghost-lawyer-general.vue';
import DataDisclaimerBox from 'vue-app/scout/shared/data-disclaimer-box.vue';
import LawyerLawFirmStatus from 'vue-app/scout/shared/lawyers/lawyer-law-firm-status.vue';

export default {
  name: 'GhostLawyerExperience',

  components: {
    GhostLawyerContact,
    GhostLawyerGeneral,
    DataDisclaimerBox,
    LawyerLawFirmStatus
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    }
  }
};
</script>
