<template>
  <div>
    <div :class="{ 'row': twoColumns }">
      <div v-for="score in reviewScores" :key="score.field" :class="['category-wrapper', { 'col-md-6': twoColumns }]">
        <div class="category-name text-ellipsis">
          {{ score.field }}
        </div>

        <rating-bar :rating="score.score" :max-rating="5" :fill-color-class="fillColorClass"></rating-bar>
      </div>
    </div>

    <div class="size-text-16px gray-text" v-if="!hasReviewScores">
      No performance scores available
    </div>
  </div>
</template>

<script>
import RatingBar from './rating-bar';
import { map, compact } from 'lodash';

export default {
  name: 'PerformanceScoreWithTopLabel',

  components: {
    RatingBar
  },

  props: {
    scores: {
      type: Array,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    fillColorClass: {
      type: String,
      default: null
    },

    twoColumns: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    reviewScoreTitles() {
      return map(this.reviewTemplate.reviewQuestions(), 'title');
    },

    reviewScores() {
      return compact(map(this.reviewScoreTitles, (title) => {
        return this.scores.find(score => score.field === title && score.score > 0);
      }));
    },

    hasReviewScores() {
      return !!this.reviewScores.length;
    }
  }
};
</script>

<style lang="scss" scoped>
  .category-wrapper:not(:last-child) {
    margin-bottom: 15px;

    &.col-md-6 {
      margin-bottom: 30px;

      .category-name {
        margin-bottom: 5px;
      }
    }
  }

  .category-name {
    margin-bottom: 4px;
  }
</style>
