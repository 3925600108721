<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div class="col-sm-3">
      {{ errorData.type }}
    </div>

    <div class="col-sm-5 top-10-xs">
      {{ errorData.message }}
    </div>

    <div class="col-sm-4 top-10-xs" v-if="locationIsVisible">
      <priori-modal title="Error Location">
        <template #modal-trigger="{ openModal }">
          <div class="error-item-emphasis clickable" @click="openModal">
            {{ errorData.location }}
          </div>
        </template>

        <template #default>
          <div v-for="(value, key, index) in errorData.context" :key="`${key}-${index}`" class="row size-text-14px list-spacing-10">
            <div class="col-sm-4 semibold-weight">
              {{ capitalizeFirst(key) }}
            </div>

            <div class="col-sm-8">
              {{ value }}
            </div>
          </div>
        </template>
      </priori-modal>
    </div>
  </div>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { isString, startCase } from 'lodash';

export default {
  name: 'UploadErrorItem',

  components: {
    PrioriModal
  },

  props: {
    errorData: {
      type: Object,
      required: true
    }
  },

  computed: {
    // allow showing empty objects but not empty strings
    locationIsVisible() {
      if (!isString(this.errorData.location)) { return true; }
      return this.errorData.location.length > 0;
    }
  },

  methods: {
    capitalizeFirst(str) {
      return startCase(str);
    }
  }
};
</script>
