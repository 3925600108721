<template>
  <div v-if="hasQuestions || handlesLawyers">
    <div class="size-text-15px bold-weight heading-underline bottom-20">
      {{ questionGroup.name }}
    </div>

    <div :class="{ 'bottom-20': handlesLawyers }" v-if="hasQuestions">
      <div class="list-spacing-20" v-for="question in orderedQuestions" :key="question.id">
        <question-answer
          :question-group="questionGroup"
          :question="question"
          :answers="answers"
          :quote-last-submitted-at="quoteLastSubmittedAt"
          :show-question-update-tags="showQuestionUpdateTags">
        </question-answer>
      </div>
    </div>

    <suggested-lawyers
      :suggested-lawyers="suggestedLawyers"
      :viewing-as-client="viewingAsClient"
      v-if="handlesLawyers">
    </suggested-lawyers>
  </div>
</template>

<script>
import QuestionAnswer from 'vue-app/scout/shared/rfqs/question-answer.vue';
import SuggestedLawyers from 'vue-app/scout/shared/rfqs/suggested-lawyers.vue';
import dateComparisons from 'vue-app/shared/mixins/date-comparisons.js';
import { orderBy } from 'lodash';

export default {
  name: 'QuestionGroup',

  components: {
    QuestionAnswer,
    SuggestedLawyers
  },

  mixins: [dateComparisons],

  props: {
    questionGroup: {
      type: Object,
      required: true
    },

    answers: {
      type: Array,
      required: true
    },

    suggestedLawyers: {
      type: Array,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      default: true
    },

    showQuestionUpdateTags: {
      type: Boolean,
      required: true
    },

    quoteLastSubmittedAt: {
      type: String,
      default: null
    }
  },

  computed: {
    nonContingentQuestions() {
      if (this.showQuestionUpdateTags) {
        return this.questionGroup.questions.filter(q => {
          return !q.prerequisiteQuestionId && this.isIncludedOrHasChanged(q);
        });
      }

      return this.questionGroup.questions.filter(q => q.isIncluded && !q.prerequisiteQuestionId);
    },

    hasQuestions() {
      return this.nonContingentQuestions.length > 0;
    },

    orderedQuestions() {
      return orderBy(this.nonContingentQuestions, 'position');
    },

    hasSuggestedLawyers() {
      return this.suggestedLawyers.length > 0;
    },

    handlesLawyers() {
      return this.questionGroup.handlesLawyers && this.hasSuggestedLawyers;
    }
  },

  methods: {
    isIncludedOrHasChanged(question) {
      return (question.isIncluded ||
        (question.latestActivity?.activityType === 'removed' && this.questionUpdatedAfterLastSubmission(question))
      );
    },

    questionUpdatedAfterLastSubmission(question) {
      if (!this.quoteLastSubmittedAt) { return true; }

      return this.dateIsAfter(question.latestActivity?.createdAt, this.quoteLastSubmittedAt);
    }
  }
};
</script>
