import axios from 'axios';
import BaseResource from 'resources/base-resource';
import { compact } from 'lodash';

class ScoutLawyerReviewTemplate extends BaseResource {
  static baseUrl = '/scout/lawyer_review_templates/:id.json';
  static urlTransformations = ['id'];

  static inWorkspace(params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/scout/lawyer_review_templates/workspace.json', { params: params })
        .then(
          (data) => { resolve(new this(data.data)); },
          reject
        );
    });
  }

  static responseAnswerLegend() {
    return [
      { value: 0, legend: 'N/A' },
      { value: 1, legend: 'Poor' },
      { value: 2, legend: 'Development Needed' },
      { value: 3, legend: 'Meets Expectations' },
      { value: 4, legend: 'Exceeds Expectations' },
      { value: 5, legend: 'Outstanding' }
    ];
  }

  complexityLabels() {
    return [
      this.complexityLowText,
      this.complexityMediumText,
      this.complexityHighText
    ];
  }

  riskLabels() {
    return [
      this.riskLowText,
      this.riskMediumText,
      this.riskHighText
    ];
  }

  reviewQuestions() {
    return compact([1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
      const title = this[`question${num}Title`];
      const text = this[`question${num}Text`];
      const considerations = this[`question${num}Considerations`];

      if (!title || !text) { return; }

      return { title, text, considerations };
    }));
  }

  booleanQuestions() {
    return compact([1, 2, 3, 4, 5].map((num) => {
      const title = this[`booleanQuestion${num}Title`];
      const text = this[`booleanQuestion${num}Text`];

      if (!title || !text) { return; }

      return { title, text };
    }));
  }

  selectQuestions() {
    // NOTE: even though we only have one select question, I kept the pattern
    // of code used above so we can easily slot in additional questions
    return compact([1].map((num) => {
      const title = this[`selectQuestion${num}Title`];
      const text = this[`selectQuestion${num}Text`];
      const options = this[`selectQuestion${num}Options`];

      if (!title || !text || !options || !options.length) { return; }

      return { title, text, options };
    }));
  }
}

export default ScoutLawyerReviewTemplate;
