<template>
  <div :class="['vertical-center section-wrapper width-100 gap-10', { 'checked': inputChecked }]">
    <div class="checkbox">
      <label>
        <input type="checkbox" v-model="inputChecked" @change="inputChanged">
      </label>
    </div>

    <div>
      <div class="question-header bold-weight">{{ headerText }}</div>
      <div><span class="subtext">{{ subText }}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxItem',

  props: {
    item: {
      type: Object,
      default: null
    },

    isChecked: {
      type: Boolean,
      default: false
    },

    headerText: {
      type: String,
      required: true
    },

    subText: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      inputChecked: this.isChecked
    };
  },

  watch: {
    isChecked(newVal) {
      this.inputChecked = newVal;
    }
  },

  methods: {
    inputChanged() {
      this.$emit('input', this.inputChecked);
    },

    reset() {
      this.inputChecked = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .subtext {
    border-bottom: 1px dashed $gray-3;
    color: $gray-3;
    font-size: 12px;
  }

  .section-wrapper {
    border-radius: $border-radius-large;
    padding: 10px 15px;
    border: 1px solid $k-gray;
  }

  .checked {
    border: 1px solid $blue-4;
    background: $blue-10;
  }
</style>
