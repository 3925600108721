import { orderBy } from 'lodash';

export function sortScoutTags(tags) {
  return orderBy(
    tags,
    [
      tag => {
        if (tag.tagType === 'general') { return 0; }
        if (tag.tagType === 'practice_area') { return 1; }
        if (tag.tagType === 'location') { return 2; }
        return 3;
      },
      tag => (tag.tagType === 'general' ? tag.position : Infinity),
      tag => (tag.tagType === 'practice_area' || tag.tagType === 'location' ? tag.name.toLowerCase() : '')
    ],
    ['asc', 'asc', 'asc']
  );
}
