<template>
  <div class="shadowed-box size-text-13px">
    <div class="box-header text-uppercase">
      Opportunity Invitations
    </div>

    <div class="box-content symmetrical">
      <div class="list-spacing-20" v-for="invitation in visibleOpportunities" :key="invitation.id">
        <request-invitation :invitation="invitation"></request-invitation>
      </div>

      <div class="size-text-base gray-text text-center" v-if="!visibleOpportunities.length">
        No lawyers have been invited to this opportunity.
      </div>
    </div>
  </div>
</template>

<script>
import RequestInvitation from 'vue-app/admin/requests/request-invitation.vue';
import moment from 'moment';

export default {
  name: 'RequestInvitations',

  components: {
    RequestInvitation
  },

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  },

  computed: {
    visibleOpportunities() {
      return this.counselRequest.opportunityInvitations.filter((invitation) => invitation.emailSent).sort((invitation) => moment(String(invitation.createdAt)).unix());
    }
  }
};
</script>
