<template>
  <div class="size-text-12px">
    <div class="bottom-10" v-for="(highlights, field) in defaultHighlights" :key="field">
      <span class="semibold-weight">{{ normalizedField(field) }}:</span>

      <span v-for="(detail, idx) in highlights" :key="idx">
        <span v-html="detail"></span>

        <span v-if="idx !== highlights.length - 1">; </span>
      </span>
    </div>

    <span v-if="highlightEntries.length > 3">
      <span v-if="expanded">
        <div class="bottom-10" v-for="(highlights, field) in expandedHighlights" :key="field">
          <span class="semibold-weight">{{ normalizedField(field) }}:</span>

          <span v-for="(detail, idx) in highlights" :key="idx">
            <span v-html="detail"></span>

            <span v-if="idx !== highlights.length - 1">; </span>
          </span>
        </div>
      </span>

      <span v-else>
        <a href="" @click.prevent="expand">+ {{ highlightEntries.length - 3 }} more</a>
      </span>
    </span>
  </div>
</template>

<script>
import { startCase } from 'lodash';

export default {
  name: 'KeywordHighlights',

  props: {
    keywordHighlights: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      expanded: false
    };
  },

  computed: {
    defaultHighlights() {
      return this.sliceHighlights(0, 3);
    },

    expandedHighlights() {
      return this.sliceHighlights(3);
    },

    highlightEntries() {
      return Object.entries(this.keywordHighlights);
    }
  },

  methods: {
    expand() {
      this.expanded = !this.expanded;
    },

    normalizedField(field) {
      return startCase(field);
    },

    sliceHighlights() {
      return Object.fromEntries(
        this.highlightEntries.slice(...arguments)
      );
    }
  }
};
</script>
