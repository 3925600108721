<template>
  <modal v-model="modalOpen" size="md-sm" :transition="0" :header="false" :footer="false" append-to-body>
    <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

    <div class="notice-modal">
      <div class="notice-header error-notice">
        {{ noticeTitle }}
      </div>

      <div class="notice-regular-text">
        <span class="size-text-base">{{ notice }}</span>
      </div>

      <div class="notice-actions">
        <div class="row">
          <div class="col-sm-6 col-sm-offset-3">
            <button type="button" class="nv-button-white smaller" @click="dismissModal">Close Window</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';

export default {
  name: 'ErrorModal',

  components: {
    ModalCloseButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    noticeTitle: {
      type: String,
      default: 'Oops!'
    },

    notice: {
      type: String,
      required: true
    }
  }
};
</script>
