import BaseResource from 'resources/base-resource';

class ScoutReviewReminder extends BaseResource {
  static baseUrl = '/scout/review_reminders.json';

  reminderTypeIs(type) {
    return this.reminderType === type;
  }
}

export default ScoutReviewReminder;
