<template>
  <form role="form" name="filterForm" class="a-form vertical-center gap-10" novalidate>
    <div :class="['keyword-search-container', { 'searched': searched }]">
      <div class="vertical-center keyword-prefix-symbol dark-gray-text">
        #
      </div>

      <input type="text" class="form-control keyword-search-input keyword-with-prefix-symbol" v-model="value" :placeholder="placeholder" @keypress.enter.prevent="search">

      <svg-icon name="search" class="base-icon keyword-search-icon" @click.prevent="search"></svg-icon>
    </div>

    <button type="button" class="pseudo-link-blue clear-button" @click.prevent="clearSearch" v-if="searched">Clear</button>
  </form>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'SearchInput',

  components: {
    SvgIcon
  },

  props: {
    placeholder: {
      type: String,
      default: ''
    },

    onSearch: {
      type: Function,
      required: true
    },

    onReset: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      value: '',
      searched: false
    };
  },

  methods: {
    search() {
      if (!this.value) { return; }
      this.onSearch(this.value);
      this.searched = true;
    },

    clearSearch() {
      this.value = '';
      this.searched = false;
      this.onReset();
    }
  }
};
</script>

<style scoped lang="scss">
.keyword-search-container {
  width: 100%;

  &.searched {
    width: calc(100% - 50px);
  }
}

.clear-button {
  width: 50px;
}
</style>
