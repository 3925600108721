<template>
  <form role="form" class="a-form" autocomplete="off" novalidate>
    <div>
      <div class="bottom-10">
        Drag and drop items to change the order
      </div>

      <div class="droppable-saved-search" v-droppable="{ id: null }" @on-drop="reorderList"></div>

      <div
        v-for="savedSearch in listCopy"
        :key="savedSearch.id"
        v-droppable="{ id: savedSearch.id }"
        v-draggable="{ id: savedSearch.id }"
        class="draggable-saved-search"
        @on-drop="reorderList">
        <searches-list-item-order :saved-search="savedSearch"></searches-list-item-order>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-5">
        <loading-button :name="saveButtonName" lb-class="nv-button-blue a-button-size" @lb-click="saveOrder">Save Changes</loading-button>
      </div>

      <div class="col-sm-4 top-20-xs">
        <button type="button" class="nv-button-white a-button-size" @click="onClose">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import { cloneDeep, uniqueId, pull } from 'lodash';
import Draggable from 'vue-app/shared/directives/draggable.js';
import Droppable from 'vue-app/shared/directives/droppable.js';
import SearchesListItemOrder from './searches-list-item-order.vue';
import SavedSearch from 'resources/admin/saved-search.js';

export default {
  name: 'SearchesListOrder',

  components: {
    SearchesListItemOrder
  },

  directives: {
    Draggable,
    Droppable
  },

  props: {
    list: {
      type: Array,
      default: () => []
    },

    onOrderUpdated: {
      type: Function,
      required: true
    },

    onClose: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      listCopy: [],
      saveButtonName: uniqueId('save-button-')
    };
  },

  mounted() {
    this.listCopy = cloneDeep(this.list);
  },

  methods: {
    reorderList(evt) {
      const draggedId = evt.detail.draggedId;
      const droppedId = evt.detail.droppedId;
      const order = this.listCopy.map((item) => item.id);

      if (draggedId === droppedId) { return; }
      if (order.indexOf(draggedId) === 0 && isNaN(droppedId)) { return; }

      pull(order, draggedId);

      order.splice(order.indexOf(droppedId) + 1, 0, draggedId);

      this.listCopy = order.map((savedSearchId, index) => {
        const item = this.listCopy.find(savedSearch => savedSearch.id === savedSearchId);

        item.position = index + 1;

        return item;
      });
    },

    saveOrder() {
      LoadingService.loading(this.saveButtonName);

      const savedSearches = this.listCopy.map((savedSearch, index) => {
        return { id: savedSearch.id, position: index + 1 };
      });

      SavedSearch.order({ order: savedSearches })
        .then(() => {
          this.onOrderUpdated();
          this.onClose();
        })
        .finally(() => {
          LoadingService.done(this.saveButtonName);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .draggable-saved-search {
    margin-bottom: 10px;

    &:hover {
      cursor: grab;
    }

    &.dragging {
      opacity: .4;
    }

    &.dragover {
      margin-bottom: 4px;
      padding-bottom: 4px;
      border-bottom: 2px solid $k-blue;
    }

    &.dragging.dragover {
      margin-bottom: 10px;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &:last-child {
      margin-bottom: 30px;

      &.dragover {
        margin-bottom: 24px;
        padding-bottom: 4px;
      }

      &.dragging.dragover {
        margin-bottom: 30px;
        padding-bottom: 0;
      }
    }

    .base-icon {
      fill: $k-gray;
    }
  }

  .droppable-saved-search {
    height: 20px;
    min-height: 20px;

    &.dragover {
      margin-top: -5px;
      margin-bottom: 5px;
      border-bottom: 2px solid $k-blue;
     }
  }
</style>
