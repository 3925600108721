<template>
  <div class="messaging-center-list-item">
    <div :class="['item-photo', (isClient ? 'client-icon' : 'lawyer-icon')]">
      <profile-photo :user="participant" :small="true"></profile-photo>
    </div>

    <div class="item-content size-text-13px">
      <div class="tight-lines">
        <span :class="['semibold-weight', { 'text-italic dark-gray-text': isInactive }]">{{ participant.fullName }}<span v-if="isCurrentUser"> (You)</span></span><span :class="['text-italic left-5', { 'dark-gray-text': isInactive }]">({{ participant.company || participant.lawFirm }})</span>
      </div>

      <div class="top-2">
        <span v-if="!isInactive">{{ participant.email }}</span>
        <span v-else class="text-italic pill-gray-text">{{ inactiveText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ConversationParticipants',

  components: {
    ProfilePhoto
  },

  props: {
    participant: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    isCurrentUser() {
      return this.user.klass === this.participant.klass && this.user.id === this.participant.id;
    },

    isClient() {
      return this.participant.klass === 'Client';
    },

    isInactive() {
      return !this.participant.accessible;
    },

    inactiveText() {
      return this.participant.deactivated ? 'Deactivated User' : 'No Access';
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  :deep(.missing-photo.small) {
    font-size: 13px;
    font-weight: 600;
    box-shadow: none;
  }

  .client-icon :deep(.missing-photo) {
    color: $white;
    background-color: $k-blue;
  }

  .lawyer-icon :deep(.missing-photo) {
    color: $gray-6;
    background-color: $k-darker-gray;
  }
</style>
