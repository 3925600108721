<template>
  <div class="opportunity-listing">
    <div class="row gutter-20">
      <div class="col-xs-12 col-sm-6 col-md-7 left-section">
        <div v-if="shouldShowTag" class="size-text-12px vertical-center bottom-5">
          <svg-icon name="circle" :class="['base-icon', 'color-indicator', 'right-5', statusColorMap[opportunity.statusTag]]"></svg-icon><span v-if="opportunity.statusTag === 'new'">NEW</span><span v-if="opportunity.statusTag === 'updated'">Updated</span>
        </div>

        <div class="two-line-ellipsis tight-lines bold-weight size-text-17px bottom-10">
          {{ opportunity.postTitle }}
        </div>

        <div class="two-line-ellipsis" v-html="opportunity.opportunityDescription"></div>

        <div v-if="hasPracticeAreas" class="practice-areas top-20">
          <div ref="practiceAreas" class="d-flex wrap col-gap-5 row-gap-5">
            <color-tag
              v-for="practiceArea in opportunity.practiceAreas"
              :key="practiceArea"
              color-pattern="light-purple">
              {{ practiceArea }}
            </color-tag>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-5 right-section">
        <div class="row tight-columns">
          <div class="col-sm-7 col-md-8">
            <div class="bottom-15 text-ellipsis">
              <svg-icon class="base-icon right-10" name="location"></svg-icon><span class="vertical-middle-text">{{ opportunity.location }}</span>
            </div>

            <div class="bottom-15 text-ellipsis">
              <svg-icon class="base-icon right-10" name="calendar"></svg-icon><span class="vertical-middle-text">{{ timeCommitment }}</span>
            </div>

            <div class="bottom-15 text-ellipsis">
              <svg-icon class="base-icon right-10" :name="siteIconName"></svg-icon><span class="vertical-middle-text">{{ site }}</span>
            </div>

            <div class="bottom-15 text-ellipsis">
              <svg-icon class="base-icon right-10" name="dollar"></svg-icon><span class="vertical-middle-text">{{ rateRange }}</span>
            </div>

            <div v-if="requiredBars.length" class="text-ellipsis">
              <svg-icon class="base-icon right-10" name="ribbon"></svg-icon><span class="vertical-middle-text">{{ requiredBars }}</span>
            </div>
          </div>

          <div class="col-sm-5 col-md-4 text-right-not-xs">
            <div v-if="requestId" class="opportunity-id">
              #{{ requestId }}
            </div>

            <a :href="`/public-app/opportunities/${opportunity.id}`" class="nv-button-clear-alt">Details</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorTag from 'vue-app/shared/components/color-tag.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';

export default {
  name: 'OpportunityListing',

  components: {
    ColorTag,
    SvgIcon
  },

  props: {
    opportunity: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      statusColorMap: {
        'new': 'green',
        'updated': 'yellow'
      },
      feeStructureAbbreviationMap: {
        'per_hour': '/hr',
        'per_week': '/wk',
        'per_month': '/mo',
        'per_year': '/yr',
        'per_project': ''
      }
    };
  },

  computed: {
    shouldShowTag() {
      return ['new', 'updated'].includes(this.opportunity.statusTag);
    },

    hasPracticeAreas() {
      return this.opportunity.practiceAreas.length > 0;
    },

    rateRange() {
      let rateRange = `$${this.opportunity.rateMin}`;

      if (this.opportunity.rateMax) {
        rateRange += ` - $${this.opportunity.rateMax}`;
      }

      return `${rateRange}${this.feeStructureAbbreviation}`;
    },

    site() {
      const site = SharedListsService.sites.find(site => site.slug === this.opportunity.site);
      return site.label;
    },

    siteIconName() {
      if (this.site === 'Remote') {
        return 'wifi';
      }
      else {
        return 'experience';
      }
    },

    timeCommitment() {
      const timeCommitment = SharedListsService.timeCommitments.find(tc => tc.slug === this.opportunity.timeCommitment);
      return timeCommitment.label;
    },

    requiredBars() {
      const sortedBars = this.opportunity.requiredBars.slice().sort((a, b) => {
        if (a.country === 'United States' && a.state === 'Any') { return -1; }
        if (b.country === 'United States' && b.state === 'Any') { return 1; }
        return 0;
      });

      return sortedBars.map(bar => {
        if (this.isUsBarWithAnyState(bar)) {
          return 'United States (Any)';
        }
        else if (bar.country === 'United States') {
          return bar.state;
        }
        else {
          return bar.country;
        }
      }).join(', ') || '—';
    },

    minLeftSectionHeight() {
      return this.$refs.practiceAreas.clientHeight > 25 ? '185px' : '170px';
    },

    requestId() {
      return this.opportunity.counselRequestId;
    },

    feeStructureAbbreviation() {
      return this.feeStructureAbbreviationMap[this.opportunity.feeStructure];
    }
  },

  methods: {
    isUsBarWithAnyState(bar) {
      return bar.country === 'United States' && bar.state === 'Any';
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .opportunity-listing {
    background-color: $white;
    border-radius: $border-radius-large;
    border: 1px solid $gray-7;
    padding: 25px 30px;
  }

  .base-icon {
    &.green {
      fill: $green-5;
    }

    &.yellow {
      fill: $p-yellow;
    }
  }

  .left-section {
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-7;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 0;
      border-right: 1px solid $gray-7;
      border-bottom: none;
      min-height: v-bind(minLeftSectionHeight);
    }
  }

  .right-section {
    padding-top: 20px;

    @media (min-width: $screen-sm-min) {
      padding-top: 0;
    }
  }

  .opportunity-id {
    margin-bottom: 10px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 25px;
    }
  }

  .practice-areas {
    position: relative;
    max-height: 53px;
    overflow: hidden;

    @media (min-width: $screen-sm-min) {
      position: absolute;
      bottom: 0;
    }
  }

  .color-indicator {
    width: 9px;
    height: 9px;
  }

  :deep(.color-tag) {
    white-space: nowrap;
    padding: 3px 6px;
    font-size: 13px;
  }

  .two-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
