import axios from 'axios';
import BaseResource from 'resources/base-resource';

class EBillingUpload extends BaseResource {
  static baseUrl = '/scout/e_billing_uploads/:id.json';
  static urlTransformations = ['id'];

  static queryData(params) {
    return axios.get('/scout/e_billing_uploads/index_data.json', { params: params });
  }
}

export default EBillingUpload;
