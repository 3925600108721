<template>
  <div class="row">
    <div class="col-sm-8">
      <data-disclaimer-box :firm-name="firm.name"></data-disclaimer-box>
    </div>

    <div class="col-sm-4 top-30-xs">
      <firm-status :firm="firm"></firm-status>
    </div>
  </div>
</template>

<script>
import DataDisclaimerBox from 'vue-app/scout/shared/data-disclaimer-box.vue';
import FirmStatus from 'vue-app/scout/client/law-firms/firm-status.vue';

export default {
  name: 'GhostFirmDetails',

  components: {
    DataDisclaimerBox,
    FirmStatus
  },

  props: {
    firm: {
      type: Object,
      required: true
    }
  }
};
</script>
