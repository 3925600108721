<template>
  <filter-button
    :label="label"
    :active="active">
    <form role="form" class="a-form" novalidate>
      <div class="d-flex vertical-center justify-between">
        <div>
          {{ description }}
        </div>

        <button type="button" :class="['toggle-button', { 'toggle-on': toggled }]" @click="onClick">
          {{ toggled ? 'On' : 'Off' }}
        </button>
      </div>
    </form>
  </filter-button>
</template>

<script>
import FilterButton from 'src/vue-app/shared/components/filter-button.vue';

export default {
  components: {
    FilterButton
  },

  props: {
    label: {
      type:     String,
      required: true
    },

    active: {
      type:     Boolean,
      default:  false
    },

    description: {
      type:     String,
      required: true
    },

    onToggle: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      toggled: false
    };
  },

  methods: {
    onClick() {
      this.toggled = !this.toggled;
      this.onToggle(this.toggled);
    }
  }
};
</script>
