<template>
  <div id="admin-users" class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-7">
          <span class="text-uppercase">{{ isAlsp ? 'Company' : 'Firm' }} Administrators</span>
          <span class="left-10 dark-gray-text normal-weight size-text-12px" v-if="hasAdmin">{{ numResultsText }}</span>
        </div>

        <div class="col-sm-5 text-right-not-xs">
          <firm-invite-modal :law-firm="lawFirm" :on-send-invite="onSendInvite"></firm-invite-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="admin">
        <div class="gray-text size-text-16px top-10 bottom-10" v-if="!hasAdmin">
          None Provided
        </div>

        <div v-for="(admin, index) in adminUsers" :key="admin.id">
          <div class="inline-flex">
            <div class="right-20">
              <profile-photo :user="admin" :editable="false"></profile-photo>
            </div>

            <div>
              <div class="semibold-weight">
                {{ admin.fullName }}
              </div>

              <div class="dark-gray-text size-text-12px top-2" v-if="admin.firmTitle">
                {{ admin.firmTitle }}
              </div>
            </div>
          </div>

          <hr class="kenny-hr" v-if="!isLastAdmin(index)">
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import FirmInviteModal from 'vue-app/marketplace/shared/law-firms/firm-invite-modal.vue';

export default {
  name: 'FirmAdminUsers',

  components: {
    LoadingSection,
    ProfilePhoto,
    FirmInviteModal
  },

  props: {
    adminUsers: {
      type: Array,
      required: true
    },

    isAlsp: {
      type: Boolean,
      default: false
    },

    lawFirm: {
      type:     Object,
      required: true
    },

    onSendInvite: {
      type:     Function,
      required: false
    }
  },

  computed: {
    hasAdmin() {
      return this.adminUsers.length > 0;
    },

    numResultsText() {
      return `(${this.adminUsers.length} ${this.$pluralize('Result', this.adminUsers.length)})`;
    }
  },

  methods: {
    isLastAdmin(index) {
      return index === this.adminUsers.length - 1;
    }
  }
};
</script>
