class RoutingService {
  static appRoute(user) {
    if (user.isScout) {
      switch (user.klass) {
        case 'Lawyer':
          return '/scout-firm';
        case 'Client':
          return '/scout-company';
      }
    }
    else {
      switch (user.klass) {
        case 'Lawyer':
          return '/lawyer-app';
        case 'Client':
          return '/client-app';
      }
    }
  }
}

export default RoutingService;
