<template>
  <form name="form" class="a-form" novalidate>
    <div class="bottom-15">
      <label class="check-option vertical medium-large" for="date-all-time">
        <span class="normal-weight">All time</span>
        <input type="radio" id="date-all-time" value="allTime" v-model="activeFilterSelection" @change="resetFilter">
        <span class="check c-radio"></span>
      </label>

      <label class="check-option vertical medium-large top-10" for="date-custom">
        <span class="normal-weight">Custom</span>
        <input type="radio" id="date-custom" value="custom" v-model="activeFilterSelection">
        <span class="check c-radio"></span>
      </label>
    </div>

    <hr class="no-margin">

    <div class="top-15">
      <div class="bottom-10">
        <div>
          <label>From</label>
          <span class="error-text left-5" v-show="(isNull(fromMonth) || !fromYear) && customRangeSelected">* Required</span>
        </div>

        <div class="row tight-columns">
          <div class="col-xs-6">
            <select name="fromMonth" :disabled="allTimeSelected" :class="['form-control filter-from-month', { 'empty': isNull(fromMonth) }]" v-model="fromMonth" @change="applySearch">
              <option disabled :value="null">
                Month
              </option>

              <option v-for="(monthName, index) in monthShortNames" :key="monthName" :value="index">
                {{ monthName }}
              </option>
            </select>
          </div>

          <div class="col-xs-6">
            <select name="fromYear" :disabled="allTimeSelected" :class="['form-control filter-from-year', { 'empty': isNull(fromYear) }]" v-model="fromYear" @change="applySearch">
              <option disabled :value="null">
                Year
              </option>

              <option v-for="year in yearRange" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div>
          <label>To</label>
          <span class="error-text left-5" v-show="(isNull(toMonth) || !toYear) && customRangeSelected">* Required</span>
        </div>

        <div class="row tight-columns">
          <div class="col-xs-6">
            <select name="toMonth" :disabled="allTimeSelected" :class="['form-control filter-to-month', { 'empty': isNull(toMonth) }]" v-model="toMonth" @change="applySearch">
              <option disabled :value="null">
                Month
              </option>

              <option v-for="(monthName, index) in monthShortNames" :key="monthName" :value="index">
                {{ monthName }}
              </option>
            </select>
          </div>

          <div class="col-xs-6">
            <select name="toYear" :disabled="allTimeSelected" :class="['form-control filter-to-year', { 'empty': isNull(toYear) }]" v-model="toYear" @change="applySearch">
              <option disabled :value="null">
                Year
              </option>

              <option v-for="year in yearRange" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="error-text top-5" v-show="error">
      {{ error }}
    </div>
  </form>
</template>

<script>
import { isNull } from 'lodash';

export default {
  name: 'FilterDateRange',

  props: {
    onApplyFilter: {
      type: Function,
      required: true
    },

    yearRange: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activeFilterSelection: 'allTime',
      formIsValid: false,
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null,
      error: null
    };
  },

  computed: {
    monthShortNames() {
      const date = new Date();

      return  [...Array(12).keys()].map((i) =>
        new Date(date.getFullYear(), i, 1).toLocaleString('en-US', { month: 'short' })
      );
    },

    allTimeSelected() {
      return this.activeFilterSelection === 'allTime';
    },

    customRangeSelected() {
      return this.activeFilterSelection === 'custom';
    },

    missingFieldCount() {
      return [this.fromMonth, this.fromYear, this.toMonth, this.toYear].filter(field => isNull(field)).length;
    },

    unexpectedDateRange() {
      return this.fromYear > this.toYear || (this.fromYear === this.toYear && this.fromMonth > this.toMonth);
    }
  },

  methods: {
    applySearch() {
      this.validateForm();

      if (!this.formIsValid) { return; }

      const lastDayInToMonth = new Date(this.toYear, this.toMonth + 1, 0).getDate();

      const searchParams = {
        'gte': new Date(this.fromYear, this.fromMonth, 1).toISOString().slice(0, 10),
        'lte': new Date(this.toYear, this.toMonth, lastDayInToMonth).toISOString().slice(0, 10)
      };

      this.onApplyFilter(searchParams);
    },

    validateForm() {
      if (!this.missingFieldCount && this.unexpectedDateRange) {
        this.formIsValid = false;
        this.error = '* To date cannot be earlier than From date';
      }
      else if (this.missingFieldCount) {
        this.formIsValid = false;
      }
      else {
        this.error = null;
        this.formIsValid = true;
      }
    },

    resetFilter() {
      if (!this.missingFieldCount) {
        this.onApplyFilter({});
      }

      this.fromMonth = null;
      this.fromYear  = null;
      this.toMonth   = null;
      this.toYear    = null;
    },

    isNull(value) {
      return isNull(value);
    }
  }
};
</script>
