<template>
  <div>
    <div v-if="hasReviewScores">
      <div class="grid-container">
        <div class="keep">
          <div class="category-wrapper" v-for="score in reviewScores" :key="score.field">
            <div class="row tight-columns vertical-center">
              <div class="col-xs-3 tight-lines text-right score-label">
                {{ score.field }}
              </div>

              <div class="col-xs-9">
                <rating-bar-with-range
                  :rating="score.score"
                  :max-rating="5"
                  :range-min="score.min"
                  :range-max="score.max"
                  :comp-score="compScoreFor(score.field)"
                  :rating-label="ratingLabel"
                  :comp-label="compLabelWithCount"
                  :range-label="rangeLabelWithCountFor(score.field)"
                  :fill-color-class="fillColorClass">
                </rating-bar-with-range>
              </div>
            </div>
          </div>
        </div>

        <div class="background-grid"></div>

        <div class="x-axis-row">
          <div class="x-axis-col" v-for="legend in responseLegend" :key="legend.value">
            <span :id="`axis-item-${legend.value}`" class="x-axis-item">{{ legend.value }}</span>

            <tooltip :target="`#axis-item-${legend.value}`" placement="bottom" custom-class="small-text" :text="legend.legend"></tooltip>
          </div>
        </div>
      </div>

      <div class="row tight-columns">
        <div class="col-lg-9 col-lg-offset-3">
          <div class="legend-panel top-20">
            <div class="row tight-columns vertical-center-not-xs">
              <div class="col-sm-4 vertical-center flex-start">
                <span :class="['rating-bar', fillColorClass]"><span class="dot"></span></span>
                <span class="left-10">{{ ratingLabel }}</span>
              </div>

              <div class="col-sm-4 vertical-center flex-center-not-xs top-10-xs">
                <span class="diamond-container"><span class="diamond"></span></span>
                <span class="left-10">{{ compLabel }}</span>
              </div>

              <div class="col-sm-4 vertical-center flex-end-not-xs top-10-xs">
                <span class="range-bar"></span>
                <span class="left-10">{{ rangeLabel }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="size-text-16px dark-gray-text text-center top-40" v-else>
      No performance scores available
    </div>
  </div>
</template>

<script>
import RatingBarWithRange from './rating-bar-with-range.vue';
import { map } from 'lodash';

export default {
  name: 'PerformanceScoreWithRange',

  components: {
    RatingBarWithRange
  },

  props: {
    reviewsSummary: {
      type: Object,
      required: true
    },

    compSummary: {
      type: Object,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    reviewType: {
      type: String,
      required: true,
      validator: (value) => { return ['Law Firm', 'Lawyer'].includes(value); }
    },

    fillColorClass: {
      type: String,
      default: null
    }
  },

  computed: {
    templateFieldNames() {
      return map(this.reviewTemplate.reviewQuestions(), 'title');
    },

    scores() {
      return this.reviewsSummary.scoreAverages;
    },

    reviewScores() {
      return map(this.templateFieldNames, (field) => {
        return this.scores.find(score => score.field === field) ||
          { field: field, max: 0, min: 0, score: 0, count: 0 };
      });
    },

    compScores() {
      return this.compSummary.scoreAverages;
    },

    compCountText() {
      if (!this.compSummary.count) { return ''; }
      return ` (${this.$pluralize(this.reviewType.toLowerCase(), this.compSummary.count, true)})`;
    },

    hasReviewScores() {
      return this.reviewScores.length > 0;
    },

    ratingLabel() {
      return `${this.reviewType} Average`;
    },

    compLabel() {
      return `All ${this.reviewType}s Average`;
    },

    compLabelWithCount() {
      return `${this.compLabel}${this.compCountText}`;
    },

    rangeLabel() {
      return `${this.reviewType} Range`;
    },

    responseLegend() {
      return this.reviewTemplate.constructor.responseAnswerLegend().filter(ans => ans.value !== 0);
    }
  },

  methods: {
    compScoreFor(field) {
      return this.compScores.find(score => score.field === field)?.score || 0;
    },

    rangeLabelWithCountFor(field) {
      const count = this.reviewScores.find(score => score.field === field)?.count;
      const countText = count ? ` (${this.$pluralize('review', count, true)})` : '';

      return `${this.rangeLabel}${countText}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .grid-container {
    position: relative;
    padding-bottom: 30px;

    .category-wrapper {
      position: relative;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    .background-grid {
      position: absolute;
      top: 0;
      left: calc(25% + 2.5px);
      width: calc(75% - 2.5px);
      height: calc(100% - 28px);
      background-image:
        repeating-linear-gradient(transparent 0 1px, transparent 1px 100%),
        repeating-linear-gradient(90deg, $k-light-gray 0 1px, transparent 1px 100%);
      background-size: 20% 100%;
      border-right: 1px solid $k-light-gray;
      z-index: 0;
    }

    .score-label {
      font-size: 12px;
      overflow-wrap: break-word;
      hyphens: auto;
    }

    .x-axis-row {
      position: absolute;
      bottom: 0;
      left: calc(25% + 7.5px);
      width: calc(75% - 2.5px);
      height: 20px;
      text-align: right;
      font-size: 11px;
      line-height: 1.3;

      .x-axis-col {
        float: left;
        width: calc(100% / 5);
      }

      .x-axis-item {
        display: inline-block;
        width: 10px;
        cursor: pointer;
        text-align: center;
      }
    }
  }

  .legend-panel {
    padding: 10px 15px;
    border: 1px solid $k-gray;
    border-radius: $border-radius-base;
    font-size: 11px;
    line-height: 12px;

    .rating-bar {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 7px;
      border-radius: 7px;
      background: $k-blue;
      background: linear-gradient(45deg, $k-blue, $k-purple);

      &.light-to-dark-blue-background {
        background: $k-blue;
        background: linear-gradient(45deg, $k-blue, $night-blue);
      }

      .dot {
        position: absolute;
        top: 50%;
        right: 0;
        width: 14px;
        height: 14px;
        transform: translate(50%, -50%);
        background: $k-blue;
        border: 2px solid $white;
        border-radius: 50%;
      }

      &.blue-purple-background .dot {
        background: $k-blue;
      }

      &.light-to-dark-blue-background .dot {
        background: $k-purple;
      }
    }

    .diamond-container {
      display: inline-block;

      @media(max-width: 767px) {
        width: 24px;
      }
    }

    .diamond {
      display: inline-block;
      width: 8px;
      height: 8px;
      transform: rotate(-45deg);
      background-color: $pill-gray;
      border-radius: 1px;
    }

    .range-bar {
      display: inline-block;
      width: 24px;
      height: 12px;
      border: 6px solid $grey-3;
      border-radius: $border-radius-small;
    }
  }
</style>
