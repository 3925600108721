<template>
  <dropdown menu-right>
    <button type="button" class="pseudo-link-blue text-link">
      {{ selectedOption.label }}<svg-icon name="caret-down" class="base-icon sort-icon"></svg-icon>
    </button>

    <template #dropdown>
      <li
        v-for="option in sortOptions"
        :key="`${option.column}-${option.direction}`"
        :class="['vertical-center col-gap-5 size-text-12px', { 'selected': isSelected(option) }]">
        <svg-icon v-if="isSelected(option)" name="checkmark-no-circle" class="base-icon smaller"></svg-icon>
        <a href="" role="button" @click.prevent="selectOption(option)">{{ option.label }}</a>
      </li>
    </template>
  </dropdown>
</template>

<script>
import SvgIcon from 'src/vue-app/shared/components/svg-icon.vue';

export default {
  name: 'SortSelect',

  components: {
    SvgIcon
  },

  props: {
    sortOptions: {
      type: Array,
      required: true
    },

    onSort: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedOption: this.sortOptions[0]
    };
  },

  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.onSort(this.selectedOption.column, this.selectedOption.direction);
    },

    isSelected(option) {
      return this.selectedOption === option;
    }
  }
};
</script>

<style lang="scss" scoped>
  .sort-icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }

  :deep(.dropdown-menu) {
    width: fit-content;

    li:first-child {
      padding-top: 5px;
    }

    li:last-child {
      padding-bottom: 5px;
    }

    li {
      padding: 0 30px;

      &.selected {
        padding-left: 13px;

        a {
          font-weight: 600;
        }
      }

      a {
        border: 0;
      }
    }
  }
</style>
