<template>
  <div>
    <div v-if="isSectionSelected('rates')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="rate" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-9 text-ellipsis">
          Hourly Rate:
          <span class="semibold-weight green-text">
            <span v-if="proposalRate">{{ formattedProposalRate }}</span>
            <span v-else>&mdash;</span>
          </span>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['rates'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('experience') && isLawyerAccount">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="experience" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-9 text-ellipsis">
          Experience:
          <span class="semibold-weight">{{ possibleLawyer.yearsExperience }} Years&rsquo; Experience</span>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['experience'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('bars')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="ribbon" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-9 text-ellipsis">
          Bars:
          <span v-if="hasBarAdmissions" class="semibold-weight">{{ barAdmissionList }}</span>
          <span v-else class="semibold-weight">N/A</span>

          <court-admissions-modal
            v-if="isSectionSelected('courts') && hasCourtAdmissions"
            class="left-5"
            :court-admissions="courtAdmissions">
          </court-admissions-modal>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['bars', 'courts'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('education')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="briefcase" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-9">
          <div>
            Education:
          </div>

          <div class="semibold-weight" v-for="school in schools" :key="school.id">
            {{ school.name }}, {{ school.degree }}
          </div>

          <div v-if="!hasSchools">
            &mdash;
          </div>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['education'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('notables') && hasNotableExperiences">
      <div class="row tight-columns">
        <div class="col-xs-10">
          <div class="tag-list">
            <span class="tag-list-item gray compact" v-for="experience in notableExperiences">{{ experience }}</span>
          </div>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['notables'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('resume')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="experience" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-9 text-ellipsis">
          Resume:
          <span class="semibold-weight" v-if="possibleLawyer.resume"><a :href="possibleLawyer.resume.url" target="_blank">{{ possibleLawyer.resume.name }}</a></span>
          <span class="semibold-weight" v-else>No resume to display</span>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['resume'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('location')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="location" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-9 text-ellipsis">
          Location:
          <span class="semibold-weight">{{ lawyerLocation || '&mdash;' }}</span>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['location'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('malpractice_insurance')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="documents" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-9 text-ellipsis">
          Malpractice Insurance:
          <span class="semibold-weight">{{ malpracticeInsurancePresenceText }}</span>
        </div>

        <div class="col-xs-2 text-right" v-if="!isRetiredOrRejected">
          <a href="" class="delete-icon" @click.prevent="removeSections(['malpractice_insurance'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div class="row" v-if="!isRetiredOrRejected">
      <div class="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
        <edit-sections-modal
          :modal-id="`edit-sections-${possibleLawyer.id}`"
          :selected-sections="possibleLawyer.displaySections"
          :available-sections="availableSections"
          :on-change="onSectionsEdited">
        </edit-sections-modal>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CourtAdmissionsModal from 'vue-app/marketplace/shared/lawyers/court-admissions-modal.vue';
import EditSectionsModal from './edit-sections-modal.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import { difference, xor } from 'lodash';

export default {
  name: 'ProposalLawyerSections',

  components: {
    SvgIcon,
    CourtAdmissionsModal,
    EditSectionsModal
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    isRfqQuote: {
      type: Boolean,
      default: false
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      allAvailableSections: [
        { slug: 'rates',                 label: 'Hourly Rate' },
        { slug: 'experience',            label: 'Years\' Experience' },
        { slug: 'bars',                  label: 'Bar Admissions' },
        { slug: 'courts',                label: 'Court Admissions' },
        { slug: 'education',             label: 'Education' },
        { slug: 'notables',              label: 'Notable Experience' },
        { slug: 'resume',                label: 'Resume' },
        { slug: 'location',              label: 'Location' },
        { slug: 'malpractice_insurance', label: 'Malpractice Insurance' },
        { slug: 'languages',             label: 'Languages' }
      ]
    };
  },

  computed: {
    isRetiredOrRejected() {
      return this.possibleLawyer.retiredOrRejected();
    },

    displaySections() {
      return this.possibleLawyer.displaySections;
    },

    proposalRate() {
      return this.possibleLawyer.visibleRate()?.dollars;
    },

    formattedProposalRate() {
      return `${CurrencyFilter.filter(this.proposalRate)}/hr`;
    },

    isLawyerAccount() {
      return this.possibleLawyer.lawyer.accountType === 'lawyer';
    },

    barAdmissionList() {
      return (this.possibleLawyer.barAdmissions || []).map((ba) => {
        return SharedLists.stateAbbreviationFromName(ba.state) || ba.country;
      }).join(', ');
    },

    hasBarAdmissions() {
      return this.barAdmissionList.length > 0;
    },

    courtAdmissions() {
      return this.possibleLawyer.courtAdmissions || [];
    },

    hasCourtAdmissions() {
      return this.courtAdmissions.length > 0;
    },

    schools() {
      return this.possibleLawyer.schools || [];
    },

    hasSchools() {
      return this.schools.length > 0;
    },

    notableExperiences() {
      return this.possibleLawyer.notableExperiences || [];
    },

    hasNotableExperiences() {
      return this.notableExperiences.length > 0;
    },

    lawyerLocation() {
      const address = this.possibleLawyer.lawyer.address;
      const country = address?.country;

      if (country === 'United States') {
        return `${address.city}, ${SharedLists.stateAbbreviationFromName(address.state)}`;
      }
      else {
        return country;
      }
    },

    malpracticeInsurancePresenceText() {
      return this.possibleLawyer.lawyer.hasMalpracticeInsurancePolicy ? 'Yes' : 'No';
    },

    availableSections() {
      let sections = this.allAvailableSections;

      if (this.possibleLawyer.lawyer.accountType === 'paralegal') {
        sections = sections.filter(section => section.slug !== 'experience');
      }
      if (this.isRfqQuote) {
        sections = sections.filter(section => section.slug !== 'rates');
      }

      return sections;
    }
  },

  methods: {
    isSectionSelected(slug) {
      return this.displaySections.includes(slug);
    },

    removeSections(slugs) {
      this.possibleLawyer.displaySections = difference(this.displaySections, slugs);
      this.onChange({ displaySections: this.possibleLawyer.displaySections });
    },

    onSectionsEdited(editedSections) {
      if (xor(editedSections, this.possibleLawyer.displaySections).length) {
        this.possibleLawyer.displaySections = editedSections;
        this.onChange({ displaySections: this.possibleLawyer.displaySections });
      }
    }
  }
};
</script>
