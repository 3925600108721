<template>
  <div>
    <div class="semibold-weight">
      Uploaded LEDES Files
    </div>

    <div v-for="document in ledesDocuments" :key="document.id">
      <a :href="document.file.url" target="_blank">{{ document.name }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatterLedesDocuments',

  props: {
    ledesDocuments: {
      type: Array,
      default: () => []
    }
  }
};
</script>
