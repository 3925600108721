<template>
  <div class="question-wrapper vertical-center">
    <div class="question-icon right-15">
      <svg-icon :name="findIcon" class="base-icon"></svg-icon>
    </div>

    <div class="width-100 right-15">
      <div class="bold-weight">
        {{ question.questionText }}
      </div>

      <div>
        <span class="sub-text">{{ findSubText }}</span>
      </div>
    </div>

    <div v-if="isEditable" class="vertical-center gap-15">
      <rfq-question-form
        v-if="isCustomQuestion"
        :question="question"
        :group="group"
        :is-editing="true">
      </rfq-question-form>

      <button type="button" class="pseudo-link-blue" @click="removeQuestion(question, group)" v-if="canDelete">
        <svg-icon name="trash" class="base-icon blue-4-fill"></svg-icon>
      </button>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import RfqQuestionForm from 'vue-app/scout/client/settings/rfqs/templates/new/question-form.vue';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import { mapActions } from 'pinia';

export default {
  name: 'RfqQuestionItem',

  components: {
    SvgIcon,
    RfqQuestionForm
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    group: {
      type: Object,
      required: true
    },

    isEditable: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    findIcon() {
      switch (this.question.questionType) {
        case 'text':
          return 'form-text';
        case 'file_upload':
          return 'form-upload';
        case 'option':
          return 'form-radio-button';
        case 'range':
          return 'form-dollar';
        default:
          return 'form-text';
      }
    },

    findSubText() {
      switch (this.question.questionType) {
        case 'text':
          return 'Short answer text';
        case 'file_upload':
          return 'File Upload';
        case 'option':
          return 'Radio buttons + short answer text';
        case 'range':
          return 'Dollar amount(s)';
        default:
          return '';
      }
    },

    isCustomQuestion() {
      return this.question.isCustomQuestion;
    },

    ratesAndPricing() {
      return this.group.name === 'Rates & Pricing';
    },

    canDelete() {
      return !this.ratesAndPricing || this.isCustomQuestion;
    }
  },

  methods: {
    ...mapActions(useRfqTemplateStore, { removeQuestion: 'removeQuestion' })
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .question-wrapper {
    padding: 10px 15px;
    border: 1px solid $k-gray;
    border-radius: $border-radius-large;
  }

  .sub-text {
    color: $pill-gray;
    font-size: 12px;
    border-bottom: 1px dotted $pill-gray;
  }
</style>
