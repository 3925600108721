<template>
  <div>
    <priori-modal ref="modal" title="New Data Upload" @on-modal-close="reset">
      <template #modal-trigger>
        <tooltip text="You won't be able to submit a new file until the last one is complete." :enable="uploadInProgress">
          <div>
            <button type="button" :class="['secondary-btn-blue a-button-size', { 'disabled': uploadInProgress }]" @click="openModalIfPermitted">Upload Scout Firm Data</button>
          </div>
        </tooltip>
      </template>

      <template #default="{ closeModal }">
        <div class="size-text-14px bold-weight bottom-5">
          Instructions for Upload
        </div>

        <p class="size-text-14px">
          Please make sure you are uploading a file in JSON format. We do not support any other type of file.
        </p>

        <form role="form" class="a-form" novalidate>
          <div v-if="file.name" class="row top-30 bottom-50">
            <div class="col-xs-8 semibold-weight">
              {{ file.name }}
            </div>

            <div class="col-xs-4 text-right">
              <a href="" @click.prevent="deleteFile">Delete</a>
            </div>
          </div>

          <div v-else>
            <div class="top-30 bottom-30">
              <file-selector class="dash-container" accept=".json" @change="setFile">
                <div class="primary-button-compact clickable">
                  Select a File<span class="hidden-xs"> From Your Device</span>
                </div>
              </file-selector>
            </div>

            <p>
              <span class="semibold-weight">NOTE:</span> Some data fields that have been manually updated by Scout users may not be overwritten by the JSON upload.
            </p>
          </div>

          <div class="row tight-columns top-30">
            <div class="col-sm-4">
              <loading-button name="submitUpload" class="primary-btn-blue a-button-size" @lb-click="uploadFile">Submit</loading-button>
            </div>

            <div class="col-sm-3 top-20-xs">
              <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
            </div>
          </div>

          <div class="error-text top-5" v-if="error.length > 0">
            {{ error }}
          </div>
        </form>
      </template>
    </priori-modal>
  </div>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import NotificationService from 'vue-app/shared/services/notification-service';

export default {
  name: 'UploadModal',

  components: {
    PrioriModal,
    FileSelector,
    LoadingButton
  },

  props: {
    uploader: {
      type: Object,
      default: () => ({})
    },

    onCreation: {
      type: Function,
      default: () => {}
    },

    uploadInProgress: {
      type: Boolean,
      default: false
    },

    uploadInitializer: {
      type: Function,
      required: true
    },

    lawFirmId: {
      type: Number
    },

    currentUpload: {
      type: Function,
      required: true
    },

    uploadType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      file: {},
      error: ''
    };
  },

  computed: {
    fileSelected() {
      return this.file instanceof File;
    }
  },

  methods: {
    reset() {
      this.file = {};
      this.error = '';
    },

    setFile(files) {
      this.file = files[0];

      if (this.fileSelected) {
        this.error = '';
      }
    },

    deleteFile() {
      this.file = {};
    },

    uploadFile() {
      if (!this.fileSelected) {
        this.error = 'Please select a file to upload.';
      }
      else {
        this.error = '';

        LoadingService.loading('submitUpload');

        new this.uploadInitializer(this.file, this.uploader, this.currentUpload, this.uploadType, this.lawFirmId)
          .process()
          .then((initializer) => {
            if (initializer.upload) { this.onCreation(initializer.upload); }

            initializer.enqueue().then(() => {
              NotificationService.success('We have received your data. We will be processing soon. Check the status on the dashboard for more information.');
              this.$refs.modal.closeModal();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            });

            return initializer;
          })
          .catch((initializer) => {
            if (initializer.upload) { this.onCreation(initializer.upload); }

            this.error = initializer.error;
          })
          .finally(() => {
            LoadingService.done('submitUpload');
            this.file = {};
          });
      }
    },

    openModalIfPermitted() {
      if (this.uploadInProgress) { return; }

      this.$refs.modal.openModal();
    }
  }
};
</script>
