<template>
  <div class="shadowed-box">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-8 text-uppercase">
            Client Information
          </div>

          <div class="col-sm-4 size-text-13px text-right-not-xs">
            <a href="" class="normal-weight" @click.prevent="startEdit" v-if="!isEditing">Edit</a>

            <span v-if="isEditing">
              <a href="" class="normal-weight right-15" @click.prevent="handleSubmit(save)" :disabled="isLoading">Save</a>
              <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
            </span>
          </div>
        </div>
      </div>

      <div class="box-content symmetrical">
        <form role="form" class="a-form" autocomplete="off" novalidate>
          <div class="row bottom-30">
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="col-sm-4 semibold-weight">
                First Name<span class="red-text" v-if="isEditing"> *</span>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </div>

              <div class="col-sm-8">
                <div v-if="!isEditing">
                  {{ client.firstName }}
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <input type="text" name="firstName" id="first-name" class="form-control" required v-model="clientCopy.firstName">
                </div>
              </div>
            </validation-provider>
          </div>

          <div class="row bottom-30">
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="col-sm-4 semibold-weight">
                Last Name<span class="red-text" v-if="isEditing"> *</span>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </div>

              <div class="col-sm-8">
                <div v-if="!isEditing">
                  {{ client.lastName }}
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <input type="text" name="lastName" id="last-name" class="form-control" required v-model="clientCopy.lastName">
                </div>
              </div>
            </validation-provider>
          </div>

          <div class="row bottom-30">
            <validation-provider rules="required|email" v-slot="{ errors }">
              <div class="col-sm-4 semibold-weight">
                Email<span class="red-text" v-if="isEditing"> *</span>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </div>

              <div class="col-sm-8">
                <div class="wrap-word" v-if="!isEditing">
                  {{ client.email }}
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <input type="email" name="email" id="email" class="form-control" required v-model="clientCopy.email">
                </div>
              </div>
            </validation-provider>
          </div>

          <div class="row bottom-30">
            <div class="col-sm-4 semibold-weight">
              Job Title
            </div>

            <div class="col-sm-8">
              <div v-if="!isEditing">
                {{ client.role || '&mdash;' }}
              </div>

              <div class="top-10-xs" v-if="isEditing">
                <input type="text" name="role" id="role" class="form-control" v-model="clientCopy.role">
              </div>
            </div>
          </div>

          <div class="row">
            <validation-provider :rules="phoneRules" v-slot="{ errors }">
              <div class="col-sm-4 semibold-weight">
                Phone<span class="red-text" v-if="isEditing && !client.invitedById"> *</span>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </div>

              <div class="col-sm-8">
                <div v-if="!isEditing">
                  {{ client.phoneNumber || '&mdash;' }}
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <input type="text" name="phoneNumber" id="phone-number" class="form-control" v-model="clientCopy.phoneNumber">
                </div>
              </div>
            </validation-provider>
          </div>

          <div class="row top-30" v-if="isEditing">
            <div class="col-sm-5 col-md-4 ">
              <loading-button name="updateClientButton" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(save)" :lb-disabled="failed">Save</loading-button>
            </div>

            <div class="col-sm-5 col-md-4 top-10-xs">
              <button type="button" class="secondary-btn-blue a-button-size" @click="cancelEdit">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ClientAccountInfo',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      clientCopy: {}
    };
  },

  computed: {
    phoneRules() {
      return this.client.invitedById ? null : 'required';
    },

    isLoading() {
      return LoadingService.isLoading('updateClientButton');
    }
  },

  methods: {
    startEdit() {
      this.clientCopy = Object.assign({}, this.client);
      this.isEditing = true;
    },

    cancelEdit() {
      this.$refs.observer.reset();
      this.isEditing = false;
    },

    save() {
      LoadingService.loading('updateClientButton');

      this.onSave(this.clientCopy).then(
        () => {
          LoadingService.done('updateClientButton');
          this.isEditing = false;
        },
        () => {
          LoadingService.done('updateClientButton');
        }
      );
    }
  }
};
</script>
