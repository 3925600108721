<template>
  <div id="matter-client-info" class="size-text-13px">
    <div class="bottom-15">
      <div class="semibold-weight">
        Client
      </div>

      <div class="wrap-word">
        <a :href="client.path" v-if="client.klass === 'Client'">{{ client.fullName }}</a>
        <span v-else>{{ client.fullName }}</span>
      </div>
    </div>

    <div class="bottom-15" v-if="client.klass === 'Client'">
      <div class="semibold-weight">
        Impersonate Client
      </div>

      <div>
        <impersonate-modal :user="client" target="_self">
          <a href="">Impersonate</a>
        </impersonate-modal>
      </div>
    </div>

    <div>
      <div class="semibold-weight">
        Message Client
      </div>

      <div>
        <a href="" @click.prevent="messageClient">Send Message</a>
      </div>
    </div>

    <div class="top-15" v-if="client.klass === 'PotentialClient'">
      <div class="semibold-weight">
        Send Account Invite
      </div>

      <div>
        <a href="" @click.prevent="onSendInvite">Compose Message</a>
      </div>
    </div>

    <div class="top-20 bottom-20">
      <hr class="kenny-hr no-margin">
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Email
      </div>

      <div class="wrap-word">
        {{ client.email }}
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Job Title
      </div>

      <div>
        {{ valueOrDefault(client.role) }}
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Company
      </div>

      <div>
        <a :href="client.teamPath" target="_blank" v-if="clientHasTeam">{{ client.teamName }}</a>
        <span v-else>&mdash;</span>
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Phone
      </div>

      <div>
        {{ valueOrDefault(client.phoneNumber) }}
      </div>
    </div>

    <div class="top-20 bottom-20">
      <hr class="kenny-hr no-margin">
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Member Since
      </div>

      <div>
        {{ formattedDate(client.createdAt) }}
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        SF Contact ID
      </div>

      <div>
        {{ valueOrDefault(client.contactId) }}
      </div>
    </div>

    <div>
      <div class="semibold-weight">
        SF Lead ID
      </div>

      <div>
        {{ valueOrDefault(client.leadId) }}
      </div>
    </div>
  </div>
</template>

<script>
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import NullableValueFilter from 'vue-app/shared/lib/nullable-value-filter.js';

export default {
  name: 'MatterClient',

  components: {
    ImpersonateModal
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    onSendInvite: {
      type: Function,
      required: true
    },

    sendMessageService: {
      type: Object,
      required: true
    }
  },

  computed: {
    clientHasTeam() {
      return this.client.teamName && this.client.teamName.length && this.client.teamPath;
    }
  },

  methods: {
    messageClient() {
      return this.sendMessageService.modal([this.client]);
    },

    formattedDate(date) {
      return this.valueOrDefault(DateFilter.long(date));
    },

    valueOrDefault(value) {
      return NullableValueFilter.defaultValue(value);
    }
  }
};
</script>
