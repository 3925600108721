const checkScroll = {
  data() {
    return {
      isScrolledDown: false,
      isScrolledUp: true,
      isScrollable: false,
      maxHeight: 170
    };
  },

  computed: {
    scrollable() {
      return this.$refs.scrollable;
    },

    scrollableParent() {
      return this.scrollable.parentElement;
    }
  },

  mounted() {
    this.checkScrollable();
  },

  updated() {
    this.checkScrollable();
    this.checkScroll();
  },

  methods: {
    checkScrollable() {
      if (this.scrollableParent.clientHeight >= this.maxHeight) {
        this.isScrollable = true;
      }
      else {
        this.isScrollable = false;
      }
    },

    checkScroll() {
      const bottomOffset = this.scrollable.scrollHeight - this.scrollable.clientHeight;

      if (this.scrollable.scrollTop > 0) {
        this.isScrolledDown = true;
      }
      else {
        this.isScrolledDown = false;
      }

      if (this.scrollable.scrollTop < bottomOffset - 1) {
        this.isScrolledUp = true;
      }
      else {
        this.isScrolledUp = false;
      }
    }
  }
};

export default checkScroll;
