<template>
  <div name="form" class="a-form filter-popover">
    <div class="text-right bottom-15">
      <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
        Clear Filter
      </button>
    </div>

    <div class="row vertical-center">
      <div class="col-xs-6 semibold-weight">
        Required
      </div>

      <div class="col-xs-6 text-right">
        <button type="button" :class="['toggle-button md', { 'toggle-on': isFilterContext }]" @click="switchFilterContext">
          {{ isFilterContext ? 'Yes' : 'No' }}
        </button>
      </div>
    </div>

    <div class="row tight-columns top-20" v-if="showTextInputs">
      <div class="col-xs-6 col-sm-5">
        <label :for="`${field}-min`">{{ minLabel }}</label>

        <validation-provider :rules="`integer|numeric|minValue:${min}|maxValue:${upper}`" v-slot="{ errors }">
          <input
            type="number"
            :id="`${field}-min`"
            :name="`${field}Min`"
            :class="['form-control', { 'has-error': errors.length }]"
            autocomplete="off"
            :min="min"
            :max="max"
            :step="step"
            required
            v-model="lower"
            @input="debouncedInputChange">
        </validation-provider>
      </div>

      <div class="col-xs-6 col-sm-5 col-sm-offset-2">
        <label :for="`${field}-max`">{{ maxLabel }}</label>

        <validation-provider :rules="`integer|numeric|minValue:${lower}|maxValue:${max}`" v-slot="{ errors }">
          <input
            type="number"
            :id="`${field}}-max`"
            :name="`${field}}Max`"
            :class="['form-control', { 'has-error': errors.length }]"
            autocomplete="off"
            :min="min"
            :max="max"
            :step="step"
            required
            v-model="upper"
            @input="debouncedInputChange">
        </validation-provider>
      </div>
    </div>

    <div class="top-50 slider-container" v-if="showSlider">
      <vue-slider
        v-model="sliderValue"
        v-bind="sliderOptions"
        @change="onSliderChange">
      </vue-slider>
    </div>

    <div class="top-20" v-if="!lockContext">
      <div class="filter-footer multiline semibold-weight">
        Set the “Required” toggle to “Yes” to ensure all search results meet the selected criteria.
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import SearchFilter from 'vue-app/shared/mixins/search-filter.js';
import VueSlider from 'vue-slider-component';
import { debounce, isEmpty, min, max } from 'lodash';

export default {
  name: 'FilterRange',

  components: {
    VueSlider,
    ValidationProvider
  },

  mixins: [
    SearchFilter
  ],

  props: {
    searchService: {
      type: Object,
      required: true
    },

    filterParams: {
      type: Object,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    const { min, max, units, slug, field, step, lockContext, inputType } = this.filterParams;

    return {
      slug,
      field,
      min,
      max,
      lower: min,
      upper: max,
      step,
      lockContext: !!lockContext,
      units,
      showSlider: inputType === 'slider',
      showTextInputs: inputType === 'text',
      minLabel: `Min${units ? ' ' + units : ''}`,
      maxLabel: `Max${units ? ' ' + units : ''}`,
      sliderOptions: {
        min,
        max,
        dotSize: 16,
        interval: 1,
        step: step,
        lazy: true,
        duration: 0.01,
        tooltip: 'always'
      },
      sliderValue: [min, max]
    };
  },

  created() {
    this.debouncedInputChange = debounce(() => {
      const lower = Number(this.lower);
      const upper = Number(this.upper);

      if (lower < 0 || upper > this.max || lower > upper) { return; }

      this.lower = Math.floor(lower);
      this.upper = Math.floor(upper);

      this.sliderValue[0] = Number(min([this.lower, this.upper]));
      this.sliderValue[1] = Number(max([this.lower, this.upper]));

      this.onChanged();
    }, 200).bind(this);
  },

  mounted() {
    const currentSelections = this.searchService.currentSelections(this.field, this.filterContext, true);
    if (!isEmpty(currentSelections)) {
      this.lower = this.searchService.currentSelections(this.field, this.filterContext, true).gte;
      this.upper = this.searchService.currentSelections(this.field, this.filterContext, true).lte;
      this.sliderValue = [this.lower, this.upper];
    }
  },

  methods: {
    clearFilter() {
      this.lower = this.min;
      this.upper = this.max;
      this.sliderValue = [this.min, this.max];
      this.searchService.unsetFilter(this.field);
      this.onSearch();
    },

    onChanged() {
      this.lower = this.sliderValue[0];
      this.upper = this.sliderValue[1];

      if (this.lower > this.min || this.upper < this.max) {
        this.searchService.setFilterParams(
          this.field,
          {
            gte: this.lower,
            lte: this.upper
          },
          this.filterContext
        );
      }
      else {
        this.searchService.unsetFilter(this.field);
      }

      this.onSearch();
    },

    onSliderChange() {
      this.lower = this.sliderValue[0];
      this.upper = this.sliderValue[1];
      this.searchService.setFilterParams(
        this.field,
        {
          gte: this.lower,
          lte: this.upper
        },
        this.filterContext
      );
      this.onSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .slider-container {
    padding: 0 10px;
  }

  .k-form .has-error, .a-form .has-error {
    color: $k-darker-gray;
  }

  :deep(.vue-slider-dot-tooltip-top) {
    top: -6px;
  }
</style>
