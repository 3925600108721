<template>
  <edit-modal title="Editing Awards" :input="lawyer" :on-save="handleOnSave" @on-modal-open="openModal">
    <template #default>
      <div class="bottom-30">
        <div class="section-header">
          Awards
        </div>

        <div class="text-italic pill-gray-text size-text-12px bottom-15">
          Please list any awards that you want to appear on your profile. Bullets will appear as a separate paragraph.
        </div>

        <field-group class="bottom-20" v-for="(award, index) in awards" :key="index" :on-remove-item="() => { removeAward(index) }" item-type="award">
          <template #input>
            <div class="bold-weight bottom-5">
              Awards Title
            </div>

            <tiptap-editor v-model="award.description" :buttons="['bulletList', 'orderedList']"></tiptap-editor>
          </template>
        </field-group>

        <a href="" @click.prevent="addAward" class="semibold-weight">{{ `+ Add ${awards.length ? 'Another' : 'Award'}` }}</a>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import FieldGroup from 'vue-app/scout/shared/field-group.vue';
import DataLock from 'resources/data-lock';
import { changes } from 'misc/resource-helpers';
import { map, some, uniqueId } from 'lodash';

export default {
  name: 'EditLawyerAwards',

  components: {
    EditModal,
    TiptapEditor,
    FieldGroup
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  data() {
    return {
      awards: []
    };
  },

  computed: {
    awardsPresent() {
      return !!this.lawyer.sanitizedAwards.length;
    }
  },

  methods: {
    openModal() {
      this.resetForm();
    },

    addAward() {
      this.awards.push({ description: '' });
    },

    handleOnSave() {
      const editedAwards =  map(this.awards, 'description');

      if (!some(changes(this.lawyer.unsanitizedAwards, editedAwards))) { return; }

      const lawyerAttributes = {
        lawyerProfileAttributes: {
          id: this.lawyer.lawyerProfileId,
          unsanitizedAwards: editedAwards
        }
      };

      this.onSave(lawyerAttributes, () => {
        DataLock.save({
          dataLock: {
            flag:         'awards',
            resourceId:   this.lawyer.id,
            resourceType: 'lawyer'
          }
        });
      });
    },

    removeAward(index) {
      this.awards.splice(index, 1);
    },

    resetForm() {
      if (this.awardsPresent) {
        this.awards = map(this.lawyer.sanitizedAwards, award => ({ id: uniqueId(), description: award }));
      }
      else {
        this.awards = [{ description: '' }];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .section-header {
    border-bottom: 1px solid $k-light-gray;
    font-weight: 700;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
</style>
