<template>
  <form role="form" name="form" class="a-form filter-popover" novalidate>
    <div class="text-right bottom-15">
      <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
        Clear Filter
      </button>
    </div>

    <div v-if="hasSelectedTerms && !lockContext" class="vertical-center justify-between semibold-weight tight-lines top-15 bottom-15">
      <div>
        Required
      </div>

      <div class="d-flex col-gap-5">
        <button type="button" :class="['toggle-button md', { 'toggle-on': isFilterContext }]" @click="switchFilterContext">
          {{ isFilterContext ? 'Yes' : '&nbsp;No' }}
        </button>
      </div>
    </div>

    <div class="bottom-20" v-if="modalLabel || hint">
      <div v-if="modalLabel" class="bold-weight">
        {{ modalLabel }}
      </div>

      <div :class="['tight-lines', { 'top-5': modalLabel }]" v-if="hint">
        {{ hint }}
      </div>
    </div>

    <label
      class="check-option vertical medium-large top-10"
      :for="`${inputId}-${index}`"
      v-for="(option, index) in options"
      :key="index">
      {{ option.label }}

      <input
        type="radio"
        :name="`options-${slug}`"
        :id="`${inputId}-${index}`"
        v-model="filterValue"
        @change="select"
        :value="option.value">

      <span class="check c-radio"></span>
    </label>

    <div v-if="!lockContext" class="filter-footer multiline semibold-weight">
      Set the “Required” toggle to “Yes” to ensure all search results meet the selected criteria.
    </div>
  </form>
</template>

<script>
import SearchFilter from 'vue-app/shared/mixins/search-filter.js';
import { uniqueId } from 'lodash';

export default {
  name: 'FilterRadio',

  mixins: [SearchFilter],

  props: {
    filterParams: {
      type: Object,
      required: true
    },

    searchService: {
      type: Object,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    const { slug, hint, modalLabel, options } = this.filterParams;

    return {
      slug,
      hint,
      modalLabel,
      options,
      filterValue: null
    };
  },

  computed: {
    inputId() {
      return uniqueId(`options-${this.slug}-`);
    }
  },

  mounted() {
    const currentSelection = this.searchService.currentSelections(this.field, this.filterContext);
    this.filterValue       = currentSelection[0];
  },

  methods: {
    select() {
      this.searchService.setFilterParams(this.field, [this.filterValue], this.filterContext);
      this.onSearch();
    },

    clearFilter() {
      this.filterValue = null;
      this.searchService.unsetFilter(this.field);
      this.onSearch();
    }
  }
};
</script>
