<template>
  <modal v-model="modalOpen" size="md" :header="false" :footer="false" :backdrop="true" :keyboard="true" append-to-body>
    <div class="close-modal full-screen" data-toggle="modal" @click="dismissModal()">
      <svg-icon name="x3" class="base-icon"></svg-icon>
    </div>

    <div class="notice-modal">
      <div class="notice-header">
        <div class="text-center bottom-20">
          <div class="circular-gradient">
            <svg-icon name="checkmark-no-circle" class="base-icon checkmark"></svg-icon>
          </div>
        </div>

        Review submitted successfully
      </div>

      <div class="notice-regular-text">
        <div class="size-text-16px">
          Your review has been submitted. You can edit your review on the “Reviews” page.
        </div>

        <div v-if="showMatterDetails" class="top-30">
          <div class="semibold-weight bottom-10">
            Continue reviewing lawyers from {{ matter.name }} ({{ reviewableCountText }})
          </div>

          <ul class="reviewable-lawyers no-bullets">
            <li class="separated-list-items" v-for="lawyer in reviewableBridges" :key="lawyer.id">
              <div class="row vertical-center-not-xs">
                <div class="col-sm-9">
                  <lawyer-name-plate :lawyer="lawyer" photo-size="small" :show-favorite="false"></lawyer-name-plate>
                </div>

                <div class="col-sm-3 top-20-xs">
                  <a :href="newReviewUrl(lawyer)" class="secondary-btn-blue" target="_self">Review</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import SvgIcon from 'vue-app/shared/components/svg-icon';
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';

export default {
  name: 'ScoutReviewSuccessModal',

  components: {
    SvgIcon,
    LawyerNamePlate
  },

  mixins: [
    modalToggle
  ],

  props: {
    review: {
      type: Object
    }
  },

  computed: {
    matter() {
      return this.review.matter;
    },

    lawyers() {
      return this.matter.scoutMatterLawyers.map(timekeeping => timekeeping.lawyer);
    },

    reviewableLawyers() {
      return this.lawyers.filter((lawyer) => lawyer.id !== this.review.resource.id);
    },

    reviewableBridges() {
      return this.reviewableLawyers.map((rawBridge) => {
        return new ScoutLawyerBridge(rawBridge);
      });
    },

    reviewableCountText: function () {
      const count = this.reviewableBridges.length;
      return `${count} ${this.$pluralize('Lawyer', count)}`;
    },

    showMatterDetails() {
      return !(_.isEmpty(this.review) || _.isEmpty(this.matter)) && _.some(this.reviewableBridges);
    }
  },

  mounted: function () {
    this.modalOpen = true;
  },

  methods: {
    newReviewUrl(lawyer) {
      if (this.matter) {
        return `/scout-company/lawyers/${lawyer.id}/reviews/new?matterId=${this.matter.id}`;
      }
      else {
        return `/scout-company/lawyers/${lawyer.id}/reviews/new`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .checkmark {
    margin-top: 16px;
    width: 20px;
    height: 20px;
    fill: $white;
  }

  .notice-modal .notice-header {
    height: auto;
  }

  .reviewable-lawyers {
    border-top: 1px solid $k-light-gray;
    border-bottom: 1px solid $k-light-gray;
    padding: 20px 0;

    @media (min-width: 768px) {
      max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    li .row { padding: 0 20px; }
  }
</style>
