<template>
  <div class="row">
    <div class="col-xs-8 col-sm-4 size-text-13px semibold-weight">
      <div class="text-ellipsis list-spacing-5" v-for="other in otherParticipants" :key="other.id">
        {{ other.firstName }} {{ other.lastName }}
      </div>
    </div>

    <div class="col-xs-4 col-sm-2 col-sm-push-6 text-right">
      <dropdown-menu>
        <li class="menu-item" role="menuitem">
          <a href="" @click.prevent="messageOtherParticipant"><svg-icon name="envelope" class="base-icon"></svg-icon>Message {{ otherParticipant.firstName }}</a>
        </li>

        <li class="menu-item" role="menuitem">
          <a href="" @click.prevent="openRescheduleAppointmentModal"><svg-icon name="calendar" class="base-icon"></svg-icon>Reschedule {{ consultationType }}</a>
        </li>

        <li class="menu-item" role="menuitem">
          <a href="" @click.prevent="openCancelAppointmentModal"><svg-icon name="reject" class="base-icon"></svg-icon>Cancel {{ consultationType }}</a>
        </li>
      </dropdown-menu>
    </div>

    <div class="col-xs-12 col-sm-3 col-sm-pull-2 top-20-xs">
      <svg-icon name="calendar" class="base-icon dash-call-icon"></svg-icon>

      <div class="dash-call-details">
        <div>
          {{ consultation.startsAtDay }}
        </div>

        <div>
          {{ consultation.startsAtDate }}
        </div>

        <div>
          {{ consultation.startsAtTime }}
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-3 col-sm-pull-2 top-20-xs">
      <div v-if="consultation.type === 'phone'">
        <svg-icon name="phone" class="base-icon dash-call-icon"></svg-icon>

        <div class="dash-call-details">
          <div>
            {{ twilioPhoneText }}
          </div>

          <div>
            PIN: {{ phoneCode }}
          </div>
        </div>
      </div>

      <div v-if="consultation.type === 'in-person'">
        <svg-icon name="location" class="base-icon dash-call-icon"></svg-icon>

        <div class="dash-call-details">
          <div v-if="location">
            <div v-if="location.title.length">
              {{ location.title }}
            </div>

            <div v-if="location.address.length">
              {{ location.address }}
            </div>

            <div v-if="location.city.length || location.state.length">
              {{ cityStateString }}
            </div>

            <div v-if="location.zip.length">
              {{ location.zip }}
            </div>

            <div v-if="location.note.length">
              NOTE: {{ location.note }}
            </div>
          </div>

          <div v-else>
            In-Person Meeting
          </div>
        </div>
      </div>

      <div v-if="consultation.type === 'video'">
        <svg-icon name="video" class="base-icon dash-call-icon"></svg-icon>

        <div class="dash-call-details">
          <div>
            <a :href="`/public-app/video-call/${videoCallToken}`" target="_blank">Join Call</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ConfigService from 'vue-app/shared/services/config-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'CallSchedule',

  components: {
    DropdownMenu,
    SvgIcon
  },

  props: {
    consultation: {
      type: Object,
      required: true
    },

    onMessageUser: {
      type: Function,
      required: true
    },

    onCancelConsultation: {
      type: Function,
      required: true
    },

    onRescheduleConsultation: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    otherParticipants() {
      return this.consultation.participantsOtherThan(this.user).map(ap => ap.participant);
    },

    otherParticipant() {
      return this.otherParticipants[0];
    },

    consultationType() {
      return this.consultation.type === 'in-person' ? 'Meeting' : 'Call';
    },

    twilioPhoneText() {
      return ConfigService.twilioPhoneText;
    },

    phoneCode() {
      return this.consultation.activityFor(this.user).phoneCode;
    },

    location() {
      return this.consultation.location;
    },

    cityStateString() {
      const { city, state } = this.consultation.location;
      return state ? `${city}, ${state}` : city;
    },

    videoCallToken() {
      return this.consultation.activityFor(this.user).videoCallToken;
    }
  },

  methods: {
    messageOtherParticipant() {
      this.onMessageUser(this.otherParticipant);
    },

    openCancelAppointmentModal() {
      this.consultation.loadAppointment().then((appointment) => {
        this.onCancelConsultation(appointment, this.otherParticipants);
      });
    },

    openRescheduleAppointmentModal() {
      this.consultation.loadAppointment().then((appointment) => {
        this.onRescheduleConsultation(this.consultation, appointment, this.otherParticipants);
      });
    }
  }
};
</script>
