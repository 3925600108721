<template>
  <div class="sidenav-content">
    <div class="container-fluid">
      <div class="padded-container">
        <div class="row">
          <div class="col-sm-5">
            <div class="medium-page-heading bottom-30">
              Admin Dashboard
            </div>
          </div>

          <div class="col-sm-7 bottom-40 size-text-12px text-right-not-xs">
            <a href="/public-app/rfp/contact" class="compact-btn-gray right-10" target="_self">Create RFP</a>

            <div class="visible-xs-block bottom-15"></div>

            <priori-modal modal-id="create-law-firm">
              <template #modal-trigger="{ openModal }">
                <a href="" class="compact-btn-gray right-10" @click.prevent="openModal">Create Law Firm</a>
              </template>

              <template #title>
                Create New Law Firm
              </template>

              <law-firm-create-form></law-firm-create-form>
            </priori-modal>

            <div class="visible-xs-block bottom-15"></div>

            <priori-modal modal-id="create-workspace">
              <template #modal-trigger="{ openModal }">
                <a href="" class="compact-btn-gray no-margin" @click.prevent="openModal">Create Team</a>
              </template>

              <template #title>
                Create New Team
              </template>

              <create-workspace-form></create-workspace-form>
            </priori-modal>
          </div>
        </div>

        <div class="row md-columns">
          <div class="col-md-8">
            <div class="bottom-20">
              <consultations-today :consultations="consultationsToday"></consultations-today>
            </div>

            <div class="bottom-20">
              <new-requests :counsel-requests="newRecentCounselRequests"></new-requests>
            </div>

            <div class="row md-columns">
              <div class="col-md-6 bottom-20">
                <new-applicants :lawyers="newApplicants"></new-applicants>
              </div>

              <div class="col-md-6 bottom-20">
                <malpractice-expired :lawyers="expiredMalpracticeInsurance"></malpractice-expired>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="bottom-20">
              <admin-links></admin-links>
            </div>

            <div>
              <new-applications :lawyers="newSubmissions"></new-applications>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateWorkspaceForm from 'vue-app/admin/workspaces/create-workspace-form.vue';
import LawFirmCreateForm from 'vue-app/admin/law-firms/law-firm-create-form.vue';
import ConsultationsToday from './consultations-today.vue';
import MalpracticeExpired from './malpractice-expired.vue';
import NewApplicants from './new-applicants.vue';
import NewApplications from './new-applications.vue';
import NewRequests from './new-requests.vue';
import AdminLinks from 'vue-app/admin/toolbox/admin-links.vue';

export default {
  name: 'AdminDashboardIndex',

  components: {
    CreateWorkspaceForm,
    LawFirmCreateForm,
    ConsultationsToday,
    MalpracticeExpired,
    NewApplicants,
    NewApplications,
    NewRequests,
    AdminLinks
  },

  props: {
    consultationsToday: {
      type: Array,
      default: () => []
    },

    newRecentCounselRequests: {
      type: Array,
      default: () => []
    },

    newApplicants: {
      type: Array,
      default: () => []
    },

    expiredMalpracticeInsurance: {
      type: Array,
      default: () => []
    },

    newSubmissions: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
  section.uiv {
    display: inline;
  }
</style>
