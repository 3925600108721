<template>
  <div class="box-content contrasting">
    <div class="row tight-columns clickable" @click="toggleExplanations">
      <div class="col-xs-10 semibold-weight">
        <span class="right-5">Score: {{ totalScore }}</span> |
        <span class="left-5">{{ criteriaMetCount }}</span>
      </div>

      <div class="col-xs-2 text-right">
        <svg-icon :name="expanded ? 'minus' : 'plus'" class="base-icon smaller clickable"></svg-icon>
      </div>
    </div>

    <div v-if="expanded" class="top-30">
      <div class="content-section">
        <div class="row tight-columns">
          <div class="col-xs-6 bold-weight text-uppercase">
            Criteria Met
          </div>

          <div class="col-xs-6 text-right">
            <span v-tooltip="{ text: tooltipText, customClass: 'k-tooltip' }"><svg-icon name="info" class="base-icon info-icon"></svg-icon></span>
            <span class="bold-weight left-5">SCORE</span>
          </div>
        </div>

        <hr class="search-separator">

        <div class="tight-lines" v-if="matched.length">
          <div v-for="(node, index) in matched" :key="`${node.label}-${index}`">
            <div class="row tight-columns">
              <div class="col-xs-4 semibold-weight">
                <div class="row no-gutter">
                  <div class="col-sm-2 hidden-xs">
                    <svg-icon name="checkmark" class="base-icon lighter-gray smaller bottom-2"></svg-icon>
                  </div>

                  <div class="col-xs-12 col-sm-10">
                    <span v-if="node.isFilterContext">{{ node.label }}&nbsp;*</span>
                    <span v-else>{{ node.label }}</span>
                  </div>
                </div>
              </div>

              <div class="col-xs-5">
                {{ node.value }}
              </div>

              <div class="col-xs-3 text-right">
                {{ node.isFilterContext ? "Must include" : node.score }}
              </div>
            </div>

            <div v-if="node.highlights" class="search-highlights top-10 bottom-15">
              <keyword-highlights :keyword-highlights="node.highlights"></keyword-highlights>
            </div>

            <hr class="search-separator">
          </div>
        </div>

        <div class="missing-text top-10" v-if="!matched.length">
          None
        </div>

        <div class="text-right top-10 bold-weight text-uppercase">
          Total Score: {{ totalScore }}
        </div>
      </div>

      <div class="content-section top-15">
        <div class="bold-weight text-uppercase bottom-10">
          Criteria Not Met
        </div>

        <hr class="search-separator">

        <div class="tight-lines" v-if="unmatched.length">
          <div v-for="(node, index) in unmatched" :key="`${node.label}-${index}`">
            <div class="row tight-columns">
              <div class="col-xs-4 semibold-weight">
                <div class="row no-gutter">
                  <div class="col-sm-2 hidden-xs">
                    <svg-icon name="reject" class="base-icon red smaller bottom-2"></svg-icon>
                  </div>

                  <div class="col-xs-12 col-sm-10">
                    {{ node.label }}
                  </div>
                </div>
              </div>

              <div class="col-xs-5">
                {{ node.value }}
              </div>

              <div class="col-xs-3 text-right">
                {{ node.score }}
              </div>
            </div>

            <hr class="search-separator" v-if="index !== unmatched.length - 1">
          </div>
        </div>

        <div class="missing-text top-10" v-if="!unmatched.length">
          None
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import KeywordHighlights from './keyword-highlights.vue';
import { filter, partition, startCase, sumBy, sortBy } from 'lodash';

export default {
  name: 'SearchHighlights',

  components: {
    SvgIcon,
    KeywordHighlights
  },

  props: {
    searchResult: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      expanded: false,
      tooltipText: 'Relevance scores are calculated by the underlying search engine, using weights to boost the values for individual filter types. Results are summed across all filters.'
    };
  },

  computed: {
    explanation() {
      return partition(this.searchResult.explanation, { isMatch: true });
    },

    matched() {
      const matched = filter(this.explanation[0], { hide: false });

      return sortBy(matched, (node) => node.label);
    },

    unmatched() {
      const unmatched = filter(this.explanation[1], { hide: false });

      return sortBy(unmatched, (node) => node.label);
    },

    totalScore() {
      return (sumBy(this.matched, (match) => (match.isFilterContext ? 0 : parseFloat(match.score)))).toFixed(1);
    },

    criteriaMetCount() {
      return `${this.matched.length} out of ${filter(this.searchResult.explanation, { hide: false }).length} criteria met`;
    }
  },

  methods: {
    normalizedField(field) {
      return startCase(field);
    },

    toggleExplanations() {
      this.expanded = !this.expanded;
    },

    toggleHighlights(highlights) {
      highlights.expanded = !highlights.expanded;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .search-highlights {
    margin-left: 15px;

    @media (min-width: 768px) {
      margin-left: 40px;
    }
  }

  hr.search-separator {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .info-icon {
    width: 13px;
    margin-bottom: 2px;
    fill: $k-dark-gray;
  }
</style>
