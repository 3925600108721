<template>
  <div>
    <div class="rating-circle bottom-20">
      <rating-circle :rating="rating" :size="'large'">
        <div v-if="scoreDataPresent">
          <span class="size-text-36px">{{ recommendationScore }}</span>
          <span class="size-text-18px dark-gray-text"> / 10</span>
        </div>

        <div v-else class="size-text-36px">
          N/A
        </div>
      </rating-circle>
    </div>

    <div v-if="scoreDataPresent" class="size-text-16px text-center">
      Score is based on
      <span v-if="matterReviewsCount > 0"><b>{{ matterReviewsCountText }}</b> across <b>{{ matterCountText }}</b></span>
      <span v-if="matterReviewsCount > 0 && generalReviewsCount > 0">and</span>
      <span v-if="generalReviewsCount > 0"><b>{{ generalReviewsCountText }}</b></span>
      rated by your peers
    </div>

    <div v-else class="size-text-16px text-center dark-gray-text">
      Not yet reviewed by any of your peers
    </div>
  </div>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';

export default {
  name: 'LawyerRecommendationScore',

  components: {
    RatingCircle
  },

  props: {
    matterCount: {
      type: Number,
      required: true
    },

    reviewsSummary: {
      type: Object,
      required: true
    }
  },

  computed: {
    matterCountText() {
      return `${this.matterCount} ${this.$pluralize('matter', this.matterCount)}`;
    },

    rating() {
      const percent = this.recommendationScore ? this.recommendationScore * 10 : null;
      return { percent: percent };
    },

    recommendationScore() {
      return this.reviewsSummary.scoreAverages.find(score => score.field === 'recommendation')?.score;
    },

    matterReviewsCount() {
      return this.reviewsSummary.matterReviewsCount;
    },

    matterReviewsCountText() {
      return `${this.matterReviewsCount} ${this.$pluralize('review', this.matterReviewsCount)}`;
    },

    generalReviewsCount() {
      return this.reviewsSummary.generalReviewsCount;
    },

    generalReviewsCountText() {
      return `${this.generalReviewsCount} ${this.$pluralize('general review', this.generalReviewsCount)}`;
    },

    scoreDataPresent() {
      return this.recommendationScore && this.reviewsSummary.count > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  .rating-circle {
    display: flex;
    justify-content: center;
  }
</style>
