<template>
  <div :class="['box-content compressed', { 'contracted': !isExpanded }]">
    <div class="row clickable" @click="toggleTags">
      <div class="col-xs-10 semibold-weight">
        <div class="tag-list">
          <span class="tag-list-item compact clear" v-for="vettingTag in vettingTags" :key="vettingTag.id">{{ vettingTag.tag }}</span>
        </div>
      </div>

      <div class="col-xs-2 text-right top-5">
        <a href="" class="darker-gray-text" @click.prevent><svg-icon name="plus" class="base-icon smaller" v-if="!isExpanded"></svg-icon><svg-icon name="minus" class="base-icon smaller" v-else></svg-icon></a>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'SearchVettingTags',

  components: {
    SvgIcon
  },

  props: {
    vettingTags: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isExpanded: false
    };
  },

  methods: {
    toggleTags() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
