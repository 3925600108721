<template>
  <div>
    <lawyer-name-plate v-if="suggestedLawyer.scoutLawyerBridge" :lawyer="lawyerBridge" photo-size="small" :compact="true" :show-favorite="false" :show-lawyer-link="true"></lawyer-name-plate>

    <div v-else-if="suggestedLawyer.lawyer" class="name-plate photo-overflow-container compact">
      <div class="relative-container inline-block right-10-not-xs">
        <profile-photo :user="suggestedLawyer.lawyer" :small="true"></profile-photo>
      </div>

      <div class="text-img-adjacent">
        <div class="name-text text-ellipsis">
          <span class="target">{{ suggestedLawyer.lawyer.fullName }}</span>
        </div>

        <div class="text-ellipsis">
          <span>{{ suggestedLawyer.lawyer.firmTitle }}</span>
        </div>
      </div>
    </div>

    <div v-else-if="suggestedLawyer.lawyerName" class="name-plate photo-overflow-container compact">
      <div class="relative-container inline-block right-10-not-xs">
        <span class="missing-photo">
          <svg-icon name="default-profile"></svg-icon>
        </span>
      </div>

      <div class="text-img-adjacent">
        <div class="name-text text-ellipsis">
          <span class="target">{{ suggestedLawyer.lawyerName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';

export default {
  name: 'SuggestedLawyerNamePlate',

  components: {
    LawyerNamePlate,
    ProfilePhoto,
    SvgIcon
  },

  props: {
    suggestedLawyer: {
      type: Object,
      required: true
    }
  },

  computed: {
    lawyerBridge() {
      return new ScoutLawyerBridge(this.suggestedLawyer.scoutLawyerBridge);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .missing-photo {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    background-color: $k-light-gray;
    box-shadow: 0 0 8px 0 rgba($black, .1);
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    cursor: default;
    user-select: none;
    vertical-align: middle;
  }

  .name-text {
    font-size: 17px;
  }

  .name-plate.compact {
    min-height: auto;

    .name-text {
      font-size: 13px;
      font-weight: 600;
    }
  }
</style>
