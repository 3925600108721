<template>
  <div class="list-sharing-editor">
    <div class="list-share" v-for="(share, index) in shares" :key="index">
      <div class="row no-gutter">
        <div class="col-xs-9">
          <div class="size-text-16px">
            {{ share.recipientFullName }}<span v-if="isCurrentUserRecipient(share)"> (You)</span>
          </div>

          <div class="size-text-12px">
            {{ titleAndWorkplace(share) || '&mdash;' }}
          </div>
        </div>

        <div class="col-xs-3 size-text-11px semibold-weight text-right">
          <a href="" class="red-link" :id="`remove-share-${share.id}`" v-if="isShareEditable(share)" @click.prevent="showConfirmModal(share)">Remove</a>
        </div>
      </div>

      <list-sharing-editor-note class="top-5" :share="share" v-if="hasNote(share)"></list-sharing-editor-note>

      <hr v-if="index !== shares.length - 1">
    </div>

    <confirmation-modal
      ref="confirmationModal"
      header="Confirm"
      :message="`Are you sure you want to remove this ${userType}?`"
      yes-btn-text="Yes"
      no-btn-text="No"
      :on-confirm-action="removeShare">
    </confirmation-modal>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { compact } from 'lodash';
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal.vue';
import ListSharingEditorNote from 'vue-app/scout/shared/list-sharing-editor-note.vue';

export default {
  name: 'ListSharingEditor',

  components: {
    ConfirmationModal,
    ListSharingEditorNote
  },

  props: {
    shares: {
      type: Array,
      required: true
    },

    onEditShare: {
      type: Function,
      required: false
    },

    onRemoveShare: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      editingShares: [],
      shareToRemove: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    userType() {
      return this.currentUser.klass === 'Lawyer' ? 'client' : 'team member';
    }
  },

  methods: {
    isCurrentUserRecipient(share) {
      return share.recipientId === this.currentUser.id &&
        share.recipientType === this.currentUser.klass;
    },

    titleAndWorkplace(share) {
      return compact([share.recipientJobTitle, share.recipientWorkplace]).join(' at ');
    },

    hasNote(share) {
      return share.note && share.note.length > 0;
    },

    removeShare() {
      this.onRemoveShare(this.shareToRemove);
      this.shareToRemove = null;
    },

    isShareEditable(share) {
      return share.sharerId === this.currentUser.id &&
        share.sharerType === this.currentUser.klass;
    },

    showConfirmModal(share) {
      this.shareToRemove = share;
      this.$refs.confirmationModal.openModal();
    }
  }
};
</script>
