<template>
  <div v-html="content"></div>
</template>

<script>
export default {
  name: 'RichTextDisplay',

  props: {
    content: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
  ul.small-bullet > li {
    text-indent: -10px;
  }

  :deep(ul) {
    padding-inline-start: 16px;
  }

  :deep(ol) {
    padding-inline-start: 13px;
  }

  :deep(ul> li> ul> li> ul,
  ol> li> ol> li> ol) {
    padding-inline-start: 0;
    list-style: unset;
  }
</style>
