<template>
  <div>
    <div class="lawyer-pricing-grid pricing-data-grid priori-rates-table">
      <div class="grid-head">
        <div class="gh"></div>
        <div class="gh">
          Client Total
          <question-tooltip class="left-5" text="The total amount paid by the client. It is the sum of the lawyer&rsquo;s rate plus the Priori management fee."></question-tooltip>
        </div>
        <div class="gh">
          Lawyer Total
          <question-tooltip class="left-5" text="The amount paid by the client to the lawyer for legal services."></question-tooltip>
        </div>
        <div class="gh">
          Priori Management Fee
          <question-tooltip class="left-5" text="The amount paid by the client to Priori for the client&rsquo;s use of our platform and service. It is calculated as a percentage of the lawyer&rsquo;s rate."></question-tooltip>
        </div>
      </div>

      <div class="grid-row">
        <div class="gh">Average</div>
        <div class="gd">{{ clientTotal('average') }}</div>
        <div class="gd">{{ lawyerTotal('average') }}</div>
        <div class="gd">{{ prioriManagementFee('average') }}</div>
      </div>

      <div class="grid-row">
        <div class="gh">Lower Quartile</div>
        <div class="gd">{{ clientTotal('lowerQuartile') }}</div>
        <div class="gd">{{ lawyerTotal('lowerQuartile') }}</div>
        <div class="gd">{{ prioriManagementFee('lowerQuartile') }}</div>
      </div>

      <div class="grid-row">
        <div class="gh">Median</div>
        <div class="gd">{{ clientTotal('median') }}</div>
        <div class="gd">{{ lawyerTotal('median') }}</div>
        <div class="gd">{{ prioriManagementFee('median') }}</div>
      </div>

      <div class="grid-row">
        <div class="gh">Upper Quartile</div>
        <div class="gd">{{ clientTotal('upperQuartile') }}</div>
        <div class="gd">{{ lawyerTotal('upperQuartile') }}</div>
        <div class="gd">{{ prioriManagementFee('upperQuartile') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from 'vue-app/shared/mixins/currency-filter.js';
import QuestionTooltip from 'vue-app/shared/components/question-tooltip.vue';

export default {
  name: 'PrioriRatesTable',

  components: {
    QuestionTooltip
  },

  mixins: [currencyFilter],

  props: {
    prioriRates: {
      type: Object,
      required: true
    }
  },

  methods: {
    clientTotal(prop) {
      return this.formattedCurrency(this.prioriRates.clientTotal[prop]);
    },

    lawyerTotal(prop) {
      return this.formattedCurrency(this.prioriRates.lawyerTotal[prop]);
    },

    prioriManagementFee(prop) {
      return this.formattedCurrency(this.prioriRates.prioriManagementFee[prop]);
    }
  }
};
</script>
