import BaseResource from 'resources/base-resource';

class FavoriteFirm extends BaseResource {
  static baseUrl = '/scout/favorite_firms/:firm_bridge_id.json';
  static urlTransformations = ['firm_bridge_id'];

  // Since we need to replace firm_bridge_id instead of id, overwrite the collectionUrl
  // function. Ideally the base class can have the option to make the primary key
  // configurable, but I don't know what that looks like, and don't currently have
  // the time to explore and experiment - Yaniv 8/21
  static transformedCollectionUrl() {
    return this.baseUrl.replace('/:firm_bridge_id', '');
  }
}

export default FavoriteFirm;
