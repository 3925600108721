<template>
  <form role="form" name="manageListForm" class="a-form" novalidate>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <div v-show="selectingList && hasResourceLists">
        <label>
          Select List <span class="red-text">*</span>
          <div v-if="renderSelectError" class="error-text">*Required</div>
        </label>

        <div class="bottom-10">
          <button type="button" :class="['select-button bottom-10 item-spacing-right-10', { 'selected': isSelected(resourceList) }]" @click="select(resourceList)" v-for="resourceList in resourceLists" :key="resourceList.id">{{ resourceList.name }}</button>
        </div>

        <div class="bottom-40">
          <a href="" class="semibold-weight" @click.prevent="toggleSelecting">Create New List</a>
        </div>
      </div>

      <div v-show="!selectingList || !hasResourceLists">
        <div class="bottom-20">
          <div class="row tight-columns">
            <div class="col-sm-9 col-sm-push-3 text-right-not-xs">
              <div class="size-text-12px" v-if="isEditingList">
                Created by <span class="bold-weight">{{ list.createdByDisplayText() }}</span> on {{ listCreatedAt }}
              </div>
            </div>

            <div v-if="!disabled" class="col-sm-3 col-sm-pull-9 top-30-xs">
              <label for="list-name">List Name <span class="red-text">*</span></label>
            </div>
          </div>

          <text-input-vertical
            v-if="!disabled"
            v-model="list.name"
            :rules="{ 'required': !selectingList || !hasResourceLists }"
            id="list-name"
            input-name="listName"
            autocomplete="off">
          </text-input-vertical>
        </div>

        <div v-if="sharingEditable">
          <div class="bold-weight bottom-5">
            Shared With:
          </div>

          <list-sharing-editor
            :shares="list.shares"
            :on-remove-share="removeShareFromList">
          </list-sharing-editor>
        </div>

        <div class="bottom-40">
          <div class="red-text" v-if="disabled">
            You can only add 100 {{ currentTab.replace('_', ' ') }} to a list at a time. Please narrow down your search results to create a list.
          </div>

          <div class="top-20" v-if="!selectingList || hasResourceLists">
            <a href="" class="semibold-weight" @click.prevent="toggleSelecting">Add to Existing List</a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-8 col-sm-5">
          <loading-button
            v-if="!disabled"
            @lb-click="handleSubmit(submit)"
            name="submitList"
            id="submit-list"
            lb-class="primary-btn-blue">
            Save
          </loading-button>
        </div>
      </div>
    </validation-observer>
  </form>
</template>

<script>
import ListSharingEditor from 'vue-app/scout/shared/list-sharing-editor.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import DateFilter from 'vue-app/shared/lib/date-filter';
import { ValidationObserver } from 'vee-validate';
import { filter } from 'lodash';

export default {
  name: 'FormManageList',

  components: {
    ValidationObserver,
    TextInputVertical,
    ListSharingEditor,
    LoadingButton
  },

  props: {
    currentTab: {
      type:    String,
      default: 'lawyers'
    },

    disabled: {
      type:    Boolean,
      default: false
    },

    list: {
      type: Object,
      default: () => ({ id: null, name: '' })
    },

    resourceLists: {
      type: Array,
      required: false
    },

    onSubmit: {
      type: Function,
      required: true
    },

    onSuccess: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      selectingList: true,
      selectedList: null,
      renderSelectError: false,
      sharesToDelete: []
    };
  },

  computed: {
    isEditingList() {
      return this.list && this.list.id;
    },

    creating() {
      return !this.isEditingList && !this.resourceLists;
    },

    hasResourceLists() {
      return this.resourceLists && this.resourceLists.length > 0;
    },

    hasOnSuccessProp() {
      return typeof this.onSuccess !== 'undefined';
    },

    listCreatedAt() {
      return DateFilter.longDate(this.list.createdAt);
    },

    sharingEditable() {
      return this.isEditingList && this.list.shares.length > 0;
    }
  },

  methods: {
    isSelected(list) {
      return this.selectedList === list;
    },

    select(list) {
      this.selectedList = list;
      this.renderSelectError = false;
    },

    toggleSelecting() {
      if (!this.creating && this.hasResourceLists) {
        this.selectingList = !this.selectingList;
        this.selectedList = null;
      }
    },

    submit() {
      if (this.hasResourceLists && this.selectingList && !this.selectedList) {
        this.renderSelectError = true;
        return;
      }

      const listToManage = (this.selectingList && this.selectedList) ? this.selectedList : this.list;
      this.manageList(listToManage);
    },

    manageList(list) {
      LoadingService.loading('submitList');

      list.sharesToDelete = this.sharesToDelete;
      list['resourceListSharesAttributes'] = list.shares;

      this.onSubmit(list).then(() => {
        if (this.hasOnSuccessProp) { this.onSuccess(); }
        this.resetForm();
        LoadingService.done('submitList');
      });
    },

    removeShareFromList(shareToRemove) {
      this.sharesToDelete.push(shareToRemove.id);

      this.list.shares = filter(this.list.shares, (share) => {
        return share.id !== shareToRemove.id;
      });
    },

    resetForm() {
      this.list.name = null;
      this.selectingList = true;
      this.selectedList = null;
      this.sharesToDelete = [];
      this.$refs.observer.reset();
    }
  }
};
</script>
