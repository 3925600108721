<template>
  <priori-modal
    ref="modal"
    modal-id="delete-saved-searches"
    :title="`Delete ${savedSearch.name}`">
    <template #modal-trigger="{ openModal }">
      <a href="" class="delete" @click.prevent="openModal">Delete</a>
    </template>

    <template #default>
      <div class="size-text-base">
        Are you sure you want to permanently delete this saved search? This action cannot be undone.
      </div>

      <div class="top-20" v-if="isSharedSearch">
        <div class="yellow-message-box">
          <svg-icon name="exclamation" class="base-icon warning-icon right-10"></svg-icon>
          <span class="message">Warning: <span class="normal-weight">This is a shared saved search. Any other Priori Admins using this search will lose access once you delete it.</span></span>
        </div>
      </div>

      <div class="row top-30">
        <div class="col-sm-4">
          <loading-button name="deleteButton" lb-class="nv-button-blue a-button-size" @lb-click="deleteSavedSearch">Yes, Delete</loading-button>
        </div>

        <div class="col-sm-4 top-20-xs">
          <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SavedSearch from 'resources/admin/saved-search.js';

export default {
  name: 'SearchesDeleteListItem',

  components: {
    PrioriModal
  },

  props: {
    savedSearch: {
      type: Object,
      required: true
    },

    onRemoved: {
      type: Function,
      required: true
    }
  },

  computed: {
    isSharedSearch() {
      return this.savedSearch.visibility === 'shared';
    }
  },

  methods: {
    deleteSavedSearch() {
      LoadingService.loading('deleteButton');

      SavedSearch.delete({ id: this.savedSearch.id })
        .then(() => {
          this.closeModal();
          this.onRemoved(this.savedSearch);
        })
        .finally(() => {
          LoadingService.done('deleteButton');
        });
    },

    closeModal() {
      this.$refs.modal.dismissModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  .delete {
    display: block;
  }

  .warning-icon {
    margin-top: 1px;
  }
</style>
