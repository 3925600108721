<template>
  <div>
    <edit-modal ref="editModal" title="Edit Law Firm Name" :input="firm" :backdrop="true" :keyboard="true" :on-save="save">
      <template #modal-trigger="{ openModal }">
        <a href="" class="normal-weight size-text-13px" @click.prevent="openModal" data-testid="open-edit-modal">Edit Law Firm Name</a>
      </template>

      <template #default="{ inputCopy: firmCopy }">
        <div class="row">
          <div class="col-sm-6">
            <text-input-vertical
              label="Law Firm Name"
              id="firm-name"
              input-name="firmName"
              placeholder="Law Firm Name"
              rules="required"
              v-model="firmCopy.name">
            </text-input-vertical>
          </div>
        </div>
      </template>
    </edit-modal>
  </div>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';

export default {
  name: 'EditFirmNameModal',

  components: {
    EditModal,
    TextInputVertical
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  methods: {
    save(firmCopy) {
      this.onSave({
        id: firmCopy.id,
        lawFirmBridge: {
          lawFirmAttributes: { id: firmCopy.lawFirmId, name: firmCopy.name }
        }
      });
    }
  }
};
</script>
