<template>
  <modal v-model="modalOpen" class="pop-up-modal" size="md-sm" :transition="0" :header="false" :footer="false" append-to-body>
    <div class="size-text-24px bottom-30" v-if="$slots.title">
      <slot name="title"></slot>
    </div>

    <div class="bottom-30 size-text-16px">
      <slot name="message"></slot>
    </div>

    <button type="button" class="primary-button-compact" @click="closeModal">{{ closePrompt }}</button>
  </modal>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'PopUpModal',

  mixins: [
    modalToggle
  ],

  props: {
    closePrompt: {
      type: String,
      default: 'Ok'
    }
  }
};
</script>

<style lang="scss" scoped>
  .pop-up-modal {
    :deep(.modal-body) {
      padding: 30px;
    }
  }
</style>
