<template>
  <form role="form" name="keywordForm" class="a-form" @submit.prevent="addKeyword" novalidate>
    <div class="keyword-search-container">
      <input type="text" name="keyword" id="keyword-search-leads" class="form-control keyword-search-input" placeholder="Keyword Search" autofocus autocomplete="off" v-model="keyword" @blur="addKeyword">
      <svg-icon name="search" class="base-icon keyword-search-icon"></svg-icon>
    </div>

    <div class="row size-text-13px top-20" v-if="hasKeywords">
      <div class="col-sm-8">
        <div class="tag-list">
          <div class="tag-list-item compact blue" v-for="addedKeyword in keywords" :key="keywordId(addedKeyword)">
            <span class="right-5">{{ addedKeyword }}</span>
            <a href="" class="tag-list-item-delete" @click.prevent="removeKeyword(addedKeyword)"><svg-icon name="x3" class="base-icon smallest"></svg-icon></a>
          </div>
        </div>

        <div class="top-20" v-if="hasMultipleKeywords">
          <span class="right-20">Logic:</span>

          <label class="check-option compact">
            And <input type="radio" name="keywordLogic" id="keyword-logic-and" value="AND" v-model="keywordLogic" @change="keywordLogicChanged">
            <span class="check c-radio"></span>
          </label>

          <label class="check-option">
            Or <input type="radio" name="keywordLogic" id="keyword-logic-or" value="OR" v-model="keywordLogic" @change="keywordLogicChanged">
            <span class="check c-radio"></span>
          </label>
        </div>
      </div>

      <div class="col-sm-4 top-6">
        <div class="text-right-not-xs">
          <a href="" class="light-gray-link" @click.prevent="clearKeywords">Clear Keywords</a>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { uniqueId } from 'lodash';

export default {
  name: 'LeadSearchKeywords',

  components: {
    SvgIcon
  },

  props: {
    searchService: {
      type: Object,
      required: true
    },

    currentSearch: {
      type: Object,
      required: true
    },

    onSearchApplied: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      keyword: null,
      keywordLogic: 'AND'
    };
  },

  computed: {
    keywords() {
      return this.searchService.keywords() || [];
    },

    hasKeywords() {
      return this.keywords.length > 0;
    },

    hasMultipleKeywords() {
      return this.keywords.length > 1;
    }
  },

  watch: {
    currentSearch() {
      this.keywordLogic = this.searchService.keywordLogic();
    }
  },

  methods: {
    addKeyword() {
      if (this.searchService.canAddKeyword(this.keyword)) {
        this.searchService.addKeyword(this.keyword);
        this.onSearchApplied();
      }

      this.keyword = null;
    },

    keywordId(keyword) {
      return uniqueId(`keyword-${keyword.replace(/\s/g, '')}-`);
    },

    removeKeyword(keyword) {
      if (this.searchService.canRemoveKeyword(keyword)) {
        this.searchService.removeKeyword(keyword);
        this.onSearchApplied();
      }
    },

    clearKeywords() {
      if (this.hasKeywords) {
        this.searchService.clearKeywords();
        this.onSearchApplied();
      }
    },

    keywordLogicChanged() {
      this.searchService.setKeywordLogic(this.keywordLogic);
      this.onSearchApplied();
    }
  }
};
</script>
