<template>
  <div class="lawyer-pricing-grid pricing-data-grid volume-work-rates-table">
    <div class="grid-head">
      <div class="gh">
        Average Weekly Hours
      </div>

      <div class="gh">
        Client Total
        <question-tooltip class="left-5" text="The total amount paid by the client. It is the sum of the lawyer&rsquo;s rate plus the Priori management fee."></question-tooltip>
      </div>

      <div class="gh">
        Lawyer Total
        <question-tooltip class="left-5" text="The amount paid by the client to the lawyer for legal services."></question-tooltip>
      </div>

      <div class="gh">
        Priori Management Fee
        <question-tooltip class="left-5" text="The amount paid by the client to Priori for the client&rsquo;s use of our platform and service. It is calculated as a percentage of the lawyer&rsquo;s rate."></question-tooltip>
      </div>
    </div>

    <div class="grid-row" v-for="(rates, range, index) in volumeWorkRates" :key="index" v-if="range != '0'">
      <div class="gh">{{ range }}+ hours per week</div>

      <template v-if="rates.clientTotal">
        <div class="gd">{{ formatCurrency(rates.clientTotal) }}</div>
        <div class="gd">{{ formatCurrency(rates.lawyerTotal) }}</div>
        <div class="gd">{{ formatCurrency(rates.prioriManagementFee) }}</div>
      </template>

      <div class="gd no-data missing-text size-text-13px" v-else>No available data</div>
    </div>
  </div>
</template>

<script>
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import QuestionTooltip from 'vue-app/shared/components/question-tooltip.vue';

export default {
  name: 'VolumeWorkRatesTable',

  components: {
    QuestionTooltip
  },

  props: {
    volumeWorkRates: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatCurrency(amount) {
      return CurrencyFilter.filter(amount);
    }
  }
};
</script>
