<template>
  <edit-modal title="Editing Contact" :input="lawyer" :on-save="save" :on-cancel="cancel">
    <template #default>
      <div class="bottom-20">
        <div class="bold-weight">
          Email
        </div>

        <div>
          {{ lawyer.email }}
        </div>
      </div>

      <div v-if="lawyer.officeNumber" class="bottom-20">
        <div class="bold-weight">
          Phone
        </div>

        <div>
          {{ lawyer.officeNumber }}
        </div>
      </div>

      <div>
        <text-input-vertical id="pronouns" label="Pronouns" v-model="pronouns"></text-input-vertical>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical';

export default {
  name: 'EditLawyerContactInfo',

  components: {
    EditModal,
    TextInputVertical
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    onSave: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      pronouns: this.lawyer.pronouns
    };
  },

  methods: {
    save() {
      this.onSave(
        {
          lawyerProfileAttributes: {
            id: this.lawyer.lawyerProfileId,
            pronouns: this.pronouns
          }
        }
      );
    },

    resetForm() {
      this.pronouns = this.lawyer.pronouns;
    },

    cancel() {
      this.resetForm();
    }
  }
};
</script>
