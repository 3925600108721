<template>
  <div>
    <div class="bold-weight bottom-5">
      {{ header }}
    </div>

    <collapsable-space>
      <data-wrapper :data="data">
        <div ref="container">
          <div v-for="dataStr in data" :key="dataItemId(dataStr)" class="list-spacing-5 d-flex">
            <span v-html="removeWhitespace(dataStr)"></span>
          </div>
        </div>
      </data-wrapper>
    </collapsable-space>
  </div>
</template>

<script>
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import CollapsableSpace from 'vue-app/shared/components/collapsable-space.vue';
import { uniqueId } from 'lodash';

export default {
  name: 'LawyerAffilationSection',

  components: {
    DataWrapper,
    CollapsableSpace
  },

  props: {
    header: {
      type:     String,
      required: true
    },

    data: {
      type:     Array,
      required: true
    }
  },

  methods: {
    dataItemId() {
      return uniqueId('data-');
    },

    // remove newlines. Currently only known whitespace.
    // can be updated to remove other as well if needed
    removeWhitespace(str) {
      return str.replace(/\n/g, '');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/lists";

  :deep {
    & > ul, & > ol {
      @extend %headed-list;

      list-style-type: disc;
    }

    & > ul > li > ul, & > ol > li > ol {
      @extend %flattened-list;
      @extend %parenthesized-list;

      margin-left: 3px;
      display:    inline;
      font-style: italic;
    }

    & > ol > li > ol {
      @extend %decimal-list;

      margin-left: 3px;
    }
  }
</style>
