<template>
  <div>
    <div class="messaging-center-header-container">
      <div class="messaging-center-header">
        <div class="header-icon icon-left" @click="onHide">
          <svg-icon name="chat" class="base-icon"></svg-icon>
        </div>

        <div class="messaging-header-text left-icon text-center">
          Participants
        </div>
      </div>
    </div>

    <div class="messaging-center-content-container">
      <conversation-participant
        v-for="participant in participants"
        :key="`${participant.klass}-${participant.id}`"
        :participant="participant">
      </conversation-participant>
    </div>
  </div>
</template>

<script>
import ConversationParticipant from 'vue-app/mailboxer/scout/conversation-participant.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { orderBy } from 'lodash';

export default {
  name: 'ConversationParticipants',

  components: {
    ConversationParticipant,
    SvgIcon
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    onHide: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    userIsClient() {
      return this.user.klass === 'Client';
    },

    participants() {
      return orderBy(this.conversation.participants, ['klass', 'lastName', 'firstName'], [(this.userIsClient ? 'desc' : 'asc'), 'asc', 'asc']);
    }
  }
};
</script>
