<template>
  <priori-modal
    ref="sendReviewReminder"
    modal-id="send-review-reminder"
    title="Send Review Reminder"
    modal-size="lg"
    @on-modal-close="resetModal">
    <template #modal-trigger="{ openModal }">
      <button type="button" class=" nv-button-white text-ellipsis" @click="openModal">Send Review Reminder</button>
    </template>

    <div class="bottom-30 size-text-14px semibold-weight">
      Review reminders may be sent to existing matter participants. Selected users will receive an email asking them to leave a review for this matter.
    </div>

    <div class="bottom-30 row tight-columns d-flex-not-xs a-form">
      <div class="col-sm-8">
        <div class="keyword-search-container">
          <input type="text" name="searchTerm" id="search-term" class="form-control keyword-search-input" placeholder="Search..." autocomplete="off" v-model="searchTerm">

          <a href="" class="keyword-search-icon clear-icon" v-if="searchTerm" @click.prevent="clearSearchTerm"><svg-icon name="x3" class="base-icon"></svg-icon></a>

          <svg-icon name="search" class="base-icon keyword-search-icon" v-else></svg-icon>
        </div>
      </div>

      <div class="col-sm-4 top-10-xs">
        <div class="row tight-columns vertical-bottom">
          <div class="col-sm-7 col-md-9 text-right-not-xs">
            <button type="button" class="pseudo-link underlined" v-show="!isSearching" @click="deselectAllParticipants">Deselect All</button>
          </div>

          <div class="col-sm-5 col-md-3 text-right-not-xs">
            <button type="button" class="pseudo-link underlined" v-show="!isSearching" @click="selectAllParticipants">Select All</button>
          </div>
        </div>
      </div>
    </div>

    <div class="resource-list bottom-30">
      <div class="list-header">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-5">
            Matter Participant
          </div>

          <div class="col-sm-2 text-center-not-xs top-10-xs">
            Reviews
            <span class="info-icon"><svg-icon id="reviews-tooltip" name="info" class="base-icon smaller text-top"></svg-icon></span>
            <tooltip target="#reviews-tooltip" placement="bottom" custom-class="tooltip-white" text="This field contains the number of reviews left by the matter participant for this matter."></tooltip>
          </div>

          <div class="col-sm-2 top-10-xs">
            Latest Review
            <span class="info-icon"><svg-icon id="latest-review-tooltip" name="info" class="base-icon smaller text-top"></svg-icon></span>
            <tooltip target="#latest-review-tooltip" placement="bottom" custom-class="tooltip-white" text="This field contains the date of the most recent review left by the matter participant for this matter."></tooltip>
          </div>

          <div class="col-sm-3 text-right-not-xs">
            Send Reminder
          </div>
        </div>
      </div>

      <div class="scrollable-list">
        <div v-if="sortedParticipants.length > 0">
          <div v-for="participant in sortedParticipants" :key="participant.id" class="list-item">
            <div class="row tight-columns vertical-center-not-xs">
              <div class="col-sm-5">
                <span class="highlighted semibold-weight" v-html="highlight(participant.fullName)"></span>

                <div>
                  {{ participant.role || '&mdash;' }}
                </div>
              </div>

              <div class="col-sm-2 text-center-not-xs top-10-xs">
                {{ participant.reviewCount }}
              </div>

              <div class="col-sm-2 top-10-xs">
                {{ formatDate(participant.latestReviewDate) || '&mdash;' }}
              </div>

              <div class="col-sm-3 text-right-not-xs">
                <button type="button" class="pseudo-link-blue bold-weight" @click="handleParticipantSelected(participant)">{{ participant.selected ? 'Remove' : 'Add' }}</button>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="list-item">
            <div class="pill-gray-text text-center">
              No matches found
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-30 shadowed-box padding-x-20 padding-y-20">
      <div class="size-text-14px bold-weight bottom-15">
        Review Reminder Recipients
      </div>

      <div v-if="selectedRecipients.length > 0">
        <tag-list :tags="selectedRecipients" :on-remove="deselectRecipient" :value-fn="getRecipientName"></tag-list>
      </div>

      <div v-else class="gray-text size-text-16px">
        No recipients added
      </div>
    </div>

    <div class="bottom-30 shadowed-box padding-x-20 padding-y-20">
      <div class="size-text-14px bold-weight bottom-5">
        Custom Messaging
      </div>

      <div class="bottom-20">
        If you would like to add a custom message to the review reminder email, please add your text below. If you do not include a custom message, the recipient will receive a standard review reminder email.
      </div>

      <div>
        <tiptap-editor v-model="customMessage" placeholder=""></tiptap-editor>
      </div>
    </div>

    <div class="row tight-columns">
      <div class="col-sm-4 col-md-3">
        <button type="button" class="primary-btn-blue" :disabled="!valid" @click="handleSubmit">Send Notification</button>
      </div>

      <div class="col-sm-2 top-20-xs">
        <button type="button" class="tertiary-button-compact" @click="closeModal">Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import { zip }      from 'lodash';
import DateFilter   from 'vue-app/shared/lib/date-filter';
import PrioriModal  from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon      from 'vue-app/shared/components/svg-icon.vue';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import TagList      from 'vue-app/scout/shared/tag-list.vue';

export default {
  name: 'SendReviewReminderModal',

  components: {
    PrioriModal,
    SvgIcon,
    TiptapEditor,
    TagList
  },

  props: {
    participants: {
      type: Array,
      required: true
    },

    sendReviewReminders: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      participantsCopy: this.participants.map(participant => ({ ...participant, selected: false })),
      selectedRecipients: [],
      customMessage: null,
      searchTerm: ''
    };
  },

  computed: {
    valid() {
      return this.participantsCopy.some(participant => participant.selected);
    },

    sortedParticipants() {
      let filteredParticipants = this.participantsCopy;

      if (this.isSearching) {
        filteredParticipants = filteredParticipants.filter(participant => {
          return participant.fullName.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      }

      return filteredParticipants.sort((a, b) => {
        if (a.selected && !b.selected) { return -1; }
        if (!a.selected && b.selected) { return 1; }

        return a.fullName.localeCompare(b.fullName);
      });
    },

    isSearching() {
      return this.searchTerm.length > 0;
    },

    recipientIds() {
      return this.selectedRecipients.map(recipient => recipient.id);
    }
  },

  methods: {
    closeModal() {
      this.$refs.sendReviewReminder.closeModal();
      this.resetModal();
    },

    resetModal() {
      this.participantsCopy = this.participants.map(participant => ({ ...participant, selected: false }));
      this.selectedRecipients = [];
      this.customMessage = '';
      this.searchTerm = '';
    },

    clearSearchTerm() {
      this.searchTerm = '';
    },

    getRecipientName(recipient) {
      return recipient.fullName;
    },

    selectAllParticipants() {
      this.participantsCopy.forEach(participant => {
        participant.selected = true;
      });

      this.selectedRecipients = this.participantsCopy.filter(participant => participant.selected);
    },

    deselectAllParticipants() {
      this.participantsCopy.forEach(participant => {
        participant.selected = false;
      });

      this.selectedRecipients = [];
    },

    handleParticipantSelected(participant) {
      participant.selected = !participant.selected;

      if (participant.selected) {
        this.selectRecipient(participant);
      }
      else {
        this.deselectRecipient(this.selectedRecipients.findIndex(selectedParticipant => selectedParticipant.id === participant.id));
      }
    },

    selectRecipient(participant) {
      participant.selected = true;
      this.selectedRecipients.push(participant);
    },

    deselectRecipient(index) {
      const participant = this.participantsCopy.find(participant => participant.id === this.selectedRecipients[index].id);

      if (participant) { participant.selected = false; }
      this.selectedRecipients.splice(index, 1);
    },

    highlight(text) {
      text = this.$sanitize(text);
      const regex = new RegExp(this.searchTerm, 'gi');
      const matches = text.match(regex);
      if (!matches) { return text; }

      const sectionsInbetween = text.split(new RegExp(this.searchTerm, 'i'));
      const highlights = matches.map(highlight => `<strong>${highlight}</strong>`);
      const zipped = zip(sectionsInbetween, highlights);

      return zipped.flat().join('');
    },

    formatDate(date) {
      return DateFilter.filterDate(date);
    },

    handleSubmit() {
      if (!this.valid) { return; }

      this.sendReviewReminders(this.recipientIds, this.customMessage).then(() => {
        this.closeModal();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .scrollable-list {
    max-height: 271px;
    overflow-y: auto;
  }

  .highlighted {
    :deep(strong) {
      color: $k-blue;
    }
  }

  .blue {
    color: $k-blue;
  }

  .cursor-pointer {
    &:hover {
      cursor: pointer;
    }
  }
</style>
