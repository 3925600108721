<template>
  <boxed-content title="Notifications" class="settings-box">
    <template #links>
      <edit-modal
        v-if="shouldShowReviewReminderEditModal"
        :input="currentClient"
        :on-save="save"
        title="Editing Notifications"
        data-testid="notification-settings-edit-modal">
        <template #default="{ inputCopy: clientCopy }">
          <div class="small-bold-header">
            My Account
          </div>

          <hr class="top-5 bottom-5">

          <div class="dark-gray-text text-italic bottom-20">
            Please edit the below settings to turn notifications on or off.
          </div>

          <div class="bottom-20">
            <div class="row tight-columns">
              <div class="col-xs-8 col-sm-9">
                <label class="no-margin">
                  <span class="bold-weight">Enable Review Reminder Emails</span>
                </label>
              </div>
              <div class="col-xs-4 col-sm-3 text-right">
                <toggle-button
                  data-testid="has-review-reminder-emails-enabled-toggle"
                  :disabled="!currentClient.workspaceHasReviewReminderEmailsEnabled || !currentClient.canDisableReviewReminderEmails"
                  :value="clientCopy.hasReviewReminderEmailsEnabled"
                  @click="toggleHasReviewReminderEmailsEnabled(clientCopy)">
                </toggle-button>
              </div>
            </div>
          </div>
        </template>
      </edit-modal>
    </template>

    <loading-section name="clientNotifications">
      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            Enable Review Reminder Emails
          </div>

          <div class="dark-gray-text">
            {{ reviewReminderSubtext }}
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <span data-testid="review-reminder-emails">{{ hasReviewReminderEmailsEnabled ? "On" : "Off" }}</span>
        </div>
      </div>
    </loading-section>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'NotificationSettings',

  components: {
    BoxedContent,
    EditModal,
    LoadingSection,
    ToggleButton
  },

  props: {
    onSave: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    hasReviewReminderEmailsEnabled() {
      if (this.currentClient.workspaceHasReviewReminderEmailsEnabled) {
        return !this.currentClient.canDisableReviewReminderEmails ||
          this.currentClient.hasReviewReminderEmailsEnabled;
      }

      return false;
    },

    shouldShowReviewReminderEditModal() {
      return this.currentClient.canDisableReviewReminderEmails && this.currentClient.workspaceHasReviewReminderEmailsEnabled;
    },

    reviewReminderSubtext() {
      if (this.shouldShowReviewReminderEditModal) {
        return 'Select whether you want to receive review reminders once weekly.';
      }

      return 'The ability to change your weekly review reminder email settings has been restricted. If you would like to turn your weekly review reminder emails on/off, please contact your system administrators.';
    }
  },

  methods: {
    save(clientCopy) {
      LoadingService.loading('clientNotifications');

      this.onSave({
        id: clientCopy.id,
        hasReviewReminderEmailsEnabled: clientCopy.hasReviewReminderEmailsEnabled
      }).finally(() => {
        LoadingService.done('clientNotifications');
      });
    },

    toggleHasReviewReminderEmailsEnabled(clientCopy) {
      clientCopy.hasReviewReminderEmailsEnabled = !clientCopy.hasReviewReminderEmailsEnabled;
    }
  }
};
</script>
