<template>
  <div class="box">
    <span class="right-5">{{ text }}</span>
    <span v-if="subText" class="right-5">•</span>
    <span v-if="subText" :class="['sub-text', { 'right-5': number }]">{{ subText }}</span>
    <span v-if="number" class="divider right-5"></span>
    <span v-if="number" class="bold-weight">{{ formattedNumber }}</span>
  </div>
</template>

<script>
export default {
  name: 'MultiPieceTag',

  props: {
    text: {
      type:     String,
      required: true
    },

    subText: {
      type:     String,
      default:  null
    },

    number: {
      type:     Number,
      default:  null
    },

    unit: {
      type:     String,
      default:  null
    }
  },

  computed: {
    formattedNumber() {
      return this.unit ? `${this.number.toLocaleString()}${this.unit}` : this.number.toLocaleString();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .box {
    display: inline-block;
    border: 1px solid $k-gray;
    border-radius: $border-radius-base;
    padding: 5px;
  }

  .sub-text {
    color: $pill-gray;
  }

  .divider {
    padding-left: 1px;
    border-left: 1px solid $k-gray;
  }
</style>
