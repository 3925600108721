<template>
  <div class="item-wrapper rfq-not-authorized">
    <div class="d-flex space-between items-center">
      <div class="bold-weight size-text-15px">
        {{ rfqLawFirmQuote.firmName }}
      </div>
    </div>

    <div class="top-20">
      <div>
        <i>This law firm is not yet authorized by your legal department to receive RFPs. Please contact your team’s administrator to grant approval to this law firm. Once the firm is approved, you will be able to edit the existing RFP to add the firm.</i>
      </div>

      <div class="row top-20">
        <div class="col-sm-9">
          <rfq-contact-scout-admins :rfq="rfq" :rfq-law-firm-quote="rfqLawFirmQuote" @contact-admins="onContactAdmins"></rfq-contact-scout-admins>
        </div>

        <div class="col-sm-3 text-right-not-xs">
          <rfq-remove-firm :rfq="rfq" :rfq-law-firm-quote="rfqLawFirmQuote"></rfq-remove-firm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqContactScoutAdmins from 'vue-app/scout/client/rfqs/create/contact-scout-admins.vue';
import RfqRemoveFirm from 'vue-app/scout/client/rfqs/create/remove-firm.vue';

export default {
  name: 'RfqRecipientItem',

  components: {
    RfqContactScoutAdmins,
    RfqRemoveFirm
  },

  props: {
    rfqLawFirmQuote: {
      type: Object,
      required: true
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  methods: {
    onContactAdmins(data) {
      this.$emit('contact-admins', data);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .space-between {
    justify-content: space-between;
  }

  .items-center {
    align-items: center;
  }

  .rfq-not-authorized {
    background-color: $k-lighter-gray;
    color: $pill-gray;
  }

  .item-wrapper {
    border-radius: $border-radius-large;
    padding: 12px 15px;
  }
</style>
