<template>
  <div class="row">
    <div class="col-sm-8">
      <firm-notes
        :notes="notes"
        :editing-allowed="editingAllowed"
        :on-save="onSaveNote">
      </firm-notes>
    </div>

    <div class="col-sm-4 top-30-xs">
      <firm-documents
        :documents="documents"
        :editing-allowed="editingAllowed"
        :on-save="onSaveDocument"
        :on-update="onUpdateDocument"
        :on-delete="onDeleteDocument">
      </firm-documents>
    </div>
  </div>
</template>

<script>
import FirmNotes from 'vue-app/scout/client/law-firms/firm-notes.vue';
import FirmDocuments from 'vue-app/scout/client/law-firms/firm-documents.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FirmNotesAndDocuments',

  components: {
    FirmNotes,
    FirmDocuments
  },

  props: {
    notes: {
      type: Array,
      required: true
    },

    documents: {
      type: Array,
      required: true
    },

    onSaveNote: {
      type: Function,
      required: true
    },

    onSaveDocument: {
      type: Function,
      required: true
    },

    onUpdateDocument: {
      type: Function,
      required: true
    },

    onDeleteDocument: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    editingAllowed() {
      return this.currentClient.admin;
    }
  }
};
</script>
