<template>
  <div class="shadowed-box">
    <div class="resource-list static-list">
      <div class="list-header">
        <div class="row tight-columns vertical-center-not-xs">
          <div :class="{ 'col-sm-4': canViewRFQFeature, 'col-sm-5': !canViewRFQFeature }">
            Law Firm
          </div>

          <div :class="{ 'col-sm-5': canViewRFQFeature, 'col-sm-6': !canViewRFQFeature }">
            Tags
          </div>

          <div v-if="shouldRenderRFQColumn" class="col-sm-2">
            RFP Enabled
          </div>
        </div>
      </div>

      <div v-if="hasLawFirms">
        <div v-for="(firm, index) in lawFirms" :key="index" class="list-item matter-row no-hover">
          <div class="row tight-columns vertical-center-not-xs">
            <div :class="[{ 'clickable': renderingActive, 'col-sm-4': canViewRFQFeature, 'col-sm-5': !canViewRFQFeature }]" @click="renderingActive && viewScoutFirmInNewTab(firm.id)">
              <firm-name-plate :firm="firm" :italic="!renderingActive" :gray="!renderingActive"></firm-name-plate>
            </div>

            <div :class="['col-xs-9 top-20-xs', { 'col-sm-5': canViewRFQFeature, 'col-sm-6': !canViewRFQFeature }]">
              <scout-tags-list-with-modal
                v-if="renderingActive && anyTags(firm)"
                :scout-tags="firm.scoutTags"
                :firm-name="firm.name"
                :show-all-tags="true">
              </scout-tags-list-with-modal>
              <span v-else-if="renderingActive" class="missing-text">No tags</span>
              <span v-else class="pill-gray-text text-italic">Law Firm Deactivated</span>
            </div>

            <div v-if="shouldRenderRFQColumn" class="col-xs-9 col-sm-2 top-20-xs">
              {{ firm.hasRfqEnabled ? 'Yes' : 'No' }}
            </div>

            <div v-if="shouldRenderDropdown" class="col-xs-3 col-sm-1 text-right top-20-xs">
              <dropdown-menu :compact="true">
                <li v-if="firmIsActive(firm)" class="menu-item" role="menuitem">
                  <a href="" @click.prevent="viewScoutFirmInNewTab(firm.id)">View Profile</a>
                </li>

                <li v-if="isAdmin && firmIsActive(firm)" class="menu-item" role="menuitem">
                  <add-remove-tags-modal
                    :scout-tags="scoutTags"
                    :firm="firm"
                    :on-save="save">
                  </add-remove-tags-modal>
                </li>

                <li v-if="canEditRFQFeature && firmIsActive(firm)" class="menu-item" role="menuitem">
                  <edit-rfq-status-modal
                    :firm="firm"
                    :on-save="save">
                  </edit-rfq-status-modal>
                </li>

                <li v-if="canEditLawFirmName(firm) && firmIsActive(firm)" class="menu-item" role="menuitem">
                  <edit-firm-name-modal
                    :firm="firm"
                    :on-save="save">
                  </edit-firm-name-modal>
                </li>

                <li v-if="shouldRenderToggleActiveButton(firm)" class="menu-item" role="menuitem">
                  <a href="" @click.prevent="openModal(firm)">{{ deactivateOrReactivate(firm) }} Law Firm</a>
                </li>
              </dropdown-menu>
            </div>
          </div>
        </div>
      </div>

      <div class="box-content" v-else>
        <div class="gray-text size-text-16px top-10 bottom-10">
          No Results
        </div>
      </div>
    </div>

    <toggle-firm-active-modal
      :firm="firmToToggleActive"
      :on-submit="toggleFirmActive"
      ref="firmToggleModal">
    </toggle-firm-active-modal>
  </div>
</template>

<script>
import DropdownMenu           from 'vue-app/shared/components/dropdown-menu.vue';
import FirmNamePlate          from 'vue-app/scout/shared/firm-name-plate.vue';
import ScoutTagsListWithModal from 'vue-app/scout/shared/scout-tags/scout-tags-list-with-modal.vue';
import AddRemoveTagsModal     from 'vue-app/scout/client/law-firms/add-remove-tags-modal.vue';
import EditRfqStatusModal     from 'vue-app/scout/client/law-firms/edit-rfq-status-modal.vue';
import EditFirmNameModal      from 'vue-app/scout/client/law-firms/edit-firm-name-modal.vue';
import ToggleFirmActiveModal  from 'vue-app/scout/client/law-firms/toggle-firm-active-modal.vue';
import useCurrentUserStore    from 'vue-app/stores/current-user.js';
import { mapState }           from 'pinia';

export default {
  name: 'FirmStatusIndex',

  components: {
    DropdownMenu,
    FirmNamePlate,
    ScoutTagsListWithModal,
    AddRemoveTagsModal,
    EditRfqStatusModal,
    EditFirmNameModal,
    ToggleFirmActiveModal
  },

  props: {
    lawFirms: {
      type: Array,
      required: true
    },

    scoutTags: {
      type: Array,
      required: true
    },

    toggleFirmActive: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    renderingActive: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      firmToToggleActive: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    hasLawFirms() {
      return !!this.lawFirms.length;
    },

    isAdmin() {
      return this.currentClient.userType === 'Admin';
    },

    canViewRFQFeature() {
      return this.currentClient.workspaceHasRfqEnabled;
    },

    canEditRFQFeature() {
      return this.isAdmin && this.currentClient.workspaceHasRfqEnabled;
    },

    shouldRenderRFQColumn() {
      if (this.renderingActive) {
        return this.canViewRFQFeature;
      }
      else {
        return this.isAdmin;
      }
    },

    shouldRenderDropdown() {
      if (this.renderingActive) { return true; }

      return this.isAdmin;
    }
  },

  methods: {
    anyTags(firm) {
      return firm.scoutTags.length > 0;
    },

    canEditLawFirmName(firm) {
      return this.isAdmin && !firm.hasLawyers && !firm.isBillingFirm();
    },

    openModal(firm) {
      this.firmToToggleActive = firm;
      this.$refs.firmToggleModal.openModal();
    },

    shouldRenderToggleActiveButton(firm) {
      if (!this.isAdmin) { return false; }

      if ((this.isProspectiveFirm(firm) && firm.scoutConnection?.active) || firm.scoutConnection?.active === false) {
        return true;
      }
      else {
        return false;
      }
    },

    isProspectiveFirm(firm) {
      return firm.accessLevel === 'prospective_data';
    },

    deactivateOrReactivate(firm) {
      return (firm.scoutConnection && firm.scoutConnection.active) ? 'Deactivate' : 'Reactivate';
    },

    firmIsActive(firm) {
      if (!firm.scoutConnection) { return true; }

      return firm.scoutConnection?.active;
    },

    save(params) {
      return this.onSave(params);
    }
  }
};
</script>
