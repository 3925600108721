<template>
  <div class="tag-list">
    <div v-if="isPublished" class="tag-list-item green compact">
      Live Post
    </div>

    <div v-else-if="isFirstDraft" class="tag-list-item gray compact">
      Draft &ndash; Not Published
    </div>

    <div v-else class="tag-list-item gray compact">
      Unpublished &ndash; Not Live
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpportunityStatusTag',

  props: {
    opportunity: {
      type: Object,
      required: true
    }
  },

  computed: {
    isPublished() {
      return !!this.opportunity.publishedAt;
    },

    isFirstDraft() {
      return this.opportunity.opportunityPublishingHistories.length === 0;
    }
  }
};
</script>
