<template>
  <div>
    <div class="size-text-15px bold-weight bottom-10">
      Priori&rsquo;s Marketplace Network
    </div>

    <review-response-group
      @change-selected-quote="changeSelectedQuote"
      title="Quote Received"
      :quotes="orderedQuotes"
      :rfq="rfq"
      :selected-quote="selectedQuote"
      :show-empty-state="true">
      <template #emptyState>
        <div class="empty-state">
          <div class="bottom-15">
            Marketplace quotes are pending. This typically takes between 1 and 3 business days.
          </div>

          <div class="bottom-15">
            Please use the button below to contact Priori&rsquo;s Marketplace team or reach out to your representative directly if you have any questions.
          </div>

          <div>
            <a href="mailto:admin@priorilegal.com"><svg-icon name="envelope" class="base-icon"></svg-icon></a>
          </div>
        </div>
      </template>
    </review-response-group>
  </div>
</template>

<script>
import ReviewResponseGroup from 'vue-app/scout/client/rfqs/review-response-group.vue';
import { orderBy } from 'lodash';

export default {
  name: 'ReviewResponsesMarketplace',

  components: {
    ReviewResponseGroup
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    selectedQuote: {
      type: Object
    },

    updateSelectedQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    orderedQuotes() {
      // TODO: order by name to be displayed
      return orderBy(
        this.rfq.lawFirmQuotes.filter(quote => quote.fromMarketplace),
        'lawFirm.name'
      );
    }
  },

  methods: {
    changeSelectedQuote(id) {
      if (id === this.selectedQuote?.id) { return; }

      this.updateSelectedQuote(this.rfq.lawFirmQuotes.find(quote => quote.id === id));
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .empty-state {
    padding: 20px;
    background: $gray-9;
    border-radius: $border-radius-large;
    line-height: 1.4;

    svg {
      fill: $gray-4;
    }
  }
</style>
