<template>
  <edit-modal title="Editing Highlights" :input="lawyer" :on-save="save" :on-cancel="cancelEdit" @on-modal-open="openModal">
    <template #default="{ inputCopy: lawyerCopy }">
      <div class="bottom-30">
        <div class="small-bold-header">
          Representative Clients
        </div>

        <hr>

        <div class="text-italic pill-gray-text size-text-12px bottom-15">
          Please use the input box below to add representative clients that you want to appear on your profile.
        </div>

        <div class="row bottom-10">
          <div class="col-sm-6">
            <add-tag-input :on-add="addRepresentativeClient"></add-tag-input>
          </div>
        </div>

        <div>
          <tag-list :tags="representativeClients" :on-remove="removeRepresentativeClient"></tag-list>
        </div>
      </div>

      <div>
        <div class="small-bold-header">
          Industries
        </div>

        <hr>

        <div class="text-italic pill-gray-text size-text-12px bottom-15">
          Please use the dropdown menu below to select the industries in which you have experience.
        </div>

        <div class="row bottom-10">
          <div class="col-sm-6">
            <dropdown class="k-dropdown" ref="dropdown" v-model="dropdownOpen" :not-close-elements="elementsToNotCloseOn">
              <button type="button" role="button" class="dropdown-toggle">
                <span>Select all that apply</span>
                <svg-icon name="caret-down" class="caret-down"></svg-icon>
                <svg-icon name="caret-up" class="caret-up"></svg-icon>
              </button>

              <template #dropdown>
                <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
                  <li role="menuitem" v-for="industry in industries" :key="industry.id">
                    <label class="check-option vertical medium-large">
                      {{ industry.name || industry.label }}
                      <input type="checkbox" v-model="selectedIndustries" :value="industry.id" :name="`check${ industry.id }`" :id="`check${ industry.id }`">
                      <span class="check c-box"></span>
                    </label>
                  </li>
                </div>
              </template>
            </dropdown>
          </div>
        </div>

        <div>
          <tag-list :tags="selectedIndustries" :on-remove="removeIndustry" :value-fn="getIndustryName"></tag-list>
        </div>
      </div>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above or remove those entries from the form.
    </template>
  </edit-modal>
</template>

<script>
import { cloneDeep } from 'lodash';
import EditModal from '../edit-modal';
import AddTagInput from '../add-tag-input';
import TagList from '../tag-list';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import ScoutFirm from 'resources/scout/scout-firm';
import DataLock from 'resources/data-lock';
import { matchingArrayValues } from 'misc/resource-helpers';

export default {
  name: 'EditLawyerHighlights',

  components: {
    EditModal,
    AddTagInput,
    TagList
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      representativeClients: [],
      selectedIndustries: [],
      dropdownOpen: false,
      elementsToNotCloseOn: [],
      originalClients: [],
      industries: [],
      firm: null
    };
  },

  mounted() {
    this.resetForm();
    this.elementsToNotCloseOn = [this.$refs.dropdown.$refs.dropdown];
  },

  methods: {
    resetForm() {
      this.representativeClients = cloneDeep(this.lawyer.representativeClients);
      this.selectedIndustries = this.lawyer.industries.map((industry) => industry.scoutFirmIndustryId);
      this.originalClients = cloneDeep(this.lawyer.representativeClients);
      this.originalIndustries = cloneDeep(this.selectedIndustries);
    },

    addRepresentativeClient(client) {
      this.representativeClients.push({
        name: client
      });
    },

    removeRepresentativeClient(index) {
      this.representativeClients.splice(index, 1);
    },

    removeIndustry(index) {
      this.selectedIndustries.splice(index, 1);
    },

    getIndustryName(industryId) {
      var industry = this.industries.find((industry) => industry.id === industryId);

      if (industry) {
        return industry.name;
      }
    },

    save() {
      var lawyerProfileAttributes = {
        id: this.lawyer.lawyerProfileId,
        representativeClientsAttributes: this.clientsWithPersistence(),
        scoutLawyerIndustriesAttributes: this.industriesWithPersistence()
      };

      var lawyerProfile = { lawyerProfileAttributes: lawyerProfileAttributes };
      const flagsToSet = [];

      if (!matchingArrayValues(this.originalClients, this.representativeClients)) {
        flagsToSet.push('representative_clients');
      }

      if (!matchingArrayValues(this.originalIndustries, this.selectedIndustries)) {
        flagsToSet.push('industries');
      }

      this.onSave(lawyerProfile, () => {
        DataLock.save({
          dataLock: {
            flag:         flagsToSet,
            resourceId:   this.lawyer.id,
            resourceType: 'lawyer'
          }
        });
      });
    },

    cancelEdit() {
      this.resetForm();
    },

    clientsWithPersistence() {
      var clientsToDelete = this.markRepresentativeClientsToBeDeleted();

      return _.union(this.representativeClients, clientsToDelete);
    },

    markRepresentativeClientsToBeDeleted() {
      return this.lawyer.representativeClients.map((client) => {
        if (!this.representativeClients.find((representativeClient) => representativeClient.id === client.id)) {
          client._destroy = true;
        }

        return client;
      });
    },

    industriesWithPersistence() {
      var selectedIndustries = this.buildSelectedIndustries();
      var industriesToDelete = this.markIndustriesToBeDeleted(selectedIndustries);

      return _.union(selectedIndustries, industriesToDelete);
    },

    buildSelectedIndustries() {
      return this.selectedIndustries.map((industryId) => {
        var lawyerIndustry = this.lawyer.industries.find((industry) => industry.scoutFirmIndustryId === industryId);
        var id = lawyerIndustry ? lawyerIndustry.id : null;

        return {
          id: id,
          displayOnProfile: true,
          scoutFirmIndustryId: industryId
        };
      });
    },

    markIndustriesToBeDeleted(selectedIndustries) {
      return this.lawyer.industries.map((industry) => {
        if (!selectedIndustries.find((selectedIndustry) => selectedIndustry.id === industry.id)) {
          industry._destroy = true;
        }

        return industry;
      });
    },

    openModal() {
      if (!this.firm) {
        ScoutFirm.get({ id: 'profile', view: 'lists' }).then((response) => {
          this.firm = response;
          this.industries = this.firm.industries;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .menu-item-container {
    padding: 5px;

    li {
      padding: 5px 10px;
    }
  }
</style>
