<template>
  <div class="shadowed-box">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-8 text-uppercase">
            Company Details
          </div>

          <div class="col-sm-4 size-text-13px text-right-not-xs">
            <a href="" class="normal-weight" @click.prevent="startEdit" v-if="!isEditing">Edit</a>

            <span v-if="isEditing">
              <a href="" class="normal-weight right-15" @click.prevent="handleSubmit(save)" :disabled="isLoading">Save</a>
              <a href="" class="dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</a>
            </span>
          </div>
        </div>
      </div>

      <div class="box-content symmetrical">
        <form role="form" class="a-form" autocomplete="off" novalidate>
          <div class="row bottom-30">
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="col-sm-4 semibold-weight">
                Company Name<span class="red-text" v-if="isEditing"> *</span>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </div>

              <div class="col-sm-8">
                <div v-if="!isEditing">
                  <a :href="`/admin-app/workspaces/${workspace.id}`" target="_blank">{{ workspace.name || '&mdash;' }}</a>
                </div>

                <div class="top-10-xs" v-if="isEditing">
                  <input type="text" name="companyName" id="company-name" :class="['form-control', { 'has-error': errors.length }]" required v-model="workspaceCopy.name">
                </div>
              </div>
            </validation-provider>
          </div>

          <div class="row">
            <div class="semibold-weight" :class="isEditing ? 'col-sm-12 bottom-10' : 'col-sm-4'">
              Company Location
            </div>

            <div :class="isEditing ? 'col-sm-12' : 'col-sm-8'">
              <div v-if="!isEditing">
                {{ location }}
              </div>

              <div class="top-10-xs" v-if="isEditing">
                <div class="row tight-columns">
                  <div class="col-sm-4 bottom-10">
                    <input type="text" name="addressCountry" id="address-country" class="form-control" placeholder="Country" autocomplete="nope">
                    <typeahead v-model="workspaceCopy.country" target="#address-country" :data="countries" @input="countrySelected" force-select force-clear :limit="8"></typeahead>
                  </div>

                  <div class="col-sm-4 bottom-10">
                    <input type="text" name="addressState" id="address-state" class="form-control" :placeholder="workspaceCopy.country === 'United States' ? 'State' : 'N/A'" autocomplete="nope" :disabled="workspaceCopy.country !== 'United States'">
                    <typeahead v-model="workspaceCopy.state" target="#address-state" :data="states" force-select force-clear :limit="8"></typeahead>
                  </div>

                  <div class="col-sm-4">
                    <input type="text" name="addressCity" id="address-city" class="form-control" placeholder="City" v-model="workspaceCopy.city">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row top-30" v-if="!isEditing">
            <div class="col-sm-4 semibold-weight bottom-5">
              Logo
            </div>

            <div class="col-sm-8">
              <loading-section name="logoUpload">
                <div class="logo-container bottom-10" v-if="hasLogo">
                  <img :src="workspace.logo" alt="Team Logo" class="img-responsive contrasting-background">
                </div>

                <button type="button" class="pseudo-link-blue" @click="openFileSelect">{{ logoButtonText }}</button>

                <input v-show="false" ref="selectInput" type="file" max-size="'5MB'" accept=".jpg,.jpeg,.gif,.png,.bmp" @change="uploadLogo">
              </loading-section>
            </div>
          </div>

          <div class="row top-30" v-if="isEditing">
            <div class="col-sm-5 col-md-4">
              <loading-button name="updateWorkspaceButton" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(save)">Save</loading-button>
            </div>

            <div class="col-sm-5 col-md-4 top-10-xs">
              <button type="button" class="secondary-btn-blue a-button-size" @click="cancelEdit">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'AdminWorkspaceInfo',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onUploadLogo: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      isEditing: false,
      workspaceCopy: {}
    };
  },

  computed: {
    location() {
      return this.workspace.location();
    },

    countries() {
      return SharedLists.listOfCountries();
    },

    states() {
      return SharedLists.listOfStateNames();
    },

    isLoading() {
      return LoadingService.isLoading('updateWorkspaceButton');
    },

    hasLogo() {
      return !!this.workspace.logo;
    },

    logoButtonText() {
      return this.hasLogo ? 'Replace Logo' : 'Upload Logo';
    }
  },

  methods: {
    countrySelected() {
      if (this.workspaceCopy.country !== 'United States') {
        this.workspaceCopy.state = null;
      }
    },

    openFileSelect() {
      this.$refs.selectInput.click();
    },

    startEdit() {
      this.workspaceCopy = Object.assign({}, this.workspace);
      this.isEditing = true;
    },

    uploadLogo(event) {
      LoadingService.loading('logoUpload');

      this.onUploadLogo(event.target.files[0]).then(() => {
        LoadingService.done('logoUpload');
      });
    },

    cancelEdit() {
      this.$refs.observer.reset();
      this.isEditing = false;
    },

    save() {
      // TODO: this is a hack to fix the fact that unmatched typeaheads set the
      // model value to undefined, which isn't submitted with the form, so there's
      // no way to clear the value since it's not required. I tried various other
      // ways to do it but they were all weird.
      if (this.workspaceCopy.state === undefined) {
        this.workspaceCopy.state = null;
      }
      if (this.workspaceCopy.country === undefined) {
        this.workspaceCopy.country = null;
      }

      LoadingService.loading('updateWorkspaceButton');

      this.onSave(this.workspaceCopy).then(
        () => {
          LoadingService.done('updateWorkspaceButton');
          this.isEditing = false;
        },
        () => {
          LoadingService.done('updateWorkspaceButton');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  img.contrasting-background {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .logo-container {
    border-right: 0;
  }
</style>
