<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-4 text-uppercase">
          Users
        </div>

        <div class="col-sm-8 text-right-not-xs">
          <a href="/scout-company/settings/users" class="box-header-link">View/Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            View/Manage Users
          </div>

          <div class="dark-gray-text">
            Admin users can make a range of structural decisions about the application&rsquo;s implementation within a legal department.
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <div class="bottom-5">
            {{ adminUsersCount }}
          </div>

          <div class="bottom-5" v-if="workspace.userManagerEnabled">
            {{ userManagersCount }}
          </div>

          <div>
            {{ clientUsersCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UsersSummary',

  props: {
    workspace: {
      type: Object,
      required: true
    }
  },

  computed: {
    adminUsersCount() {
      return `${this.workspace.adminUsersCount} Admin ${this.$pluralize('User', this.workspace.adminUsersCount)}`;
    },

    clientUsersCount() {
      return `${this.workspace.clientUsersCount} Client ${this.$pluralize('User', this.workspace.clientUsersCount)}`;
    },

    userManagersCount() {
      return `${this.workspace.userManagerCount} User ${this.$pluralize('Manager', this.workspace.userManagerCount)}`;
    }
  }
};
</script>
