const states = [['Alabama', 1, 'AL'], ['Alaska', 2, 'AK'], ['Arizona', 3, 'AZ'], ['Arkansas', 4, 'AR'], ['California', 5, 'CA'], ['Colorado', 6, 'CO'], ['Connecticut', 7, 'CT'], ['Delaware', 8, 'DE'], ['District of Columbia', 9, 'DC'], ['Florida', 10, 'FL'], ['Georgia', 11, 'GA'], ['Hawaii', 12, 'HI'], ['Idaho', 13, 'ID'], ['Illinois', 14, 'IL'], ['Indiana', 15, 'IN'], ['Iowa', 16, 'IA'], ['Kansas', 17, 'KS'], ['Kentucky', 18, 'KY'], ['Louisiana', 19, 'LA'], ['Maine', 20, 'ME'], ['Maryland', 21, 'MD'], ['Massachusetts', 22, 'MA'], ['Michigan', 23, 'MI'], ['Minnesota', 24, 'MN'], ['Mississippi', 25, 'MS'], ['Missouri', 26, 'MO'], ['Montana', 27, 'MT'], ['Nebraska', 28, 'NE'], ['Nevada', 29, 'NV'], ['New Hampshire', 30, 'NH'], ['New Jersey', 31, 'NJ'], ['New Mexico', 32, 'NM'], ['New York', 33, 'NY'], ['North Carolina', 34, 'NC'], ['North Dakota', 35, 'ND'], ['Ohio', 36, 'OH'], ['Oklahoma', 37, 'OK'], ['Oregon', 38, 'OR'], ['Pennsylvania', 39, 'PA'], ['Puerto Rico', 40, 'PR'], ['Rhode Island', 41, 'RI'], ['South Carolina', 42, 'SC'], ['South Dakota', 43, 'SD'], ['Tennessee', 44, 'TN'], ['Texas', 45, 'TX'], ['Utah', 46, 'UT'], ['Vermont', 47, 'VT'], ['Virginia', 48, 'VA'], ['Washington', 49, 'WA'], ['West Virginia', 50, 'WV'], ['Wisconsin', 51, 'WI'], ['Wyoming', 52, 'WY']];

function stateName(id) {
  return states[id - 1] ? states[id - 1][0] : '';
}

function stateAbbreviation(id) {
  return states[id - 1] ? states[id - 1][2] : '';
}

function stateIdFromName(stateName) {
  var stateData = _.find(states, function (state) { return (state[0] === stateName || state[2] === stateName); });
  return stateData ? stateData[1] : null;
}

function stateAbbreviationFromName(stateName) {
  var stateData = _.find(states, function (state) { return (state[0] === stateName || state[2] === stateName); });
  return stateData ? stateData[2] : null;
}

export {
  states,
  stateName,
  stateAbbreviation,
  stateIdFromName,
  stateAbbreviationFromName
};
