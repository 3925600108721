<template>
  <div v-if="currentClientId">
    <div class="titlebar">
      <div class="row">
        <div class="col-xs-6">
          <div class="medium-page-heading">
            Dashboard
          </div>
        </div>

        <div class="col-xs-6 size-text-16px text-right top-4">
          Welcome, {{ currentClient.firstName }}
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <feature-lab-client-dashboard
            v-if="isFeatureLab"
            :notifications="rfqNotifications">
          </feature-lab-client-dashboard>

          <scout-client-dashboard
            v-else
            :current-client="currentClient"
            :notifications="rfqNotifications">
          </scout-client-dashboard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoutClientDashboard      from 'vue-app/scout/client/dashboard/dashboard.vue';
import FeatureLabClientDashboard from 'vue-app/feature-lab/client/dashboard.vue';
import RFQClientNotification     from 'resources/scout/rfq-client-notification.js';
import useCurrentUserStore       from 'vue-app/stores/current-user.js';
import { mapState }              from 'pinia';
import { groupBy }               from 'lodash';

export default {
  name: 'ScoutClientDashboardIndex',

  components: {
    FeatureLabClientDashboard,
    ScoutClientDashboard
  },

  data() {
    return {
      rfqNotifications: {}
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser', currentProduct: 'currentProduct' }),

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    currentClientId() {
      return this.currentClient.id;
    }
  },

  watch: {
    currentClientId() {
      this.fetchRfqNotifications();
    }
  },

  mounted() {
    if (this.currentClientId) {
      this.fetchRfqNotifications();
    }
  },

  methods: {
    fetchRfqNotifications() {
      if (!this.currentClient.hasRfqEnabled) { return; }

      return RFQClientNotification.query().then((notifications) => {
        this.rfqNotifications = groupBy(notifications, 'rfqName');
      });
    }
  }
};
</script>
