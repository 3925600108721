<template>
  <priori-modal ref="modal" @on-modal-close="onCloseModal">
    <template #title>
      <div class="text-center bottom-20">
        <div class="circular-gradient">
          <svg-icon name="checkmark-no-circle" class="base-icon checkmark"></svg-icon>
        </div>
      </div>

      <div>
        <div>
          {{ isLawyer ? 'Lawyer' : 'Law firm' }} successfully added to list
        </div>
      </div>
    </template>

    <template #default="{ closeModal }">
      <div class="size-text-14px bottom-30">
        You have successfully added the selected {{ isLawyer ? 'lawyer' : 'law firm' }} to the applicable list. To review your list, go to the left navigation bar and select "Lists", which is located below "Search".
      </div>

      <div class="row tight-columns">
        <div class="col-xs-4">
          <button type="button" class="primary-btn-blue" @click="closeModal">Close</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ScoutSuccessListModal',

  components: {
    PrioriModal,
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    showSuccessModal: {
      type: Boolean,
      default: false
    },

    isLawyer: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    showSuccessModal(value) {
      if (value) {
        this.$refs.modal.openModal();
      }
    }
  },

  methods: {
    onCloseModal() {
      // Emit the `on-modal-close` event further up the component stack so parent components can use @on-modal-close.
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .checkmark {
    margin-top: 16px;
    width: 20px;
    height: 20px;
    fill: $white;
  }
</style>
