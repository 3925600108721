<template>
  <div class="admin-comment-wrapper">
    <div v-if="!isEditing">
      <div class="row tight-columns bottom-10">
        <div class="col-xs-10">
          <span class="simple-text">{{ comment.body }}</span>
        </div>

        <div class="col-xs-2 text-right">
          <button type="button" class="pseudo-link-blue" @click="toggleEditing">Edit</button>
        </div>
      </div>

      <div class="dark-gray-text size-text-12px">
        {{ comment.author }} {{ formattedTime(comment.createdAt) }}
        <span v-if="showAsEdited">(edited {{ formattedTime(comment.updatedAt) }})</span>
      </div>
    </div>

    <div class="comment-editor" v-if="isEditing">
      <validation-observer v-slot="{ handleSubmit, failed: formInvalid }" ref="validationObserver">
        <form name="editCommentForm" class="a-form" novalidate>
          <div :class="formInvalid ? 'bottom-10' : 'bottom-5'">
            <textarea-vertical
              :id="`${comment.resourceType}CommentContent-${comment.resourceId}-${comment.id}`"
              :rows="5"
              rules="required"
              v-model="commentCopy.body">
            </textarea-vertical>
          </div>

          <div class="row tight-columns size-text-12px">
            <div class="col-xs-4">
              <confirmation-modal
                ref="deleteNoteModal"
                message="Are you sure you want to delete this note?"
                :on-confirm-action="deleteComment">
              </confirmation-modal>

              <button type="button" class="pseudo-link-blue bold-link red-link" @click="openConfirmationModal">Delete</button>
            </div>

            <div class="col-xs-8 text-right">
              <button type="button" class="pseudo-link-blue bold-link" @click="cancelEdit"><svg-icon name="undo" class="base-icon smaller right-5"></svg-icon>Cancel</button>

              <button type="button" class="pseudo-link-blue bold-link left-20" @click="handleSubmit(updateComment)">Save</button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal.vue';
import { ValidationObserver } from 'vee-validate';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import { clone } from 'lodash';

export default {
  name: 'AdminComment',

  components: {
    TextareaVertical,
    ConfirmationModal,
    ValidationObserver
  },

  props: {
    comment: {
      type: Object,
      required: true
    },

    onUpdateComment: {
      type: Function,
      required: true
    },

    onDeleteComment: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      commentCopy: clone(this.comment),
      isEditing: false
    };
  },

  computed: {
    showAsEdited() {
      return this.comment.updatedAt > this.comment.createdAt;
    }
  },

  methods: {
    formattedTime(date) {
      return DateFilter.longWithAt(date);
    },

    toggleEditing() {
      this.isEditing = true;
    },

    cancelEdit() {
      this.commentCopy = clone(this.comment);
      this.isEditing = false;
    },

    updateComment() {
      this.onUpdateComment(this.commentCopy).then(() => {
        this.isEditing = false;
      });
    },

    openConfirmationModal() {
      this.$refs.deleteNoteModal.openModal();
    },

    deleteComment() {
      this.onDeleteComment(this.comment).then(() => {
        this.isEditing = false;
      });
    }
  }
};
</script>
