<template>
  <validation-provider :name="name" :rules="rules" v-slot="{ errors, failed }" :mode="validationMode ? validationMode : passiveAggressive" :custom-messages="customErrorMessages">
    <label :for="id" v-if="label">{{ label }} <span class="inline-help required" v-if="required">*</span></label>

    <div class="input-with-icon">
      <input
        type="number"
        :id="id"
        :data-testid="id"
        :name="name"
        :class="['form-control', { 'has-error': failed, 'disabled-text': disabled }]"
        :placeholder="placeholder"
        min="0.01"
        step="0.01"
        :disabled="disabled"
        :value="value"
        @input="onInput"
        @keydown="onKeydown">
      <div class="form-icon">$</div>
    </div>

    <div class="error-text top-5" v-if="errors.length">
      {{ errors[0] }}
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { isString } from 'lodash';

export default {
  name: 'CurrencyInputVertical',

  components: {
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    value: {
      type: [String, Number],
      default: ''
    },

    validationMode: {
      type: String,
      default: null
    },

    label: {
      type: String,
      required: false
    },

    id: {
      type: String,
      required: false
    },

    name: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false
    },

    rules: {
      type: [String, Object],
      default: () => ({})
    },

    customErrorMessages: {
      type: Object,
      default: () => ({})
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    required() {
      if (isString(this.rules)) {
        return this.rules.includes('required');
      }
      else {
        return this.rules.required;
      }
    }
  },

  methods: {
    onInput(event) {
      if (!this.moreThanTwoDecimals(event.target.value)) {
        this.$emit('input', event.target.value);
      }
    },

    onKeydown(event) {
      if (event.key === 'Alphanumeric' && this.moreThanTwoDecimals(event.target.value)) {
        event.preventDefault();
      }
    },

    moreThanTwoDecimals(value) {
      return value.includes('.') && value.split('.')[1].length > 2;
    }
  }
};
</script>
