<template>
  <div>
    <div class="size-text-12px semibold-weight list-spacing-5" v-for="field in fields" :key="field.name">
      <span class="right-10">Sort by {{ field.label }}:</span>

      <a href="" :class="['right-10', { 'light-gray-link': !sortingBy(field.name, 'asc'), 'text-link': sortingBy(field.name, 'asc') }]" @click.prevent="updateSort(field.name, 'asc')">Asc</a>

      <a href="" :class="['right-10', { 'light-gray-link': !sortingBy(field.name, 'desc'), 'text-link': sortingBy(field.name, 'desc') }]" @click.prevent="updateSort(field.name, 'desc')">Desc</a>

      <a href="" :class="[{ 'light-gray-link': sortingBy(field.name), 'text-link': !sortingBy(field.name) }]" @click.prevent="removeSort(field.name)">Off</a>
    </div>
  </div>
</template>

<script>
import { has, omit } from 'lodash';

export default {
  name: 'SearchSort',

  props: {
    searchService: {
      type: Object,
      required: true
    },

    onSortApplied: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      fields: [
        { name: 'date_applied', label: 'Date Applied' },
        { name: 'default_rate', label: 'Default Rate' }
      ],
      sort: {}
    };
  },

  methods: {
    sortingBy(field, direction) {
      const sortSelected = has(this.sort, field);

      if (direction) {
        return sortSelected && this.sort[field] === direction;
      }
      else {
        return sortSelected;
      }
    },

    updateSort(field, direction) {
      this.sort = {};
      this.sort[field] = direction;

      this.applySort();
    },

    removeSort(field) {
      this.sort = omit(this.sort, [field]);

      this.applySort();
    },

    applySort() {
      this.onSortApplied(this.sort);
    }
  }
};
</script>
