<template>
  <div class="field-group">
    <div class="bottom-15">
      <slot name="input"></slot>
    </div>

    <div class="text-right">
      <a href="" class="remove-btn" @click.prevent="showDeleteConfirmationOptions(identifier)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon>Remove</a>
    </div>

    <div v-if="confirmationOptionsVisible" class="top-20">
      <div class="pill-gray-text size-text-12px bottom-15">
        Are you sure you’d like to remove this {{ itemType }}?
      </div>

      <div class="row">
        <div class="col-sm-3">
          <button type="button" class="secondary-btn-blue" @click.prevent="removeItem">Yes</button>
        </div>

        <div class="col-sm-3 top-20-xs">
          <button type="button" class="primary-btn-white" @click.prevent="hideDeleteConfirmationOptions(identifier)">No</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import useFieldGroupsStore from 'vue-app/stores/field-groups.js';
import { uniqueId } from 'lodash';

export default {
  name: 'FieldGroup',

  props: {
    onRemoveItem: {
      type: Function,
      required: true
    },

    itemType: {
      type: String,
      default: 'element'
    }
  },

  data: function () {
    return {
      identifier: uniqueId(`${this.itemType}_`)
    };
  },

  computed: {
    ...mapState(useFieldGroupsStore, {
      confirmationOptionsVisible(store) {
        return store.confirmationOptionsVisible(this.identifier);
      }
    })
  },

  methods: {
    ...mapActions(useFieldGroupsStore, [
      'showDeleteConfirmationOptions',
      'hideDeleteConfirmationOptions'
    ]),

    removeItem: function () {
      this.onRemoveItem();
      this.hideDeleteConfirmationOptions(this.identifier);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .field-group {
    padding: 20px;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-large;
  }

  .remove-btn {
    color: $k-darker-gray;
    font-weight: 600;
  }
</style>
