<template>
  <div class="shadowed-box">
    <div class="box-header compact text-uppercase">
      New Counsel Requests (Last 48 hours)
    </div>

    <div class="box-content dash-widget">
      <loading-section name="newRecentCounselRequests">
        <div class="size-text-13px" v-if="counselRequests.length">
          <table class="table auto-height no-margin">
            <tbody>
              <tr v-for="counselRequest in counselRequests" :key="counselRequest.id">
                <td>
                  <a :href="linkTo(counselRequest)">{{ counselRequest.requester.fullName }}</a>
                </td>
                <td>
                  {{ formattedDate(counselRequest.createdAt) }}
                </td>
                <td>
                  <span v-tooltip="`Phone: ${counselRequest.requester.phoneNumber}, Email ${counselRequest.requester.email}`"><svg-icon name="settings" class="base-icon"></svg-icon></span>
                </td>
                <td>
                  <a :href="`/admin-app/requests/${counselRequest.id}`" class="bold-weight size-text-12px">VIEW</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="gray-text" v-else>
          No New Requests
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'NewRequests',

  components: {
    LoadingSection,
    SvgIcon
  },

  props: {
    counselRequests: {
      type: Array,
      required: true
    }
  },

  methods: {
    linkTo(counselRequest) {
      return counselRequest.requester.adminViewPath || `/admin-app/requests/${counselRequest.id}`;
    },

    formattedDate(date) {
      return DateFilter.short(date);
    }
  }
};
</script>
