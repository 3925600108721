<template>
  <div class="grid-row law-firm-list-item">
    <div class="gd">
      <a :href="`/admin-app/law-firms/${lawFirm.id}`" target="_blank">{{ lawFirm.id }}</a>
    </div>

    <div class="gd">
      <a :href="`/admin-app/law-firms/${lawFirm.id}`" target="_blank">{{ lawFirm.name }}</a>
    </div>

    <div class="gd">
      <div class="list-spacing-5" v-for="lawyer in lawyers" :key="lawyer.id">
        <a :href="`/admin-app/lawyers/${lawyer.id}`" target="_blank">{{ lawyer.fullName }}</a>
      </div>

      <div v-if="!lawFirm.lawyers.length">
        &mdash;
      </div>
    </div>

    <div class="gd">
      <select :name="`select-${lawFirm.id}`" :id="`select-${lawFirm.id}`" v-model="selectedOption" @change="selectAction">
        <option value="null"></option>

        <option value="viewLawFirm" :name="`viewLawFirm-${lawFirm.id}`" :id="`view-law-firm-${lawFirm.id}`">
          View Law Firm
        </option>

        <option value="inviteLawyer" :name="`inviteLawyer-${lawFirm.id}`" :id="`invite-lawyer-${lawFirm.id}`">
          Invite Lawyer
        </option>

        <option v-if="!lawFirm.ssoEnabled" value="copyInviteURL" :name="`copyInviteURL-${lawFirm.id}`" :id="`copy-invite-url-${lawFirm.id}`" :disabled="invitingByLink">
          Copy Invite URL
        </option>
      </select>

      <div class="text-center">
        <copy-to-clipboard
          ref="copyLink"
          :id="`copy-${lawFirm.id}`"
          :text-to-copy="inviteUrl"
          tooltip-text="Invite link copied to clipboard">
        </copy-to-clipboard>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';

export default {
  name: 'LawFirmListItem',

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    onStartInvite: {
      type: Function,
      required: true
    },

    onGetInviteLink: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedOption: null,
      inviteUrl: '',
      invitingByLink: false
    };
  },

  computed: {
    lawyers() {
      return orderBy(this.lawFirm.lawyers, ['lastName', 'firstName']);
    }
  },

  methods: {
    selectAction() {
      switch (this.selectedOption) {
        case 'viewLawFirm':
          this.viewLawFirm();
          break;
        case 'inviteLawyer':
          this.startInvite();
          break;
        case 'copyInviteURL':
          this.copyInviteUrl();
          break;
      }

      this.selectedOption = null;
    },

    viewLawFirm() {
      window.open(`/admin-app/law-firms/${this.lawFirm.id}`, '_blank');
    },

    startInvite() {
      this.onStartInvite(this.lawFirm);
    },

    clickOnCopyLink() {
      this.$refs.copyLink.$refs.copyLink.click();
    },

    copyInviteUrl() {
      if (this.inviteUrl.length) {
        this.clickOnCopyLink();
      }
      else {
        this.invitingByLink = true;

        this.onGetInviteLink(this.lawFirm)
          .then((response) => {
            this.inviteUrl = response.link;
            setTimeout(this.clickOnCopyLink);
          })
          .finally(() => {
            this.invitingByLink = false;
          });
      }
    }
  }
};
</script>
