<template>
  <div class="dash-totals">
    <div class="row">
      <div class="col-sm-3 dash-totals-cell">
        <div class="tiny-gray-header-text">
          Total Billings
        </div>

        <div class="dash-total-amount">
          {{ formattedCurrency(lawyer.dashboardTotals.all / 100.0) }}
        </div>

        <div class="uiv dash-total-footnote">
          <span id="total-billing-tt">View Note</span>
          <tooltip
            :auto-placement="true"
            custom-class="k-tooltip tooltip-white"
            placement="bottom"
            text="Priori management and third-party payment processor fees are deducted from this total."
            target="#total-billing-tt">
          </tooltip>
        </div>
      </div>

      <div class="col-sm-3 dash-totals-cell">
        <div class="tiny-gray-header-text">
          Sent/Unpaid Invoices
        </div>

        <div class="dash-total-amount">
          {{ formattedCurrency(lawyer.dashboardTotals.unpaid / 100.0) }}
        </div>

        <div class="dash-total-footnote">
          <a href="/lawyer-app/invoices/filter/unpaid">View Invoices</a>
        </div>
      </div>

      <div class="col-sm-3 dash-totals-cell">
        <div class="tiny-gray-header-text">
          Unsent Invoices
        </div>

        <div class="dash-total-amount">
          {{ formattedCurrency(lawyer.dashboardTotals.unsent / 100.0) }}
        </div>

        <div class="dash-total-footnote">
          <a href="/lawyer-app/projects">View Projects</a>
        </div>
      </div>

      <div class="col-sm-3 dash-totals-cell">
        <div class="tiny-gray-header-text">
          Uninvoiced Line Items
        </div>

        <div class="dash-total-amount">
          {{ formattedCurrency(lawyer.dashboardTotals.uninvoiced / 100.0) }}
        </div>

        <div class="uiv dash-total-footnote">
          <span id="uninvoiced-line-item-tt">View Note</span>
          <tooltip
            :auto-placement="true"
            custom-class="k-tooltip tooltip-white"
            placement="bottom"
            text="All uninvoiced line items will be automatically invoiced on the 7th of the month."
            target="#uninvoiced-line-item-tt">
          </tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from 'vue-app/shared/mixins/currency-filter.js';

export default {
  name: 'DashboardTotals',

  mixins: [currencyFilter],

  props: {
    lawyer: {
      type: Object,
      required: true
    }
  }
};
</script>
