<template>
  <div>
    <div class="bottom-20">
      <filters-lawyers
        :search-service="searchService"
        :init-hidden="true"
        :on-filter-open="hidePanel"
        :on-search="applySearch"
        :search-definition="searchDefinition">
      </filters-lawyers>
    </div>

    <div class="resource-list">
      <div class="list-header">
        <div class="row tight-columns">
          <div class="col-sm-4">
            Lawyer
          </div>

          <div class="col-sm-7">
            <div class="row tight-columns">
              <div class="col-sm-3 text-center-not-xs">
                Match
              </div>

              <div class="col-sm-3 text-center-not-xs">
                Hourly&nbsp;Rate
              </div>

              <div class="col-sm-3 text-center-not-xs">
                Score
              </div>

              <div class="col-sm-3 text-center-not-xs">
                #&nbsp;Matters
                <span v-if="mattersTooltipText" class="info-icon">
                  <svg-icon id="matters-tooltip" name="info" class="base-icon smaller text-top"></svg-icon>
                  <tooltip
                    target="#matters-tooltip"
                    placement="bottom"
                    custom-class="tooltip-white"
                    :text="mattersTooltipText">
                  </tooltip>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <loading-section name="listItems">
        <div :class="['list-item', { 'selected': selectedLawyer === lawyer }]" v-for="lawyer in lawyers">
          <firm-lawyer
            :lawyer="lawyer"
            :resource-lists="lawyerLists"
            :on-view="showPanel"
            :on-toggle-favorite="toggleFavorite"
            :on-create-list="createList"
            :on-add-to-list="addToList">
          </firm-lawyer>
        </div>

        <div class="top-20" v-if="lawyers.length">
          <priori-pagination
            class="scout-pagination top-15"
            :current-page="page"
            :total-entries="searchService.count()"
            :per-page="10"
            @change="changePage">
          </priori-pagination>
        </div>

        <div class="list-item empty gray-text size-text-16px" v-if="!lawyers.length">
          No Results
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import FirmLawyer from 'vue-app/scout/client/law-firms/firm-lawyer.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import FiltersLawyers from 'vue-app/scout/shared/searches/filters-lawyers.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import FavoriteLawyer from 'resources/scout/favorite-lawyer.js';
import ResourceList from 'resources/scout/resource-list.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { find, includes, pull } from 'lodash';

export default {
  name: 'FirmLawyers',

  components: {
    FiltersLawyers,
    FirmLawyer,
    PrioriPagination,
    SvgIcon,
    LoadingSection
  },

  props: {
    lawyers: {
      type: Array,
      required: true
    },

    lawyerLists: {
      type: Array,
      required: true
    },

    page: {
      type: Number,
      required: true
    },

    searchService: {
      type: Object,
      required: true
    },

    selectedLawyer: {
      type: [Object, Boolean],
      required: true
    },

    applySearch: {
      type: Function,
      required: true
    },

    changePage: {
      type: Function,
      required: true
    },

    showPanel: {
      type: Function,
      required: true
    },

    hidePanel: {
      type: Function,
      required: true
    },

    createList: {
      type: Function,
      required: true
    },

    searchDefinition: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      totalCount: this.searchService.count()
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    mattersTooltipText() {
      return this.currentClient.workspace.scoutMattersTooltipText;
    }
  },

  methods: {
    toggleFavorite(lawyer) {
      if (lawyer.isFavorite) {
        lawyer.isFavorite = false;
        FavoriteLawyer.delete({ lawyer_bridge_id: lawyer.id });

        pull(this.currentClient.favoriteLawyers, lawyer.id);
      }
      else {
        lawyer.isFavorite = true;
        FavoriteLawyer.save({ lawyer_bridge_id: lawyer.id });

        this.currentClient.favoriteLawyers.push(lawyer.id);
      }
    },

    addToList(lawyer, listId) {
      const list = find(this.lawyerLists, { id: listId });

      if (list && list.isLawyerList() && !includes(list.itemIds, lawyer.id)) {
        list.itemIds.push(lawyer.id);
        return ResourceList.update({ id: list.id, resourceList: { itemIds: list.itemIds } });
      }
    }
  }
};
</script>
