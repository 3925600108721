<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-8 text-uppercase">
          Your Information
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <edit-modal title="Editing Your Information" :input="currentClient" :on-save="save">
            <template #default="{ inputCopy: clientCopy }">
              <div class="small-bold-header">
                Your Information
              </div>

              <hr class="top-5 bottom-5">

              <div class="dark-gray-text text-italic bottom-20">
                Please use the fields below to update your personal information. Please contact your system administrator to change your permission group, email address or phone number.
              </div>

              <div class="bottom-20">
                <text-input-vertical label="First Name" id="first-name" name="firstName" v-model="clientCopy.firstName" rules="required" :maxlength="50"></text-input-vertical>
              </div>

              <div class="bottom-20">
                <text-input-vertical label="Last Name" id="last-name" name="lastName" v-model="clientCopy.lastName" rules="required" :maxlength="50"></text-input-vertical>
              </div>

              <div class="bottom-20">
                <text-input-vertical label="Job Title" id="role" name="role" v-model="clientCopy.role" :maxlength="300"></text-input-vertical>
              </div>

              <div class="bottom-20">
                <div class="bold-weight bottom-5">
                  Permission Groups
                </div>

                <div>
                  {{ permissionGroupsText }}
                </div>
              </div>

              <div class="bottom-20">
                <div class="bold-weight bottom-5">
                  Email
                </div>

                <div>
                  {{ clientCopy.email || '&mdash;' }}
                </div>
              </div>

              <div class="bottom-20">
                <div class="bold-weight bottom-5">
                  Phone
                </div>

                <div>
                  {{ clientCopy.phoneNumber || '&mdash;' }}
                </div>
              </div>

              <div>
                <label for="time-zone">Time Zone</label>

                <select v-model="clientCopy.timeZone" name="timeZoneSelect" id="time-zone" class="form-control">
                  <option v-for="timeZone in timeZones" :value="timeZone.railsName" :key="timeZone.railsName">
                    {{ timeZone.railsName }}
                  </option>
                </select>
              </div>
            </template>

            <template #errorMessage>
              * One or more required fields are empty. Please fill in the required fields indicated above.
            </template>
          </edit-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="clientAccountInfo">
        <info-item
          label="First Name"
          :value="currentClient.firstName"
          class="bottom-20">
        </info-item>

        <info-item
          label="Last Name"
          :value="currentClient.lastName"
          class="bottom-20">
        </info-item>

        <info-item
          label="Job Title"
          :value="currentClient.role"
          class="bottom-20">
        </info-item>

        <info-item
          label="Permission Groups"
          :value="permissionGroupsText"
          class="bottom-20">
        </info-item>

        <info-item
          label="Email"
          :value="currentClient.email"
          class="bottom-20">
        </info-item>

        <info-item
          label="Phone"
          :value="currentClient.phoneNumber"
          class="bottom-20">
        </info-item>

        <info-item
          label="Time Zone"
          :value="currentClient.timeZone">
        </info-item>

        <div class="row top-20" v-if="!ssoEnabled">
          <div class="col-sm-4 bold-weight">
            Password
          </div>

          <div class="col-sm-8" v-if="currentClient.id">
            ******
            <span class="inline-block">
              <change-password-modal
                :resource="currentClient"
                :on-save="savePassword"
                :password-authentication="passwordAuthentication">
              </change-password-modal>
            </span>
          </div>

          <div class="col-sm-8" v-else>
            &mdash;
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import InfoItem from 'vue-app/shared/components/info-item.vue';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ChangePasswordModal from 'vue-app/scout/shared/change-password-modal.vue';
import TimeZoneHelper from 'vue-app/shared/services/time-zone-helper.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'AccountInfo',

  components: {
    InfoItem,
    EditModal,
    TextInputVertical,
    LoadingSection,
    ChangePasswordModal
  },

  props: {
    onSave: {
      type: Function,
      required: true
    },

    passwordAuthentication: {
      type: Function,
      required: true
    },

    updatePassword: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    ssoEnabled() {
      this.currentClient.workspace.ssoEnabled;
    },

    permissionGroupNames() {
      return (this.currentClient.practiceGroups || []).map((group) => {
        return group.name;
      });
    },

    permissionGroupsText() {
      return this.permissionGroupNames.join('; ') || '—';
    },

    timeZones: function () {
      return TimeZoneHelper.sortedByRailsName;
    }
  },

  methods: {
    save(currentClient) {
      LoadingService.loading('clientAccountInfo');

      return this.onSave(currentClient)
      .then(() => {
        window.location.href = '/sign-in';
      })
      .finally(() => {
        LoadingService.done('clientAccountInfo');
      });
    },

    savePassword(client) {
      LoadingService.loading('clientAccountInfo');

      return this.updatePassword({ password: client.password, passwordConfirmation: client.passwordConfirmation }).then(() => {
        window.location.href = '/sign-in';
      })
      .finally(() => {
        LoadingService.done('clientAccountInfo');
      });
    }
  }
};
</script>
