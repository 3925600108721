<template>
  <div>
    <dropdown-menu v-if="!isEditing" :compact="true" :align-right="false">
      <template #trigger>
        <button data-role="trigger" type="button" class="pseudo-link-blue blue-text bold-weight d-flex items-center gap-5">Add Section <svg-icon name="caret-down" class="base-icon smaller"></svg-icon></button>
      </template>

      <li class="menu-item" role="menuitem" v-if="canAddStandardSection">
        <rfq-template-add-standard-section
          :is-editing="isEditing">
        </rfq-template-add-standard-section>
      </li>

      <li class="menu-item" role="menuitem">
        <rfq-template-add-custom-section
          :is-editing="isEditing"
          :existing-groups="existingGroups">
        </rfq-template-add-custom-section>
      </li>
    </dropdown-menu>

    <rfq-template-add-custom-section
      v-else
      :group="group"
      :is-editing="isEditing"
      :existing-groups="existingGroups">
    </rfq-template-add-custom-section>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import RfqTemplateAddCustomSection from 'vue-app/scout/client/settings/rfqs/templates/new/add-custom-section.vue';
import RfqTemplateAddStandardSection from 'vue-app/scout/client/settings/rfqs/templates/new/add-standard-section.vue';
import { mapState } from 'pinia';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';

export default {
  name: 'RfqTemplateGroupForm',

  components: {
    SvgIcon,
    DropdownMenu,
    RfqTemplateAddCustomSection,
    RfqTemplateAddStandardSection
  },

  props: {
    group: {
      type: Object,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    existingGroups: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState(useRfqTemplateStore, {
      canAddStandardSection: 'canAddStandardSection'
    })
  }
};
</script>
