<template>
  <div :id="`lawyer-list-item-${lawyer.id}`" class="grid-row">
    <div class="gd lawyer-id">
      <a :href="`/admin-app/lawyers/${lawyer.id}`" target="_blank">{{ lawyer.id }}</a>
    </div>

    <div class="gd">
      <a :href="`/admin-app/lawyers/${lawyer.id}`" target="_blank">{{ lawyer.lastName }}, {{ lawyer.firstName }} {{ lawyer.middleName }}</a>
    </div>

    <div class="gd">
      <span class="simple-text">{{ lawyer.email }}</span>
    </div>

    <div class="gd">
      {{ lawyer.location || '&mdash;' }}
    </div>

    <div class="gd">
      <div v-for="(bar, index) in lawyer.barAdmissions" :key="`${bar}-${index}`">
        {{ bar }}
      </div>

      <span v-if="!lawyer.barAdmissions.length">&mdash;</span>
    </div>

    <div class="gd">
      {{ lawyer.displayStatus() || '&mdash;' }}
    </div>

    <div class="gd">
      {{ lawyer.contactId || '&mdash;' }}
    </div>

    <div class="gd">
      {{ lawyer.leadId || '&mdash;' }}
    </div>

    <div class="gd">
      <select :name="`select-${lawyer.id}`" :id="`select-${lawyer.id}`" v-model="selectedOption" @change="selectAction">
        <option value="null"></option>

        <option value="viewLawyer" :name="`viewLawyer-${lawyer.id}`" :id="`viewLawyer-${lawyer.id}`">
          View Lawyer
        </option>

        <option value="messageLawyer" :name="`messageLawyer-${lawyer.id}`" :id="`messageLawyer-${lawyer.id}`">
          Message Lawyer
        </option>

        <option value="impersonateLawyer" :name="`impersonateLawyer-${lawyer.id}`" :id="`impersonateLawyer-${lawyer.id}`">
          Impersonate Lawyer
        </option>
      </select>

      <impersonate-modal
        :user="lawyer"
        :target="'_self'"
        :modal-trigger="impersonateLawyer"
        :on-cancel="cancelImpersonation">
      </impersonate-modal>
    </div>
  </div>
</template>

<script>
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';

export default {
  name: 'LawyerListItem',

  components: {
    ImpersonateModal
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    sendMessageService: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedOption: null,
      impersonateLawyer: false
    };
  },

  methods: {
    resetSelection() {
      this.selectedOption = null;
    },

    selectAction() {
      switch (this.selectedOption) {
        case 'viewLawyer':
          this.viewLawyerProfile();
          break;
        case 'messageLawyer':
          this.sendMessage();
          break;
        case 'impersonateLawyer':
          this.impersonateLawyer = true;
          break;
      }

      this.resetSelection();
    },

    viewLawyerProfile() {
      window.open(`/admin-app/lawyers/${this.lawyer.id}`, '_blank');
    },

    sendMessage() {
      this.sendMessageService.modal([this.lawyer]);
    },

    cancelImpersonation() {
      this.impersonateLawyer = false;
    }
  }
};
</script>
