<template>
  <div class="list-item matter-row no-hover">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-3 clickable" @click="viewScoutLawyer(review.resource.id, 'reviews')" v-if="isLawyerReview">
        <lawyer-name-plate :lawyer="review.resource"></lawyer-name-plate>
      </div>

      <div :class="['col-sm-3', { 'top-10-xs': isLawyerReview }]">
        <div v-if="isMatterReview">
          <div class="semibold-weight text-ellipsis">
            <a :href="`/scout-company/matters/${review.matter.id}`" target="_blank">{{ review.matter.name }}</a>
          </div>

          <div class="pill-gray-text text-italic size-text-12px text-ellipsis" v-if="matterCode">
            #{{ matterCode }}
          </div>
        </div>

        <div class="semibold-weight text-ellipsis" v-else>
          General Review
        </div>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="size-text-12px semibold-weight">
          Score
        </div>

        <div class="top-2">
          {{ review.recommendation }}/10
        </div>
      </div>

      <div class="col-sm-3 top-10-xs" v-if="showComplexity">
        <div v-if="review.complexity">
          <div class="size-text-12px semibold-weight tight-lines">
            {{ reviewTemplate.complexityPromptBrief }}
          </div>

          <level-widget
            class="top-2"
            :label="complexityLabel"
            :score="review.complexity">
          </level-widget>
        </div>
      </div>

      <div :class="['size-text-12px top-10-xs', { 'col-sm-3': showComplexity, 'col-sm-6': !showComplexity }]">
        <div class="semibold-weight tight-lines">
          Reviewed By
        </div>

        <div class="inline-flex top-2">
          <div class="initials-circle">
            {{ reviewerInitials }}
          </div>

          <div class="left-5">
            {{ review.reviewer.fullName }} on {{ updatedAt }}
          </div>
        </div>
      </div>

      <div class="col-sm-1 text-right-not-xs">
        <a :href="`/scout-company/reviews/${review.id}`" target="_blank" class="semibold-weight">View</a>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import LevelWidget from 'vue-app/shared/components/level-widget.vue';

export default {
  name: 'ReviewsListItem',

  components: {
    LawyerNamePlate,
    LevelWidget
  },

  props: {
    review: {
      type: Object,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    reviewsType: {
      type: String,
      required: true
    }
  },

  computed: {
    isFirmReview() {
      return this.reviewsType === 'firm';
    },

    isLawyerReview() {
      return this.reviewsType === 'lawyers';
    },

    isMatterReview() {
      return this.review.scoutMatterId;
    },

    matterCode() {
      return this.review.matter?.code;
    },

    complexityLabel() {
      return this.reviewTemplate.complexityLabels()[this.review.complexity - 1] || 'N/A';
    },

    reviewerInitials() {
      return (this.review.reviewer.firstName[0] + this.review.reviewer.lastName[0]).toUpperCase();
    },

    updatedAt() {
      return DateFilter.filter(this.review.updatedAt);
    },

    showComplexity() {
      return this.isFirmReview && this.reviewTemplate.complexityShown;
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-item {
    padding: 15px 20px;

    @media (min-width: 768px) {
      padding: 15px 30px;
    }
  }
</style>
