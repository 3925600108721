<template>
  <priori-modal
    ref="modal"
    modal-id="merge-client"
    title="Merge Team Member">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openPrioriModal(openModal)">Select Client</a>
    </template>

    <template #default>
      <div v-if="!merged && workspace">
        <div :class="{ 'bottom-30': client }">
          <div class="bottom-20">
            Enter Admin ID of client to move into this team <span class="semibold-weight purple-text">({{ workspace.name }})</span>
          </div>

          <form role="form" name="findForm" autocomplete="off" class="a-form" novalidate>
            <div class="row">
              <div class="col-sm-8">
                <input type="number" name="clientId" id="clientId" v-model="clientId" class="form-control" placeholder="Client ID" autocomplete="off" min="1" step="1">
              </div>

              <div class="col-sm-4 top-10-xs">
                <loading-button name="findClientButton" class="nv-button-blue a-button-size" :lb-disabled="!formValid" @lb-click="findClient">Find</loading-button>
              </div>
            </div>
          </form>
        </div>

        <div v-if="client">
          <div class="separator">
            <hr class="kenny-hr no-margin">
          </div>

          <div class="bottom-30 top-30">
            <div class="bottom-10">
              <span class="semibold-weight right-10">Client Name:</span>
              <a :href="`/admin-app/clients/${client.id}`" target="_blank">{{ client.fullName }}</a>
            </div>

            <div class="bottom-20" v-if="sourceWorkspace">
              <span class="semibold-weight right-10">Current Team:</span>
              <a :href="`/admin-app/workspaces/${sourceWorkspace.id}`" target="_blank">{{ sourceWorkspace.name }}</a>
            </div>

            <div class="retainer-panel" v-if="sourceWorkspace">
              <div class="tiny-header-text bottom-20">
                Team History
              </div>

              <div class="row bottom-10">
                <div class="col-sm-4 semibold-weight">
                  Requests
                </div>

                <div class="col-sm-8">
                  <div class="text-ellipsis" v-for="cr in sourceWorkspace.counselRequests" :key="cr.id">
                    <a :href="`/admin-app/requests/${cr.id}`" target="_blank">{{ cr.nameForClientsReference }}</a>
                  </div>

                  <div v-if="hasNoCounselRequests">
                    &mdash;
                  </div>
                </div>
              </div>

              <div class="row bottom-10">
                <div class="col-sm-4 semibold-weight">
                  Projects
                </div>

                <div class="col-sm-8">
                  <div class="text-ellipsis" v-for="matter in sourceWorkspace.matters" :key="matter.id">
                    <a :href="`/admin-app/matters/${matter.id}`" target="_blank">{{ matter.nameForLawyersReference }}</a>
                  </div>

                  <div v-if="hasNoMatters">
                    &mdash;
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4 semibold-weight">
                  Documents
                </div>

                <div class="col-sm-8">
                  <div class="text-ellipsis" v-for="document in sourceWorkspace.internalDocuments" :key="document.id">
                    {{ document.name }}
                  </div>

                  <div v-if="hasNoDocuments">
                    &mdash;
                  </div>
                </div>
              </div>

              <hr class="kenny-hr">

              <div class="tiny-header-text bottom-20">
                Team Contacts
              </div>

              <div class="row bottom-10">
                <div class="col-sm-4 semibold-weight">
                  Primary
                </div>

                <div class="col-sm-8">
                  {{ primaryContact ? 'Yes' : 'No' }}
                </div>
              </div>

              <div class="row bottom-10">
                <div class="col-sm-4 semibold-weight">
                  Secondary
                </div>

                <div class="col-sm-8">
                  {{ secondaryContact ? 'Yes' : 'No' }}
                </div>
              </div>

              <div class="row bottom-10">
                <div class="col-sm-4 semibold-weight">
                  Primary AP
                </div>

                <div class="col-sm-8">
                  {{ primaryApContact ? 'Yes' : 'No' }}
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4 semibold-weight">
                  Secondary AP
                </div>

                <div class="col-sm-8">
                  {{ secondaryApContact ? 'Yes' : 'No' }}
                </div>
              </div>
            </div>
          </div>

          <div class="separator">
            <hr class="kenny-hr no-margin">
          </div>

          <div class="top-30" v-if="sourceWorkspace">
            <div v-if="!canBeMerged">
              <div class="row tight-columns red-text size-text-14px bottom-30">
                <div class="col-xs-2 col-sm-1">
                  <svg-icon name="reject" class="base-icon"></svg-icon>
                </div>

                <div class="col-xs-10 col-sm-11">
                  {{ client.fullName }}
                  <span v-if="clientInWorkspace">is already a member of this team.</span>
                  <span v-else>can not be moved into this team due to conflicts.</span>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-3">
                  <button type="button" class="nv-button-white a-button-size" @click="closeModal">Close</button>
                </div>
              </div>
            </div>

            <div v-if="canBeMerged">
              <form role="form" name="mergeForm" class="a-form" novalidate>
                <div class="row tight-columns green-text size-text-14px bottom-30">
                  <div class="col-xs-2 col-sm-1">
                    <svg-icon name="checkmark-green" class="base-icon"></svg-icon>
                  </div>

                  <div class="col-xs-10 col-sm-11">
                    {{ client.fullName }} can be moved to this team ({{ workspace.name }})
                  </div>
                </div>

                <div class="bottom-10" v-if="!workspaceClients.length">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" id="deleteWorkspace" name="deleteWorkspace" v-model="deleteWorkspace">
                      <span class="normal-weight dark-gray-text vertical-middle-text">Delete other team ({{ sourceWorkspace.name }})</span>
                    </label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-3">
                    <loading-button name="mergeButton" lb-class="nv-button-blue a-button-size" @lb-click="mergeClient">Move Client</loading-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div v-if="merged">
        <div class="notice-text">
          You successfully moved the client to this team.
        </div>

        <div class="row">
          <div class="col-xs-8 col-xs-offset-2 col-sm-4 col-sm-offset-4">
            <button type="button" class="nv-button-white a-button-size" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import Client from 'resources/client';
import Workspace from 'resources/workspace';
import { find } from 'lodash';

export default {
  name: 'WorkspaceMergeClient',

  components: {
    PrioriModal,
    LoadingButton,
    SvgIcon
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    onClientMerged: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      clientId: null,
      client: null,
      merged: false,
      deleteWorkspace: true,
      sourceWorkspace: null
    };
  },

  computed: {
    formValid() {
      return !!this.clientId;
    },

    primaryContact() {
      return this.sourceWorkspace.primaryContactId === this.client.id;
    },

    secondaryContact() {
      return this.sourceWorkspace.secondaryContactId === this.client.id;
    },

    primaryApContact() {
      return this.sourceWorkspace.primaryApContactId === this.client.id;
    },

    secondaryApContact() {
      return this.sourceWorkspace.secondaryApContactId === this.client.id;
    },

    clientInWorkspace() {
      return find(this.workspace.clients, { id: this.client.id });
    },

    workspaceClients() {
      return this.sourceWorkspace.clients.filter((c) => c.id !== this.client.id);
    },

    canBeMerged() {
      return this.hasNoCounselRequests &&
             this.hasNoMatters &&
             this.hasNoDocuments &&
             !this.clientInWorkspace &&
             !this.primaryContact &&
             !this.secondaryContact &&
             !this.primaryApContact &&
             !this.secondaryApContact;
    },

    hasNoCounselRequests() {
      return this.sourceWorkspace.counselRequests.length === 0;
    },

    hasNoMatters() {
      return this.sourceWorkspace.matters.length === 0;
    },

    hasNoDocuments() {
      return this.sourceWorkspace.internalDocuments.length === 0;
    }
  },

  methods: {
    openPrioriModal(openModalFn) {
      this.clientId = null;
      this.client = null;
      this.merged = false;
      this.deleteWorkspace = true;
      this.sourceWorkspace = null;

      openModalFn();
    },

    findClient() {
      LoadingService.loading('findClientButton');

      Client.get({ id: this.clientId }).then(
        (client) => {
          this.client = client;

          if (client.workspaceId) {
            Workspace.get({ id: client.workspaceId, view: 'merge' }).then(
              (workspace) => {
                this.sourceWorkspace = workspace;
              }
            );
          }
        },
        () => {
          this.client = null;
          this.sourceWorkspace = null;
        }
      ).finally(() => {
        LoadingService.done('findClientButton');
      });
    },

    mergeClient() {
      LoadingService.loading('mergeButton');

      Workspace.mergeClient(this.workspace.id, { clientId: this.client.id, deleteWorkspace: this.deleteWorkspace }).then(
        (response) => {
          LoadingService.done('mergeButton');
          this.merged = true;
          this.onClientMerged(response.data);
        },
        () => {
          this.merged = false;
          LoadingService.done('mergeButton');
        }
      );
    },

    closeModal() {
      this.$refs.modal.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  .separator {
    margin: 0 -30px;
  }

  .notice-text {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
  }
</style>
