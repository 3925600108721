import BaseResource from 'resources/base-resource';
import axios from 'axios';

class RFQTemplate extends BaseResource {
  static baseUrl = '/scout/rfq_templates/:id.json';

  static duplicate(id, params) {
    return axios.post(`/scout/rfq_templates/${id}/duplicate.json`, params);
  }
}

export default RFQTemplate;
