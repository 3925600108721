<template>
  <div class="data-disclaimer">
    <div class="row display-flex">
      <div class="col-xs-12 col-md-3 col-lg-2">
        <svg-icon name="scout-logo" class="brand-icon top-5"></svg-icon>
      </div>

      <div class="col-xs-12 col-sm-9 col-md-6 col-lg-7 top-20-xs-sm">
        <div class="tight-lines bold-weight bottom-5">
          Some profile data may not yet be available.
        </div>

        <div class="tight-lines">
          Please notify your Scout administrator if you encounter missing profile data and would like to see more information about that lawyer or law firm in Scout.
        </div>
      </div>

      <div class="col-xs-12 col-sm-3 text-right-not-xs">
        <div class="h-100 top-10-xs">
          <priori-modal>
            <template #modal-trigger="{ openModal }">
              <button type="button" class="secondary-btn-blue smaller" @click="openModal">Learn More</button>
            </template>

            <template #title>
              <div class="title-logo">
                <svg-icon name="scout-logo" class="brand-icon"></svg-icon>
              </div>
            </template>

            <template #default="{ closeModal }">
              <div class="bold-weight bottom-30">
                Some profile data may not yet be available.
              </div>

              <p>
                Some lawyer and law firm profiles are not yet populated or are missing certain data elements. Profile data may not be available because the law firm wasn’t invited to join Scout, hasn’t yet onboarded to Scout, or hasn’t yet provided  data for a particular lawyer.
              </p>

              <div class="top-30">
                Please notify your Scout administrator if you encounter missing data on a lawyer or law firm profile and would like more information about that lawyer or law firm.
              </div>

              <div class="row tight-columns top-30">
                <div class="col-sm-4">
                  <button type="button" class="primary-btn-blue" @click="closeModal">Close</button>
                </div>
              </div>
            </template>
          </priori-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';

export default {
  name: 'DataDisclaimer',

  components: {
    SvgIcon,
    PrioriModal
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .brand-icon {
    max-width: 125px;
  }

  .title-logo {
    .brand-icon {
      max-width: 110px;
    }
  }

  .display-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .secondary-btn-blue.smaller {
    width: fit-content;
    padding: 10px 30px;
    white-space: nowrap;
  }

  .data-disclaimer {
    padding: 15px 30px;
    background: white;
    border-radius: $border-radius-large;

    > [class*='col-'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
