<template>
  <priori-modal ref="modal" title="Delete Lawyer Profile Data" @on-modal-close="reset">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="secondary-btn-red a-button-size" @click="openModal">Delete Lawyer Data</button>
    </template>

    <template #default="{ closeModal }">
      <div v-if="isConfirmMode">
        <div class="notification-panel-error bottom-30">
          Are you sure you would like to delete all lawyer data for {{ lawFirm.name }} with the exception of the following lawyers?
        </div>

        <ul class="bottom-30">
          <li v-for="lawyer in preservedLawyers" :key="lawyer.id">
            {{ lawyer.firstName }} {{ lawyer.lastName }} (ID: {{ lawyer.id }})
          </li>
        </ul>

        <div class="row tight-columns">
          <div class="col-sm-5">
            <loading-button name="submit" class="nv-button-red a-button-size" @lb-click="submitForm">Yes, Delete Data</loading-button>
          </div>

          <div class="col-sm-3 top-10-xs">
            <button type="button" class="nv-button-white a-button-size" @click="clickNoButton">No</button>
          </div>
        </div>
      </div>

      <div v-else>
        <form role="form" name="clearDataForm" class="form a-form bottom-30" novalidate>
          <div class="bottom-20 semibold-weight">
            Please input the Lawyer IDs of any lawyers whose data should not be deleted.
            You can submit multiple IDs separated by commas. Please do not include any spaces between IDs.
          </div>

          <div class="bottom-30 text-italic">
            Note: The main contact lawyer for the firm will always be preserved.
          </div>

          <div class="notification-panel-error">
            Any lawyers at the law firm whose ID is not input below will be removed from the law firm and deleted from the database.
          </div>

          <input
            type="text"
            name="preservedLawyerIdList"
            id="preserved-lawyer-id-list"
            v-model="preservedLawyerIdList"
            class="form-control top-30"
            autocomplete="off"
            placeholder="Ex: 1,2,3">
        </form>

        <div class="row tight-columns">
          <div class="col-sm-5">
            <loading-button name="submit" lb-class="nv-button-red a-button-size" @lb-click="submitForm">Delete Profiles</loading-button>
          </div>

          <div class="col-sm-3 top-10-xs">
            <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
          </div>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'LawFirmClearDataModal',

  components: {
    LoadingButton,
    PrioriModal
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    onSubmitted: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      preservedLawyerIdList: '',
      isConfirmMode: false
    };
  },

  methods: {
    reset() {
      this.preservedLawyerIdList = '';
      this.isConfirmMode = false;
    },

    clickNoButton() {
      this.isConfirmMode = false;
    },

    submitForm() {
      const params = {
        id: this.lawFirm.id,
        preservedLawyerIdList: this.preservedLawyerIdList,
        commit: this.isConfirmMode
      };

      LoadingService.loading('submit');
      this.onSubmitted(params)
        .then((data) => {
          this.preservedLawyers = data.preservedLawyers;

          if (data.result) {
            this.$refs.modal.closeModal();
          }
          else {
            this.isConfirmMode = true;
          }
        })
        .catch(() => {
          this.$refs.modal.closeModal();
        })
        .finally(() => {
          LoadingService.done('submit');
        });
    }
  }
};
</script>
