<template>
  <div class="bar-chart-wrapper">
    <ul class="no-bullets">
      <li v-for="(item, index) in chartData" :key="index">
        <div class="item-name">
          {{ item.name }}
        </div>

        <div class="item-value">
          <span v-for="(subItem, subIndex) in item.values" :key="subIndex" :style="{ 'width': `${widthFor(subItem)}%`, 'background-color': subItem.color }" class="bar vertical-middle" v-if="widthFor(subItem) > 0"></span>
        </div>
      </li>
    </ul>

    <div v-if="asPercent" class="percentage-panel-container" :style="{ 'width': '49%', 'height': totalHeight }">
      <canvas ref="canvas"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  props: {
    chartData: {
      type: Array,
      required: true
    },

    asPercent: {
      type: Boolean,
      default: false
    },

    maxBarLength: {
      type: Number,
      default: 215
    }
  },

  computed: {
    totalHeight: function () {
      const barHeight = 16;
      return `${2 * barHeight * this.chartData.length}px`;
    },

    dataValues: function () {
      return _.map(this.chartData, 'value');
    },

    maxItemValue: function () {
      if (this.asPercent) { return 100; }
      return _.max(this.dataValues);
    },

    percentagePanelWidth: function () {
      return '300px';
    }
  },

  mounted: function () {
    this.chartObject = this.renderChart();
  },

  updated: function () {
    this.chartObject.destroy();
    this.chartObject = this.renderChart();
  },

  methods: {
    widthFor: function (item) {
      if (!this.maxItemValue) { return 0; }

      const modifier = this.asPercent ? item.percentage : item.value;

      // The minimum size to draw the bar must be 2
      const minModifer = (this.asPercent && modifier > 0 && modifier < 3) ? 2 : modifier;

      // TODO: Scout; I used 90 to get it to the approximate full width, but it's not exact and should be refined
      const maxLength = this.asPercent ? 90 : this.maxBarLength;

      return Math.floor((minModifer / this.maxItemValue) * maxLength);
    },

    renderChart: function () {
      return new Chart(this.$refs.canvas, {
        type: 'horizontalBar',

        data: {},

        options: {
          responsive: true,
          aspectRatio: 1,
          maintainAspectRatio: false,

          legend: { display: false },
          animation: { duration: 0 },

          scales: {
            xAxes:  [{
              ticks: {
                callback: (value) => { return value + '%'; },
                min: 0,
                max: 100,
                fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
                fontSize: 10
              }
            }],

            yAxes: [{
              display: false
            }]
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .bar-chart-wrapper {
    position: relative;
  }

  ul {
    z-index: 50;
    position: relative;

    li {
      margin: 15px 0;
      font-size: 11px;

      @media(min-width: 768px) {
        margin: 10px 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .item-name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;

        @media (min-width: 768px) {
          display: inline-block;
          width: 49%;
          text-align: right;
        }
      }

      .item-value {
        width: 100%;

        @media (min-width: 768px) {
          display: inline-block;
          width: 49%;
          margin-left: 5px;
        }

        .bar {
          height: 16px;
          display: inline-block;

          &:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

          &:last-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
      }
    }
  }

  .percentage-panel-container {
    // TODO: Scout; make this display properly
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    @media(min-width: 768px) {
      display: block;
      left: 48.5%;
    }
  }
</style>
