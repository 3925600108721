<template>
  <div :class="['list-item matter-row no-hover no-border-radius', { 'expanded': isExpanded }]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-6">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-5">
            <div class="size-text-14px semibold-weight text-ellipsis">
              <a :href="`/scout-company/matters/${timekeeping.scoutMatterId}`">{{ scoutMatter.name }}</a>
            </div>

            <div class="pill-gray-text text-italic size-text-12px text-ellipsis tight-lines" v-if="scoutMatter.code">
              #{{ scoutMatter.code }}
            </div>

            <div class="size-text-12px tight-lines">
              <span class="semibold-weight">Manager:</span> {{ managerNames }}
            </div>
          </div>

          <div class="col-xs-6 col-sm-3 top-10-xs">
            <div class="size-text-12px semibold-weight">
              Status
            </div>

            <div>
              {{ openOrClosedText }}
            </div>
          </div>

          <div class="col-xs-6 col-sm-4 top-10-xs">
            <div class="size-text-12px semibold-weight">
              Total Spent
            </div>

            <div :class="{ 'missing-text': timekeeping.placeholder }">
              {{ totalSpent }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-6 top-10-xs">
            <div class="tag-list" v-if="timekeeping.practiceArea">
              <div class="tag-list-item gray compact size-text-12px">
                {{ timekeeping.practiceArea }}
              </div>
            </div>

            <div v-else>
              &mdash;
            </div>
          </div>

          <template v-if="hasReviews">
            <div class="col-xs-6 col-sm-4 text-right-not-xs">
              {{ reviewsCountText }}
            </div>

            <div class="col-xs-6 col-sm-2 text-right">
              <button type="button" class="nv-button-square-white" @click="toggleReviews">
                <svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded': isExpanded }]"></svg-icon>
              </button>
            </div>
          </template>

          <template v-else>
            <div class="col-sm-6 dark-gray-text text-right-not-xs">
              No reviews
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="top-30" v-if="isExpanded && hasReviews">
      <scout-review-summary
        v-for="review in reviews"
        :key="`review-${review.id}`"
        :review="review"
        :review-template="reviewTemplate"
        class="list-spacing-10">
      </scout-review-summary>
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import ScoutReviewSummary from './scout-review-summary.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'LawyerMatterReviews',

  components: {
    ScoutReviewSummary
  },

  props: {
    timekeeping: {
      type: Object,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isExpanded: true
    };
  },

  computed: {
    scoutMatter() {
      return this.timekeeping.scoutMatter;
    },

    reviews() {
      return this.timekeeping.reviews;
    },

    hasReviews() {
      return this.reviews.length > 0;
    },

    reviewsCountText() {
      return this.$pluralize('review', this.reviews.length, true);
    },

    openOrClosedText() {
      return this.scoutMatter.closedOn ? 'Closed' : 'Open';
    },

    totalSpent() {
      if (this.timekeeping.placeholder) { return 'Not Available'; }

      const fee = this.timekeeping.feeTotalInUsCents;
      if (typeof fee !== 'number') {
        return fee;
      }
      else {
        return CurrencyFilter.filter(fee / 100);
      }
    },

    managersExist() {
      return this.timekeeping.scoutMatter.managerFullNames.length > 0;
    },

    managerNames() {
      return this.managersExist ? sortBy(this.timekeeping.scoutMatter.managerFullNames).join(', ') : '—';
    }
  },

  methods: {
    toggleReviews() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
