<template>
  <priori-modal ref="modal" title="Add/Remove Law Firm Tags">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openPrioriModal(openModal)">Add/Remove Tags</a>
    </template>

    <template #default>
      <validation-observer v-slot="{ handleSubmit }">
        <form role="form" name="form" class="a-form" novalidate>
          <div class="small-bold-header">
            Law Firm Tags
          </div>

          <hr class="top-5 bottom-5">

          <div class="dark-gray-text text-italic bottom-20">
            Please select which law firm tags you want to apply to {{ firm.name }}&rsquo;s profile. If you want to create a new tag or edit/reorder existing tags, please visit the Admin Settings page.
          </div>

          <validation-provider ref="scoutTags" v-slot="{ errors }">
            <div class="row">
              <div class="col-sm-8">
                <multi-select-dropdown
                  :value="selectedTagNames"
                  :options="tagNames"
                  :show-typeahead="false"
                  :option-class-fn="scoutTagColorClass"
                  @change="updateScoutTags">
                </multi-select-dropdown>
              </div>
            </div>

            <div class="top-15 bottom-30">
              <editable-scout-tags-list
                :on-remove-tag="removeTag"
                :scout-tags="selectedTags">
              </editable-scout-tags-list>

              <div class="error-text top-10" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>
            </div>
          </validation-provider>

          <div class="row">
            <div class="col-sm-4">
              <loading-button name="save-button" lb-class="primary-btn-blue" @lb-click="handleSubmit(submitForm)">Save</loading-button>
            </div>

            <div class="col-sm-3 top-10-xs">
              <button type="button" class="secondary-btn-blue" @click.prevent="closeModal">Cancel</button>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import EditableScoutTagsList from 'vue-app/scout/client/law-firms/editable-scout-tags-list.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import MultiSelectDropdown from 'vue-app/shared/components/multi-select-dropdown.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { sortScoutTags } from 'resources/utils/tag-sorter.js';
import { without } from 'lodash';

export default {
  name: 'AddRemoveTagsModal',

  components: {
    EditableScoutTagsList,
    MultiSelectDropdown,
    PrioriModal,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    },

    firm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedTagIds: []
    };
  },

  computed: {
    tagNames() {
      return sortScoutTags(this.scoutTags).map(tag => tag.name);
    },

    selectedTags() {
      return this.scoutTags.filter(tag => this.selectedTagIds.includes(tag.id));
    },

    selectedTagNames() {
      return this.selectedTags.map(tag => tag.name);
    }
  },

  methods: {
    openPrioriModal(openModalFn) {
      this.selectedTagIds = this.firm.scoutTags.map(tag => tag.id);

      openModalFn();
    },

    updateScoutTags(tagNames) {
      this.selectedTagIds = tagNames.map(name => this.scoutTags.find(tag => tag.name === name).id);
    },

    removeTag(tagId) {
      this.selectedTagIds = without(this.selectedTagIds, tagId);
    },

    submitForm() {
      const firmParams = {
        id: this.firm.id,
        lawFirmBridge: {
          scoutTagIds: this.selectedTagIds
        }
      };

      LoadingService.loading('save-button');

      this.onSave(firmParams)
        .finally(() => {
          this.closeModal();
          LoadingService.done('save-button');
        });
    },

    scoutTagColorClass(optionName) {
      const scoutTagColor = this.scoutTags.find(tag => tag.name === optionName).color;
      return ['scout-tag d-block fit-content wrap-word', scoutTagColor];
    },

    closeModal() {
      this.$refs.modal.dismissModal();
    }
  }
};
</script>
