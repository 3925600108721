<template>
  <section>
    <div class="row">
      <div class="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
        <div class="shadowed-box top-30">
          <div class="box-content extra-padded">
            <div class="size-text-20px bottom-20">
              Two-Factor Authentication
            </div>

            <div class="bottom-20">
              {{ instructions }}
            </div>

            <inline-notification v-if="notification" class="bottom-20" :type="notification.type">
              <div v-html="notification.message"></div>
            </inline-notification>

            <validation-observer v-slot="{ handleSubmit }">
              <form role="form" class="a-form" novalidate>
                <div class="bottom-30">
                  <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
                    <div class="flex-between">
                      <label class="bold-weight" for="otp">
                        Authentication Code

                        <div class="error-text top-5" v-if="errors.length">
                          {{ errors[0] }}
                        </div>
                      </label>
                    </div>

                    <div>
                      <input type="text" ref="otp" id="otp" name="otp" :class="['form-control', { 'has-error': errors.length }]" v-model="otpAttempt" autocomplete="off" placeholder="6-digit code" maxlength="6" autofocus>
                    </div>

                    <div v-if="invalidOtp" class="error-text top-5">
                      * Incorrect or invalid authentication code. You have {{ remainingAttempts }} remaining attempts. Please try again.
                    </div>
                  </validation-provider>
                </div>

                <div class="row">
                  <div class="col-md-7">
                    <button type="submit" class="nv-button-blue smaller" @click.prevent="handleSubmit(signIn)">Sign In</button>
                  </div>

                  <div class="col-md-5">
                    <div v-if="strategy === 'email'">
                      <a href="" @click.prevent="sendOneTimePassword(true)" :disabled="shouldWait">Resend Code</a>
                    </div>

                    <div v-if="shouldWait" class="tight-lines dark-gray-text size-text-12px">
                      If you have not received an authentication code, you will be able to request a new one in <span class="semibold-weight">{{ countdown }}</span> seconds
                    </div>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'OtpAttempt',

  components: {
    ValidationObserver,
    ValidationProvider,
    InlineNotification
  },

  mixins: [
    interactionModes
  ],

  props: {
    authenticatable: {
      type: Object,
      required: true
    },

    onSendOneTimePassword: {
      type: Function,
      required: true
    },

    signInWithTwoFactor: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      otpAttempt: '',
      otpReSent: false,
      invalidOtp: false,
      countdownStart: 60,
      countdown: 60,
      shouldWait: false
    };
  },

  computed: {
    strategy() {
      return this.authenticatable.otpStrategy;
    },

    instructions() {
      if (this.strategy === 'email') {
        return `Please enter the authentication code that has been sent to your email: ${this.authenticatable.email}`;
      }
      else {
        return 'Please enter the authentication code generated by your authenticator app';
      }
    },

    waitTime() {
      return Math.floor(Date.now() / 1000) - this.authenticatable.lastOtpSentAt;
    },

    remainingAttempts() {
      return this.authenticatable.otpAttemptsRemaining;
    },

    notification() {
      if (this.otpReSent) {
        return {
          type: 'success',
          message: 'Authentication code has been re-sent'
        };
      }
      else if (this.invalidOtp || this.remainingAttempts < 5) {
        return {
          type: 'warning',
          message: `For security reasons, you will be allowed <b>${this.remainingAttempts}</b> more sign-in attempts before your account is locked for 24 hours. If you require assistance, please contact admin@priorilegal.com`
        };
      }

      return undefined;
    }
  },

  mounted() {
    if (this.waitTime < 60) {
      this.shouldWait = true;
      this.startCountdown(true);
    }
    else if (this.strategy === 'email') {
      this.sendOneTimePassword();
    }
  },

  methods: {
    onInvalidOtp() {
      this.otpReSent = false;
      this.invalidOtp = true;
    },

    sendOneTimePassword(resending) {
      if (!this.shouldWait) {
        this.shouldWait = true;
        this.onSendOneTimePassword()
        .then(
          () => {
            this.otpReSent = resending;
            this.startCountdown();
          }
        )
        .catch(
          (response) => {
            this.authenticatable.lastOtpSentAt = response.data.lastOtpSentAt;
            this.startCountdown(true);
            this.otpReSent = false;
          }
        );
      }
    },

    startCountdown(waiting) {
      if (waiting) {
        this.countdown = 60 - this.waitTime;
      }

      let interval = setInterval(() => {
        this.countdown--;

        if (this.countdown < 1) {
          this.countdown = this.countdownStart;
          this.shouldWait = false;

          clearInterval(interval);
        }
      }, 1000);
    },

    signIn() {
      this.signInWithTwoFactor(this.otpAttempt, this.strategy, () => {}, this.onInvalidOtp);
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .nv-button-blue.smaller {
    width: unset;
    padding: 0 20px;
  }
</style>
