import axios from 'axios';
import BaseResource from 'resources/base-resource';
import { defaults, isNil, pick, isBoolean } from 'lodash';

const attributeDefaults = {
  id: null,
  resourceId: null,
  resourceType: null,
  scoutMatterId: null,
  createdAt: null,
  publishedAt: null,
  lawFirmLocationId: null,
  // publish is used to determine whether it should be published or not
  // on the backend. Defaults to false, but overwritten in attributes() to
  // match needed data
  publish: false,

  experience: '',

  risk: null,
  complexity: null,
  recommendation: null,

  responses: [],
  booleanResponses: [],
  selectQuestionResponses: [],
  practiceAreaNames: []
};

class ScoutReview extends BaseResource {
  static baseUrl = '/scout/reviews/:id.json';

  static getDrafts(params) {
    return axios.get('/scout/reviews/drafts.json', { params: params }).then((resp) => resp.data);
  }

  constructor(data, template) {
    data = data || {};

    defaults(data, attributeDefaults);
    super(data);

    if (this.createdAt) { this.createdAt = new Date(this.createdAt); }
    if (this.recommendation) { this.rating = { percent: ((10 - this.recommendation) * 10.0) }; }

    if (template) {
      template.reviewQuestions().forEach((question) => {
        this.initializeResponse(question.title);
      });

      template.booleanQuestions().forEach((question) => {
        this.initializeBooleanResponse(question.title);
      });

      template.selectQuestions().forEach((question) => {
        this.initializeSelectQuestionResponse(question.title);
      });
    }
  }

  initializeBooleanResponse(fieldName) {
    const resp = this.booleanResponses.find(response => response.field === fieldName);

    if (resp) {
      resp.value = isBoolean(resp.value) ? resp.value : null;
    }
    else {
      this.booleanResponses.push({ field: fieldName, value: null });
    }
  }

  initializeResponse(fieldName) {
    const resp = this.responses.find(response => response.field === fieldName);

    if (resp) {
      resp.value = resp.value || 0;
    }
    else {
      this.responses.push({ field: fieldName, value: 0 });
    }
  }

  initializeSelectQuestionResponse(fieldName) {
    const resp = this.selectQuestionResponses.find(response => response.field === fieldName);

    if (resp) {
      resp.value = resp.value || null;
    }
    else {
      this.selectQuestionResponses.push({ field: fieldName, value: null });
    }
  }

  submissionData() {
    return pick(this, [
      'resourceId',
      'resourceType',
      'scoutMatterId',
      'experience',

      'risk',
      'complexity',
      'recommendation',

      'lawFirmLocationId',
      'practiceAreaNames',
      'responses',
      'booleanResponses',
      'selectQuestionResponses'
    ]);
  }

  save(opts) {
    if (opts && !isNil(opts.publish)) { this.publish = opts.publish; }

    return super.save();
  }

  isPublished() {
    return !isNil(this.publishedAt);
  }

  attributes() {
    return {
      id: this.id,
      published: this.publish,
      scout_review: this.submissionData()
    };
  }

  reviewsTypeOf(type) {
    return this.resourceType === type;
  }
}

export default ScoutReview;
