<template>
  <doughnut-chart-with-legend :chart-data="chartData" :legend-data="legendData" :doughnut-width="'200px'" :doughnut-height="'200px'">
    <div class="count-container">
      <div class="vertical-spacer">
        <div class="count-sum">
          {{ totalMatters }}
        </div>

        <div class="count-desc">
          Total Matters
        </div>
      </div>
    </div>
  </doughnut-chart-with-legend>
</template>

<script>
import DoughnutChartWithLegend from 'src/vue-app/shared/components/doughnut-chart-with-legend.vue';
import { map, orderBy, some } from 'lodash';

export default {
  name: 'MatterDistribution',

  components: { DoughnutChartWithLegend },

  props: {
    datapoints: {
      type:     Array,
      required: true
    }
  },

  computed: {
    orderedDatapoints() {
      return orderBy(this.datapoints, this.anyMatterCount ? 'matterCount' : 'dollarsSpent', 'desc');
    },

    anyMatterCount() {
      return some(this.datapoints, 'matterCount');
    },

    chartData() {
      if (this.anyMatterCount) {
        return map(this.orderedDatapoints, (datapoint) => {
          return {
            name: datapoint.name,
            color: datapoint.color,
            value: datapoint.matterCount
          };
        });
      }

      return this.emptyChartData;
    },

    emptyChartData() {
      return [{ name: 'No Practice Area', color: '#eee', value: 1 }];
    },

    legendData() {
      if (this.datapoints.length) {
        return map(this.orderedDatapoints, (datapoint) => {
          return {
            name: datapoint.name,
            color: datapoint.matterCount > 0 ? datapoint.color : '#eee',
            value: datapoint.matterCount
          };
        });
      }

      return this.emptyLegendData;
    },

    emptyLegendData() {
      return [{ name: 'No Practice Area', color: '#eee', value: 0 }];
    },

    totalMatters() {
      return this.datapoints.reduce((total, datapoint) => (total + datapoint.matterCount), 0);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .count-container {
    position: absolute;
    top: 96px;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 768px) {
      left: 100px;
    }

    .count-sum {
      font-size: 30px;
      line-height: 45px;
    }

    .count-desc {
      color: $k-dark-gray;
      font-size: 12px;
    }
  }
</style>
