<template>
  <div class="shadowed-box">
    <div class="resource-list static-list">
      <div class="list-header">
        <div class="row vertical-center-not-xs">
          <div class="col-sm-4">
            <dynamic-order-arrows
              class="vertical-center"
              :order="order"
              order-by="practiceAreaName"
              :initial-order="initialSortOrder('practiceAreaName')"
              :on-sort="sort">
              <div class="size-text-13px bold-weight">
                Timeliness By Practice Area
              </div>
            </dynamic-order-arrows>
          </div>

          <div class="col-sm-4 top-10-xs">
            <dynamic-order-arrows
              class="vertical-center center-justified-not-xs"
              :order="order"
              order-by="threeMonthPercentage"
              :initial-order="initialSortOrder('threeMonthPercentage')"
              :on-sort="sort">
              <div class="vertical-center">
                <svg-icon id="three-month-tooltip" name="info" class="base-icon smaller right-5"></svg-icon>
                <tooltip
                  target="#three-month-tooltip"
                  placement="bottom"
                  custom-class="tooltip-white"
                  text="The data in this column reflects the previous 3-month period, not including the current month.">
                </tooltip>
                <div class="size-text-13px bold-weight">
                  3 Months
                </div>
              </div>
            </dynamic-order-arrows>
          </div>

          <div class="col-sm-4 top-10-xs">
            <dynamic-order-arrows
              class="vertical-center center-justified-not-xs"
              :order="order"
              order-by="twelveMonthPercentage"
              :initial-order="initialSortOrder('twelveMonthPercentage')"
              :on-sort="sort">
              <div class="vertical-center">
                <svg-icon id="twelve-month-tooltip" name="info" class="base-icon smaller right-5"></svg-icon>
                <tooltip
                  target="#twelve-month-tooltip"
                  placement="bottom"
                  custom-class="tooltip-white"
                  text="The data in this column reflects the previous 12-month period, not including the current month.">
                </tooltip>
                <div class="size-text-13px bold-weight">
                  12 Months
                </div>
              </div>
            </dynamic-order-arrows>
          </div>
        </div>
      </div>

      <div class="list-content-wrapper">
        <div class="list-item-with-col-borders" v-for="breakdown in timelinessBreakdownCopy">
          <div class="row vertical-center-not-xs">
            <div class="col-sm-4">
              <div class="content-container">
                <div class="dot" :style="{ backgroundColor: breakdown.color }"></div>
                <span class="semibold-weight">{{ breakdown.practiceAreaName }}</span>
              </div>
            </div>

            <div class="col-sm-4 responsive-text-align top-5-xs">
              <div class="content-container">
                {{ percentageDisplay(breakdown.threeMonthTotal, breakdown.threeMonthPercentage) }}<span class="size-text-11px pill-gray-text left-5">({{ $pluralize('invoice', breakdown.threeMonthTotal, true) }})</span>
              </div>
            </div>

            <div class="col-sm-4 responsive-text-align top-5-xs">
              <div class="content-container">
                {{ percentageDisplay(breakdown.twelveMonthTotal, breakdown.twelveMonthPercentage) }}<span class="size-text-11px pill-gray-text left-5">({{ $pluralize('invoice', breakdown.twelveMonthTotal, true) }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list-item no-hover totals-row">
        <div class="row vertical-center-not-xs bold-weight">
          <div class="col-sm-4 size-text-14px">
            All Practice Areas
          </div>

          <div class="col-sm-4 responsive-text-align top-5-xs">
            {{ percentageDisplay(breakdownTotals.threeMonthTotal, breakdownTotals.threeMonthPercentage, true) }}<span class="size-text-11px normal-weight left-5">({{ $pluralize('invoice', breakdownTotals.threeMonthTotal, true) }})</span>
          </div>

          <div class="col-sm-4 responsive-text-align top-5-xs">
            {{ percentageDisplay(breakdownTotals.twelveMonthTotal, breakdownTotals.twelveMonthPercentage, true) }}<span class="size-text-11px normal-weight left-5">({{ $pluralize('invoice', breakdownTotals.twelveMonthTotal, true) }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import DynamicOrderArrows from 'vue-app/shared/components/dynamic-order-arrows.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'FirmInvoiceTimelinessBreakdown',

  components: {
    DynamicOrderArrows,
    SvgIcon
  },

  props: {
    formattedTimelinessBreakdown: {
      type:     Array,
      required: true
    }
  },

  data() {
    return {
      order: { 'practiceAreaName': 'asc' },
      timelinessBreakdownCopy: cloneDeep(this.formattedTimelinessBreakdown)
    };
  },

  computed: {
    breakdownTotals() {
      const totals = this.timelinessBreakdownCopy.reduce((acc, breakdown) => {
        acc.threeMonthOnTime += breakdown.threeMonthOnTime;
        acc.threeMonthTotal += breakdown.threeMonthTotal;
        acc.twelveMonthOnTime += breakdown.twelveMonthOnTime;
        acc.twelveMonthTotal += breakdown.twelveMonthTotal;

        return acc;
      }, { threeMonthOnTime: 0, threeMonthTotal: 0, twelveMonthOnTime: 0, twelveMonthTotal: 0 });

      return {
        ...totals,
        threeMonthPercentage: this.calculatePercentage(totals.threeMonthOnTime, totals.threeMonthTotal),
        twelveMonthPercentage: this.calculatePercentage(totals.twelveMonthOnTime, totals.twelveMonthTotal)
      };
    }
  },

  methods: {
    calculatePercentage(onTime, total) {
      return total ? Math.round((onTime / total) * 100) : 0;
    },

    percentageDisplay(total, percentage, totalsRow = false) {
      if (total === 0) { return '—'; }

      return totalsRow ? `${percentage}% timely` : `${percentage}%`;
    },

    sort(sortBy) {
      let sortOrder;

      if (this.order[sortBy]) {
        sortOrder = this.order[sortBy] === 'asc' ? 'desc' : 'asc';
      }
      else {
        sortOrder = this.initialSortOrder(sortBy);
      }

      this.order = { [sortBy]: sortOrder };
      this.onSort({ sortBy: sortBy, sortDirection: sortOrder });
    },

    onSort(sortInfo) {
      this.timelinessBreakdownCopy = this.timelinessBreakdownCopy.sort((a, b) => {
        // Handle string sorting
        if (typeof a[sortInfo.sortBy] === 'string') {
          return sortInfo.sortDirection === 'asc' ? a[sortInfo.sortBy].localeCompare(b[sortInfo.sortBy]) : b[sortInfo.sortBy].localeCompare(a[sortInfo.sortBy]);
        }

        // Primary sort by the percentage
        if (sortInfo.sortBy === 'threeMonthPercentage' || sortInfo.sortBy === 'twelveMonthPercentage') {
          if (a[sortInfo.sortBy] !== b[sortInfo.sortBy]) {
            return sortInfo.sortDirection === 'asc' ? a[sortInfo.sortBy] - b[sortInfo.sortBy] : b[sortInfo.sortBy] - a[sortInfo.sortBy];
          }
          // Secondary sort by total if percentages are equal
          let secondarySortKey = sortInfo.sortBy.includes('threeMonth') ? 'threeMonthTotal' : 'twelveMonthTotal';
          return sortInfo.sortDirection === 'asc' ? a[secondarySortKey] - b[secondarySortKey] : b[secondarySortKey] - a[secondarySortKey];
        }
      });
    },

    initialSortOrder(sortBy) {
      return {
        'threeMonthPercentage': 'desc',
        'twelveMonthPercentage': 'desc',
        'practiceAreaName': 'asc'
      }[sortBy];
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
  }

  .list-content-wrapper {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .list-item-with-col-borders:last-child {
    border-bottom: 0;
  }

  .totals-row {
    border-top: 2px solid $k-dark-gray;
  }

  .responsive-text-align {
    text-align: left;

    @media (min-width: $screen-sm-min) {
      text-align: center;
    }
  }
</style>
