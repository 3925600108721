<template>
  <form role="form" name="form" class="a-form" novalidate>
    <div class="text-right bottom-15">
      <a href="" class="light-gray-link" @click.prevent="clearFilter">Clear&nbsp;Filter</a>
    </div>

    <div class="keyword-search-container">
      <input type="text" name="searchTerm" id="search-term" class="form-control keyword-search-input" placeholder="Search..." autocomplete="off" v-model="searchTerm" :disabled="disabled">

      <a href="" class="keyword-search-icon clear-icon" @click.prevent.stop="clearSearchTerm" v-if="searchTerm"><svg-icon name="x3" class="base-icon"></svg-icon></a>

      <svg-icon name="search" class="base-icon keyword-search-icon" v-else></svg-icon>

      <div v-if="disabled" class="missing-text top-10">
        * Filter not available
      </div>

      <div v-if="noMatches" class="error-text top-10">
        * No matching options
      </div>
    </div>

    <div :class="{ 'top-15': (!disabled && !noMatches), 'fade-scroll-container': isScrollable, 'scrolled-up': isScrolledUp, 'scrolled-down': isScrolledDown }" v-show="!disabled && !noMatches">
      <div ref="scrollable" @scroll="checkScroll" class="fade-scrollable">
        <div v-if="searchTerm">
          <label class="check-option vertical medium-large" v-for="option in filteredBySearchTermOptions" :key="option.id">
            <span class="highlighted" v-html="highlight(option[optionsLabelKey])"></span>

            <input type="checkbox" :name="`options${option.id}`" :id="`options-${option.id}`" v-model="selection" :value="option[optionsValueKey]" @change="applyFilter">
            <span class="check c-box"></span>
          </label>
        </div>

        <div v-else>
          <div v-if="selectedOptions.length">
            <label class="check-option vertical medium-large" v-for="option in selectedOptions" :key="`selected-${option.id}`">
              {{ option[optionsLabelKey] }}

              <input type="checkbox" :name="`selected${option.id}`" :id="`selected-${option.id}`" v-model="selection" :value="option[optionsValueKey]" @change="applyFilter">
              <span class="check c-box"></span>
            </label>
          </div>

          <div class="top-15" v-if="selectedOptions.length && availableOptions.length">
            <hr class="no-margin">
          </div>

          <div :class="{ 'top-15': selectedOptions.length }" v-if="availableOptions.length">
            <label class="check-option vertical medium-large" v-for="option in availableOptions" :key="`available-${option.id}`">
              {{ option[optionsLabelKey] }}

              <input type="checkbox" :name="`available${option.id}`" :id="`available-${option.id}`" v-model="selection" :value="option[optionsValueKey]" @change="applyFilter">
              <span class="check c-box"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import checkScroll from 'vue-app/shared/mixins/check-scroll.js';
import { filter, difference, unescape, zip } from 'lodash';

export default {
  name: 'FilterMultiselect',

  components: {
    SvgIcon
  },

  mixins: [checkScroll],

  props: {
    onApplyFilter: {
      type: Function,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    optionsLabelKey: {
      type: String,
      default: 'label'
    },

    optionsValueKey: {
      type: String,
      default: 'label'
    }
  },

  data() {
    return {
      selection: [],
      searchTerm: null
    };
  },

  computed: {
    disabled() {
      return !this.options.length;
    },

    filteredBySearchTermOptions() {
      return filter(this.options, option => option[this.optionsLabelKey].toLowerCase().includes(this.searchTerm.toLowerCase()));
    },

    noMatches() {
      return this.searchTerm && !this.filteredBySearchTermOptions.length;
    },

    selectedOptions() {
      return filter(this.options, option => this.selection.includes(option[this.optionsValueKey]));
    },

    availableOptions() {
      return difference(this.options, this.selectedOptions);
    }
  },

  methods: {
    highlight(text) {
      text = this.$sanitize(text, { textFilter: t => unescape(t) });

      const sectionsInbetween = text.split(new RegExp(this.searchTerm, 'i'));
      const highlights = text.match(new RegExp(this.searchTerm, 'gi')).map(highlight => `<strong>${highlight}</strong>`);
      const zipped = zip(sectionsInbetween, highlights);

      return zipped.flat().join('');
    },

    applyFilter() {
      this.onApplyFilter(this.selection);
    },

    clearSearchTerm() {
      this.searchTerm = null;
    },

    clearFilter() {
      this.searchTerm = null;

      if (!this.selection.length) { return; }

      this.selection = [];
      this.applyFilter();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .fade-scroll-container {
    .fade-scrollable {
      max-height: 170px;
    }
  }

  .highlighted {
    :deep(strong) {
      color: $k-blue;
    }
  }
</style>
