<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <form class="a-form" novalidate>
        <div class="size-text-18px bold-weight bottom-20">
          Connect Matters
        </div>

        <loading-section name="connectedMatters">
          <div v-if="!hasConnectedMatters" class="next-steps-content">
            <div class="row">
              <div class="col-sm-5">
                <div class="vertical-center-not-xs gap-15">
                  <div class="svg-circle">
                    <svg-icon name="star-dollar" class="base-icon medium stroke"></svg-icon>
                  </div>

                  <div class="top-20-xs">
                    <div class="bold-weight size-text-15px">
                      Search for Matter
                      <svg-icon id="search-for-matter-tooltip" name="info" class="base-icon smaller dark-gray-text tooltip-icon"></svg-icon>
                      <tooltip
                        target="#search-for-matter-tooltip"
                        placement="bottom"
                        custom-class="tooltip-white"
                        :text="toolTipLanguage">
                      </tooltip>
                    </div>

                    <div class="size-text-14px">
                      Connect e-billing Matter to track spend against RFP budget.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-7 top-20-xs">
                <div class="row tight-columns">
                  <div class="col-sm-8">
                    <typeahead-vertical
                      rules="required"
                      :id="'matter-input'"
                      ref="selectedMatterInput"
                      :options="[]"
                      option-label-key="displayName"
                      @input="setSelectedMatter"
                      :async-function="loadMatters"
                      additional-input-classes="keyword-with-prefix-symbol"
                      placeholder="Search by Matter Number"
                      :force-select="true">
                      <template #prefix-symbol>
                        <div class="vertical-center keyword-prefix-symbol dark-gray-text">#</div>
                      </template>
                    </typeahead-vertical>
                  </div>

                  <div class="col-sm-4 top-20-xs">
                    <button type="button" class="nv-button-blue" :disabled="!validMatter" @click="confirmConnectMatter">Connect</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="next-steps-content completed">
            <div class="row">
              <div class="col-sm-5">
                <div class="vertical-center-not-xs gap-15">
                  <div class="svg-circle">
                    <svg-icon name="star-dollar" class="base-icon medium stroke"></svg-icon>
                  </div>

                  <div class="top-20-xs">
                    <div class="bold-weight size-text-15px">
                      Connected Matters
                    </div>

                    <div class="size-text-14px">
                      Connect e-billing Matter to track spend against RFP budget.
                    </div>

                    <priori-modal ref="connectMatterModal" class="top-5" title="Add Additional Matter" @on-modal-close="resetMatterList">
                      <template #modal-trigger="{ openModal }">
                        <a href="" class="bold-weight" @click.prevent="openModal">+ Connect Additional Matter</a>
                      </template>

                      <form class="a-form" novalidate>
                        <validation-observer v-slot="{ handleSubmit }" ref="validationObserver">
                          <div class="bottom-30">
                            <label for="matter-input-modal">Search by Matter Number <span class="inline-help required">*</span></label>

                            <typeahead-vertical
                              id="matter-input-modal"
                              ref="selectedMatterModalInput"
                              option-label-key="displayName"
                              :options="[]"
                              :excluded-options="mattersToAdd"
                              @input="updateMattersToAdd"
                              additional-input-classes="keyword-with-prefix-symbol"
                              :async-function="loadMatters"
                              placeholder="Search by Matter Number"
                              :force-select="true">
                              <template #prefix-symbol>
                                <div class="vertical-center keyword-prefix-symbol dark-gray-text">#</div>
                              </template>
                            </typeahead-vertical>
                          </div>

                          <div class="bottom-30">
                            <div class="list-spacing-10 mattters-to-add" v-for="matter in mattersToAdd" :key="matter.id">
                              {{ matter.name }} &mdash; #{{ matter.code }}
                              <span class="left-10 clickable" @click="removeMatter(matter.id)"><svg-icon name="x-circle" class="base-icon delete-btn"></svg-icon></span>
                            </div>
                          </div>

                          <div class="row tight-columns">
                            <div class="col-sm-4">
                              <button type="button" class="nv-button-blue" :disabled="!hasSelectedMatter" @click="handleSubmit(addMatterList)">Add Matter</button>
                            </div>

                            <div class="col-sm-3 top-20-xs">
                              <button type="button" class="nv-button-white" @click="closeMatterModal">Cancel</button>
                            </div>
                          </div>
                        </validation-observer>
                      </form>
                    </priori-modal>
                  </div>
                </div>
              </div>

              <div class="col-sm-7 top-20-xs">
                <div class="connected-matter list-spacing-10" v-for="matter in connectedMatters" :key="matter.id">
                  <div class="row tight-columns vertical-center-not-xs">
                    <div class="col-sm-10">
                      <a :href="`/scout-company/matters/${matter.id}`" class="bold-weight size-text-15px right-10" target="_blank">{{ matter.name }}</a>
                      <span class="text-italic pill-gray-text">#{{ matter.code }}</span>
                    </div>

                    <div class="col-sm-2 text-right-not-xs">
                      <a href="" class="blue-text" @click.prevent="unlinkMatterWarning(matter.id)"><svg-icon name="trash" class="delete-btn base-icon"></svg-icon></a>
                    </div>
                  </div>
                </div>
              </div>

              <priori-modal ref="disconnectMatterWarning" modal-id="disconnectmatter" title="Disconnect Matter">
                <div class="size-text-14px">
                  Are you sure you want to disconnect this matter?
                </div>

                <div class="row tight-columns top-30">
                  <div class="col-sm-4">
                    <button type="button" class="nv-button-red" @click="unlinkMatter">Disconnect</button>
                  </div>

                  <div class="col-sm-3 top-20-xs">
                    <button type="button" class="nv-button-white" @click="closeDisconnectModal">Cancel</button>
                  </div>
                </div>
              </priori-modal>
            </div>
          </div>
        </loading-section>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { ValidationObserver } from 'vee-validate';
import ScoutMatter from 'resources/scout/scout-matter.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ConnectMatter',

  components: {
    ValidationObserver,
    LoadingSection,
    TypeaheadVertical,
    SvgIcon,
    PrioriModal
  },

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      connectedMatters: [],
      selectedMatter: null,
      mattersToAdd: [],
      matterIDToDisconnect: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    hasConnectedMatters() {
      return this.connectedMatters.length > 0;
    },

    validMatter() {
      return this.selectedMatter && !this.connectedMatters.some(matter => matter.id === this.selectedMatter.id);
    },

    hasSelectedMatter() {
      return this.mattersToAdd.length > 0;
    },

    isFeatureLab() {
      return this.currentUser.workspace?.isFeatureLab;
    },

    toolTipLanguage() {
      if (this.isFeatureLab) {
        return 'This implementation does not include an e-billing connection. If desired, please reach out to Priori to set this up.';
      }
      else {
        return 'Please note if your matter does not appear, it may not yet be synced with the platform.';
      }
    }
  },

  mounted() {
    LoadingService.loading('connectedMatters');

    ScoutMatter.connectedMattersLookup({ rfq_id: this.rfq.id, view: 'rfq_connect_matter' })
      .then((response) => {
        this.connectedMatters = response.data;
      })
      .finally(() => {
        LoadingService.done('connectedMatters');
      });
  },

  methods: {
    loadMatters(matterQuery) {
      if (matterQuery.length < 1) { return Promise.resolve([]); }

      return ScoutMatter.matterLookup({ code: matterQuery, view: 'rfq_connect_matter' })
        .then((response) => {
          return response.data.map(matter => ({
            ...matter,
            displayName: `${matter.name} - ${matter.code}`
          }));
        });
    },

    setSelectedMatter(event) {
      //we dont want this to trigger on reset
      if (event) {
        this.selectedMatter = event;
      }
    },

    updateMattersToAdd(event) {
      if (event.id != undefined && !this.mattersToAdd.some(matter => matter.id === event.id)) {
        this.mattersToAdd.push(event);
      }
      this.$refs.selectedMatterModalInput.reset();
    },

    addMatterList() {
      this.mattersToAdd.forEach(matter => {
        ScoutMatter.update({ id: matter.id, scout_rfq_id: this.rfq.id });
        this.connectedMatters.push({ id: matter.id, name: matter.name, code: matter.code });
      });
      this.mattersToAdd = [];
      this.$refs.selectedMatterModalInput.reset();

      if (this.$refs.connectMatterModal) { this.closeMatterModal(); }
    },

    confirmConnectMatter() {
      ScoutMatter.update({ id: this.selectedMatter.id, scout_rfq_id: this.rfq.id });
      this.connectedMatters.push({ id: this.selectedMatter.id, name: this.selectedMatter.name, code: this.selectedMatter.code });
      this.selectedMatter = null;
      this.$refs.selectedMatterInput.reset();
    },

    // unlink and put the matter back into searchable matters as well as remove from connected matters list
    unlinkMatter() {
      ScoutMatter.update({ id: this.matterIDToDisconnect, scout_rfq_id: null });

      this.connectedMatters = this.connectedMatters.filter(matter => matter.id !== this.matterIDToDisconnect);
      this.matterIDToDisconnect = null;
      this.$refs.disconnectMatterWarning.closeModal();
    },

    unlinkMatterWarning(matterId) {
      this.matterIDToDisconnect = matterId;
      this.$refs.disconnectMatterWarning.openModal();
    },

    removeMatter(id) {
      this.mattersToAdd = this.mattersToAdd.filter(matter => matter.id !== id);
    },

    closeDisconnectModal() {
      this.$refs.disconnectMatterWarning.closeModal();
    },

    closeMatterModal() {
      this.$refs.connectMatterModal.closeModal();
      this.$refs.selectedMatterModalInput.reset();
      this.$refs.validationObserver.reset();
      this.resetMatterList();
    },

    resetMatterList() {
      this.mattersToAdd = [];
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/scout/variables" as *;

  .mattters-to-add {
    border: 1px solid $gray-8;
    width: fit-content;
    padding: 5px 10px;
    border-radius: $border-radius-small;

    svg {
      position: relative;
      top: -1px;
      width: 15px;
      height: 15px;
      fill: $gray-1;
      opacity: 30%;
    }
  }

  .tooltip-icon {
    position: relative;
    top: -1px;
  }

  .connected-matter {
    padding: 15px 20px;
    border: 1px solid $gray-6;
    border-radius: $border-radius-large;
    background: $bg-color;
    position: relative;
  }
</style>
