<template>
  <priori-modal ref="addLawyerModal" modal-id="add-lawyer-modal" :title="addLawyerModalTitle">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="pseudo-link-blue bold-weight" @click="openModal">+ Add Lawyer</button>
    </template>

    <loading-section name="addLawyer">
      <div class="a-form bottom-30">
        <typeahead-vertical
          ref="lawyersToAddTypeahead"
          :label="addLawyerModalTitle"
          id="add-lawyer-selection"
          :options="searchableLawyersAtFirm"
          option-label-key="fullName"
          placeholder="Search by name"
          @input="addLawyer">
        </typeahead-vertical>

        <div class="top-10" v-if="hasLawyersToAdd">
          <tag-list
            :tags="lawyersToAdd"
            :on-remove="removeLawyer"
            :value-fn="getLawyerBridgeName"
            tag-class="clear">
          </tag-list>
        </div>
      </div>

      <div class="row tight-columns">
        <div class="col-sm-5">
          <button type="button" :class="['primary-btn-night-blue', { 'disabled': !hasLawyersToAdd }]" @click="proceedToAddLawyer">Add Lawyer</button>
        </div>

        <div class="col-sm-4 top-20-xs">
          <button type="button" class="secondary-btn-blue" @click="cancelAddLawyer">Cancel</button>
        </div>
      </div>
    </loading-section>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import TagList from 'vue-app/scout/shared/tag-list.vue';

export default {
  name: 'RfqAddLawyer',

  components: {
    PrioriModal,
    TypeaheadVertical,
    LoadingSection,
    TagList
  },

  props: {
    rfqLawFirmQuote: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      lawyersToAdd: [],
      availableLawyers: []
    };
  },

  computed: {
    addLawyerModalTitle() {
      return `Add Lawyer from ${this.rfqLawFirmQuote.firmName}`;
    },

    searchableLawyersAtFirm() {
      const suggestedLawyerIds = new Set(this.rfqLawFirmQuote.suggestedLawyers.map(item => item.scoutLawyerBridge.id));
      const pendingLawyerIds = new Set(this.lawyersToAdd.map(item => item.scoutLawyerBridge.id));

      const remainingLawyers = this.availableLawyers.filter(item => {
        return !suggestedLawyerIds.has(item.id) && !pendingLawyerIds.has(item.id);
      });

      return remainingLawyers;
    },

    hasLawyersToAdd() {
      return this.lawyersToAdd.length > 0;
    }
  },

  mounted() {
    this.loadAvailableLawyers();
  },

  methods: {
    getLawyerBridgeName(lawyer) {
      return lawyer.scoutLawyerBridge.fullName;
    },

    addLawyer(lawyer) {
      if (!lawyer) { return; }

      this.lawyersToAdd.push({
        scoutLawyerBridge: lawyer
      });
      this.$refs.lawyersToAddTypeahead.reset();
    },

    removeLawyer(index) {
      this.lawyersToAdd.splice(index, 1);
    },

    proceedToAddLawyer() {
      this.rfqLawFirmQuote.suggestedLawyers.push(...this.lawyersToAdd);
      this.lawyersToAdd = [];
      this.$refs.addLawyerModal.closeModal();
    },

    cancelAddLawyer() {
      this.lawyersToAdd = [];
      this.$refs.addLawyerModal.closeModal();
    },

    loadAvailableLawyers() {
      LoadingService.loading('addLawyers');
      ScoutLawyerBridge.rfqAvailableLawyers({ law_firm_id: this.rfqLawFirmQuote.lawFirmId })
        .then(response => {
          this.availableLawyers = response.data;
        })
        .finally(() => {
          LoadingService.done('addLawyers');
        });
    }
  }
};
</script>
