import axios from 'axios';
import BaseResource from 'resources/base-resource';

class ScoutTag extends BaseResource {
  static baseUrl = '/scout/tags/:id.json';

  static order(data) {
    return axios.post('/scout/tags/order.json', data).then(response => response.data);
  }
}

export default ScoutTag;
