import { render, staticRenderFns } from "./draft-review-list-item.vue?vue&type=template&id=50c7f835&scoped=true"
import script from "./draft-review-list-item.vue?vue&type=script&lang=js"
export * from "./draft-review-list-item.vue?vue&type=script&lang=js"
import style0 from "./draft-review-list-item.vue?vue&type=style&index=0&id=50c7f835&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c7f835",
  null
  
)

export default component.exports