<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-xs-7 text-uppercase">
          {{ title }} ({{ list.length }})
        </div>

        <div class="col-xs-5 size-text-13px normal-weight text-right">
          <a href="" v-if="showEditOrder" @click.prevent="toggleOrder">Edit Order</a>
        </div>
      </div>
    </div>

    <div class="box-content size-text-13px">
      <div v-if="listHasItems">
        <div v-if="!isEditing">
          <searches-list-item
            v-for="savedSearch in list"
            class="saved-search-item"
            :saved-search="savedSearch"
            :key="`saved-search-${savedSearch.id}`"
            :on-removed="onRemoved"
            :on-saved="onSaved">
          </searches-list-item>
        </div>

        <div v-else>
          <searches-list-order
            :list="list"
            :on-order-updated="onOrderUpdated"
            :on-close="toggleOrder">
          </searches-list-order>
        </div>
      </div>

      <div class="size-text-base gray-text text-center" v-else>
        No Saved Searches
      </div>
    </div>
  </div>
</template>

<script>

import SearchesListItem from './searches-list-item.vue';
import SearchesListOrder from './searches-list-order.vue';

export default {
  name: 'SearchesList',

  components: {
    SearchesListItem,
    SearchesListOrder
  },

  props: {
    title: {
      type: String,
      required: true
    },

    list: {
      type: Array,
      default: () => []
    },

    onRemoved: {
      type: Function,
      required: true
    },

    onSaved: {
      type: Function,
      required: true
    },

    onOrderUpdated: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false
    };
  },

  computed: {
    listHasItems() {
      return !!this.list.length;
    },

    showEditOrder() {
      return this.listHasItems && !this.isEditing;
    }
  },

  methods: {
    toggleOrder() {
      this.isEditing = !this.isEditing;
    }
  }
};
</script>
