<template>
  <div class="review-list-filters">
    <filter-button
      label="Review Date"
      class="bottom-10"
      :active="isActive('review_date')">
      <filter-date-range
        :on-apply-filter="filterByReviewDate"
        :year-range="lawyerReviewsYearRange">
      </filter-date-range>
    </filter-button>
  </div>
</template>

<script>
import { range, isEmpty } from 'lodash';

export default {
  name: 'LawyerGeneralReviewListFilters',

  props: {
    filtersData: {
      type: Object,
      required: true
    },

    onApplyFilters: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      filterParams: { review_date: {} }
    };
  },

  computed: {
    lawyerReviewsYearRange() {
      let lawyerReviewStartYear = 1999;

      if (this.filtersData.earliestReviewYear?.lawyerReviewYear) {
        lawyerReviewStartYear = this.filtersData.earliestReviewYear.lawyerReviewYear - 1;
      }

      return range(new Date().getFullYear(), lawyerReviewStartYear, -1);
    }
  },

  methods: {
    filterByReviewDate(dateRange) {
      this.applyFilters('review_date', dateRange);
    },

    applyFilters(filter, filterParams) {
      Object.assign(this.filterParams, { [filter]: filterParams });

      this.onApplyFilters('general', this.filterParams);
    },

    isActive(filter) {
      return !isEmpty(this.filterParams[filter]);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .review-list-filters {
    border-bottom: 1px solid $k-gray;
    padding: 25px 25px 15px;
  }
</style>
