<template>
  <div class="row">
    <div class="col-sm-4 bold-weight">
      {{ label }}
    </div>

    <div class="col-sm-8">
      <div>
        {{ value || '&mdash;' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoItem',

  props: {
    label: {
      type: String,
      required: true
    },

    value: {
      type: String,
      default: null
    }
  }
};
</script>
