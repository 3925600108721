<template>
  <div class="review-list-filters">
    <div class="row tight-columns">
      <div class="col-sm-4">
        <search-input
          placeholder="Search by Matter Number"
          class="right-5 bottom-10"
          :on-search="filterByMatterNumber"
          :on-reset="filterByMatterNumber">
        </search-input>
      </div>

      <div class="col-sm-8">
        <filter-button
          label="Practice Area"
          class="right-5 bottom-10"
          :active="isActive('practice_areas')">
          <filter-multiselect
            :on-apply-filter="filterByPracticeArea"
            :options="practiceAreaOptions"
            options-label-key="name"
            options-value-key="name">
          </filter-multiselect>
        </filter-button>

        <filter-button
          label="Status"
          class="right-5 bottom-10"
          :active="isActive('statuses')">
          <filter-multiselect
            :on-apply-filter="filterByStatus"
            :options="statusOptions"
            options-label-key="label"
            options-value-key="id">
          </filter-multiselect>
        </filter-button>

        <filter-button
          label="Matter Manager"
          class="right-5 bottom-10"
          :active="isActive('matter_manager_ids')">
          <filter-multiselect
            :on-apply-filter="filterByMatterManagers"
            :options="matterManagerOptions"
            options-label-key="displayName"
            options-value-key="participationIds">
          </filter-multiselect>
        </filter-button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from 'vue-app/shared/components/search-input.vue';
import FilterButton from 'vue-app/shared/components/filter-button.vue';
import FilterMultiselect from 'vue-app/shared/components/filter-popovers/filter-multiselect.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'LawyerMatterReviewListFilters',

  components: {
    SearchInput,
    FilterButton,
    FilterMultiselect
  },

  props: {
    practiceAreaOptions: {
      type:     Array,
      required: true
    },

    onApplyFilters: {
      type:     Function,
      required: true
    },

    matterManagerOptions: {
      type:     Array,
      required: true
    }
  },

  data() {
    return {
      filterParams: {
        code: '',
        statuses: [],
        practice_areas: [],
        matter_manager_ids: []
      },

      statusOptions: [
        { id: 'open', label: 'Open' },
        { id: 'closed', label: 'Closed' }
      ]
    };
  },

  methods: {
    filterByMatterNumber(matterNumber) {
      this.applyFilters('code', matterNumber);
    },

    filterByPracticeArea(practiceAreas) {
      this.applyFilters('practice_areas', practiceAreas);
    },

    filterByStatus(statuses) {
      this.applyFilters('statuses', statuses);
    },

    filterByMatterManagers(managerIds) {
      this.applyFilters('matter_manager_ids', managerIds);
    },

    applyFilters(filter, filterParams) {
      Object.assign(this.filterParams, { [filter]: filterParams });

      this.onApplyFilters('matters', this.filterParams);
    },

    isActive(filter) {
      return !isEmpty(this.filterParams[filter]);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .review-list-filters {
    border-bottom: 1px solid $k-gray;
    padding: 25px 25px 15px;
  }
</style>
