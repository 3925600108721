<template>
  <priori-modal ref="modal" :title="modalContent.headerText">
    <template #modal-trigger="{ openModal}">
      <a href="" @click.prevent="openModal"><svg-icon name="archive" class="base-icon"></svg-icon>{{ isClosed ? 'Open' : 'Close' }} Project</a>
    </template>

    <template #default="{ closeModal }">
      <div class="size-text-14px bottom-30">
        {{ modalContent.primaryMsg }}
      </div>

      <div v-if="modalContent.secondaryMsg" class="semibold-weight bottom-30">
        {{ modalContent.secondaryMsg }}
      </div>

      <div class="row tight-columns">
        <div class="col-sm-5" v-if="modalContent.yesBtnText">
          <button type="button" class="nv-button-red a-button-size" @click="confirm">{{ modalContent.yesBtnText }}</button>
        </div>

        <div :class="['col-sm-4', { 'col-sm-offset-4': !modalContent.yesBtnText, 'top-20-xs': modalContent.yesBtnText }]">
          <button type="button" class="nv-button-white a-button-size" @click="closeModal">{{ modalContent.noBtnText }}</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProjectStatusModal',

  components: {
    PrioriModal,
    SvgIcon
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    retainer: {
      type: Object,
      required: false
    },

    toggleCloseProject: {
      type: Function,
      required: true
    }
  },

  computed: {
    isClosed() {
      return this.matter.status === 'closed';
    },

    isOpen() {
      return this.matter.status === 'open';
    },

    hasOutstandingRetainers() {
      return this.retainer ? this.retainer.unsent > 0 : this.matter.outstandingRetainers;
    },

    modalContent() {
      if (this.isOpen) {
        if (this.hasOutstandingRetainers) {
          return {
            headerText: 'Invoice Includes Retainer',
            primaryMsg: 'This project cannot be closed because the outstanding unsent line items contain a retainer. Please remove any retainers from your unsent line items before closing the project.',
            secondaryMsg: null,
            yesBtnText: null,
            noBtnText: 'OK'
          };
        }
        else {
          return {
            headerText: 'Confirm',
            primaryMsg: 'Are you sure you\'ve completed all work and billing for this project and are ready to close it?',
            secondaryMsg: 'Please note that all unsent line items will be added to an invoice and sent to the client during the next pay period. If you would not like to send an invoice with the outstanding line items, please delete them before closing the project.',
            yesBtnText: 'Yes, proceed',
            noBtnText: 'Exit'
          };
        }
      }
      else {
        return {
          headerText: 'Reopen Project',
          primaryMsg: 'Are you sure you want to reopen this project?',
          secondaryMsg: null,
          yesBtnText: 'Yes, reopen',
          noBtnText: 'Exit'
        };
      }
    }
  },

  methods: {
    confirm() {
      this.toggleCloseProject();
      this.$refs.modal.closeModal();
    }
  }
};
</script>
