<template>
  <component :is="svgComponentName"></component>
</template>

<script>
import SvgPriorityHigh from './svg-priority-high.vue';
import SvgPriorityMedium from './svg-priority-medium.vue';
import SvgPriorityLow from './svg-priority-low.vue';
import PriorityDormant from './priority-dormant.vue';

export default {
  name: 'PriorityIcon',

  components: {
    SvgPriorityHigh,
    SvgPriorityMedium,
    SvgPriorityLow,
    PriorityDormant
  },

  props: {
    priority: {
      type: String,
      required: true,
      validator: (value) => { return ['high', 'medium', 'low', 'dormant'].includes(value); }
    }
  },

  computed: {
    svgComponentName() {
      if (this.priority === 'dormant') {
        return 'priority-dormant';
      }
      else {
        return `svg-priority-${this.priority}`;
      }
    }
  }
};
</script>
