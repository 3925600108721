import LawyerLeadSearch from 'resources/admin/lawyer-lead-search';
import { includes, without } from 'lodash';

class LawyerLeadSearchService {
  constructor() {
    this.initSearch();
  }

  initSearch() {
    this.page = 1;
    this.dirty = false;
    this.clear = true;

    this.lawyerLeadSearch = new LawyerLeadSearch({ id: 0, counselRequestId: null });
    this.totalCount = 0;
    this.searchLogic = {
      keywords: [],
      keywordLogic: 'AND',
      filters: {}
    };
  }

  applySearch(onAppliedSearchFn) {
    const service = this;

    service.page = 1;
    service.setPristine();

    if (service.isAllClear()) {
      service.lawyerLeadSearch.results = [];
      service.totalCount = 0;
      onAppliedSearchFn(service.lawyerLeadSearch.results);
    }
    else {
      LawyerLeadSearch.get({
        id: service.lawyerLeadSearch.id,
        search_params: JSON.stringify(service.searchLogic),
        counsel_request_id: service.lawyerLeadSearch.counselRequestId,
        current_page: 1,
        refresh_total: true
      })
      .then((lawyerLeadSearch) => {
        lawyerLeadSearch.id = lawyerLeadSearch.id || 0;

        service.lawyerLeadSearch = lawyerLeadSearch;
        service.totalCount = lawyerLeadSearch.totalCount;

        onAppliedSearchFn(lawyerLeadSearch.results);
      });
    }
  }

  changePage(page) {
    this.page = page;

    return LawyerLeadSearch.get({
      id: this.lawyerLeadSearch.id,
      search_params: JSON.stringify(this.lawyerLeadSearch.searchParams),
      counsel_request_id: this.lawyerLeadSearch.counselRequestId,
      current_page: this.page
    });
  }

  saveSearch() {
    LawyerLeadSearch.update({
      id: this.lawyerLeadSearch.id,
      searchParams: JSON.stringify(this.lawyerLeadSearch.searchParams),
      counselRequestId: this.lawyerLeadSearch.counselRequestId
    });
  }

  loadSearch(lawyerLeadSearch) {
    this.searchLogic = lawyerLeadSearch.searchParams;
  }

  setCounselRequest(counselRequestId) {
    this.lawyerLeadSearch.counselRequestId = counselRequestId;
  }

  isAllClear() {
    return this.isClear() && !this.hasKeywords();
  }

  keywords() {
    return this.searchLogic.keywords;
  }

  keywordLogic() {
    return this.searchLogic.keywordLogic;
  }

  hasKeywords() {
    return !!this.keywords().length;
  }

  keywordPresent(keyword) {
    return includes(this.keywords(), keyword);
  }

  canAddKeyword(keyword) {
    return keyword && keyword.length && !this.keywordPresent(keyword);
  }

  addKeyword(keyword) {
    if (this.canAddKeyword(keyword)) {
      this.keywords().push(keyword);
    }
  }

  canRemoveKeyword(keyword) {
    return this.keywordPresent(keyword);
  }

  removeKeyword(keyword) {
    if (this.canRemoveKeyword(keyword)) {
      this.searchLogic.keywords = without(this.keywords(), keyword);
    }
  }

  setKeywordLogic(keywordLogic) {
    this.searchLogic.keywordLogic = keywordLogic;
  }

  clearKeywords() {
    this.searchLogic.keywords = [];
  }

  filters() {
    return this.searchLogic.filters;
  }

  setPristine() {
    this.dirty = false;
  }

  isClear() {
    return this.clear;
  }

  resetFilters() {
    this.searchLogic.filters = {};
    this.setPristine();
    this.clear = false;
  }
}

export default LawyerLeadSearchService;
