<template>
  <div>
    <div class="titlebar with-switcher">
      <div class="row">
        <div class="col-sm-9 medium-page-heading">
          Reviews
        </div>

        <div class="col-sm-3 text-right">
          <review-form-guide-modal
            :load-firms="loadReviewableFirms"
            :load-firm-lawyers="loadReviewableLawyers"
            :leaving-review="leavingReview">
          </review-form-guide-modal>
        </div>
      </div>

      <div class="titlebar-switcher">
        <a @click.prevent="selectTab('lawFirms')" href="" :class="['right-20', { 'selected': isTabSelected('lawFirms') }]" data-testid="law-firms-tab">Law Firms</a>

        <a v-if="!isFeatureLab" @click.prevent="selectTab('lawyers')" href="" :class="['right-20', { 'selected': isTabSelected('lawyers') }]" data-testid="lawyers-tab">Lawyers</a>

        <a v-if="!isFeatureLab" @click.prevent="selectTab('matters')" href="" :class="['right-20', { 'selected': isTabSelected('matters') }]" data-testid="matters-tab">Matters</a>

        <a @click.prevent="selectTab('drafts')" href="" :class="{ 'selected': isTabSelected('drafts') }" data-testid="drafts-tab">My Drafts</a>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-30" v-if="workspace.scoutReviewsNote">
            <interface-note
              :note-text="workspace.scoutReviewsNote">
            </interface-note>
          </div>

          <div v-show="isTabSelected('matters')">
            <loading-section name="reviewReminders">
              <review-reminders
                class="bottom-30"
                :review-reminders="reviewReminders"
                :load-matter-firms="loadReviewableMatterFirms"
                :load-matter-lawyers="loadReviewableMatterLawyers"
                :review-reminder-configuration="reviewReminderConfiguration"
                :create-irrelevant-review-reminder-matter="createIrrelevantReviewReminderMatter">
              </review-reminders>
            </loading-section>

            <div class="bottom-10">
              <matters-list-filters
                :show-filters="['matterNumber', 'firm', 'activityDate', 'practiceAreas', 'reviewed', 'matterManagers']"
                :date-filter-years-range="matterActivityYearRange"
                :internal-practice-areas="internalPracticeAreas"
                :on-apply-filter="filterMatters"
                :matter-managers="matterManagers">
              </matters-list-filters>
            </div>

            <loading-section name="paginatedMatters">
              <div class="bottom-20">
                <span class="semibold-weight">{{ mattersTotal }}</span>
                {{ $pluralize('result', mattersTotal) }}
              </div>

              <matters-list
                :matters="matters"
                :matter-info-tooltip-text="toolTipText"
                @on-load-lawyers="loadMatterLawyers">
              </matters-list>

              <div class="top-20" v-if="mattersTotal > 0">
                <priori-pagination
                  class="scout-pagination"
                  :current-page="pageMatters"
                  :total-entries="mattersTotal"
                  :per-page="10"
                  @change="changePageMatters">
                </priori-pagination>
              </div>
            </loading-section>
          </div>

          <div v-show="isTabSelected('lawyers')">
            <div class="bottom-10">
              <search-filter
                class="bottom-10 right-5"
                :search-service="lawyerSearchService"
                :filter-service="filterService"
                :filter-params="lawyerNameFilterParams"
                :on-search="applyLawyerSearch">
              </search-filter>

              <filter-review-date
                class="lawyer-review-date-filter bottom-10 right-5"
                :search-service="lawyerSearchService"
                :year-range="lawyerReviewsYearRange"
                :on-search="applyLawyerSearch">
              </filter-review-date>

              <search-filter
                class="bottom-10 right-5"
                :search-service="lawyerSearchService"
                :filter-service="filterService"
                :filter-params="lawyerReviewerNamesFilterParams"
                :on-search="applyLawyerSearch">
              </search-filter>
            </div>

            <loading-section name="paginatedLawyers">
              <div class="bottom-20">
                <span class="semibold-weight">{{ lawyersTotal }}</span>
                {{ $pluralize('result', lawyersTotal) }}
              </div>

              <lawyers-list
                :lawyers="lawyers"
                :review-template="lawyerReviewTemplate"
                :on-sort="setSortAndApplyLawyerSearch">
              </lawyers-list>

              <div class="top-20" v-if="lawyers.length">
                <priori-pagination
                  class="scout-pagination"
                  :current-page="pageLawyers"
                  :total-entries="lawyersTotal"
                  :per-page="10"
                  @change="changePageLawyers">
                </priori-pagination>
              </div>
            </loading-section>
          </div>

          <div v-show="isTabSelected('lawFirms')">
            <div class="bottom-10">
              <search-filter
                class="bottom-10 right-5"
                :search-service="firmSearchService"
                :filter-service="filterService"
                :filter-params="firmNameFilterParams"
                :on-search="applyFirmSearch">
              </search-filter>

              <filter-review-date
                class="firm-review-date-filter bottom-10 right-5"
                :search-service="firmSearchService"
                :year-range="firmReviewsYearRange"
                :on-search="applyFirmSearch">
              </filter-review-date>

              <search-filter
                class="bottom-10 right-5"
                :search-service="firmSearchService"
                :filter-service="filterService"
                :filter-params="firmReviewerNamesFilterParams"
                :on-search="applyFirmSearch">
              </search-filter>

              <toggle-filter
                class="bottom-10"
                :label="'Reviewed Firms'"
                :active="firmSearchService.isFilterParamSet('has_published_reviews')"
                :description="'Show firms with reviews only'"
                :on-toggle="filterReviewedFirms">
              </toggle-filter>
            </div>

            <loading-section name="paginatedFirms">
              <div class="bottom-20">
                <span class="semibold-weight">{{ lawFirmsTotal }}</span>
                {{ $pluralize('result', lawFirmsTotal) }}
              </div>

              <law-firms-list
                :law-firms="lawFirms"
                :review-template="firmReviewTemplate"
                :on-sort="setSortAndApplyFirmSearch">
              </law-firms-list>

              <div class="top-20" v-if="lawFirms.length">
                <priori-pagination
                  class="scout-pagination"
                  :current-page="pageLawFirms"
                  :total-entries="lawFirmsTotal"
                  :per-page="10"
                  @change="changePageLawFirms">
                </priori-pagination>
              </div>
            </loading-section>
          </div>

          <div v-show="isTabSelected('drafts')">
            <loading-section name="paginatedDrafts">
              <drafts-list :drafts="drafts"></drafts-list>

              <div class="top-20" v-if="drafts.length">
                <priori-pagination
                  class="scout-pagination"
                  :current-page="pageDrafts"
                  :total-entries="draftsTotal"
                  :per-page="10"
                  @change="changePageDrafts">
                </priori-pagination>
              </div>
            </loading-section>
          </div>
        </div>
      </div>

      <scout-review-success-modal v-if="submittedReview" :review="submittedReview"></scout-review-success-modal>
    </div>
  </div>
</template>

<script>
import { flatten, range, snakeCase, sortBy } from 'lodash';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import ScoutMatter from 'resources/scout/scout-matter.js';
import ScoutMatterManager from 'src/resources/scout/scout-matter-manager.js';
import ScoutReviewReminder from 'resources/scout/client/scout-review-reminder.js';
import ScoutLawyerReviewTemplate from 'resources/scout/client/scout-lawyer-review-template';
import LoadingService from 'vue-app/shared/services/loading-service';
import NotificationService from 'vue-app/shared/services/notification-service';
import SearchService from 'src/services/scout/client/search-service.js';
import FilterService from 'vue-app/shared/services/filter-service.js';
import ScoutFirmReviewTemplate from 'resources/scout/client/scout-firm-review-template.js';
import ScoutReview from 'resources/scout/scout-review.js';
import ScoutIrrelevantReviewReminderMatter from 'resources/scout/client/scout-irrelevant-review-reminder-matter';
import ScoutWorkspace from 'resources/scout/scout-workspace.js';

import ReviewFormGuideModal from 'vue-app/scout/client/reviews/review-form-guide-modal.vue';
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';
import MattersList from 'vue-app/scout/client/matters/matters-list.vue';
import ReviewReminders from 'vue-app/scout/client/reviews/review-reminders.vue';
import SearchFilter from 'vue-app/scout/shared/searches/search-filter.vue';
import LawyersList from 'vue-app/scout/client/reviews/lawyers-list.vue';
import ToggleFilter from 'vue-app/shared/components/toggle-filter.vue';
import LawFirmsList from 'vue-app/scout/client/reviews/law-firms-list.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import DraftsList from 'vue-app/scout/client/reviews/drafts-list.vue';
import ScoutReviewSuccessModal from 'vue-app/scout/client/reviews/success-modal.vue';
import MattersListFilters from 'vue-app/scout/client/matters/matters-list-filters.vue';
import FilterReviewDate from 'vue-app/scout/client/reviews/filter-review-date.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ScoutClientReviewsIndex',

  components: {
    DraftsList,
    FilterReviewDate,
    InterfaceNote,
    LawyersList,
    LawFirmsList,
    LoadingSection,
    MattersList,
    MattersListFilters,
    PrioriPagination,
    ReviewFormGuideModal,
    ReviewReminders,
    SearchFilter,
    ScoutReviewSuccessModal,
    ToggleFilter
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    const lawyerSearchService = new SearchService({
      type: 'lawyers',
      workspaceId: this.initData.workspace.id
    });

    lawyerSearchService.view = 'review_list';
    lawyerSearchService.searchLogic.filters = { 'has_published_reviews': true };

    const firmSearchService = new SearchService({
      type: 'law_firms',
      workspaceId: this.initData.workspace.id
    });

    firmSearchService.view = 'review_list';
    firmSearchService.searchLogic.sort = { 'name': 'asc' };

    return {
      leavingReview: this.initData.leavingReview,
      workspace: this.initData.workspace,
      tab: this.initData.selectedTab || 'lawFirms',
      submittedReview: this.initData.submittedReview,

      draftsAreLoaded: false,
      lawyersAreLoaded: false,
      lawFirmsAreLoaded: false,
      mattersAreLoaded: false,

      drafts: [],
      lawyers: [],
      lawFirms: [],
      matters: [],

      pageDrafts: 1,
      pageLawyers: 1,
      pageLawFirms: 1,
      pageMatters: 1,

      matterFilters: {},
      mattersTotal: 0,
      matterManagers: [],
      reviewReminders: [],
      reviewReminderConfiguration: {},
      lawyerReviewTemplate: {},
      lawyerFullNames: [],

      lawyersTotal: 0,
      lawFirmsTotal: 0,

      lawyerSearchService,
      firmSearchService,
      filterService: new FilterService(this.initData.workspace.id),

      firmReviewTemplate: {},
      internalPracticeAreas: [],
      matterActivityYearRange: [],
      lawyerReviewsYearRange: [],
      firmReviewsYearRange: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),
    ...mapState(useCurrentUserStore, ['currentProduct']),

    toolTipText() {
      return this.currentClient.workspace.scoutReviewsMattersInfoTooltipText;
    },

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    firmReviewerNamesFilterParams() {
      return {
        slug:           'reviewerNames',
        field:          'reviewer_names',
        label:          'Reviewer Name',
        defaultContext: 'filter',
        lockContext:    true,
        template:       'multiselect',
        logic:          'or',
        prioritizedOption: { value: this.currentClient.fullName, label: `${this.currentClient.fullName} (You)` }
      };
    },

    firmNameFilterParams() {
      return {
        slug:           'firms',
        field:          'law_firm_bridge_id',
        label:          'Firm Name',
        lockContext:     true,
        defaultContext: 'filter',
        template:       'multiselect',
        logic:          'or'
      };
    },

    lawyerReviewerNamesFilterParams() {
      return {
        slug:           'reviewerNames',
        field:          'reviewer_names',
        label:          'Reviewer Name',
        defaultContext: 'filter',
        lockContext:    true,
        template:       'multiselect',
        logic:          'or',
        prioritizedOption: { value: this.currentClient.fullName, label: `${this.currentClient.fullName} (You)` }
      };
    },

    lawyerNameFilterParams() {
      return {
        slug:           'lawyerName',
        field:          'full_name',
        label:          'Lawyer Name',
        defaultContext: 'filter',
        lockContext:    true,
        template:       'typeahead',
        logic:          'or',
        loadAsync:      true
      };
    }
  },

  mounted() {
    this.selectTab(this.tab);

    ScoutWorkspace.get({ id: this.currentClient.workspaceId, view: 'filters_data' }).then((filtersData) => {
      const matterActivityYearRangeStart = filtersData.matterActivityYearRange.minYear ? filtersData.matterActivityYearRange.minYear - 1 : 1999;
      const matterActivityYearRangeEnd = filtersData.matterActivityYearRange.maxYear ? filtersData.matterActivityYearRange.maxYear : new Date().getFullYear();
      this.internalPracticeAreas = filtersData.internalPracticeAreas;
      this.matterActivityYearRange = range(matterActivityYearRangeEnd, matterActivityYearRangeStart, -1);

      const lawyerReviewStartYear = filtersData.earliestReviewYear.lawyerReviewYear ? filtersData.earliestReviewYear.lawyerReviewYear - 1 : 1999;
      this.lawyerReviewsYearRange = range(new Date().getFullYear(), lawyerReviewStartYear, -1);

      const firmReviewStartYear = filtersData.earliestReviewYear.firmReviewYear ? filtersData.earliestReviewYear.firmReviewYear - 1 : 1999;
      this.firmReviewsYearRange = range(new Date().getFullYear(), firmReviewStartYear, -1);
    });
  },

  methods: {
    loadReviewableFirms() {
      return ScoutFirmBridge.query();
    },

    loadReviewableLawyers(firmBridgeId) {
      return ScoutLawyerBridge.query({ lawFirmBridgeId: firmBridgeId, view: 'nameplate' });
    },

    isTabSelected(tab) {
      return this.tab === tab;
    },

    selectTab(tab) {
      if (tab === 'matters' && !this.mattersAreLoaded) {
        this.loadMatters();
        this.loadMatterManagers();
        this.fetchReviewReminders().then((reviewReminders) => {
          this.reviewReminderConfiguration = reviewReminders.metadata.configuration;
        });
      }

      if (tab === 'lawyers' && !this.lawyersAreLoaded) {
        this.loadLawyerReviewTemplate();
        this.loadSearchableLawyers();
        this.loadLawyerReviews();
      }

      if (tab === 'lawFirms' && !this.lawFirmsAreLoaded) {
        this.loadFirmReviewTemplate();
        this.loadFirmReviews();
      }

      if (tab === 'drafts' && !this.draftsAreLoaded) {
        this.loadDraftReviews();
      }

      if (!this.isTabSelected(tab)) {
        this.tab = tab;
      }
    },

    loadMatters(filterParams = {}) {
      const params = { view: 'review_list', page: this.pageMatters, ...this.matterFilters, ...filterParams };
      if (params.matter_manager_ids) {
        params.matter_manager_ids = flatten(params['matter_manager_ids']);
      }
      LoadingService.loading('paginatedMatters');

      return ScoutMatter.queryData(params).then((matterData) => {
        this.mapMatterDataToResources(matterData);
        this.mattersTotal = matterData.metadata.total;
        this.matters = matterData.resources;
        this.mattersAreLoaded = true;
        LoadingService.done('paginatedMatters');
      })
      .catch(() => {
        LoadingService.done('paginatedMatters');
        NotificationService.error('There was a problem filtering the list.');
      });
    },

    mapMatterDataToResources(matterData) {
      matterData.resources.forEach(matter => {
        matter.matterLawyers.forEach(matterLawyer => {
          matterLawyer.lawyer = new ScoutLawyerBridge(matterLawyer.lawyer);
        });
      });
    },

    loadMatterManagers() {
      LoadingService.loading('mattersListFilters');

      ScoutMatterManager.groupedByExternalId().then(data => {
        this.matterManagers = sortBy(data, 'displayName');
        LoadingService.done('mattersListFilters');
      });
    },

    fetchReviewReminders() {
      LoadingService.loading('reviewReminders');

      return ScoutReviewReminder.query()
        .then(reviewReminders => {
          this.reviewReminders = reviewReminders.reminders.map(reminder => new ScoutReviewReminder(reminder));

          return reviewReminders;
        })
        .finally(() => {
          LoadingService.done('reviewReminders');
        });
    },

    loadLawyerReviewTemplate() {
      ScoutLawyerReviewTemplate.inWorkspace().then((template) => {
        this.lawyerReviewTemplate = template;
      });
    },

    loadSearchableLawyers() {
      ScoutLawyerBridge.query({ view: 'review_list_search' }).then((lawyers) => {
        this.lawyerFullNames = lawyers.map(lawyer => lawyer.name);
      });
    },

    loadLawyerReviews() {
      LoadingService.loading('paginatedLawyers');

      this.lawyerSearchService.applySearch().then(lawyers => {
        this.setLawyerSearchResultsData(lawyers);
        this.lawyersAreLoaded = true;
        LoadingService.done('paginatedLawyers');
      });
    },

    setLawyerSearchResultsData(results) {
      this.lawyers = results.map(lawyer => new ScoutLawyerBridge(lawyer));
      this.lawyersTotal = this.lawyerSearchService.count();
    },

    loadFirmReviewTemplate() {
      ScoutFirmReviewTemplate.inWorkspace().then((template) => {
        this.firmReviewTemplate = template;
      });
    },

    loadFirmReviews() {
      LoadingService.loading('paginatedFirms');

      this.firmSearchService.applySearch().then(firms => {
        this.setFirmSearchResultsData(firms);
        this.lawFirmsAreLoaded = true;
        LoadingService.done('paginatedFirms');
      });
    },

    setFirmSearchResultsData(results) {
      this.lawFirms = results.map(firm => new ScoutFirmBridge(firm));
      this.lawFirmsTotal = this.firmSearchService.count();
    },

    loadDraftReviews() {
      LoadingService.loading('paginatedDrafts');

      const params = { page: this.pageDrafts, view: 'review_list' };

      ScoutReview.getDrafts(params).then(response => {
        this.draftsTotal = response.metadata.total;
        this.drafts = response.resources.map(resource => { return new ScoutReview(resource); });
        this.draftsAreLoaded = true;
        LoadingService.done('paginatedDrafts');
      });
    },

    loadReviewableMatterFirms(matterId) {
      return ScoutFirmBridge.query({ matter_id: matterId });
    },

    loadReviewableMatterLawyers(firmBridgeId, matterId) {
      return ScoutLawyerBridge.query({
        lawFirmBridgeId: firmBridgeId,
        matter_id: matterId,
        view: 'nameplate'
      });
    },

    createIrrelevantReviewReminderMatter(params) {
      return ScoutIrrelevantReviewReminderMatter.save({ irrelevantReviewReminderMatter: params }).then(() => {
        return this.fetchReviewReminders();
      });
    },

    loadMatterLawyers(_matter) {
      // TODO: Since they're already loaded with the matters in this controller,
      //       it's not necessary to do anything here, but would be more efficient
      //       to defer loading with the matters and then load them here.
    },

    applyLawyerSearch(loadingSectionName = 'paginatedLawyers') {
      LoadingService.loading(loadingSectionName);

      this.pageLawyers = 1;

      this.lawyerSearchService.applySearch().then(lawyers => {
        this.setLawyerSearchResultsData(lawyers);
        LoadingService.done(loadingSectionName);
      });
    },

    setSortAndApplyLawyerSearch(sortBy, sortOrder) {
      this.lawyerSearchService.searchLogic.sort = this.buildSortObject(sortBy, sortOrder);
      this.applyLawyerSearch('lawyer-reviews-sort');
    },

    buildSortObject(sortBy, sortOrder) {
      return { [snakeCase(sortBy)]: sortOrder };
    },

    applyFirmSearch(loadingSectionName = 'paginatedFirms') {
      LoadingService.loading(loadingSectionName);

      this.pageLawFirms = 1;

      this.firmSearchService.applySearch().then(firms => {
        this.setFirmSearchResultsData(firms);
        LoadingService.done(loadingSectionName);
      });
    },

    filterReviewedFirms(reviewed) {
      if (reviewed) {
        this.firmSearchService.setSearchParam('has_published_reviews', { context: 'filter', filter: 'true' });
      }
      else {
        this.firmSearchService.unsetSearchParam('has_published_reviews');
      }

      this.applyFirmSearch('law-firm-reviews-filter');
    },

    setSortAndApplyFirmSearch(sortBy, sortOrder) {
      this.firmSearchService.searchLogic.sort = this.buildSortObject(sortBy, sortOrder);
      this.applyFirmSearch('law-firm-reviews-sort');
    },

    changePageDrafts(page) {
      if (this.pageDrafts === page || page < 1) { return; }

      this.pageDrafts = page;
      this.loadDraftReviews();

      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    changePageLawyers(page) {
      if (this.pageLawyers === page || page < 1) { return; }

      LoadingService.loading('paginatedLawyers');
      this.pageLawyers = page;
      this.lawyerSearchService.page = page;

      this.lawyerSearchService.changePage().then(lawyers => {
        this.setLawyerSearchResultsData(lawyers);
        LoadingService.done('paginatedLawyers');

        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },

    changePageLawFirms(page) {
      if (this.pageLawFirms === page || page < 1) { return; }

      LoadingService.loading('paginatedFirms');
      this.pageLawFirms = page;
      this.firmSearchService.page = page;

      this.firmSearchService.changePage().then(firms => {
        this.setFirmSearchResultsData(firms);
        LoadingService.done('paginatedFirms');

        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },

    changePageMatters(page) {
      if (this.pageMatters === page || page < 1) { return; }

      this.pageMatters = page;
      this.loadMatters().then(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },

    filterMatters(filterParams) {
      this.matterFilters = filterParams;
      this.pageMatters = 1;

      return this.loadMatters();
    }
  }
};
</script>
