<template>
  <div class="list-item matter-row no-hover">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-3 semibold-weight">
        <div class="semibold-weight text-ellipsis">
          General Review
        </div>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="semibold-weight size-text-12px tight-lines">
          Score
        </div>

        <div class="top-2">
          {{ review.recommendation }}/10
        </div>
      </div>

      <div class="col-sm-3 top-10-xs" v-if="showComplexity">
        <div v-if="review.complexity">
          <div class="semibold-weight size-text-12px tight-lines">
            {{ reviewTemplate.complexityPromptBrief }}
          </div>

          <level-widget class="top-2" :label="complexityLabel" :score="review.complexity"></level-widget>
        </div>
      </div>

      <div :class="['size-text-12px top-10-xs', { 'col-sm-3': showComplexity, 'col-sm-6': !showComplexity }]">
        <div class="semibold-weight tight-lines">
          Reviewed By
        </div>

        <div class="inline-flex top-2">
          <div class="initials-circle">
            {{ reviewerInitials }}
          </div>

          <div class="left-5">
            {{ review.reviewer.fullName }} on {{ updatedAt }}
          </div>
        </div>
      </div>

      <div class="col-sm-1 text-right-not-xs">
        <a :href="`/scout-company/reviews/${review.id}`" class="semibold-weight" target="_self">View</a>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import LevelWidget from 'vue-app/shared/components/level-widget.vue';

export default {
  name: 'LawyerGeneralReview',

  components: {
    LevelWidget
  },

  props: {
    review: {
      type: Object,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    }
  },

  computed: {
    complexityLevels() {
      return [
        this.reviewTemplate.complexityLowText,
        this.reviewTemplate.complexityMediumText,
        this.reviewTemplate.complexityHighText
      ];
    },

    complexityLabel() {
      return this.complexityLevels[this.review.complexity - 1] || 'N/A';
    },

    reviewerInitials() {
      return (this.review.reviewer.firstName[0] + this.review.reviewer.lastName[0]).toUpperCase();
    },

    updatedAt() {
      return DateFilter.filter(this.review.updatedAt);
    },

    showComplexity() {
      return this.reviewTemplate.complexityShown;
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-item:last-child {
    border-bottom: 0;
  };
</style>
