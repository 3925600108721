<template>
  <div class="matter-titlebar titlebar-with-sections split-60-40">
    <div class="titlebar">
      <div class="medium-page-heading text-ellipsis bottom-5">
        {{ matter.nameForLawyersReference }}
      </div>

      <div class="size-text-13px text-ellipsis">
        <span class="right-20"><span class="semibold-weight">Admin ID:</span> {{ matter.id }}</span>
        <span class="right-20">&#8231;</span>
        <span class="semibold-weight">Status:</span>
        <span :class="matter.isClosed() ? 'red-text' : 'green-text'">{{ matter.displayStatus() }}</span>
      </div>
    </div>

    <div class="titlebar-switcher text-center">
      <a v-for="tabOfTabs in tabs" href="" :key="`tab-${labelFor(tabOfTabs)}`" :class="{ 'selected': isSelected(tabOfTabs) }" @click.prevent="selectTab(tabOfTabs)">{{ labelFor(tabOfTabs) }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatterTitlebar',

  props: {
    matter: {
      type:     Object,
      required: true
    },

    tabs: {
      type:     Array,
      required: true
    },

    tab: {
      type:     String,
      required: true
    },

    onTabChanged: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      selectedTab: this.tab
    };
  },

  methods: {
    labelFor(tab) {
      return {
        'matter': 'Project',
        'notes': 'Notes',
        'invoices': 'Invoices'
      }[tab];
    },

    isSelected(tab) {
      return this.tab === tab;
    },

    selectTab(tab) {
      if (!this.isSelected(tab)) {
        this.selectedTab = tab;
        this.onTabChanged(tab);
      }
    }
  }
};
</script>
