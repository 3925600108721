<template>
  <span :class="['match-tag', scoreClass]">{{ score }}%</span>
</template>

<script>
export default {
  name: 'MatchTag',

  props: {
    score: {
      type: Number,
      required: true
    }
  },

  computed: {
    scoreClass() {
      if (this.score === 100) {
        return 'perfect-match';
      }
      else if (this.score > 66) {
        return 'high-score';
      }
      else if (this.score > 33) {
        return 'mid-score';
      }
      else {
        return 'low-score';
      }
    }
  }
};
</script>
