<template>
  <div class="titlebar-with-sections">
    <div class="titlebar">
      <div class="inline-block right-20-not-xs">
        <profile-photo :user="lawyerLead"></profile-photo>
      </div>

      <div class="search-result-row">
        <div class="size-text-24px text-ellipsis bottom-5">
          {{ lawyerLead.fullName }}
        </div>

        <div class="size-text-13px semibold-weight text-ellipsis top-10-xs-sm">
          {{ lawyerLead.company }} <span v-if="lawyerLead.title">&mdash; {{ lawyerLead.title }}</span>
        </div>
      </div>
    </div>

    <div class="titlebar-switcher extra-padding text-center">
      <a href="" :class="{ 'selected': selectedTab === 'profile' }" @click.prevent="onSelect('profile')">Profile</a>
      <a href="" :class="{ 'selected': selectedTab === 'notes' }" @click.prevent="onSelect('notes')">Notes</a>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'LeadTitlebar',

  components: {
    ProfilePhoto
  },

  props: {
    lawyerLead: {
      type:     Object,
      required: true
    },

    selectedTab: {
      type:     String,
      required: true
    },

    onSelect: {
      type:     Function,
      required: true
    }
  }
};
</script>
