<template>
  <div>
    <div class="breadcrumbs-container with-buttons">
      <div class="container">
        <div class="row tight-columns vertical-center-not-xs">
          <div :class="['col-xs-12 col-sm-8 col-md-9', { 'top-10': isPublished }]">
            <a :href="reviewsUrl" class="back-link" target="_self"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Cancel & Exit</a>
          </div>

          <div v-if="!isPublished" class="col-xs-10 col-sm-4 col-md-3 top-20-xs">
            <loading-button name="saveDraft" :lb-class="['secondary-btn-blue transparent', { 'loading': isSavingDraft }]" @lb-click="saveDraft">Save & Complete Later</loading-button>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-4">
              <lawyer-card v-if="isLawyerReview" :lawyer="resource"></lawyer-card>
              <firm-card v-else :firm="resource"></firm-card>
            </div>

            <div class="col-sm-8 top-30-xs">
              <div class="shadowed-box">
                <div class="box-content">
                  <div class="size-text-24px bottom-10">
                    Submit a {{ isLawyerReview ? 'Lawyer' : 'Law Firm' }} Review
                  </div>

                  <div class="bottom-30">
                    Only your colleagues may access your reviews. No users outside of your company have access to your reviews on the platform.
                  </div>

                  <lawyer-review-form
                    v-if="isLawyerReview"
                    :lawyer-bridge="resource"
                    :review="review"
                    :practice-area-names="practiceAreaNames"
                    :scout-matters="scoutMatters"
                    :template="reviewTemplate"
                    :on-submit="submitReview">
                  </lawyer-review-form>

                  <firm-review-form
                    v-else
                    :firm="resource"
                    :review="review"
                    :practice-area-names="practiceAreaNames"
                    :scout-matters="scoutMatters"
                    :template="reviewTemplate"
                    :create-review="submitReview">
                  </firm-review-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerCard from 'vue-app/scout/client/reviews/lawyer-card.vue';
import FirmCard from 'vue-app/scout/client/reviews/firm-card.vue';
import LawyerReviewForm from 'vue-app/scout/client/reviews/lawyer-review-form.vue';
import FirmReviewForm from 'vue-app/scout/client/reviews/firm-review-form.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ScoutReview from 'resources/scout/scout-review.js';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { map, uniq } from 'lodash';

export default {
  name: 'ReviewsEdit',

  components: {
    LawyerCard,
    FirmCard,
    LawyerReviewForm,
    FirmReviewForm,
    LoadingButton,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      review: new ScoutReview(this.initData.review, this.initData.reviewTemplate),
      resource: this.initData.resource,
      reviewTemplate: this.initData.reviewTemplate,
      practiceAreaNames: [],
      scoutMatters: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isLawyerReview() {
      return this.review.resourceType === 'Scout::LawyerBridge';
    },

    reviewsUrl() {
      if (this.isLawyerReview) {
        return `/scout-company/lawyers/${this.review.resourceId}/reviews`;
      }
      else {
        return `/scout-company/law-firms/${this.review.resourceId}/reviews`;
      }
    },

    isPublished() {
      return !!this.review.publishedAt;
    },

    isSavingDraft() {
      return LoadingService.isLoading('saveDraft');
    }
  },

  mounted() {
    this.currentProduct.queryPracticeAreas().then((practiceAreas) => {
      this.practiceAreaNames = uniq(map(practiceAreas, 'name')).sort();
    });

    if (this.currentProduct.isScout) {
      const review = this.initData.review;
      const bridgeType = (review.resourceType === 'Scout::LawyerBridge' ? ScoutLawyerBridge : ScoutFirmBridge);

      LoadingService.loading('scoutMatters');
      bridgeType.scoutMatters(review.resourceId).then((matters) => {
        this.scoutMatters = matters;
        LoadingService.done('scoutMatters');
      });
    }
  },

  methods: {
    saveDraft() {
      LoadingService.loading('saveDraft');

      this.review.save({ publish: false })
        .then(() => {
          window.location = '/scout-company/reviews/drafts';
        })
        .finally(() => {
          LoadingService.done('saveDraft');
        });
    },

    submitReview() {
      LoadingService.loading('submitReview');

      this.review.save({ publish: true })
        .then(() => {
          NotificationService.success('You successfully edited the review.', true);
          window.location = `/scout-company/reviews/${this.review.id}`;
        })
        .catch(() => {
          NotificationService.error('Something went wrong while submitting your review.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() => {
          LoadingService.done('submitReview');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .secondary-btn-blue.transparent.loading {
    color: $white;
    background-color: $k-blue;
  }
</style>
