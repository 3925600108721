<template>
  <div>
    <interface-note class="bottom-40" :note-text="interfaceNoteText"></interface-note>

    <div class="row">
      <div class="col-sm-8">
        <!-- TODO: Scout -->
        <div class="size-text-20px dark-gray-text">
          Under construction. Please check back soon.
        </div>
      </div>

      <div class="col-sm-4">
      </div>
    </div>
  </div>
</template>

<script>
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';

export default {
  name: 'FirmClientMatters',

  components: {
    InterfaceNote
  },

  props: {
    workspace: {
      type: Object,
      required: true
    }
  },

  computed: {
    interfaceNoteText() {
      return `The following matter history shows all matters between ${this.workspace.name} and your law firm based on information in the client’s e-billing system.`;
    }
  }
};
</script>
