<template>
  <div class="size-text-13px">
    <div v-if="hasRequestPath" class="bottom-15">
      <div class="semibold-weight">
        Request
      </div>

      <div class="wrap-word">
        <a :href="matter.requestPath">View Request</a>
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Project Opened
      </div>

      <div class="wrap-word">
        <span v-if="matter.startedDate">{{ startedDate }}</span>
        <span v-else>&mdash;</span>
      </div>
    </div>

    <div class="bottom-15">
      <div class="semibold-weight">
        Project Closed
      </div>

      <div class="wrap-word">
        <span v-if="matter.endedDate">{{ endedDate }}</span>
        <span v-else>&mdash;</span>
      </div>
    </div>

    <div :class="{ 'bottom-30': matter.isOpen() }">
      <div class="semibold-weight">
        SF Opportunity ID
      </div>

      <div class="wrap-word">
        <a :href="matter.opportunityUrl" v-if="hasOpportunityUrl" target="_blank">{{ matter.opportunityId }}</a>
        <span v-if="!hasOpportunityUrl">&mdash;</span>
      </div>
    </div>

    <div v-if="matter.isOpen()">
      <priori-modal title="Confirm">
        <template #modal-trigger="{ openModal }">
          <button type="button" class="nv-button-white smaller" @click="openModal">Close Project</button>
        </template>

        <template #default="{ closeModal }">
          <div class="bottom-30">
            Are you sure you want to close this project?
          </div>

          <div class="row tight-columns">
            <div class="col-sm-4">
              <loading-button name="closeProject" lb-class="primary-btn-blue a-button-size" @lb-click="closeProject">Confirm</loading-button>
            </div>

            <div class="col-sm-3 top-10-xs">
              <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
            </div>
          </div>
        </template>
      </priori-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import NotificationService from 'vue-app/shared/services/notification-service';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import { isNil } from 'lodash';

export default {
  name: 'MatterStatus',

  components: {
    PrioriModal
  },

  mixins: [
    modalToggle
  ],

  props: {
    matter: {
      type: Object,
      required: true
    },

    closeMatter: {
      type: Function,
      required: true
    }
  },

  computed: {
    hasRequestPath() {
      return !isNil(this.matter.requestPath);
    },

    hasOpportunityUrl() {
      return !isNil(this.matter.opportunityUrl);
    },

    startedDate() {
      return moment(String(this.matter.startedDate)).format('MM/DD/yyyy hh:mm A');
    },

    endedDate() {
      return moment(String(this.matter.endedDate)).format('MM/DD/yyyy hh:mm A');
    }
  },

  methods: {
    closeProject() {
      LoadingService.loading('closeProject');

      this.closeMatter().then(() => {
        LoadingService.done('closeProject');

        NotificationService.success('You successfully closed the project.');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    }
  }
};
</script>
