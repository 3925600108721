<template>
  <edit-modal
    :input="rfq"
    :on-save="submitForm"
    @on-modal-close="reset"
    modal-size="md"
    save-button-text="Send"
    title="Edit Law Firm Questions">
    <div>
      <warning-message
        class="bottom-20"
        message="All law firm recipients will be notified of any changes made to this RFP.">
      </warning-message>

      <div class="list-spacing-30" v-for="group in orderedQuestionGroups" :key="group.id">
        <div class="size-text-15px bold-weight bottom-10">
          {{ group.name }}
        </div>

        <rfq-question-item
          v-for="question in orderedQuestionsFor(group)"
          :key="question.id"
          class="list-spacing-10"
          :question="question"
          :rfq="rfq"
          :show-edit="false">
        </rfq-question-item>
      </div>

      <validation-provider :rules="'atLeastOneTrue'">
        <input type="hidden" :value="allIsIncludedValues">
      </validation-provider>
    </div>

    <template #errorMessage>
      * Each RFP must include one question.
    </template>
  </edit-modal>
</template>

<script>
import { cloneDeep, orderBy } from 'lodash';
import { ValidationProvider } from 'vee-validate';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import WarningMessage from 'vue-app/shared/components/warning-message.vue';
import RfqQuestionItem from 'vue-app/scout/client/rfqs/create/question-item.vue';

export default {
  name: 'EditRfqQuestions',

  components: {
    EditModal,
    WarningMessage,
    RfqQuestionItem,
    ValidationProvider
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    updateQuestions: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      rfqCopy: cloneDeep(this.rfq)
    };
  },

  computed: {
    groupsWithQuestions() {
      return this.rfqCopy.questionGroups;
    },

    orderedQuestionGroups() {
      return orderBy(this.groupsWithQuestions, 'position');
    },

    allIsIncludedValues() {
      return this.rfqCopy.questionGroups.flatMap(group =>
        group.questions
          .filter(question => !question.prerequisiteQuestionId)
          .map(question => question.isIncluded)
      );
    }
  },

  methods: {
    orderedQuestionsFor(group) {
      return orderBy(group.questions.filter(q => !q.prerequisiteQuestionId), 'position');
    },

    reset() {
      this.rfqCopy = cloneDeep(this.rfq);
    },

    compareQuestions(rfq, rfqCopy) {
      return rfqCopy.questionGroups.reduce((differingQuestions, group, groupIndex) => {
        const differingGroupQuestions = group.questions.filter((rfqCopyQuestion, questionIndex) => {
          const rfqQuestion = rfq.questionGroups[groupIndex].questions[questionIndex];

          return this.compareQuestion(rfqQuestion, rfqCopyQuestion);
        });

        return differingQuestions.concat(differingGroupQuestions);
      }, []);
    },

    compareQuestion(rfqQuestion, rfqCopyQuestion) {
      if (!rfqCopyQuestion || !rfqQuestion) { return false; }

      return rfqCopyQuestion.isIncluded !== rfqQuestion.isIncluded;
    },

    submitForm() {
      const changedQuestions = this.compareQuestions(this.rfq, this.rfqCopy);

      if (!changedQuestions.length > 0) { return; }

      this.updateQuestions(this.rfq.id, changedQuestions).then((updatedRfq) => {
        this.rfqCopy = cloneDeep(updatedRfq);
      });
    }
  }
};
</script>
