import axios from 'axios';
import BaseResource from 'resources/base-resource';

class SavedSearch extends BaseResource {
  static baseUrl = '/admin/saved_searches/:id.json';

  static order(data) {
    return axios.post('/admin/saved_searches/order.json', data);
  }
}

export default SavedSearch;
