<template>
  <div class="row tight-columns">
    <div :class="[(viewingAsLawyer ? 'col-sm-11' : 'col-sm-4'), 'clickable']" @click="viewLawyer">
      <lawyer-name-plate :lawyer="lawyer" :hide-firm="true"></lawyer-name-plate>
    </div>

    <div v-if="!viewingAsLawyer" class="col-sm-7">
      <div class="row match-row tight-columns vertical-center-not-xs">
        <div class="col-sm-3">
          <span :class="{ 'text-italic': lawyer.isBillingLawyer() }">{{ lawyer.firmName || '&mdash;' }}</span>
        </div>

        <div class="col-sm-3 text-center">
          <span v-if="lawyer.rate">{{ lawyer.rate }}/hr</span>
          <span v-else>&mdash;</span>
        </div>

        <div class="col-sm-3 text-center">
          <div v-if="lawyer.reviews.score">
            <div class="semibold-weight">
              {{ reviewScore }}/10
            </div>

            <div class="size-text-12px">
              ({{ reviewCount }})
            </div>
          </div>

          <div v-else>
            &mdash;
          </div>
        </div>

        <div class="col-sm-3 text-center">
          {{ lawyer.matterCount || 0 }}
        </div>
      </div>
    </div>

    <div class="col-sm-1 text-right-not-xs">
      <dropdown-menu :compact="true" class="top-5">
        <li class="menu-item" role="menuitem">
          <a :href="profilePath" target="_blank">View Profile</a>
        </li>

        <li v-if="isMutable" class="menu-item" role="menuitem">
          <a href="" @click.prevent="removeFromList">Remove</a>
        </li>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';

export default {
  name: 'ListLawyer',

  components: {
    LawyerNamePlate,
    DropdownMenu
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    workspaceId: {
      type: Number,
      required: false
    },

    viewingAsLawyer: {
      type: Boolean,
      default: false
    },

    isMutable: {
      type: Boolean,
      default: true
    },

    onView: {
      type: Function,
      required: true
    },

    onRemoveFromList: {
      type: Function,
      required: true
    }
  },

  computed: {
    reviewScore() {
      return Number(this.lawyer.reviews.score).toFixed(1);
    },

    reviewCount() {
      return this.$pluralize('review', this.lawyer.reviews.count, true);
    },

    profilePath() {
      if (this.viewingAsLawyer) {
        return `/scout-firm/clients/${this.workspaceId}/lawyers/${this.lawyer.id}`;
      }
      else {
        return `/scout-company/lawyers/${this.lawyer.id}`;
      }
    }
  },

  methods: {
    viewLawyer() {
      this.onView(this.lawyer);
    },

    removeFromList() {
      if (!this.isMutable) { return; }

      this.onRemoveFromList(this.lawyer);
    }
  }
};
</script>
