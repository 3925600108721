<template>
  <div>
    <div class="semibold-weight purple-5-text feature-lab-status">
      Onboarded - Feature Lab
    </div>

    <hr class="kenny-hr">

    <div class="bold-weight text-uppercase size-text-11px bottom-10">
      Connected Firms
    </div>

    <div>
      <scout-firm
        v-for="firm in connectedFirms"
        :firm="firm"
        :key="firm.id">
      </scout-firm>
    </div>

    <div v-if="!hasConnectedFirms" class="size-text-16px gray-6-text bottom-15">
      No connected firms
    </div>

    <connect-to-firm
      class="top-20"
      :workspace="workspace"
      :use-connecting-firms="false"
      v-if="permittedToConfigure">
    </connect-to-firm>

    <hr class="kenny-hr">

    <div class="bold-weight text-uppercase size-text-11px bottom-10">
      E-Billing
    </div>

    <div>
      <div v-for="(billingSystem, index) in billingSystems" :key="`billing-system-${index}`" class="list-spacing-5">
        {{ billingSystem.name }}
      </div>
    </div>

    <div
      v-if="!hasBillingSystems"
      class="size-text-16px gray-6-text">
      No billing system registered
    </div>
  </div>
</template>

<script>
import ConnectToFirm from 'vue-app/admin/workspaces/connect-to-firm.vue';
import ScoutFirm from 'vue-app/admin/workspaces/scout-firm.vue';

export default {
  name: 'FeatureLabConfiguration',

  components: {
    ConnectToFirm,
    ScoutFirm
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    permittedToConfigure: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasConnectedFirms() {
      return this.connectedFirms.length > 0;
    },

    hasBillingSystems() {
      return this.billingSystems.length > 0;
    },

    connectedFirms() {
      return this.workspace.scoutFirms;
    },

    billingSystems() {
      return this.workspace.billingSystems;
    }
  }
};
</script>
