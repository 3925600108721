<template>
  <boxed-content v-if="displayComponent" title="Affiliations">
    <template #links v-if="canEditProfile">
      <edit-lawyer-affiliations :lawyer="lawyer" :on-save="onSave"></edit-lawyer-affiliations>
    </template>

    <section-wrapper class="separated-list-items" :data="lawyer.sanitizedBoardExperience" :editing-allowed="canEditProfile">
      <lawyer-affiliation-section header="Board Experience" :data="lawyer.sanitizedBoardExperience"></lawyer-affiliation-section>
    </section-wrapper>

    <section-wrapper class="separated-list-items" :data="lawyer.sanitizedProBonoAffiliations" :editing-allowed="canEditProfile">
      <lawyer-affiliation-section header="Pro Bono / Community Affiliations" :data="lawyer.sanitizedProBonoAffiliations"></lawyer-affiliation-section>
    </section-wrapper>

    <section-wrapper class="separated-list-items" :data="lawyer.sanitizedIndustryGroupExperience" :editing-allowed="canEditProfile">
      <lawyer-affiliation-section header="Industry Group Experience" :data="lawyer.sanitizedIndustryGroupExperience"></lawyer-affiliation-section>
    </section-wrapper>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import EditLawyerAffiliations from './edit-lawyer-affiliations.vue';
import LawyerAffiliationSection from './lawyer-affiliation-section.vue';

export default {
  name: 'LawyerAffilations',

  components: {
    BoxedContent,
    SectionWrapper,
    EditLawyerAffiliations,
    LawyerAffiliationSection
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  computed: {
    displayComponent() {
      const {
        sanitizedBoardExperience,
        sanitizedProBonoAffiliations,
        sanitizedIndustryGroupExperience
      } = this.lawyer;

      return this.canEditProfile || this.anyDataIsPresent(
        [
          sanitizedBoardExperience,
          sanitizedProBonoAffiliations,
          sanitizedIndustryGroupExperience
        ]
      );
    }
  }
};
</script>
