<template>
  <div class="row tight-columns">
    <div class="col-sm-8 dash-project-container" @click="viewRequest">
      <div class="dash-project-name text-ellipsis">
        {{ counselRequest.nameForLawyersReference }}
        <span class="left-10 size-text-base purple-text semibold-weight" v-if="counselRequest.wework">(via WeWork)</span>
      </div>

      <div class="text-ellipsis">
        <span class="right-5">
          <span class="semibold-weight">{{ requester.fullName }}</span><span v-if="requester.businessName"> ({{ requester.businessName }})</span>
        </span>

        <span class="right-5">&#8231;</span>

        <span>Status: <span :class="['semibold-weight light-blue-k-text', { 'red-text': possibleLawyer.awaitingProposalStatus() || possibleLawyer.requestedStatus() }]">{{ possibleLawyer.statusForLawyer() }}</span></span>
      </div>
    </div>

    <div class="col-sm-4 top-4">
      <div class="row tight-columns text-right-not-xs">
        <div class="col-xs-9 col-lg-8">
          <button type="button" class="nv-button-blue smaller" @click="viewRequest">View Details</button>
        </div>

        <div class="col-xs-3 col-lg-4">
          <request-options-tooltip
            :possible-lawyer="possibleLawyer"
            :on-message-client="messageClient"
            :on-send-proposal="sendProposal"
            :on-schedule-call="scheduleCall"
            :on-reject-request="rejectRequest">
          </request-options-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestOptionsTooltip from './request-options-tooltip.vue';

export default {
  name: 'ClientRequest',

  components: {
    RequestOptionsTooltip
  },

  props: {
    possibleLawyer: {
      type: Object,
      default: () => ({})
    },

    onMessageClient: {
      type: Function,
      required: true
    },

    onSendProposal: {
      type: Function,
      required: true
    },

    onScheduleCall: {
      type: Function,
      required: true
    },

    onRejectRequest: {
      type: Function,
      required: true
    }
  },

  computed: {
    counselRequest: function () {
      return this.possibleLawyer.counselRequest;
    },

    requester: function () {
      return this.counselRequest.requester;
    }
  },

  methods: {
    viewRequest: function () {
      window.location.href = '/lawyer-app/requests/' + this.counselRequest.id + '/document/' + this.possibleLawyer.id;
    },

    messageClient: function () {
      this.onMessageClient(this.requester, this.counselRequest);
    },

    sendProposal: function () {
      this.onSendProposal(this.possibleLawyer);
    },

    scheduleCall: function () {
      this.onScheduleCall(this.possibleLawyer);
    },

    rejectRequest: function () {
      this.onRejectRequest(this.possibleLawyer);
    }
  }
};
</script>
