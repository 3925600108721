<template>
  <div>
    <connected-firm-dashboard
      v-if="!isConnecting"
      :firm="firm"
      :rfq-notifications="rfqNotifications"
      @update-viewed-scout-onboarding-modal="updateViewedScoutOnboardingModal">
    </connected-firm-dashboard>

    <connecting-firm-dashboard
      v-if="isConnecting"
      :rfq-notifications="rfqNotifications">
    </connecting-firm-dashboard>
  </div>
</template>

<script>
import ConnectedFirmDashboard from 'vue-app/scout/firm/dashboard/dashboard-connected.vue';
import ConnectingFirmDashboard from 'vue-app/scout/firm/dashboard/dashboard-connecting.vue';
import RFQLawFirmNotification from 'resources/scout/rfq-law-firm-notification.js';
import Lawyer from 'resources/lawyer.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'DashboardIndex',

  components: {
    ConnectedFirmDashboard,
    ConnectingFirmDashboard
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      firm: this.initData.firm,
      isConnecting: this.initData.isConnecting,
      rfqNotifications: {}
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    hasRfqEnabled() {
      return this.user.hasRfqEnabled;
    }
  },

  mounted() {
    if (this.hasRfqEnabled) { this.loadRfqNotifications(); }
  },

  methods: {
    loadRfqNotifications() {
      RFQLawFirmNotification.query().then((notifications) => {
        this.rfqNotifications = notifications;
      });
    },

    updateViewedScoutOnboardingModal() {
      this.user.viewedScoutOnboardingModal = true;
      Lawyer.update({ id: this.user.id, lawyer: { viewedScoutOnboardingModal: true } });
    }
  }
};
</script>
