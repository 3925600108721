<template>
  <div class="dropdown-text">
    <div class="title text-uppercase semibold-weight size-text-15px">
      {{ title }}

      <button type="button" class="nv-button-square-white" @click="toggleAccordion">
        <svg-icon v-if="showAccordion" name="caret-up" class="base-icon smaller"></svg-icon>
        <svg-icon v-else name="caret-down" class="base-icon smaller"></svg-icon>
      </button>
    </div>

    <collapse v-model="showAccordion" :transition="250">
      <div class="content">
        <slot></slot>
      </div>
    </collapse>
  </div>
</template>

<script>
export default {
  name: 'DropdownText',

  props: {
    title: {
      type: String,
      required: true
    },

    initiallyOpen: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      showAccordion: this.initiallyOpen
    };
  },

  methods: {
    toggleAccordion: function () {
      this.showAccordion = !this.showAccordion;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .dropdown-text {
    border-bottom: 1px solid $k-light-gray;
    padding: 15px 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.4;
  }

  .content {
    max-width: 90%;
    padding-top: 10px;
  }
</style>
