<template>
  <div>
    <div class="small-bold-header">
      Jurisdictional Experience
    </div>

    <hr class="margin-top-5">

    <div class="flex bottom-15" v-for="(experience, index) in jurisdictionalExperience" :key="`jurisdictionalExperience_${index}`">
      <div>
        <a href="" @click.prevent="removeJurisdictionalExperience(index)"><svg-icon name="minus-circle" class="base-icon remove-icon-spacing"></svg-icon></a>
      </div>

      <div class="width-95">
        <text-input-vertical
          :id="`jurisdictional-experience-${index}`"
          :name="`jurisdictionalExperience_${index}`"
          label="Location"
          rules="required"
          v-model="jurisdictionalExperience[index]">
        </text-input-vertical>
      </div>
    </div>

    <div>
      <a href="" class="semibold-weight" @click.prevent="addJurisdictionalExperience">+ {{ jurisdictionalExperience.length ? 'Add Another' : 'Add Jurisdictional Experience' }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditLawyerJurisdictionalExperience',

  props: {
    jurisdictionalExperience: {
      type: Array,
      required: true
    },

    jurisdictionalExperienceToDestroy: {
      type: Array,
      required: true
    }
  },

  methods: {
    addJurisdictionalExperience: function () {
      this.jurisdictionalExperience.push('');
    },

    removeJurisdictionalExperience: function (index) {
      if (index > -1) { this.jurisdictionalExperience.splice(index, 1); }
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
  }

  .remove-icon-spacing {
    margin-top: 40px;
    margin-right: 10px;

    @media (min-width: 768px) {
      margin-top: 38px;
    }
  }

  .width-95 {
    width: 95%;
  }

  .margin-top-5 {
    margin-top: 5px;
  }
</style>
