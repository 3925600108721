<template>
  <boxed-content id="diversity-settings" :title="'Diversity Disclosure Settings'" class="settings-box">
    <div class="bold-weight size-text-14px bottom-5">
      Geographic Diversity Settings
    </div>

    <div class="dark-gray-text">
      Indicate whether lawyers in the following locations have the ability to disclose individual-level diversity data using Scout. If you have any questions, please reach out to the Priori team at <a href="mailto:admin@priorilegal.com" class="dark-gray-text" target="_blank">admin@priorilegal.com</a>.
    </div>

    <form role="form" class="a-form" novalidate>
      <div class="top-20 bottom-15">
        <hr class="no-margin">
      </div>

      <div class="continent-btn-container">
        <span v-for="continent in continents" :key="continent" class="inline-block">
          <label class="check-option">
            <span class="normal-weight">{{ continentLabel(continent) }}</span>
            <input type="radio" name="continent" v-model="selectedContinent" :value="continent">
            <span class="check c-radio"></span>
          </label>
        </span>
      </div>

      <div class="top-15 bottom-20">
        <hr class="no-margin">
      </div>

      <div class="row tight-columns bottom-20">
        <div class="col-xs-9 col-sm-10">
          <div class="size-text-14px bold-weight">
            {{ continentLabel(selectedContinent) }}
          </div>

          <div class="dark-gray-text text-italic top-5" v-if="disclaimer">
            {{ disclaimer }}
          </div>
        </div>

        <div class="col-xs-3 col-sm-2 text-right" v-if="!editMode">
          <a href="" @click.prevent="editMode = true">Edit</a>
        </div>
      </div>

      <div v-for="(continentSettings, continent) in disclosureSettingsCopy" :key="continent" v-show="selectedContinent == continent">
        <div v-for="countrySettings in continentSettings" :key="countrySettings.countryName" class="country-setting list-spacing-20">
          <div class="row tight-columns">
            <div class="col-xs-8 col-sm-9">
              <label :for="countrySettings.countryName" class="no-margin">
                <span class="normal-weight">{{ countrySettings.countryName }}</span>
              </label>
            </div>

            <div class="col-xs-4 col-sm-3 text-right">
              <toggle-button
                v-if="editMode && countrySettings.editable"
                :id="countrySettings.countryName"
                :value="countrySettings.disclosureEnabled"
                :disabled="saving"
                @click="toggleDisclosureSettings(countrySettings)">
              </toggle-button>
              <span v-if="!editMode || !countrySettings.editable">{{ countrySettings.disclosureEnabled ? "On" : "Off" }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="editMode" class="top-30">
        <loading-button name="submitLawFirm" lb-class="primary-button-compact right-20" @lb-click="save">Save</loading-button>
        <button type="button" class="secondary-button-compact" @click.prevent="cancel">Cancel</button>
      </div>
    </form>

    <request-submitted-success-modal
      ref="successModal">
    </request-submitted-success-modal>

    <data-removal-request-confirmation-modal
      ref="confirmationModal"
      :on-request-removal="requestRemoval">
    </data-removal-request-confirmation-modal>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import RequestSubmittedSuccessModal from 'vue-app/scout/firm/settings/diversity-disclosure/request-submitted-success-modal.vue';
import DataRemovalRequestConfirmationModal from 'vue-app/scout/firm/settings/diversity-disclosure/data-removal-request-confirmation-modal.vue';
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import { cloneDeep, startCase } from 'lodash';

export default {
  name: 'DiversityDisclosureSettings',

  components: {
    BoxedContent,
    RequestSubmittedSuccessModal,
    DataRemovalRequestConfirmationModal,
    ToggleButton,
    LoadingButton
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onRemovalRequest: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedContinent: null,
      editMode: false,
      disclosureSettingsCopy: cloneDeep(this.firm.diversityDisclosureSettings),
      removalQueue: ''
    };
  },

  computed: {
    disclosureEnabledCountries() {
      const countrySettings = Object.values(this.disclosureSettingsCopy).flat();
      const disclosureEnabled = countrySettings.filter(obj => obj.disclosureEnabled);

      return disclosureEnabled.map(obj => obj.countryName);
    },

    initiallyOffCountries() {
      const countrySettings = Object.values(this.firm.diversityDisclosureSettings).flat();
      const disclosureDisabled = countrySettings.filter(obj => !obj.disclosureEnabled);

      return disclosureDisabled.map(obj => obj.countryName);
    },

    continents() {
      const allContinents = ['africa', 'asia', 'oceania', 'europe', 'northAmerica', 'southAmerica'];

      return allContinents.filter(continent => Object.keys(this.firm.diversityDisclosureSettings).includes(continent));
    },

    disclaimer() {
      if (!['europe', 'northAmerica'].includes(this.selectedContinent)) { return; }

      const continentString = this.selectedContinent === 'northAmerica' ? 'the United States' : 'Europe';
      const enabledString = this.selectedContinent === 'europe' ? 'disabled' : 'enabled';

      return `Individual level diversity disclosures are ${enabledString} by default for law firm locations in ${continentString}. Please reach out to the Scout team if you have questions on this setting.`;
    },

    saving() {
      return LoadingService.isLoading('submitLawFirm');
    }
  },

  mounted() {
    this.selectedContinent = this.continents[0];
  },

  methods: {
    continentLabel(continent) {
      return startCase(continent);
    },

    toggleDisclosureSettings(countrySettings) {
      const { countryName, editable, disclosureEnabled } = countrySettings;

      if (!editable) { return; }

      if (disclosureEnabled && !this.initiallyOffCountries.includes(countryName)) {
        this.removalQueue = countryName;
        this.$refs.confirmationModal.openModal();
      }
      else {
        countrySettings.disclosureEnabled = !disclosureEnabled;
      }
    },

    requestRemoval() {
      this.onRemovalRequest(
        { dataRemovalCountry: this.removalQueue },
        () => { this.$refs.successModal.openModal(); }
      );
    },

    successFunction() {
      this.editMode = false;
      LoadingService.done('submitLawFirm');
    },

    errorFunction() {
      this.disclosureSettingsCopy = cloneDeep(this.firm.diversityDisclosureSettings);
      this.editMode = false;
      LoadingService.done('submitLawFirm');
    },

    save() {
      LoadingService.loading('submitLawFirm');
      this.onSave(
        {
          id: this.firm.id,
          disclosureEnabledCountries: this.disclosureEnabledCountries
        },
        this.successFunction,
        this.errorFunction
      );
    },

    cancel() {
      this.disclosureSettingsCopy = cloneDeep(this.firm.diversityDisclosureSettings);
      this.editMode = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .check-option {
    margin-bottom: 0;
  }

  .continent-btn-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
  }
</style>
