<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-xs-6 text-uppercase">
          Notes
        </div>

        <div class="col-xs-6 text-right">
          <div v-if="editingAllowed">
            <edit-note-modal title="Add Note" :on-save="save">
              <template #modal-trigger="{ openModal }">
                <a href="" @click.prevent="openModal">+ Add Note</a>
              </template>
            </edit-note-modal>
          </div>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <loading-section name="firmNotes" :render-after-loading="true">
        <firm-note
          v-for="note in notes"
          class="list-spacing-20"
          :key="note.id"
          :note="note"
          :on-save="save"
          :editing-allowed="editingAllowed">
        </firm-note>

        <div v-if="!notes.length" class="gray-text size-text-16px">
          No Notes
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import FirmNote from 'vue-app/scout/client/law-firms/firm-note.vue';
import EditNoteModal from 'vue-app/scout/client/law-firms/edit-note-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

export default {
  name: 'FirmNotes',

  components: {
    FirmNote,
    EditNoteModal,
    LoadingSection
  },

  props: {
    notes: {
      type: Array,
      required: true
    },

    editingAllowed: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  methods: {
    save(note) {
      if (!this.editingAllowed) { return; }

      return this.onSave(note);
    }
  }
};
</script>
