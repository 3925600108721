<template>
  <textarea-vertical
    v-model="answer"
    :label="question.questionText"
    :id="`question-${question.id}`"
    :name="`question${question.id}`"
    :rows="2"
    :autoresize="true"
    :rules="validationRules"
    :disable-input="!question.isIncluded"
    @input="handleInput">
    <template #tag v-if="showQuestionUpdateTag">
      <scout-tag :scout-tag="tagData"></scout-tag>
    </template>
  </textarea-vertical>
</template>

<script>
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import { startCase } from 'lodash';

export default {
  name: 'QuoteFormQuestionText',

  components: {
    TextareaVertical,
    ScoutTag
  },

  props: {
    value: {
      type: String
    },

    question: {
      type: Object,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      answer: this.value
    };
  },

  computed: {
    validationRules() {
      return this.question.isRequired && this.question.isIncluded ? 'required' : '';
    },

    tagData() {
      return {
        name: this.activityText,
        color: 'yellow'
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  },

  methods: {
    handleInput() {
      this.onChange(this.answer);
    }
  }
};
</script>
