<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header text-uppercase">
      Contact
    </div>

    <div class="box-content">
      <div class="semibold-weight">
        Email
      </div>

      <div>
        {{ lawyer.email }}
      </div>
    </div>
  </div>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';

export default {
  name: 'GhostLawyerContact',

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    }
  },

  computed: {
    displayComponent() {
      return !this.lawyer.firmIsConnecting && this.anyDataIsPresent([this.lawyer.email]);
    }
  }
};
</script>
