<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      My Drafts
    </div>

    <div class="resource-list static-list">
      <draft-review-list-item :review="review" v-for="review in drafts" :key="review.id"></draft-review-list-item>

      <div class="list-item empty single-item gray-text size-text-16px" v-if="!hasDrafts">
        No Draft Reviews
      </div>
    </div>
  </div>
</template>

<script>
import DraftReviewListItem from './draft-review-list-item.vue';

export default {
  name: 'DraftsList',

  components: {
    DraftReviewListItem
  },

  props: {
    drafts: {
      type: Array,
      required: true
    }
  },

  computed: {
    hasDrafts() {
      return !!this.drafts.length;
    }
  }
};
</script>
