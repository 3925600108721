<template>
  <edit-modal :title="modalTitle" :input="document" modal-size="md-sm" :on-save="save" :on-cancel="cancel">
    <template #modal-trigger="{ openModal }">
      <slot name="modal-trigger" :open-modal="openModal">
        <a href="" class="normal-weight size-text-13px" @click.prevent="openModal">Edit</a>
      </slot>
    </template>

    <template #default="{ inputCopy: documentCopy }">
      <div class="pill-gray-text bottom-30">
        Add documents related to this law firm by uploading files and adding titles.
      </div>

      <div class="bottom-30">
        <text-input-vertical
          label="Title"
          rules="required"
          v-model="documentCopy.name">
        </text-input-vertical>
      </div>

      <div v-if="isEditing">
        <file-selector
          ref="fileSelector"
          class="width-100"
          :accept="acceptedExtensions"
          :max-size="50"
          v-model="selectedFile"
          @change="selectFile"
          @errors="setFileErrors">
          <div class="vertical-center-not-xs col-gap-20">
            <div class="secondary-btn-blue fit-content clickable upload-file-btn">
              Replace File
            </div>

            <div class="text-ellipsis top-10-xs">
              <div class="selected-file inline-block bold-weight text-ellipsis top-5">
                {{ selectedFile?.name || document.fileIdentifier }}
              </div>
            </div>
          </div>
        </file-selector>
      </div>

      <div v-else>
        <div v-if="selectedFile" class="selected-file d-flex bold-weight">
          <div class="text-ellipsis">{{ selectedFile.name }}</div>

          <button type="button" class="pseudo-link left-10" @click="deselectFile">
            <svg-icon name="x4" class="base-icon smallest dark-gray-text"></svg-icon>
          </button>
        </div>

        <validation-provider v-else rules="required" v-slot="{ errors }">
          <file-selector
            ref="fileSelector"
            :accept="acceptedExtensions"
            :max-size="50"
            v-model="selectedFile"
            @change="selectFile"
            @errors="setFileErrors">
            <span class="secondary-btn-blue fit-content clickable upload-file-btn">Upload File</span>
          </file-selector>

          <div class="error-text top-5">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above.
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'EditDocumentModal',

  components: {
    EditModal,
    FileSelector,
    SvgIcon,
    ValidationProvider
  },

  props: {
    modalTitle: {
      type: String,
      required: true
    },

    document: {
      type: Object,
      default: () => ({
        name: '',
        content: ''
      })
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      acceptedExtensions: '.jpg,.jpeg,.gif,.png,.bmp,.pdf,.doc,.docx,.key,.xls,.xlsx,.csv,.txt,.ppt,.pptx,.pps,.ppsx,.odt,.rtf',
      selectedFile: null,
      fileErrors: []
    };
  },

  computed: {
    isEditing() {
      return !!this.document.id;
    }
  },

  methods: {
    selectFile(files) {
      this.selectedFile = files[0];
    },

    save(editedDocument) {
      if (this.fileErrors.length) {
        LoadingService.done('editModalSubmit');
        throw this.fileErrors[0];
      }

      editedDocument.file = this.selectedFile;
      return this.onSave(editedDocument);
    },

    cancel() {
      this.$refs.fileSelector?.reset();
      this.fileErrors = [];
      this.deselectFile();
    },

    deselectFile() {
      this.selectedFile = null;
    },

    setFileErrors(errors) {
      this.fileErrors = errors;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .upload-file-btn {
    min-width: 136px;
  }

  .selected-file {
    padding: 5px 15px;
    border-radius: 25px;
    border: 1px solid $medium-gray;
    max-width: 100%;
    width: fit-content;
  }
</style>
