<template>
  <div class="shadowed-box lawyer-profile lead-search">
    <div class="box-content">
      <a :href="lawyerLeadAccountUrl" class="text-link user-pic-container right-20" target="_blank"><profile-photo :user="lawyerLead"></profile-photo></a>

      <div class="search-result-row">
        <div class="row tight-columns">
          <div class="col-sm-10 semibold-weight top-6">
            <div class="size-text-18px text-ellipsis">
              <a :href="lawyerLeadAccountUrl" class="text-link" target="_blank">{{ lawyerLead.fullName }}</a>
            </div>

            <div class="text-ellipsis">
              {{ lawyerLead.company }}
              <span v-if="lawyerLead.title">&mdash; {{ lawyerLead.title }}</span>
            </div>
          </div>

          <div class="col-xs-9 col-sm-2 text-right top-20-xs">
            <dropdown-menu icon-name="caret-down">
              <li class="menu-item" role="menuitem">
                <a :href="lawyerLeadAccountUrl" target="_blank"><svg-icon name="document" class="base-icon"></svg-icon>View Lead</a>
              </li>
            </dropdown-menu>
          </div>
        </div>
      </div>
    </div>

    <hr class="kenny-hr no-margin">

    <div class="no-gutter">
      <div class="row">
        <div class="col-sm-6 search-result-details">
          <div class="box-content symmetrical">
            <div class="bottom-15">
              <div class="semibold-weight">
                Location
              </div>

              <div>
                {{ address || '&mdash;' }}
              </div>
            </div>

            <div class="bottom-15">
              <div class="semibold-weight">
                LinkedIn ID
              </div>

              <div>
                {{ lawyerLead.linkedinId || '&mdash;' }}
              </div>
            </div>

            <div>
              <div class="semibold-weight">
                Salesforce Lead ID
              </div>

              <div>
                <a :href="`https://priori.my.salesforce.com/${lawyerLead.salesforceLeadId}`" target="_blank" v-if="lawyerLead.salesforceLeadId">{{ lawyerLead.salesforceLeadId }}</a>
                <span v-else>&mdash;</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 search-result-sidebar">
          <div class="box-content symmetrical">
            <div class="tiny-header-text bottom-10">
              Law School
            </div>

            <div>
              <span v-if="lawyerLead.lawSchool">{{ lawyerLead.lawSchool }}<span v-if="lawyerLead.gradYear"> ({{ lawyerLead.gradYear }})</span></span>
              <span v-else>&mdash;</span>
            </div>

            <hr class="kenny-hr normal">

            <div class="tiny-header-text bottom-10">
              Practice Areas
            </div>

            <div>
              {{ lawyerLead.practiceAreas || '&mdash;' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="lawyerLead.searchHighlights">
      <hr class="kenny-hr no-margin">

      <lead-search-highlights :search-result="lawyerLead"></lead-search-highlights>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import LeadSearchHighlights from './lead-search-highlights.vue';
import { compact } from 'lodash';

export default {
  name: 'LeadSearchResult',

  components: {
    ProfilePhoto,
    SvgIcon,
    DropdownMenu,
    LeadSearchHighlights
  },

  props: {
    lawyerLead: {
      type: Object,
      required: true
    }
  },

  computed: {
    lawyerLeadAccountUrl() {
      if (this.lawyerLead.id) {
        return `/admin-app/lawyer-leads/${this.lawyerLead.id}`;
      }
      else {
        return `/admin-app/lawyer-leads/salesforce/${this.lawyerLead.salesforceLeadId}`;
      }
    },

    address() {
      if (this.lawyerLead.city) {
        return compact([this.lawyerLead.city, this.stateOrCountry]).join(', ');
      }
      else {
        return 'N/A';
      }
    },

    stateOrCountry() {
      return this.lawyerLead.country === 'United States' ? this.lawyerLead.state : this.lawyerLead.country;
    }
  }
};
</script>
