<template>
  <div class="data-disclaimer text-center">
    <div class="logo-container center-block bottom-10">
      <svg-icon name="scout-logo" class="brand-icon"></svg-icon>
    </div>

    <div class="bold-weight bottom-5">
      * Profile data is not yet on Scout
    </div>

    <div class="bottom-15">
      Some profile data may not yet be available. Reviews data, matter data and rates data may still be available.
    </div>

    <div class="row">
      <div class="col-md-10 col-md-offset-1">
        <data-disclaimer-modal :firm-name="firmName" :lawyer-name="lawyerName"></data-disclaimer-modal>
      </div>
    </div>
  </div>
</template>

<script>
import DataDisclaimerModal from 'vue-app/scout/shared/data-disclaimer-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'DataDisclaimerPanel',

  components: {
    DataDisclaimerModal,
    SvgIcon
  },

  props: {
    firmName: {
      type: String,
      required: true
    },

    lawyerName: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .data-disclaimer {
    height: 203px;
    padding: 20px 25px;
    line-height: 1.3;
    background-color: $k-lighter-gray;

    .logo-container {
      max-width: 110px;
    }
  }
</style>
