<template>
  <boxed-content title="Relationship Partners" v-if="displayComponent">
    <div class="size-text-12px text-italic pill-gray-text tight-lines bottom-10">
      <span v-if="canEditProfile">Edit your firm&rsquo;s relationship partners for each client by selecting the relevant client from the dropdown menu below, then clicking &ldquo;Edit&rdquo;.</span>

      <span v-else>View your firm&rsquo;s relationship partners for each client by selecting from the dropdown menu below.</span>
    </div>

    <form role="form" class="a-form" novalidate>
      <label class="bold-weight">Client</label>

      <dropdown ref="dropdown" class="k-dropdown" v-model="dropdownOpen">
        <button type="button" role="button" id="k-toggle-button-relationship-partners-workspace" :class="['dropdown-toggle', { 'placeholder': !selectedWorkspace }]">
          <span v-if="!selectedWorkspace">Select Client</span>
          <span v-if="selectedWorkspace">{{ selectedWorkspace.name }}</span>
          <svg-icon name="caret-down" class="caret-down"></svg-icon>
          <svg-icon name="caret-up" class="caret-up"></svg-icon>
        </button>

        <template slot="dropdown" class="dropdown-menu" role="menu" aria-labelledby="k-toggle-button-relationship-partners-workspace">
          <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
            <li role="menuitem" class="menu-item" v-for="workspace in sortedRelationshipPartnerWorkspaces" :key="workspace.id" @click="selectWorkspace(workspace)">
              <div class="dropdown-row-inner">
                {{ workspace.name }}
              </div>
            </li>
          </div>
        </template>
      </dropdown>
    </form>

    <div class="top-30" v-if="selectedWorkspace">
      <div class="row tight-columns bold-weight">
        <div :class="{ 'col-sm-9': canEditProfile, 'col-xs-12': !canEditProfile }">
          Relationship Partners
        </div>

        <div class="col-sm-3 text-right-not-xs" v-if="canEditProfile">
          <edit-firm-relationship-partners
            :firm="firm"
            :workspace="selectedWorkspace"
            :on-save="save">
          </edit-firm-relationship-partners>
        </div>
      </div>

      <div class="top-5 bottom-20">
        <hr class="no-margin">
      </div>

      <loading-section name="firm-relationship-partners">
        <display-rules-data-wrapper v-if="dataIsPresentOrEditingAllowed(selectedWorkspace.lawyers)" :data="selectedWorkspace.lawyers">
          <lawyer-name-plate
            v-for="lawyer in selectedWorkspace.lawyers"
            class="relationship-partner"
            :key="`relationship-partner-${lawyer.id}`"
            :lawyer="lawyer"
            :hide-firm="true">
          </lawyer-name-plate>
        </display-rules-data-wrapper>

        <div v-else class="gray-text size-text-16px">
          No relationship partners
        </div>
      </loading-section>
    </div>
  </boxed-content>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import { flatten, sortBy } from 'lodash';
import EditFirmRelationshipPartners from './edit-firm-relationship-partners';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'FirmClientRelationshipPartners',

  components: {
    BoxedContent,
    DisplayRulesDataWrapper,
    LawyerNamePlate,
    EditFirmRelationshipPartners
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  mixins: [displayRules],

  props: {
    firm: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedWorkspace: null,
      dropdownOpen: false
    };
  },

  computed: {
    displayComponent() {
      const allRelationshipPartners = flatten(this.firm.relationshipPartnerWorkspaces.map(workspace => {
        return workspace.lawyers;
      }));

      return this.anyDataIsPresent([allRelationshipPartners]) || this.canEditProfile;
    },

    sortedRelationshipPartnerWorkspaces() {
      return sortBy(this.firm.relationshipPartnerWorkspaces, [workspace => workspace.name.toLowerCase()]);
    }
  },

  methods: {
    selectWorkspace(workspace) {
      this.selectedWorkspace = workspace;
    },

    isSelectedWorkspace(workspace) {
      return this.selectedWorkspace.id === workspace.id;
    },

    save(firmEdited) {
      const vueInstance = this;
      if (!vueInstance.canEditProfile) { return; }

      LoadingService.loading('firm-relationship-partners');

      this.onSave(firmEdited,
        (response) => {
          vueInstance.selectedWorkspace = response.relationshipPartnerWorkspaces.find((workspace) => workspace.id === this.selectedWorkspace.id);

          LoadingService.done('firm-relationship-partners');
        },
        () => {
          LoadingService.done('firm-relationship-partners');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  .relationship-partner:not(:last-child) {
    margin-bottom: 20px;
  }
</style>
