<template>
  <div class="titlebar-with-sections split-60-40">
    <div class="titlebar">
      <span class="user-pic-container right-20"><profile-photo :user="lawyer"></profile-photo></span>

      <div class="search-result-row">
        <div class="row tight-columns">
          <div :class="canAddToProposal ? 'col-sm-6' : 'col-sm-10'">
            <div class="size-text-24px text-ellipsis bottom-5">
              {{ lawyer.fullName }}
            </div>

            <div class="size-text-13px semibold-weight text-ellipsis">
              <span :class="[lawyer.accountStatus() === 'Available' ? 'green-text' : 'red-text']">{{ lawyer.accountStatus() }}<span v-if="lawyer.accountStatus() === 'Base Profile' && lawyer.invitedById"> (Invited)</span></span> &#8212; {{ startCase(lawyer.accountType) }}
            </div>
          </div>

          <div class="col-xs-6 col-sm-4 top-30-xs" v-if="canAddToProposal">
            <loading-section name="add-to-proposal">
              <div class="svg-button action-bar-button" @click.prevent="addToProposal()" v-if="!isAddedToProposal">
                <svg-icon name="add-item" class="base-icon bottom-5"></svg-icon>
                <div>Add to Proposal</div>
              </div>

              <div class="svg-button action-bar-button disabled" v-if="isAddedToProposal">
                <svg-icon name="added-item" class="base-icon bottom-5"></svg-icon>
                <div>Added to Proposal</div>
              </div>
            </loading-section>
          </div>

          <div class="col-xs-12 col-sm-2 text-right top-20-xs">
            <account-actions
              :lawyer="lawyer"
              :message-lawyer="messageLawyer"
              :add-lawyer-firm-to-proposal="addLawyerFirmToProposal"
              :impersonate="startImpersonation"
              :can-add-to-proposal="eligibleForProposal">
            </account-actions>

            <impersonate-modal
              :user="lawyer"
              :modal-trigger="impersonateLawyer"
              :on-cancel="stopImpersonation">
            </impersonate-modal>
          </div>
        </div>
      </div>
    </div>

    <div class="titlebar-switcher extra-padding text-center">
      <a href="" :class="{ 'selected': selectedTab === 'profile' }" @click.prevent="onSelect('profile')">Profile</a>
      <a href="" :class="{ 'selected': selectedTab === 'notes' }" @click.prevent="onSelect('notes')">Notes</a>
      <a href="" :class="{ 'selected': selectedTab === 'history' }" @click.prevent="onSelect('history')">History</a>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';
import AccountActions from 'vue-app/admin/lawyers/account-actions.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

import TextManipulation from 'vue-app/shared/mixins/text-manipulation';

import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'AccountTitlebar',

  components: {
    SvgIcon,
    ProfilePhoto,
    ImpersonateModal,
    AccountActions
  },

  mixins: [TextManipulation],

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    triggerMessagingFunc: {
      type: Function,
      default: () => {}
    },

    selectedTab: {
      type: String,
      required: true
    },

    addToProposalFunc: {
      type: Function,
      default: () => {}
    },

    addLawyerFirmToProposalFunc: {
      type: Function,
      default: () => {}
    },

    onSelect: {
      type: Function,
      default: () => {}
    },

    canAddToProposal: {
      type: Boolean,
      default: false
    },

    isAddedToProposal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      impersonateLawyer: false
    };
  },

  computed: {
    eligibleForProposal() {
      return this.canAddToProposal && !this.isAddedToProposal;
    }
  },

  methods: {
    startImpersonation() {
      this.impersonateLawyer = true;
    },

    stopImpersonation() {
      this.impersonateLawyer = false;
    },

    addToProposal() {
      this._callWithLoading(this.addToProposalFunc);
    },

    addLawyerFirmToProposal() {
      this._callWithLoading(this.addLawyerFirmToProposalFunc);
    },

    _callWithLoading(asyncable) {
      LoadingService.loading('add-to-proposal');

      asyncable().then(() => {
        LoadingService.done('add-to-proposal');
      });
    },

    messageLawyer() {
      this.triggerMessagingFunc([this.lawyer]);
    }
  }
};
</script>
