import { isEmpty, isNumber, map, some } from 'lodash';

const displayRules = {
  methods: {
    dataIsPresentOrEditingAllowed(data) {
      return this.dataIsPresent(data) || this.editingAllowed;
    },

    anyDataIsPresent(dataArray) {
      return some(
        map(dataArray, (data) => {
          return this.dataIsPresent(data);
        })
      );
    },

    dataIsPresent(data) {
      return isNumber(data) || !isEmpty(data);
    }
  }
};

export default displayRules;
