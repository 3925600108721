<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-7 text-uppercase">
          RFP Settings
        </div>

        <div class="col-sm-5">
          <div class="text-right-not-xs">
            <button v-if="!isEditing" @click="toggleIsEditing" class="pseudo-link-blue normal-weight" data-testid="edit-button">Edit</button>

            <div v-else class="d-flex gap-10 pull-right-not-xs">
              <button @click="saveWorkspace" class="pseudo-link-blue" data-testid="save-button-header">Save</button>
              <button @click="handleCancel" class="pseudo-link-gray normal-weight" data-testid="cancel-button-header">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            Allow users to send RFPs to Marketplace?
          </div>

          <div class="dark-gray-text">
            Enable your team to send RFPs into Priori’s Marketplace Network to find additional talent
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <span v-if="!isEditing" data-testid="marketplace-rfps-enabled">{{ workspace.marketplaceRfqsEnabled ? 'Yes' : 'No' }}</span>
          <toggle-button
            v-else
            :value="workspaceCopy.marketplaceRfqsEnabled"
            @click="toggleMarketplaceRfqsEnabled"
            true-text="Yes"
            false-text="No"
            data-testid="marketplace-rfps-enabled-toggle">
          </toggle-button>
        </div>
      </div>

      <div v-if="isEditing" class="row top-30">
        <div class="col-sm-5 col-md-4 col-lg-3">
          <loading-button
            @lb-click="saveWorkspace"
            name="updateWorkspace"
            lb-class="nv-button-blue"
            data-testid="save-button-main">
            Save
          </loading-button>
        </div>

        <div class="col-sm-5 col-md-4 col-lg-3 top-20-xs">
          <button
            @click="handleCancel"
            type="button"
            class="secondary-btn-blue"
            data-testid="cancel-button-main">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'RfpSettings',

  components: {
    ToggleButton,
    LoadingButton
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    updateWorkspace: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      workspaceCopy: { ...this.workspace }
    };
  },

  methods: {
    toggleIsEditing() {
      this.isEditing = !this.isEditing;

      if (this.isEditing) {
        this.workspaceCopy = { ...this.workspace };
      }
    },

    toggleMarketplaceRfqsEnabled() {
      this.workspaceCopy.marketplaceRfqsEnabled = !this.workspaceCopy.marketplaceRfqsEnabled;
    },

    saveWorkspace() {
      if (this.workspace.marketplaceRfqsEnabled === this.workspaceCopy.marketplaceRfqsEnabled) {
        this.toggleIsEditing();
        return;
      }

      LoadingService.around('updateWorkspace', () => {
        return this.updateWorkspace(this.workspaceCopy).then(() => {
          this.toggleIsEditing();
        });
      });
    },

    handleCancel() {
      this.toggleIsEditing();
    }
  }
};
</script>
