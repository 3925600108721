<template>
  <div class="row">
    <div class="col-sm-8">
      <firm-about class="bottom-30" :firm="firm"></firm-about>

      <firm-practice-areas class="bottom-30" :firm="firm"></firm-practice-areas>

      <firm-office-locations class="bottom-30" :firm="firm"></firm-office-locations>

      <firm-diversity class="bottom-30" :firm="firm"></firm-diversity>

      <firm-representative-matters :matters="firm.representativeMatters"></firm-representative-matters>

      <data-disclaimer-box class="empty-disclaimer" :firm-name="firm.name"></data-disclaimer-box>
    </div>

    <div class="col-sm-4 top-30-xs">
      <firm-status class="bottom-30" :firm="firm"></firm-status>

      <firm-relationship-partners class="bottom-30" :firm="firm"></firm-relationship-partners>

      <firm-awards class="bottom-30" :firm="firm"></firm-awards>

      <firm-highlights class="bottom-30" :firm="firm"></firm-highlights>

      <firm-leadership class="bottom-30" :firm="firm"></firm-leadership>

      <firm-sustainability :firm="firm"></firm-sustainability>
    </div>
  </div>
</template>

<script>
import FirmAbout from 'src/vue-app/scout/shared/law-firms/firm-about.vue';
import FirmPracticeAreas from 'src/vue-app/scout/shared/law-firms/firm-practice-areas.vue';
import FirmOfficeLocations from 'src/vue-app/scout/shared/law-firms/firm-office-locations.vue';
import FirmDiversity from 'src/vue-app/scout/shared/law-firms/firm-diversity.vue';
import FirmRepresentativeMatters from 'src/vue-app/scout/shared/law-firms/firm-representative-matters.vue';
import FirmRelationshipPartners from './firm-relationship-partners.vue';
import FirmAwards from 'src/vue-app/scout/shared/law-firms/firm-awards.vue';
import FirmLeadership from 'src/vue-app/scout/shared/law-firms/firm-leadership.vue';
import FirmHighlights from 'src/vue-app/scout/shared/law-firms/firm-highlights.vue';
import FirmSustainability from 'src/vue-app/scout/shared/law-firms/firm-sustainability.vue';
import FirmStatus from 'vue-app/scout/client/law-firms/firm-status.vue';
import DataDisclaimerBox from 'src/vue-app/scout/shared/data-disclaimer-box.vue';

export default {
  name: 'FirmOverview',

  components: {
    FirmAbout,
    FirmPracticeAreas,
    FirmOfficeLocations,
    FirmDiversity,
    FirmLeadership,
    FirmRepresentativeMatters,
    FirmRelationshipPartners,
    FirmAwards,
    FirmHighlights,
    FirmSustainability,
    FirmStatus,
    DataDisclaimerBox
  },

  props: {
    firm: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  .empty-disclaimer {
    display: none;

    &:only-child {
      display: block;
    }
  }
</style>
