<template>
  <form role="form" class="a-form" novalidate>
    <div class="text-right bottom-15 size-text-13px">
      <button
        class="pseudo-link-gray"
        @click.prevent="clearFilter">
        Clear Filter
      </button>
    </div>

    <div class="keyword-search-container">
      <input
        type="text"
        name="searchTerm"
        id="search-term"
        class="form-control keyword-search-input"
        placeholder="Search..."
        autocomplete="off"
        v-model="searchTerm">

      <typeahead
        v-model="selectedOption"
        target="#search-term"
        :data="filteredOptions"
        item-key="label"
        force-select
        force-clear
        :limit="6"
        @input="addTerm">
      </typeahead>

      <svg-icon name="search" class="base-icon keyword-search-icon"></svg-icon>
    </div>

    <div class="error-text top-10" v-if="noMatches">
      * No matching results
    </div>

    <div class="top-15">
      <div v-if="selectedTerms.length" class="check-options">
        <label
          v-for="(term, index) in selectedTerms"
          :key="term"
          class="check-option vertical medium-large"
          :for="`${field}-selected-${index}`">
          {{ term }}

          <input
            type="checkbox"
            :name="`selected${index}`"
            :id="`${field}-selected-${index}`"
            @change="removeTerm(term)"
            checked>

          <span class="check c-box"></span>
        </label>
      </div>
    </div>

    <div v-if="!hasSelectedTerms" class="missing-text size-text-13px top-15">
      Start typing to see options.
    </div>
  </form>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { has, some } from 'lodash';

export default {
  name: 'FilterTypeahead',

  components: {
    SvgIcon
  },

  props: {
    searchService: {
      type: Object,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    field: {
      type: String,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      searchTerm: '',
      selectedOption: { label: '' }
    };
  },

  computed: {
    filteredOptions() {
      return this.options.filter(option => !this.selectedTerms.includes(option.label));
    },

    selectedTerms() {
      return this.searchService.searchLogic.filters[this.field] || [];
    },

    hasSelectedTerms() {
      return has(this.searchService.searchLogic.filters, this.field);
    },

    noMatches() {
      if (!this.searchTerm) { return false; }

      return !some(this.options, option => option.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  },

  methods: {
    addTerm() {
      if (!this.selectedOption) { return; }

      this.searchService.addSearchParam(this.field, this.selectedOption.label);

      this.clearSearchTerm();
      this.selectedOption = { label: '' };

      this.onSearch();
    },

    removeTerm(term) {
      this.searchService.removeSearchParam(this.field, term);

      this.onSearch();
    },

    clearFilter() {
      this.clearSearchTerm();
      this.searchService.unsetSearchParam(this.field);
      this.onSearch();
    },

    clearSearchTerm() {
      this.searchTerm = '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "stylesheets/globals/variables";
@import "stylesheets/scout/variables";

.missing-text {
  color: $pill-gray;
}

.a-form label {
  font-weight: 400;
}

:deep {
  .open > .dropdown-menu {
    > li {
      > a {
        strong, b {
          color: $k-purple
        }
      }
    }
  }
}

:deep {
  .check-option {
    input:checked + .check.c-box {
      border-color: $purple-5;
      background-color: $purple-5;
    }
  }
}

</style>

