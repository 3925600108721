<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': canEditProfile }]">
          Rankings, Awards &amp; Certifications
        </div>

        <div class="col-sm-3 text-right-not-xs" v-if="canEditProfile">
          <edit-firm-rankings-awards-certifications :firm="firm" :on-save="onSave"></edit-firm-rankings-awards-certifications>
        </div>
      </div>
    </div>

    <div class="box-content">
      <display-rules-section-wrapper class="separated-list-items" :data="sortedRankings" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Rankings
        </div>

        <display-rules-data-wrapper :data="sortedRankings">
          <div v-for="(rankingData, index) in sortedRankings" :key="`${rankingData.name}-${index}`" class="row tight-columns list-spacing-10">
            <div class="col-xs-2">
              <svg-icon name="award" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10">
              {{ rankingData.name }} {{ rankingData.ranking }}
            </div>
          </div>
        </display-rules-data-wrapper>
      </display-rules-section-wrapper>

      <display-rules-section-wrapper class="separated-list-items" :data="firm.sanitizedAwards" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Awards
        </div>

        <collapsable-space>
          <display-rules-data-wrapper :data="firm.sanitizedAwards">
            <div class="row tight-columns list-spacing-10 award-list" v-for="(award, index) in firm.sanitizedAwards" :key="index">
              <div class="col-xs-2">
                <svg-icon name="award" class="base-icon"></svg-icon>
              </div>

              <div class="col-xs-10">
                <span v-html="removeWhiteSpace(enforceStylableFirstElement(award))" class="lists-to-csv"></span>
              </div>
            </div>
          </display-rules-data-wrapper>
        </collapsable-space>
      </display-rules-section-wrapper>

      <display-rules-section-wrapper class="separated-list-items" :data="firm.firmOwnerships" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Certifications
        </div>

        <display-rules-data-wrapper :data="firm.firmOwnerships">
          <ul>
            <li v-for="tag in firm.firmOwnerships" :key="tag.id">
              {{ tag.presentedName }}
            </li>
          </ul>
        </display-rules-data-wrapper>
      </display-rules-section-wrapper>

      <display-rules-section-wrapper class="separated-list-items" :data="firm.diversities" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Member and Signatory
        </div>

        <display-rules-data-wrapper :data="firm.diversities">
          <ul>
            <li v-for="tag in firm.diversities" :key="tag.id">
              {{ tag.presentedName }}
            </li>
          </ul>
        </display-rules-data-wrapper>
      </display-rules-section-wrapper>
    </div>
  </div>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesSectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditFirmRankingsAwardsCertifications from 'vue-app/scout/shared/law-firms/edit-firm-rankings-awards-certifications';
import CollapsableSpace from 'vue-app/shared/components/collapsable-space.vue';

export default {
  name: 'FirmAwards',

  components: {
    DisplayRulesSectionWrapper,
    DisplayRulesDataWrapper,
    EditFirmRankingsAwardsCertifications,
    CollapsableSpace
  },

  mixins: [displayRules],

  props: {
    firm: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  computed: {
    displayComponent() {
      const {
        amLawRanking,
        awards,
        diversities,
        firmOwnerships,
        globalRanking,
        rankings
      } = this.firm;

      return this.anyDataIsPresent(
        [
          amLawRanking,
          awards,
          diversities,
          firmOwnerships,
          globalRanking,
          rankings
        ]
      ) || this.canEditProfile;
    },

    sortedRankings() {
      let rankings = [];

      if (this.firm.amLawRanking) {
        rankings.push({ name: 'Am Law', ranking: this.firm.amLawRanking });
      }

      if (this.firm.globalRanking) {
        rankings.push({ name: 'Am Law Global', ranking: this.firm.globalRanking });
      }

      rankings = rankings.concat(
        [...this.firm.rankings].sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      );

      return rankings;
    }
  },

  methods: {
    // Ensures the first elements, if not lists, are either para or div
    // if they aren't, they can't be targeted for styling following lists
    enforceStylableFirstElement(string) {
      const indexOfFirstList = string.search(/<[u|o]l>/);
      const preListText = string.substring(0, indexOfFirstList);

      if (preListText.search(/^<div>.*<\/div>$/) >= 0 || preListText.search(/^<p>.*<\/p>$/) >= 0) {
        return string;
      }

      const replacement = `<div>${preListText}</div>`;
      return string.replace(preListText, replacement);
    },

    removeWhiteSpace(string) {
      return string.replace(/>\s+</g, '><');
    }
  }
};
</script>

<style scoped lang="scss">
@import "stylesheets/scout/lists";

.award-list {
  .lists-to-csv {
    overflow: hidden;

    :deep {
      & > ol:first-child, & > ol:first-child ~ ol {
        padding: 0;

        & > li > p { display: block; }

        & > li > ol {
          @extend %flattened-list;
          @extend %decimal-list;

          & > li > ol {
            @extend %parenthesized-list;
            @extend %roman-numeral-list;

            margin-left: 3px;
          }
        }
      }

      & > ul:first-child, & > ul:first-child ~ ul {
        @extend %headed-list;

        & > li > ul {
          @extend %flattened-list;
        }
      }

      & > p ~ ol, & > div ~ ol {
        @extend %flattened-list;
        @extend %decimal-list;

        & > li > ol {
          @extend %parenthesized-list;
          @extend %roman-numeral-list;

          margin-left: 3px;
        }
      }

      & > p ~ ul, & > div ~ ul {
        @extend %flattened-list;

        & > li > ul {
          @extend %parenthesized-list;

          margin-left: 3px;
        }
      }
    }
  }
}

ul {
  margin-bottom: 0;
  padding-left: 25px;

  li {
    padding-left: 5px;
    line-height: 1.4;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.base-icon {
  width: 16px;
  height: 16px;
}
</style>
