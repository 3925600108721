<template>
  <div id="messaging-center" ref="messagingCenter" class="messaging-center">
    <action-bar
      :on-refresh-messaging="refreshMessaging"
      :on-close-messaging="closeMessaging">
    </action-bar>

    <div :class="['conversation-container', { 'top-30': isLoadingConversation }]">
      <loading-section name="selectedConversation" :render-after-loading="true">
        <conversation-viewer
          v-if="hasSelectedConversation"
          :conversation="selectedConversation"
          :rfq-info="rfqInfo"
          :on-create-or-update-message="createOrUpdateMessage">
        </conversation-viewer>
      </loading-section>
    </div>

    <div v-if="isVisible" class="close-message-center-button">
      <button type="button" @click="closeMessaging"><svg-icon name="arrow-right" class="base-icon right-10"></svg-icon></button>
    </div>
  </div>
</template>

<script>
import ActionBar from 'vue-app/mailboxer/scout/action-bar.vue';
import ConversationViewer from 'vue-app/mailboxer/scout/conversation-viewer.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import MessagingService from 'services/scout/messaging-service.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'MessagingCenter',

  components: {
    ActionBar,
    ConversationViewer,
    LoadingSection,
    SvgIcon
  },

  data() {
    return {
      messaging: MessagingService
    };
  },

  computed: {
    isVisible() {
      return this.messaging.isVisible;
    },

    selectedConversation() {
      return this.messaging.selectedConversation;
    },

    hasSelectedConversation() {
      return !!this.messaging.selectedConversation;
    },

    isLoadingConversation() {
      return LoadingService.isLoading('selectedConversation');
    },

    rfqInfo() {
      return {
        rfqId: this.messaging.rfq.id,
        rfqName: this.messaging.rfq.name,
        lawFirmName: this.messaging.quote.lawFirm.name
      };
    }
  },

  methods: {
    closeMessaging() {
      this.messaging.closeMessaging();
    },

    refreshMessaging() {
      return this.messaging.refresh();
    },

    createOrUpdateMessage(isSending = false) {
      return LoadingService.around('receipts', () => {
        return this.messaging.createOrUpdateMessage(isSending);
      });
    }
  }
};
</script>
