<template>
  <div class="normal-weight pill-gray-text">
    {{ formatClosedStatus(rfq.closingReason) }}{{ closingReason(rfq.customClosingReason) }}
  </div>
</template>

<script>
export default {
  name: 'ClosingReason',

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatClosedStatus(statusCode) {
      if (statusCode === null) { return ''; }

      return statusCode
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    closingReason(reason) {
      if (this.rfq.closingReason !== 'other') { return ''; }
      if (reason === null) { return ''; }
      return ` — ${reason}`;
    }
  }
};
</script>
