<template>
  <div class="row">
    <div class="col-sm-8">
      <lawyer-biography class="bottom-30" :lawyer="lawyer"></lawyer-biography>

      <lawyer-general class="bottom-30" :lawyer="lawyer"></lawyer-general>

      <lawyer-practice-areas class="bottom-30" :lawyer-practice-areas="lawyer.lawyerPracticeAreas"></lawyer-practice-areas>

      <lawyer-representative-matters class="bottom-30" :matters="lawyer.representativeMatters" :lawyer="lawyer"></lawyer-representative-matters>

      <lawyer-articles :lawyer="lawyer"></lawyer-articles>

      <data-disclaimer-box class="empty-disclaimer" :firm-name="lawyer.firmName" :lawyer-name="lawyer.fullName"></data-disclaimer-box>
    </div>

    <div class="col-sm-4 top-30-xs">
      <lawyer-law-firm-status class="bottom-30" :lawyer="lawyer"></lawyer-law-firm-status>

      <lawyer-contact-info class="bottom-30" :lawyer="lawyer"></lawyer-contact-info>

      <lawyer-diversity-inclusion v-if="lawyer.diversityDisclosureEnabledForAllCountries" class="bottom-30" :lawyer="lawyer"></lawyer-diversity-inclusion>

      <lawyer-highlights class="bottom-30" :lawyer="lawyer"></lawyer-highlights>

      <lawyer-awards class="bottom-30" :lawyer="lawyer"></lawyer-awards>

      <lawyer-experience-card class="bottom-30" :lawyer="lawyer"></lawyer-experience-card>

      <lawyer-affiliations class="bottom-30" :lawyer="lawyer"></lawyer-affiliations>

      <lawyer-statistics :lawyer="lawyer"></lawyer-statistics>
    </div>
  </div>
</template>

<script>
import LawyerArticles from 'vue-app/scout/shared/lawyers/lawyer-articles.vue';
import LawyerBiography from 'vue-app/scout/shared/lawyers/lawyer-biography.vue';
import LawyerLawFirmStatus from 'vue-app/scout/shared/lawyers/lawyer-law-firm-status.vue';
import LawyerContactInfo from 'vue-app/scout/shared/lawyers/lawyer-contact-info.vue';
import LawyerExperienceCard from 'vue-app/scout/shared/lawyers/lawyer-experience.vue';
import LawyerGeneral from 'vue-app/scout/shared/lawyers/lawyer-general.vue';
import LawyerHighlights from 'vue-app/scout/shared/lawyers/lawyer-highlights.vue';
import LawyerAwards from 'vue-app/scout/shared/lawyers/lawyer-awards.vue';
import LawyerAffiliations from 'vue-app/scout/shared/lawyers/lawyer-affiliations.vue';
import LawyerPracticeAreas from 'vue-app/scout/shared/lawyers/lawyer-practice-areas.vue';
import LawyerRepresentativeMatters from 'vue-app/scout/shared/lawyers/lawyer-representative-matters.vue';
import LawyerStatistics from 'vue-app/scout/shared/lawyers/lawyer-statistics.vue';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import LawyerDiversityInclusion from 'vue-app/scout/shared/lawyers/lawyer-diversity-inclusion.vue';
import DataDisclaimerBox from 'vue-app/scout/shared/data-disclaimer-box.vue';

export default {
  name: 'LawyerExperience',

  components: {
    LawyerArticles,
    LawyerBiography,
    LawyerLawFirmStatus,
    LawyerContactInfo,
    LawyerDiversityInclusion,
    LawyerExperienceCard,
    LawyerGeneral,
    LawyerHighlights,
    LawyerAwards,
    LawyerAffiliations,
    LawyerPracticeAreas,
    LawyerRepresentativeMatters,
    LawyerStatistics,
    DataDisclaimerBox
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  .empty-disclaimer {
    display: none;

    &:only-child {
      display: block;
    }
  }
</style>
