<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Client Activity
    </div>

    <div v-for="possibleLawyer in notRetiredPossibleLawyers" :key="`possibleLawyer-${possibleLawyer.id}`">
      <div class="box-content">
        <div class="semibold-weight bottom-15">
          {{ longName(possibleLawyer) }}
        </div>

        <div v-if="possibleLawyer.rejected()">
          {{ shortName(possibleLawyer) }} {{ possibleLawyer.rejectedByClient() ? 'was rejected by the client' : 'rejected this project' }}.
        </div>

        <div v-if="possibleLawyer.selected()">
          {{ shortName(possibleLawyer) }} was hired.
        </div>

        <div v-if="!possibleLawyer.selected() && !possibleLawyer.rejected()">
          <span class="dark-gray-text text-italic" v-if="!possibleLawyer.appointment && !possibleLawyer.rateSentStatus()">No activity yet</span>

          <span v-if="appointmentStatus(possibleLawyer) === 'client_suggesting'">The client proposed times for a consultation and is waiting for {{ shortName(possibleLawyer) }} to confirm availability.</span>

          <span v-if="appointmentStatus(possibleLawyer) === 'lawyer_suggesting'">{{ shortName(possibleLawyer) }} suggested alternative times for a consultation. Waiting for client to choose from the suggested times.</span>

          <span v-if="appointmentStatus(possibleLawyer) === 'scheduled'">Consultation is scheduled<span v-if="possibleLawyer.appointment.scheduledConsultation()"> for {{ filteredDate(possibleLawyer.appointment.scheduledConsultation().startsAt) }}</span>.</span>

          <span v-if="appointmentStatus(possibleLawyer) === 'needs_rescheduling'">The consultation needs to be rescheduled.</span>

          <span v-if="appointmentStatus(possibleLawyer) === 'completed'">{{ shortName(possibleLawyer) }} completed a consultation with the client and needs to send a proposal/rate(s).</span>

          <span v-if="possibleLawyer.rateSentStatus()">{{ shortName(possibleLawyer) }} sent a proposal/rate(s) to client. Waiting for client to hire.</span>
        </div>
      </div>

      <hr class="kenny-hr no-margin">
    </div>

    <div class="box-content symmetrical" v-if="!proposal.possibleLawyers.length">
      No activity
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';

export default {
  name: 'ProposalClientActivity',

  props: {
    proposal: {
      type:    Object,
      default: () => { return { possibleLawyers: [] }; }
    }
  },

  computed: {
    notRetiredPossibleLawyers() {
      return this.proposal.possibleLawyers.filter(possibleLawyer => !possibleLawyer.retired);
    }
  },

  methods: {
    firmName(possibleLawyer) {
      return (possibleLawyer.lawFirm || {}).name;
    },

    longName(possibleLawyer) {
      return this.firmName(possibleLawyer) || possibleLawyer.lawyer.fullName;
    },

    shortName(possibleLawyer) {
      return this.firmName(possibleLawyer) || possibleLawyer.lawyer.firstName;
    },

    filteredDate(date) {
      return DateFilter.longWithAt(date);
    },

    appointmentStatus(possibleLawyer) {
      if (!possibleLawyer.appointment) { return null; }
      return possibleLawyer.appointment.possibleLawyerStatus();
    }
  }
};
</script>
