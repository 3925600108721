<template>
  <div class="shadowed-box">
    <div class="box-content">
      <div class="bottom-15">
        <div class="semibold-weight">
          Invite Team Member
        </div>

        <div>
          <a href="" @click.prevent="onComposeInvite">Send Invite</a>
        </div>
      </div>

      <div v-if="showInviteUrlLink" class="bottom-15">
        <div class="semibold-weight">
          Invite URL
        </div>

        <div>
          <copy-to-clipboard :text-to-copy="inviteUrl" tooltip-text="Invite link copied to clipboard">
            Copy Invite URL
          </copy-to-clipboard>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Create Admin RFP
        </div>

        <div>
          <a :href="createRfpPath" target="_self">Create RFP</a>
        </div>
      </div>

      <div>
        <div class="semibold-weight">
          Merge Team Member
        </div>

        <div>
          <workspace-merge-client :workspace="workspace" :on-client-merged="onClientMerged"></workspace-merge-client>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyToClipboard from 'vue-app/shared/components/copy-to-clipboard.vue';
import WorkspaceMergeClient from 'vue-app/admin/workspaces/workspace-merge-client.vue';

export default {
  name: 'WorkspaceOptions',

  components: {
    CopyToClipboard,
    WorkspaceMergeClient
  },

  props: {
    inviteUrl: {
      type:     String,
      required: true
    },

    createRfpPath: {
      type:     String,
      required: true
    },

    workspace: {
      type:     Object,
      required: true
    },

    onComposeInvite: {
      type:     Function,
      required: true
    },

    onClientMerged: {
      type:     Function,
      required: true
    }
  },

  computed: {
    showInviteUrlLink() {
      return !this.workspace.ssoEnabled && !this.workspace.isScout;
    }
  }
};
</script>
