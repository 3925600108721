<template>
  <div class="shadowed-box settings-box">
    <div class="box-header text-uppercase">
      Law Firm Tags
    </div>

    <div class="box-content">
      <div class="size-text-14px bold-weight bottom-5">
        View/Manage Law Firm Tags
      </div>

      <div class="dark-gray-text">
        Admin users can view and manage descriptive law firm tags here. Use the edit functionality below to create and edit law firm tag names and assign law firm tag colors. Use the drag and drop functionality to prioritize the order in which the law firm tags appear on law firm and associated lawyer profiles. Administrators can assign law firm tags to the applicable law firm on the Law Firms page.
      </div>

      <div class="top-30">
        <scout-tag-editing-modal
          :scout-tag="scoutTag"
          :on-create="onCreateTag"
          :practice-areas="practiceAreas">
        </scout-tag-editing-modal>
      </div>

      <hr class="top-30 bottom-30">

      <div class="size-text-14px bold-weight">
        General Tags
      </div>

      <loading-section name="scoutTags">
        <div class="droppable-scout-tag" v-droppable="{ id: null }" @on-drop="reorderScoutTags"></div>
        <collapsable-items
          :items="generalTags"
          :items-limit="initialDisplayCount"
          :show-all-button-text="showAllButtonText('General')">
          <template #items="{ renderingItems }">
            <div
              v-for="tag in renderingItems"
              :key="tag.id"
              v-droppable="{ id: tag.id }"
              v-draggable="{ id: tag.id }"
              class="draggable-scout-tag"
              @on-drop="reorderScoutTags">
              <scout-tag-editing-row
                :scout-tag="tag"
                :on-update="onUpdateTag"
                :on-delete="onDeleteTag">
              </scout-tag-editing-row>
            </div>
          </template>
        </collapsable-items>
      </loading-section>

      <div v-if="!hasGeneralTags" class="missing-text">
        No tags added
      </div>

      <hr class="top-30 bottom-30">

      <div class="size-text-14px bold-weight bottom-20">
        Practice Area Tags
      </div>

      <loading-section name="scoutTags">
        <collapsable-items
          :items="practiceAreaTags"
          :items-limit="initialDisplayCount"
          :show-all-button-text="showAllButtonText('Practice Area')">
          <template #items="{ renderingItems }">
            <div
              v-for="tag in renderingItems"
              :key="tag.id"
              class="bottom-20">
              <scout-tag-editing-row
                :scout-tag="tag"
                :on-update="onUpdateTag"
                :on-delete="onDeleteTag"
                :can-reorder="false"
                :practice-areas="practiceAreas">
              </scout-tag-editing-row>
            </div>
          </template>
        </collapsable-items>
      </loading-section>

      <div v-if="!hasPracticeAreaTags" class="missing-text">
        No tags added
      </div>

      <hr class="top-30 bottom-30">

      <div class="size-text-14px bold-weight bottom-20">
        Location Tags
      </div>

      <loading-section name="scoutTags">
        <collapsable-items
          :items="locationTags"
          :items-limit="initialDisplayCount"
          :show-all-button-text="showAllButtonText('Location')">
          <template #items="{ renderingItems }">
            <div
              v-for="tag in renderingItems"
              :key="tag.id"
              class="bottom-20">
              <scout-tag-editing-row
                :scout-tag="tag"
                :on-update="onUpdateTag"
                :on-delete="onDeleteTag"
                :can-reorder="false">
              </scout-tag-editing-row>
            </div>
          </template>
        </collapsable-items>
      </loading-section>

      <div v-if="!hasLocationTags" class="missing-text">
        No tags added
      </div>
    </div>
  </div>
</template>

<script>
import CollapsableItems from 'vue-app/shared/components/collapsable-items.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ScoutTagEditingRow from 'vue-app/scout/client/settings/scout-tag-editing-row.vue';
import ScoutTagEditingModal from 'vue-app/scout/client/settings/scout-tag-editing-modal.vue';
import Draggable from 'vue-app/shared/directives/draggable.js';
import Droppable from 'vue-app/shared/directives/droppable.js';
import { cloneDeep, pull, orderBy } from 'lodash';

export default {
  name: 'ScoutTagEditingPanel',

  directives: {
    Draggable,
    Droppable
  },

  components: {
    ScoutTagEditingRow,
    ScoutTagEditingModal,
    CollapsableItems,
    LoadingSection
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    },

    onOrderUpdated: {
      type: Function,
      required: true
    },

    onCreateTag: {
      type: Function,
      required: true
    },

    onUpdateTag: {
      type: Function,
      required: true
    },

    onDeleteTag: {
      type: Function,
      required: true
    },

    practiceAreas: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      scoutTag: {},
      scoutTagsCopy: cloneDeep(this.scoutTags),
      initialDisplayCount: 5
    };
  },

  computed: {
    hasGeneralTags() {
      return this.generalTags.length > 0;
    },

    hasLocationTags() {
      return this.locationTags.length > 0;
    },

    hasPracticeAreaTags() {
      return this.practiceAreaTags.length > 0;
    },

    generalTags() {
      return orderBy(
        this.scoutTagsCopy.filter(tag => tag.tagType === 'general'),
        'position'
      );
    },

    locationTags() {
      return this.scoutTagsCopy.filter(tag => tag.tagType === 'location');
    },

    practiceAreaTags() {
      return this.scoutTagsCopy.filter(tag => tag.tagType === 'practice_area');
    }
  },

  watch: {
    scoutTags() {
      this.scoutTagsCopy = cloneDeep(this.scoutTags);
    }
  },

  methods: {
    reorderScoutTags(evt) {
      const draggedId = evt.detail.draggedId;
      const droppedId = evt.detail.droppedId;
      const order = this.generalTags.map((scoutTag) => scoutTag.id);

      if (draggedId === droppedId) { return; }
      if (order.indexOf(draggedId) === 0 && isNaN(droppedId)) { return; }

      pull(order, draggedId);

      order.splice(order.indexOf(droppedId) + 1, 0, draggedId);

      const scoutTags = order.map((scoutTagId, index) => {
        return { id: scoutTagId, position: index + 1 };
      });

      this.onOrderUpdated(scoutTags);
    },

    showAllButtonText(tagType) {
      return `Show all ${tagType} tags`;
    }
  }
};

</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .yellow-message-box .message {
    font-weight: normal;
  }

  .draggable-scout-tag {
    margin-bottom: 20px;

    &:hover {
      cursor: grab;
    }

    &.dragging {
      opacity: .4;
    }

    &.dragover {
      margin-bottom: 9px;
      padding-bottom: 9px;
      border-bottom: 2px solid $k-blue;
    }

    &.dragging.dragover {
      margin-bottom: 20px;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .droppable-scout-tag {
    height: 20px;
    min-height: 20px;

    &.dragover {
      margin-top: -10px;
      margin-bottom: 10px;
      border-bottom: 2px solid $k-blue;
    }
  }
</style>
