<template>
  <div class="billing-note-row">
    <div>
      <a :href="`/admin-app/workspaces/${billingNote.workspace.id}`" v-if="billingNote.workspace">{{ billingNote.workspace.name }}</a>
      <span v-else>{{ billingNote.company || '&mdash;' }}</span>
    </div>

    <div>
      <a :href="billingNote.billingApplication.websiteLink" target="_blank" v-if="billingNote.billingApplication">{{ billingNote.billingApplication.name }}</a>
      <a :href="billingNote.websiteLink" target="_blank" v-if="!billingNote.billingApplication && billingNote.websiteLink">{{ billingNote.software || '&mdash;' }}</a>
      <span v-if="!billingNote.billingApplication && !billingNote.websiteLink">{{ billingNote.software || '&mdash;' }}</span>
    </div>

    <div>
      <span class="simple-text">{{ billingNote.submissionRequirements }}</span>
    </div>

    <div>
      <span class="simple-text">{{ billingNote.additionalNotes }}</span>
    </div>

    <div>
      <select :name="`select-${billingNote.id}`" :id="`select-${billingNote.id}`" v-model="selectedOption" @change="selectAction">
        <option value="null"></option>

        <option value="edit" :name="`edit-${billingNote.id}`" :id="`edit-${billingNote.id}`">
          Edit
        </option>

        <option value="delete" :name="`delete-${billingNote.id}`" :id="`delete-${billingNote.id}`">
          Delete
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingNoteListItem',

  props: {
    billingNote: {
      type: Object,
      required: true
    },

    onEditBillingNote: {
      type: Function,
      required: true
    },

    onDeleteBillingNote: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedOption: null
    };
  },

  methods: {
    selectAction() {
      switch (this.selectedOption) {
        case 'edit':
          this.onEditBillingNote(this.billingNote);
          break;
        case 'delete':
          this.onDeleteBillingNote(this.billingNote);
          break;
      }

      this.selectedOption = null;
    }
  }
};
</script>
