<template>
  <div>
    <div class="titlebar-sm">
      <div class="medium-page-heading top-2">
        Lawyer Leads
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <lead-search
            :search-service="searchService"
            :current-search="currentSearch">
          </lead-search>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerLeadSearchService from 'vue-app/admin/shared/services/lawyer-lead-search-service';
import LeadSearch from './lead-search.vue';

export default {
  name: 'AdminLawyerLeadsIndex',

  components: {
    LeadSearch
  },

  data() {
    return {
      searchService: new LawyerLeadSearchService(),
      currentSearch: {}
    };
  },

  mounted() {
    this.currentSearch = this.searchService.searchLogic;
  }
};
</script>
