<template>
  <div>
    <div class="row tight-columns bottom-10" v-show="!filtersHidden" v-if="!noResults">
      <div class="col-sm-9">
        <div class="inline-block bottom-10 right-5" v-for="filter in visibleFilters">
          <search-filter
            :search-service="searchService"
            :filter-service="filterService"
            :filter-params="filter"
            :on-open="onFilterOpen"
            :on-search="onSearch">
          </search-filter>
        </div>
      </div>

      <div class="col-sm-3 text-right-not-xs">
        <dropdown ref="addFilterDropdown" :not-close-elements="dropdownElements" class="k-dropdown add-filter-dropdown pull-right-not-xs" v-model="filterDropdownOpen">
          <button type="button" class="filter-button add-filter" @click="onFilterOpen">Add Filter</button>

          <template #dropdown>
            <form role="form" name="form" class="filter-dropdown-content a-form" novalidate>
              <div :class="{ 'fade-scroll-container': isScrollable, 'scrolled-up': isScrolledUp, 'scrolled-down': isScrolledDown }">
                <div ref="scrollable" @scroll="checkScroll" class="fade-scrollable">
                  <div v-for="(filterGroup, groupIndex) in filterGroups" :key="filterGroup.id" class="list-spacing-15">
                    <div class="semibold-weight">
                      {{ filterGroup.label }}
                    </div>

                    <div class="top-5 bottom-5">
                      <hr class="no-margin">
                    </div>

                    <label class="check-option vertical medium-large" :for="`${groupIndex}-${index}`" v-for="(filter, index) in filterGroup.filters">
                      {{ filter.label }}
                      <input type="checkbox" :name="`${groupIndex}${index}`" :id="`${groupIndex}-${index}`" v-model="filterParams[filter.slug].visible" @change="visibilityChanged(filter.slug)">
                      <span class="check c-box"></span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </dropdown>
      </div>
    </div>

    <div class="row tight-columns" v-if="showResults">
      <div class="col-sm-9">
        <span class="semibold-weight">{{ searchService.count() }}</span> {{ $pluralize('result', searchService.count()) }}
      </div>

      <div class="col-sm-3 text-right-not-xs">
        <a href="" class="dark-gray-link" @click.prevent="onRestart" v-if="allowRestart">Start New Search</a>
        <a href="" class="dark-gray-link" @click.prevent="toggleFilters" v-if="!noResults && !allowRestart">{{ filtersHidden ? 'Show' : 'Hide' }} Filters</a>
      </div>
    </div>
  </div>
</template>

<script>
import FilterService from 'vue-app/shared/services/filter-service.js';
import SearchFilter from 'vue-app/scout/shared/searches/search-filter.vue';
import checkScroll from 'vue-app/shared/mixins/check-scroll.js';
import { filter, includes, pull, reduce, some, transform } from 'lodash';

export default {
  name: 'FiltersLawyers',

  components: {
    SearchFilter
  },

  mixins: [checkScroll],

  props: {
    initHidden: {
      type: Boolean,
      default: false
    },

    noResults: {
      type: Boolean,
      default: false
    },

    searchService: {
      type: Object,
      required: true
    },

    onFilterOpen: {
      type: Function,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    },

    onRestart: {
      type: Function,
      required: false
    },

    showResults: {
      type: Boolean,
      default: true
    },

    searchDefinition: {
      type: Object,
      required: true
    }
  },

  data() {
    const filterSets = this.searchService.filterSets;

    this.searchDefinition.filterParams.lawyers = reduce(filterSets, (result, set) => {
      return { ...result, ...this.searchDefinition.filterParams.lawyers[set] };
    }, {});

    const visibleFilterKeys = filter(this.searchDefinition.defaultFilters.lawyers, (slug) => {
      return some(this.searchDefinition.filterParams.lawyers, (param, key) => (key === slug));
    });

    const data = {
      dropdownElements: [],
      filterDropdownOpen: false,
      filtersHidden: this.initHidden,
      visibleFilterKeys: visibleFilterKeys,
      filterParams: this.searchDefinition.filterParams.lawyers,
      allowRestart: !this.initHidden && !this.searchService.isSavedSearch(),
      visibleFilters: []
    };

    data.visibleFilterKeys.forEach(slug => {
      data.filterParams[slug].visible = true;
      data.visibleFilters.push(data.filterParams[slug]);
    });

    return data;
  },

  computed: {
    filterService() {
      return new FilterService(this.searchService.workspaceId, this.searchService.lawFirmBridgeId);
    },

    groupedParams() {
      return transform(this.searchDefinition.filterParams.lawyers, (result, v, k) => {
        result[v.group].push({ slug: k, label: v.label });
      }, { companyData: [], firmData: [] });
    },

    filterGroups() {
      const sortByLabel = (a, b) => (a.label > b.label) ? 1 : -1;

      return [
        {
          id: 'company-data-option',
          label: 'Filter Your Company’s Data',
          filters: this.groupedParams.companyData.toSorted(sortByLabel)
        },
        {
          id: 'firm-data-option',
          label: 'Filter Law Firm Data',
          filters:  this.groupedParams.firmData.toSorted(sortByLabel)
        }
      ];
    }
  },

  mounted() {
    this.dropdownElements.push(this.$refs.addFilterDropdown.$el);
  },

  methods: {
    toggleFilters() {
      this.filtersHidden = !this.filtersHidden;
      this.onFilterOpen();
    },

    visibilityChanged(slug) {
      const wasVisible = includes(this.visibleFilterKeys, slug);
      const setVisible = this.filterParams[slug].visible;

      if (!wasVisible && setVisible) {
        this.visibleFilterKeys.push(slug);
        this.visibleFilters.push(this.filterParams[slug]);
      }
      else if (wasVisible && !setVisible) {
        const field = this.filterParams[slug].field;
        pull(this.visibleFilterKeys, slug);
        pull(this.visibleFilters, this.filterParams[slug]);

        if (slug === 'location') {
          this.searchService.unsetFilter('geo_locations');
          this.searchService.unsetFilter('location');
        }
        else {
          this.searchService.unsetFilter(field);
        }
        this.onSearch();
      }
    }
  }
};
</script>
