<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-8 col-lg-5 text-uppercase">
          Documents
        </div>

        <div class="col-sm-4 col-lg-7 text-right-not-xs">
          <div v-if="editingAllowed">
            <edit-document-modal modal-title="Add Document" :on-save="save">
              <template #modal-trigger="{ openModal }">
                <a href="" @click.prevent="openModal">+ Add<span class="visible-xs-inline visible-lg-inline"> Document</span></a>
              </template>
            </edit-document-modal>
          </div>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <loading-section name="firmDocuments">
        <firm-document
          v-for="document in documents"
          class="list-spacing-20"
          :key="document.id"
          :document="document"
          :on-save="update"
          :on-delete="deleteDocument"
          :editing-allowed="editingAllowed">
        </firm-document>

        <div v-if="!documents.length" class="gray-text size-text-16px">
          No Documents
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import EditDocumentModal from 'vue-app/scout/client/law-firms/edit-document-modal.vue';
import FirmDocument from 'vue-app/scout/client/law-firms/firm-document.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

export default {
  name: 'FirmDocuments',

  components: {
    EditDocumentModal,
    FirmDocument,
    LoadingSection
  },

  props: {
    documents: {
      type: Array,
      required: true
    },

    editingAllowed: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: true
    },

    onUpdate: {
      type: Function,
      required: true
    },

    onDelete: {
      type: Function,
      required: true
    }
  },

  methods: {
    save(document) {
      if (!this.editingAllowed) { return; }
      return this.onSave(document);
    },

    update(document) {
      if (!this.editingAllowed) { return; }
      return this.onUpdate(document);
    },

    deleteDocument(documentId) {
      if (!this.editingAllowed) { return; }
      return this.onDelete(documentId);
    }
  }
};
</script>
