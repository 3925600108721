<template>
  <div>
    <div class="titlebar-sm">
      <div class="medium-page-heading top-2">
        Call Tracker
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="shadowed-box">
            <div class="box-content">
              <div class="table-responsive">
                <table class="table fixed-table auto-height no-margin">
                  <thead>
                    <th>Matter Name</th>
                    <th>Client</th>
                    <th>Lawyer</th>
                    <th>Status</th>
                    <th>Starts At</th>
                    <th>Type</th>
                    <th>Details</th>
                  </thead>

                  <tbody>
                    <td>
                      <a :href="`/admin-app/requests/${appointment.requestId}`">{{ appointment.requestName }}</a>
                    </td>

                    <td>
                      <div>
                        {{ clientParticipant.firstName }} {{ clientParticipant.lastName }}
                      </div>

                      <div>
                        {{ clientParticipant.phoneNumber }}
                      </div>

                      <div>
                        <span class="simple-text">{{ clientParticipant.email }}</span>
                      </div>
                    </td>

                    <td>
                      <div>
                        {{ lawyerParticipant.fullName }}
                      </div>

                      <div>
                        {{ lawyerParticipant.officeNumber }}
                      </div>

                      <div>
                        <span class="simple-text">{{ lawyerParticipant.email }}</span>
                      </div>
                    </td>

                    <td>
                      <span class="simple-text">{{ appointmentStatus }}</span>
                    </td>

                    <td>
                      {{ appointmentStartsAt }}
                    </td>

                    <td>
                      {{ appointmentType }}
                    </td>

                    <td>
                      <div v-if="isTrackingActivity">
                        <div v-if="!lawyerEntered && !clientEntered">
                          No activity
                        </div>

                        <div v-if="lawyerEntered">
                          <div class="size-text-12px semibold-weight">
                            Lawyer Entered:
                          </div>

                          {{ lawyerEnteredAt }}
                        </div>

                        <div v-if="clientEntered">
                          <div class="size-text-12px semibold-weight">
                            Client Entered:
                          </div>

                          {{ clientEnteredAt }}
                        </div>

                        <div v-if="conferenceStarted">
                          <div class="size-text-12px semibold-weight">
                            Consultation Started:
                          </div>

                          {{ conferenceStartedAt }}
                        </div>

                        <div v-if="clientLeft">
                          <div class="size-text-12px semibold-weight">
                            Client Left:
                          </div>

                          {{ clientLeftAt }}
                        </div>

                        <div v-if="lawyerLeft">
                          <div class="size-text-12px semibold-weight">
                            Lawyer Left:
                          </div>

                          {{ lawyerLeftAt }}
                        </div>

                        <div v-if="conferenceEnded">
                          <div class="size-text-12px semibold-weight">
                            Consultation Ended:
                          </div>

                          {{ conferenceEndedAt }}
                        </div>
                      </div>

                      <div v-if="location">
                        <div v-if="location.title.length">
                          {{ location.title }}
                        </div>

                        <div v-if="location.address.length">
                          {{ location.address }}
                        </div>

                        <div v-if="location.city.length && location.state.length">
                          {{ location.city }}, {{ location.state }}
                        </div>
                        <div v-else-if="location.city.length">
                          {{ location.city }}
                        </div>
                        <div v-else-if="location.state.length">
                          {{ location.state }}
                        </div>

                        <div v-if="location.zip.length">
                          {{ location.zip }}
                        </div>

                        <div v-if="location.note.length">
                          NOTE: {{ location.note }}
                        </div>
                      </div>
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import { startCase } from 'lodash';

export default {
  name: 'AdminAppointmentsShow',

  props: {
    appointment: {
      type: Object,
      required: true
    }
  },

  computed: {
    client() {
      return this.appointment.clientParticipant();
    },

    clientParticipant() {
      return this.client.participant;
    },

    clientActivity() {
      return this.appointment.participantActivityFor(this.client);
    },

    lawyer() {
      return this.appointment.lawyerParticipant();
    },

    lawyerParticipant() {
      return this.lawyer.participant;
    },

    lawyerActivity() {
      return this.appointment.participantActivityFor(this.lawyer);
    },

    isTrackingActivity() {
      return this.appointment.type === 'phone' || this.appointment.type === 'video';
    },

    appointmentStatus() {
      return startCase(this.appointment.status);
    },

    appointmentType() {
      return startCase(this.appointment.type);
    },

    location() {
      return this.appointment.location;
    },

    appointmentStartsAt() {
      return DateFilter.short(this.appointment.startsAt);
    },

    lawyerEntered() {
      return !!this.lawyerActivity.enteredAt;
    },

    lawyerEnteredAt() {
      return DateFilter.shortTime(this.lawyerActivity.enteredAt);
    },

    clientEntered() {
      return !!this.clientActivity.enteredAt;
    },

    clientEnteredAt() {
      return DateFilter.shortTime(this.clientActivity.enteredAt);
    },

    conferenceStarted() {
      return !!this.appointment.conferenceStartedAt;
    },

    conferenceStartedAt() {
      return DateFilter.shortTime(this.appointment.conferenceStartedAt);
    },

    lawyerLeft() {
      return !!this.lawyerActivity.leftAt;
    },

    lawyerLeftAt() {
      return DateFilter.shortTime(this.lawyerActivity.leftAt);
    },

    clientLeft() {
      return !!this.clientActivity.leftAt;
    },

    clientLeftAt() {
      return DateFilter.shortTime(this.clientActivity.leftAt);
    },

    conferenceEnded() {
      return !!this.appointment.conferenceEndedAt;
    },

    conferenceEndedAt() {
      return DateFilter.shortTime(this.appointment.conferenceEndedAt);
    }
  }
};
</script>
