<template>
  <div class="row tight-columns size-text-13px">
    <div class="col-sm-9">
      <priori-pagination
        :current-page="searchService.page"
        :total-entries="searchService.totalCount"
        :per-page="perPage"
        :show-page-info="false"
        @change="onChangePage">
      </priori-pagination>
    </div>

    <div class="col-sm-3 text-right-not-xs">
      {{ resultRange }}
    </div>
  </div>
</template>

<script>
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';

export default {
  name: 'SearchPagination',

  components: {
    PrioriPagination
  },

  props: {
    searchService: {
      type: Object,
      required: true
    },

    onChangePage: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      perPage: 10
    };
  },

  computed: {
    resultRange() {
      let resultStart = 1;
      let resultEnd = this.perPage;

      resultStart = (this.searchService.page * this.perPage) - (this.perPage - 1);
      resultEnd = (this.searchService.page * this.perPage) > this.searchService.totalCount ? this.searchService.totalCount : (this.searchService.page * this.perPage);

      return resultStart + '-' + resultEnd + ' of ' + this.searchService.totalCount;
    }
  }
};
</script>
