<template>
  <div class="shadowed-box proposal-recipients">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-xs-9 text-uppercase">
          <span class="hidden-sm">Proposal </span>Recipients
        </div>

        <div class="col-xs-3 normal-weight size-text-13px text-right" v-if="!isPotentialClient">
          <a href="" @click.prevent="onStartAdd">Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="semibold-weight text-ellipsis" v-if="counselRequest.requester.fullName !== counselRequest.requester.email">
        {{ counselRequest.requester.fullName }}
      </div>

      <div class="dark-gray-text text-ellipsis">
        {{ counselRequest.requester.email }}
      </div>

      <div class="top-15" v-for="recipient in requestClients" :key="recipient.id">
        <div class="semibold-weight text-ellipsis">
          {{ recipient.client.fullName }}
        </div>

        <div class="dark-gray-text text-ellipsis">
          {{ recipient.client.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProposalRecipients',

  props: {
    counselRequest: {
      type: Object,
      required: true
    },

    isPotentialClient: {
      type: Boolean,
      required: true
    },

    onStartAdd: {
      type: Function,
      required: true
    }
  },

  computed: {
    requestClients() {
      return this.counselRequest.requestClients;
    }
  }
};
</script>
