<template>
  <div>
    <interface-note class="bottom-40" :note-text="interfaceNoteText"></interface-note>

    <div class="shadowed-box">
      <div class="box-header">
        <div class="size-text-13px">
          {{ resourceLists.length }} <span class="normal-weight">{{ pluralize(resourceLists.length, 'List') }}</span>
        </div>
      </div>

      <div class="box-content">
        <firm-client-list
          v-for="list in resourceLists"
          :key="`internal-resource-list-${list.id}`"
          class="separated-list-items"
          :resource-list="list"
          :workspace="workspace">
        </firm-client-list>

        <div class="gray-text size-text-16px" v-if="!resourceLists.length">
          No Lists
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirmClientList from './firm-client-list.vue';

export default {
  name: 'FirmClientLists',

  components: {
    FirmClientList
  },

  props: {
    workspace: {
      type:     Object,
      required: true
    },

    resourceLists: {
      type:     Array,
      required: true
    }
  },

  computed: {
    interfaceNoteText() {
      return 'Lists are used to create a selection of lawyers/professionals to view or share with a client. Create a list here and add lawyers to it from the lawyers tab.';
    }
  },

  methods: {
    pluralize(count, word) {
      return this.$pluralize(word, count);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
