<template>
  <div v-if="dataIsPresentOrEditingAllowed(data)">
    <slot></slot>
  </div>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';

export default {
  name: 'DisplayRulesSectionWrapper',

  mixins: [displayRules],

  props: {
    // passing `null` or `undefined` data is valid
    // `null` and `undefined` values will pass any type validation
    data: {
      type: [Array, String, Number, Object]
    },

    editingAllowed: {
      type: Boolean,
      default: false
    }
  }
};
</script>
