import { render, staticRenderFns } from "./submission-error.vue?vue&type=template&id=144a9c40"
import script from "./submission-error.vue?vue&type=script&lang=js"
export * from "./submission-error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports