<template>
  <div>
    <nav class="navbar navbar-fixed-top opportunities-nav">
      <div class="nav-container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" aria-expanded="false">
            <span class="sr-only">Toggle Navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

          <a href="https://www.priorilegal.com" class="navbar-brand" aria-label="Priori Homepage"><svg-icon name="priori-logo" class="brand-icon"></svg-icon></a>
        </div>

        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav">
            <li class="nav-how-it-works nav-first">
              <a href="https://www.priorilegal.com/how-it-works">Marketplace</a>
            </li>
            <li class="nav-scout">
              <a href="https://www.priorilegal.com/scout">Scout</a>
            </li>
            <li class="nav-case-studies">
              <a href="https://www.priorilegal.com/case-studies">Case Studies</a>
            </li>
            <li class="nav-blog">
              <a href="https://www.priorilegal.com/blog">Blog</a>
            </li>
            <li class="nav-about-us nav-last">
              <a href="https://www.priorilegal.com/about-priori-legal">About Us</a>
            </li>
          </ul>

          <ul class="nav navbar-nav navbar-right">
            <li class="small-links-container"><a href="https://www.priorilegal.com/join-network">Join Provider Network</a></li>

            <li class="small-links-container"><a href="/sign-in" class="navbar-button" target="_self">Sign In</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="slidenav-overlay" class="slidenav-overlay"></div>

    <div id="slidenav" class="slidenav" role="navigation">
      <ul>
        <li><a href="https://www.priorilegal.com">Home</a></li>
      </ul>

      <ul>
        <li class="nav-how-it-works nav-first">
          <a href="https://www.priorilegal.com/how-it-works">Marketplace</a>
        </li>
        <li class="nav-scout">
          <a href="https://www.priorilegal.com/scout">Scout</a>
        </li>
        <li class="nav-case-studies">
          <a href="https://www.priorilegal.com/case-studies">Case Studies</a>
        </li>
        <li class="nav-blog">
          <a href="https://www.priorilegal.com/blog">Blog</a>
        </li>
        <li class="nav-about-us nav-last">
          <a href="https://www.priorilegal.com/about-priori-legal">About Us</a>
        </li>
      </ul>

      <ul>
        <li><a href="https://www.priorilegal.com/join-network">Join Provider Network</a></li>
        <li><a href="/sign-in" target="_self">Sign In</a></li>
      </ul>
    </div>

    <div class="padding-because-fixed-nav">
      <div class="container">
        <div class="padded-container">
          <slot></slot>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="padded-container">
          <div class="text-center bottom-20">
            <svg-icon name="priori-logo" class="brand-icon"></svg-icon>
          </div>

          <div class="text-center bottom-20">
            <a href="https://x.com/priorilegal" class="footer-social-media-link" target="_blank" rel="noopener" aria-label="Twitter Link"><svg-icon name="twitter-x" class="twitter-icon"></svg-icon></a>
            <a href="https://www.linkedin.com/company/priori-legal" class="footer-social-media-link" target="_blank" rel="noopener" aria-label="LinkedIn Link"><svg-icon name="linkedin"></svg-icon></a>
          </div>

          <div class="priori-links text-center bottom-5">
            <a href="https://www.priorilegal.com/privacy-policy-dmca-copyright-policy">Privacy Policy</a>
            <span class="left-5 right-5">|</span>
            <a href="https://www.priorilegal.com/terms-of-use">Terms of Use</a>
            <span class="left-5 right-5">|</span>
            &copy; {{ currentYear }} Priori Legal, Inc.
          </div>

          <div class="dark-gray-text size-text-11px text-center">
            Priori Legal is a platform that enables businesses to connect with lawyers of their choosing within our network and provides tools to facilitate that interaction. Priori Legal is neither a law firm nor &ldquo;lawyer referral service&rdquo; and provides no legal services. No attorney-client relationship is ever created between you and Priori.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'OpportunitiesLayout',

  components: {
    SvgIcon
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .navbar.opportunities-nav {
    margin-bottom: 0;
    border: 0;
    border-bottom: 1px solid $k-gray;
    background-color: $white;
    box-shadow: none;

    .nav-container {
      padding-right: 30px;
      padding-left: 30px;
    }

    .navbar-header {
      .navbar-brand {
        height: 80px;
        padding: 25px 15px 25px 0;

        .brand-icon {
          display: inline-block;
          width: 140px;
          height: 30px;
          vertical-align: middle;
          fill: $k-darker-gray;
        }
      }

      .navbar-toggle {
        margin: 29px 0;
        padding: 6px;

        .icon-bar {
          background-color: $body-text-color;
        }
      }
    }

    .navbar-nav {
      padding-right: 15px;

      @media (min-width: $screen-sm-min) {
        padding-right: 0;
      }

      &.navbar-right {
        display: flex;
        align-items: center;
        padding-right: 15px;
      }

      & > li > a {
        padding: 10px 15px;
        color: $body-text-color;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;

        @media (min-width: $screen-sm-min) {
          padding: 30px 8px;
        }

        @media (min-width: $screen-md-min) {
          padding: 30px 15px;
        }

        @media (min-width: $screen-lg-min) {
          padding: 30px 20px;
        }

        &:hover, &:active, &:focus {
          opacity: .75;
          background-color: transparent;
        }

        &.navbar-button {
          padding: 9px 15px;
          height: 40px;
          border: 1px solid $grey-4;
          border-radius: $border-radius-large;
          background-color: transparent;
        }
      }

      &:not(.navbar-right) > li > a {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .padding-because-fixed-nav {
    padding-top: 81px;

    @media (min-width: $screen-sm-min) {
      min-height: calc(100vh - 228px);
    }

    @media (min-width: $screen-md-min) {
      min-height: calc(100vh - 212px);
    }
  }

  .footer {
    position: relative;
    margin-right: -15px;
    margin-left: -15px;
    background-color: $white;
    border-top: 1px solid $k-gray;

    .brand-icon {
      width: 120px;
      height: 24px;
    }

    .padded-container {
      padding: 30px 5px;

      @media (min-width: $screen-sm-min) {
        padding: 30px 20px;
      }

      @media (min-width: $screen-md-min) {
        padding: 30px;
      }

      @media (min-width: $screen-lg-min) {
        padding: 30px 40px;
      }
    }

    .priori-links {
      font-size: 12px;
      font-weight: 600;

      a {
        color: $body-text-color;
        text-decoration: underline;

        &:hover, &:active {
          opacity: .75;
        }
      }
    }
  }

  .footer-social-media-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
    fill: $body-text-color;

    :deep(.twitter-icon) {
      rect {
        stroke: $body-text-color;
      }

      path {
        fill: $body-text-color;
      }
    }

    &:hover, &:active {
      opacity: .75;
    }
  }
</style>
