<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <h1 class="size-text-24px text-center bottom-40">
        <span class="normal-weight">Change Your Password</span>
      </h1>

      <validation-observer v-slot="{ handleSubmit }">
        <form id="password-reset-form" role="form" class="a-form" method="POST" novalidate>
          <input type="checkbox" id="good_check" name="good_check" value="1" class="checking-input">

          <div v-if="backendErrors" class="error-text top-5 bottom-20">
            {{ backendErrors }}
          </div>

          <div class="row">
            <div class="col-sm-6">
              <label for="password">Password</label>

              <validation-provider vid="password" v-slot="{ errors, failedRules, changed }" :bails="false" rules="required|oneNumber|min:15|oneUppercaseChar|oneLowercaseChar|oneSpecialChar">
                <input type="password" label="Password" name="password" id="password" class="form-control" placeholder="Password" autocomplete="off" v-model="user.password">

                <div class="error-text top-5" v-if="errors.length && submitClicked">
                  <span v-if="user.password && user.password.length">* Password doesn&rsquo;t meet requirements</span>
                  <span v-else>{{ errors[0] }}</span>
                </div>

                <div class="top-15 size-text-12px dark-gray-text semibold-weight">
                  <div class="bottom-5">
                    Password must have:
                  </div>

                  <ul>
                    <li v-for="(constraint, rule) in rules" :key="rule" class="bottom-5">
                      <svg-icon v-if="isValid(rule, failedRules) && changed" name="checkmark" class="base-icon success"></svg-icon>
                      <svg-icon v-else name="exclamation" class="base-icon"></svg-icon>
                      {{ constraint }}
                    </li>
                  </ul>
                </div>
              </validation-provider>
            </div>

            <div class="col-sm-6">
              <label for="password-confirmation">Confirm Password</label>

              <validation-provider vid="passwordConfirmation" v-slot="{ errors }" rules="required|confirmed:password">
                <input type="password" label="Password Confirmation" name="passwordConfirmation" id="password-confirmation" class="form-control" placeholder="Password Confirmation" autocomplete="off" v-model="user.passwordConfirmation">

                <div class="error-text top-5" v-if="errors.length && submitClicked">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>
        </form>

        <div class="row top-20">
          <div class="col-sm-6">
            <loading-button name="submitSignUp" lb-class="nv-button-blue a-button-size" @lb-click="setSubmitClicked() && handleSubmit(submit)">
              Change My Password
            </loading-button>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'PasswordResetForm',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    onSubmit: {
      type: Function,
      required: true
    }
  },

  data: function () {
    return {
      rules: {
        min: 'Minimum of 15 characters',
        oneNumber: 'At least one number',
        oneUppercaseChar: 'At least one uppercase character',
        oneLowercaseChar: 'At least one lowercase character',
        oneSpecialChar: 'At least one special character'
      },

      user: {
        password: '',
        passwordConfirmation: '',
        resetPasswordToken: ''
      },

      backendErrors: null,
      submitClicked: false
    };
  },

  computed: {
    querystringParams: function () {
      const querystring = window.location.search;
      return new URLSearchParams(querystring);
    },

    resetPasswordToken: function () {
      return this.querystringParams.get('reset_password_token');
    }
  },

  methods: {
    onSuccess: function (response) {
      this.backendErrors = null;
      window.location.href = response.redirect;
    },

    onError: function (response) {
      if (response.data?.redirect) {
        window.location.href = response.data.redirect;
      }

      if (response.data?.errors) {
        this.backendErrors = response.data.errors[0];
      }

      LoadingService.done('submitPasswordChange');
    },

    setSubmitClicked: function () {
      this.submitClicked = true;
      return true;
    },

    submit: function () {
      LoadingService.loading('submitPasswordChange');

      this.user.resetPasswordToken = this.resetPasswordToken;

      this.onSubmit(this.user, this.onSuccess, this.onError);
    },

    isValid: function (rule, failedRules) {
      return !Object.keys(failedRules).includes(rule);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  label {
    padding-left: 0;
  }

  h1 {
    margin-top: 0;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      display: flex;
      column-gap: 7px;

      .base-icon {
        fill: $k-dark-gray;
        width: 16px;

        &.success {
          fill: $k-green;
        }
      }
    }
  }
</style>
