<template>
  <div class="overlap-photos">
    <div
      v-for="admin in adminUsers"
      :key="admin.id"
      class="missing-photo"
      v-tooltip.bottom="{ text: admin.fullName, customClass: 'k-tooltip' }">
      {{ initialsFor(admin) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlappingAdminPhotos',

  props: {
    adminUsers: {
      type: Array,
      required: true
    }
  },

  methods: {
    initialsFor: function (person) {
      if (person.firstName && person.lastName) {
        return (person.firstName[0] + person.lastName[0]).toUpperCase();
      }
      else {
        return '@';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.missing-photo {
  font-weight: 600;
  width: 30px;
  height: 30px;
  padding: 8px 0;

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 12px 0;
  }
}
</style>
