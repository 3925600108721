<template>
  <div class="text-center">
    <div class="row tight-columns vertical-center bottom-20">
      <div class="col-xs-3 col-sm-offset-1 d-flex flex-center">
        <rating-circle
          :rating="{ percent: score * 10 }"
          :color-class="circleColorClass"
          :dot-color-class="dotColorClass">
        </rating-circle>
      </div>

      <div class="col-xs-6 col-sm-4 legend-text">
        {{ reviewType }}<br>Average
      </div>

      <div class="col-xs-3 size-text-14px semibold-weight">
        {{ scoreDisplay }}
      </div>
    </div>

    <div class="row tight-columns vertical-center bottom-20">
      <div class="col-xs-3 col-sm-offset-1 d-flex flex-center">
        <div class="diamond"></div>
      </div>

      <div class="col-xs-6 col-sm-4 legend-text">
        All {{ reviewType }}s<br>Average
      </div>

      <div class="col-xs-3 size-text-14px semibold-weight">
        {{ compScoreDisplay }}
      </div>
    </div>

    <div class="row tight-columns vertical-center">
      <div class="col-xs-3 col-sm-offset-1 d-flex flex-center">
        <div class="range-bar"></div>
      </div>

      <div class="col-xs-6 col-sm-4 legend-text">
        {{ reviewType }}<br>Range
      </div>

      <div class="col-xs-3 size-text-14px semibold-weight">
        {{ rangeDisplay }}
      </div>
    </div>
  </div>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';
import { compact } from 'lodash';

export default {
  name: 'ReviewsAverageScoreWithRangeLegend',

  components: {
    RatingCircle
  },

  props: {
    reviewType: {
      type: String,
      required: true,
      validator: (value) => { return ['Law Firm', 'Lawyer'].includes(value); }
    },

    score: {
      type: Number,
      required: true
    },

    rangeMin: {
      type: Number,
      required: true
    },

    rangeMax: {
      type: Number,
      required: true
    },

    compScore: {
      type: Number,
      required: true
    },

    gradientColor: {
      type: String,
      default: null
    }
  },

  computed: {
    scoreDisplay() {
      return this.score ? `${this.score}/10` : 'N/A';
    },

    compScoreDisplay() {
      return this.compScore ? `${this.compScore}/10` : 'N/A';
    },

    rangeDisplay() {
      return compact([this.rangeMin, this.rangeMax]).join(' - ') || 'N/A';
    },

    circleColorClass() {
      return this.gradientColor === 'blue' ? 'blue-rating-indicator' : '';
    },

    dotColorClass() {
      return this.gradientColor === 'blue' ? 'purple-dot' : 'blue-dot';
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .legend-text {
    color: $pill-gray;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    text-transform: uppercase;
  }

  .diamond {
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    background-color: $pill-gray;
    border-radius: 1px;
  }

  .range-bar {
    width: 24px;
    height: 12px;
    border: 6px solid $grey-3;
    border-bottom: 0;
    border-radius: 12px 12px 0 0;
  }
</style>
