<template>
  <filter-button
    label="Active During"
    :active="activeFilterSelection != 'allTime'">
    <form data-testid="activity-date-filter" name="form" class="a-form" novalidate>
      <div class="text-right bottom-15">
        <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
          Clear Filter
        </button>
      </div>

      <div class="bottom-15">
        <label class="check-option vertical medium-large" for="filter-current">
          <span class="normal-weight">Current Active Matters</span>
          <input type="radio" id="filter-current" value="current" v-model="activeFilterSelection" @change="applySearch">
          <span class="check c-radio"></span>
        </label>

        <label class="check-option vertical medium-large" for="filter-month">
          <span class="normal-weight">Active Last Month</span>
          <input type="radio" name="filter-month" id="filter-month" value="month" v-model="activeFilterSelection" @change="applySearch">
          <span class="check c-radio"></span>
        </label>

        <label class="check-option vertical medium-large" for="filter-year">
          <span class="normal-weight">Active Last Year</span>
          <input type="radio" name="filter-year" id="filter-year" value="year" v-model="activeFilterSelection" @change="applySearch">
          <span class="check c-radio"></span>
        </label>

        <label class="check-option vertical medium-large" for="filter-custom">
          <span class="normal-weight">Custom</span>
          <input type="radio" name="filter-custom" id="filter-custom" value="custom" v-model="activeFilterSelection">
          <span class="check c-radio"></span>
        </label>
      </div>

      <div class="top-10">
        <hr class="no-margin">

        <div class="top-15">
          <div class="bottom-10">
            <div>
              <label>From</label>
              <span class="error-text left-5" v-show="(isNull(fromMonth) || !fromYear) && customRangeSelected">* Required</span>
            </div>

            <div class="row tight-columns">
              <div class="col-xs-6">
                <select name="fromMonth" :disabled="!customRangeSelected" :class="['form-control filter-from-month', { 'empty': isNull(fromMonth) }]" v-model="fromMonth" @change="applySearch">
                  <option disabled :value="null">
                    Month
                  </option>

                  <option v-for="(monthName, index) in monthShortNames" :key="monthName" :value="index">
                    {{ monthName }}
                  </option>
                </select>
              </div>

              <div class="col-xs-6">
                <select name="fromYear" :disabled="!customRangeSelected" :class="['form-control filter-from-year', { 'empty': isNull(fromYear) }]" v-model="fromYear" @change="applySearch">
                  <option disabled :value="null">
                    Year
                  </option>

                  <option v-for="year in yearRange" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div>
            <div>
              <label>To</label>
              <span class="error-text left-5" v-show="(isNull(toMonth) || !toYear) && customRangeSelected">* Required</span>
            </div>

            <div class="row tight-columns">
              <div class="col-xs-6">
                <select name="toMonth" :disabled="!customRangeSelected" :class="['form-control filter-to-month', { 'empty': isNull(toMonth) }]" v-model="toMonth" @change="applySearch">
                  <option disabled :value="null">
                    Month
                  </option>

                  <option v-for="(monthName, index) in monthShortNames" :key="monthName" :value="index">
                    {{ monthName }}
                  </option>
                </select>
              </div>

              <div class="col-xs-6">
                <select name="toYear" :disabled="!customRangeSelected" :class="['form-control filter-to-year', { 'empty': isNull(toYear) }]" v-model="toYear" @change="applySearch">
                  <option disabled :value="null">
                    Year
                  </option>

                  <option v-for="year in yearRange" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="error-text top-5" v-show="error">
          {{ error }}
        </div>
      </div>
    </form>
  </filter-button>
</template>

<script>
import { isNull } from 'lodash';
import FilterButton from 'vue-app/shared/components/filter-button.vue';

export default {
  name: 'FilterActivityDate',

  components: {
    FilterButton
  },

  props: {
    onApplyFilter: {
      type: Function,
      required: true
    },

    yearRange: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activeFilterSelection: 'allTime',
      formIsValid: false,
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null,
      error: null
    };
  },

  computed: {
    monthShortNames() {
      const date = new Date();

      return  [...Array(12).keys()].map((i) =>
        new Date(date.getFullYear(), i, 1).toLocaleString('en-US', { month: 'short' })
      );
    },

    customRangeSelected() {
      return this.activeFilterSelection === 'custom';
    },

    missingFieldCount() {
      return [this.fromMonth, this.fromYear, this.toMonth, this.toYear].filter(field => isNull(field)).length;
    },

    unexpectedDateRange() {
      return this.fromYear > this.toYear || (this.fromYear === this.toYear && this.fromMonth > this.toMonth);
    },

    today() {
      return new Date();
    }
  },

  methods: {
    applySearch() {
      if (this.activeFilterSelection !== 'custom') {
        this.clearCalendarElements();
      }

      if (this.activeFilterSelection === 'current') {
        return this.onApplyFilter({ active: true });
      }

      const params = {};

      let start, finish;

      const today = new Date();

      if (this.activeFilterSelection === 'custom') {
        this.validateForm();

        if (!this.formIsValid) { return; }

        start = new Date(this.fromYear, this.fromMonth);
        finish = new Date(this.toYear, parseInt(this.toMonth) + 1, 0);
      }

      if (this.activeFilterSelection === 'month') {
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        finish = new Date(start.getFullYear(), start.getMonth() + 1, 0, 23, 59, 59);
      }

      if (this.activeFilterSelection === 'year') {
        start = new Date(today.getFullYear() - 1, 0, 1);
        finish = new Date(today.getFullYear() - 1, 12, 0, 23, 59, 59);
      }

      params.active = {
        from: Math.round(start.getTime() / 1000),
        to: Math.round(finish.getTime() / 1000)
      };

      this.onApplyFilter(params);
    },

    validateForm() {
      if (!this.missingFieldCount && this.unexpectedDateRange) {
        this.formIsValid = false;
        this.error = '* To date cannot be earlier than From date';
      }
      else if (this.missingFieldCount) {
        this.formIsValid = false;
      }
      else {
        this.error = null;
        this.formIsValid = true;
      }
    },

    clearFilter() {
      this.activeFilterSelection = 'allTime';
      this.clearCalendarElements();
      this.onApplyFilter({});
    },

    clearCalendarElements() {
      this.fromMonth = null;
      this.fromYear  = null;
      this.toMonth   = null;
      this.toYear    = null;
    },

    isNull(value) {
      return isNull(value);
    }
  }
};
</script>
