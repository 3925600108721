<template>
  <priori-modal ref="modal" :title="noticeHeader">
    <template #modal-trigger="{ openModal }">
      <button
        type="button"
        :disabled="isDeclined"
        @click="openModal"
        :class="['icon-button nv-button-white', { 'declined': isDeclined }]">
        <svg-icon name="x-circle" class="base-icon"></svg-icon>
        <span class="left-5">{{ isDeclined ? 'Declined' : 'Decline' }}</span>
      </button>
    </template>

    <template #default="{ closeModal }">
      <div class="bottom-20">
        {{ noticeBody }}
      </div>

      <div class="bottom-30">
        Are you sure you&rsquo;d like to proceed?
      </div>

      <div class="row tight-columns">
        <div class="col-sm-4">
          <loading-button name="updateQuote" lb-class="primary-btn-blue" @lb-click="declineFirm">
            Decline
          </loading-button>
        </div>

        <div class="col-sm-3 top-10-xs">
          <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'DeclineFirmButton',

  components: {
    PrioriModal,
    SvgIcon
  },

  props: {
    selectedQuote: {
      type: Object,
      required: true
    },

    isDeclined: {
      type: Boolean,
      default: false
    },

    rfq: {
      type: Object,
      required: true
    },

    isMarketplace: {
      type: Boolean,
      default: false
    },

    updateQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    noticeHeader() {
      return this.isMarketplace ? `Decline ${this.marketplaceProviderName}` : 'Confirm Declining Firm';
    },

    marketplaceProviderName() {
      return this.selectedQuote.marketplaceLawyer.addedAsFirm ? this.selectedQuote.lawFirm.name : this.selectedQuote.marketplaceLawyer.fullName;
    },

    noticeBody() {
      return this.isMarketplace ? 'Confirm your selection below. Once confirmed, Priori will be notified that you have declined this quote and will handle any required communications with the provider.' : 'By confirming your selection, you will notify the Firm that you have declined their quote for the project.';
    }
  },

  methods: {
    declineFirm() {
      LoadingService.loading('updateQuote');

      return this.updateQuote(this.rfq, this.selectedQuote, 'decline_firm').then(() => {
        LoadingService.done('updateQuote');
        this.$refs.modal.closeModal();
      });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .icon-button {
    &:disabled, &:disabled .base-icon {
      opacity: 1;
    }
  }

  .declined {
    color: $scout-red;
    fill: $scout-red;
    background-color: $red-10;
    border-color: $scout-red;

    svg {
      fill: $scout-red;
    }
  }
</style>
