<template>
  <collapsable-items
    :show-all-button-text="`Show more (${quotes.length - itemLimit})`"
    :items="quotes"
    :items-limit="itemLimit">
    <template #items="{ renderingItems }">
      <quote-item
        v-for="quote in renderingItems"
        :key="quote.id"
        class="list-spacing-10"
        :rfq="rfq"
        :quote="quote"
        :view-quote="viewQuote">
      </quote-item>
    </template>
  </collapsable-items>
</template>

<script>
import QuoteItem from 'vue-app/scout/client/rfqs/quote-item.vue';
import CollapsableItems from 'vue-app/shared/components/collapsable-items.vue';

export default {
  name: 'QuoteGroup',

  components: {
    QuoteItem,
    CollapsableItems
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    quotes: {
      type: Array,
      required: true
    },

    viewQuote: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      itemLimit: 3
    };
  }
};
</script>
