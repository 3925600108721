<template>
  <a href="" role="button" class="text-link table-sort" @click.prevent="toggleSort">
    <slot></slot>
    <svg-icon :name="iconName" :class="['base-icon', { 'disabled': unsorted }]"></svg-icon>
  </a>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'TableSort',

  components: {
    SvgIcon
  },

  props: {
    column: {
      type: String,
      required: true
    },

    direction: {
      type: String,
      default: null
    },

    onToggleSort: {
      type: Function,
      required: true
    }
  },

  computed: {
    unsorted() {
      return !this.direction;
    },

    sortedAsc() {
      return this.direction === 'ASC';
    },

    sortedDesc() {
      return this.direction === 'DESC';
    },

    iconName() {
      return this.sortedAsc ? 'caret-up' : 'caret-down';
    }
  },

  methods: {
    toggleSort() {
      this.onToggleSort(this.column);
    }
  }
};
</script>
