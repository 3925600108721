<template>
  <div class="text-ellipsis">
    <span>
      Created
      <span v-if="createdByNameAndTitle">by</span>
      <br class="visible-xs">
      <span class="semibold-weight" v-if="createdByNameAndTitle">{{ createdByNameAndTitle }}</span>
      <br class="visible-xs">
      on {{ createdOn }}
    </span>

    <span v-if="!list.shared">
      <br class="visible-xs">
      <span class="left-5 right-5 hidden-xs">&#8231;</span>
      <span v-if="hasShares">{{ shareText }} (<a href="" class="dark-gray-link" @click.prevent="onStartEditList()">Review notes and sharing</a>)</span>
      <span v-else>Only visible to you</span>
    </span>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import { compact } from 'lodash';

export default {
  name: 'ListHeaderDetails',

  props: {
    list: {
      type:     Object,
      required: true
    },

    onStartEditList: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      dateFormat: { year: 'numeric', month: 'short', day: '2-digit' }
    };
  },

  computed: {
    createdByName() {
      return this.list.shared ? this.list.createdBy.fullName : 'You';
    },

    createdByTitle() {
      if (!this.list.shared) { return null; }

      const createdBy = this.list.createdBy;

      if (createdBy.klass === 'Client') {
        return compact([createdBy.role, createdBy.businessName]).join(' at ');
      }
      else if (createdBy.klass === 'Lawyer') {
        return compact([createdBy.firmTitle, createdBy.firmName]).join(' at ');
      }
      else {
        return null;
      }
    },

    createdByNameAndTitle() {
      if (!this.list.createdBy) { return; }

      let nameAndTitle = this.list.createdByDisplayText();

      if (this.createdByTitle) {
        nameAndTitle += ` (${this.createdByTitle})`;
      }

      return nameAndTitle;
    },

    shareText() {
      return `Shared with ${this.list.shares.length} ${this.$pluralize('person', this.list.shares.length)}`;
    },

    createdOn() {
      return DateFilter.filter(this.list.createdAt, this.dateFormat);
    },

    hasShares() {
      return this.list.shares && this.list.shares.length;
    }
  }
};
</script>
