<template>
  <div class="row tight-columns">
    <div class="col-sm-4 clickable" @click="viewFirm">
      <firm-name-plate :firm="firm" :italic="firm.isBillingFirm()"></firm-name-plate>
    </div>

    <div class="col-sm-7">
      <div class="row match-row tight-columns vertical-center-not-xs">
        <div class="col-sm-3 text-center">
          <match-tag :score="firm.matchScore"></match-tag>
        </div>

        <div :class="{ 'col-sm-6': !isFeatureLab, 'col-sm-5 text-center': isFeatureLab }">
          <div v-if="isFeatureLab">
            {{ reviewCount }}
          </div>

          <div v-else>
            {{ relationshipPartners }}
          </div>
        </div>

        <div :class="['text-center', { 'col-sm-3': !isFeatureLab, 'col-sm-4': isFeatureLab }]">
          <div v-if="isFeatureLab">
            {{ rfqCount }}
          </div>

          <div v-else>
            {{ matterCount }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-1 text-right-not-xs">
      <dropdown-menu :compact="true" class="top-5">
        <li class="menu-item" role="menuitem">
          <a :href="`/scout-company/law-firms/${firm.id}`" target="_blank">View Profile</a>
        </li>

        <li class="menu-item" role="menuitem">
          <a href="" @click.prevent="viewFirm" target="_blank">View Search Summary</a>
        </li>

        <li class="menu-item" role="menuitem">
          <a href="" @click.prevent="toggleFavorite">{{ firm.isFavorite ? 'Unfavorite' : 'Favorite' }}</a>
        </li>

        <li class="menu-item" role="menuitem">
          <modal-add-to-list
            :resource-lists="resourceLists"
            :resource="firm"
            :on-create-list="onCreateList"
            :on-add-to-list="onAddToList">
          </modal-add-to-list>
        </li>

        <li class="menu-item" role="menuitem">
          <a :href="`/scout-company/law-firms/${firm.id}/reviews/new`" target="_blank">Leave a Review</a>
        </li>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import MatchTag from 'vue-app/scout/shared/match-tag.vue';
import FirmNamePlate from 'vue-app/scout/shared/firm-name-plate.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import ModalAddToList from 'vue-app/scout/shared/searches/modal-add-to-list.vue';
import { mapState } from 'pinia';
import { map } from 'lodash';

export default {
  name: 'SearchFirm',

  components: {
    MatchTag,
    FirmNamePlate,
    DropdownMenu,
    ModalAddToList
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    onView: {
      type: Function,
      required: true
    },

    onToggleFavorite: {
      type: Function,
      required: true
    },

    onCreateList: {
      type: Function,
      required: true
    },

    onAddToList: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    relationshipPartners() {
      const partners = this.firm.relationshipPartners;

      return partners.length ? map(partners, 'fullName').join(', ') : '—';
    },

    matterCount() {
      return this.firm.matterCount || 0;
    },

    reviewCount() {
      return this.firm.reviewCount || 0;
    },

    rfqCount() {
      return this.firm.rfqCount || 0;
    }
  },

  methods: {
    toggleFavorite() {
      this.onToggleFavorite(this.firm);
    },

    viewFirm() {
      this.onView(this.firm);
    }
  }
};
</script>
