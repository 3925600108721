<template>
  <div class="project-rates-table">
    <div class="lawyer-pricing-grid pricing-data-grid" v-if="projectRates.clientTotal">
      <div class="grid-head">
        <div class="gh"></div>

        <div class="gh">
          Client Total
          <question-tooltip class="left-5" text="The total amount paid by the client. It is the sum of the lawyer&rsquo;s rate plus the Priori management fee."></question-tooltip>
        </div>

        <div class="gh">
          Lawyer Total
          <question-tooltip class="left-5" text="The amount paid by the client to the lawyer for legal services."></question-tooltip>
        </div>

        <div class="gh">
          Priori Management Fee
          <question-tooltip class="left-5" text="The amount paid by the client to Priori for the client&rsquo;s use of our platform and service. It is calculated as a percentage of the lawyer&rsquo;s rate."></question-tooltip>
        </div>
      </div>

      <div class="grid-row">
        <div class="gh">
          Average
        </div>
        <div class="gd">
          {{ clientTotal }}
        </div>
        <div class="gd">
          {{ lawyerTotal }}
        </div>
        <div class="gd">
          {{ prioriManagementFee }}
        </div>
      </div>
    </div>

    <div class="gray-text size-text-base bottom-10 text-center" v-else>
      No available data.
    </div>
  </div>
</template>

<script>
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import QuestionTooltip from 'vue-app/shared/components/question-tooltip.vue';

export default {
  name: 'ProjectRatesTable',

  components: {
    QuestionTooltip
  },

  props: {
    projectRates: {
      type: Object,
      required: true
    }
  },

  computed: {
    clientTotal() {
      return CurrencyFilter.filter(this.projectRates.clientTotal);
    },

    lawyerTotal() {
      return CurrencyFilter.filter(this.projectRates.lawyerTotal);
    },

    prioriManagementFee() {
      return CurrencyFilter.filter(this.projectRates.prioriManagementFee);
    }
  }
};
</script>
