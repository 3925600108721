<template>
  <div class="shadowed-box size-text-13px">
    <div class="box-header text-uppercase">
      Team Members
    </div>

    <div class="box-content">
      <div v-for="(teamMember, index) in teamMembers">
        <div class="row">
          <div class="col-sm-8">
            <a :href="teamMember.path" class="semibold-weight">{{ teamMember.fullName }}</a>
            <span class="dark-gray-text" v-if="teamMember.role.length">({{ teamMember.role }})</span>
          </div>

          <div class="col-sm-4 text-right-not-xs">
            <impersonate-modal :user="teamMember" :target="'_self'">
              <a href="">Impersonate</a>
            </impersonate-modal>
          </div>
        </div>

        <hr class="kenny-hr" v-if="!isLastTeamMember(index)">
      </div>

      <div class="text-center size-text-base gray-text" v-if="!hasTeamMembers">
        The client hasn't added any team members.
      </div>
    </div>
  </div>
</template>

<script>
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';

export default {
  name: 'MatterTeamMembers',

  components: {
    ImpersonateModal
  },

  props: {
    teamMembers: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    hasTeamMembers() {
      return this.teamMembers.length > 0;
    }
  },

  methods: {
    isLastTeamMember(index) {
      return index === this.teamMembers.length - 1;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
