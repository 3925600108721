<template>
  <boxed-content v-if="displayComponent" title="Experience">
    <template #links v-if="canEditProfile">
      <edit-lawyer-experience :lawyer="lawyer" :on-save="onSave"></edit-lawyer-experience>
    </template>

    <section-wrapper class="separated-list-items" :data="lawyer.notableExperience" :editing-allowed="canEditProfile">
      <div class="bold-weight bottom-10">
        Notable Legal Experience
      </div>

      <data-wrapper :data="lawyer.notableExperience">
        <div class="tag-list">
          <div v-for="(experience, index) in lawyer.notableExperience" :key="`lawyer-notable-experience-${index}`" class="tag-list-item gray compact">
            <div class="text-ellipsis">
              {{ experience.presentedName }}
            </div>
          </div>
        </div>
      </data-wrapper>
    </section-wrapper>

    <section-wrapper class="separated-list-items" :data="lawyer.sanitizedGovernmentExperience" :editing-allowed="canEditProfile">
      <div class="bold-weight bottom-10">
        Government Experience
      </div>

      <data-wrapper :data="lawyer.sanitizedGovernmentExperience">
        <div class="tag-list">
          <div v-for="(experience, index) in lawyer.sanitizedGovernmentExperience" :key="`lawyer-government-experience-${index}`" class="tag-list-item gray compact">
            <div class="text-ellipsis">
              {{ experience }}
            </div>
          </div>
        </div>
      </data-wrapper>
    </section-wrapper>

    <section-wrapper class="separated-list-items" :data="lawyer.sanitizedPracticeAreaCertifications" :editing-allowed="canEditProfile">
      <div class="bold-weight bottom-10">
        Practice Area Certifications
      </div>

      <data-wrapper :data="lawyer.sanitizedPracticeAreaCertifications">
        <div class="tag-list">
          <div v-for="(certification, index) in lawyer.sanitizedPracticeAreaCertifications" :key="`lawyer-practice-area-certification-${index}`" class="tag-list-item gray compact">
            <div class="text-ellipsis">
              {{ certification }}
            </div>
          </div>
        </div>
      </data-wrapper>
    </section-wrapper>

    <section-wrapper class="separated-list-items" :data="lawyer.privacyCertifications" :editing-allowed="canEditProfile">
      <div class="bold-weight bottom-10">
        Privacy Certifications
      </div>

      <data-wrapper :data="lawyer.privacyCertifications">
        <div class="tag-list">
          <div v-for="(certification, index) in lawyer.privacyCertifications" :key="`lawyer-privacy-certification-${index}`" class="tag-list-item gray compact">
            <div class="text-ellipsis">
              {{ certification.presentedName }}
            </div>
          </div>
        </div>
      </data-wrapper>
    </section-wrapper>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import EditLawyerExperience from 'vue-app/scout/shared/lawyers/edit-lawyer-experience.vue';

export default {
  name: 'LawyerExperience',

  components: {
    BoxedContent,
    SectionWrapper,
    DataWrapper,
    EditLawyerExperience
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  computed: {
    displayComponent() {
      const {
        notableExperience,
        sanitizedGovernmentExperience,
        sanitizedPracticeAreaCertifications,
        privacyCertifications
      } = this.lawyer;

      return this.anyDataIsPresent(
        [
          notableExperience,
          sanitizedGovernmentExperience,
          sanitizedPracticeAreaCertifications,
          privacyCertifications
        ]
      ) || this.canEditProfile;
    }
  }
};
</script>
