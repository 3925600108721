<template>
  <div v-if="hasSuggestedLawyers">
    <div class="bold-weight">
      Edit Suggested Lawyers
    </div>

    <div class="bottom-10">
      Please indicate if these lawyers would be a good fit for this project and, if so, enter the applicable hourly rates.
    </div>

    <div class="flat-box">
      <div class="box-header">
        <div class="row vertical-center-not-xs">
          <div class="col-sm-4">
            Lawyer
          </div>

          <div class="col-sm-2 top-10-xs">
            Rate on File
            <svg-icon id="client-tt" name="info" class="base-icon smaller left-5"></svg-icon>
            <tooltip target="#client-tt" placement="bottom" custom-class="tooltip-white" text="This data reflects current rates stored in the Client’s e-billing system."></tooltip>
          </div>

          <div class="col-sm-3 top-10-xs">
            Proposed Hourly Rate
            <span class="inline-help required" v-if="ratesAreRequired">*</span>
            <span v-else>(if applicable)</span>
          </div>

          <div class="col-sm-3 text-right-not-xs">
            Include in Quote?
          </div>
        </div>
      </div>

      <div class="box-content no-padding">
        <div class="lawyer-row" v-for="lawyer in orderedLawyers" :key="lawyer.id">
          <quote-form-client-suggested-lawyer
            :suggested-lawyer="lawyer"
            :rate-is-required="ratesAreRequired"
            :on-change="onChange">
          </quote-form-client-suggested-lawyer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteFormClientSuggestedLawyer from './quote-form-client-suggested-lawyer.vue';
import { orderBy } from 'lodash';

export default {
  name: 'QuoteFormClientSuggestedLawyers',

  components: {
    QuoteFormClientSuggestedLawyer
  },

  props: {
    clientSuggestedLawyers: {
      type: Array,
      required: true
    },

    ratesAreRequired: {
      type: Boolean,
      default: true
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  computed: {
    orderedLawyers() {
      return orderBy(this.clientSuggestedLawyers, [
        'scoutLawyerBridge.lastName', 'scoutLawyerBridge.firstName', 'scoutLawyerBridge.fullName'
      ]);
    },

    hasSuggestedLawyers() {
      return this.clientSuggestedLawyers.length > 0;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .lawyer-row {
    padding: 15px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $medium-gray;
    }
  }
</style>
