<template>
  <div class="row tight-columns">
    <div class="col-sm-5 clickable" @click="viewFirm">
      <firm-name-plate :firm="firm" :italic="firm.isBillingFirm()"></firm-name-plate>
    </div>

    <div class="col-sm-6">
      <div class="row match-row tight-columns vertical-center-not-xs">
        <div :class="['col-sm-6', { 'text-center': isFeatureLab }]">
          <div v-if="isFeatureLab">
            {{ reviewCount }}
          </div>

          <div v-else>
            {{ relationshipPartners }}
          </div>
        </div>

        <div class="col-sm-6 text-center">
          <div v-if="isFeatureLab">
            {{ rfqCount }}
          </div>

          <div v-else>
            {{ matterCount }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-1 text-right-not-xs">
      <dropdown-menu :compact="true" class="top-5">
        <li class="menu-item" role="menuitem">
          <a :href="`/scout-company/law-firms/${firm.id}`" target="_blank">View Profile</a>
        </li>

        <li class="menu-item" role="menuitem">
          <a href="" @click.prevent="removeFromList">Remove</a>
        </li>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import FirmNamePlate from 'vue-app/scout/shared/firm-name-plate.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { map } from 'lodash';

export default {
  name: 'ListFirm',

  components: {
    FirmNamePlate,
    DropdownMenu
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    onView: {
      type: Function,
      required: true
    },

    onRemoveFromList: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    relationshipPartners() {
      const partners = this.firm.relationshipPartners;

      return partners.length ? map(partners, 'fullName').join(', ') : '—';
    },

    matterCount() {
      return this.firm.matterCount || 0;
    },

    reviewCount() {
      return this.firm.reviewCount || 0;
    },

    rfqCount() {
      return this.firm.rfqCount || 0;
    }
  },

  methods: {
    viewFirm() {
      this.onView(this.firm);
    },

    removeFromList() {
      this.onRemoveFromList(this.firm);
    }
  }
};
</script>
