<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Scout / Feature Lab Status
    </div>

    <div class="box-content symmetrical size-text-13px">
      <loading-section name="productConfiguration">
        <div v-if="workspace.isScout && !workspace.isFeatureLab">
          <div class="semibold-weight scout-purple-text scout-status">
            Onboarded - Scout
          </div>

          <hr class="kenny-hr">

          <div class="tiny-header-text bottom-10">
            Connected Firms
          </div>

          <div>
            <scout-firm
              v-for="firm in connectedFirms"
              :firm="firm"
              :key="firm.id">
            </scout-firm>
          </div>

          <div class="gray-text size-text-16px" v-if="!connectedFirms.length">
            No connected firms
          </div>

          <connect-to-firm
            class="top-20"
            :workspace="workspace"
            :use-connecting-firms="false"
            v-if="permittedToConfigure">
          </connect-to-firm>

          <hr class="kenny-hr">

          <div class="tiny-header-text bottom-10">
            Connecting Firms
          </div>

          <div>
            <scout-firm
              v-for="firm in connectingFirms"
              :firm="firm"
              :key="firm.id">
            </scout-firm>
          </div>

          <div class="gray-text size-text-16px" v-if="!connectingFirms.length">
            No connecting firms
          </div>

          <connect-to-firm
            class="top-20"
            :workspace="workspace"
            :use-connecting-firms="true"
            v-if="permittedToConfigure">
          </connect-to-firm>

          <hr class="kenny-hr">

          <workspace-billing-system-config
            :workspace="workspace"
            :permitted-to-configure="permittedToConfigure">
          </workspace-billing-system-config>
        </div>

        <feature-lab-status
          v-else-if="workspace.isOnboardingFeatureLab || workspace.isFeatureLab"
          :error="termsOfServiceError"
          :permitted-to-configure="permittedToConfigure"
          :send-terms-of-service="sendTermsOfService"
          :workspace="workspace">
        </feature-lab-status>

        <div class="row tight-columns scout-status" v-else>
          <div class="col-sm-4 semibold-weight dark-gray-text">
            Not Onboarded
          </div>

          <div class="col-sm-4 top-10-xs" v-if="permittedToConfigure">
            <button type="button" class="pseudo-link-blue text-left" @click="openConfirmationModal('scoutConfirmationModal')">Configure for Scout</button>

            <confirmation-modal
              ref="scoutConfirmationModal"
              header="Configure for Scout"
              :message="`Are you sure you want to configure ${workspace.name} for Scout? This cannot be undone.`"
              :on-confirm-action="onConfigureScout">
            </confirmation-modal>
          </div>

          <div class="col-sm-4 top-10-xs" v-if="permittedToConfigure">
            <button type="button" class="pseudo-link-blue text-left" @click="openConfirmationModal('featureLabConfirmationModal')">Configure for Feature Lab</button>

            <confirmation-modal
              ref="featureLabConfirmationModal"
              header="Configure for Feature Lab"
              :message="`Are you sure you want to configure ${workspace.name} for Feature Lab?`"
              :on-confirm-action="onConfigureFeatureLab">
            </confirmation-modal>
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal.vue';
import ConnectToFirm from 'vue-app/admin/workspaces/connect-to-firm.vue';
import FeatureLabStatus from 'vue-app/admin/workspaces/feature-lab-status.vue';
import WorkspaceBillingSystemConfig from 'vue-app/admin/workspaces/workspace-billing-system-config.vue';
import ScoutFirm from 'vue-app/admin/workspaces/scout-firm.vue';
import { orderBy } from 'lodash';

export default {
  name: 'WorkspaceProductStatus',

  components: {
    LoadingSection,
    ConfirmationModal,
    ConnectToFirm,
    FeatureLabStatus,
    WorkspaceBillingSystemConfig,
    ScoutFirm
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    permittedToConfigure: {
      type: Boolean,
      default: false
    },

    onConfigureScout: {
      type: Function,
      required: true
    },

    onConfigureFeatureLab: {
      type: Function,
      required: true
    },

    sendTermsOfService: {
      type: Function,
      required: true
    },

    termsOfServiceError: {
      type: String,
      default: null
    }
  },

  computed: {
    orderedFirms() {
      return orderBy(this.workspace.scoutFirms, 'name');
    },

    connectedFirms() {
      return this.orderedFirms.filter(firm => !firm.isConnectingFirm);
    },

    connectingFirms() {
      return this.orderedFirms.filter(firm => firm.isConnectingFirm);
    }
  },

  methods: {
    openConfirmationModal(ref) {
      this.$refs[ref].openModal();
    }
  }
};
</script>
