<template>
  <boxed-content v-if="displayComponent" title="Highlights">
    <template #links v-if="canEditProfile">
      <edit-firm-highlights :firm="firm" :on-save="save"></edit-firm-highlights>
    </template>

    <loading-section name="firm-highlights">
      <section-wrapper class="separated-list-items" :data="representativeClients" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Representative Clients
        </div>

        <data-wrapper :data="representativeClients">
          <div class="tag-list">
            <collapsable-items :items="representativeClients">
              <template #items="slotProps">
                <div class="tag-list-item gray compact" v-for="client in slotProps.renderingItems" :key="`${client.name}-${client.id}`">
                  {{ client.name }}
                </div>
              </template>
            </collapsable-items>
          </div>
        </data-wrapper>
      </section-wrapper>

      <section-wrapper class="separated-list-items" :data="sortedIndustries" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Industries
        </div>

        <data-wrapper :data="sortedIndustries">
          <div class="tag-list">
            <collapsable-items :items="sortedIndustries">
              <template #items="slotProps">
                <div class="tag-list-item gray compact" v-for="industry in slotProps.renderingItems" :key="`${industry.name}-${industry.id}`">
                  {{ industry.name }}
                </div>
              </template>
            </collapsable-items>
          </div>
        </data-wrapper>
      </section-wrapper>
    </loading-section>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content';
import EditFirmHighlights from './edit-firm-highlights';
import LoadingService from 'vue-app/shared/services/loading-service';
import { sortBy } from 'lodash';
import CollapsableItems from 'vue-app/shared/components/collapsable-items';

export default {
  name: 'FirmHighlights',

  components: {
    BoxedContent,
    SectionWrapper,
    DataWrapper,
    EditFirmHighlights,
    CollapsableItems
  },

  mixins: [displayRules],

  props: {
    firm: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    representativeClients() {
      return this.firm.representativeClients;
    },

    sortedIndustries() {
      return sortBy(this.firm.industries, 'name');
    },

    displayComponent() {
      const { representativeClients, sortedIndustries } = this;
      return this.anyDataIsPresent([representativeClients, sortedIndustries]) || this.canEditProfile;
    }
  },

  methods: {
    save(firmEdited, success) {
      if (!this.canEditProfile) { return; }

      LoadingService.loading('firm-highlights');

      this.onSave(firmEdited,
        () => {
          LoadingService.done('firm-highlights');
          if (success) { success(); }
        },
        () => {
          LoadingService.done('firm-highlights');
        }
      );
    }
  }
};
</script>
