<template>
  <edit-modal title="Editing Articles" :input="list" :on-save="save" @on-modal-open="resetForm">
    <template #default="{ inputCopy: listCopy }">
      <div class="bold-weight text-uppercase">
        Articles
      </div>

      <hr>

      <div>
        <field-group class="bottom-20" v-for="item in listCopy" :on-remove-item="() => { removeItem(listCopy, item.identifier) }" item-type="article" :key="item.identifier">
          <template #input>
            <textarea-vertical
              label="Article Title or Overview"
              :name="`input${item.identifier}`"
              :autoresize="true"
              :id="`input-${item.identifier}`"
              rules="required"
              v-model="item.value"
              :maxlength="maxlength"
              :with-limit="false"
              :default-value="item.value">
              <template #limit-message="textareaProps">
                * Please note that only the first {{ textareaProps.maxlength }} characters of each article will be displayed on your profile (currently {{ textareaProps.inputLength }}/{{ textareaProps.maxlength }})
              </template>
            </textarea-vertical>
          </template>
        </field-group>

        <a href="" class="semibold-weight" @click.prevent="addItem(listCopy)">+ {{ listCopy.length ? 'Add Another' : 'Add Article' }}</a>
      </div>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above or remove that entry from the form.
    </template>
  </edit-modal>
</template>

<script>
import EditModal from '../edit-modal';
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import FieldGroup from 'vue-app/scout/shared/field-group.vue';
import DataLock from 'resources/data-lock';
import { changes } from 'misc/resource-helpers';
import { some, uniqueId } from 'lodash';
import useFieldGroupsStore from 'vue-app/stores/field-groups.js';
import { mapActions } from 'pinia';

export default {
  name: 'EditLawyerArticles',

  components: {
    EditModal,
    TextareaVertical,
    FieldGroup
  },

  props: {
    sanitizedArticles: {
      type: Array,
      default: () => []
    },

    lawyer: {
      type: Object
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      maxlength: 750
    };
  },

  computed: {
    list() {
      return this.sanitizedArticles.map(item => ({ value: item, identifier: uniqueId('article_') }));
    }
  },

  methods: {
    ...mapActions(useFieldGroupsStore, [
      'initializeIdentifiers'
    ]),

    resetForm() {
      this.initializeIdentifiers();
    },

    addItem(listCopy) {
      listCopy.push({ value: '', identifier: uniqueId('article_') });
    },

    removeItem(listCopy, identifier) {
      let index = listCopy.findIndex((item) => item.identifier === identifier);
      listCopy.splice(index, 1);
    },

    save(editedList) {
      const changedArticles = changes(this.sanitizedArticles, editedList.map(item => item.value));

      if (some(changedArticles)) {
        let items = editedList.filter(item => item.value.trim().length > 0);
        this.onSave(items.map(item => item.value));

        DataLock.save({
          dataLock: {
            flag:         'articles',
            resourceType: 'lawyer',
            resourceId: this.lawyer.id
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: 5px;
  }
</style>
