<template>
  <loading-section name="rfqActivities">
    <collapsable-items
      :items="activities"
      :items-limit="initialDisplayCount">
      <template #items="{ renderingItems }">
        <div v-for="(activity, index) in renderingItems" class="row tight-columns" :key="activity.id">
          <div class="col-xs-2">
            <svg-icon :name="activityIcon(activity.activityType)" class="base-icon lighter-gray"></svg-icon>
          </div>

          <div class="col-xs-10">
            <div class="row tight-columns size-text-12px bottom-5">
              <div class="col-md-6 bold-weight">
                {{ title(activity) }}
              </div>

              <div class="col-md-6 pill-gray-text text-right-md">
                {{ formatDate(activity.createdAt) }}
              </div>
            </div>

            <div>
              {{ activity.message }}
            </div>
          </div>

          <div class="col-xs-12" v-if="activities.length > initialDisplayCount || index < renderingItems.length - 1">
            <hr>
          </div>
        </div>
      </template>
    </collapsable-items>
  </loading-section>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import CollapsableItems from 'vue-app/shared/components/collapsable-items.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

export default {
  name: 'RfqTeamActivity',

  components: {
    CollapsableItems,
    LoadingSection
  },

  props: {
    activities: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      initialDisplayCount: 4
    };
  },

  methods: {
    formatDate(date) {
      return DateFilter.filter(date);
    },

    activityIcon(activityType) {
      const iconMap = {
        firm_added: 'plusCircle',
        project_details_updated: 'writing',
        questions_updated: 'writing',
        rfq_sent: 'paperPlane',
        marketplace_added: 'plusCircle'
      };
      return iconMap[activityType];
    },

    toggleShowAll() {
      this.showAll = !this.showAll;
    },

    title(activity) {
      if (activity.activityType === 'firm_added') {
        return 'Firm Added';
      }
      else if (['project_details_updated', 'questions_updated'].includes(activity.activityType)) {
        return 'RFP Modified';
      }
      else if (activity.activityType === 'marketplace_added') {
        return 'Marketplace Added';
      }
      else {
        return 'RFP Sent';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border-color: $medium-gray;
  }

  .text-right-md {
    margin-top: 5px;

    @media (min-width: 992px) {
      margin-top: 0;
      text-align: right;
    }
  }
</style>
