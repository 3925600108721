<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': canEditProfile }]">
          Practice Areas &amp; Proficiencies
        </div>

        <div class="col-sm-3 text-right-not-xs" v-if="canEditProfile">
          <edit-practice-areas
            data-testid="edit-practice-areas"
            :lawyer="lawyer"
            :on-save="onSave"
            :firm-practice-areas="firmPracticeAreas"
            :lawyer-practice-areas="lawyerPracticeAreas">
          </edit-practice-areas>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="practice-areas">
        <display-rules-data-wrapper :data="practiceAreasWithAlphabetizedProficiencies">
          <div v-for="(practiceArea, index) in practiceAreasWithAlphabetizedProficiencies" :key="practiceArea.id">
            <div>
              {{ practiceArea.name }}
            </div>

            <div class="top-5" v-if="practiceArea.proficiencies.length > 0">
              <ul data-testid="proficiencies-wrapper" class="small-bullet row">
                <li class="text-ellipsis col-sm-6" v-for="(proficiency, innerIndex) in practiceArea.proficiencies" :key="proficiency + practiceArea.id + innerIndex">
                  {{ proficiency }}
                </li>
              </ul>
            </div>

            <div v-else-if="canEditProfile" class="text-italic dark-gray-text size-text-12px top-5">
              No Proficiencies
            </div>

            <hr v-if="index !== lawyerPracticeAreas.length - 1">
          </div>
        </display-rules-data-wrapper>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditPracticeAreas from 'vue-app/scout/shared/lawyers/edit-practice-areas.vue';

export default {
  name: 'LawyerPracticeAreas',

  components: {
    DisplayRulesDataWrapper,
    EditPracticeAreas,
    LoadingSection
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type: Object,
      // when rendered for a client view, there is no lawyer
      required: false
    },

    lawyerPracticeAreas: {
      type: Array,
      required: true
    },

    firmPracticeAreas: {
      type: Array,
      default: () => []
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    displayComponent() {
      return this.anyDataIsPresent([this.lawyerPracticeAreas]) || this.canEditProfile;
    },

    practiceAreasWithAlphabetizedProficiencies() {
      return this.lawyerPracticeAreas.map(practiceArea => {
        return {
          ...practiceArea,
          proficiencies: practiceArea.proficiencies.sort()
        };
      });
    }
  }
};
</script>
