<template>
  <div class="titlebar-with-sections split-60-40">
    <div class="titlebar">
      <div class="vertical-center">
        <div class="size-text-24px text-ellipsis">
          {{ workspace.name }}
        </div>
      </div>
    </div>

    <div class="titlebar-switcher text-center">
      <a href="" :class="{ 'selected': selectedTab === 'profile' }" @click.prevent="onSelect('profile', 'main')">Profile</a>
      <a href="" :class="{ 'selected': selectedTab === 'notes' }" @click.prevent="onSelect('notes', 'main')">Notes</a>
      <a href="" :class="{ 'selected': selectedTab === 'history' }" @click.prevent="onSelect('history', 'main')">History</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkspaceTitlebar',

  props: {
    workspace: {
      type:     Object,
      required: true
    },

    selectedTab: {
      type:     String,
      required: true
    },

    onSelect: {
      type:     Function,
      required: true
    }
  }
};
</script>
