<template>
  <color-message-bar color="yellow" :header="header" :message="message">
    <template #icon>
      <svg-icon name="exclamation" class="base-icon warning-icon right-10"></svg-icon>
    </template>
  </color-message-bar>
</template>

<script>
import ColorMessageBar from 'vue-app/shared/components/color-message-bar.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'WarningMessage',

  components: {
    ColorMessageBar,
    SvgIcon
  },

  props: {
    message: {
      type: String,
      required: true
    },

    header: {
      type: String,
      default: 'Warning'
    }
  }
};
</script>
