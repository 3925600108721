<template>
  <priori-modal ref="modal" title="Decline Request" modal-id="decline-rfq-modal">
    <template #modal-trigger="{ openModal }">
      <button type="button" @click="openModal" class="secondary-btn-red">Decline Request</button>
    </template>

    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <div class="semibold-weight size-text-14px bottom-30">
        You are declining to submit a quote for this RFP. {{ quote.workspace.name }} will receive a notification that you have declined this request.
      </div>

      <form role="form" name="form" class="a-form bottom-30" novalidate>
        <textarea-vertical
          :label="`Include a note for ${workspace.name} to provide context for your decision to decline.`"
          name="reason-for-decline"
          :autoresize="true"
          id="reason-for-decline"
          rules="required"
          v-model="reasonForDecline"
          :with-limit="false">
        </textarea-vertical>
      </form>

      <div class="row tight-columns">
        <div class="col-sm-5">
          <loading-button name="declineRequest" class="primary-btn-night-blue" @lb-click="handleSubmit(declineRequest)">Submit</loading-button>
        </div>

        <div class="col-sm-4 top-20-xs">
          <button type="button" class="secondary-btn-blue" @click="reset()">Cancel</button>
        </div>
      </div>
    </validation-observer>
  </priori-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';

export default {
  name: 'DeclineRFQ',

  components: {
    ValidationObserver,
    TextareaVertical
  },

  mixins: {
    interactionModes
  },

  props: {
    quote: {
      type: Object,
      required: true
    },

    onDecline: {
      type: Function,
      required: true
    },

    workspace: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      reasonForDecline: ''
    };
  },

  methods: {
    declineRequest() {
      this.onDecline(this.reasonForDecline);
    },

    reset() {
      this.reasonForDecline = '';
      this.$refs.form.reset();
      this.$refs.modal.closeModal();
    }
  }
};
</script>
