<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Marketplace Actions
    </div>

    <div class="actions-wrapper">
      <div class="flex-column width-100">
        <div class="notice-wrapper width-100">
          <div>
            <span class="bold-weight">Note:</span> These actions will take you to your Marketplace environment.
          </div>
        </div>
      </div>

      <div class="flex-column gap-10 width-100">
        <div class="width-100">
          <a href="/client-app/projects" class="secondary-btn-purple" target="_blank">View Projects</a>
        </div>

        <div class="width-100">
          <a href="/client-app/invoices" class="primary-btn-white" target="_blank">View Invoices</a>
        </div>

        <div class="width-100">
          <a href="/client-app/dashboard?messaging" class="primary-btn-white" target="_blank">Contact Providers</a>
        </div>
      </div>

      <div class="footer-wrapper">
        <svg-icon name="priori" class="icon-sm"></svg-icon> Marketplace
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'FeatureLabMarketplaceActions',

  components: {
    SvgIcon
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .notice-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 20px;
    background: rgba($purple-5, .1);
    border-radius: $border-radius-base;
    border-left: 4px solid $purple-5;
  }

  .icon-sm {
    width: 20px;
    height: 20px;
  }

  .actions-wrapper {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;

    @media (min-width: $screen-sm-min) {
      padding: 20px;
    }

    @media (min-width: $screen-md-min) {
      padding: 30px;
    }
  }

  .footer-wrapper {
    display: flex;
    align-items: center;
    color: $purple-5;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;

    svg {
      margin-right: 3px;
      padding-top: 1px;
      fill: $purple-5;
    }
  }
</style>
