<template>
  <div>
    <dropdown-menu v-if="!isEditing" :compact="true" :align-right="false">
      <template #trigger>
        <button data-role="trigger" type="button" class="pseudo-link-blue blue-text bold-weight d-flex items-center gap-5">Add Question <svg-icon name="caret-down" class="base-icon smaller"></svg-icon></button>
      </template>

      <li class="menu-item" role="menuitem" v-if="hasHiddenQuestions">
        <rfq-template-add-standard-question
          :is-editing="isEditing"
          :group="group">
        </rfq-template-add-standard-question>
      </li>

      <li class="menu-item" role="menuitem">
        <rfq-template-add-custom-question
          :is-editing="isEditing"
          :group="group"
          :question="question">
        </rfq-template-add-custom-question>
      </li>
    </dropdown-menu>

    <rfq-template-add-custom-question
      v-else
      :group="group"
      :is-editing="isEditing"
      :question="question">
    </rfq-template-add-custom-question>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import RfqTemplateAddCustomQuestion from 'vue-app/scout/client/settings/rfqs/templates/new/add-custom-question.vue';
import RfqTemplateAddStandardQuestion from 'vue-app/scout/client/settings/rfqs/templates/new/add-standard-question.vue';
import { mapState } from 'pinia';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';

export default {
  name: 'RfqTemplateQuestionForm',

  components: {
    SvgIcon,
    DropdownMenu,
    RfqTemplateAddCustomQuestion,
    RfqTemplateAddStandardQuestion
  },

  props: {
    group: {
      type: Object,
      default: null
    },

    question: {
      type: Object,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(useRfqTemplateStore, {
      hiddenQuestionsForGroup: 'hiddenQuestionsForGroup'
    }),

    hasHiddenQuestions() {
      return this.hiddenQuestionsForGroup(this.group).length > 0;
    }
  }
};
</script>
