<template>
  <div class="shadowed-box settings-box">
    <div class="box-header text-uppercase">
      Law Firm Data Uploads
    </div>

    <div class="box-content">
      <div class="bold-weight size-text-14px bottom-5">
        Data Uploads
      </div>

      <div class="dark-gray-text bottom-20">
        Upload your law firm data to our platform via API or file transfer.
      </div>

      <div>
        <a href="/scout-firm/data-uploads" class="nv-button-blue"><span class="hidden-sm">Launch </span>Dashboard</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataUploads'
};
</script>

<style lang="scss" scoped>
  .nv-button-blue {
    @media (min-width: 768px) {
      padding: 11px 6px;
    }
  }
</style>
