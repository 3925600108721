<template>
  <div>
    <div class="size-text-15px bold-weight bottom-10">
      Your Firms
    </div>

    <review-response-group
      @change-selected-quote="changeSelectedQuote"
      title="Quote Received"
      :quotes="groupedQuotes['quoteReceived']"
      :rfq="rfq"
      :selected-quote="selectedQuote"
      :show-compare-button="true">
    </review-response-group>

    <review-response-group
      @change-selected-quote="changeSelectedQuote"
      title="Pending Response"
      :quotes="groupedQuotes['pendingResponse']"
      :rfq="rfq"
      :selected-quote="selectedQuote">
    </review-response-group>

    <review-response-group
      @change-selected-quote="changeSelectedQuote"
      title="Declined by Firm"
      :quotes="groupedQuotes['declined']"
      :rfq="rfq"
      :selected-quote="selectedQuote">
    </review-response-group>
  </div>
</template>

<script>
import ReviewResponseGroup from 'vue-app/scout/client/rfqs/review-response-group.vue';
import { groupBy, orderBy } from 'lodash';

export default {
  name: 'ReviewResponsesFirm',

  components: {
    ReviewResponseGroup
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    selectedQuote: {
      type: Object
    },

    updateSelectedQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    orderedQuotes() {
      return orderBy(
        this.rfq.lawFirmQuotes.filter(quote => !quote.fromMarketplace),
        'lawFirm.name'
      );
    },

    groupedQuotes() {
      return groupBy(this.orderedQuotes, (quote) => {
        if (this.quotePending(quote)) {
          return 'pendingResponse';
        }
        else if (this.quoteReceived(quote)) {
          return 'quoteReceived';
        }
        else if (this.quoteDeclined(quote)) {
          return 'declined';
        }
      });
    }
  },

  methods: {
    changeSelectedQuote(id) {
      if (id === this.selectedQuote?.id) { return; }

      this.updateSelectedQuote(this.rfq.lawFirmQuotes.find(quote => quote.id === id));
    },

    quoteReceived(quote) {
      return (quote.status === 'quote_submitted' || quote.status === 'selected_by_client' || quote.status === 'declined_by_client');
    },

    quotePending(quote) {
      return quote.status === 'new_rfq' || quote.status === 'draft';
    },

    quoteDeclined(quote) {
      return quote.status === 'declined_by_firm';
    }
  }
};
</script>
