<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      {{ listType }}
    </div>

    <div class="box-content">
      <div class="tag-list" v-if="collection.length">
        <span :class="['tag-list-item', color]" v-for="item in collection">{{ item[itemKey] }}</span>
      </div>

      <div class="size-text-base gray-text text-center top-10 bottom-10" v-if="!collection.length">
        This lawyer has not added any {{ listType }}.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountList',

  props: {
    listType: {
      type: String,
      required: true
    },

    collection: {
      type: Array,
      default: () => []
    },

    itemKey: {
      type: String,
      required: true
    },

    color: {
      type: String,
      required: true
    }
  }
};
</script>
