<template>
  <div>
    <div class="small-bold-header bottom-15">
      Tasks to Get Started
    </div>

    <div class="shadowed-box bottom-20">
      <div class="border-gradiant"></div>

      <div class="box-content">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-2">
            <svg-icon name="feedback" class="feedback-icon"></svg-icon>
          </div>

          <div class="col-sm-6 col-lg-7 top-20-xs">
            <div class="size-text-20px bottom-5">
              How it works
            </div>

            <div>
              Scout is a platform for you and {{ firm.name }}&rsquo;s top clients to access detailed information about your firms&rsquo; lawyers. Read the FAQs to learn more.
            </div>
          </div>

          <div class="col-sm-4 col-lg-3 top-20-xs">
            <a href="https://priorilegal.gitbook.io/scout-law-firm-guide" class="secondary-btn-blue" target="_blank">Read FAQs</a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="lawyer.hasVisibleProfile" class="shadowed-box bottom-20">
      <div class="border-gradiant"></div>

      <div class="box-content">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-2">
            <svg-icon name="lawyer-profile" class="feedback-icon"></svg-icon>
          </div>

          <div class="col-sm-6 col-lg-7 top-20-xs">
            <div class="size-text-20px bottom-5">
              Review your profile
            </div>

            <div>
              <!-- TODO: Scout -->
            </div>
          </div>

          <div class="col-sm-4 col-lg-3 top-20-xs">
            <a href="/scout-firm/my-profile" class="secondary-btn-blue">View Profile</a>
          </div>
        </div>
      </div>
    </div>

    <div class="shadowed-box">
      <div class="border-gradiant"></div>

      <div class="box-content">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-2">
            <svg-icon name="firm-profile" class="feedback-icon"></svg-icon>
          </div>

          <div class="col-sm-6 col-lg-7 top-20-xs">
            <div class="size-text-20px bottom-5">
              Explore your firm
            </div>

            <div>
              <!-- TODO: Scout -->
            </div>
          </div>

          <div class="col-sm-4 col-lg-3 top-20-xs">
            <a href="/scout-firm/firm-profile" class="secondary-btn-blue">View Firm</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'GetStarted',

  components: {
    SvgIcon
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    lawyer: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .border-gradiant {
    background: linear-gradient($k-purple, $k-blue);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    border-top-left-radius: $border-radius-large;
    border-bottom-left-radius: $border-radius-large;
  }

  .feedback-icon {
    width: 60px;
    height: 66px;
  }
</style>
