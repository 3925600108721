import BaseResource from 'resources/base-resource';
import axios from 'axios';

class ScoutSearch extends BaseResource {
  static baseUrl = '/scout/searches/:id.json';

  static search(params) {
    return axios.post('/scout/searches/search.json', params).then(response => response.data);
  }
}

export default ScoutSearch;
