<template>
  <div>
    <question-answer-text
      :question="question"
      :answer="answer"
      :show-question-update-tag="showQuestionUpdateTag"
      v-if="isTextType">
    </question-answer-text>

    <question-answer-range
      :question="question"
      :answer="answer"
      :show-question-update-tag="showQuestionUpdateTag"
      v-else-if="isRangeType">
    </question-answer-range>

    <question-answer-option
      :question="question"
      :answer="answer"
      :show-question-update-tag="showQuestionUpdateTag"
      v-else-if="isOptionType">
    </question-answer-option>

    <question-answer-file
      :question="question"
      :answer="answer"
      :show-question-update-tag="showQuestionUpdateTag"
      v-else-if="isFileType">
    </question-answer-file>

    <div class="top-20" v-if="hasDisplayableDependents">
      <div class="list-spacing-20" v-for="dependent in orderedDependents" :key="dependent.id">
        <question-answer
          :question-group="questionGroup"
          :question="dependent"
          :answers="answers"
          :quote-last-submitted-at="quoteLastSubmittedAt"
          :show-question-update-tags="showQuestionUpdateTags">
        </question-answer>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionAnswerText from './question-answer-text.vue';
import QuestionAnswerRange from './question-answer-range.vue';
import QuestionAnswerOption from './question-answer-option.vue';
import QuestionAnswerFile from './question-answer-file.vue';
import dateComparisons from 'vue-app/shared/mixins/date-comparisons.js';
import { orderBy } from 'lodash';

export default {
  name: 'QuestionAnswer',

  components: {
    QuestionAnswerText,
    QuestionAnswerRange,
    QuestionAnswerOption,
    QuestionAnswerFile
  },

  mixins: [dateComparisons],

  props: {
    questionGroup: {
      type: Object,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    answers: {
      type: Array,
      required: true
    },

    showQuestionUpdateTags: {
      type: Boolean,
      required: true
    },

    quoteLastSubmittedAt: {
      type: String,
      default: null
    }
  },

  computed: {
    isTextType() {
      return this.question.questionType === 'text';
    },

    isRangeType() {
      return this.question.questionType === 'range';
    },

    isOptionType() {
      return this.question.questionType === 'option';
    },

    isFileType() {
      return this.question.questionType === 'file_upload';
    },

    answer() {
      return this.answers.find(a => a.scoutRfqQuestionId === this.question.id);
    },

    dependents() {
      return this.questionGroup.questions.filter(q => q.prerequisiteQuestionId === this.question.id);
    },

    displayableDependents() {
      return this.dependents.filter(q => this.answer?.value === q.prerequisiteQuestionAnswer);
    },

    orderedDependents() {
      return orderBy(this.displayableDependents, 'position');
    },

    hasDisplayableDependents() {
      return this.displayableDependents.length > 0;
    },

    showQuestionUpdateTag() {
      if (!this.showQuestionUpdateTags) { return false; }
      if (!this.quoteLastSubmittedAt && this.question.latestActivity) { return true; }

      return this.dateIsAfter(this.question.latestActivity?.createdAt, this.quoteLastSubmittedAt);
    }
  }
};
</script>
