<template>
  <div class="row vertical-center-not-xs">
    <div class="col-sm-4">
      <lawyer-name-plate
        :lawyer="lawyerBridge"
        photo-size="small"
        :compact="true"
        :show-favorite="false"
        :show-lawyer-link="true">
      </lawyer-name-plate>
    </div>

    <div class="col-sm-2 top-10-xs">
      <span v-if="lawyerBridge.rate">{{ lawyerBridge.rate }}/hr</span>
      <span v-else>&mdash;</span>
    </div>

    <div class="col-sm-3 top-10-xs">
      <currency-input-vertical
        v-model="proposedHourlyRate"
        :id="`lawyer-rate-${suggestedLawyer.id}`"
        :name="`lawyerRate${suggestedLawyer.id}`"
        :rules="rateValidationRules"
        @input="handleInput">
      </currency-input-vertical>
    </div>

    <div class="col-sm-3 text-right-not-xs">
      <toggle-button
        :id="`toggle-lawyer-${suggestedLawyer.id}`"
        :value="includedInQuote"
        true-text="Yes"
        false-text="No"
        size="sm"
        @click="toggleIncludeLawyer">
      </toggle-button>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge';

export default {
  name: 'QuoteFormClientSuggestedLawyer',

  components: {
    LawyerNamePlate,
    CurrencyInputVertical,
    ToggleButton
  },

  props: {
    suggestedLawyer: {
      type: Object,
      required: true
    },

    rateIsRequired: {
      type: Boolean,
      default: true
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      proposedHourlyRate: this.suggestedLawyer.proposedHourlyRate ? Number.parseFloat(this.suggestedLawyer.proposedHourlyRate).toFixed(2) : null,
      includedInQuote: this.suggestedLawyer.includedInQuote
    };
  },

  computed: {
    lawyerBridge() {
      return new ScoutLawyerBridge(this.suggestedLawyer.scoutLawyerBridge);
    },

    rateValidationRules() {
      return {
        minValue: 0.01,
        required: this.rateIsRequired && this.includedInQuote
      };
    }
  },

  methods: {
    toggleIncludeLawyer() {
      this.includedInQuote = !this.includedInQuote;
      this.onChange(this.suggestedLawyer, 'includedInQuote', this.includedInQuote);
    },

    handleInput() {
      this.onChange(this.suggestedLawyer, 'proposedHourlyRate', this.proposedHourlyRate);
    }
  }
};
</script>
