<template>
  <validation-provider :rules="'required'" v-slot="{ errors }" :mode="passiveAggressive">
    <div class="row bottom-20">
      <div class="col-sm-4 ">
        <label :class="{ 'no-margin': !editing }">Practice Area(s) <span class="inline-help required">*</span></label>
      </div>

      <div v-if="editing" class="col-sm-8">
        <div class="row">
          <div class="col-sm-6">
            <multi-select-dropdown
              :value="selectedPracticeAreaNames"
              :options="practiceAreaNames"
              :show-typeahead="false"
              :invalid="errors.length > 0"
              placeholder="Add a Practice Area"
              @change="updatePracticeAreas">
            </multi-select-dropdown>
          </div>
        </div>

        <tag-list
          v-if="selectedPracticeAreaNames.length"
          class="top-10"
          :tags="selectedPracticeAreas"
          :on-remove="removePracticeAreaName">
        </tag-list>
      </div>

      <div v-else class="col-sm-8">
        {{ practiceAreasDisplay }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import TagList from 'vue-app/scout/shared/tag-list.vue';
import MultiSelectDropdown from 'vue-app/shared/components/multi-select-dropdown.vue';
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';

export default {
  name: 'OpportunityPracticeAreas',

  components: {
    TagList,
    MultiSelectDropdown,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    editing: {
      type: Boolean,
      default: true
    },

    selectedPracticeAreas: {
      type: Array,
      required: true
    },

    practiceAreas: {
      type: Array,
      required: true
    },

    updatePracticeAreas: {
      type: Function,
      required: true
    },

    removePracticeAreaName: {
      type: Function,
      required: true
    }
  },

  computed: {
    practiceAreasDisplay() {
      return this.selectedPracticeAreaNames.join(', ') || '—';
    },

    practiceAreaNames() {
      return this.practiceAreas.map(tag => tag.name);
    },

    selectedPracticeAreaNames() {
      return this.selectedPracticeAreas.map(tag => tag.name);
    }
  }
};
</script>

