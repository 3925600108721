<template>
  <div>
    <div v-if="editing">
      <hr class="kenny-hr">
      <div class="size-text-15px bold-weight bottom-10">
        Opportunity Application Form
      </div>

      <div class="missing-text bottom-20">
        Please add <span class="semibold-weight">up to seven questions</span> that lawyers should be prompted to answer in applying for the opportunity. Note: These questions will appear in the Opportunity Application Form and not on the Opportunities Page.
      </div>
    </div>

    <div v-for="(question, index) in opportunityQuestions" :class="`bottom-20 deselect-input opportunity-question-${index}`" :key="`opportunity-question-${index}`">
      <div>
        <span class="semibold-weight">Question {{ index + 1 }}</span>
        <span class="text-italic">({{ question.questionType === 'text' ? 'Free Text' : 'Numeric' }})</span>
        <a v-if="editing" href="" class="left-15" @click.prevent="removeQuestion(index)">Remove</a>
      </div>

      <div v-if="editing" class="top-10">
        <input type="text" :name="`question${index + 1}`" :id="`question-${index + 1}`" v-model="question.questionText" class="form-control">
      </div>

      <div v-else class="top-2">
        {{ question.questionText }}
      </div>
    </div>

    <div v-if="editing && opportunityQuestions.length < 7" class="text-right-not-xs">
      <button type="button" class="right-20 small-btn" @click="addQuestion('text')">+ Free Text Question</button>

      <button type="button" class="small-btn top-10-xs" @click="addQuestion('numeric')">+ Numeric Question</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpportunityQuestions',

  props: {
    editing: {
      type: Boolean,
      default: true
    },

    opportunityQuestions: {
      type: Array,
      required: true
    }
  },

  methods: {
    removeQuestion(index) {
      this.opportunityQuestions.splice(index, 1);
    },

    addQuestion(type) {
      this.opportunityQuestions.push({ questionType: type, questionText: '' });
    }
  }
};
</script>

