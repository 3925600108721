<template>
  <next-steps-stepper>
    <template #default="{ activeStep, moveToNextStep, moveToPrevStep }">
      <div v-if="activeStep === 1">
        <confirm-budget
          :rfq="rfq">
        </confirm-budget>

        <div v-if="showContinueButton" class="text-right top-30">
          <a href="" class="blue-text bold-weight" @click.prevent="moveToNextStep">Continue<svg-icon name="arrow-right" class="base-icon left-10"></svg-icon></a>
        </div>
      </div>

      <div v-if="activeStep === 2">
        <connect-matter
          :rfq="rfq">
        </connect-matter>

        <div class="top-30">
          <a href="" class="blue-text bold-weight" @click.prevent="moveToPrevStep"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Previous</a>
        </div>
      </div>
    </template>
  </next-steps-stepper>
</template>

<script>
import NextStepsStepper from 'vue-app/scout/client/rfqs/next-steps/stepper.vue';
import ConfirmBudget from 'vue-app/scout/client/rfqs/next-steps/confirm-budget.vue';
import ConnectMatter from 'vue-app/scout/client/rfqs/next-steps/connect-matter.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RfqNextSteps',

  components: {
    NextStepsStepper,
    ConfirmBudget,
    ConnectMatter,
    SvgIcon
  },

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  computed: {
    showContinueButton() {
      return !!this.rfq.confirmedBudgetInUsCents;
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/scout/variables" as *;

  :deep(.svg-circle) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: $white;
    color: $newblue-5;
    flex-shrink: 0;

    .base-icon {
      stroke: $newblue-5;
    }
  }

  :deep(.next-steps-content) {
    background: $blue-10;
    border: 1px solid $newblue-9;
    padding: 20px 30px;
    border-radius: $border-radius-base;

    &.completed {
      background: $white;
      border: 1px solid $medium-gray;
      align-items: flex-start;

      .svg-circle {
        background: $blue-10;
      }
    }
  }
</style>
