<template>
  <div class="shadowed-box settings-box">
    <div class="box-header text-uppercase">
      Locked Data
    </div>

    <div class="box-content">
      <div class="bold-weight size-text-14px bottom-5">
        Locked Data
      </div>

      <div class="dark-gray-text bottom-20">
        When a lawyer edits their own profile information, the data is locked and will not be overwritten by the next law firm data upload. There is no way to currently unlock the data.
      </div>

      <div>
        <a href="/scout-firm/data-locks" class="nv-button-blue">View Data</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataLocks'
};
</script>
