import { each } from 'lodash';
import LoadingService from 'vue-app/shared/services/loading-service';

class AdminPaginationService {
  constructor(resource, collectionName, serializableView, additionalParams) {
    this.currentPage = 1;
    this.perPage = 25;
    this.totalEntries = 0;

    this.resource = resource;
    this.collectionName = collectionName;

    this.queryParams = {
      page: this.currentPage,
      per_page: this.perPage
    };
    if (serializableView) {
      this.queryParams.view = serializableView;
    }
    if (additionalParams) {
      each(additionalParams, (params, key) => { this.queryParams[key] = params; });
    }
  }

  loadResources() {
    LoadingService.loading(this.collectionName);

    return this.resource.queryData(this.queryParams).then((response) => {
      this.totalEntries = response.metadata.total;
      LoadingService.done(this.collectionName);

      return response.resources;
    });
  }

  setPage(page) {
    this.currentPage = page;
    this.queryParams.page = page;
  }

  pageChanged(page) {
    this.setPage(page);
    return this.loadResources();
  }
}

export default AdminPaginationService;
