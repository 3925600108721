<template>
  <div class="clear-panel compact-panel">
    <div class="notification-row">
      <div class="row tight-columns bold-weight bottom-10">
        <div :class="['text-ellipsis', (isConnecting ? 'col-xs-12' : 'col-sm-8')]">
          {{ workspaceName }}
        </div>

        <div v-if="!isConnecting" class="col-sm-4 text-right-not-xs">
          <a :href="`/scout-firm/clients/${workspaceId}/rfps`" class="bold-weight">View Client RFPs</a>
        </div>
      </div>

      <div v-for="(notification, index) in notifications" :key="index" class="pill-gray-text left-15">
        <hr v-if="index !== 0" class="top-10 bottom-10">

        <div :class="['notification-icon inline-block right-10', iconNameMap[notification.type]]">
          <svg-icon :name="iconNameMap[notification.type]" class="base-icon"></svg-icon>
        </div>

        {{ notification.message }}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RfqNotification',

  components: {
    SvgIcon
  },

  props: {
    notifications: {
      type: Array,
      required: true
    },

    isConnecting: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      iconNameMap: {
        'action_required': 'notification',
        'new_messages': 'envelope'
      }
    };
  },

  computed: {
    workspaceName() {
      return this.notifications[0].workspace.name;
    },

    workspaceId() {
      return this.notifications[0].workspace.id;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .notification-row {
    .notification-icon {
      position: relative;
      width: 22px;

      .base-icon {
        width: 20px;
        height: 20px;
        fill: $k-dark-gray;
      }

      &::after {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: $scout-red;
        border-radius: 50%;
      }

      &.envelope::after {
        right: -2px;
      }
    }

    hr {
      border-color: $k-light-gray;
    }
  }
</style>
