<template>
  <div class="clear-panel no-padding">
    <div class="right-10 left-10">
      <div class="row tight-columns top-10 bottom-10">
        <div class="col-md-4">
          <profile-photo :user="user" :small="true"></profile-photo>
        </div>

        <div class="col-md-8 top-10-xs-sm">
          <div class="text-ellipsis">
            <a :href="`/admin-app/clients/${user.id}`" target="_blank">{{ user.fullName }}</a>
          </div>

          <div class="dark-gray-text text-ellipsis">
            {{ user.role || '&mdash;' }}
            <span v-if="user.deactivatedAt" class="red-text text-italic">(Deactivated)</span>
          </div>
        </div>
      </div>

      <div class="size-text-9px bottom-5" v-if="isScout">
        <span :class="['text-uppercase bold-weight right-10', { 'scout-purple-text': user.isScout, 'gray-text': !user.isScout }]">Scout</span>

        <span :class="['text-uppercase bold-weight', { 'marketplace-blue-text': user.isMarketplace, 'gray-text': !user.isMarketplace }]">Marketplace</span>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'AdminWorkspaceUser',

  components: {
    ProfilePhoto
  },

  props: {
    user: {
      type: Object,
      required: true
    },

    isScout: {
      type: Boolean,
      default: false
    }
  }
};
</script>
