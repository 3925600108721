<template>
  <div class="sidenav-content">
    <div class="container-fluid">
      <div class="padded-container less-padded">
        <div class="row links-container">
          <div class="col-sm-6">
            <a :href="`/scout-company/rfps/${rfq.id}`" class="back-link" target="_self"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to RFP Overview</a>
          </div>

          <div class="col-sm-6 text-right-not-xs">
            <a :href="`/scout/rfqs/${rfq.id}/comparison.csv`" target="_blank" class="bold-weight">Download Comparison</a>
          </div>
        </div>

        <div class="vertical-center-not-xs header-container">
          <div class="medium-page-heading">
            <b>Compare RFP Responses:</b> {{ rfq.name || `Reference #${rfq.id}` }}
          </div>
        </div>

        <table id="rfq-comparison-grid" class="rfq-comparison-grid">
          <thead>
            <tr>
              <th class="header-cell">
                <div class="row tight-columns vertical-center">
                  <div class="col-xs-4">
                    Questions
                  </div>

                  <div class="col-xs-8 size-text-10px dark-gray-text text-right">
                    <button type="button" class="pseudo-link-gray" @click="expandAll">Expand All</button>
                    |
                    <button type="button" class="pseudo-link-gray" @click="collapseAll">Collapse All</button>
                  </div>
                </div>
              </th>

              <th v-for="col in quoteColumns" class="header-cell">
                <!-- empty -->
              </th>
            </tr>

            <tr>
              <th class="firm-detail-cell">
                <!-- empty -->
              </th>

              <firm-header-cell
                v-for="quote in orderedQuotes"
                :key="quote.id"
                :rfq="rfq"
                :quote="quote">
              </firm-header-cell>
            </tr>
          </thead>

          <tbody>
            <answer-row
              v-for="question in orderedQuestions"
              :ref="`answerRow${question.id}`"
              :key="question.id"
              :question="question"
              :quotes="orderedQuotes"
              :on-collapse="setNotAllExpanded">
            </answer-row>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import FirmHeaderCell from 'vue-app/scout/client/rfqs/comparison/firm-header-cell.vue';
import AnswerRow from 'vue-app/scout/client/rfqs/comparison/answer-row.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { compact, orderBy } from 'lodash';

export default {
  name: 'RfqComparison',

  components: {
    FirmHeaderCell,
    AnswerRow,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      rfq: this.initData.rfq,
      allExpanded: false
    };
  },

  computed: {
    quotes() {
      return this.rfq.lawFirmQuotes.filter(q => (q.status === 'selected_by_client' || q.status === 'quote_submitted') && !q.fromMarketplace);
    },

    orderedQuotes() {
      return orderBy(this.quotes, 'lawFirm.name');
    },

    quotesCount() {
      return this.quotes.length;
    },

    quoteColumns() {
      return [...Array(this.quotesCount).keys()];
    },

    groupsWithQuestions() {
      return this.rfq.questionGroups.filter(group => group.questions.some(question => question.isIncluded && !question.prerequisiteQuestionId));
    },

    // TODO: build empty state
    hasIncludedQuestions() {
      return this.groupsWithQuestions.length > 0;
    },

    orderedQuestionGroups() {
      return orderBy(this.groupsWithQuestions, 'position');
    },

    orderedQuestions() {
      let ordered;
      let contingent;

      return this.orderedQuestionGroups.flatMap((group) => {
        ordered = orderBy(group.questions.filter(q => q.isIncluded && !q.prerequisiteQuestionId), 'position');

        return ordered.flatMap((question) => {
          contingent = group.questions.find(q => q.prerequisiteQuestionId === question.id);
          return compact([question, contingent]);
        });
      });
    }
  },

  methods: {
    download() {
      // TODO
    },

    expandAll() {
      this.orderedQuestions.forEach((question) => {
        this.$refs[`answerRow${question.id}`][0].expand();
      });

      this.allExpanded = true;
    },

    collapseAll() {
      this.orderedQuestions.forEach((question) => {
        this.$refs[`answerRow${question.id}`][0].collapse();
      });

      this.allExpanded = false;
    },

    setNotAllExpanded() {
      this.allExpanded = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .links-container {
    margin-bottom: 10px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 15px;
    }
  }

  .header-container {
    margin-bottom: 10px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 30px;
    }
  }

  .medium-page-heading {
    display: block;
    height: 60px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: $screen-sm-min) {
      height: 30px;
      -webkit-line-clamp: 1;
    }
  }

  .rfq-comparison-grid {
    display: grid;
    max-height: calc(100vh - 250px);
    width: fit-content;
    max-width: 100%;
    border: 1px solid $gray-6;
    background-color: $white;
    border-collapse: collapse;
    overflow-x: scroll;
    overscroll-behavior: none;
    grid-template-columns: 300px repeat(v-bind(quotesCount), 360px);

    @media (min-width: $screen-sm-min) {
      max-height: calc(100vh - 215px);
    }

    @media (min-width: $screen-md-min) {
      max-height: calc(100vh - 155px);
    }

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      background: $bg-color;

      &:vertical {
        border-left: 1px solid $gray-6;
      }

      &:horizontal {
        border-top: 1px solid $gray-6;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-6;
      -webkit-border-radius: 4px;
    }

    &::-webkit-scrollbar-corner {
      background: $k-light-gray;
      border-top: 1px solid $gray-6;
      border-left: 1px solid $gray-6;
    }

    @supports (not selector(::-webkit-scrollbar)) {
      scrollbar-width: thin;
      scrollbar-gutter: stable;
      scrollbar-color: transparent;
    }

    thead, tbody, tr {
      display: contents;
    }

    th, :deep(td) {
      padding: 15px;
      border-bottom: 1px solid $gray-6;
      border-left: 1px solid $gray-6;
      background-color: $white;
      font-weight: normal;

      &:first-child {
        background-color: $bg-color;
        font-weight: 700;
        border-left: 0;
      }
    }

    tr:last-child :deep(td) {
      border-bottom: 0;
    }

    th {
      z-index: 1;
    }

    th.header-cell {
      position: sticky;
      top: 0;
      height: 40px;
      padding: 10px;
      background-color: $blue-10;
      text-transform: uppercase;
    }

    th.firm-detail-cell {
      position: sticky;
      top: 40px;
      height: 170px;
      background-color: $white;

      &:first-child {
        background-color: $bg-color;
      }

      .firm-name-container {
        max-height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 21px;
      }

      .buttons-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    button.pseudo-link-gray {
      color: $gray-4;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;

      &:hover, &:focus {
        color: $pill-gray;
        text-decoration: underline;
      }
    }
  }
</style>
