import { defineStore } from 'pinia';

export default defineStore('viewingAsSwitcher', {
  state: () => {
    return {
      initialViewUrl: null
    };
  },

  actions: {
    setInitialViewUrl(url) {
      this.initialViewUrl = url;
    }
  }
});
