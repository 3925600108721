<template>
  <div>
    <div>
      <div v-if="hasLawyers" class="main-chart">
        <div class="size-text-12px text-center">
          <span class="bold-weight">Total Lawyers:</span>
          {{ totalLawyers }}
        </div>

        <div class="top-30 bottom-30">
          <chart-inline-legend :legend-data="legendData"></chart-inline-legend>
        </div>

        <div>
          <bar-chart-multiple :chart-data="ethnicityData" :as-percent="true"></bar-chart-multiple>
        </div>
      </div>

      <div v-else class="gray-text size-text-16px">
        No Ethnicity Data
      </div>
    </div>

    <hr>

    <div class="row tight-columns">
      <div class="col-md-7">
        <div class="gender-graph" v-if="hasLawyers">
          <doughnut-chart-with-legend :chart-data="genderData" :doughnut-width="'140px'" :doughnut-height="'140px'">
            <div class="count-container">
              <div class="vertical-spacer">
                <div class="count-sum">
                  {{ totalLawyers }}
                </div>

                <div class="count-desc">
                  Total Lawyers
                </div>
              </div>
            </div>
          </doughnut-chart-with-legend>
        </div>

        <div v-else class="gray-text size-text-16px">
          No Gender Data
        </div>
      </div>

      <div class="col-md-5 text-center rank-container">
        <div class="rank-label">
          Am Law Diversity Scorecard Rank
        </div>

        <div class="size-text-21px semibold-weight rank-content">
          <div class="gray-award">
            #{{ statistic.amlawDiversityRank }}
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-sm-4 counter-container">
        <firm-diversity-detail-count label="LGBTQ+ Individuals" :value="countLgbtq"></firm-diversity-detail-count>
      </div>

      <div class="col-sm-4 counter-container">
        <firm-diversity-detail-count label="Individuals With Disabilities" :value="countWithDisabilities"></firm-diversity-detail-count>
      </div>

      <div class="col-sm-4 counter-container">
        <firm-diversity-detail-count label="Veterans" :value="countVeterans"></firm-diversity-detail-count>
      </div>
    </div>

    <div class="data-background-container" v-if="diStatisticExtra.dataBackground !== ''">
      <hr>

      <div>
        <span class="simple-text">{{ diStatisticExtra.dataBackground }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PrioriColors from 'vue-app/shared/misc/priori-colors';
import BarChartMultiple from 'vue-app/shared/components/bar-chart-multiple.vue';
import ChartInlineLegend from 'vue-app/shared/components/chart-inline-legend.vue';
import DoughnutChartWithLegend from 'vue-app/shared/components/doughnut-chart-with-legend.vue';
import FirmDiversityDetailCount from './firm-diversity-detail-count.vue';
import { map, sumBy } from 'lodash';
import Ethnicity from 'resources/ethnicity';

export default {
  name: 'FirmDiversityDetail',

  components: {
    BarChartMultiple,
    ChartInlineLegend,
    DoughnutChartWithLegend,
    FirmDiversityDetailCount
  },

  props: {
    statistic: {
      type: Object,
      required: true
    },

    diStatisticExtra: {
      type: Object
    }
  },

  computed: {
    totalFemale: function () {
      return sumBy(this.statistic.ethnicityStatistics, 'countFemale');
    },

    totalMale: function () {
      return sumBy(this.statistic.ethnicityStatistics, 'countMale');
    },

    totalNonBinary: function () {
      return sumBy(this.statistic.ethnicityStatistics, 'countNonBinary');
    },

    totalLawyers: function () {
      return this.totalFemale + this.totalMale + this.totalNonBinary + this.totalNotDisclosed;
    },

    hasLawyers() {
      return this.totalLawyers > 0;
    },

    ethnicityData: function () {
      const stats =  map(this.statistic.ethnicityStatistics, (statistic) => {
        return {
          name: statistic.ethnicity,
          internalName: statistic.internalName,
          values: [
            {
              value: statistic.countFemale,
              percentage: (statistic.countFemale / this.totalLawyers) * 100,
              color: this.femaleColor()
            },
            {
              value: statistic.countMale,
              percentage: (statistic.countMale / this.totalLawyers) * 100,
              color: this.maleColor()
            },
            {
              value: statistic.countNonBinary,
              percentage: (statistic.countNonBinary / this.totalLawyers) * 100,
              color: this.nonBinaryColor()
            },
            {
              value: statistic.countNotDisclosed,
              percentage: (statistic.countNotDisclosed / this.totalLawyers) * 100,
              color: this.notDisclosedColor()
            }
          ]
        };
      });

      return Ethnicity.sortForDisplay(stats);
    },

    genderData: function () {
      return [
        {
          name: 'Female',
          value: this.totalFemale,
          color: this.femaleColor()
        },
        {
          name: 'Male',
          value: this.totalMale,
          color: this.maleColor()
        },
        {
          name: 'Non-binary',
          value: this.totalNonBinary,
          color: this.nonBinaryColor()
        },
        {
          name: 'Not disclosed',
          value: this.totalNotDisclosed,
          color: this.notDisclosedColor()
        }
      ];
    },

    legendData: function () {
      return [
        {
          name: 'Female',
          color: this.femaleColor()
        },
        {
          name: 'Male',
          color: this.maleColor()
        },
        {
          name: 'Non-binary',
          color: this.nonBinaryColor()
        },
        {
          name: 'Not disclosed',
          color: this.notDisclosedColor()
        }
      ];
    },

    countLgbtq() {
      return this.statistic.countLgbtq;
    },

    countWithDisabilities() {
      return this.statistic.countWithDisabilities;
    },

    countVeterans() {
      return this.statistic.countVeterans;
    },

    totalNotDisclosed: function () {
      return sumBy(this.statistic.ethnicityStatistics, 'countNotDisclosed');
    }
  },

  methods: {
    femaleColor: function () {
      return PrioriColors.red();
    },

    maleColor: function () {
      return PrioriColors.purple();
    },

    nonBinaryColor: function () {
      return PrioriColors.blue();
    },

    notDisclosedColor: function () {
      return PrioriColors.teal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .main-chart {
    @media (min-width: 768px) {
      padding-bottom: 50px;
    }
  }

  .count-container {
    top: 0;
    left: calc(50% - 70px);
    width: 140px;
    height: 140px;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      left: 0;
    }

    .count-sum {
      font-size: 26px;
      line-height: 30px;
    }

    .count-desc {
      color: $k-dark-gray;
      font-size: 11px;
    }
  }

  .gender-graph {
    :deep(.canvas-container) {
      @media (min-width: 992px) {
        padding-right: 15px;
      }
    }

    :deep(.chart-legend) {
      font-size: 10px;
    }

    :deep(.legend) {
      @media (min-width: 768px) {
        width: calc(100% - 160px);
      }

      @media (min-width: 992px) {
        width: calc(100% - 155px);
      }
    }
  }

  .rank-container {
    margin-top: 40px;

    @media (min-width: 992px) {
      margin-top: 0;
      border-left: 1px solid $k-light-gray;
    }

    .rank-label {
      height: 40px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.4;
    }

    .rank-content {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .gray-award {
    background-image: url("../../../../images/gray-award.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;
  }

  .counter-container {
    @media (min-width: 768px) {
      &:not(:first-child) {
        border-left: 1px solid $k-light-gray;
      }
    }
  }
</style>
