<template>
  <div>
    <div class="row tight-columns bottom-20">
      <div class="col-sm-6">
        <text-input-vertical
          id="notif-first-name"
          label="First Name"
          rules="required"
          v-model="recipient.firstName">
        </text-input-vertical>
      </div>

      <div class="col-sm-6 top-20-xs">
        <text-input-vertical
          id="notif-last-name"
          label="Last Name"
          rules="required"
          v-model="recipient.lastName">
        </text-input-vertical>
      </div>
    </div>

    <div class="bottom-20">
      <text-input-vertical
        id="notif-email"
        label="Email"
        :rules="{ required: true, email: true, unique: { list: existingEmails(recipient.email), errorMessage: 'This email address is already set to receive a notification for this RFP.' } }"
        type="email"
        v-model="recipient.email">
      </text-input-vertical>
    </div>

    <warning-message
      v-if="showMismatchedDomainWarning"
      class="bottom-20"
      message="This domain does not match the domain for provisioned users at this law firm."
      header="Note">
    </warning-message>

    <div class="vertical-center flex-end-justified gap-10">
      <button type="button" class="darker-gray-link gap-10 pseudo-link vertical-center" @click="onRemove(recipient)">
        <svg-icon name="minus-circle" class="base-icon"></svg-icon> Remove
      </button>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import WarningMessage from 'vue-app/shared/components/warning-message.vue';

export default {
  name: 'RfqContactFirmAdditionalRecipient',

  components: {
    TextInputVertical,
    SvgIcon,
    WarningMessage
  },

  props: {
    recipient: {
      type: Object,
      required: true
    },

    onRemove: {
      type: Function,
      required: true
    },

    provisionedEmails: {
      type: Array,
      required: true
    },

    additionalRecipients: {
      type: Array,
      required: true
    }
  },

  computed: {
    showMismatchedDomainWarning() {
      if (this.isPartialEmailValid()) {
        return this.checkInvalidDomain();
      }

      return false;
    },

    provisionedDomains() {
      const domains = [];

      this.provisionedEmails.forEach(email => {
        const domain = email.split('@')[1];

        if (domain) {
          domains.push(domain);
        }
      });
      return this.filterDuplicates(domains);
    }
  },

  methods: {
    existingEmails(recipientEmail) {
      const otherEmails = this.additionalRecipients.map(ar => ar.email);
      const emailIndex = otherEmails.indexOf(recipientEmail);
      if (emailIndex > -1) {
        otherEmails.splice(emailIndex, 1);
      }
      return this.provisionedEmails.concat(otherEmails);
    },

    filterDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    },

    isPartialEmailValid() {
      const regex = /.+@.+\..+/;
      return regex.test(this.recipient.email);
    },

    checkInvalidDomain() {
      const domain = this.recipient.email.split('@')[1];
      return !this.provisionedDomains.includes(domain);
    }
  }
};
</script>
