<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-9 text-uppercase">
          Diversity &amp; Inclusion
        </div>

        <div class="col-sm-3 size-text-12px normal-weight text-right-not-xs">
          <edit-lawyer-diversity-inclusion v-if="canEditProfile" :lawyer="lawyer" :workspaces="workspaces" :on-save="onSave"></edit-lawyer-diversity-inclusion>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="bold-weight size-text-12px tight-lines bottom-10">
        This attorney has identified as:
      </div>

      <div class="dark-gray-text text-italic" v-if="!canEditProfile && lawyerChoseNotToDiscloseInformation">
        Diversity Disclosure Not Available
      </div>

      <data-wrapper v-else :data="diversityAndInclusionData">
        <ul class="small-bullet">
          <li v-for="item in diversityAndInclusionData" :key="item" class="text-ellipsis">
            {{ item }}
          </li>
        </ul>
      </data-wrapper>
    </div>
  </div>
</template>

<script>
import EditLawyerDiversityInclusion from 'vue-app/scout/shared/lawyers/edit-lawyer-diversity-inclusion.vue';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import { compact, map, some } from 'lodash';

export default {
  name: 'LawyerDiversityInclusion',

  components: {
    DataWrapper,
    EditLawyerDiversityInclusion
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    workspaces: {
      type: Array,
      default: () => []
    },

    viewingAsGenericClient: {
      type: Boolean,
      default: false
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    prefersNotToDiscloseEthnicity() {
      return some(this.lawyer.userEthnicities, (userEthnicity) => {
        return userEthnicity.ethnicityInternalName === 'prefer_not_disclose';
      });
    },

    ethnicityData() {
      if (this.prefersNotToDiscloseEthnicity) {
        return [];
      }
      else {
        return map(this.lawyer.userEthnicities, 'ethnicityName');
      }
    },

    genderData() {
      let data = [];

      this.lawyer.genderProfileTaggings.forEach(genderProfileTagging => {
        if (genderProfileTagging.internalName !== 'not_disclosed') {
          data.push(genderProfileTagging.presentedName);
        }
      });

      return data;
    },

    additionalInformationData() {
      let data = [];

      if (this.lawyer.identifiesAsLgbtq === 'identifiesAsLgbtq') {
        data.push('LGBTQ+');
      }

      if (this.lawyer.identifiesAsDifferentlyAbled === 'identifiesAsDifferentlyAbled') {
        data.push('Disabled Person');
      }

      if (this.lawyer.isMilitaryVeteran === 'isMilitaryVeteran') {
        data.push('Military Veteran');
      }

      return data;
    },

    viewPermission() {
      return this.lawyer.diversityInclusionViewPermission;
    },

    lawyerChoseNotToDiscloseInformation() {
      return !this.viewPermission || (
        !this.viewPermission.clientsCanView &&
        !this.viewPermission.lawyersCanView &&
        !this.viewPermission.selectWorkspacesCanView
      );
    },

    diversityAndInclusionData() {
      return compact(
        this.ethnicityData
          .concat(this.genderData)
          .concat(this.additionalInformationData)
      ).sort();
    },

    genericClientCanView() {
      return this.viewPermission && this.viewPermission.clientsCanView;
    },

    displayComponent() {
      if (this.viewingAsGenericClient) {
        return this.genericClientCanView;
      }
      else {
        return this.canEditProfile || this.anyDataIsPresent([
          this.ethnicityData,
          this.genderData,
          this.additionalInformationData
        ]);
      }
    }
  }
};
</script>
