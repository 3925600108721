<template>
  <div class="row">
    <div class="col-sm-9">
      <div class="bottom-30">
        <lead-search-keywords
          :search-service="searchService"
          :current-search="currentSearch"
          :on-search-applied="applySearch">
        </lead-search-keywords>
      </div>

      <loading-section name="leadSearchResults">
        <div class="bottom-40" v-for="result in searchResults" :key="uniqueId(result)">
          <lead-search-result :lawyer-lead="result"></lead-search-result>
        </div>

        <div v-if="searchResults.length">
          <search-pagination
            :search-service="searchService"
            :on-change-page="changePage">
          </search-pagination>
        </div>

        <div class="shadowed-box" v-if="!searchResults.length">
          <div class="box-content">
            <div class="top-30 bottom-30 gray-text text-center">
              There were no results for those search criteria.
            </div>
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LeadSearchKeywords from './lead-search-keywords.vue';
import LeadSearchResult from './lead-search-result.vue';
import SearchPagination from 'vue-app/admin/shared/search-pagination.vue';

export default {
  name: 'LeadSearch',

  components: {
    LoadingSection,
    LeadSearchKeywords,
    LeadSearchResult,
    SearchPagination
  },

  props: {
    searchService: {
      type: Object,
      required: true
    },

    currentSearch: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      searchResults: []
    };
  },

  watch: {
    currentSearch() {
      this.applySearch();
    }
  },

  methods: {
    uniqueId(lawyerLead) {
      return lawyerLead.id || lawyerLead.salesforceLeadId;
    },

    applySearch() {
      LoadingService.loading('leadSearchResults');

      this.searchService.applySearch((results) => {
        this.searchResults = results;
        LoadingService.done('leadSearchResults');
      });
    },

    changePage(page) {
      LoadingService.loading('leadSearchResults');

      this.searchService.changePage(page).then((leadSearch) => {
        this.searchResults = leadSearch.results;
        LoadingService.done('leadSearchResults');
      });

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>
