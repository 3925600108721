<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      RFP Status
    </div>

    <div class="box-content">
      <div class="wrapper">
        <div class="icon bottom-15">
          <svg-icon name="check-and-dollar" class="icon-lg"></svg-icon>
        </div>

        <div class="bold-weight size-text-15px bottom-5">
          Not provisioned for RFP
        </div>

        <div class="text-center text-italic">
          You do not currently have access to RFPs. Please contact

          <priori-modal modal-id="contact-admins" ref="contactAdminsModal" modal-size="md" title="Contact Admins">
            <template #modal-trigger="{ openModal }">
              <span @click="openModal" class="pseudo-link-blue">your company’s admins</span>
            </template>

            <div class="bottom-40">
              <div class="row bottom-10">
                <div class="col-sm-8 size-text-15px semibold-weight">
                  {{ workspace.name }} Admins:
                </div>

                <div v-if="hasEmails" class="col-sm-4 semibold-weight blue-text text-right-not-xs">
                  <copy-to-clipboard :text-to-copy="adminEmails.join(',')" tooltip-text="Addresses copied to clipboard">
                    <svg-icon name="copy" class="base-icon right-3 bottom-5"></svg-icon>Copy All Addresses
                  </copy-to-clipboard>
                </div>
              </div>

              <div v-if="hasEmails">
                <div v-for="email in adminEmails" class="list-spacing-5">
                  {{ email }}
                </div>
              </div>

              <div v-else class="dark-gray-text size-text-14px">
                No provisioned admins
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <button type="button" class="nv-button-white" @click="closeModal">Close</button>
              </div>
            </div>
          </priori-modal>

          to request RFP access.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import CopyToClipboard from 'vue-app/shared/components/copy-to-clipboard.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProvisionRfpAccess',

  components: {
    SvgIcon,
    CopyToClipboard,
    PrioriModal
  },

  props: {
    workspace: {
      type: Object,
      required: true
    }
  },

  computed: {
    adminEmails() {
      return this.workspace.clients
        .filter(client => client.admin)
        .map(client => client.email);
    },

    hasEmails() {
      return this.adminEmails.length > 0;
    }
  },

  methods: {
    closeModal() {
      this.$refs.contactAdminsModal.dismissModal();
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (width >= $screen-sm-min) {
      padding: 8%;
    }
  }

  .icon {
    display: flex;
    background: $newblue-10;
    border-radius: 100px;
  }

  .icon-lg {
    width: 64px;
    height: 64px;
  }

  :deep(.uiv) {
    display: inline;
  }
</style>
