<template>
  <validation-observer ref="observerRef" v-slot="{ handleSubmit, failed: formInvalid }">
    <form role="form" name="form" class="a-form" novalidate @submit.prevent>
      <div class="titlebar with-button">
        <div class="row">
          <div class="col-sm-7">
            <div class="medium-page-heading top-6">
              Create New Template
            </div>
          </div>

          <div class="col-sm-5 top-20-xs">
            <div class="row tight-columns">
              <div class="col-sm-7">
                <loading-button name="saveTemplate" lb-class="primary-btn-blue" @lb-click="handleSubmit(confirmSaveTemplate)">Save Template</loading-button>
              </div>

              <div class="col-sm-5 top-10-xs">
                <a href="/scout-company/settings/question-templates" class="nv-button-white">Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidenav-content">
        <div class="container-fluid">
          <div class="padded-container">
            <div class="row">
              <div class="col-sm-10 col-lg-8">
                <div class="shadowed-box bottom-30">
                  <div class="box-header text-uppercase">
                    Template Options
                  </div>

                  <div class="box-content">
                    <div class="bottom-30">
                      <text-input-vertical
                        label="Template Name"
                        :name="'templateName'"
                        :id="'template-id'"
                        rules="required"
                        type="text"
                        v-model="rfqTemplate.name">
                      </text-input-vertical>
                      <div class="error-text top-5" v-if="hasResponseErrors">
                        <div class="size-text-12px">
                          <span>{{ responseErrorsDisplay }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="bottom-20">
                      <radio-toggle
                        label="How would you like to customize your questions?"
                        name="customizationType"
                        :options="customizeQuestionOptions"
                        @change="loadRecentlySentRfqs">
                        <template #section-one>
                          <div class="blue-accent">
                            <label for="k-toggle-button-selected-template">Select a Template<span class="inline-help required"> *</span></label>

                            <dropdown-select
                              ref="templateDropdown"
                              id-label="selected-template"
                              placeholder="Select One"
                              v-model="currentTemplate"
                              @change="updateSelectedTemplate"
                              value-key="name"
                              label-key="name"
                              :initial-label="rfqSelectedTemplate.name"
                              :options="rfqTemplates.map(obj => ({ name: obj.name, id: obj.id }))">
                            </dropdown-select>
                          </div>
                        </template>

                        <template #section-two>
                          <loading-section name="recentlySentRfqs">
                            <div class="blue-accent">
                              <label>Search for an RFP<span class="inline-help required"> *</span></label>

                              <typeahead-vertical
                                id="search-by-recently-sent-rfp"
                                :options="searchableRFQs"
                                option-label-key="rfpDescription"
                                placeholder="Search by RFP Ref Name or #"
                                @input="selectSearchedRFQ"
                                v-model="currentTemplateSearchable">
                              </typeahead-vertical>
                            </div>
                          </loading-section>
                        </template>
                      </radio-toggle>
                    </div>
                  </div>
                </div>

                <rfq-template-questions :template="rfqTemplate"></rfq-template-questions>

                <div class="row tight-columns top-30">
                  <div class="col-sm-4">
                    <loading-button name="saveTemplate" lb-class="primary-btn-blue" @lb-click="handleSubmit(confirmSaveTemplate)">Save Template</loading-button>
                  </div>

                  <div class="col-sm-3 top-10-xs">
                    <a href="/scout-company/settings/question-templates" class="nv-button-white">Cancel</a>
                  </div>
                </div>

                <div v-if="formInvalid" class="error-text top-10">
                  * One or more required fields are empty. Please fill in the required fields indicated above.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import { ValidationObserver } from 'vee-validate';
import RadioToggle from 'vue-app/shared/components/radio-toggle.vue';
import RfqTemplateQuestions from 'vue-app/scout/client/settings/rfqs/templates/questions.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import NotificationService from 'vue-app/shared/services/notification-service';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import RFQ from 'src/resources/scout/rfq.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

export default {
  name: 'ScoutClientSettingsRfpTemplatesNew',

  components: {
    ValidationObserver,
    RadioToggle,
    RfqTemplateQuestions,
    TextInputVertical,
    TypeaheadVertical,
    LoadingSection,
    LoadingButton,
    DropdownSelect
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      templates: this.initData.templates,
      currentTemplate: this.rfqSelectedTemplate,
      customizeQuestionOptions: [
        { text: 'Start from a template', value: 'template' },
        { text: 'Start from a recently sent RFP', value: 'recently-sent' }
      ],
      responseErrors: [],
      searchableRFQs: [],
      currentTemplateSearchable: null,
      recentlySentLoaded: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    ...mapState(useRfqTemplateStore, {
      rfqTemplate: 'rfqTemplate',
      rfqTemplates: 'rfqTemplates',
      rfqSelectedTemplate: 'rfqSelectedTemplate'
    }),

    hasResponseErrors() {
      return this.responseErrors.length > 0;
    },

    responseErrorsDisplay() {
      return this.responseErrors.join('; ');
    }
  },

  mounted() {
    this.setTemplates(structuredClone(this.templates));
    this.setRfqGroupTemplates(structuredClone(this.rfqSelectedTemplate.questionGroupTemplatesAttributes));
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      setRfqGroupTemplates: 'setRfqGroupTemplates',
      setTemplates: 'setTemplates',
      saveTemplate: 'saveTemplate',
      removeGroup: 'removeGroup',
      setSelectedTemplateByName: 'setSelectedTemplateByName'
    }),

    loadRecentlySentRfqs() {
      if (this.recentlySentLoaded) { return; }

      LoadingService.loading('recentlySentRfqs');
      RFQ.recentlySent()
        .then((response) => {
          this.searchableRFQs = response.data.map(rfq => {
            return {
              id: rfq.id,
              rfpDescription: `RFP #${rfq.id} - ${rfq.name}`,
              questionGroupTemplatesAttributes: rfq.questionGroupTemplatesAttributes
            };
          });
          this.recentlySentLoaded = true;
        })
        .finally(() => {
          LoadingService.done('recentlySentRfqs');
        });
    },

    selectSearchedRFQ(rfq) {
      if (!rfq) { return; }
      this.setRfqGroupTemplates(structuredClone(rfq.questionGroupTemplatesAttributes));
    },

    confirmSaveTemplate() {
      this.responseErrors = [];
      LoadingService.loading('saveTemplate');

      this.saveTemplate()
        .then((response) => {
          NotificationService.success('This template has been successfully created.', true);
          window.location.href = `/scout-company/settings/question-templates/${response.id}`;
        })
        .catch((response) => {
          this.responseErrors = (response?.response?.data?.errors || []);
        })
        .finally(() => {
          LoadingService.done('saveTemplate');
        });
    },

    // Use name b/c it's unique to a workspace.
    updateSelectedTemplate(templateName) {
      this.setRfqGroupTemplates(structuredClone(this.templates.find((t) => t.name === templateName).questionGroupTemplatesAttributes));
      this.setSelectedTemplateByName(templateName);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .blue-accent {
    background: $blue-10;
    padding: 10px 20px 20px 20px;
    border-radius: $border-radius-large;
  }
</style>
