<template>
  <div id="matter-legal-arrangement-rider" class="shadowed-box size-text-13px">
    <div class="box-header text-uppercase">
      Legal Arrangement Rider
    </div>

    <div class="box-content">
      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Disbursements
        </div>

        <div class="col-sm-8 top-10-xs">
          <span class="simple-text">{{ legalArrangementRider.disbursements || '&mdash;' }}</span>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Minimum Amount (Weekly/Monthly)
        </div>

        <div class="col-sm-8 top-10-xs">
          <span class="simple-text">{{ legalArrangementRider.minimumAmount || '&mdash;' }}</span>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Term
        </div>

        <div class="col-sm-8 top-10-xs">
          <span class="simple-text">{{ legalArrangementRider.term || '&mdash;' }}</span>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Weekly Time Commitment
        </div>

        <div class="col-sm-8 top-10-xs">
          <span class="simple-text">{{ legalArrangementRider.weeklyTimeCommitment || '&mdash;' }}</span>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Termination
        </div>

        <div class="col-sm-8 top-10-xs">
          <span class="simple-text">{{ legalArrangementRider.termination || '&mdash;' }}</span>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Billing Frequency
        </div>

        <div class="col-sm-8 top-10-xs">
          <span class="simple-text">{{ legalArrangementRider.billingFrequency || '&mdash;' }}</span>
        </div>
      </div>

      <hr class="kenny-hr">

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Additional Notes
        </div>

        <div class="col-sm-8 top-10-xs">
          <span class="simple-text">{{ legalArrangementRider.additionalNotes || '&mdash;' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatterLegalArrangementRider',

  props: {
    legalArrangementRider: {
      type: Object,
      required: true
    }
  }
};
</script>
