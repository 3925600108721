import 'ngVue';
import 'ngVue/build/plugins';

import angular from 'angular';
import useNotificationStore from 'vue-app/stores/notification';
import useCurrentUserStore from 'vue-app/stores/current-user';
import useViewingAsSwitcherStore from 'vue-app/stores/viewing-as-switcher';
import LoadingService from 'vue-app/shared/services/loading-service';
import Vue from 'vue';
import { createPinia } from 'pinia';

const pinia = createPinia();
Vue.use(pinia);

const vueComponents = angular.module('vueComponents', ['ngVue', 'ngVue.plugins']);

vueComponents

.config(['$ngVueProvider', function ($ngVueProvider) {
  $ngVueProvider.setRootVueInstanceProps({
    pinia: pinia
  });
}])

.factory('VueNotificationStore', [function () {
  return useNotificationStore();
}])

.factory('VueCurrentUserStore', [function () {
  return useCurrentUserStore();
}])

.factory('VueViewingAsSwitcherStore', [function () {
  return useViewingAsSwitcherStore();
}])

.factory('VueLoadingService', [function () {
  return LoadingService;
}]);

export default vueComponents;
