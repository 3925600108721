<template>
  <div class="shadowed-box">
    <div class="box-header compact text-uppercase">
      Recent Applications
    </div>

    <div class="box-content dash-widget">
      <loading-section name="newSubmissions">
        <div class="size-text-13px" v-if="lawyers.length">
          <table class="table no-margin auto-height">
            <tbody>
              <tr v-for="lawyer in lawyers" :key="lawyer.id">
                <td>
                  {{ lawyer.lastName }}, {{ lawyer.firstName }} {{ lawyer.middleName }}
                </td>
                <td>
                  <span v-tooltip="`Phone: ${lawyer.officeNumber}, Email ${lawyer.email}`"><svg-icon name="settings" class="base-icon"></svg-icon></span>
                </td>
                <td>
                  <a :href="`/admin-app/lawyers/${lawyer.id}`" class="bold-weight size-text-12px">VIEW</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="gray-text" v-else>
          No Applications
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'NewApplications',

  components: {
    LoadingSection,
    SvgIcon
  },

  props: {
    lawyers: {
      type: Array,
      required: true
    }
  }
};
</script>
