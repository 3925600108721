<template>
  <div :id="boxId" class="shadowed-box">
    <validation-observer v-slot="{ handleSubmit }" ref="validationObserver">
      <div class="box-header">
        <div class="row tight-columns">
          <div class="col-sm-8 text-uppercase">
            {{ title }}
          </div>

          <div class="col-sm-4 size-text-13px text-right-not-xs">
            <span v-if="isEditing">
              <loading-button :name="loadingButtonName" :lb-class="['header-loading-button pseudo-link-blue normal-weight', { 'loading': isSaving }]" @lb-click="handleSubmit(save)">Save</loading-button>

              <button type="button" class="pseudo-link-blue dark-gray-text normal-weight" @click.prevent="cancelEdit">Cancel</button>
            </span>

            <button type="button" class="pseudo-link-blue normal-weight" @click.prevent="startEdit" v-else>Edit</button>
          </div>
        </div>
      </div>

      <div class="box-notification-error" v-if="backendErrors.length">
        {{ backendErrors }}
      </div>

      <div class="box-content symmetrical">
        <form role="form" name="form" class="a-form" novalidate @submit.prevent>
          <slot name="default" :is-editing="isEditing" :resource-copy="resourceCopy"></slot>

          <div class="row top-30" v-if="isEditing">
            <div class="col-sm-4 col-md-3">
              <loading-button :name="loadingButtonName" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(save)">Save</loading-button>
            </div>

            <div class="col-sm-4 col-md-3 top-10-xs">
              <button type="button" class="secondary-btn-blue a-button-size" @click.prevent="cancelEdit">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingService from 'vue-app/shared/services/loading-service';
import { uniqueId } from 'lodash';

export default {
  name: 'EditingBox',

  components: {
    ValidationObserver
  },

  props: {
    title: {
      type: String,
      required: true
    },

    boxId: {
      type: String,
      required: false
    },

    resource: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      providedErrors: '',
      resourceCopy: {}
    };
  },

  computed: {
    backendErrors() {
      return (this.providedErrors instanceof Array) ? this.providedErrors.join('; ') : this.providedErrors;
    },

    loadingButtonName() {
      return uniqueId('lb-');
    },

    isSaving() {
      return LoadingService.isLoading(this.loadingButtonName);
    }
  },

  methods: {
    startEdit() {
      this.resourceCopy = Object.assign({}, this.resource);

      this.resetForm();
      this.isEditing = true;

      this.$emit('on-edit-start');
    },

    cancelEdit() {
      this.isEditing = false;
      this.resetForm();
    },

    resetForm() {
      this.providedErrors = '';
      this.$refs.validationObserver.reset();
    },

    save() {
      LoadingService.loading(this.loadingButtonName);
      this.providedErrors = '';

      this.onSave(this.resourceCopy)
        .then(() => {
          this.isEditing = false;
        })
        .catch((errors) => {
          if (!errors) { return; }
          this.providedErrors = errors;
        })
        .finally(() => {
          LoadingService.done(this.loadingButtonName);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .header-loading-button {
    margin-right: 29px;

    &.loading {
      margin-right: 0;
    }

    :deep(.spinner) {
      margin-right: 5px;
      margin-left: 5px;
      border-color: $k-gray;
      border-top-color: $k-darker-gray;
    }
  }
</style>
