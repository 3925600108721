<template>
  <boxed-content v-if="displayComponent" title="Awards" class="award-list">
    <template #links v-if="canEditProfile">
      <edit-lawyer-awards :lawyer="lawyer" :on-save="onSave"></edit-lawyer-awards>
    </template>

    <section-wrapper :data="lawyer.sanitizedAwards" :editing-allowed="canEditProfile">
      <div class="bold-weight bottom-10">
        Awards
      </div>

      <collapsable-space>
        <data-wrapper :data="lawyer.sanitizedAwards">
          <div v-for="(award, index) in lawyer.sanitizedAwards" :key="`lawyer-award-${index}`" class="list-spacing-10 d-flex">
            <span class="right-10">
              <svg-icon name="award" class="base-icon"></svg-icon>
            </span>

            <span v-html="removeWhiteSpace(enforceStylableFirstElement(award))" class="lists-to-csv"></span>
          </div>
        </data-wrapper>
      </collapsable-space>
    </section-wrapper>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditLawyerAwards from './edit-lawyer-awards.vue';
import CollapsableSpace from 'vue-app/shared/components/collapsable-space.vue';

export default {
  name: 'LawyerAwards',

  components: {
    SectionWrapper,
    DataWrapper,
    EditLawyerAwards,
    CollapsableSpace
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  computed: {
    displayComponent() {
      return this.dataIsPresentOrEditingAllowed(this.lawyer.sanitizedAwards);
    }
  },

  methods: {
    // Ensures the first elements, if not lists, are either para or div
    // if they aren't, they can't be targeted for styling following lists
    enforceStylableFirstElement(string) {
      const indexOfFirstList = string.search(/<[u|o]l>/);
      const preListText = string.substring(0, indexOfFirstList);

      if (preListText.search(/^<div>.*<\/div>$/) >= 0 || preListText.search(/^<p>.*<\/p>$/) >= 0) {
        return string;
      }

      const replacement = `<div>${preListText}</div>`;
      return string.replace(preListText, replacement);
    },

    // Whitespaces cause unwanted spaces between inline elements
    removeWhiteSpace(award) {
      return award.replace(/>\s+</g, '><');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "stylesheets/scout/lists";

.award-list {
  .lists-to-csv {
    overflow: hidden;
    width: 100%;
    white-space: normal;

    :deep {
      & > ol:first-child, & > ol:first-child ~ ol {
        padding: 0;

        & > li > p { display: block; }

        & > li > ol {
          @extend %flattened-list;
          @extend %decimal-list;

          & > li > ol {
            @extend %parenthesized-list;
            @extend %roman-numeral-list;

            margin-left: 3px;
          }
        }
      }

      & > ul:first-child, & > ul:first-child ~ ul {
        @extend %headed-list;

        & > li > ul {
          @extend %flattened-list;
        }
      }

      & > p ~ ol, & > div ~ ol {
        @extend %flattened-list;
        @extend %decimal-list;

        & > li > ol {
          @extend %parenthesized-list;
          @extend %roman-numeral-list;

          margin-left: 3px;
        }
      }

      & > p ~ ul, & > div ~ ul {
        @extend %flattened-list;

        & > li > ul {
          @extend %parenthesized-list;

          margin-left: 3px;
        }
      }
    }
  }
}
</style>
