class NullableValueFilter {
  defaultValue(value) {
    if (value) {
      return value;
    }
    else {
      return '—';
    }
  }
}

const instance = new NullableValueFilter();

export default instance;
