<template>
  <div class="row tight-columns">
    <div class="col-xs-2">
      <svg-icon :name="iconName" class="base-icon"></svg-icon>
    </div>

    <div class="col-xs-10">
      <div class="bold-weight">
        {{ title }}
      </div>

      <div class="top-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'src/vue-app/shared/components/svg-icon.vue';
export default {
  name: 'DataWithIcon',
  components: {
    SvgIcon
  },
  props: {
    iconName: {
      type:     String,
      required: true
    },

    title: {
      type:     String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
