<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-7 text-uppercase">
          RFP Question Templates
        </div>

        <div class="col-sm-5 text-right-not-xs">
          <a href="/scout-company/settings/question-templates" class="box-header-link">View/Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            View/Manage Question Templates
          </div>

          <div class="dark-gray-text">
            Admins can edit the questions and templates available for users creating RFPs.
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          {{ $pluralize('question template', templateCount, true) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RfpQuestionTemplates',

  props: {
    templateCount: {
      type: Number,
      required: true
    }
  }
};
</script>
