<template>
  <div>
    <div class="bold-weight">
      {{ title }}
    </div>

    <hr>

    <ul class="list">
      <li v-for="statistic in dataToDisplay" :key="uniqueId(statistic)">
        <span>{{ statistic[attributeKey] }}</span>
        <span>{{ statistic["count"] }}</span>
      </li>
    </ul>

    <div class="top-10" v-if="statisticData.length > collapsedShowCount">
      <a href="" class="semibold-weight size-text-12px" @click.prevent="toggleFullData">{{ showAll ? "Show less" : "Show all" }}</a>
    </div>
  </div>
</template>

<script>
import ListToggle from '../../../shared/mixins/list-toggle';

export default {
  name: 'LawyerStatistic',

  mixins: [
    ListToggle
  ],

  props: {
    title: {
      type: String,
      required: true
    },

    attributeKey: {
      type: String,
      required: true
    },

    statisticData: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    listData: function () {
      return this.statisticData;
    }
  },

  methods: {
    uniqueId: function (statistic) {
      return _.uniqueId(`${statistic[this.attributeKey]}_`);
    }
  }
};
</script>

<style lang="scss" scoped>
  div {
    line-height: 1.4;
  }

  hr {
    margin: 10px 0;
  }

  .list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      margin-top: 5px;
      column-gap: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
