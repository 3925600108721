<template>
  <div class="shadowed-box">
    <div class="border-gradiant"></div>

    <div class="box-content">
      <div class="row tight-columns vertical-center-not-xs">
        <div class="col-sm-2">
          <svg-icon name="rfq-logo" class="feedback-icon"></svg-icon>
        </div>

        <div class="col-sm-6 top-20-xs">
          <div class="size-text-17px bold-weight">
            RFP Notifications
          </div>
        </div>

        <div class="col-sm-4 top-20-xs">
          <a href="/scout-firm/rfps" class="primary-btn-blue">View All RFPs</a>
        </div>
      </div>

      <div class="top-20" v-if="hasNotifications">
        <div v-for="(data, workspaceId) in orderedNotifications" :key="workspaceId" class="list-spacing-10">
          <rfq-notification
            :notifications="data.notifications"
            :is-connecting="isConnecting">
          </rfq-notification>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';

import RfqNotification from 'vue-app/scout/firm/dashboard/rfq-notification.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RfqNotifications',

  components: {
    RfqNotification,
    SvgIcon
  },

  props: {
    notifications: {
      type: Object,
      required: true
    },

    isConnecting: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasNotifications() {
      return Object.keys(this.notifications).length > 0;
    },

    orderedNotifications() {
      return orderBy(this.notifications, data => data.mostRecentDateTime, ['desc']);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .border-gradiant {
    background: linear-gradient(27deg, $newblue-4 32.7%, $darkblue-2 99.34%);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    border-top-left-radius: $border-radius-large;
    border-bottom-left-radius: $border-radius-large;
  }
</style>
