<template>
  <div class="clear-panel">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-4">
        <lawyer-name-plate :lawyer="lawyer" photo-size="small">
          <template #lawyer-name-element>
            <a :href="`/scout-company/lawyers/${lawyer.id}`" class="text-link" target="_blank">{{ lawyer.fullName }}</a>
          </template>
        </lawyer-name-plate>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="size-text-12px semibold-weight">
          Total Billed
        </div>

        <div :class="['text-ellipsis', { 'missing-text': matterLawyer.placeholder }]">
          {{ fees }}
        </div>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="size-text-12px semibold-weight">
          Total Hours
        </div>

        <div :class="['text-ellipsis', { 'missing-text': matterLawyer.placeholder }]">
          {{ hoursText }}
        </div>
      </div>

      <div class="col-sm-2">
        <div class="top-10-xs" v-if="diversityDisclosureDataIsPresent">
          <div class="size-text-12px semibold-weight">
            Diversity Disclosure
          </div>

          <div>
            {{ diversityDisclosureText }}
          </div>
        </div>
      </div>

      <div class="col-sm-2 text-ellipsis text-right-not-xs">
        <a :href="`/scout-company/lawyers/${lawyer.id}/reviews`" class="semibold-weight" target="_blank" v-if="reviewCount > 0">{{ reviewCountText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'MattersListItemLawyer',

  components: {
    LawyerNamePlate
  },

  props: {
    matterLawyer: {
      type:     Object,
      required: true
    }
  },

  computed: {
    lawyer() {
      return this.matterLawyer.lawyer;
    },

    fees() {
      if (this.matterLawyer.placeholder) { return 'Not Available'; }
      const fee = this.matterLawyer.feeTotalInUsCents;
      if (typeof fee !== 'number') {
        return fee;
      }
      else {
        return CurrencyFilter.filter(fee / 100);
      }
    },

    hoursText() {
      if (this.matterLawyer.placeholder) { return 'Not Available'; }

      return this.matterLawyer.hours ? this.$pluralize('hr', this.matterLawyer.hours, true) : '—';
    },

    reviewCount() {
      return this.matterLawyer.reviewCount;
    },

    reviewCountText() {
      return this.$pluralize('review', this.reviewCount, true);
    },

    diversityDisclosureData() {
      let data = [];

      // ethnicity
      this.lawyer.userEthnicities.forEach(ethnicity => {
        data.push(ethnicity.ethnicityName);
      });

      // gender
      this.lawyer.genderProfileTaggings.forEach(tagging => {
        data.push(tagging.presentedName);
      });

      // lgbtq
      if (this.lawyer.identifiesAsLgbtq === 'identifiesAsLgbtq') {
        data.push('LGBTQ+');
      }

      // differently abled
      if (this.lawyer.identifiesAsDifferentlyAbled === 'identifiesAsDifferentlyAbled') {
        data.push('Disabled Person');
      }

      // military veteran
      if (this.lawyer.isMilitaryVeteran === 'isMilitaryVeteran') {
        data.push('Military Veteran');
      }

      return data;
    },

    diversityDisclosureDataIsPresent() {
      return this.diversityDisclosureData.length > 0;
    },

    diversityDisclosureText() {
      return this.diversityDisclosureData.join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
  .name-plate {
    padding-top: 10px;
  }
</style>
