import Vue from 'vue';
import vueComponents from './vue-components';

/****************/
/*  COMPONENTS  */
/****************/

import MarketplaceMessagingCenter from 'vue-app/mailboxer/marketplace/messaging-center.vue';
import ScoutMessagingCenter from 'vue-app/mailboxer/scout/messaging-center.vue';

vueComponents
.directive('ngvMarketplaceMessagingCenter', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('MarketplaceMessagingCenter', MarketplaceMessagingCenter));
}])

.directive('ngvScoutMessagingCenter', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ScoutMessagingCenter', ScoutMessagingCenter));
}]);
