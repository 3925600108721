<template>
  <div class="row tight-columns vertical-center-not-xs scout-review-item">
    <div :class="{ 'col-sm-3': showComplexity, 'col-sm-4': !showComplexity }">
      <div v-if="isMatterReview">
        <div class="semibold-weight text-ellipsis">
          <a :href="`/scout-company/matters/${review.matter.id}`" target="_blank">{{ matterOrProjectName }}</a>
        </div>

        <div class="pill-gray-text text-italic size-text-12px text-ellipsis" v-if="matterCode">
          #{{ matterCode }}
        </div>
      </div>

      <div class="semibold-weight text-ellipsis" v-else>
        General Review
      </div>
    </div>

    <div :class="['size-text-12px', { 'col-sm-3': showComplexity, 'col-sm-5': !showComplexity }]">
      <div class="semibold-weight">
        Reviewed By
      </div>

      <div class="inline-flex top-2">
        <div class="initials-circle">
          {{ reviewerInitials }}
        </div>

        <div class="left-5">
          {{ review.reviewer.fullName }} on {{ updatedAt }}
        </div>
      </div>
    </div>

    <div class="col-sm-3" v-if="showComplexity">
      <div class="size-text-12px semibold-weight">
        {{ reviewTemplate.complexityPromptBrief }}
      </div>

      <level-widget class="top-2" :label="complexityLabel" :score="review.complexity"></level-widget>
    </div>

    <div class="col-sm-2">
      <div class="size-text-12px semibold-weight">
        Score
      </div>

      <div class="top-2">
        {{ review.recommendation }}/10
      </div>
    </div>

    <div class="col-sm-1 text-right-not-xs">
      <a :href="`/scout-company/reviews/${review.id}`" class="semibold-weight" target="_blank">View</a>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import LevelWidget from 'vue-app/shared/components/level-widget.vue';

export default {
  name: 'ReviewListItem',

  components: {
    LevelWidget
  },

  props: {
    review: {
      type: Object,
      default: () => ({})
    },

    reviewTemplate: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      complexityLabels: this.reviewTemplate.complexityLabels()
    };
  },

  computed: {
    updatedAt() {
      return DateFilter.filter(this.review.updatedAt);
    },

    showComplexity() {
      return this.reviewTemplate.complexityShown;
    },

    complexityLabel() {
      return this.complexityLabels[this.review.complexity - 1] || 'N/A';
    },

    reviewerInitials() {
      const reviewer = this.review.reviewer;
      return (reviewer.firstName[0] + reviewer.lastName[0]).toUpperCase();
    },

    isMatterReview() {
      return this.review.scoutMatterId;
    },

    matterOrProjectName() {
      return this.review.matter.name;
    },

    matterCode() {
      return this.review.matter?.code;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .scout-review-item {
    padding: 10px;
    min-height: 65px;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-large;

    @media (max-width: 767px) {
      > div:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
</style>
