import axios from 'axios';
import EBillingUpload from 'resources/scout/client/e-billing-upload.js';

class EBillingUploadInitializer {
  constructor(file) {
    this.file = file;
    this.error = '';
  }

  process() {
    return new Promise((resolve, reject) => {
      // bind functions because scope is changing the value of `this`
      this._checkFileIsJson()
        .then(this._createEBillingUpload.bind(this))
        .then(this._uploadFile.bind(this))
        .then(function () { resolve(this); }.bind(this))
        .catch(() => {
          // if we register an error, wait until we finish and set the
          // upload status to the DB upload's status before rejecting
          if (this.upload) {
            this._registerError().then(() => { reject(this); });
          }
          else {
            reject(this);
          }
        });
    });
  }

  enqueue() {
    if (!this.upload) {
      return new Promise((_, rej) => { rej('No upload available'); });
    }

    return axios.patch(`scout/e_billing_uploads/${this.upload.id}/process_data.json`)
      .then((resp) => {
        this.upload.status = resp.data.status;
      });
  }

  _checkFileIsJson() {
    return new Promise((resolve, reject) => {
      return this.file.text().then((fileText) => {
        try { JSON.parse(fileText); }

        catch {
          this.error = 'Sorry, the file isn\'t supported for import. Please make sure your file is a JSON file.';
          reject();
          return;
        }

        resolve();
      });
    });
  }

  _createEBillingUpload() {
    return EBillingUpload.save({}).then((upload) => { this.upload = upload; });
  }

  _uploadFile() {
    return EBillingUpload.putForm({
      id: this.upload.id,
      jsonData: this.file
    })
    .catch((e) => {
      this.error = 'We encountered an error trying to upload your file. Please try again or contact support';
      throw e;
    });
  }

  _registerError() {
    return axios.patch(`/scout/e_billing_uploads/${this.upload.id}/register_error.json`, {
      errorData: {
        type:    'Upload error',
        message: 'There was a problem uploading the JSON file'
      }
    })
    .then((resp) => {
      this.upload.status = resp.data.status;
    });
  }
}

export default EBillingUploadInitializer;
