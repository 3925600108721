<template>
  <div>
    <edit-modal
      ref="editModal"
      title="Edit RFP Status"
      :input="firm"
      :on-save="save">
      <template #modal-trigger="{ openModal }">
        <a href="" class="normal-weight size-text-13px" @click.prevent="initForm(openModal)" data-testid="open-edit-modal">Edit RFP Status</a>
      </template>

      <template #default="{ inputCopy: firmCopy }">
        <div class="pill-gray-text bottom-20">
          An RFP Enabled law firm is eligible to receive RFPs from your team. If RFP is not enabled for a law firm, that firm can’t be added as a recipient to your team’s RFPs. RFP status can be edited by Admins at your law department at any time.
        </div>

        <div class="row tight-columns vertical-center">
          <div class="col-xs-5 col-sm-1-5 bold-weight">
            RFP Enabled?
          </div>

          <div class="col-xs-7 col-sm-4-5">
            <toggle-button
              id="toggle-rfq-enabled"
              :value="hasRfqEnabled"
              :disabled="connectionPending"
              true-text="Yes"
              false-text="No"
              @click="toggleHasRfqEnabled">
            </toggle-button>
          </div>
        </div>

        <div v-if="connectionPending" class="error-text top-5">
          Connection pending: Priori is reaching out to the firm to confirm their connection to your workspace.
        </div>

        <div v-if="shouldShowContactForm">
          <hr>

          <div class="bold-weight">
            Add Contact
          </div>

          <div class="pill-gray-text bottom-20">
            You must provide law firm contact information in order to send an RFP.
          </div>

          <div class="row">
            <div class="col-sm-6 bottom-20">
              <text-input-vertical
                label="Contact First Name"
                id="contact-first-name"
                input-name="contactFirstName"
                placeholder="Contact First Name"
                rules="required"
                v-model="contact.firstName"
                :disabled="hasExistingContact">
              </text-input-vertical>
            </div>

            <div class="col-sm-6 bottom-20">
              <text-input-vertical
                label="Contact Last Name"
                id="contact-last-name"
                input-name="contactLastName"
                placeholder="Contact Last Name"
                rules="required"
                v-model="contact.lastName"
                :disabled="hasExistingContact">
              </text-input-vertical>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <text-input-vertical
                label="Contact Email"
                id="contact-email"
                input-name="contactEmail"
                input-type="email"
                placeholder="Contact Email"
                rules="required|email"
                v-model="contact.email"
                :disabled="hasExistingContact">
              </text-input-vertical>
            </div>
          </div>
        </div>
      </template>
    </edit-modal>

    <pop-up-modal ref="errorModal">
      <template #message>
        Thank you for adding this firm. Priori will reach out to the firm to confirm their connection to your workspace.
      </template>
    </pop-up-modal>
  </div>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import PopUpModal from 'vue-app/shared/components/pop-up-modal.vue';
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';

export default {
  name: 'EditRfqStatusModal',

  components: {
    EditModal,
    PopUpModal,
    ToggleButton,
    TextInputVertical
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      hasRfqEnabled: this.firm.hasRfqEnabled,
      contact: { ...this.firm.scoutFirmContact }
    };
  },

  computed: {
    hasExistingContact() {
      return !!this.firm.scoutFirmContact.id;
    },

    canAddContact() {
      return !this.firm.isConnected || !this.firm.hasLawyers;
    },

    shouldShowContactForm() {
      return this.hasExistingContact || (this.hasRfqEnabled && this.canAddContact);
    },

    connectionPending() {
      return this.firm.isBillingFirm() && this.hasExistingContact && !this.hasRfqEnabled;
    }
  },

  methods: {
    initForm(openModalFn) {
      this.hasRfqEnabled = this.firm.hasRfqEnabled;
      this.contact = { ...this.firm.scoutFirmContact };
      openModalFn();
    },

    toggleHasRfqEnabled() {
      this.hasRfqEnabled = !this.hasRfqEnabled;
    },

    save() {
      if (this.connectionPending) {
        this.$refs.editModal.closeModal();
        this.$refs.errorModal.openModal();
        return Promise.resolve();
      }

      const params = {
        id: this.firm.id,
        lawFirmBridge: {
          hasRfqEnabled: this.hasRfqEnabled
        }
      };

      if (this.hasRfqEnabled && this.canAddContact) {
        params.contact = this.contact;
      }

      return this.onSave(params)
        .catch((response) => {
          if (response.response.data.showProcessError) {
            this.$refs.editModal.closeModal();
            this.$refs.errorModal.openModal();

            return Promise.resolve();
          }
          else {
            return Promise.reject(response);
          }
        });
    }
  }
};
</script>
