<template>
  <edit-modal title="Relationship Partners" :input="firm" :on-save="save" @on-modal-open="openModal">
    <template #default="{ inputCopy: firmCopy }">
      <loading-section name="rp-lawyers">
        <form role="form" name="form" class="a-form" novalidate>
          <div class="bottom-20">
            <div class="small-bold-header">
              Relationship Partners
            </div>

            <hr>

            <div class="bottom-20 text-italic pill-gray-text size-text-12px">
              You are currently editing the relationship partners for the client you selected. You can edit the relationship partners for other clients by selecting another client from the dropdown menu on your firm&rsquo;s profile page before clicking &ldquo;Edit&rdquo;.
            </div>
          </div>

          <div class="size-text-16px semibold-weight bottom-20">
            {{ workspace.name }}
          </div>

          <div v-for="(bridge, index) in lawyerBridges" class="bottom-20" :key="bridge.id">
            <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
              <div class="partner-label">
                <label :for="`rp-lawyer-${index}`">Relationship Partner {{ index + 1 }} <span class="red-text">*</span></label>

                <div class="error-text bottom-5" v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </div>

              <div class="flex">
                <div>
                  <a href="" @click.prevent="removePartner(index)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon></a>
                </div>

                <div class="flex-1">
                  <input type="text" :id="`rp-lawyer-${index}`" autocomplete="off" :class="['form-control', { 'has-error': errors.length }]">
                  <typeahead v-model="bridge.fullName" :target="`#rp-lawyer-${index}`" :data="lawyers" item-key="fullName" force-select force-clear></typeahead>
                </div>
              </div>
            </validation-provider>
          </div>

          <a href="" @click.prevent="addPartner" class="semibold-weight">+ Add Another</a>
        </form>
      </loading-section>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from '../edit-modal';
import ScoutFirm from 'resources/scout/scout-firm';
import LoadingService from 'vue-app/shared/services/loading-service';
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';

export default {
  name: 'EditRelationShipPartners',

  components: {
    EditModal,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    firm: {
      type: Object,
      required: true
    },

    workspace: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      relationshipPartnerWorkspaces: [],
      lawyerBridges: []
    };
  },

  computed: {
    lawyers() {
      var workspace = this.relationshipPartnerWorkspaces.find(workspace => workspace.id === this.workspace.id);

      if (workspace) {
        return _.orderBy(workspace.lawyers, 'fullName');
      }
      else {
        return [];
      }
    }
  },

  methods: {
    save() {
      var firmEdited = {
        ...this.firm,
        scoutLawyerBridgesAttributes: this.selectedLawyerBridges()
      };

      this.onSave(firmEdited);
    },

    selectedLawyerBridges() {
      return this.lawyers.map((partner) => {
        var isPartner = _.some(this.lawyerBridges, (bridge) => { return this.isLawyerBridgeSelected(bridge, partner); });

        if (isPartner) {
          partner.relationshipPartner = true;
        }
        else {
          partner.relationshipPartner = false;
        }

        return partner;
      });
    },

    isLawyerBridgeSelected(bridge, partner) {
      if (bridge.fullName && bridge.fullName.id) {
        return bridge.fullName.id === partner.id;
      }
      else {
        return bridge.id === partner.id;
      }
    },

    addPartner() {
      this.lawyerBridges.push({
        fullName: null
      });
    },

    removePartner(index) {
      this.lawyerBridges.splice(index, 1);
    },

    buildScoutLawyerBridges() {
      this.lawyerBridges = [];

      _.each(this.workspace.lawyers, (lawyer) => {
        this.lawyerBridges.push(Object.assign({}, lawyer));
      });
    },

    openModal() {
      LoadingService.loading('rp-lawyers');

      if (this.relationshipPartnerWorkspaces.length) {
        this.buildScoutLawyerBridges();

        LoadingService.done('rp-lawyers');
      }
      else {
        ScoutFirm.get({ id: 'profile', view: 'lists' }).then((response) => {
          this.relationshipPartnerWorkspaces = response.relationshipPartnerWorkspaces;

          this.buildScoutLawyerBridges();

          LoadingService.done('rp-lawyers');
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .partner-label {
    margin-left: 28px;
  }
</style>
