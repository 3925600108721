<template>
  <div>
    <quote-detail-header
      :quote="quote"
      :is-editing="isEditing"
      :on-edit-quote="editQuote">
    </quote-detail-header>

    <div class="top-10 bottom-10">
      <hr class="no-margin">
    </div>

    <div class="row tight-columns vertical-center-not-xs bottom-30" v-if="!isEditing">
      <div class="col-sm-5 col-sm-push-7 text-right-not-xs">
        <selection-badge variant="declined"></selection-badge>
      </div>

      <div class="col-sm-7 col-sm-pull-5 missing-text top-20-xs">
        Below are the responses you provided to {{ workspace.name }}’s RFP
      </div>
    </div>

    <div class="bottom-30" v-else>
      You are editing your quote that was submitted on {{ dateResponded }}. Fields marked with<span class="required-asterisk left-5 right-5">*</span>are required.
    </div>

    <quote-detail
      v-if="!isEditing"
      :rfq="rfq"
      :quote="quote"
      :suggested-lawyers="suggestedLawyers"
      :viewing-as-client="false"
      :has-new-question-activities="hasNewQuestionActivities">
    </quote-detail>

    <quote-form
      v-else
      class="quote-form"
      :quote="quoteCopy"
      :is-connecting="isConnecting"
      :on-load-firm-lawyers="onLoadFirmLawyers"
      :on-save="onSave"
      :on-update-file-answer="onUpdateFileAnswer"
      :on-submit="onSubmit"
      :is-editing="isEditing"
      :on-cancel="cancel"
      :has-new-question-activities="hasNewQuestionActivities"
      :errors="errors">
    </quote-form>
  </div>
</template>

<script>
import QuoteDetail from 'vue-app/scout/shared/rfqs/quote-detail.vue';
import SelectionBadge from './selection-badge.vue';
import QuoteForm from './quote-form.vue';
import QuoteDetailHeader from 'vue-app/scout/firm/rfqs/quote-detail-header.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';
import { cloneDeep } from 'lodash';

export default {
  name: 'QuoteDetailClientDeclined',

  components: {
    QuoteDetail,
    QuoteForm,
    SelectionBadge,
    QuoteDetailHeader
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    quote: {
      type: Object,
      required: true
    },

    isConnecting: {
      type: Boolean,
      required: true
    },

    onLoadFirmLawyers: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onUpdateFileAnswer: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    },

    hasNewQuestionActivities: {
      type: Boolean,
      required: true
    },

    errors: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isEditing: false,
      quoteCopy: cloneDeep(this.quote)
    };
  },

  computed: {
    dateResponded() {
      return DateFilter.filter(this.quote.lastUpdatedAt || this.quote.dateResponded);
    },

    workspace() {
      return this.quote.workspace;
    },

    suggestedLawyers() {
      return this.quote.clientSuggestedLawyers.concat(this.quote.firmSuggestedLawyers);
    }
  },

  methods: {
    editQuote() {
      this.isEditing = true;
    },

    cancel() {
      this.isEditing = false;
    }
  }
};
</script>
