<template>
  <div class="shadowed-box">
    <div class="resource-list static-list">
      <div class="list-header">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-3 vertical-center avg-score">
            Matter Information
            <svg-icon v-if="matterInfoTooltipText" id="matter-info-tooltip" name="info" class="base-icon smaller left-5"></svg-icon>
            <tooltip target="#matter-info-tooltip" placement="bottom" custom-class="tooltip-white" :text="matterInfoTooltipText"></tooltip>
          </div>

          <div class="col-sm-9">
            <div class="row tight-columns vertical-center-not-xs">
              <div class="col-sm-2">
                Open Date
              </div>

              <div class="col-sm-2">
                Close Date
              </div>

              <div class="col-sm-2">
                Total Spent
              </div>

              <div class="col-sm-3">
                Internal Practice Area
              </div>

              <div class="col-sm-3 vertical-center avg-score">
                Average Score
                <svg-icon id="avg-matter-tooltip" name="info" class="base-icon smaller left-5"></svg-icon>
                <tooltip target="#avg-matter-tooltip" placement="bottom" custom-class="tooltip-white" text="The displayed score represents an average of all Recommendation Scores for this matter in Scout, based on all matter-specific reviews submitted by your colleagues. The maximum possible recommendation score is 10. This information is entirely internal to your team."></tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <matters-list-item :matter="matter" @load-lawyers="loadLawyers" v-for="matter in matters" :key="matter.id"></matters-list-item>

      <div class="box-content" v-if="!hasMatters">
        <div class="gray-text size-text-16px top-10 bottom-10">
          No Results
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MattersListItem from 'vue-app/scout/client/matters/matters-list-item.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'MattersList',

  components: {
    MattersListItem,
    SvgIcon
  },

  props: {
    matters: {
      type: Array,
      default: () => []
    },

    matterInfoTooltipText: {
      type: String,
      required: false
    }
  },

  computed: {
    hasMatters() {
      return !!this.matters.length;
    }
  },

  methods: {
    loadLawyers(matter) {
      this.$emit('load-lawyers', matter);
    }
  }
};
</script>
