<template>
  <boxed-content title="Law Firm Questions">
    <div class="vertical-list row-gap-30">
      <div v-for="group in orderedQuestionGroups" :key="group.id" class="vertical-list row-gap-5">
        <div class="bold-weight">
          {{ group.name }}
        </div>

        <div v-for="question in orderedQuestionsFor(group)" :key="question.id">
          {{ question.questionText }} <span v-if="question.isRequired" class="red-text">*</span>
        </div>
      </div>

      <div class="missing-text size-text-14px" v-if="!hasIncludedQuestions">
        No questions added
      </div>
    </div>
  </boxed-content>
</template>

<script>
import { orderBy } from 'lodash';

export default {
  name: 'RfqRecipientQuestions',

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  computed: {
    groupsWithQuestions() {
      return this.rfq.questionGroups.filter(group => group.questions.some(question => question.isIncluded && !question.prerequisiteQuestionId));
    },

    hasIncludedQuestions() {
      return this.groupsWithQuestions.length > 0;
    },

    orderedQuestionGroups() {
      return orderBy(this.groupsWithQuestions, 'position');
    }
  },

  methods: {
    orderedQuestionsFor(group) {
      return orderBy(group.questions.filter(q => q.isIncluded && !q.prerequisiteQuestionId), 'position');
    }
  }
};
</script>

<style lang="scss" scoped>
.vertical-list {
  display: flex;
  flex-direction: column;
}
</style>
