<template>
  <div class="review-reminder">
    <span :class="['type-text', reminderTypeTextClass]">
      {{ reminderTypeText }}
    </span>

    <div class="prompt" v-if="reviewReminder.reminderTypeIs('new_project')">
      How is <a :href="`/scout-company/matters/${matter.id}`" target="_blank">{{ matter.name }}</a> going? Would you like to leave a review?
    </div>
    <div class="prompt" v-else-if="reviewReminder.reminderTypeIs('recently_closed')">
      <a :href="`/scout-company/matters/${matter.id}`" target="_blank">{{ matter.name }}</a> was recently closed. Would you like to leave a review?
    </div>
    <div class="prompt" v-else-if="reviewReminder.reminderTypeIs('total_spent_on')">
      <a :href="`/scout-company/matters/${matter.id}`" target="_blank">{{ matter.name }}</a> billed more than {{ formattedTotalDollarsSpentOnMatter }}. Would you like to leave a review?
    </div>
    <div class="prompt" v-else-if="reviewReminder.reminderTypeIs('hours_worked_on')">
      <a :href="`/scout-company/matters/${matter.id}`" target="_blank">{{ matter.name }}</a> billed more than {{ reviewReminderConfiguration.hoursWorkedOnMatter }} hours. Would you like to leave a review?
    </div>
    <div class="prompt" v-else-if="reviewReminder.reminderTypeIs('periodic_reminder')">
      <a :href="`/scout-company/matters/${matter.id}`" target="_blank">{{ matter.name }}</a> has been open for {{ matter.daysOpenedFor }} {{ dayOrDays }}. Would you like to leave a review?
    </div>

    <div class="buttons-container">
      <div class="review-button-container">
        <review-form-guide-modal
          :load-firms="loadFirms"
          :load-firm-lawyers="loadFirmLawyers"
          :matter-id="reviewReminder.matter.id">
          <template #trigger="modalProps">
            <button type="button" class="review-reminder-review-button" @click="modalProps.openAndLoadFirms">Review</button>
          </template>
        </review-form-guide-modal>
      </div>

      <review-reminder-not-relevant-modal
        :matter="matter"
        :on-submit="createIrrelevantReviewReminderMatter">
      </review-reminder-not-relevant-modal>
    </div>
  </div>
</template>

<script>
import currencyFilter from 'vue-app/shared/mixins/currency-filter.js';
import ReviewFormGuideModal from 'vue-app/scout/client/reviews/review-form-guide-modal.vue';
import ReviewReminderNotRelevantModal from 'vue-app/scout/client/reviews/review-reminder-not-relevant-modal.vue';

export default {
  name: 'ReviewReminder',

  components: {
    ReviewFormGuideModal,
    ReviewReminderNotRelevantModal
  },

  mixins: [currencyFilter],

  props: {
    reviewReminder: {
      type: Object,
      required: true
    },

    loadMatterFirms: {
      type: Function,
      required: true
    },

    loadMatterLawyers: {
      type: Function,
      required: true
    },

    reviewReminderConfiguration: {
      type: Object,
      required: true
    },

    createIrrelevantReviewReminderMatter: {
      type: Function,
      required: true
    }
  },

  computed: {
    matter() {
      return this.reviewReminder.matter;
    },

    reminderTypeText() {
      return {
        'new_project': 'New Project!',
        'recently_closed': 'Recently Closed',
        'total_spent_on': 'Significant Milestone',
        'hours_worked_on': 'Significant Milestone',
        'periodic_reminder': 'Periodic Reminder'
      }[this.reviewReminder.reminderType];
    },

    reminderTypeTextClass() {
      return {
        'new_project': 'green-bg',
        'recently_closed': 'purple-bg',
        'total_spent_on': 'red-violet-bg',
        'hours_worked_on': 'red-violet-bg',
        'periodic_reminder': 'red-pink-bg'
      }[this.reviewReminder.reminderType];
    },

    dayOrDays() {
      return this.$pluralize('day', this.matter.daysOpenedFor);
    },

    formattedTotalDollarsSpentOnMatter() {
      return this.formattedCurrency(this.reviewReminderConfiguration.totalCentsSpentOnMatter / 100);
    }
  },

  methods: {
    loadFirms() {
      return this.loadMatterFirms(this.matter.id);
    },

    loadFirmLawyers(firmBridgeId) {
      return this.loadMatterLawyers(firmBridgeId, this.matter.id);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .review-reminder {
    padding: 20px;
    border: 1px solid $k-gray;
    border-radius: $border-radius-large;
  }

  .type-text {
    padding: 3px 8px;
    border-radius: $border-radius-small;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;

    &.green-bg {
      background: $k-green;
    }

    &.purple-bg {
      background: $k-purple;
    }

    &.red-violet-bg {
      background: $k-red-violet;
    }

    &.red-pink-bg {
      background: $k-red-pink;
    }
  }

  .prompt {
    margin-top: 12px;
    font-weight: 600;
    line-height: 1.4;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .review-button-container {
    margin-right: 15px;
    width: 80px;
  }

  .review-reminder-review-button {
    padding: 5px 15px;
    border: 1px solid $k-blue;
    border-radius: $border-radius-large;
    color: $k-blue;
    background: transparent;
    font-weight: 700;
    text-transform: uppercase;

    &:hover, &:active {
      background-color: $k-blue;
      color: $white;
    }
  }
</style>
