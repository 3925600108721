<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Admin Activity
    </div>

    <div class="box-content symmetrical">
      <div class="list-spacing-20" v-for="activity in activities" :key="activity.id">
        <div v-if="activity.type === 'possibleLawyerAdded'">
          <div class="dark-gray-text">
            {{ filteredDate(activity.date) }}:
          </div>

          <div>
            <span class="semibold-weight">{{ activity.lawyerName }}</span> added to proposal<span v-if="activity.addedByName"> by {{ activity.addedByName }}</span>
          </div>
        </div>

        <div v-if="activity.type === 'possibleLawyerRetired'">
          <div class="dark-gray-text">
            {{ filteredDate(activity.date) }}:
          </div>

          <div>
            <span class="semibold-weight">{{ activity.lawyerName }}</span> retired
          </div>
        </div>

        <div v-if="activity.type === 'proposalSent'">
          <div class="dark-gray-text">
            {{ filteredDate(activity.date) }}:
          </div>

          <div>
            <span class="semibold-weight">Proposal sent</span> to client<span v-if="activity.sentByName"> by {{ activity.sentByName }}</span>
          </div>
        </div>
      </div>

      <div v-if="!activities.length">
        No activity
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import { each, find, orderBy, uniqueId } from 'lodash';

export default {
  name: 'ProposalAdminActivity',

  props: {
    proposal: {
      type: Object,
      required: true
    },

    proposalVersions: {
      type: Array,
      required: true
    },

    admins: {
      type: Array,
      required: true
    }
  },

  computed: {
    possibleLawyers() {
      return this.proposal.possibleLawyers;
    },

    activities() {
      let activities = [];
      let lawyerName = null;
      let addedByName = null;
      let sentByName = null;

      each(this.possibleLawyers, (pl) => {
        lawyerName = (pl.lawFirm ? pl.lawFirm.name : pl.lawyer.fullName);
        addedByName = (pl.addedById ? find(this.admins, { id: pl.addedById })?.firstName : null);

        activities.push({
          id: uniqueId(),
          type: 'possibleLawyerAdded',
          date: pl.createdAt,
          lawyerName: lawyerName,
          addedByName: addedByName
        });

        if (pl.retired) {
          // TODO: we don't know who retired the lawyer
          activities.push({
            id: uniqueId(),
            type: 'possibleLawyerRetired',
            date: pl.updatedAt,
            lawyerName: lawyerName
          });
        }
      });

      each(this.proposalVersions, (proposal) => {
        if (proposal.isSent()) {
          sentByName = (proposal.sentById ? find(this.admins, { id: proposal.sentById })?.firstName : null);

          activities.push({
            id: uniqueId(),
            type: 'proposalSent',
            date: proposal.sentAt,
            sentByName: sentByName
          });
        }
      });

      return orderBy(activities, ['date'], ['desc']);
    }
  },

  methods: {
    filteredDate(date) {
      return DateFilter.longWithAt(date);
    }
  }
};
</script>
