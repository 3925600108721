import { render, staticRenderFns } from "./edit-firm-leadership.vue?vue&type=template&id=75ed7190&scoped=true"
import script from "./edit-firm-leadership.vue?vue&type=script&lang=js"
export * from "./edit-firm-leadership.vue?vue&type=script&lang=js"
import style0 from "./edit-firm-leadership.vue?vue&type=style&index=0&id=75ed7190&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ed7190",
  null
  
)

export default component.exports