<template>
  <div>
    <div class="shadowed-box">
      <div class="resource-list static-list" ng-if="dataLocks.length > 0">
        <div class="list-header">
          <div class="row tight-columns">
            <div class="col-sm-4 vertical-center">
              <dynamic-order-arrows
                :order="order"
                :on-sort="sort"
                order-by="name"
                :initial-order="initialOrder('name')"
                :reverse="true">
                Lawyer Name
              </dynamic-order-arrows>
            </div>

            <div class="col-sm-4 vertical-center">
              <dynamic-order-arrows
                :order="order"
                :on-sort="sort"
                order-by="content"
                :initial-order="initialOrder('content')"
                :reverse="true">
                Locked Content
              </dynamic-order-arrows>
            </div>

            <div class="col-sm-4 vertical-center">
              <dynamic-order-arrows
                :order="order"
                :on-sort="sort"
                order-by="locked_at"
                :initial-order="initialOrder('locked_at')">
                Locked On
              </dynamic-order-arrows>
            </div>
          </div>
        </div>

        <div>
          <div class="list-item" v-for="lock in dataLocks" :key="lock.id">
            <div class="row tight-columns">
              <div class="col-sm-4">
                {{ lock.lockableName }}
              </div>

              <div class="col-sm-4">
                {{ formattedFlag(lock.flag) }}
              </div>

              <div class="col-sm-4">
                {{ formattedDate(lock.lockedAt) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-content" v-if="dataLocks.length === 0">
        <div class="gray-text size-text-16px top-10 bottom-10">
          Your firm has no locked data.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash';
import DateFilter from 'src/vue-app/shared/lib/date-filter.js';
import DynamicOrderArrows from 'src/vue-app/shared/components/dynamic-order-arrows.vue';

export default {
  name: 'DataLocksTable',

  components: {
    DynamicOrderArrows
  },

  props: {
    dataLocks: {
      type:     Array,
      required: true
    },

    onSort: {
      type:     Function,
      required: true
    }
  },

  data: function () {
    return {
      order: { 'locked_at': 'desc' }
    };
  },

  methods: {
    initialOrder(newOrder) {
      return {
        name: 'asc',
        content: 'asc',
        locked_at: 'asc'
      }[newOrder];
    },

    formattedDate(date) {
      return DateFilter.filter(date, { year: 'numeric', month: 'short', day: '2-digit' });
    },

    formattedFlag(flag) {
      return flag.split('/').map(str => startCase(str)).join('/');
    },

    sort(newOrder) {
      let newDirection;

      if (this.order[newOrder]) {
        newDirection = this.order[newOrder] === 'asc' ? 'desc' : 'asc';
      }
      else {
        newDirection = this.initialOrder(newOrder);
      }

      this.order = { [newOrder]: newDirection };
      this.onSort(newOrder, newDirection);
    }
  }
};
</script>
