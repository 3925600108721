<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div class="text-center">
        <div class="clickable" @click="viewScoutLawyer(lawyer.id)">
          <div class="bottom-10">
            <profile-photo :user="lawyer"></profile-photo>
          </div>

          <div class="size-text-18px target">
            {{ lawyer.fullName }}
          </div>
        </div>

        <div class="bottom-20">
          {{ lawyer.firmTitle }}
          <span v-if="lawyer.firmName.length && lawyer.firmName !== lawyer.fullName">
            <span v-if="lawyer.firmTitle">at </span>
            <a :href="`/scout-company/law-firms/${lawyer.lawFirmBridgeId}`" target="_self" v-if="lawyer.lawFirmBridgeId">{{ lawyer.firmName }}</a>
            <span v-else>{{ lawyer.firmName }}</span>
          </span>
        </div>

        <div class="bottom-20" v-if="lawyer.rate">
          {{ lawyer.rate }}/hr
        </div>

        <div>
          {{ matterCountText }} with {{ workspaceName }}
        </div>

        <div class="top-20" v-if="lawyer.reviewsSummary.score">
          <div class="bottom-5">
            Average Recommendation Score
          </div>

          <div class="vertical-center center-justified">
            <rating-circle class="right-5" :rating="rating"></rating-circle>
            <span class="semibold-weight right-5">{{ scoreText }}</span>
            <span class="right-5 bold-weight">&#8231;</span>
            <span class="pill-gray-text">{{ reviewCountText }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user';
import { mapState } from 'pinia';
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';

export default {
  name: 'LawyerCard',

  components: {
    ProfilePhoto,
    RatingCircle
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    matterCountText() {
      return this.$pluralize('matter', this.lawyer.matterCount || 0, true);
    },

    workspaceName() {
      return this.currentClient?.workspace?.name;
    },

    reviewCountText() {
      return this.$pluralize('review', this.lawyer.reviewsSummary.count, true);
    },

    rating() {
      return { percent: (this.lawyer.reviewsSummary.score / 10 * 100) };
    },

    scoreText() {
      return this.lawyer.reviewsSummary.score.toFixed(1);
    }
  }
};
</script>

<style lang="scss" scoped>
  .clickable :deep(.missing-photo) {
    cursor: pointer;
  }
</style>
