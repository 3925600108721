<template>
  <div class="flat-box" ref="errorContainer">
    <div class="box-content symmetrical">
      <inline-notification class="bottom-30" type="error">
        <span class="bold-weight">Error:</span> Responses cannot be submitted or saved at this time.
      </inline-notification>

      <div class="size-text-14px bottom-30">
        <div v-if="hasClientUpdateError">
          <div class="bottom-20">
            The client has sent an update to this form while you were working, which will unfortunately prevent you from submitting or saving your responses.
          </div>

          <div>
            We’re sorry about that! You can download your draft response and refresh the page in order to submit.
          </div>
        </div>

        <div v-else>
          There was a problem saving your responses. To ensure you do not lose progress, you can download your responses below. Please reach out to Priori if the issue persists.
        </div>
      </div>

      <div class="row tight-columns">
        <div class="col-sm-4">
          <button type="button" class="primary-btn-night-blue" @click="download">Download<span class="hidden-xs"> Responses</span></button>
        </div>

        <div class="col-sm-2 top-20-xs">
          <button type="button" class="nv-button-white" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';
import RfqQuoteToDocx from 'services/scout/client/rfq-quote-to-docx.js';

export default {
  name: 'SubmissionError',

  components: {
    InlineNotification
  },

  props: {
    quote: {
      type: Object,
      required: true
    },

    errors: {
      type: Array,
      required: true
    },

    formData: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasClientUpdateError() {
      return this.errors.includes('client_updated_questions');
    }
  },

  mounted() {
    this.$refs.errorContainer.scrollIntoView({ behavior: 'smooth' });
  },

  methods: {
    download() {
      const quoteFormData = { answers: this.formData.answers };
      const docx = new RfqQuoteToDocx(this.quote.rfq, [quoteFormData], `${this.quote.workspace.name} draft response`, true);
      docx.generateDocx();
    },

    cancel() {
      window.location.reload();
    }
  }
};
</script>
