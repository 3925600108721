<template>
  <edit-modal
    :input="defaultScoutTag"
    :title="modalTitle"
    :on-cancel="reset"
    :on-save="submitForm">
    <template #modal-trigger="modalProps">
      <div>
        <a href="" class="bold-weight" @click.prevent="modalProps.openModal()">{{ triggerName }}</a>
      </div>
    </template>

    <template #default="{ inputCopy: scoutTagCopy }">
      <div class="row tight-columns bottom-30">
        <div class="col-sm-7">
          <label>Tag Type <span class="inline-help required">*</span></label>

          <div v-if="isPersisted" class="text-capitalize">
            {{ tagTypeDisplay }} Tag
          </div>

          <div v-else class="vertical-center-not-xs gap-20 top-10">
            <label v-for="tagType in tagTypes" class="check-option vertical medium-large">
              <span class="normal-weight">{{ tagType.text }}</span>
              <input
                type="radio"
                name="tagType"
                :value="tagType.value"
                v-model="scoutTagCopy.tagType"
                @change="scoutTagCopy.name = null">
              <span class="check c-radio"></span>
            </label>
          </div>
        </div>

        <div class="col-sm-5 top-30-xs">
          <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
            <label for="color">Color <span class="inline-help required">*</span></label>

            <dropdown-select-color
              ref="dropdownSelectColor"
              :initial-label="scoutTagCopy.color"
              id-label="color"
              v-model="scoutTagCopy.color"
              :colors="colors"
              :invalid="errors.length > 0">
            </dropdown-select-color>

            <div class="error-text top-5" v-if="errors.length">
              <error-message :errors="errors"></error-message>
            </div>
          </validation-provider>
        </div>
      </div>

      <div v-if="scoutTagCopy.tagType === 'general'" class="bottom-30">
        <text-input-vertical
          ref="name"
          label="Label"
          input-name="name"
          id="tagType"
          rules="required"
          :maxlength="nameMaxlength"
          v-model="scoutTagCopy.name">
        </text-input-vertical>

        <div class="size-text-12px dark-gray-text top-5" v-if="showCharactersLimitedMessage(scoutTagCopy)">
          Limited to {{ nameMaxlength }} characters
        </div>
      </div>

      <div v-else-if="scoutTagCopy.tagType === 'practice_area'" class="bottom-30">
        <typeahead-vertical
          label="Practice Area"
          placeholder="Search..."
          :options="practiceAreaOptions"
          :rules="'required'"
          option-label-key="label"
          :id="'practiceAreas'"
          :initial-value="initialPracticeArea"
          @input="(selected) => practiceAreaChanged(selected, scoutTagCopy)">
        </typeahead-vertical>
      </div>

      <div v-else class="container-fluid clear-panel bottom-30">
        <label>Location Type <span class="inline-help required">*</span></label>

        <div class="d-flex-not-xs gap-20 top-10 bottom-20">
          <div v-for="option in locationSearchOptions" :key="option.value">
            <label class="check-option vertical medium-large">
              {{ option.label }}
              <input
                type="radio"
                name="locationSearch"
                required
                v-model="scoutTagCopy.isStateAndCountry"
                @change="scoutTagCopy.name = null"
                :value="option.value">
              <span class="check c-radio"></span>
            </label>
          </div>
        </div>

        <div v-if="scoutTagCopy.isStateAndCountry">
          <div class="keyword-search-container typeahead-container">
            <label>Location</label>

            <typeahead-vertical
              placeholder="Search..."
              :options="stateAndCountryOptions"
              :rules="'required'"
              :id="'stateOrCountry'"
              v-model="scoutTagCopy.name">
            </typeahead-vertical>
          </div>
        </div>

        <div v-else>
          <label>Location</label>

          <div class="keyword-search-container typeahead-container">
            <validation-provider rules="required|geocodeExists" :mode="passiveAggressive" v-slot="{ errors }">
              <input
                type="text"
                :class="['form-control keyword-search-input', { 'has-error': errors.length }]"
                placeholder="Search..."
                autocomplete="off"
                v-model="scoutTagCopy.name">
              <svg-icon name="search" class="base-icon keyword-search-icon" @click="addRadiusTerm(term)"></svg-icon>

              <div class="error-text top-5" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div v-if="!isPersisted" class="bottom-30">
        <label class="check-option vertical medium-large">
          <span class="normal-weight">Create a Saved Search for this Tag</span>
          <input type="checkbox" name="savedSearch" id="saved-search" v-model="createSavedSearch">
          <span class="check c-box"></span>
        </label>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import DropdownSelectColor from 'vue-app/shared/components/dropdown-select-color.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationProvider } from 'vee-validate';
import FilterService from 'vue-app/shared/services/filter-service.js';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import { toLower, startCase } from 'lodash';

export default {
  name: 'ScoutTagEditingModal',

  components: {
    ErrorMessage,
    DropdownSelectColor,
    EditModal,
    ValidationProvider,
    TypeaheadVertical,
    SvgIcon
  },

  mixins: [
    interactionModes
  ],

  props: {
    scoutTag: {
      type: Object,
      required: true
    },

    onCreate: {
      type: Function,
      default: () => {}
    },

    onUpdate: {
      type: Function,
      default: () => {}
    },

    practiceAreas: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      colors: ['blue', 'gray', 'green', 'purple', 'red', 'yellow'],
      nameMaxlength: 30,
      createSavedSearch: false,
      tagTypes: [
        { text: 'General', value: 'general' },
        { text: 'Practice Area', value: 'practice_area' },
        { text: 'Location', value: 'location' }
      ],
      locationSearchOptions: [
        { label: 'Search by State or Country', value: true },
        { label: 'Search by City or County', value: false }
      ],
      filterService: new FilterService(),
      stateAndCountryOptions: []
    };
  },

  computed: {
    isPersisted() {
      return !!this.scoutTag.id;
    },

    triggerName() {
      return this.isPersisted ? 'Edit tag' : '+ Add Law Firm tag';
    },

    modalTitle() {
      return this.isPersisted ? `Edit ${this.tagTypeDisplay} tag` : 'Add Law Firm tag';
    },

    tagTypeDisplay() {
      return startCase(toLower(this.scoutTag.tagType));
    },

    practiceAreaOptions() {
      return this.practiceAreas.map(area => ({ id: area.id, label: area.name }));
    },

    initialPracticeArea() {
      return this.isPersisted ? this.practiceAreas.find(practiceArea => practiceArea.id === this.scoutTag.practiceAreaId)?.name : '';
    },

    defaultScoutTag() {
      if (this.isPersisted) {
        return this.scoutTag;
      }
      return {
        tagType: 'general',
        isStateAndCountry: true
      };
    }
  },

  mounted() {
    this.fetchStateAndCountries();
  },

  methods: {
    showCharactersLimitedMessage(scoutTagCopy) {
      return scoutTagCopy.name?.length >= this.nameMaxlength;
    },

    submitForm(scoutTagCopy) {
      if (this.isPersisted) {
        return this.onUpdate(scoutTagCopy);
      }
      else {
        return this.onCreate(scoutTagCopy, this.createSavedSearch);
      }
    },

    reset() {
      this.$refs.dropdownSelectColor.reset();
      this.createSavedSearch = false;
    },

    practiceAreaChanged(practiceArea, scoutTagCopy) {
      if (practiceArea) {
        scoutTagCopy.name = practiceArea.label;
        scoutTagCopy.practiceAreaId = practiceArea.id;
      }
    },

    fetchStateAndCountries() {
      return this.filterService.getFilterOptions('location')
        .then((response) => {
          this.stateAndCountryOptions = response.map(obj => obj.label);
          return response;
        });
    }
  }
};
</script>
