<template>
  <div class="shadowed-box">
    <div class="box-header compact text-uppercase">
      Lawyer Screening Terms
    </div>

    <div class="box-content size-text-13px">
      <loading-section name="screeningTemplate" :render-after-loading="true">
        <div v-for="(question, index) in questions" :key="question.key">
          <screening-term-add
            class="bottom-20"
            :question="question"
            :on-add="addOption">
          </screening-term-add>

          <screening-term-options
            :question="question"
            :on-remove="removeOption">
          </screening-term-options>

          <hr class="kenny-hr" v-if="index !== questions.length - 1">
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import ScreeningTermAdd from './screening-term-add.vue';
import ScreeningTermOptions from './screening-term-options.vue';
import { transform } from 'lodash';

export default {
  name: 'ScreeningTerms',

  components: {
    ScreeningTermAdd,
    ScreeningTermOptions
  },

  props: {
    template: {
      type: Object,
      required: true
    },

    onAddOption: {
      type: Function,
      required: true
    },

    onRemoveOption: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      termKeys: ['experienceTypes', 'referralSources', 'communicationTools']
    };
  },

  computed: {
    questions() {
      return transform(this.termKeys, (result, key) => {
        result.push(this.questionFor(key));
      }, []);
    }
  },

  methods: {
    questionFor(key) {
      return { key: key, value: this.template[key] };
    },

    addOption(question, option) {
      this.onAddOption(question, option);
    },

    removeOption(question, option) {
      this.onRemoveOption(question, option);
    }
  }
};
</script>
