<template>
  <div class="add-input-container">
    <input type="text" v-model="tag" class="form-control add-input" autocomplete="off" @keydown.enter.prevent="addTag">

    <a href="" class="add-icon" @click.prevent="addTag"><svg-icon name="plus-circle" class="base-icon"></svg-icon></a>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'AddTagInput',

  components: {
    SvgIcon
  },

  props: {
    onAdd: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      tag: null
    };
  },

  methods: {
    addTag() {
      if (this.tag && this.tag.length > 0) {
        this.onAdd(this.tag);
        this.tag = null;
      }
    },

    resetTag() {
      this.tag = '';
    }
  }
};
</script>

<style lang="scss" scoped>
  .add-input-container {
    position: relative;
  }

  .add-input {
    padding-right: 40px;
  }

  .add-icon {
    position: absolute;
    top: 15px;
    right: 15px;

    @media (min-width: 768px) {
      top: 13px;
    }
  }

  .add-icon, .base-icon {
    display: block;
    width: 15px;
    height: 15px;
  }
</style>
