<template>
  <validation-observer v-slot="{ handleSubmit }">
    <form role="form" class="a-form" novalidate>
      <div class="bottom-40">
        <label for="workspace-name">Team Name <span class="red-text">*</span></label>

        <div>
          <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
            <input type="text" name="workspaceName" id="workspace-name" :class="['form-control', { 'has-error': errors.length }]" autocomplete="off" v-model="name">

            <div class="error-text top-5" v-if="errors.length">
              <error-message :errors="errors"></error-message>
            </div>
          </validation-provider>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-8 col-sm-6">
          <loading-button name="submitWorkspace" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(createWorkspace)">Create Team</loading-button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import Workspace from 'resources/workspace';

export default {
  name: 'CreateWorkspaceForm',

  components: {
    ErrorMessage,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  data() {
    return {
      name: ''
    };
  },

  methods: {
    createWorkspace() {
      LoadingService.loading('submitWorkspace');

      Workspace.save({ workspace: { name: this.name } })
        .then((workspace) => {
          window.location = `admin-app/workspaces/${workspace.id}`;
        })
        .finally(() => {
          LoadingService.done('submitWorkspace');
        });
    }
  }
};
</script>
