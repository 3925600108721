<template>
  <div>
    <label :for="`lawyer-rate-${suggestedLawyer.id}`">{{ suggestedLawyer.lawyer.fullName }} Proposed Hourly Rate <span class="inline-help required" v-if="rateIsRequired">*</span></label>

    <div class="row tight-columns">
      <div class="col-sm-3">
        <currency-input-vertical
          v-model="proposedHourlyRate"
          :id="`lawyer-rate-${suggestedLawyer.id}`"
          :name="`lawyerRate${suggestedLawyer.id}`"
          :rules="rateValidationRules"
          @input="handleInput">
        </currency-input-vertical>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';

export default {
  name: 'QuoteFormPrioriSuggestedLawyer',

  components: {
    CurrencyInputVertical
  },

  props: {
    suggestedLawyer: {
      type: Object,
      required: true
    },

    rateIsRequired: {
      type: Boolean,
      default: true
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      proposedHourlyRate: this.suggestedLawyer.proposedHourlyRate
    };
  },

  computed: {
    rateValidationRules() {
      return {
        minValue: 0.01,
        required: this.rateIsRequired
      };
    }
  },

  methods: {
    handleInput() {
      this.onChange(this.suggestedLawyer, 'proposedHourlyRate', this.proposedHourlyRate);
    }
  }
};
</script>
