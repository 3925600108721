<template>
  <boxed-content title="Relationship Partners" v-if="displayComponent">
    <div class="clickable relationship-partner" v-for="lawyer in relationshipPartners" :key="`relationship-partner-${lawyer.id}`" @click="viewScoutLawyer(lawyer.id)">
      <lawyer-name-plate :lawyer="lawyer" :hide-firm="true"></lawyer-name-plate>
    </div>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import BoxedContent from 'vue-app/shared/components/boxed-content';
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate';

export default {
  name: 'FirmRelationshipPartners',

  components: {
    BoxedContent,
    LawyerNamePlate
  },

  mixins: [
    displayRules
  ],

  props: {
    firm: {
      type: Object,
      required: true
    }
  },

  computed: {
    displayComponent() {
      return this.anyDataIsPresent([this.firm.relationshipPartners]);
    },

    relationshipPartners() {
      return this.firm.relationshipPartners;
    }
  }
};
</script>

<style lang="scss" scoped>
  .relationship-partner:not(:last-child) {
    margin-bottom: 20px;
  }
</style>
