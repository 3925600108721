<template>
  <div>
    <div class="semibold-weight bottom-10">
      Lawyers
    </div>

    <div v-if="hasSuggestedLawyers">
      <div class="list-spacing-10" v-for="lawyer in orderedLawyers" :key="lawyer.lawyerId">
        <quote-form-priori-suggested-lawyer
          :suggested-lawyer="lawyer"
          :rate-is-required="ratesAreRequired"
          :on-change="onChange">
        </quote-form-priori-suggested-lawyer>
      </div>
    </div>

    <div class="gray-panel pill-gray-text text-center" v-else>
      No lawyers added
    </div>
  </div>
</template>

<script>
import QuoteFormPrioriSuggestedLawyer from './quote-form-priori-suggested-lawyer.vue';
import { orderBy } from 'lodash';

export default {
  name: 'QuoteFormPrioriSuggestedLawyers',

  components: {
    QuoteFormPrioriSuggestedLawyer
  },

  props: {
    prioriSuggestedLawyers: {
      type: Array,
      required: true
    },

    ratesAreRequired: {
      type: Boolean,
      default: true
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  computed: {
    orderedLawyers() {
      return orderBy(this.prioriSuggestedLawyers, ['name']);
    },

    hasSuggestedLawyers() {
      return this.prioriSuggestedLawyers.length > 0;
    }
  }
};
</script>
