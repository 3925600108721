<template>
  <div class="messaging-center-header-container conversation scout-conversation">
    <div class="messaging-center-header">
      <div class="messaging-header-text size-text-14px semibold-weight text-center text-ellipsis">
        <div class="bottom-5">
          <svg-icon name="law-firm" class="firm-icon small"></svg-icon>
        </div>

        <div class="text-ellipsis">
          {{ lawFirmName }}
        </div>

        <div class="text-ellipsis">
          {{ rfqName }}
        </div>

        <div class="size-text-12px normal-weight vertical-center center-justified">
          <span class="pill-gray-text right-7">{{ $pluralize('active participant', activeParticipantsCount, true) }}</span>
          <span class="clickable" @click="onViewParticipants"><svg-icon name="info" class="base-icon smaller"></svg-icon></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ConversationHeader',

  components: {
    SvgIcon
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    rfqInfo: {
      type: Object,
      required: true
    },

    onViewParticipants: {
      type: Function,
      required: true
    }
  },

  computed: {
    lawFirmName() {
      return this.rfqInfo.lawFirmName;
    },

    rfqName() {
      return `RFP #${this.rfqInfo.rfqId} – ${this.rfqInfo.rfqName}`;
    },

    activeParticipantsCount() {
      return this.conversation.participants.filter(p => p.accessible).length;
    }
  }
};
</script>
