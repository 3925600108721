<template>
  <div :id="`proposal-lawyer-${possibleLawyer.id}`" class="shadowed-box size-text-13px">
    <div class="box-header text-uppercase">
      <div class="row">
        <div class="col-sm-6">
          <span v-if="isRetired" class="red-text">RETIRED FROM PROPOSAL</span>
          <span v-else>Quote {{ orderNumber }}<span v-if="isNewQuote" class="red-text left-15">UNSENT</span></span>
        </div>

        <div class="col-sm-6 text-right-not-xs">
          <button type="button" class="pseudo-link-blue bold-link" @click="toggleDetails"><svg-icon :name="expanded ? 'minimize' : 'expand'" class="base-icon right-10"></svg-icon><span class="button-text-container">{{ expanded ? 'Hide Details' : 'View Details' }}</span></button>
        </div>
      </div>
    </div>

    <div class="box-content">
      <a :href="possibleLawyerPath" class="text-link user-pic-container right-20" target="_blank"><profile-photo :user="lawyer" :name="lawFirm.name"></profile-photo></a>

      <div class="search-result-row">
        <div class="row tight-columns">
          <div class="col-sm-10 semibold-weight top-6">
            <div class="size-text-20px text-ellipsis">
              <a :href="possibleLawyerPath" class="text-link" target="_blank">{{ lawFirm.name || lawyer.fullName }}</a>
            </div>

            <div class="text-ellipsis">
              <span v-if="isLawFirm">
                <a :href="possibleLawyerPath" class="text-link right-20" target="_blank">{{ lawyer.fullName }}</a>
                <span class="right-20">&#8231;</span>
              </span>

              <a :href="possibleLawyerPath" :class="['text-link', (isLawyerAvailable ? 'green-text' : 'red-text')]" target="_blank">{{ lawyerAccountStatus }}</a>
            </div>
          </div>

          <div class="col-sm-2 text-right-not-xs">
            <options-menu
              :counsel-request="counselRequest"
              :possible-lawyer="possibleLawyer"
              :is-new-quote="isNewQuote"
              :on-remove="deletePossibleLawyer"
              :on-retire="retirePossibleLawyer">
            </options-menu>
          </div>
        </div>
      </div>
    </div>

    <div v-show="expanded">
      <hr class="kenny-hr no-margin">

      <div class="box-content symmetrical">
        <div class="clear-panel variable-padded bottom-30">
          <form role="form" name="possibleLawyerForm" class="a-form" novalidate>
            <proposal-bio-editor
              class="bottom-40"
              :possible-lawyer="possibleLawyer"
              :on-change="savePossibleLawyer">
            </proposal-bio-editor>

            <div class="semibold-weight bottom-10">
              Details
            </div>

            <div class="bottom-30">
              <hr class="kenny-hr no-margin">
            </div>

            <proposal-lawyer-sections
              v-if="!isLawFirm"
              :possible-lawyer="possibleLawyer"
              :is-rfq-quote="true"
              :on-change="savePossibleLawyer">
            </proposal-lawyer-sections>

            <proposal-firm-sections
              v-else
              :possible-lawyer="possibleLawyer"
              :is-rfq-quote="true"
              :on-change="savePossibleLawyer">
            </proposal-firm-sections>
          </form>
        </div>

        <div v-if="isNewQuote && !isEditingQuote && !isRetired" class="row">
          <div class="col-sm-6 col-lg-5">
            <button type="button" class="nv-button-blue smaller" @click="startEditingQuote">Respond to This RFP</button>
          </div>
        </div>

        <div v-if="(!isNewQuote || isRetired) && !isEditingQuote" class="clear-panel variable-padded">
          <div v-if="!isRetired" class="text-right bottom-15">
            <button type="button" class="pseudo-link-blue" @click="startEditingQuote">Edit Answers</button>
          </div>

          <quote-detail
            :rfq="rfq"
            :quote="quote"
            :suggested-lawyers="suggestedLawyers"
            :viewing-as-client="false"
            :has-new-question-activities="hasNewQuestionActivities">
          </quote-detail>
        </div>

        <div v-if="isEditingQuote" class="clear-panel variable-padded">
          <quote-form
            v-if="quote"
            :rfq="rfq"
            :quote="quote"
            :on-update-file-answer="onUpdateFileAnswer"
            :on-submit="submitQuote"
            :on-cancel="stopEditingQuote">
          </quote-form>
        </div>

        <div class="text-center top-30" v-if="isRetired">
          <button type="button" class="pseudo-link-blue bold-link light-blue-k-text" @click="addBackToQuote">Add Back To Quote</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import OptionsMenu from 'vue-app/admin/requests/proposal-options-menu.vue';
import ProposalLawyerSections from 'vue-app/admin/requests/proposal-lawyer-sections.vue';
import ProposalFirmSections from 'vue-app/admin/requests/proposal-firm-sections.vue';
import QuoteForm from 'vue-app/admin/requests/quote-form.vue';
import QuoteDetail from 'vue-app/scout/shared/rfqs/quote-detail.vue';
import dateComparisons from 'vue-app/shared/mixins/date-comparisons.js';

export default {
  name: 'QuoteLawyer',

  components: {
    SvgIcon,
    ProfilePhoto,
    OptionsMenu,
    ProposalLawyerSections,
    ProposalFirmSections,
    QuoteForm,
    QuoteDetail
  },

  mixins: [dateComparisons],

  props: {
    counselRequest: {
      type: Object,
      required: true
    },

    possibleLawyer: {
      type: Object,
      required: true
    },

    orderNumber: {
      type: Number,
      required: true
    },

    onUpdatePossibleLawyer: {
      type: Function,
      required: true
    },

    onDeletePossibleLawyer: {
      type: Function,
      required: true
    },

    onRetirePossibleLawyer: {
      type: Function,
      reuqired: true
    },

    onUpdateFileAnswer: {
      type: Function,
      required: true
    },

    onSubmitQuote: {
      type: Function,
      required: true
    },

    onAddBackToQuote: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      expanded: true,
      isEditingQuote: false
    };
  },

  computed: {
    lawyer() {
      return this.possibleLawyer.lawyer;
    },

    lawFirm() {
      return this.possibleLawyer.lawFirm || {};
    },

    isLawFirm() {
      return !!this.possibleLawyer.lawFirm;
    },

    isRetired() {
      return this.possibleLawyer.retired;
    },

    possibleLawyerPath() {
      return `/admin-app/lawyers/${this.possibleLawyer.lawyerId}/proposal/${this.counselRequest.id}`;
    },

    lawyerAccountStatus() {
      return this.lawyer.accountStatus();
    },

    isLawyerAvailable() {
      return this.lawyerAccountStatus === 'Available';
    },

    quote() {
      return this.possibleLawyer.rfqLawFirmQuote;
    },

    rfq() {
      return this.counselRequest.scoutRfq;
    },

    suggestedLawyers() {
      return this.quote.prioriSuggestedLawyers;
    },

    hasNewQuestionActivities() {
      if (this.quote.latestQuestionActivity && !this.quoteLastSubmittedAt) { return true; }

      return this.dateIsAfter(this.quote.latestQuestionActivity?.createdAt, this.quoteLastSubmittedAt);
    },

    isNewQuote() {
      return !this.quote || ['new_rfq', 'draft'].includes(this.quote.status);
    }
  },

  mounted() {
    this.expanded = this.isNewQuote && !this.isRetired;
  },

  methods: {
    expandDetails() {
      this.expanded = true;
    },

    collapseDetails() {
      this.expanded = false;
    },

    toggleDetails() {
      this.expanded = !this.expanded;
    },

    savePossibleLawyer(possibleLawyer) {
      if (this.isRetired) { return; }

      possibleLawyer.id = this.possibleLawyer.id;
      return this.onUpdatePossibleLawyer(possibleLawyer);
    },

    startEditingQuote() {
      this.isEditingQuote = true;
    },

    stopEditingQuote() {
      this.isEditingQuote = false;
    },

    deletePossibleLawyer() {
      this.onDeletePossibleLawyer(this.possibleLawyer);
    },

    retirePossibleLawyer() {
      if (this.isRetired) { return; }
      this.onRetirePossibleLawyer(this.possibleLawyer);
    },

    addBackToQuote() {
      if (!this.isRetired) { return; }
      this.onAddBackToQuote(this.possibleLawyer);
    },

    submitQuote(quote) {
      this.onSubmitQuote(quote).then(() => {
        this.stopEditingQuote();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .button-text-container {
    display: inline-block;
    width: 75px;
    text-align: right;
  }
</style>
