<template>
  <priori-modal
    ref="modal"
    modal-id="edit-saved-searches"
    :title="modalTitle"
    @on-modal-close="onCloseModal"
    :backdrop="false">
    <template #modal-trigger>
      <slot></slot>
    </template>

    <template #default>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form role="form" class="a-form" autocomplete="off" novalidate>
          <div class="bottom-30">
            <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
              <label for="search-name">Saved Search Name <span class="red-text">*</span></label>

              <input type="text" name="searchName" id="search-name" :class="['form-control', { 'has-error': errors.length }]" v-model="savedSearchCopy.name">

              <div class="error-text top-5" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>

              <div class="error-text top-5" v-if="nameErrors.length">
                <error-message :errors="nameErrors"></error-message>
              </div>
            </validation-provider>
          </div>

          <div class="bottom-20">
            <validation-provider rules="required" v-slot="{ errors }">
              <label>Who can view this saved search?</label>

              <div>
                <label class="check-option vertical">
                  <input type="radio" name="searchVisibility" v-model="savedSearchCopy.visibility" value="private">
                  <span class="check c-radio"></span>
                  <span class="normal-weight">Private (only you can view this saved search)</span>
                </label>

                <label class="check-option vertical">
                  <input type="radio" name="searchVisibility" v-model="savedSearchCopy.visibility" value="shared">
                  <span class="check c-radio"></span>
                  <span class="normal-weight">Shared (all admins can view this saved search)</span>
                </label>
              </div>

              <div class="error-text top-5" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>

              <div class="error-text top-5" v-if="visibilityErrors.length">
                <error-message :errors="visibilityErrors"></error-message>
              </div>
            </validation-provider>
          </div>

          <interface-note
            v-if="visibilityChanged"
            :note-text="warningMessage"
            icon="exclamation"
            variant="warning">
          </interface-note>

          <div class="row top-40">
            <div class="col-sm-4">
              <loading-button name="save-button" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(submitForm)">Save</loading-button>
            </div>

            <div class="col-sm-4 top-20-xs">
              <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';
import SavedSearch from 'resources/admin/saved-search.js';

export default {
  name: 'SavedSearchModal',

  components: {
    PrioriModal,
    ValidationObserver,
    ValidationProvider,
    InterfaceNote
  },

  mixins: [
    interactionModes
  ],

  props: {
    savedSearch: {
      type: Object,
      required: true
    },

    onSaved: {
      type: Function,
      required: true
    },

    showModal: {
      type: Boolean,
      default: false
    },

    onCloseModal: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      savedSearchCopy: {},
      warningMessage: 'Warning: Changing the status of a Saved Search to Private will prevent other Priori admins from using this Saved Search.',
      nameErrors: [],
      visibilityErrors: []
    };
  },

  computed: {
    visibilityChanged() {
      return this.savedSearchCopy.visibility &&
        this.savedSearchCopy.visibility === 'private' &&
        this.savedSearch.visibility === 'shared';
    },

    modalTitle() {
      return this.savedSearch.id ? 'Edit Details' : 'New Saved Search';
    }
  },

  watch: {
    showModal(showModal) {
      if (showModal) {
        this.openModal();
      }
    },

    savedSearch(value) {
      this.savedSearchCopy = { ...value };
    }
  },

  methods: {
    openModal() {
      this.savedSearchCopy = { ...this.savedSearch };

      this.nameErrors = [],
      this.visibilityErrors = [];

      this.$refs.modal.openModal();
    },

    submitForm() {
      if (this.savedSearchCopy.id) {
        this.updateSavedSearch();
      }
      else {
        this.saveSavedSearch();
      }
    },

    updateSavedSearch() {
      LoadingService.loading('save-button');

      this.nameErrors = [];
      this.visibilityErrors = [];

      SavedSearch.update({ id: this.savedSearch.id, savedSearch: this.savedSearchCopy })
        .then(response => {
          this.onSaved(response);
          this.closeModal();

          NotificationService.success('Search successfully saved');
          window.scrollTo(0, 0);
        })
        .catch((response) => {
          this.nameErrors = response.response.data.errors?.name || [];
          this.visibilityErrors = response.response.data.errors?.visibility || [];
        })
        .finally(() => {
          LoadingService.done('save-button');
        });
    },

    saveSavedSearch() {
      LoadingService.loading('save-button');

      this.nameErrors = [];
      this.visibilityErrors = [];

      SavedSearch.save({ savedSearch: this.savedSearchCopy })
      .then(response => {
        this.onSaved(response);
        this.closeModal();

        NotificationService.keepAlive();
        NotificationService.success(`Saved search "${response.name}" was successfully created`);

        window.scrollTo(0, 0);
      })
      .catch((response) => {
        this.nameErrors = response.response.data.errors?.name || [];
        this.visibilityErrors = response.response.data.errors?.visibility || [];
      })
      .finally(() => {
        LoadingService.done('save-button');
      });
    },

    closeModal() {
      this.$refs.observer.reset();
      this.$refs.modal.dismissModal();
      this.onCloseModal();
    }
  }
};
</script>
