<template>
  <div v-if="lineBreak">
    <div class="vertical-center" v-if="score">
      <rating-circle class="right-5" :rating="rating"></rating-circle>
      <span class="size-text-13px bold-weight">{{ displayScore }}</span>
    </div>

    <div class="dark-gray-text">
      {{ unitCount }}
    </div>
  </div>

  <div v-else class="vertical-center">
    <rating-circle class="right-5" :rating="rating"></rating-circle>
    <span class="size-text-13px bold-weight right-5" v-if="score">{{ displayScore }}</span>
    <span class="dark-gray-text right-5" v-if="score">•</span>
    <span class="dark-gray-text">{{ unitCount }}</span>
  </div>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';

export default {
  name: 'RatingCountPlate',

  components: {
    RatingCircle
  },

  props: {
    score: {
      type: Number,
      required: false
    },

    maxScore: {
      type: Number,
      required: true
    },

    count: {
      type: Number,
      required: true
    },

    unit: {
      type: String,
      required: true
    },

    lineBreak: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    displayScore() {
      return this.score ? Number(this.score).toFixed(1) : 'N/A';
    },

    rating() {
      const percent = this.score ? this.score / this.maxScore * 100 : 0;
      return { percent: percent };
    },

    unitCount() {
      return `${this.count || 'No'} ${this.$pluralize(this.unit, this.count)}`;
    }
  }
};
</script>
