<template>
  <form role="form" name="form" class="a-form filter-popover" novalidate>
    <div class="text-right bottom-15">
      <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
        Clear Filter
      </button>
    </div>

    <div :class="['keyword-search-container', { 'bottom-20': !lockContext }]">
      <input
        type="text"
        name="matchSearch"
        id="match-search"
        class="form-control keyword-search-input"
        placeholder="Search by name..."
        autocomplete="off"
        v-model="keyword"
        @blur="addTerm(keyword)"
        @keydown.enter.prevent="addTerm(keyword)">

      <svg-icon name="search" class="base-icon keyword-search-icon"></svg-icon>
    </div>

    <div v-if="showOptionsHeader && isOrLogic" class="vertical-center justify-between semibold-weight tight-lines top-15">
      <div>
        Required
      </div>

      <div class="d-flex col-gap-5">
        <button
          type="button"
          :class="['toggle-button md light-background', { 'toggle-on': isFilterContext }]"
          @click="switchFilterContext">
          {{ isFilterContext ? 'Yes' : 'No&nbsp;' }}
        </button>
      </div>
    </div>

    <div v-if="showOptionsHeader && !isOrLogic" class="vertical-center justify-between semibold-weight tight-lines top-15">
      <div>Filter Criteria:</div>

      <div v-if="!lockContext" class="d-flex col-gap-5">
        Required

        <span v-tooltip.right="tooltipText">
          <svg-icon name="info" class="base-icon smaller"></svg-icon>
        </span>
      </div>
    </div>

    <div class="top-15" fade-scroll="top bottom">
      <div class="check-options">
        <div v-for="(term, index) in selectedTerms" :key="term" class="d-flex justify-between col-gap-10 bottom-10">
          <label
            class="check-option medium-large no-margin"
            :for="`${field}-selected-${index}`">
            {{ term }}

            <input
              type="checkbox"
              :name="`selected${index}`"
              :id="`${field}-selected-${index}`"
              :checked="isTermSelected(term)"
              @change="removeTerm(term)">

            <span class="check c-box"></span>
          </label>

          <button
            type="button"
            v-if="!isOrLogic && !lockContext"
            :class="['toggle-button md', { 'toggle-on': termIsFilterContext(term) }]"
            @click="switchTermContext(term)">
            {{ termIsFilterContext(term) ? 'Yes' : '&nbsp;No' }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="!lockContext" class="filter-footer multiline semibold-weight">
      Set the “Required” toggle to “Yes” to ensure all search results meet {{ isOrLogic ? 'one of the' : 'the' }} selected criteria.
    </div>
  </form>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import searchFilter from 'vue-app/shared/mixins/search-filter.js';

export default {
  name: 'FilterMatch',

  components: {
    SvgIcon
  },

  mixins: [
    searchFilter
  ],

  props: {
    searchService: {
      type: Object,
      required: true
    },

    filterParams: {
      type: Object,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      field: this.filterParams.field,
      isOrLogic: this.filterParams.logic === 'or',
      lockContext: !!this.filterParams.lockContext,
      keyword: '',
      tooltipText: 'Set the “Required” toggle to “Yes” to ensure all search results meet the selected criteria. If the “Required” toggle is applied to multiple filter criteria, the search result set will be limited to results that meet all of the selected criteria.'
    };
  },

  computed: {
    showOptionsHeader() {
      return !this.lockContext && this.hasSelectedTerms;
    }
  },

  methods: {
    addTerm(term) {
      if (!term.length) { return; }

      this.searchService.addSearchTerms(this.field, term, this.termContext(term));
      this.onSearch();
      this.keyword = '';
    },

    removeTerm(term) {
      this.searchService.removeSearchTerm(this.field, term, this.termContext(term));
      this.onSearch();
    },

    clearFilter() {
      this.keyword = '';
      this.searchService.unsetFilter(this.field);
      this.onSearch();
    }
  }
};
</script>
