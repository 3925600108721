<template>
  <div :class="['message clearfix', { 'received': isReceivedMessage, 'sent': isSentMessage }]">
    <div v-if="isReceivedMessage">
      <div :class="['message-photo', (senderIsClient ? 'client-icon' : 'lawyer-icon')]">
        <profile-photo :user="sender" :small="true"></profile-photo>
      </div>
    </div>

    <div>
      <div :class="['size-text-11px dark-gray-text semibold-weight bottom-5', { 'text-left': isSentMessage, 'text-right': isReceivedMessage }]">
        {{ updatedAt }}
      </div>

      <div :class="['bubble', { 'left': isReceivedMessage, 'right': isSentMessage, 'client-sent': isReceivedMessage && senderIsClient }]">
        <div v-if="isReceivedMessage">
          <div class="tight-lines">
            <span :class="['semibold-weight', { 'text-italic pill-gray-text': senderInactive }]">{{ sender.fullName }}</span><span :class="['text-italic left-5', { 'pill-gray-text': senderInactive }]">({{ sender.company || sender.lawFirm }}<span v-if="senderInactive"> – {{ inactiveText }}</span>)</span>
          </div>
        </div>

        <div :class="['simple-text', { 'top-2': isReceivedMessage, 'text-italic': senderInactive }]" v-html="message.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'Message',

  components: {
    ProfilePhoto
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    receipt: {
      type: Object,
      required: true
    }
  },

  computed: {
    message() {
      return this.receipt.message;
    },

    isSentMessage() {
      return this.receipt.mailboxType === 'sentbox';
    },

    isReceivedMessage() {
      return !this.isSentMessage;
    },

    sender() {
      return this.conversation.participants.find(p => p.id === this.message.senderId && p.klass === this.message.senderType);
    },

    senderIsClient() {
      return this.sender.klass === 'Client';
    },

    senderInactive() {
      return !this.sender.accessible;
    },

    inactiveText() {
      return this.sender.deactivated ? 'Deactivated User' : 'No Access';
    },

    updatedAt() {
      return DateFilter.shortTime(this.message.updatedAt);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  :deep(.missing-photo.small) {
    font-size: 13px;
    font-weight: 600;
    box-shadow: none;
  }

  .client-icon :deep(.missing-photo) {
    color: $white;
    background-color: $k-blue;
  }

  .lawyer-icon :deep(.missing-photo) {
    color: $gray-6;
    background-color: $k-darker-gray;
  }
</style>
