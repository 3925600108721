import axios from 'axios';
import BaseResource from 'resources/base-resource';

class ScoutWorkspace extends BaseResource {
  static baseUrl = '/scout/workspaces/:id.json';

  static sendWorkspaceInvite(params) {
    return axios.post('/scout/workspaces/send_workspace_invite.json', params);
  }
}

export default ScoutWorkspace;
