<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      E-Billing Upload
    </div>

    <div class="box-content">
      <div class="bottom-20">
        Upload your E-Billing information to our platform via API or file transfer.
      </div>

      <div>
        <a href="/scout-company/data-uploads" class="primary-btn-night-blue"><span class="hidden-sm">Launch </span>Dashboard</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EBillingUpload'
};
</script>

<style lang="scss" scoped>
  .primary-btn-night-blue {
    @media (min-width: 768px) {
      padding: 11px 6px;
    }
  }
</style>
