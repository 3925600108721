<template>
  <div class="shadowed-box">
    <div class="box-header">
      IDENTITY VERIFICATION NEEDED
    </div>

    <div class="box-content size-text-13px normal-weight">
      <div class="bottom-20">
        In order to accept payments through Priori, you must <a href="/lawyer-app/identity">verify your identity</a> for our third-party payment processor, <a href="https://stripe.com/" target="_blank">Stripe</a>.
      </div>

      <div>
        <a href="/lawyer-app/identity" class="nv-button-purple-red smaller">Verify Identity</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketplaceLawyerSettingsIdentityVerification'
};
</script>
