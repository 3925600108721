import BaseResource from 'resources/base-resource';
import axios from 'axios';

class ScoutMatter extends BaseResource {
  static baseUrl = '/scout/matters/:id.json';

  static matterLookup(params) {
    return axios.get('/scout/matters/available_to_connect_to_rfq.json', { params: params });
  }

  static connectedMattersLookup(params) {
    return axios.get('/scout/matters/connected_to_rfq.json', { params: params });
  }
}

export default ScoutMatter;
