<template>
  <div v-if="firm.id">
    <div class="titlebar lawyer-titlebar">
      <div class="vertical-center-not-xs">
        <div class="inline-block right-20-not-xs">
          <svg-icon name="law-firm" class="firm-icon large"></svg-icon>
        </div>

        <div class="text-img-adjacent">
          <div class="medium-page-heading text-ellipsis">
            {{ firm.name }}
          </div>

          <loading-section name="firm-size" class="left-aligned">
            <div class="size-text-16px inline-flex col-gap-15">
              <span>{{ firm.firmSize }}</span>

              <span>
                <edit-firm-details
                  :firm="firm"
                  :can-edit-profile="canEditProfile"
                  :on-save="onSave">
                </edit-firm-details>
              </span>
            </div>
          </loading-section>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-8">
              <firm-about
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-about>

              <firm-practice-areas
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-practice-areas>

              <firm-office-locations
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-office-locations>

              <firm-diversity
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-diversity>

              <firm-representative-matters
                :firm-id="firm.id"
                :matters="firm.representativeMatters"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-representative-matters>
            </div>

            <div class="col-sm-4 top-30-xs">
              <firm-relationship-partners
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-relationship-partners>

              <firm-awards
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-awards>

              <firm-highlights
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-highlights>

              <firm-leadership
                class="bottom-30"
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-leadership>

              <firm-sustainability
                :firm="firm"
                :can-edit-profile="canEditProfile"
                :on-save="onSave">
              </firm-sustainability>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirmAbout from 'vue-app/scout/shared/law-firms/firm-about.vue';
import FirmPracticeAreas from 'vue-app/scout/shared/law-firms/firm-practice-areas.vue';
import FirmOfficeLocations from 'vue-app/scout/shared/law-firms/firm-office-locations.vue';
import FirmDiversity from 'vue-app/scout/shared/law-firms/firm-diversity.vue';
import FirmRelationshipPartners from 'vue-app/scout/shared/law-firms/firm-relationship-partners.vue';
import FirmAwards from 'vue-app/scout/shared/law-firms/firm-awards.vue';
import FirmHighlights from 'vue-app/scout/shared/law-firms/firm-highlights.vue';
import FirmSustainability from 'vue-app/scout/shared/law-firms/firm-sustainability.vue';
import FirmLeadership from 'vue-app/scout/shared/law-firms/firm-leadership.vue';
import FirmRepresentativeMatters from 'vue-app/scout/shared/law-firms/firm-representative-matters.vue';
import EditFirmDetails from 'vue-app/scout/shared/law-firms/edit-firm-details.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

import ScoutFirm from 'resources/scout/scout-firm';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import PermissionsService from 'vue-app/shared/services/permissions-service.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import { mapState } from 'pinia';
import { each } from 'lodash';

export default {
  name: 'FirmProfile',

  components: {
    FirmAbout,
    FirmPracticeAreas,
    FirmOfficeLocations,
    FirmDiversity,
    FirmRelationshipPartners,
    FirmAwards,
    FirmHighlights,
    FirmSustainability,
    FirmLeadership,
    FirmRepresentativeMatters,
    EditFirmDetails,
    SvgIcon,
    LoadingSection
  },

  data() {
    return {
      firm: {}
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    permissionsService() {
      return new PermissionsService(this.currentUser);
    },

    canEditProfile() {
      return this.permissionsService.can('editFirmProfile');
    }
  },

  mounted() {
    ScoutFirm.get({ track: true, view: 'detail' }).then((response) => {
      this.firm = this.serializeLawyers(response);
    });
  },

  methods: {
    onSave(firm, successFn, errorFn) {
      if (!this.canEditProfile) { return; }

      ScoutFirm.update(firm).then(
        (response) => {
          this.firm = this.serializeLawyers(response);

          if (successFn) { successFn(this.firm); }
        },
        () => {
          if (errorFn) { errorFn(); }
          NotificationService.error('Unable to save changes. Please try again.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      );
    },

    serializeLawyers(response) {
      each(response.relationshipPartnerWorkspaces, (workspace) => {
        workspace.lawyers = workspace.lawyers.map(lawyer => {
          return new ScoutLawyerBridge(lawyer);
        });
      });

      return response;
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep(.left-aligned) {
    .loading-modal {
      text-align: left;
    }
  }
</style>
