<template>
  <div class="shadowed-box">
    <div class="box-content">
      <div class="bottom-15">
        <div class="semibold-weight">
          Status
        </div>

        <div :class="['semibold-weight', { 'green-text': lawyer.isApproved(), 'red-text': !lawyer.isApproved() }]">
          {{ lawyer.displayStatus() }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Account Type
        </div>

        <div class="semibold-weight purple-text text-capitalize">
          {{ startCase(lawyer.accountType) }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Available
        </div>

        <div v-if="lawyer.isRejected()">
          <span>&mdash;</span>
        </div>

        <div v-if="!lawyer.isRejected()">
          <span :class="['semibold-weight', { 'green-text': lawyer.available, 'red-text': !lawyer.available }]">{{ lawyer.available ? 'Yes': 'No' }}</span>

          <priori-modal ref="unavailabilityModal" modal-id="unavailability-modal" v-if="!indefiniteUnavailability && unavailabilities.length">
            <template #modal-trigger>
              <span class="left-10">(<a href="" class="dark-gray-link" @click.prevent="viewUnavailabilities()">details</a>)</span>
            </template>

            <template #title>
              Scheduled Out-of-Office Periods
            </template>

            <template #default>
              <div v-for="unavailability in unavailabilitiesSorted" :key="unavailability.id">
                {{ formatDate(unavailability.startDate) }} - {{ formatDate(unavailability.endDate) }}
              </div>
            </template>
          </priori-modal>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Profile Rate
        </div>

        <div class="semibold-weight green-text">
          <span v-if="rate">{{ rate }}/hr</span>
          <span v-else>&mdash;</span>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Malpractice Insurance
        </div>

        <div :class="['semibold-weight', { 'green-text': malpractice && malpractice.hasSome, 'red-text': malpractice && malpractice.hasSome === false }]">
          <span v-if="hasMalpractice">{{ malpractice.hasSome ? 'Yes': 'No' }}</span>
          <span v-else>&mdash;</span>
        </div>
      </div>

      <div>
        <div class="semibold-weight bottom-10">
          Warning: Review History
        </div>

        <div class="row">
          <div class="col-xs-9 col-sm-8">
            <div class="two-btn-group alternate-group">
              <button type="button" :class="{ 'selected': lawyer.reviewHistory }" @click="toggleReviewHistory()">Yes</button>
              <button type="button" :class="{ 'selected': !lawyer.reviewHistory }" @click="toggleReviewHistory()">No</button>
            </div>
          </div>

          <div class="col-xs-3 col-sm-4 top-5">
            <span v-if="lawyer.reviewHistory" class="tag-list-item compact clear">
              <svg-icon name="warning" class="base-icon red-text"></svg-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import { find, sortBy, filter, round } from 'lodash';
import moment from 'moment';
import TextManipulation from 'vue-app/shared/mixins/text-manipulation';

export default {
  name: 'AccountStatus',

  mixins: [
    TextManipulation
  ],

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    unavailabilities: {
      type: Array,
      default: () => []
    },

    toggleReviewHistory: {
      type: Function,
      required: true
    },

    counselRequest: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    indefiniteUnavailability() {
      return find(this.unavailabilities, (u) => !u.endDate);
    },

    unavailabilitiesSorted() {
      let unavailabilities = filter(this.unavailabilities, (u) => u.endDate);
      return sortBy(unavailabilities, 'startDate');
    },

    malpractice() {
      return this.lawyer.malpracticeInsurancePolicy;
    },

    clientRateInDollars() {
      return (this.lawyer.clientRate || 0) / 100;
    },

    indicativeRate() {
      return (this.lawyer.indicativeRate || 0);
    },

    clientRateWithFees() {
      return (round(this.indicativeRate + (this.managementFeePercent * this.indicativeRate)) / 100);
    },

    managementFeePercent() {
      return (this.counselRequest.managementFeePercent / 100);
    },

    rate() {
      return CurrencyFilter.filter(this.counselRequest ? this.clientRateWithFees : this.clientRateInDollars);
    },

    hasMalpractice() {
      return this.malpractice && this.malpractice.hasSome !== null;
    }
  },

  methods: {
    viewUnavailabilities() {
      this.$refs.unavailabilityModal.openModal();
    },

    formatDate(date) {
      return moment(String(date)).format('MM/DD/YYYY');
    }
  }
};
</script>

<style lang="scss" scoped>
  section.uiv {
    display: inline;
  }
</style>

