<template>
  <div :class="['row tight-columns vertical-center-not-xs', { 'text-italic dark-gray-text': isDeactivated }]">
    <div class="col-sm-4">
      <div class="row tight-columns vertical-center-not-xs">
        <div class="col-sm-5 text-ellipsis">
          {{ name }}
        </div>

        <div class="col-sm-7">
          <div class="email-container">
            <span class="simple-text">{{ email }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-7">
      <div class="row tight-columns">
        <div class="col-sm-1-5">
          {{ hasVisibleProfileText }}
        </div>

        <div class="col-sm-1-5">
          {{ isEditorText }}
        </div>

        <div class="col-sm-1-5">
          {{ clientDataAccessText }}
        </div>

        <div class="col-sm-1-5">
          {{ userType }}
        </div>

        <div class="col-sm-1-5">
          {{ hasRfqEnabledText }}
        </div>
      </div>
    </div>

    <div class="col-sm-1 text-right-not-xs">
      <dropdown-menu :compact="true">
        <li class="menu-item" role="menuitem">
          <edit-lawyer-permissions
            :lawyer="lawyer"
            :employee-id-is-required="employeeIdIsRequired"
            :billing-systems="billingSystems"
            :on-save="onSave">
            <template #modal-trigger="{ openModal }">
              <a href="" @click.prevent="openModal">Edit User</a>
            </template>
          </edit-lawyer-permissions>
        </li>

        <li class="menu-item" role="menuitem">
          <a href="" @click.prevent="openDeactivationModal">{{ isDeactivated ? 'Reactivate User' : 'Deactivate User' }}</a>

          <confirmation-modal
            ref="deactivationModal"
            :title="`${isDeactivated ? 'Reactivate' : 'Deactivate'} User`"
            :yes-btn-text="isDeactivated ? 'Yes, Reactivate' : 'Yes, Deactivate'"
            no-btn-text="No, Cancel"
            :on-confirm-action="toggleUserDeactivation">
            <div class="semibold-weight size-text-14px bottom-20">
              {{ deactivationModalText }}
            </div>

            <div class="semibold-weight size-text-14px bottom-30">
              Are you sure you&rsquo;d like to {{ isDeactivated ? 'reactivate' : 'deactivate' }} this user?
            </div>
          </confirmation-modal>
        </li>

        <li v-if="lawyer.hasVisibleProfile" class="menu-item" role="menuitem">
          <a :href="`/scout-firm/lawyers/${lawyer.id}`" target="_blank">View Profile</a>
        </li>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import EditLawyerPermissions from 'vue-app/scout/firm/settings/edit-lawyer-permissions.vue';
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal-secondary.vue';

export default {
  name: 'UserListItem',

  components: {
    DropdownMenu,
    EditLawyerPermissions,
    ConfirmationModal
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    employeeIdIsRequired: {
      type: Boolean,
      default: false
    },

    billingSystems: {
      type: Array,
      default: () => []
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isDeactivated: this.lawyer.hiddenFromScout
    };
  },

  computed: {
    name() {
      return this.lawyer.fullName;
    },

    email() {
      return this.lawyer.email;
    },

    hasVisibleProfileText() {
      return this.booleanText(this.lawyer.hasVisibleProfile);
    },

    isEditorText() {
      return this.booleanText(this.lawyer.isEditor);
    },

    clientDataAccessText() {
      return this.booleanText(this.lawyer.partner);
    },

    userType() {
      if (this.isDeactivated) { return 'Deactivated'; }

      return this.lawyer.isScoutAdmin ? 'Admin' : 'Non-Admin';
    },

    hasRfqEnabledText() {
      return this.booleanText(this.lawyer.hasRfqEnabled);
    },

    deactivationModalText() {
      if (this.isDeactivated) {
        return 'Reactivating this user will give them access to the Scout platform.';
      }
      else {
        return 'Deactivating this user will remove their access to the Scout platform.';
      }
    }
  },

  methods: {
    booleanText(boolean) {
      return boolean ? 'Yes' : 'No';
    },

    openDeactivationModal() {
      this.$refs.deactivationModal.openModal();
    },

    toggleUserDeactivation() {
      const lawyer = Object.assign(this.lawyer, {
        hiddenFromScout: !this.isDeactivated,
        hiddenFromScoutAtChangedVia: 'user_interface'
      });

      this.onSave(lawyer).then(() => {
        this.isDeactivated = !this.isDeactivated;
      });
    }
  }
};
</script>

<style scoped lang="scss">
  .menu-item a {
    font-style: normal;
  }

  .email-container {
    padding-right: 5px;
  }
</style>
