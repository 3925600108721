<template>
  <div class="shadowed-box">
    <div class="box-header compact text-uppercase">
      Consultations Today
    </div>

    <div class="box-content dash-widget">
      <loading-section name="consultationsToday">
        <div class="size-text-13px" v-if="consultations.length">
          <table class="table auto-height no-margin">
            <tbody>
              <tr v-for="consultation in consultations" :key="consultation.id">
                <td>
                  {{ formattedDate(consultation.startsAt) }}
                </td>

                <td v-for="appointmentParticipant in consultation.appointmentParticipants" :key="appointmentParticipant.id">
                  <a :href="linkTo(appointmentParticipant)">{{ nameFor(appointmentParticipant) }}</a>
                </td>

                <td>
                  {{ consultation.status }}
                </td>

                <td>
                  <a :href="`/admin-app/appointments/${consultation.id}`" class="bold-weight size-text-12px">VIEW</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="gray-text" v-else>
          None Scheduled
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'ConsultationsToday',

  components: {
    LoadingSection
  },

  props: {
    consultations: {
      type: Array,
      required: true
    }
  },

  methods: {
    formattedDate(date) {
      return DateFilter.shortTime(date);
    },

    linkTo(participant) {
      return `/admin-app/${participant.participantType.toLowerCase()}s/${participant.participantId}`;
    },

    nameFor(participant) {
      return `${participant.participant.firstName} ${participant.participant.lastName}`;
    }
  }
};
</script>
