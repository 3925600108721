<template>
  <div class="pill-gray-text size-text-12px text-ellipsis">
    <span v-if="hasScoutQuotes">
      <span class="bold-weight">{{ yourFirmsCount }}</span> Your Firms
    </span>

    <span v-if="hasScoutQuotes && sentToMarketplace"> + </span>

    <span v-if="sentToMarketplace">
      <span class="bold-weight">{{ respondedMarketplaceQuotesCount }}</span> Marketplace
    </span>
  </div>
</template>

<script>
export default {
  name: 'ResponseCount',

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  computed: {
    scoutQuotes() {
      return this.rfq.lawFirmQuotes.filter(quote => !quote.fromMarketplace);
    },

    marketplaceQuotes() {
      return this.rfq.lawFirmQuotes.filter(quote => quote.fromMarketplace);
    },

    respondedScoutQuotesCount() {
      return this.scoutQuotes.filter(quote => this.hasQuoteBeenResponded(quote)).length;
    },

    respondedMarketplaceQuotesCount() {
      return this.marketplaceQuotes.filter(quote => this.hasQuoteBeenResponded(quote)).length;
    },

    yourFirmsCount() {
      return `${this.respondedScoutQuotesCount}/${this.scoutQuotes.length}`;
    },

    hasScoutQuotes() {
      return this.scoutQuotes.length > 0;
    },

    sentToMarketplace() {
      return this.rfq.sentToMarketplace;
    }
  },

  methods: {
    hasQuoteBeenResponded(quote) {
      return ['quote_submitted', 'declined_by_firm', 'selected_by_client', 'declined_by_client'].includes(quote.status);
    }
  }
};
</script>
