<template>
  <div>
    <question-group
      class="list-spacing-30"
      v-for="group in orderedQuestionGroups"
      :key="group.id"
      :question-group="group"
      :answers="quote.answers"
      :suggested-lawyers="suggestedLawyers"
      :viewing-as-client="viewingAsClient"
      :quote-last-submitted-at="quote.lastSubmittedAt"
      :show-question-update-tags="showQuestionUpdateTags">
    </question-group>

    <div class="missing-text size-text-14px" v-if="!hasQuestions">
      No questions were {{ viewingAsClient ? 'added to the RFP' : 'sent by the client' }}
    </div>
  </div>
</template>

<script>
import QuestionGroup from './question-group.vue';
import { orderBy } from 'lodash';

export default {
  name: 'QuoteDetail',

  components: {
    QuestionGroup
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    quote: {
      type: Object,
      required: true
    },

    suggestedLawyers: {
      type: Array,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      default: true
    },

    hasNewQuestionActivities: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    orderedQuestionGroups() {
      return orderBy(this.rfq.questionGroups, 'position');
    },

    hasQuestions() {
      return this.rfq.questionGroups.length > 0;
    },

    showQuestionUpdateTags() {
      return !this.viewingAsClient && this.hasNewQuestionActivities;
    }
  }
};
</script>
