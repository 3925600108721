<template>
  <div>
    <div :class="['bold-weight tight-lines', { 'disabled-text' : !question.isIncluded }]">
      {{ question.questionText }}
      <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
    </div>

    <div :class="{ 'disabled-text' : !question.isIncluded }" v-if="hasAnswer">
      {{ formattedAnswer }}
    </div>

    <div v-else :class="['missing-text', { 'disabled-text' : !question.isIncluded }]">
      No answer provided
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';

export default {
  name: 'QuestionAnswerOption',

  components: {
    ScoutTag
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    answer: {
      type: Object,
      required: false
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasAnswer() {
      return this.answer && this.answer.value;
    },

    formattedAnswer() {
      return startCase(this.answer.value);
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  }
};
</script>
