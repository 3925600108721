<template>
  <div>
    <div class="titlebar-sm">
      <div class="medium-page-heading top-2">
        Open Sales Inquiries
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="shadowed-box">
            <div class="box-header compact text-uppercase">
              Open Sales Inquiries
            </div>

            <div class="box-content dash-widget">
              <loading-section name="salesInquiries">
                <open-inquiries :open-inquiries="openInquiries"></open-inquiries>
              </loading-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import OpenInquiries from './open-inquiries.vue';

export default {
  name: 'AdminInquiries',

  components: {
    LoadingSection,
    OpenInquiries
  },

  props: {
    openInquiries: {
      type: Array,
      required: true
    }
  }
};
</script>
