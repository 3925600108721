<template>
  <div>
    <div :class="['content', 'faded-section', { 'fade-down': fadeOverflow && isContentOverflowing && !isContentExpanded }]" ref="content">
      <slot></slot>
    </div>

    <div class="top-10" v-if="isContentOverflowing">
      <a href="" class="semibold-weight" @click.prevent="toggleExpanded">{{ isContentExpanded ? 'Show less' : 'Show all' }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsableSpace',

  props: {
    // The max content height in px after which the expand/collapse options should be shown
    maxHeight: {
      type: Number,
      default: 160
    },

    fadeOverflow: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isContentExpanded: false,
      contentHeight: 0
    };
  },

  computed: {
    maxContentHeight() {
      return this.isContentExpanded ? 'none' : this.maxHeight + 'px';
    },

    isContentOverflowing() {
      return this.contentHeight > this.maxHeight;
    }
  },

  mounted() {
    this.updateContentHeight();
    window.addEventListener('resize', this.updateContentHeight);
  },

  updated() {
    this.updateContentHeight();
  },

  destroyed() {
    window.removeEventListener('resize', this.updateContentHeight);
  },

  methods: {
    toggleExpanded() {
      this.isContentExpanded = !this.isContentExpanded;
    },

    updateContentHeight() {
      this.contentHeight = this.$refs.content.scrollHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "stylesheets/globals/variables";

.content {
  max-height: v-bind(maxContentHeight);
  line-height: 1.4;
  overflow: hidden;
  position: relative;
}

button {
  position: absolute;
  bottom: 0;
}
</style>
