<template>
  <div>
    <div class="lawyer-preferences-widget">
      <div class="preferences-message text-center">
        <div class="size-text-24px bottom-20">
          {{ completed ? 'Has anything changed?' : 'Want better client matches?' }}
        </div>

        <div class="size-text-13px">
          {{ completed ? 'Keep your matching information and experience updated to get the best matches.' : 'Tell us more about your matching information and experience.' }}
        </div>
      </div>

      <div class="preferences-carousel-container" v-touch:swipe.left="next" v-touch:swipe.right="previous">
        <div class="preference-selector-container text-center">
          <div class="preference-header size-text-18px semibold-weight bottom-30">
            <svg-icon name="checkmark-white" class="completed base-icon right-10" v-if="preferences[step].isComplete"></svg-icon>{{ preferences[step].label }}
          </div>

          <div class="preference-selector">
            <a href="" class="arrow-container">
              <div class="arrow" @click.prevent="previous">
                <svg-icon name="arrow-left" class="base-icon top-10"></svg-icon>
              </div>
            </a>

            <div class="button-container">
              <button class="nv-button smaller" @click="launchModal">
                {{ preferences[step].isComplete ? 'Edit Section' : 'Get Started' }}
              </button>
            </div>

            <a href="" class="arrow-container">
              <div class="arrow" @click.prevent="next">
                <svg-icon name="arrow-right" class="base-icon top-10"></svg-icon>
              </div>
            </a>
          </div>

          <div class="swipe-message top-25 text-italic size-text-13px">
            <svg-icon name="arrow-left" class="base-icon smaller right-10"></svg-icon><span class="top-5">Swipe</span><svg-icon name="arrow-right" class="base-icon smaller left-10"></svg-icon>
          </div>

          <div class="preference-pager top-10">
            <span v-for="(label, slug, index) in preferences" :key="slug">
              <a href="" :class="{ 'right-5': !isLastPreference(index) }" @click.prevent="selectStep(slug)"><svg-icon name="circle" :class="['base-icon circle-icon', { 'selected': step === slug }]"></svg-icon></a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LawyerPreferencesWidget',

  props: {
    preferencesService: {
      type: Object,
      required: true
    }
  },

  computed: {
    preferences: function () {
      return this.preferencesService.preferences;
    },

    step: function () {
      return this.preferencesService.step;
    },

    completed: function () {
      return this.preferencesService.completed();
    }
  },

  methods: {
    previous: function () {
      this.preferencesService.seekStep(-1);
    },

    next: function () {
      this.preferencesService.seekStep(1);
    },

    selectStep: function (slug) {
      this.preferencesService.selectStep(slug);
    },

    launchModal: function () {
      this.preferencesService.launchModal(this.step);
    },

    isLastPreference: function (preferenceIndex) {
      return (Object.keys(this.preferences).length - 1) === preferenceIndex;
    }
  }
};
</script>
