<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div class="bottom-15">
        <div class="semibold-weight">
          Admin Requester
        </div>

        <div>
          <span v-if="adminCreatorLastName">{{ counselRequest.adminCreator.firstName }} {{ counselRequest.adminCreator.lastName }}</span>
          <span v-else>&mdash;</span>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Salesforce Opportunity ID
        </div>

        <div>
          <a :href="`https://priori.my.salesforce.com/${counselRequest.opportunityId}`" target="_blank" v-if="counselRequest.opportunityId">{{ counselRequest.opportunityId }}</a>
          <span v-else>&mdash;</span>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Detected State
        </div>

        <div>
          {{ counselRequest.detectedState || '&mdash;' }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Detected City
        </div>

        <div>
          {{ counselRequest.detectedCity || '&mdash;' }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Detected Zip
        </div>

        <div>
          {{ counselRequest.detectedZip || '&mdash;' }}
          <span v-if="counselRequest.detectedZip">(<a :href="`https://www.google.com/maps/place/${counselRequest.detectedZip}`" target="_blank">see on map</a>)</span>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Detected Country
        </div>

        <div>
          {{ counselRequest.detectedCountry || '&mdash;' }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Source Lawyer
        </div>

        <div>
          <a :href="`/admin-app/lawyers/${sourceLawyerId}`" target="_blank" v-if="sourceLawyerId">{{ sourceLawyer.fullName }}</a>
          <span v-else>&mdash;</span>
        </div>
      </div>

      <div>
        <div class="semibold-weight">
          Product Interested In
        </div>

        <div>
          {{ counselRequest.productInterestedIn || '&mdash;' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestAdmin',

  props: {
    counselRequest: {
      type:     Object,
      required: true
    }
  },

  computed: {
    sourceLawyer() {
      return this.counselRequest.sourceLawyer;
    },

    sourceLawyerId() {
      return this.sourceLawyer?.id;
    },

    adminCreatorLastName() {
      return this.counselRequest?.adminCreator?.lastName;
    }
  }
};
</script>
