<template>
  <div>
    <slot v-if="dataIsPresent(data)"></slot>

    <div v-else class="dark-gray-text text-italic">
      <div>Click edit to add data</div>
    </div>
  </div>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';

export default {
  name: 'DisplayRulesDataWrapper',

  mixins: [displayRules],

  props: {
    // passing `null` or `undefined` data is valid
    // `null` and `undefined` values will pass any type validation
    data: {
      type: [Array, String, Number, Object]
    }
  }
};
</script>
