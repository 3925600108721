import { defineStore } from 'pinia';
import RFQTemplate from 'src/resources/scout/rfq-template';
import { orderBy } from 'lodash';

export default defineStore('rfqTemplateStore', {
  state: () => {
    const initialState = {
      name: '',
      questionGroupTemplatesAttributes: [],
      creator: {}
    };

    return {
      rfqTemplate: structuredClone(initialState),
      rfqTemplates: [],
      rfqSelectedTemplate: {},
      standardTemplate: {},
      initialState
    };
  },

  actions: {
    setRfqTemplate(template) {
      this.rfqTemplate = structuredClone(template);
    },

    setRfqGroupTemplates(rfqGroupTemplates) {
      this.rfqTemplate.questionGroupTemplatesAttributes = rfqGroupTemplates;
    },

    resetRfqTemplate() {
      this.rfqTemplate = structuredClone(this.initialState);
    },

    setTemplates(templates) {
      this.resetRfqTemplate();
      const templatesCopy = structuredClone(templates);
      this.rfqTemplates = templatesCopy;
      this.standardTemplate = this.findStandardTemplate(templates);
      this.rfqSelectedTemplate = this.findStandardTemplate(templates);
    },

    findStandardTemplate(templates) {
      return templates.find(t => t.id === null);
    },

    setStandardTemplate(template) {
      this.standardTemplate = structuredClone(template);
    },

    updateTemplate(template) {
      this.pruneEmptySections();
      template.name = template.name.trim();
      return RFQTemplate.update({ id: template.id, rfqTemplate: template });
    },

    setSelectedTemplateByName(templateName) {
      this.rfqSelectedTemplate = structuredClone(this.rfqTemplates.find((t) => t.name === templateName));
    },

    setSelectedTemplateById(templateId) {
      this.rfqSelectedTemplate = structuredClone(this.rfqTemplates.find((t) => t.id === templateId));
    },

    saveTemplate() {
      this.pruneEmptySections();
      this.rfqTemplate.name = this.rfqTemplate.name.trim();
      return RFQTemplate.save({ rfqTemplate: this.rfqTemplate });
    },

    pruneEmptySections() {
      this.rfqTemplate.questionGroupTemplatesAttributes = orderBy(this.rfqTemplate.questionGroupTemplatesAttributes, 'position');

      for (let i = this.rfqTemplate.questionGroupTemplatesAttributes.length - 1; i >= 0; i--) {
        const section = this.rfqTemplate.questionGroupTemplatesAttributes[i];

        // Update `isShown` for standard questions
        if (section.position < this.standardQuestionsPosition && (section.questionTemplatesAttributes.length === 0 || this.allQuestionsStagedForRemoval(section))) {
          section.isShown = false;
        }
        else if (this.isCustomSectionWithNoQuestions(section)) {
          if (section.id) { // Custom section is persisted, mark for removal from server
            section._destroy = true;
          }
          else { // Custom section not persisted, remove from JSON object
            this.rfqTemplate.questionGroupTemplatesAttributes.splice(i, 1);
          }

          // Decrement position by 1 for every object after empty question section
          for (let j = i; j < this.rfqTemplate.questionGroupTemplatesAttributes.length; j++) {
            this.rfqTemplate.questionGroupTemplatesAttributes[j].position -= 1;
          }
        }
      }
    },

    isCustomSectionWithNoQuestions(section) {
      return section.position >= this.standardQuestionsPosition && section.questionTemplatesAttributes.filter(q => !q._destroy).length === 0;
    },

    allQuestionsStagedForRemoval(group) {
      return group.questionTemplatesAttributes.every(question => question._destroy);
    },

    addGroup(group) {
      this.rfqTemplate.questionGroupTemplatesAttributes.push(group);
    },

    duplicateTemplate(template) {
      const id = (template === undefined || template.id === null) ? 'standard' : template.id;
      return RFQTemplate.duplicate(id);
    },

    updateGroup(group) {
      const index = this.rfqTemplate.questionGroupTemplatesAttributes.findIndex(
        (rfqGroup) => rfqGroup.position === group.position
      );

      if (index !== -1) {
        this.rfqTemplate.questionGroupTemplatesAttributes.splice(index, 1, group);
      }
    },

    removeGroup(group) {
      this.rfqTemplate.questionGroupTemplatesAttributes.splice(group.position - 1, 1);
    },

    addQuestion(question, group) {
      const groupQuestions = this.rfqTemplate.questionGroupTemplatesAttributes.find(g => g.position === group.position).questionTemplatesAttributes;
      const activeQuestions = groupQuestions.filter(q => !q._destroy);

      question.position = activeQuestions.length + 1;

      this.rfqTemplate.questionGroupTemplatesAttributes.find(g => g.position === group.position).questionTemplatesAttributes.push(question);
    },

    updateQuestion(question, group) {
      const groupIndex = this.rfqTemplate.questionGroupTemplatesAttributes.findIndex(
        (item) => item.position === group.position
      );

      if (groupIndex !== -1) {
        const updatedGroupAttributes = [...this.rfqTemplate.questionGroupTemplatesAttributes];
        const questionIndex = updatedGroupAttributes[groupIndex].questionTemplatesAttributes.findIndex(
          (q) => q.position === question.position
        );

        if (questionIndex !== -1) {
          const updatedQuestions = [...updatedGroupAttributes[groupIndex].questionTemplatesAttributes];
          updatedQuestions[questionIndex] = { ...updatedQuestions[questionIndex], ...question };
          updatedGroupAttributes[groupIndex].questionTemplatesAttributes = updatedQuestions;
          this.rfqTemplate.questionGroupTemplatesAttributes = updatedGroupAttributes;
        }
      }
    },

    removeQuestion(question, group) {
      const groupIndex = this.rfqTemplate.questionGroupTemplatesAttributes.findIndex(
        (item) => item.position === group.position
      );
      if (groupIndex !== -1) {
        const updatedGroupAttributes = [...this.rfqTemplate.questionGroupTemplatesAttributes];
        const groupQuestions = updatedGroupAttributes[groupIndex].questionTemplatesAttributes;

        const questionIndex = groupQuestions.findIndex(q => q.position === question.position);

        if (questionIndex !== -1) {
          if (groupQuestions[questionIndex].id) {
            groupQuestions[questionIndex]._destroy = true;
            groupQuestions[questionIndex].position = null;
          }
          else {
            groupQuestions.splice(questionIndex, 1);
          }

          const filteredQuestions = groupQuestions.filter(q => !q._destroy);
          const reorderedQuestions = filteredQuestions.map((q, index) => ({
            ...q,
            position: index + 1
          }));

          updatedGroupAttributes[groupIndex].questionTemplatesAttributes = [
            ...reorderedQuestions,
            ...groupQuestions.filter(q => q._destroy)
          ];
          this.rfqTemplate.questionGroupTemplatesAttributes = updatedGroupAttributes;
        }
      }
    },

    reorderQuestions(movedPosition, targetPosition, group) {
      const groupIndex = this.rfqTemplate.questionGroupTemplatesAttributes.findIndex(
        (item) => item.position === group.position
      );

      if (groupIndex !== -1) {
        const updatedGroupAttributes = [...this.rfqTemplate.questionGroupTemplatesAttributes];
        let groupQuestions = updatedGroupAttributes[groupIndex].questionTemplatesAttributes;

        const movedIndex = groupQuestions.findIndex((q) => q.position === movedPosition);

        if (movedIndex !== -1) {
          const [movedQuestion] = groupQuestions.splice(movedIndex, 1);

          if (targetPosition === groupQuestions.length + 1) {
            groupQuestions.push(movedQuestion);
          }
          else {
            const targetIndex = groupQuestions.findIndex((q) => q.position === targetPosition);

            if (targetIndex !== -1) {
              groupQuestions.splice(targetIndex, 0, movedQuestion);
            }
          }

          const reorderedQuestions = groupQuestions.map((q, index) => ({
            ...q,
            position: index + 1
          }));

          updatedGroupAttributes[groupIndex].questionTemplatesAttributes = reorderedQuestions;
          this.rfqTemplate.questionGroupTemplatesAttributes = updatedGroupAttributes;
        }
      }
    },

    hiddenQuestionsForGroup(group) {
      if (!this.standardTemplate || !Array.isArray(this.standardTemplate.questionGroupTemplatesAttributes)) {
        return [];
      }

      if (group.position - 1 < 0 || group.position - 1 >= this.standardTemplate.questionGroupTemplatesAttributes.length) {
        return [];
      }

      return this.standardTemplate.questionGroupTemplatesAttributes
        .find(g => g.position === group.position)
        .questionTemplatesAttributes
          .filter(templateQuestion => {
            return !group.questionTemplatesAttributes.filter(question => !question._destroy).some(groupQuestion => templateQuestion.shortname === groupQuestion.shortname);
          });
    }
  },

  getters: {
    standardQuestionsPosition() {
      return 5;
    },

    nextSectionPosition() {
      return this.rfqTemplate.questionGroupTemplatesAttributes.length + 1;
    },

    getTemplateByID(id) {
      return structuredClone(this.rfqTemplates.find((t) => t.id === id));
    },

    canAddStandardSection() {
      return this.rfqTemplate.questionGroupTemplatesAttributes.some(group => group.isShown === false);
    },

    hiddenGroups() {
      const filteredAttributes = this.rfqTemplate.questionGroupTemplatesAttributes.filter(group => group.isShown === false);

      const matchingTemplates = filteredAttributes.map(group => {
        const matchedTemplate = this.standardTemplate.questionGroupTemplatesAttributes.find(template => template.position === group.position);
        if (matchedTemplate) {
          matchedTemplate.isShown = false;
        }
        return matchedTemplate;
      });

      return matchingTemplates;
    }
  }
});
