<template>
  <div class="card-wrapper">
    <div class="icon">
      <slot name="icon"></slot>
    </div>

    <div class="message">
      <slot name="message"></slot>
    </div>

    <div>
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card'
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .card-wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 20px;
    background: $newblue-10;
    border: 1px solid $newblue-9;
    border-radius: $border-radius-base;

    @media (min-width: $screen-md-min) {
      padding: 20px 30px 30px;
    }
  }

  .message {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-top: 4px;
  }

  .icon {
    display: flex;
    background: $white;
    border-radius: 100px;
  }
</style>
