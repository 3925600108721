<template>
  <div v-if="success">
    <h1 class="size-text-24px text-center bottom-30">
      <span id="sign-up-header" class="normal-weight">Success!</span>
    </h1>

    <p>
      You will receive a confirmation email shortly. Click the link in your email to complete your registration.
    </p>
  </div>

  <div v-else>
    <h1 class="size-text-24px text-center bottom-10">
      <span id="sign-up-header" class="normal-weight">Create a Client Marketplace Account</span>
    </h1>

    <div class="size-text-13px pill-gray-text text-center bottom-20">
      If you would like to sign up for Scout or Feature Lab, please reach out to Priori to schedule a demo.
    </div>

    <div class="size-text-13px text-center bottom-40">
      <div class="bg-gray inline-block inner-20">
        <div class="bottom-5">
          <span>Already have a client account?</span> <a href="/sign-in" target="_self" class="semibold-weight">Sign in</a>
        </div>

        <div>
          <span>Want to sign up to be a lawyer?</span> <a href="/lawyer-signup" target="_self" class="semibold-weight">Sign up here</a>
        </div>
      </div>
    </div>

    <validation-observer v-slot="{ handleSubmit }">
      <form id="client_sign_up" role="form" class="a-form" method="POST" novalidate>
        <input type="checkbox" id="good-check" name="goodCheck" class="checking-input" v-model="goodCheck">

        <div class="row tight-columns">
          <div class="col-sm-6 bottom-15">
            <text-input-vertical label="First Name" id="first-name" input-name="firstName" placeholder="First Name" rules="required" v-model="client.firstName"></text-input-vertical>
          </div>

          <div class="col-sm-6 bottom-15">
            <text-input-vertical label="Last Name" id="last-name" input-name="lastName" placeholder="Last Name" rules="required" v-model="client.lastName"></text-input-vertical>
          </div>
        </div>

        <div class="bottom-15">
          <text-input-vertical input-type="email" id="email" label="Email" input-name="email" placeholder="Email" rules="required|email" v-model="client.email"></text-input-vertical>
        </div>

        <div class="row tight-columns">
          <div class="col-sm-6 bottom-15">
            <text-input-vertical label="Job Title" id="job-title" input-name="role" placeholder="Job Title" v-model="client.role"></text-input-vertical>
          </div>

          <div class="col-sm-6 bottom-15">
            <text-input-vertical v-if="workspace" label="Company" id="company" input-name="company" :value="workspace.name" disabled></text-input-vertical>
            <text-input-vertical v-else label="Company" id="company" input-name="company" placeholder="Company" v-model="client.businessName"></text-input-vertical>
          </div>
        </div>

        <div class="bottom-15">
          <text-input-vertical input-type="tel" label="Phone" id="phone" input-name="phone" placeholder="Phone" rules="required" v-model="client.phoneNumber"></text-input-vertical>
        </div>

        <div class="row tight-columns bottom-20">
          <div class="col-sm-6">
            <label for="password">Password <span class="red-text">*</span></label>

            <validation-provider vid="password" v-slot="{ errors, failedRules, changed }" :bails="false" rules="required|oneNumber|min:15|oneUppercaseChar|oneLowercaseChar|oneSpecialChar">
              <input type="password" label="Password" name="password" id="password" :class="['form-control', { 'has-error': errors.length && submitClicked }]" placeholder="Password" autocomplete="off" v-model="client.password">

              <div class="error-text top-5" v-if="errors.length && submitClicked">
                <span v-if="client.password && client.password.length">* Password doesn&rsquo;t meet requirements</span>

                <span v-else>{{ errors[0] }}</span>
              </div>

              <div class="top-15 size-text-12px dark-gray-text semibold-weight">
                <div class="bottom-5">
                  Password must have:
                </div>

                <ul>
                  <li v-for="(constraint, rule) in passwordRules" :key="rule" class="bottom-5">
                    <svg-icon v-if="isValid(rule, failedRules) && changed" name="checkmark" class="base-icon success"></svg-icon>

                    <svg-icon v-else name="exclamation" class="base-icon"></svg-icon>

                    {{ constraint }}
                  </li>
                </ul>
              </div>
            </validation-provider>
          </div>

          <div class="col-sm-6 top-20-xs">
            <label for="password-confirmation">Confirm Password <span class="red-text">*</span></label>

            <validation-provider vid="passwordConfirmation" v-slot="{ errors }" rules="required|confirmed:password">
              <input type="password" label="Password Confirmation" name="passwordConfirmation" id="password-confirmation" :class="['form-control', { 'has-error': errors.length && submitClicked }]" placeholder="Password Confirmation" autocomplete="off" v-model="client.passwordConfirmation">

              <div class="error-text top-5" v-if="errors.length && submitClicked">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>

        <div class="bottom-30">
          <div class="checkbox sign-up-message">
            <input-checkbox id="client_agree_to_terms" name="agreeToTerms" rules="required" v-model="client.agreeToTerms" size="medium-large">
              <span class="dark-gray-text">
                I have read and agree to the <a href="https://www.priorilegal.com/terms-of-use" target="_blank">Terms & Conditions</a> and <a href="https://www.priorilegal.com/privacy-policy-dmca-copyright-policy" target="_blank">Privacy Policy</a>
              </span>

              <div class="error-text" v-if="!client.agreeToTerms && submitClicked">
                * Required
              </div>
            </input-checkbox>
          </div>
        </div>

        <div class="bottom-30">
          <recaptcha-v2 ref="recaptcha" v-model="recaptchaToken"></recaptcha-v2>

          <div class="error-text top-5" v-if="recaptchaBackendError && !recaptchaToken">
            {{ recaptchaBackendError }}
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5 col-md-4">
            <loading-button name="submitSignUp" lb-class="nv-button-blue a-button-size" @lb-click="setSubmitClicked() && handleSubmit(submit)">Sign Up</loading-button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RecaptchaV2 from 'vue-app/shared/components/recaptcha-v2';

export default {
  name: 'ClientSignUpForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    TextInputVertical,
    RecaptchaV2
  },

  props: {
    onSubmit: {
      type: Function,
      required: true
    },

    workspace: {
      type: Object,
      required: false
    }
  },

  data: function () {
    return {
      client: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        businessName: '',
        phoneNumber: '',
        password: '',
        passwordConfirmation: '',
        agreeToTerms: false
      },

      passwordRules: {
        min: 'Minimum of 15 characters',
        oneNumber: 'At least one number',
        oneUppercaseChar: 'At least one uppercase character',
        oneLowercaseChar: 'At least one lowercase character',
        oneSpecialChar: 'At least one special character'
      },

      goodCheck: false,
      recaptchaToken: '',
      recaptchaBackendError: '',
      submitClicked: false,
      success: false
    };
  },

  methods: {
    isValid: function (rule, failedRules) {
      return !Object.keys(failedRules).includes(rule);
    },

    submit: function () {
      LoadingService.loading('submitSignUp');

      if (this.client.agreeToTerms) {
        const params = {
          good_check: this.goodCheck,
          client: this.client,
          recaptcha: this.recaptchaToken
        };

        this.onSubmit(params, this.onSuccess, this.onError);
      }
      else {
        LoadingService.done('submitSignUp');
      }
    },

    setSubmitClicked: function () {
      this.submitClicked = true;
      return true;
    },

    onSuccess: function () {
      this.success = true;
      window.scrollTo(0, 0);
      LoadingService.done('submitSignUp');
    },

    onError: function (response) {
      const { errors } = response.data;
      this.recaptchaBackendError = errors.recaptcha?.[0];
      this.$refs.recaptcha.resetRecaptcha();
      LoadingService.done('submitSignUp');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  h1 {
    margin-top: 0;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      display: flex;
      column-gap: 7px;

      .base-icon {
        fill: $k-dark-gray;
        width: 16px;

        &.success {
          fill: $k-green;
        }
      }
    }
  }
</style>
