<template>
  <div>
    <slot name="items" :rendering-items="renderingItems"></slot>

    <button type="button" class="top-10 semibold-weight size-text-13px pseudo-link-blue" @click="toggleShowAll" v-if="moreThanTenItems">
      {{ showAllOrLess }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CollapsableItems',

  props: {
    items: {
      type: Array,
      required: true
    },

    itemsLimit: {
      type: Number,
      default: 10
    },

    showAllButtonText: {
      type: String,
      default: 'Show all'
    }
  },

  data() {
    return {
      showAll: false
    };
  },

  computed: {
    renderingItems() {
      return this.showAll ? this.items : this.items.slice(0, this.itemsLimit);
    },

    moreThanTenItems() {
      return this.items.length > this.itemsLimit;
    },

    showAllOrLess() {
      return this.showAll ? 'Show less' : this.showAllButtonText;
    }
  },

  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  border: 0;
  background: transparent;
  display: block;
}
</style>
