<template>
  <div class="shadowed-box" v-if="hasReviewReminders">
    <div class="box-header text-uppercase">
      Reminders
    </div>

    <div class="review-reminders-container">
      <div class="d-flex">
        <review-reminder
          v-for="reviewReminder in displayingReminders"
          class="review-reminder-thirds"
          :key="reviewReminder.id"
          :review-reminder="reviewReminder"
          :load-matter-firms="loadMatterFirms"
          :load-matter-lawyers="loadMatterLawyers"
          :review-reminder-configuration="reviewReminderConfiguration"
          :create-irrelevant-review-reminder-matter="handleCreateIrrelevantReviewReminderMatter">
        </review-reminder>
      </div>

      <div class="vertical-center flex-end-justified">
        <div :class="['caret-container vertical-center', { 'lighter': isAtFirstPage }]" @click="goToPrevPage">
          <svg-icon name="caret-left" class="arrow"></svg-icon>
        </div>

        <div class="pagination-text left-5 right-5">
          {{ firstReminderPlace }} - {{ lastReminderPlace }} of {{ totalReminders }}
        </div>

        <div :class="['caret-container vertical-center', { 'lighter': isAtLastPage }]" @click="goToNextPage">
          <svg-icon name="caret-right" class="arrow"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewReminder from 'vue-app/scout/client/reviews/review-reminder.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ReviewReminders',

  components: {
    ReviewReminder,
    SvgIcon
  },

  props: {
    reviewReminders: {
      type: Array,
      required: true
    },

    loadMatterFirms: {
      type: Function,
      required: true
    },

    loadMatterLawyers: {
      type: Function,
      required: true
    },

    remindersPerPage: {
      type: Number,
      default: 3
    },

    reviewReminderConfiguration: {
      type: Object,
      required: true
    },

    createIrrelevantReviewReminderMatter: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      page: 1
    };
  },

  computed: {
    hasReviewReminders() {
      return this.reviewReminders.length > 0;
    },

    totalReminders() {
      return this.reviewReminders.length;
    },

    isAtFirstPage() {
      return this.page === 1;
    },

    isAtLastPage() {
      return this.page === Math.ceil(this.totalReminders / this.remindersPerPage);
    },

    firstReminderPlace() {
      return ((this.page - 1) * this.remindersPerPage) + 1;
    },

    lastReminderPlace() {
      const lastPlace = this.page * this.remindersPerPage;
      return lastPlace > this.totalReminders ? this.totalReminders : lastPlace;
    },

    displayingReminders() {
      return this.reviewReminders.slice(
        (this.page - 1) * this.remindersPerPage, this.page * this.remindersPerPage
      );
    }
  },

  methods: {
    goToPrevPage() {
      if (this.isAtFirstPage) { return; }

      this.page -= 1;
    },

    goToNextPage() {
      if (this.isAtLastPage) { return; }

      this.page += 1;
    },

    handleCreateIrrelevantReviewReminderMatter(params) {
      return this.createIrrelevantReviewReminderMatter(params).then(() => {
        const newLastPage = Math.ceil(this.reviewReminders.length / this.remindersPerPage);
        if (this.page <= newLastPage) { return; }

        this.page = newLastPage;
      });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .review-reminders-container {
    padding: 20px;
  }

  .review-reminder-thirds {
    width: 100%;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      width: 32%;

      &:not(:last-child) {
        margin-right: 2%;
      }
    }
  }

  .caret-container {
    width: 20px;

    &:hover {
      cursor: pointer;
    }

    .arrow {
      height: 20px;
      width: 20px;
      fill: $k-darker-gray;
    }

    &.lighter {
      .arrow {
        opacity: .5;
      }

      &:hover {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  .pagination-text {
    user-select: none;
  }
</style>
