<template>
  <div>
    <div class="titlebar multi-line">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-10">
          <div class="medium-page-heading">
            <div class="d-flex gap-10 items-center">
              <div>{{ rfqTemplate.name }}</div>

              <color-tag v-if="isStandardTemplate" color-pattern="blue padding-sm">
                Default
              </color-tag>
            </div>

            <div v-if="!isStandardTemplate" class="size-text-13px top-5">
              Created by <span class="bold-weight">{{ creatorName }}</span> on {{ formatDate }}
            </div>
          </div>
        </div>

        <div class="col-sm-2 top-20-xs d-flex flex-end">
          <dropdown-menu :compact="true">
            <li class="menu-item" role="menuitem">
              <a href="" @click.prevent="confirmDuplicateTemplate(rfqTemplate)">Duplicate Template</a>
            </li>

            <li v-if="!isStandardTemplate" class="menu-item" role="menuitem">
              <priori-modal ref="editName" modal-id="edit-name-modal" title="Edit Template Name">
                <template #modal-trigger="{ openModal }">
                  <a href="" @click.prevent="openModal">Edit Template Name</a>
                </template>

                <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
                  <loading-section name="templates-show">
                    <form role="form" class="a-form" novalidate @submit.prevent>
                      <div class="bottom-30">
                        <validation-provider rules="required" v-slot="{ errors }">
                          <label for="template-name">
                            Template Name <span class="inline-help required">*</span>
                          </label>

                          <input type="text" name="templateName" id="template-name" :class="['form-control', { 'has-error': errors.length }]" v-model="templateForm.nameText" autocomplete="off">

                          <div class="error-text top-5" v-if="errors.length || hasResponseErrors">
                            <span v-if="hasResponseErrors">{{ responseErrorsDisplay }}</span>
                            <span v-else>{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </div>

                      <div class="row tight-columns">
                        <div class="col-sm-4">
                          <button type="button" class="primary-btn-blue" @click="handleSubmit(updateName)">Save</button>
                        </div>

                        <div class="col-sm-3 top-10-xs">
                          <button type="button" class="nv-button-white" @click="dismissModal">Cancel</button>
                        </div>
                      </div>
                    </form>
                  </loading-section>
                </validation-observer>
              </priori-modal>
            </li>

            <li v-if="!isStandardTemplate" class="menu-item" role="menuitem">
              <delete-template-modal
                :template="rfqTemplate"
                :on-delete-template="onDeleteTemplate">
              </delete-template-modal>
            </li>
          </dropdown-menu>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-10 col-lg-8">
              <div class="bottom-30">
                <a href="/scout-company/settings/question-templates" class="back-link"><svg-icon name="arrow-left" class=" base-icon right-10"></svg-icon>Back to All Templates</a>
              </div>

              <status-banner class="bottom-30" v-if="isStandardTemplate">
                <div>
                  The Standard Questions template contains the standard questions within your organization.
                  <br>
                  These cannot be edited. Duplicate this template to edit the questions.
                </div>
              </status-banner>

              <rfq-template-questions
                :template="rfqTemplate"
                :is-new="false">
              </rfq-template-questions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqTemplateQuestions from 'vue-app/scout/client/settings/rfqs/templates/questions.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import { scrollToTop } from 'misc/ui-helpers.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import StatusBanner from 'vue-app/scout/shared/rfqs/status-banner.vue';
import DeleteTemplateModal from 'vue-app/scout/client/settings/rfqs/templates/delete-template-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ScoutClientSettingsRfpTemplatesShow',

  components: {
    DeleteTemplateModal,
    RfqTemplateQuestions,
    DropdownMenu,
    PrioriModal,
    ValidationProvider,
    ValidationObserver,
    LoadingSection,
    StatusBanner,
    SvgIcon
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      responseErrors: [],
      templateForm: {
        nameText: null
      }
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    ...mapState(useRfqTemplateStore, { rfqTemplate: 'rfqTemplate' }),

    isStandardTemplate() {
      return this.rfqTemplate.id === null && this.rfqTemplate.name === 'Standard Questions';
    },

    creatorName() {
      if (this.createdByCurrentUser) {
        return 'You';
      }

      return this.rfqTemplate.creator.name;
    },

    createdByCurrentUser() {
      return this.currentClient.id === this.rfqTemplate.creator.id;
    },

    formatDate() {
      return DateFilter.longDate(this.rfqTemplate.createdAt);
    },

    hasResponseErrors() {
      return this.responseErrors.length > 0;
    },

    responseErrorsDisplay() {
      return this.responseErrors.join('; ');
    }
  },

  mounted() {
    this.setRfqTemplate(this.initData.template);
    this.setStandardTemplate(this.initData.standardTemplate);
    this.templateForm.nameText = this.initData.template.name;
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      setRfqTemplate: 'setRfqTemplate',
      updateTemplate: 'updateTemplate',
      setStandardTemplate: 'setStandardTemplate',
      deleteTemplate: 'deleteTemplate',
      duplicateTemplate: 'duplicateTemplate'
    }),

    confirmDuplicateTemplate(template) {
      this.duplicateTemplate(template).then((response) => {
        window.location.href = `/scout-company/settings/question-templates/${response.data.id}`;
      });
    },

    updateName() {
      const updatedTemplate = {
        ...this.rfqTemplate,
        name: this.templateForm.nameText.trim()
      };

      LoadingService.loading('templates-show');
      this.responseErrors = [];
      this.updateTemplate(updatedTemplate)
        .then((response) => {
          this.setRfqTemplate(response);
          NotificationService.success('This template has been successfully saved.');
          this.dismissModal();
        })
        .catch((response) => {
          this.responseErrors = (response?.response?.data?.errors || []);
          NotificationService.error('Failed to update template. Please try again.');
        })
        .finally(() => {
          scrollToTop();
          LoadingService.done('templates-show');
        });
    },

    dismissModal() {
      this.$refs.editName.closeModal();
      this.clearForm();
    },

    onDeleteTemplate() {
      window.location.href = '/scout-company/settings/question-templates';
    },

    clearForm() {
      this.templateForm.nameText = this.rfqTemplate.name;
      this.$refs.validationObserver.reset();
    }
  }
};
</script>
