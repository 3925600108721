<template>
  <div class="tight-lines">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-5">
        <a :href="`/scout-firm/clients/${workspace.id}/lists/${resourceList.id}`" class="semibold-weight size-text-14px">{{ resourceList.name }}</a>

        <div class="size-text-12px top-5">
          Created by {{ resourceList.createdBy.fullName }}
        </div>
      </div>

      <div class="col-sm-3 top-20-xs">
        <div class="semibold-weight size-text-12px">
          Last Edited:
        </div>

        <div class="text-italic top-5">
          {{ formattedDate(resourceList.updatedAt) }}
        </div>
      </div>

      <div class="col-sm-4 top-20-xs">
        Shared with <span class="semibold-weight">{{ countSingularOrPlural(resourceList.sharesCount, 'person') }}</span> at {{ workspace.name }}
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'FirmClientList',

  props: {
    workspace: {
      type:     Object,
      required: true
    },

    resourceList: {
      type:     Object,
      required: true
    }
  },

  methods: {
    formattedDate(date) {
      return DateFilter.filter(date, { year: 'numeric', month: 'short', day: '2-digit' });
    },

    countSingularOrPlural(count, word) {
      return `${count} ${this.$pluralize(word, count)}`;
    }
  }
};
</script>
