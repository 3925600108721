<template>
  <expandable-box
    :title="title"
    :expandable="expandable"
    :expanded-by-parent="expandedByParent"
    :open-by-default="openByDefault"
    :scrollable="scrollable">
    <template #links>
      <slot name="links"></slot>
    </template>

    <template #box-content>
      <request-possible-lawyer
        v-for="possibleLawyer in orderedPossibleLawyers" :key="possibleLawyer.id"
        :possible-lawyer="possibleLawyer"
        :lawyer-interactions="lawyerInteractionsFor(possibleLawyer)">
      </request-possible-lawyer>
    </template>
  </expandable-box>
</template>

<script>
import RequestPossibleLawyer from 'vue-app/admin/requests/request-possible-lawyer.vue';
import ExpandableBox from 'vue-app/admin/shared/expandable-box.vue';
import { orderBy } from 'lodash';

export default {
  name: 'RequestPossibleLawyers',

  components: {
    RequestPossibleLawyer,
    ExpandableBox
  },

  props: {
    possibleLawyers: {
      type: Array,
      required: true
    },

    clientLawyerInteractions: {
      type: Array,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    openByDefault: {
      type: Boolean,
      default: false
    },

    expandedByParent: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    expandable() {
      return this.possibleLawyers.length > 0;
    },

    scrollable() {
      return this.possibleLawyers.length > 6;
    },

    orderedPossibleLawyers() {
      return orderBy(this.possibleLawyers, ['status']);
    }
  },

  methods: {
    lawyerInteractionsFor(possibleLawyer) {
      return this.clientLawyerInteractions.find(interaction => interaction.lawyerId === possibleLawyer.lawyerId);
    }
  }
};
</script>
