import Opportunity from 'src/resources/opportunity.js';
import PracticeArea from 'src/resources/practice-area.js';
import LoadingService from 'src/vue-app/shared/services/loading-service.js';
import SharedListsService from 'src/vue-app/shared/services/shared-lists-service.js';
import { uniq } from 'lodash';

class FilterService {
  getFilterOptions(field) {
    return this.#fetchFunctionMap[field]();
  }

  #fetchFunctionMap = {
    practice_areas:  this.#practiceAreasData.bind(this),
    location:        this.#locationsData.bind(this),
    time_commitment: this.#timeCommitmentsData.bind(this),
    site:            this.#sitesData.bind(this)
  };

  #dataToOptions(data, slugKey, labelKey) {
    return data.map(item => ({
      slug: item[slugKey] || item,
      label: item[labelKey] || item
    }));
  }

  #practiceAreasData() {
    LoadingService.loading('filterOptions');

    return PracticeArea.query({ view: 'minimal' })
      .then(response => {
        return this.#dataToOptions(response, 'name', 'name');
      })
      .finally(() => {
        LoadingService.done('filterOptions');
      });
  }

  #locationsData() {
    return Opportunity.query()
    .then(response => {
      const locations = uniq(response.map(opportunity => opportunity.locationIndexString));

      return this.#dataToOptions(locations);
    })
    .finally(() => {
      LoadingService.done('filterOptions');
    });
  }

  #timeCommitmentsData() {
    const timeCommitments = SharedListsService.timeCommitments;

    return Promise.resolve(this.#dataToOptions(timeCommitments, 'slug', 'label'));
  }

  #sitesData() {
    const sites = SharedListsService.sites;

    return Promise.resolve(this.#dataToOptions(sites, 'slug', 'label'));
  }
}

export default new FilterService;
