<template>
  <span :class="badgeStyles" class="status-badge">{{ statusValue }}</span>
</template>

<script>
import { startCase } from 'lodash';

export default {
  name: 'StatusBadge',

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  computed: {
    submittedLawFirmQuotesCount() {
      return this.rfq.lawFirmQuotes.filter(response => (
        ['quote_submitted', 'declined_by_firm', 'selected_by_client', 'declined_by_client'].includes(response.status)
      )).length;
    },

    badgeStyles() {
      const styles = {
        'closed': 'closed',
        'draft': 'draft',
        'firm_selected': 'firm-selected',
        'sent': this.submittedLawFirmQuotesCount > 0 ? 'full-response' : 'no-response'
      };

      return styles[this.rfq.status] || '';
    },

    statusValue() {
      const values = {
        'sent': this.submittedLawFirmQuotesCount > 0 ? 'Response Received' : 'No Responses',
        'closed': startCase(this.rfq.status),
        'firm_selected': startCase(this.rfq.status),
        'draft': startCase(this.rfq.status)
      };

      return values[this.rfq.status] || '';
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .status-badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 4px;

    &.full-response {
      background-color: $high-match-score-blue;
      color: $white;
      border: 1px solid $high-match-score-blue;
    }

    &.no-response {
      background-color: $white;
      color: $high-match-score-blue;
      border: 1px solid $high-match-score-blue;
    }

    &.closed {
      background-color: $k-purple;
      color: $white;
      border: 1px solid $k-purple;
    }

    &.draft {
      background-color: $mid-match-score-blue;
      color: $white;
      border: 1px solid $mid-match-score-blue;
    }

    &.firm-selected {
      background-color: $scout-green;
      color: $white;
      border: 1px solid $scout-green;
    }
  }
</style>
