<template>
  <validation-observer v-slot="{ handleSubmit }">
    <form role="form" class="a-form" novalidate>
      <validation-provider v-if="!disabled" rules="required" v-slot="{ errors }" :mode="passiveAggressive">
        <label for="list-name">List Name <span class="red-text">*</span></label>

        <div class="error-text bottom-5" v-if="errors.length">
          {{ errors[0] }}
        </div>

        <input type="text" id="list-name" name="listName" class="form-control bottom-10" v-model="name">
      </validation-provider>

      <div v-if="disabled" class="red-text bottom-30">
        You can only add 100 {{ currentTab.replace('_', ' ') }} to a list at a time. Please narrow down your search results to create a list.
      </div>

      <div v-else class="dark-gray-text bottom-30">
        Note: this list will be added to Internal Lists
      </div>

      <div class="row">
        <div class="col-sm-4">
          <loading-button
            v-if="!disabled"
            name="createList"
            lb-class="primary-btn-blue"
            @lb-click="handleSubmit(handleOnSubmit)">
            Save
          </loading-button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';

export default {
  name: 'FirmClientCreateListForm',

  components: {
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    currentTab: {
      type:    String,
      required: true
    },

    createList: {
      type:     Function,
      required: true
    },

    disabled: {
      type:    Boolean,
      default: false
    }
  },

  data() {
    return {
      name: ''
    };
  },

  methods: {
    handleOnSubmit() {
      LoadingService.loading('createList');

      return this.createList({ name: this.name }).then(() => {
        LoadingService.done('createList');
      });
    }
  }
};
</script>
