import sanitizeHtml from 'sanitize-html';

class HtmlTextSanitizer {
  sanitize(text) {
    let allTagsAreEmpty = true;

    sanitizeHtml(
      text,
      { exclusiveFilter: (frame) => {
        if (frame.text.length > 0) {
          allTagsAreEmpty = false;
        }
      } }
    );

    return allTagsAreEmpty ? null : text;
  }
}

export default HtmlTextSanitizer;
