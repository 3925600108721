<template>
  <div>
    <div class="d-flex wrap row-gap-8 col-gap-8" :style="showMoreStyles" ref="tagContainer">
      <scout-tag
        v-for="scoutTag in orderedTags"
        :scout-tag="scoutTag"
        :key="scoutTag.id"
        ref="tags">
      </scout-tag>
    </div>

    <div v-show="isOverflowing" class="top-5">
      <priori-modal :title="`${firmName} Tags`">
        <template #modal-trigger="{ openModal }">
          <a href="" @click.prevent="openModal" class="semibold-weight">Show all</a>
        </template>

        <template #default="{ closeModal }">
          <div class="d-flex wrap row-gap-8 col-gap-8">
            <scout-tag
              v-for="scoutTag in orderedTags"
              :scout-tag="scoutTag"
              :key="scoutTag.id"
              ref="tags">
            </scout-tag>
          </div>

          <div class="row top-30 tight-columns">
            <div class="col-sm-3">
              <button type="button" @click="closeModal" class="nv-button-white">Close</button>
            </div>
          </div>
        </template>
      </priori-modal>
    </div>
  </div>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import { sortScoutTags } from 'resources/utils/tag-sorter.js';
import { orderBy } from 'lodash';

export default {
  name: 'ScoutTagsListWithModal',

  components: {
    PrioriModal,
    ScoutTag
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    },

    firmName: {
      type: String,
      required: false
    },

    maxHeight: {
      type: Number,
      default: 58
    },

    showAllTags: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOverflowing: false
    };
  },

  computed: {
    orderedTags() {
      if (this.showAllTags) {
        return sortScoutTags(this.scoutTags);
      }
      else {
        return orderBy(this.generalTags, 'position');
      }
    },

    showMoreStyles() {
      return {
        'max-height': `${this.maxHeight}px`,
        'overflow': 'hidden'
      };
    },

    generalTags() {
      return this.scoutTags.filter(tag => tag.tagType === 'general');
    }
  },

  mounted() {
    this.setIsOverflowing();
    window.addEventListener('resize', this.setIsOverflowing);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setIsOverflowing);
  },

  methods: {
    setIsOverflowing() {
      // Fixes weird intermittent issue involving Chrome Dev Tools, where the tagContainer is briefly not present.
      if (!this.$refs.tagContainer) {
        return;
      }
      this.isOverflowing = this.$refs.tagContainer.scrollHeight > this.$refs.tagContainer.clientHeight;
    }
  }
};
</script>
