<template>
  <div v-if="hasQuestions || handlesLawyers">
    <div class="size-text-15px bold-weight heading-underline bottom-20">
      {{ questionGroup.name }}
    </div>

    <div :class="{ 'bottom-20': handlesLawyers }" v-if="hasQuestions">
      <div class="list-spacing-20" v-for="question in orderedQuestions" :key="question.id">
        <quote-form-question
          :question-group="questionGroup"
          :question="question"
          :answers="formData.answers"
          :on-change="onAnswerChange"
          :quote-last-submitted-at="quoteLastSubmittedAt"
          :is-admin-form="isAdminForm"
          :quote-received-at="quoteReceivedAt">
        </quote-form-question>
      </div>
    </div>

    <quote-form-client-suggested-lawyers
      v-if="!isAdminForm && handlesLawyers"
      class="bottom-20"
      :client-suggested-lawyers="formData.clientSuggestedLawyers"
      :rates-are-required="ratesAreRequired"
      :on-change="onSuggestedLawyerChange">
    </quote-form-client-suggested-lawyers>

    <quote-form-firm-suggested-lawyers
      v-if="!isAdminForm && handlesLawyers"
      :firm-suggested-lawyers="formData.firmSuggestedLawyers"
      :client-suggested-lawyers="formData.clientSuggestedLawyers"
      :rates-are-required="ratesAreRequired"
      :on-load-firm-lawyers="onLoadFirmLawyers"
      :on-add-lawyers="onAddFirmSuggestedLawyers"
      :on-change="onSuggestedLawyerChange"
      :on-remove-lawyer="onRemoveFirmSuggestedLawyer"
      :is-connecting="isConnecting"
      :quote="quote">
    </quote-form-firm-suggested-lawyers>

    <quote-form-priori-suggested-lawyers
      v-if="isAdminForm && handlesLawyers"
      :priori-suggested-lawyers="formData.prioriSuggestedLawyers"
      :rates-are-required="ratesAreRequired"
      :on-change="onSuggestedLawyerChange">
    </quote-form-priori-suggested-lawyers>
  </div>
</template>

<script>
import QuoteFormQuestion from 'vue-app/scout/firm/rfqs/quote-form-question.vue';
import QuoteFormClientSuggestedLawyers from 'vue-app/scout/firm/rfqs/quote-form-client-suggested-lawyers.vue';
import QuoteFormFirmSuggestedLawyers from 'vue-app/scout/firm/rfqs/quote-form-firm-suggested-lawyers.vue';
import QuoteFormPrioriSuggestedLawyers from 'vue-app/admin/requests/quote-form-priori-suggested-lawyers.vue';
import dateComparisons from 'vue-app/shared/mixins/date-comparisons.js';
import { orderBy } from 'lodash';

export default {
  name: 'QuoteFormQuestionGroup',

  components: {
    QuoteFormQuestion,
    QuoteFormClientSuggestedLawyers,
    QuoteFormFirmSuggestedLawyers,
    QuoteFormPrioriSuggestedLawyers
  },

  mixins: [dateComparisons],

  props: {
    questionGroup: {
      type: Object,
      required: true
    },

    isAdminForm: {
      type: Boolean,
      default: false
    },

    formData: {
      type: Object,
      required: true
    },

    ratesAreRequired: {
      type: Boolean,
      default: true
    },

    onLoadFirmLawyers: {
      type: Function,
      required: true
    },

    onAnswerChange: {
      type: Function,
      required: true
    },

    onSuggestedLawyerChange: {
      type: Function,
      required: true
    },

    onAddFirmSuggestedLawyers: {
      type: Function,
      required: true
    },

    onRemoveFirmSuggestedLawyer: {
      type: Function,
      required: true
    },

    hasNewQuestionActivities: {
      type: Boolean,
      required: true
    },

    quoteLastSubmittedAt: {
      type: String,
      required: false
    },

    isConnecting: {
      type: Boolean,
      default: false
    },

    quote: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasQuestions() {
      return this.nonContingentQuestions.length > 0;
    },

    nonContingentQuestions() {
      if (this.hasNewQuestionActivities) {
        return this.questionGroup.questions.filter(q => {
          return !q.prerequisiteQuestionId && this.isIncludedOrHasChanged(q);
        });
      }

      return this.questionGroup.questions.filter(q => q.isIncluded && !q.prerequisiteQuestionId);
    },

    orderedQuestions() {
      return orderBy(this.nonContingentQuestions, 'position');
    },

    handlesLawyers() {
      return this.questionGroup.handlesLawyers;
    },

    quoteReceivedAt() {
      return this.quote.dateReceived;
    }
  },

  methods: {
    isIncludedOrHasChanged(question) {
      return question.isIncluded || this.shouldShowRemovedQuestion(question);
    },

    shouldShowRemovedQuestion(question) {
      if (question.latestActivity?.activityType !== 'removed') { return false; }
      if (!this.removedBeforeQuoteReceived(question)) { return false; }

      return this.questionUpdatedAfterLastSubmission(question);
    },

    questionUpdatedAfterLastSubmission(question) {
      if (!this.quoteLastSubmittedAt) { return true; }

      return this.dateIsAfter(question.latestActivity?.createdAt, this.quoteLastSubmittedAt);
    },

    removedBeforeQuoteReceived(question) {
      return this.dateIsAfter(question.latestActivity?.createdAt, this.quoteReceivedAt);
    }
  }
};
</script>
