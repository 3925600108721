import { defineStore } from 'pinia';

export default defineStore('fieldGroups', {
  state: () => {
    return {
      identifiers: []
    };
  },

  getters: {
    confirmationOptionsVisible: (state) => {
      return identifier => state.identifiers.indexOf(identifier) !== -1;
    }
  },

  actions: {
    showDeleteConfirmationOptions(identifier) {
      if (this.identifiers.includes(identifier)) { return; }
      this.identifiers.push(identifier);
    },

    hideDeleteConfirmationOptions(identifier) {
      let index = this.identifiers.indexOf(identifier);
      if (index !== -1) { this.identifiers.splice(index, 1); }
    },

    initializeIdentifiers() {
      this.identifiers = [];
    }
  }
});
