<template>
  <div>
    <textarea :name="fieldName" :id="fieldId" class="form-control" :rows="rows" v-model.lazy="object[fieldName]" @change="onFieldChanged" @focus="startEditing" @blur="finishEditing" :disabled="isDisabled"></textarea>

    <div v-if="hasChanges" class="text-right top-10">
      <a href="" class="bold-link" @click.prevent="undo" :disabled="isEditing || !numUndos"><svg-icon name="undo" class="base-icon smaller right-5"></svg-icon>Undo</a>

      <a href="" class="bold-link left-20" @click.prevent="redo" :disabled="isEditing || !numRedos">Redo<svg-icon name="redo" class="base-icon smaller left-5"></svg-icon></a>

      <a href="" class="bold-link left-20" @click.prevent="accept" :disabled="isEditing">Save</a>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'TextareaWithUndo',

  components: {
    SvgIcon
  },

  props: {
    object: {
      type: Object,
      required: true
    },

    fieldName: {
      type: String,
      required: true
    },

    fieldId: {
      type: String,
      required: true
    },

    rows: {
      type: Number,
      default: 3
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    onStartEditing: {
      type: Function,
      default: () => {}
    },

    onFinishEditing: {
      type: Function,
      default: () => {}
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      versions: [this.object[this.fieldName]],
      cursor: 0
    };
  },

  computed: {
    numUndos() {
      return this.cursor;
    },

    numRedos() {
      return this.versions.length - this.cursor - 1;
    },

    previousVersion() {
      return this.versions[this.cursor - 1];
    },

    nextVersion() {
      return this.versions[this.cursor + 1];
    },

    hasChanges() {
      return this.versions.length > 1;
    }
  },

  methods: {
    startEditing() {
      this.isEditing = true;
      this.onStartEditing(this.fieldName);
    },

    finishEditing() {
      this.isEditing = false;
      this.onFinishEditing(this.fieldName);
    },

    onFieldChanged() {
      this.cursor += 1;
      this.versions = this.versions.slice(0, this.cursor);
      this.versions.push(this.object[this.fieldName]);
      this.broadcastChange();
    },

    undo() {
      if (!this.numUndos) { return; }

      this.object[this.fieldName] = this.previousVersion;
      this.cursor -= 1;
      this.broadcastChange();
    },

    redo() {
      if (!this.numRedos) { return; }

      this.object[this.fieldName] = this.nextVersion;
      this.cursor += 1;
      this.broadcastChange();
    },

    accept() {
      this.versions = [this.object[this.fieldName]];
      this.cursor = 0;
    },

    broadcastChange() {
      const params = {};
      params[this.fieldName] = this.object[this.fieldName];
      this.onChange(params);
    }
  }
};
</script>
