<template>
  <div class="lawyer-total-matters">
    <div class="chart-dollar-container">
      <div class="chart-container">
        <doughnut-chart
          height="80px"
          width="80px"
          :chart-data="chartData">
        </doughnut-chart>
      </div>

      <div class="dollars-container text-center">
        <div class="dollar-num">
          {{ formattedDollarsSpent }}
        </div>

        <div class="dollar-text">
          Total Dollars Spent
        </div>
      </div>
    </div>

    <chart-legends
      :column-count="legendColumnCount"
      :legend-data="legendData"
      :initial-max-legends="4">
    </chart-legends>
  </div>
</template>

<script>
import DoughnutChart from 'vue-app/shared/components/doughnut-chart.vue';
import ChartLegends from 'vue-app/shared/components/chart-legends.vue';
import PrioriColors from 'vue-app/shared/misc/priori-colors';
import CurrencyFormatter from 'vue-app/shared/misc/currency-formatter';
import { map, orderBy, sumBy } from 'lodash';

export default {
  name: 'LawyerTotalMatters',

  components: {
    ChartLegends,
    DoughnutChart
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    legendColumns: {
      type: Number,
      default: 2
    }
  },

  computed: {
    practiceAreaData() {
      return this.lawyer.spendByPracticeArea;
    },

    colors() {
      return PrioriColors.generate(this.practiceAreaData.length);
    },

    formattedData() {
      return map(this.practiceAreaData, (pa) => {
        pa.color = this.colors.shift();
        return pa;
      });
    },

    practiceAreas() {
      const data = this.formattedData.length ? this.formattedData : this.emptyChartData;
      return orderBy(data, 'percentSpent', 'desc');
    },

    chartData() {
      return map(this.practiceAreas, (practiceArea) => {
        return {
          name: practiceArea.name,
          color: practiceArea.color,
          value: practiceArea.percentSpent
        };
      });
    },

    legendData: function () {
      return map(this.practiceAreas, (practiceArea) => {
        return {
          name: practiceArea.name,
          color: practiceArea.color,
          value: `${practiceArea.percentSpent}%`
        };
      });
    },

    emptyChartData() {
      return [{ name: 'No Practice Area', color: '#eee', percentSpent: 100 }];
    },

    dollarsSpent() {
      return sumBy(this.practiceAreaData, 'dollarsSpent');
    },

    formattedDollarsSpent() {
      return CurrencyFormatter.compact().format(this.dollarsSpent);
    },

    legendColumnCount() {
      return this.chartData.length > 5 ? this.legendColumns : 1;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .lawyer-total-matters {
    max-width: 300px;
  }

  .chart-dollar-container {
    display: flex;
    align-items: center;
    min-height: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .dollar-num {
    font-size: 22px;
    line-height: 1.3;
  }

  .dollar-text {
    color: $k-dark-gray;
    font-size: 11px;
    line-height: 1.3;
  }

  .chart-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .dollars-container {
    width: 100%;
  }

  @media (min-width: 768px) {
    .chart-container {
      width: 52%;
      margin-bottom: 0;
    }

    .dollars-container {
      width: 48%;
    }
  }

  @media (min-width: 992px) {
    .chart-container, .dollars-container {
      width: 50%;
    }

    .dollar-num {
      font-size: 36px;
    }

    .dollar-text {
      font-size: 13px;
    }
  }
</style>
