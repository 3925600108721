<template>
  <priori-modal ref="modal" modal-id="create-law-firm" title="Review Not Relevant" @on-modal-close="reset">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="pseudo-link-blue dark-gray-link" @click="openModal">
        Not Relevant
      </button>
    </template>

    <template #default="{ closeModal }">
      <div class="bottom-20 size-text-14px">
        Please provide more detail as to why this reminder for <b>{{ matter.name }}</b> is not relevant. This will help Scout provide better recommendations in the future.
      </div>

      <form-with-radio-buttons
        class="bottom-30"
        :selected="reason"
        :options="options"
        :on-click="onClick">
      </form-with-radio-buttons>

      <div class="row tight-columns">
        <div class="col-xs-4">
          <button type="button" class="primary-btn-blue" :disabled="!reason" @click="submit">Submit</button>
        </div>

        <div class="col-xs-3">
          <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import FormWithRadioButtons from 'vue-app/shared/components/form-with-radio-buttons.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';

export default {
  name: 'ReviewReminderNotRelevantModal',

  components: {
    FormWithRadioButtons,
    PrioriModal
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      reason: null
    };
  },

  computed: {
    options() {
      return [
        {
          label: 'I\'m not working on this matter',
          value: 'not_working_on_matter'
        },
        {
          label: 'I do not have enough information to write a review at the moment, but may write a review in the future',
          value: 'not_enough_information'
        },
        {
          label: 'I don\'t plan to review this matter',
          value: 'not_planning_to_review'
        }
      ];
    }
  },

  methods: {
    onClick(value) {
      this.reason = value;
    },

    submit() {
      this.onSubmit({ scoutMatterId: this.matter.id, reason: this.reason }).then(() => {
        this.reset();
      });
    },

    reset() {
      this.reason = null;
    }
  }
};
</script>
