<template>
  <div>
    <div class="table-responsive size-text-13px" v-if="hasOpenInquiries">
      <table class="table auto-height no-margin">
        <tbody>
          <tr v-for="inquiry in openInquiries" :key="inquiry.id">
            <td>
              <a :href="`/admin-app/clients/${inquiry.user.id}`" v-if="inquiry.user.klass === 'Client'">{{ inquiry.firstName }} {{ inquiry.lastName }}</a>
              <span v-if="inquiry.user.klass !== 'Client'">{{ inquiry.firstName }} {{ inquiry.lastName }}</span>
            </td>

            <td>
              {{ formattedDate(inquiry.createdAt) }}
            </td>

            <td>
              {{ inquiry.email }}
            </td>

            <td>
              {{ inquiry.phoneNumber }}
            </td>

            <td>
              {{ (inquiry.assignedAdmin || {}).fullName || 'Unassigned' }}
            </td>

            <td>
              <a :href="`/law_simplified/contact_messages/${inquiry.id}/edit`" target="_blank">Edit</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="gray-text" v-if="!hasOpenInquiries">
      No Inquiries
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'OpenInquiries',

  props: {
    openInquiries: {
      type: Array,
      required: true
    }
  },

  computed: {
    hasOpenInquiries() {
      return this.openInquiries && this.openInquiries.length;
    }
  },

  methods: {
    formattedDate(date) {
      return DateFilter.isoDate(date);
    }
  }
};
</script>
