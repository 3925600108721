<template>
  <div>
    <div :class="['field-group sharper-corners', { 'expanded': expanded, 'top-rounded-corners': firstItem, 'last-border-corners': lastItem }]">
      <div class="row bottom-20-xs">
        <div class="col-sm-10 ">
          <label class="normal-weight">{{ practiceArea.name }}</label>
        </div>

        <div class="col-sm-2 text-right-not-xs">
          <a href="" class="normal-weight size-text-13px" @click.prevent="expanded = !expanded">{{ expanded ? 'Collapse' : 'Edit' }}</a>
        </div>
      </div>

      <div v-if="expanded">
        <input-with-tag-list
          :array-data="practiceArea.proficiencies"
          :tag-value-fn="getStringValue"
          @add-string="addStringToArray(practiceArea.proficiencies, $event)"
          @remove-string="removeStringFromArray(practiceArea.proficiencies, $event)">
        </input-with-tag-list>

        <div class="top-10">
          <a href="" class="remove-btn" @click.prevent="removeMode = true"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon>Remove Practice Area</a>
        </div>

        <div v-if="removeMode">
          <div class="pill-gray-text size-text-12px bottom-15 top-20">
            Are you sure you’d like to remove this practice area?
          </div>

          <div class="row">
            <div class="col-sm-3">
              <button type="button" role="button" class="secondary-btn-blue" @click="removePracticeArea">Yes</button>
            </div>

            <div class="col-sm-3 top-20-xs">
              <button type="button" class="primary-btn-white" @click="removeMode = false">No</button>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div>
          <div v-if="practiceArea.proficiencies.length > 0">
            {{ practiceArea.proficiencies.join(', ') }}
          </div>

          <div v-else class="text-italic dark-gray-text size-text-12px">
            No Proficiencies
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputWithTagList from 'vue-app/scout/shared/input-with-tag-list.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'EditPracticeArea',

  components: {
    InputWithTagList,
    SvgIcon
  },

  props: {
    practiceArea: {
      type:     Object,
      required: true
    },

    removePracticeArea: {
      type: Function,
      required: true
    },

    firstItem: {
      type: Boolean,
      required: false
    },

    lastItem: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      expanded: false,
      removeMode: false
    };
  },

  methods: {
    getStringValue(string) {
      return string;
    },

    addStringToArray(array, string) {
      if (!array.includes(string)) {
        array.push(string);
      }
    },

    removeStringFromArray(array, index) {
      array.splice(index, 1);
    },

    collapse() {
      this.expanded = false;
      this.removeMode = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .remove-btn {
    align-items: center;
    color: $k-darker-gray;
    display: flex;
    justify-content: right;
  }

  .expanded {
    border-left: 4px solid $k-blue;
  }

  // overrides for app/javascript/src/stylesheets/scout/forms.scss
  .field-group {
    padding: 20px;
    border-bottom: none;
    border-radius: 0;
  }

  .top-rounded-corners {
    border-radius: 4px 4px 0px 0px;
  }

  .last-border-corners {
    border-radius: 0px 0px 4px 4px;
    border-bottom: 1px solid $k-light-gray;
  }
</style>
