import axios from 'axios';
import BaseResource from 'resources/base-resource';

class ScoutClientUsageAnalytics extends BaseResource {
  static memberUsage(params) {
    return axios.get('/scout/client_usage_analytics/member_usage.json', { params: params });
  }
}

export default ScoutClientUsageAnalytics;
