<template>
  <div>
    <div :class="['bold-weight tight-lines', { 'disabled-text' : !question.isIncluded }]">
      {{ question.questionText }} ({{ attachmentCount }})
      <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
    </div>

    <div class="top-5" v-if="hasAnswer">
      <ul class="compact">
        <li :class="['list-spacing-2 bold-weight', { 'disabled-text' : !question.isIncluded }]" v-for="attachment in answer.attachments"><a :href="attachment.url" target="_blank" rel="noopener">{{ attachment.name }}</a></li>
      </ul>
    </div>

    <div v-else :class="['missing-text', { 'disabled-text' : !question.isIncluded }]">
      None provided
    </div>
  </div>
</template>

<script>
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import { startCase } from 'lodash';

export default {
  name: 'QuestionAnswerFile',

  components: {
    ScoutTag
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    answer: {
      type: Object,
      required: false
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasAnswer() {
      return this.answer && this.answer.attachments && this.answer.attachments.length;
    },

    attachmentCount() {
      return this.hasAnswer ? this.answer.attachments.length : 0;
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  }
};
</script>
