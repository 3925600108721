<template>
  <div>
    <div class="conversation-viewer" v-show="!viewingParticipants">
      <conversation-header
        :conversation="conversation"
        :rfq-info="rfqInfo"
        :on-view-participants="toggleParticipantsView">
      </conversation-header>

      <conversation-messages
        :conversation="conversation">
      </conversation-messages>

      <conversation-actions
        :conversation="conversation"
        :on-toggle-expanded="scrollToLastMessage"
        :on-save-draft="saveDraftMessage"
        :on-send="sendMessage">
      </conversation-actions>
    </div>

    <div v-show="viewingParticipants">
      <conversation-participants
        :conversation="conversation"
        :on-hide="toggleParticipantsView">
      </conversation-participants>
    </div>
  </div>
</template>

<script>
import ConversationHeader from 'vue-app/mailboxer/scout/conversation-header.vue';
import ConversationMessages from 'vue-app/mailboxer/scout/conversation-messages.vue';
import ConversationActions from 'vue-app/mailboxer/scout/conversation-actions.vue';
import ConversationParticipants from 'vue-app/mailboxer/scout/conversation-participants.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'ConversationViewer',

  components: {
    ConversationHeader,
    ConversationMessages,
    ConversationActions,
    ConversationParticipants
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    rfqInfo: {
      type: Object,
      required: false
    },

    onCreateOrUpdateMessage: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      savingPromise: Promise.resolve(),
      viewingParticipants: false
    };
  },

  methods: {
    scrollToLastMessage(smooth) {
      const container = document.getElementById('conversation-messages');

      this.$nextTick(() => {
        if (smooth) {
          container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
        }
        else {
          container.scrollTop = container.scrollHeight;
        }
      });
    },

    toggleParticipantsView() {
      this.viewingParticipants = !this.viewingParticipants;
    },

    saveDraftMessage() {
      if (this.isSaving) { return; }

      this.isSaving = true;
      this.savingPromise = this.onCreateOrUpdateMessage(false).then(() => {
        this.isSaving = false;
      });
    },

    sendMessage() {
      LoadingService.loading('sendMessage');

      return this.savingPromise.then(() => {
        this.isSaving = true;

        return this.onCreateOrUpdateMessage(true).then(() => {
          LoadingService.done('sendMessage');
          this.scrollToLastMessage();
          this.isSaving = false;
        });
      });
    }
  }
};
</script>
