<template>
  <div>
    <div class="viewing-as-container titlebar" v-if="viewingAsClient">
      <div class="text">
        <span class="size-text-12px text-italic right-5">You are viewing this profile as:</span>
        <span class="size-text-14px semibold-weight">{{ workspaceName }}</span>
      </div>

      <div class="top-10-xs">
        <a :href="switcherExitUrl" class="button">Exit View As</a>
      </div>
    </div>

    <div class="titlebar lawyer-titlebar">
      <div class="row vertical-center-not-xs">
        <div :class="['col-xs-12', { 'col-sm-7': canUseViewAsSwitcher }]">
          <lawyer-profile-header :lawyer="lawyer" @profile-photo-updated="updateProfilePhoto" :can-edit-profile="canEditProfile"></lawyer-profile-header>
        </div>

        <div class="col-sm-5 top-20-xs" v-if="canUseViewAsSwitcher">
          <div class="flex" v-if="switcherAvailable">
            <lawyer-viewing-as-switcher :lawyer-bridges="lawyerBridges" :generic-view-url="genericViewUrl"></lawyer-viewing-as-switcher>

            <div class="note top-5">
              <i>Note: you will not be able to edit your profile when viewing as a client.</i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-8">
              <lawyer-biography class="bottom-30" :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-biography>

              <lawyer-general class="bottom-30" :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-general>

              <lawyer-practice-areas class="bottom-30" :lawyer="lawyer" :lawyer-practice-areas="lawyer.lawyerPracticeAreas" :firm-practice-areas="lawyer.firmPracticeAreas" :unselected-firm-practice-areas="lawyer.unselectedFirmPracticeAreas" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-practice-areas>

              <lawyer-representative-matters class="bottom-30" :matters="lawyer.representativeMatters" :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-representative-matters>

              <lawyer-articles :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-articles>
            </div>

            <div class="col-sm-4 top-30-xs">
              <lawyer-contact-info class="bottom-30" :lawyer="lawyer" :as-lawyer="true" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-contact-info>

              <lawyer-diversity-inclusion v-if="lawyer.diversityDisclosureEnabledForAllCountries" class="bottom-30" :lawyer="lawyer" :workspaces="workspaces" :viewing-as-generic-client="viewTypeIsGenericWorkspace" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-diversity-inclusion>

              <lawyer-highlights class="bottom-30" :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-highlights>

              <lawyer-awards class="bottom-30" :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-awards>

              <lawyer-experience class="bottom-30" :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-experience>

              <lawyer-affiliations class="bottom-30" :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-affiliations>

              <lawyer-statistics :lawyer="lawyer" :on-save="onSave" :can-edit-profile="canEditProfile"></lawyer-statistics>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerProfileHeader from 'vue-app/scout/firm/lawyers/lawyer-profile-header.vue';
import LawyerBiography from 'vue-app/scout/shared/lawyers/lawyer-biography.vue';
import LawyerGeneral from 'vue-app/scout/shared/lawyers/lawyer-general.vue';
import LawyerPracticeAreas from 'vue-app/scout/shared/lawyers/lawyer-practice-areas.vue';
import LawyerRepresentativeMatters from 'vue-app/scout/shared/lawyers/lawyer-representative-matters.vue';
import LawyerArticles from 'vue-app/scout/shared/lawyers/lawyer-articles.vue';
import LawyerContactInfo from 'vue-app/scout/shared/lawyers/lawyer-contact-info.vue';
import LawyerDiversityInclusion from 'vue-app/scout/shared/lawyers/lawyer-diversity-inclusion.vue';
import LawyerHighlights from 'vue-app/scout/shared/lawyers/lawyer-highlights.vue';
import LawyerAwards from 'vue-app/scout/shared/lawyers/lawyer-awards.vue';
import LawyerExperience from 'vue-app/scout/shared/lawyers/lawyer-experience.vue';
import LawyerAffiliations from 'vue-app/scout/shared/lawyers/lawyer-affiliations.vue';
import LawyerStatistics from 'vue-app/scout/shared/lawyers/lawyer-statistics.vue';
import LawyerViewingAsSwitcher from 'vue-app/scout/firm/lawyers/lawyer-viewing-as-switcher.vue';

import PermissionsService from 'vue-app/shared/services/permissions-service.js';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import useViewingAsSwitcherStore from 'vue-app/stores/viewing-as-switcher.js';
import { mapState } from 'pinia';

export default {
  name: 'ScoutFirmLawyerShow',

  components: {
    LawyerProfileHeader,
    LawyerBiography,
    LawyerGeneral,
    LawyerPracticeAreas,
    LawyerRepresentativeMatters,
    LawyerArticles,
    LawyerContactInfo,
    LawyerDiversityInclusion,
    LawyerHighlights,
    LawyerAwards,
    LawyerExperience,
    LawyerAffiliations,
    LawyerStatistics,
    LawyerViewingAsSwitcher
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    lawyerBridges: {
      type:    Array,
      default: () => []
    },

    viewType: {
      type:     String,
      required: true
    },

    workspace: {
      type:    Object,
      default: null
    },

    onSave: {
      type:    Function,
      default: () => {}
    },

    workspaces: {
      type:    Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(useViewingAsSwitcherStore, { initialViewUrl: 'initialViewUrl' }),

    currentUser() {
      return useCurrentUserStore().currentUser;
    },

    permissionsService() {
      return new PermissionsService(this.currentUser);
    },

    switcherAvailable() {
      return this.viewTypeIsMyProfile || this.viewTypeIsInternal;
    },

    viewTypeIsMyProfile() {
      return this.viewType === 'myProfile';
    },

    viewTypeIsInternal() {
      return this.viewType === 'internal';
    },

    viewTypeIsGenericWorkspace() {
      return this.viewType === 'genericWorkspace';
    },

    viewTypeIsWorkspace() {
      return this.viewType === 'workspace';
    },

    viewingAsClient() {
      return this.viewTypeIsGenericWorkspace || this.viewTypeIsWorkspace;
    },

    workspaceName() {
      if (this.viewTypeIsWorkspace && this.workspace) {
        return this.workspace.name;
      }
      else if (this.viewTypeIsGenericWorkspace) {
        return 'Generic Client';
      }
      else {
        return '';
      }
    },

    genericViewUrl() {
      return `/scout-firm/lawyers/${this.lawyer.id}/client`;
    },

    lawyerId() {
      return this.lawyer.klass === 'Lawyer' ? this.lawyer.id : this.lawyer.lawyerId;
    },

    switcherExitUrl() {
      if (this.initialViewUrl) {
        return this.initialViewUrl;
      }
      else {
        return this.lawyerId ? `/scout-firm/lawyers/${this.lawyerId}` : '/scout-firm/search';
      }
    },

    canUseViewAsSwitcher() {
      if (this.viewingAsClient) { return false; }

      return this.permissionsService.can('viewAsGenericClient') || this.permissionsService.can('viewAsSpecificClient');
    },

    canEditProfile() {
      if (this.viewingAsClient) { return false; }

      const currentUserIsResourceOwner = (this.lawyer.id === this.currentUser.id) && (this.lawyer.klass === this.currentUser.klass);

      return currentUserIsResourceOwner || this.permissionsService.can('editFirmLawyerProfiles');
    }
  },

  methods: {
    updateProfilePhoto(data) {
      this.lawyer.photoLarge = data === null ? null : data.photo.large.url;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .flex {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $screen-sm-min) {
      justify-content: flex-end;
    }
  }

  .note {
    width: 265px;
    color: $k-dark-gray;
    line-height: 1.4;
  }

  .viewing-as-container {
    background: $k-blue;

    @media (min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .text {
      color: $white;

      @media (min-width: $screen-sm-min) {
        width: 70%;
      }
    }

    .button {
      display: inline-block;
      padding: 13px 30px;
      background: $white;
      border-radius: $border-radius-large;
      color: $k-darker-gray;
      line-height: 18px;
      font-weight: 700;
      text-transform: uppercase;

      @media (min-width: $screen-sm-min) {
        padding: 11px 30px;
      }

      &:hover {
        text-decoration: none;
        background-color: $k-lighter-gray;
      }
    }
  }
</style>
