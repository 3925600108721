<template>
  <div>
    <div class="titlebar-sm">
      <div class="medium-page-heading top-2">
        Manage Saved Searches
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <interface-note class="size-text-13px bottom-30" :note-text="interfaceNoteText"></interface-note>

          <loading-section name="savedSearches">
            <div class="row">
              <div class="col-sm-6">
                <searches-list
                  title="Shared"
                  :list="sharedList"
                  :on-removed="loadSavedSearches"
                  :on-saved="loadSavedSearches"
                  :on-order-updated="loadSavedSearches">
                </searches-list>
              </div>

              <div class="col-sm-6 top-30-xs">
                <searches-list
                  title="Private"
                  :list="privateList"
                  :on-removed="loadSavedSearches"
                  :on-saved="loadSavedSearches"
                  :on-order-updated="loadSavedSearches">
                </searches-list>
              </div>
            </div>
          </loading-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';
import SearchesList from './searches-list.vue';
import SavedSearch from 'resources/admin/saved-search';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'AdminSavedSearchesIndex',

  components: {
    InterfaceNote,
    SearchesList,
    LoadingSection
  },

  data() {
    return {
      savedSearches: {},
      interfaceNoteText: 'Use this page to view, edit, delete, or re-order your Saved Searches. The top 5 Saved Searches in each column will be visible within the dropdown menu on the search page. Please note that order changes on the Shared Saved Searches list will apply to all admins.'
    };
  },

  computed: {
    sharedList() {
      return this.savedSearches.shared;
    },

    privateList() {
      return this.savedSearches.private;
    }
  },

  mounted() {
    this.loadSavedSearches();
  },

  methods: {
    loadSavedSearches() {
      LoadingService.loading('savedSearches');
      SavedSearch.query()
        .then(response => {
          this.savedSearches = response;
        })
        .finally(() => {
          LoadingService.done('savedSearches');
        });
    }
  }
};
</script>
