<template>
  <expandable-box
    title="Notes"
    :scrollable="false"
    :expanded-by-parent="expandedByParent || expandedLocally">
    <template #box-content>
      <div class="admin-comments-wrapper">
        <div v-if="isAdding">
          <validation-observer v-slot="{ handleSubmit, failed: formInvalid }" ref="validationObserver">
            <form name="commentsForm" class="a-form comments-section" novalidate>
              <div :class="formInvalid ? 'bottom-10' : 'bottom-5'">
                <textarea-vertical
                  :id="`${resourceType}CommentContent-${resourceId}`"
                  :rows="5"
                  placeholder="Add a note"
                  rules="required"
                  v-model="newComment">
                </textarea-vertical>
              </div>

              <div class="size-text-12px text-right">
                <button type="button" class="pseudo-link-blue bold-link" @click="cancelNewComment">Cancel</button>

                <button type="button" class="pseudo-link-blue bold-link left-20" @click="handleSubmit(createComment)">Save</button>
              </div>
            </form>
          </validation-observer>
        </div>

        <div v-if="!isAdding">
          <div v-for="(comment, index) in comments" :key="comment.id">
            <admin-comment
              :comment="comment"
              :on-update-comment="onUpdateComment"
              :on-delete-comment="onDeleteComment">
            </admin-comment>

            <hr v-if="index !== comments.length - 1" class="kenny-hr">
          </div>

          <div class="gray-text size-text-base top-10 bottom-10 text-center" v-if="!comments.length">
            No Admin Notes
          </div>
        </div>
      </div>
    </template>

    <template #links>
      <button type="button" class="pseudo-link-blue" @click="startAdding">Add Note</button>
    </template>
  </expandable-box>
</template>

<script>
import TextareaVertical from 'vue-app/shared/components/textarea-vertical.vue';
import AdminCommentComponent from 'vue-app/admin/shared/admin-comment.vue';
import ExpandableBox from 'vue-app/admin/shared/expandable-box.vue';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'AdminCommentsSection',

  components: {
    TextareaVertical,
    AdminComment: AdminCommentComponent,
    ExpandableBox,
    ValidationObserver
  },

  props: {
    expandedByParent: {
      type: Boolean,
      required: true
    },

    resourceId: {
      type: Number,
      required: true
    },

    resourceType: {
      type: String,
      required: true
    },

    comments: {
      type: Array,
      required: true
    },

    onCreateComment: {
      type: Function,
      required: true
    },

    onUpdateComment: {
      type: Function,
      required: true
    },

    onDeleteComment: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isAdding: false,
      newComment: '',
      expandedLocally: false
    };
  },

  watch: {
    expandedByParent() {
      this.expandedLocally = this.expandedByParent;
    }
  },

  methods: {
    startAdding() {
      this.isAdding = true;
      this.expandedLocally = true;
    },

    cancelNewComment() {
      this.isAdding = false;
      this.newComment = '';
    },

    createComment() {
      this.onCreateComment(this.newComment).then(() => {
        this.isAdding = false;
        this.newComment = '';
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .admin-comments-wrapper {
    padding: 20px 0;
  }
</style>
