<template>
  <div>
    <opportunity-legacy
      v-if="hasLegacyOpportunity"
      :opportunity="opportunity"
      :published-at="counselRequest.publishedAt">
    </opportunity-legacy>

    <opportunity-editor
      v-else
      :counsel-request="counselRequest">
    </opportunity-editor>
  </div>
</template>

<script>
import OpportunityEditor from 'vue-app/admin/opportunities/opportunity-editor.vue';
import OpportunityLegacy from 'vue-app/admin/opportunities/opportunity-legacy.vue';

export default {
  name: 'OpportunityContainer',

  components: {
    OpportunityEditor,
    OpportunityLegacy
  },

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  },

  computed: {
    opportunity() {
      return this.counselRequest.opportunity;
    },

    hasLegacyOpportunity() {
      return !!this.opportunity?.legacy;
    }
  }
};
</script>
