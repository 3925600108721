<template>
  <tr>
    <td :class="{ 'expanded': isExpanded }">
      <div class="row tight-columns question-text">
        <div :class="(hasOverflow ? 'col-xs-8' : 'col-xs-12')">
          {{ question.questionText }}
        </div>

        <div v-if="hasOverflow" class="col-xs-4 text-right">
          <button v-if="!isExpanded" type="button" class="pseudo-link-blue expand-link" @click="expand">Expand<svg-icon name="caret-right" class="base-icon"></svg-icon></button>

          <button v-else type="button" class="pseudo-link-blue collapse-link" @click="collapse">Collapse<svg-icon name="caret-down" class="base-icon"></svg-icon></button>
        </div>
      </div>
    </td>

    <td v-for="(quote, index) in quotes" :key="quote.id" :class="{ 'expanded': isExpanded }">
      <answer-cell
        :quote="quote"
        :question="question">
      </answer-cell>
    </td>
  </tr>
</template>

<script>
import AnswerCell from 'vue-app/scout/client/rfqs/comparison/answer-cell.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'AnswerRow',

  components: {
    AnswerCell,
    SvgIcon
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    quotes: {
      type: Array,
      required: true
    },

    onCollapse: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      hasOverflow: true,
      isExpanded: false
    };
  },

  computed: {
    elementIds() {
      return this.quotes.map(q => `#answer-${q.id}-${this.question.id}`).join(', ');
    },

    elements() {
      return Array.from(document.querySelectorAll(this.elementIds));
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.hasOverflow = this.elements.some(e => e.scrollHeight > e.clientHeight);
    });
  },

  methods: {
    expand() {
      this.isExpanded = true;
    },

    collapse() {
      this.isExpanded = false;
      this.onCollapse();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  td:not(.expanded) {
    max-height: 130px;
  }

  .question-text {
    height: 100%;
    overflow: hidden;
  }

  .expand-link, .collapse-link {
    height: 20px;
    font-size: 12px;
    text-transform: uppercase;

    .base-icon {
      fill: $k-blue;
      vertical-align: top;
    }
  }

  .collapse-link .base-icon {
    width: 16px;
    margin-left: 2px;
  }
</style>
