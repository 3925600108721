// format
// enumVal: {
//   int: int value,
//   label: label
// }

// key maps for LawyerProfile rails model
// lawyer_profile.identifies_as_lgbtq
export const identifiesAsLgbtqMap = {
  identifiesAsLgbtq: {
    int: 0,
    label: 'Yes'
  },
  doesNotIdentifyAsLgbtq: {
    int: 1,
    label: 'No'
  },
  prefersNotToDiscloseLgbtq: {
    int: 2,
    label: 'Prefer not to disclose'
  }
};

// lawyer_profile.identifies_as_differently_abled
export const identifiesAsDifferentlyAbledMap = {
  identifiesAsDifferentlyAbled: {
    int: 0,
    label: 'Yes'
  },
  doesNotIdentifyAsDifferentlyAbled: {
    int: 1,
    label: 'No'
  },
  prefersNotToDiscloseDifferentlyAbled: {
    int: 2,
    label: 'Prefer not to disclose'
  }
};

// lawyer_profile.is_military_veteran
export const isMilitaryVeteranMap = {
  isMilitaryVeteran: {
    int: 0,
    label: 'Yes'
  },
  isNotMilitaryVeteran: {
    int: 1,
    label: 'No'
  },
  prefersNotToDiscloseMilitaryVeteran: {
    int: 2,
    label: 'Prefer not to disclose'
  }
};
