<template>
  <priori-modal
    ref="addClientModal"
    modal-id="add-client"
    modal-size="md"
    :backdrop="false"
    title="Add new user"
    @on-modal-close="reset">
    <template #modal-trigger="modalProps">
      <div>
        <button type="button" class="titlebar-button secondary-btn-blue" @click="modalProps.openModal()">Add new users</button>
      </div>
    </template>

    <template #default>
      <client-form
        ref="clientForm"
        :client="client"
        :on-save="save"
        :on-cancel="closeModal"
        :practice-groups="practiceGroups"
        :is-user-manager="isUserManager"
        :is-employee-id-required="isEmployeeIdRequired">
      </client-form>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import ClientForm from 'vue-app/scout/client/settings/users/client-form.vue';

export default {
  name: 'AddClient',

  components: {
    ClientForm,
    PrioriModal
  },

  mixins: [
    interactionModes
  ],

  props: {
    onSave: {
      type: Function,
      default: () => {}
    },

    practiceGroups: {
      type: Array,
      default: () => []
    },

    isUserManager: {
      type: Boolean,
      default: false
    },

    isEmployeeIdRequired: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
      client: {
        practiceGroupIds: []
      }
    };
  },

  methods: {
    save() {
      LoadingService.loading('submit');

      if (this.billingIdIsEmployeeId) {
        this.client.billingId = this.client.employeeId;
      }

      this.onSave(this.client).then(() => {
        this.closeModal();
      }).finally(() => {
        LoadingService.done('submit');
      });
    },

    closeModal() {
      this.reset();
      this.$refs.addClientModal.closeModal();
    },

    reset() {
      this.billingIdIsEmployeeId = false;
      this.noBillingId = false;
      this.client = {
        email: null,
        firstName: null,
        lastName: null,
        role: null,
        employeeId: null,
        billingId: null,
        practiceGroupIds: [],
        userType: null
      };
      this.$refs.clientForm.reset();
    }
  }
};
</script>
