<template>
  <div>
    <edit-modal ref="editModal" title="Add Law Firm" :input="firm" :backdrop="true" :keyboard="true" :on-save="save">
      <template #modal-trigger="{ openModal }">
        <button type="button" class="secondary-btn-blue" @click="initForm(openModal)" data-testid="open-edit-modal">Add Law Firm</button>
      </template>

      <template #default="{ inputCopy: firmCopy }">
        <div class="row">
          <div class="col-sm-6">
            <text-input-vertical
              label="Law Firm Name"
              id="firm-name"
              input-name="firmName"
              placeholder="Law Firm Name"
              rules="required"
              v-model="firmCopy.name">
            </text-input-vertical>
          </div>
        </div>

        <div v-if="canViewRFQFeature" class="top-20">
          <div class="row tight-columns vertical-center">
            <div class="col-xs-5 col-sm-1-5 bold-weight">
              RFP Enabled?
            </div>

            <div class="col-xs-7 col-sm-4-5">
              <toggle-button
                id="toggle-rfq-enabled"
                :value="hasRfqEnabled"
                true-text="Yes"
                false-text="No"
                @click="toggleHasRfqEnabled">
              </toggle-button>
            </div>
          </div>

          <div class="top-30" v-if="hasRfqEnabled">
            <div class="bold-weight">
              Add Contact <span class="inline-help required">*</span>
            </div>

            <div class="pill-gray-text bottom-20">
              You must provide law firm contact information in order to send RFPs. Upon saving, the firm will be notified by email that they have been invited to receive and respond to RFPs.
            </div>

            <div class="row">
              <div class="col-sm-6 bottom-20">
                <text-input-vertical
                  label="Contact First Name"
                  id="contact-first-name"
                  input-name="contactFirstName"
                  placeholder="Contact First Name"
                  rules="required"
                  v-model="contact.firstName">
                </text-input-vertical>
              </div>

              <div class="col-sm-6 bottom-20">
                <text-input-vertical
                  label="Contact Last Name"
                  id="contact-last-name"
                  input-name="contactLastName"
                  placeholder="Contact Last Name"
                  rules="required"
                  v-model="contact.lastName">
                </text-input-vertical>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <text-input-vertical
                  label="Contact Email"
                  id="contact-email"
                  input-name="contactEmail"
                  input-type="email"
                  placeholder="Contact Email"
                  rules="required|email"
                  v-model="contact.email">
                </text-input-vertical>
              </div>
            </div>
          </div>
        </div>
      </template>
    </edit-modal>

    <pop-up-modal ref="errorModal">
      <template #message>
        Thank you for adding this firm. Priori will reach out to the firm to confirm their connection to your workspace.
      </template>
    </pop-up-modal>
  </div>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import PopUpModal from 'vue-app/shared/components/pop-up-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'AddFirmModal',

  components: {
    EditModal,
    PopUpModal,
    TextInputVertical,
    ToggleButton
  },

  props: {
    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      hasRfqEnabled: false,
      firm: { name: null },
      contact: { firstName: null, lastName: null, email: null }
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    canViewRFQFeature() {
      return this.currentClient.workspaceHasRfqEnabled;
    }
  },

  methods: {
    initForm(openModalFn) {
      this.hasRfqEnabled = false;
      this.firm = { name: null };
      this.contact = { firstName: null, lastName: null, email: null };
      openModalFn();
    },

    toggleHasRfqEnabled() {
      this.hasRfqEnabled = !this.hasRfqEnabled;
    },

    save(firmCopy) {
      return this.onSubmit(firmCopy, this.hasRfqEnabled, this.contact)
        .catch((response) => {
          if (response.response.data.showProcessError) {
            this.$refs.editModal.closeModal();
            this.$refs.errorModal.openModal();

            return Promise.resolve();
          }
          else {
            return Promise.reject(response);
          }
        });
    }
  }
};
</script>
