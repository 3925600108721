<template>
  <div class="upload-status-box">
    <span>{{ status.text }}</span>
    <span :class="`status-icon ${status.iconClass}`"></span>
  </div>
</template>

<script>
export default {
  name: 'StatusBox',

  props: {
    statusCode: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      statuses: {
        0: { text: 'In Progress', iconClass: 'in-progress' },
        1: { text: 'In Progress', iconClass: 'in-progress' },
        2: { text: 'In Progress', iconClass: 'in-progress' },
        3: { text: 'Completed',   iconClass: 'completed'   },
        4: { text: 'Failed',      iconClass: 'failed'      }
      }
    };
  },

  computed: {
    status() {
      return this.statuses[this.statusCode];
    }
  }
};
</script>
