import { render, staticRenderFns } from "./connect-matter.vue?vue&type=template&id=b5c5b67e&scoped=true"
import script from "./connect-matter.vue?vue&type=script&lang=js"
export * from "./connect-matter.vue?vue&type=script&lang=js"
import style0 from "./connect-matter.vue?vue&type=style&index=0&id=b5c5b67e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5c5b67e",
  null
  
)

export default component.exports