<template>
  <div class="row tight-columns vertical-center saved-search-row">
    <div class="col-xs-10">
      <div class="semibold-weight text-ellipsis size-text-14px">
        {{ savedSearch.name }}
      </div>

      <div class="dark-gray-text size-text-12px top-5">
        {{ creatorFullName }}<span class="left-5 right-5 bold-weight">&#8231;</span>{{ visibility }}
      </div>
    </div>

    <div class="col-xs-2 text-right">
      <svg-icon name="menu" class="base-icon"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchesListItemOrder',

  props: {
    savedSearch: {
      type: Object,
      required: true
    }
  },

  computed: {
    visibility() {
      return this.savedSearch.visibility === 'shared' ? 'Shared' : 'Private';
    },

    creatorFullName() {
      if (this.savedSearch.currentUserIsCreator) {
        return `${this.savedSearch.creatorFullName} (You)`;
      }
      else {
        return this.savedSearch.creatorFullName;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .saved-search-row {
    padding: 10px;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-base;
  }
</style>
