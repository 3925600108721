<template>
  <div class="padded-container">
    <div class="row">
      <div class="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
        <div class="shadowed-box">
          <div class="box-content symmetrical">
            <div class="size-text-24px text-center bottom-30">
              Feature Lab
            </div>

            <form v-if="termsOfServiceRequired" role="form" class="a-form" novalidate>
              <div class="checkbox-container">
                <div class="checkbox">
                  <label for="terms-of-service" class="check-option vertical">
                    <span class="normal-weight">I have read and agree to the <a :href="termsOfServiceUrl" target="_blank">Terms & Conditions</a> and <a href="https://www.priorilegal.com/privacy-policy-dmca-copyright-policy" target="_blank">Privacy Policy</a></span>
                    <input type="checkbox" id="terms-of-service" name="termsOfService" v-model="agreed">
                    <span class="check c-box"></span>
                  </label>
                </div>
              </div>

              <div class="button-container">
                <loading-button
                  :disabled="!agreed"
                  lb-class="nv-button-blue-4 a-button-size"
                  @lb-click="submit"
                  name="terms-of-service-button">
                  Submit
                </loading-button>
              </div>
            </form>

            <div v-else>
              <div class="size-text-13px semibold-weight">
                Another user at your company has already accepted our Terms of Use and granted your team access to Feature Lab. Please log in to access Feature Lab.
              </div>

              <div class="button-container">
                <a href="/sign-in" class="nv-button-blue-4 smaller" target="_self">Sign In</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from 'vue-app/shared/components/loading-button.vue';

export default {
  name: 'ExistingUserTermsOfServiceForm',

  components: {
    LoadingButton
  },

  props: {
    termsOfServiceRequired: {
      type: Boolean,
      required: true
    },

    termsOfServiceUrl: {
      type: String,
      required: false
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      agreed: false
    };
  },

  methods: {
    submit() {
      if (!this.agreed) { return; }
      this.onSubmit();
    }
  }
};
</script>

<style lang="scss" scoped>
  .checkbox-container {
    margin: 0 auto;
    max-width: 425px;
  }

  .button-container {
    margin: 30px auto 0;
    width: 150px;
  }
</style>
