<template>
  <div class="shadowed-box">
    <div class="box-header compact text-uppercase">
      Expired Malpractice Insurance
    </div>

    <div class="box-content dash-widget">
      <loading-section name="expiredMalpracticeInsurance">
        <div class="size-text-13px" v-if="lawyers.length">
          <table class="table auto-height no-margin">
            <tbody>
              <tr v-for="lawyer in lawyers" :key="lawyer.id">
                <td><a :href="`/admin-app/lawyers/${lawyer.id}`">{{ lawyer.fullName }}</a></td>
                <td>{{ lawyer.malpracticeInsurancePolicy.expires }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="gray-text" v-else>
          None Expired
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

export default {
  name: 'MalpracticeExpired',

  components: {
    LoadingSection
  },

  props: {
    lawyers: {
      type: Array,
      required: true
    }
  }
};
</script>
