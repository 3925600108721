<template>
  <div class="titlebar-with-sections split-50-50">
    <div class="titlebar">
      <div class="bottom-5">
        <div class="vertical-center">
          <div v-if="counselRequest.scoutRfqId" class="scout-rfp-badge right-10">
            Scout
          </div>

          <div class="medium-page-heading text-ellipsis">
            {{ name }}
          </div>
        </div>
      </div>

      <div class="size-text-13px text-ellipsis">
        <span class="right-20"><span class="semibold-weight">Admin ID:</span> {{ counselRequest.id }}</span>

        <span class="right-20">&#8231;</span>

        <span class="semibold-weight">Status:</span> <span :class="statusTextClass">{{ displayStatus }}</span>
      </div>
    </div>

    <div class="titlebar-switcher text-center">
      <a href="" :id="tabId(tabLabels[tabKey])" :class="['less-padded', { 'selected': isSelected(tabKey) }]" @click.prevent="selectTab(tabKey)" v-for="tabKey in tabs" :key="tabKey">{{ tabLabels[tabKey] }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestTitlebar',

  props: {
    counselRequest: {
      type: Object,
      required: true
    },

    tabs: {
      type: Array,
      required: true
    },

    tab: {
      type: String,
      required: false
    },

    onTabChanged: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      tabLabels: {
        'request': 'Request',
        'notes': 'Notes',
        'search': 'Search',
        'opportunity': 'Opportunity',
        'proposal': (this.counselRequest.scoutRfqId ? 'Quote' : 'Proposal')
      },

      currentTab: this.tab || 'request'
    };
  },

  computed: {
    displayStatus: function () {
      return this.counselRequest.adminDisplayStatus();
    },

    name: function () {
      return this.counselRequest.nameForLawyersReference;
    },

    isNew: function () {
      return this.counselRequest.newStatus();
    },

    isAdminClosed: function () {
      return this.counselRequest.adminClosed();
    },

    statusTextClass: function () {
      return this.isNew || this.isAdminClosed ? 'red-text' : 'green-text';
    }
  },

  methods: {
    tabId(name) {
      return name.toLowerCase() + '-link';
    },

    isSelected: function (tab) {
      return tab === this.currentTab;
    },

    selectTab: function (tab) {
      if (!this.isSelected(tab)) {
        this.currentTab = tab;
        this.onTabChanged(tab);
      }
    }
  }
};
</script>
<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .scout-rfp-badge {
    display: block;
    height: 22px;
    margin-top: 1px;
    border: 1px solid $high-match-score-blue;
    border-radius: $border-radius-small;
    padding: 3px 6px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 700;
    background-color: $high-match-score-blue;
    color: $white;
  }
</style>
