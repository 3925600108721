<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label :for="uniqueId" v-if="hasLabel">{{ label }}<span class="inline-help required" v-if="required && !disabled"> *</span></label>

    <div class="keyword-search-container typeahead-container">
      <input
        type="text"
        ref="typeaheadInput"
        :data-testid="inputId"
        :id="uniqueId"
        :name="uniqueName"
        :class="['form-control keyword-search-input', { 'has-error': errors.length, 'disabled': disabled }]"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="nope"
        @keydown.enter.prevent="addCustomValue">

      <div v-if="!hideInputIcon">
        <svg-icon v-if="showSearchIcon" name="search" class="base-icon keyword-search-icon"></svg-icon>
        <a href="" v-else @click.prevent="addCustomValue"><svg-icon name="plus-circle" class="base-icon keyword-search-icon"></svg-icon></a>
      </div>
    </div>

    <div class="error-text top-5" v-if="errors.length">
      {{ errors[0] }}
    </div>

    <typeahead
      v-model="model"
      :target="`#${uniqueId}`"
      :data="sanitizedOptions"
      :item-key="optionLabelKey"
      :force-select="true"
      :force-clear="false"
      @input="onTypeaheadInput">
    </typeahead>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { now, unescape } from 'lodash';

export default {
  name: 'TypeaheadInputAndDropdown',

  components: {
    SvgIcon,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    label: {
      type: String,
      required: false
    },

    inputId: {
      type: String,
      required: true
    },

    inputName: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false
    },

    hideInputIcon: {
      type: Boolean,
      default: false
    },

    rules: {
      type: [String, Object],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      required: true
    },

    optionLabelKey: {
      type: String,
      required: false
    },

    optionValueKey: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      model: null,
      showSearchIcon: true
    };
  },

  computed: {
    hasLabel() {
      return this.label && this.label.length;
    },

    uniqueId() {
      // NOTE: this is an attempt at breaking Chrome's autocomplete nonsense
      return `${this.inputId}-${now()}`;
    },

    uniqueName() {
      return this.inputName ? `${this.inputName}${now()}` : null;
    },

    required() {
      return this.rules?.includes('required');
    },

    sanitizedOptions() {
      return this.options.map((option) => {
        if (this.optionLabelKey) {
          option[this.optionLabelKey] = this.$sanitize(option[this.optionLabelKey], { textFilter: text => unescape(text) });
        }
        return this.optionLabelKey ? option : this.$sanitize(option, { textFilter: text => unescape(text) });
      });
    }
  },

  methods: {
    getInputText() {
      return this.$refs.typeaheadInput.value;
    },

    onTypeaheadInput() {
      if (typeof this.model === 'undefined') {
        this.showSearchIcon = false;
        return;
      }

      this.$emit('select', (this.optionValueKey ? this.model[this.optionValueKey] : this.model));
      this.model = null;
      this.showSearchIcon = true;
    },

    addCustomValue() {
      // Use setTimeout to force this to happen after `onTypeaheadInput` if both triggered
      setTimeout(() => {
        const value = this.$refs.typeaheadInput.value;

        if (typeof value === 'undefined' || value === '' || typeof this.model !== 'undefined') { return; }

        this.$emit('add-value', value);
        this.model = null;
        this.showSearchIcon = true;
      }, 1);
    },

    reset() {
      this.model = null;
      this.showSearchIcon = true;
    }
  }
};
</script>
