<template>
  <div>
    <div class="tiny-header-text bottom-10">
      E-Billing
    </div>

    <div v-if="workspaceHasBillingSystem">
      <div class="list-spacing-5" v-for="system in workspace.billingSystems" :key="system.id">
        {{ system.name }}
      </div>
    </div>

    <div class="gray-text size-text-16px" v-else>
      No billing system registered
    </div>

    <div class="top-15" v-if="!workspaceHasBillingSystem & permittedToConfigure">
      <loading-button name="billing-systems" class="pseudo-link-blue" @lb-click="toggleDropdown()">Add Billing System</loading-button>

      <div class="contain-dropdown top-10" v-if="showDropdown">
        <loading-section name="billing-systems">
          <form role="form" class="a-form" novalidate>
            <dropdown-select
              :options="billingSystems"
              id-label="billing-system-dropdown"
              label-key="name"
              @change="selectSystem">
            </dropdown-select>
          </form>
        </loading-section>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton  from 'vue-app/shared/components/loading-button.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';

import BillingSystem         from 'resources/billing-system.js';
import LoadingService        from 'vue-app/shared/services/loading-service';
import NotificationService   from 'vue-app/shared/services/notification-service';
import BillingSystemRegistry from 'resources/billing-system-registry';

import { clone       } from 'lodash';
import { scrollToTop } from 'misc/ui-helpers.js';

export default {
  name: 'WorkspaceBillingSystemConfig',

  components: {
    LoadingButton,
    LoadingSection,
    DropdownSelect
  },

  props: {
    workspace: {
      type:     Object,
      required: true
    },

    permittedToConfigure: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showDropdown: false,
      billingSystems: null
    };
  },

  computed: {
    workspaceHasBillingSystem() {
      return this.workspace.billingSystems.length > 0;
    }
  },

  methods: {
    toggleDropdown() {
      if (this.billingSystems) {
        this.showDropdown = !this.showDropdown;
      }
      else {
        LoadingService.loading('billing-systems');

        BillingSystem.query({ view: 'base' }).then((systems) => {
          this.showDropdown    = !this.showDropdown;
          this.billingSystems = systems;
          LoadingService.done('billing-systems');
        });
      }
    },

    selectSystem(system) {
      if (confirm(`Are you sure you want to select ${system.name} as the E-Billing system for this team? This selection cannot be changed or removed once complete.`)) {
        BillingSystemRegistry.save({ workspace_id: this.workspace.id, billing_system_id: system.id, view: 'base' })
          .then(this.notifySuccess)
          .catch(this.notifyFailure);
      }

      this.showDropdown = false;
    },

    notifySuccess(registry) {
      const system = this.billingSystems.find(sys => sys.id === registry.billingSystemId);
      this.workspace.billingSystems.push(clone(system));

      NotificationService.success(`Billing System added to ${this.workspace.name}.`);
      scrollToTop();
    },

    notifyFailure() {
      NotificationService.error('Failed to add billing system. Please try again.');
      scrollToTop();
    }
  }
};
</script>
