<template>
  <div>
    <div class="small-bold-header">
      Bar Admissions
    </div>

    <hr class="margin-top-5">

    <div class="flex" v-for="(barAdmission, index) in barAdmissions" :key="`barAdmission_${barAdmission.id}_${index}`">
      <div>
        <a href="" @click.prevent="removeBarAdmission(barAdmission, index)"><svg-icon name="minus-circle" class="base-icon remove-icon-spacing"></svg-icon></a>
      </div>

      <div class="row tight-columns width-95">
        <div class="col-sm-5 bottom-15">
          <typeahead-vertical
            label="Country"
            rules="required"
            v-model="barAdmission.country"
            @input="countrySelected(barAdmission)"
            :id="`bar-admission-country-${barAdmission.id}-${index}`"
            :initial-value="barAdmission.country"
            :input-name="`barAdmissionCountry_${barAdmission.id}_${index}`"
            :options="countries"
            :force-select="true">
          </typeahead-vertical>
        </div>

        <div class="col-sm-4 bottom-15">
          <typeahead-vertical
            label="State"
            v-model="barAdmission.state"
            :rules="stateRequired(barAdmission)"
            :disabled="!isUnitedStates(barAdmission.country)"
            :force-select="true"
            :id="`bar-admission-state-${barAdmission.id}-${index}`"
            :initial-value="barAdmission.state"
            :input-name="`barAdmissionState_${barAdmission.id}_${index}`"
            :options="stateNames"
            :placeholder="togglePlaceholder(barAdmission)">
          </typeahead-vertical>
        </div>

        <div class="col-sm-3 bottom-15">
          <dropdown-select
            label="Year"
            :id-label="`bar-admission-year-${barAdmission.id}-${index}`"
            :initial-label="dateToYear(barAdmission.admitDate)"
            :options="yearOptions"
            @input="admissionYearUpdate(barAdmission, $event)">
          </dropdown-select>
        </div>
      </div>
    </div>

    <div>
      <a href="" class="semibold-weight" @click.prevent="addBarAdmission">+ {{ barAdmissions.length ? 'Add Another' : 'Add Bar Admission' }}</a>
    </div>
  </div>
</template>

<script>
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';

export default {
  name: 'EditLawyerBarAdmissions',

  components: {
    TypeaheadVertical,
    DropdownSelect
  },

  props: {
    barAdmissions: {
      type: Array,
      required: true
    },

    barAdmissionsToDestroy: {
      type: Array,
      required: true
    },

    countries: {
      type: Array,
      required: true
    },

    stateNames: {
      type: Array,
      required: true
    }
  },

  computed: {
    yearOptions: function () {
      const thisYear = new Date().getFullYear();
      return this.range(thisYear, 1900);
    }
  },

  methods: {
    dateToYear: function (date) {
      return DateFilter.filter(date, { year: 'numeric', timeZone: 'UTC' });
    },

    addBarAdmission: function () {
      this.barAdmissions.push({ country: '', state: '', admitDate: '' });
    },

    removeBarAdmission: function (barAdmission, index) {
      if (index < 0) { return; }

      if (barAdmission.id) {
        this.barAdmissionsToDestroy.push({ id: barAdmission.id, '_destroy': true });
      }

      this.barAdmissions.splice(index, 1);
    },

    isUnitedStates: function (countryName) {
      return countryName === 'United States';
    },

    isValidCountry(country) {
      return this.countries.includes(country);
    },

    countrySelected: function (barAdmission) {
      let country = barAdmission.country;

      if (this.isValidCountry(country) && !this.isUnitedStates(country)) {
        barAdmission.state = '';
      }
    },

    stateRequired: function (barAdmission) {
      if (this.isUnitedStates(barAdmission.country)) {
        return 'required';
      }
      else {
        return null;
      }
    },

    togglePlaceholder: function (barAdmission) {
      let country = barAdmission.country;

      if (this.isValidCountry(country) && !this.isUnitedStates(country)) {
        return 'N/A';
      }
      else {
        return '';
      }
    },

    admissionYearUpdate: function (barAdmission, value) {
      barAdmission.admitDate = `${value}-02-02`;
    },

    range: function (start, end) {
      if (start === end) { return [start]; }
      return [start, ...this.range(start - 1, end)];
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
  }

  .width-95 {
    width: 95%;
  }

  .margin-top-5 {
    margin-top: 5px;
  }

  .remove-icon-spacing {
    margin-top: 40px;
    margin-right: 10px;

    @media (min-width: 768px) {
      margin-top: 38px;
    }
  }
</style>
