<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header text-uppercase">
      General
    </div>

    <div class="box-content">
      <display-rules-section-wrapper class="list-spacing-20" :data="lawyer.firmTitle">
        <div class="semibold-weight">
          Title
        </div>

        <div>
          {{ lawyer.firmTitle }}
        </div>
      </display-rules-section-wrapper>

      <display-rules-section-wrapper class="list-spacing-20" :data="lawyer.yearsExperience">
        <div class="semibold-weight">
          Years of Experience
        </div>

        <div>
          {{ yearsExperience }}
        </div>
      </display-rules-section-wrapper>

      <display-rules-section-wrapper :data="lawyer.rate">
        <div class="semibold-weight">
          Rate
        </div>

        <div>
          {{ lawyer.rate }}/hr
        </div>
      </display-rules-section-wrapper>
    </div>
  </div>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesSectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';

export default {
  name: 'GhostLawyerGeneral',

  components: {
    DisplayRulesSectionWrapper
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    }
  },

  computed: {
    yearsExperience() {
      return `${this.lawyer.yearsExperience} ${this.$pluralize('year', this.lawyer.yearsExperience)}`;
    },

    displayComponent() {
      const { firmTitle, rate, yearsExperience } = this.lawyer;

      return this.anyDataIsPresent([firmTitle, rate, yearsExperience]);
    }
  }
};
</script>
