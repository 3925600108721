<template>
  <div class="flex">
    <div class="text-italic right-10">
      Viewing as
    </div>

    <div class="uiv">
      <dropdown class="context-picker" menu-right>
        <btn type="info">Internal View</btn>
        <btn type="info" class="dropdown-toggle"><svg-icon name="caret-down" class="base-icon smaller"></svg-icon></btn>
        <template slot="dropdown">
          <li>
            <a href="" role="button" :class="{ 'selected': isSelectedView('internal') }" @click.prevent="selectView('internal')">Internal View</a>
          </li>

          <li>
            <a :href="genericViewUrl" role="button" :class="{ 'selected': isSelectedView('genericWorkspace') }" @click="selectView('genericClient')">Generic Client</a>
          </li>

          <li v-for="(lawyerBridge, index) in lawyerBridges" :key="`lawyerBridge-dropdown-${index}`">
            <a :href="`/scout-firm/clients/${lawyerBridge.workspaceId}/lawyers/${lawyerBridge.id}`" role="button" :class="{ 'selected': isSelectedView(lawyerBridge.workspaceName) }" @click="selectView('workspace')">{{ lawyerBridge.workspaceName }}</a>
          </li>
        </template>
      </dropdown>
    </div>
  </div>
</template>

<script>
import useViewingAsSwitcherStore from 'vue-app/stores/viewing-as-switcher';
import { mapActions } from 'pinia';

export default {
  name: 'LawyerViewingAsSwitcher',

  props: {
    genericViewUrl: {
      type:    String,
      required: false
    },

    lawyerBridges: {
      type:    Array,
      default: () => []
    }
  },

  data: function () {
    return {
      selectedView: 'internal'
    };
  },

  methods: {
    ...mapActions(useViewingAsSwitcherStore, ['setInitialViewUrl']),

    selectView: function (view) {
      this.selectedView = view;
      this.setInitialViewUrl(window.location.pathname);
    },

    isSelectedView: function (view) {
      return this.selectedView === view;
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex {
    align-items: center;
    display: flex;
    width: 100%;

    @media (min-width: 768px) {
      justify-content: flex-end;
    }
  }
</style>
