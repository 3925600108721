<template>
  <div class="row list-spacing-5">
    <div class="col-sm-6 col-md-5">
      <a :href="`/admin-app/law-firms/${firm.id}`" target="_blank">{{ firm.name }}</a>
    </div>

    <div class="col-sm-6 col-md-2 text-right-not-xs">
      <span class="green-text semibold-weight" v-if="firm.isActive">Active</span>
      <span class="dark-gray-text" v-else>Inactive</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoutFirm',

  props: {
    firm: {
      type: Object,
      required: true
    }
  }
};
</script>
