<template>
  <priori-modal ref="modal" :modal-id="`add-to-list-${resource.id}`">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">Add to List</a>
    </template>

    <template #title>
      Add To List
    </template>

    <form-manage-list
      :resource-lists="filteredResourceLists"
      :on-submit="manageList"
      :current-tab="currentTab">
    </form-manage-list>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import FormManageList from 'vue-app/scout/shared/form-manage-list.vue';
import useCurrentUserStore from 'vue-app/stores/current-user';
import { mapState } from 'pinia';

export default {
  name: 'ModalAddToList',

  components: {
    PrioriModal,
    FormManageList
  },

  props: {
    resourceLists: {
      type: Array,
      required: true
    },

    resource: {
      type: Object,
      required: true
    },

    onCreateList: {
      type: Function,
      required: true
    },

    onAddToList: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      currentTab: 'lawyer'
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentUser']),

    filteredResourceLists() {
      return this.resourceLists.filter((resourceList) => {
        return !resourceList.itemIds.includes(this.resource.id) && resourceList.createdById === this.currentUser.id;
      });
    }
  },

  methods: {
    manageList(list) {
      if (list.id) {
        return this.onAddToList(this.resource, list.id).then(this.closeModal);
      }
      else {
        list.itemIds = [this.resource.id];

        return this.onCreateList(list).then(this.closeModal);
      }
    },

    closeModal() {
      this.$refs.modal.closeModal();
    }
  }
};
</script>
