<template>
  <edit-modal title="Editing Leadership &amp; Billable Credit Initiatives" :input="firm" :on-save="save" :on-cancel="cancelEdit" @on-modal-open="openModal">
    <template #default="{ inputCopy: firmCopy }">
      <form role="form" name="form" class="a-form" novalidate>
        <loading-section name="profileTaggings">
          <div class="text-italic pill-gray-text size-text-12px">
            Please use the fields below to indicate your firm’s approach to staffing certain initiatives and certain billable credit rules.
          </div>

          <hr>

          <div class="separated-list-items">
            <div class="bold-weight bottom-10">
              Does your law firm provide billable credit for:
            </div>

            <div v-for="(displayName, internalName) in billableDisplayData" :key="internalName" class="row list-item">
              <div class="col-sm-7 left-15-not-xs">
                {{ displayName }}
              </div>

              <div class="col-xs-4 col-sm-2 top-10-xs" v-for="boolean in [true, false]" :key="internalName + boolean">
                <label class="check-option vertical">
                  <span class="normal-weight">{{ boolean ? 'Yes' : 'No' }}</span>
                  <input
                    type="radio"
                    :name="internalName"
                    :value="boolean"
                    :checked="tagPresent(internalName) == boolean"
                    @input="() => updateSelectedTags(internalName, boolean)">
                  <span class="check c-radio"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="separated-list-items">
            <div class="bold-weight bottom-10">
              Does your law firm have dedicated staff for a:
            </div>

            <div v-for="(displayName, internalName) in leadershipDisplayData" :key="internalName" class="row list-item">
              <div class="col-sm-7 left-15-not-xs">
                {{ displayName }}
              </div>

              <div class="col-xs-4 col-sm-2 top-10-xs" v-for="boolean in [true, false]" :key="internalName + boolean">
                <label class="check-option vertical">
                  <span class="normal-weight">{{ boolean ? 'Yes' : 'No' }}</span>
                  <input
                    type="radio"
                    :name="internalName"
                    :value="boolean"
                    :checked="tagPresent(internalName) == boolean"
                    @input="() => updateSelectedTags(internalName, boolean)">
                  <span class="check c-radio"></span>
                </label>
              </div>
            </div>
          </div>
        </loading-section>
      </form>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above or remove those entries from the form.
    </template>
  </edit-modal>
</template>

<script>
import { cloneDeep } from 'lodash';
import EditModal from '../edit-modal';
import ProfileTagging from 'resources/profile-tagging';
import LoadingService from 'vue-app/shared/services/loading-service';
import { pull, uniq, intersection } from 'lodash';
import { matchingArrayValues } from 'misc/resource-helpers';
import DataLock from 'resources/data-lock';

export default {
  name: 'EditFirmLeadership',

  components: {
    EditModal
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    billableDisplayData: {
      type: Object,
      required: true
    },

    leadershipDisplayData: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    tagPresent: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedIds:            [],
      billableTaggings:       [],
      leadershipTaggings:     [],
      originalBillableTags:   [],
      originalLeadershipTags: []
    };
  },

  computed: {
    taggingsWithIds() {
      return this.leadershipTaggings.concat(this.billableTaggings);
    }
  },

  methods: {
    resetForm() {
      this.selectedIds = this.firm.profileTaggingIds;
    },

    currentlySelectedLeadershipTags() {
      return intersection(this.selectedIds, this.leadershipTaggings.map(tag => tag.id));
    },

    currentlySelectedBillableTags() {
      return intersection(this.selectedIds, this.billableTaggings.map(tag => tag.id));
    },

    updateSelectedTags(internalName, boolean) {
      const tagId = this.taggingsWithIds.find(tag => {
        return tag.internalName === internalName;
      }).id;

      if (boolean && !this.selectedIds.includes(internalName)) {
        this.selectedIds.push(tagId);
      }
      else {
        pull(this.selectedIds, tagId);
      }
    },

    save() {
      const firmEdited = {
        ...this.firm,
        profileTaggingIds: uniq(this.selectedIds)
      };

      const flagsToSet = [];

      if (!matchingArrayValues(this.originalLeadershipTags, this.currentlySelectedLeadershipTags())) {
        flagsToSet.push('dedicated_staff');
      }

      if (!matchingArrayValues(this.originalBillableTags, this.currentlySelectedBillableTags())) {
        flagsToSet.push('billable_credit_initiatives');
      }

      this.onSave(firmEdited, () => {
        DataLock.save({
          dataLock: {
            flag:         flagsToSet,
            resourceId:   this.firm.id,
            resourceType: 'law_firm'
          }
        });
      });
    },

    cancelEdit() {
      this.resetForm();
    },

    openModal() {
      this.resetForm();

      if (this.taggingsWithIds.length === 0) {
        LoadingService.loading('profileTaggings');

        const billableCreditQuery = ProfileTagging.query({ category: 'sustainability', sub_category: 'billable_credit' }).then((response) => {
          this.billableTaggings = response;
          this.originalBillableTags = cloneDeep(this.currentlySelectedBillableTags());
        });

        const leadershipCreditQuery = ProfileTagging.query({ category: 'awards_and_certifications', sub_category: 'firm_leadership' }).then((response) => {
          this.leadershipTaggings = response;
          this.originalLeadershipTags = cloneDeep(this.currentlySelectedLeadershipTags());
        });

        Promise.all([billableCreditQuery, leadershipCreditQuery]).then(() => {
          LoadingService.done('profileTaggings');
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .separated-list-items {
    .list-item {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
</style>
