import { render, staticRenderFns } from "./input-with-tag-list.vue?vue&type=template&id=7bbfe528&scoped=true"
import script from "./input-with-tag-list.vue?vue&type=script&lang=js"
export * from "./input-with-tag-list.vue?vue&type=script&lang=js"
import style0 from "./input-with-tag-list.vue?vue&type=style&index=0&id=7bbfe528&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bbfe528",
  null
  
)

export default component.exports