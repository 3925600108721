<template>
  <div>
    <div class="bold-weight">
      Add Lawyers
    </div>

    <div class="bottom-10">
      Add lawyers to your quote.
    </div>

    <div class="flat-box" v-if="hasSuggestedLawyers">
      <div class="box-header">
        <div class="row vertical-center-not-xs">
          <div :class="hideRateOnFile ? 'col-sm-6' : 'col-sm-4'">
            Lawyer
          </div>

          <div class="col-sm-2 top-10-xs" v-if="!hideRateOnFile">
            Rate on File
            <svg-icon id="firm-tt" name="info" class="base-icon smaller left-5"></svg-icon>
            <tooltip target="#firm-tt" placement="bottom" custom-class="tooltip-white" text="This data reflects lawyer rates in the Client’s e-billing system."></tooltip>
          </div>

          <div class="col-sm-3 top-10-xs">
            Proposed Hourly Rate
            <span class="inline-help required" v-if="ratesAreRequired">*</span>
            <span v-else>(if applicable)</span>
          </div>
        </div>
      </div>

      <div class="box-content no-padding">
        <div class="lawyer-row" v-for="lawyer in orderedLawyers" :key="lawyer.formId">
          <quote-form-firm-suggested-lawyer
            :suggested-lawyer="lawyer"
            :rate-is-required="ratesAreRequired"
            :on-change="onChange"
            :on-remove="removeLawyer"
            :hide-rate-on-file="hideRateOnFile">
          </quote-form-firm-suggested-lawyer>
        </div>
      </div>
    </div>

    <div class="gray-panel pill-gray-text text-center" v-else>
      No additional lawyers added
    </div>

    <div class="top-10">
      <add-lawyers-modal
        title="Add Lawyer(s) to Quote"
        :selected-lawyer-bridge-ids="suggestedLawyerBridgeIds"
        :options-are-bridges="!quoteForFeatureLab"
        :on-load-lawyers="onLoadFirmLawyers"
        :on-add-lawyers="addLawyers"
        :is-connecting="isConnecting"
        :client-suggested-lawyers="clientSuggestedLawyers"
        :firm-suggested-lawyers="firmSuggestedLawyers">
      </add-lawyers-modal>
    </div>
  </div>
</template>

<script>
import QuoteFormFirmSuggestedLawyer from 'vue-app/scout/firm/rfqs/quote-form-firm-suggested-lawyer.vue';
import AddLawyersModal from 'vue-app/scout/shared/add-lawyers-modal.vue';
import { compact, map, orderBy } from 'lodash';

export default {
  name: 'QuoteFormFirmSuggestedLawyers',

  components: {
    QuoteFormFirmSuggestedLawyer,
    AddLawyersModal
  },

  props: {
    firmSuggestedLawyers: {
      type: Array,
      required: true
    },

    clientSuggestedLawyers: {
      type: Array,
      required: true
    },

    ratesAreRequired: {
      type: Boolean,
      default: true
    },

    onLoadFirmLawyers: {
      type: Function,
      required: true
    },

    onAddLawyers: {
      type: Function,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    onRemoveLawyer: {
      type: Function,
      required: true
    },

    isConnecting: {
      type: Boolean,
      default: false
    },

    quote: {
      type: Object,
      required: true
    }
  },

  computed: {
    orderedLawyers() {
      return orderBy(this.firmSuggestedLawyers, [
        'scoutLawyerBridge.lastName', 'scoutLawyerBridge.firstName', 'scoutLawyerBridge.fullName',
        'lawyer.lastName', 'lawyer.firstName', 'lawyer.fullName',
        'lawyerName'
      ]);
    },

    hasSuggestedLawyers() {
      return this.firmSuggestedLawyers.length > 0;
    },

    clientSuggestedLawyerBridgeIds() {
      return map(this.clientSuggestedLawyers, 'scoutLawyerBridgeId');
    },

    firmSuggestedLawyerBridgeIds() {
      return compact(map(this.firmSuggestedLawyers, 'scoutLawyerBridgeId'));
    },

    suggestedLawyerBridgeIds() {
      return this.clientSuggestedLawyerBridgeIds.concat(this.firmSuggestedLawyerBridgeIds);
    },

    quoteForFeatureLab() {
      return this.quote.workspace.featureLab;
    },

    hideRateOnFile() {
      return this.isConnecting || this.quoteForFeatureLab;
    }
  },

  methods: {
    addLawyers(lawyers) {
      this.onAddLawyers(lawyers);
    },

    removeLawyer(suggestedLawyer) {
      this.onRemoveLawyer(suggestedLawyer);
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .lawyer-row {
    padding: 15px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $medium-gray;
    }
  }
</style>
