<template>
  <div class="level-widget size-text-12px">
    <span class="vertical-middle">{{ label }}</span>

    <div class="indicator-group">
      <span :class="[{ 'filled': score >= 1 }, 'level-indicator']"></span>
      <span :class="[{ 'filled': score >= 2 }, 'level-indicator']"></span>
      <span :class="[{ 'filled': score >= 3 }, 'level-indicator']"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LevelWidget',

  props: {
    label: {
      type: String,
      required: true
    },

    score: {
      type: Number,
      default: 0
    }
  }
};
</script>
