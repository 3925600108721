let Droppable = {
  inserted: function (el, binding, _vnode) {
    el.addEventListener('dragover', function (e) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
      this.classList.add('dragover');
    }, false);

    el.addEventListener('dragenter', function () {
      this.classList.add('dragover');
    }, false);

    el.addEventListener('dragleave', function () {
      this.classList.remove('dragover');
    }, false);

    el.addEventListener('drop', function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.classList.remove('dragover');

      let draggedId = parseInt(e.dataTransfer.getData('text'));
      let droppedId = binding.value.id;

      const event = new CustomEvent('on-drop', { detail: { draggedId: draggedId, droppedId: droppedId } });

      el.dispatchEvent(event);
    }, false);
  }
};

export default Droppable;
