<template>
  <collapsable-space
    :fade-overflow="false"
    :max-height="88">
    <div class="d-flex wrap row-gap-8 col-gap-8 flex-center">
      <scout-tag
        v-for="scoutTag in orderedTags"
        :scout-tag="scoutTag"
        :key="scoutTag.id">
      </scout-tag>
    </div>
  </collapsable-space>
</template>

<script>
import CollapsableSpace from 'vue-app/shared/components/collapsable-space.vue';
import ScoutTag from './scout-tag.vue';
import { orderBy } from 'lodash';

export default {
  name: 'CollapsableScoutTagsList',

  components: {
    CollapsableSpace,
    ScoutTag
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    }
  },

  computed: {
    orderedTags() {
      return orderBy(this.scoutTags.filter(tag => tag.tagType === 'general'), 'position');
    }
  }
};
</script>
