<template>
  <div class="gray-lighter-panel">
    <div class="row tight-columns vertical-center-lg bottom-20">
      <div :class="[{ 'col-xs-12': !editingAllowed, 'col-md-7 col-lg-8': editingAllowed }, 'dark-gray-text size-text-12px text-italic']">
        Last Updated {{ document.updatedAt }} by {{ document.updatedBy }}
      </div>

      <div v-if="editingAllowed" class="col-md-5 col-lg-4 edit-buttons col-gap-15">
        <edit-document-modal
          modal-title="Edit Document"
          :document="document"
          :on-save="save">
        </edit-document-modal>

        <priori-modal title="Delete Document">
          <template #modal-trigger="{ openModal }">
            <a href="" @click.prevent="openModal"><svg-icon name="trash" class="delete-btn base-icon"></svg-icon></a>
          </template>

          <template #default="{ closeModal }">
            <div class="size-text-14px bottom-30">
              Are you sure that you want to delete this document?
            </div>

            <div class="row tight-columns">
              <div class="col-sm-6">
                <loading-button
                  name="deleteDocument"
                  lb-class="primary-btn-red"
                  @lb-click="deleteDocument">
                  Delete Document
                </loading-button>
              </div>

              <div class="col-sm-3 top-20-xs">
                <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
              </div>
            </div>
          </template>
        </priori-modal>
      </div>
    </div>

    <div v-if="document.name" class="bold-weight size-text-15px bottom-5">
      {{ document.name }}
    </div>

    <div class="bold-weight text-ellipsis">
      <a :href="document.file.url" target="_blank">{{ document.fileIdentifier }}</a>
    </div>
  </div>
</template>

<script>
import EditDocumentModal from 'vue-app/scout/client/law-firms/edit-document-modal.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'FirmDocument',

  components: {
    EditDocumentModal,
    PrioriModal,
    LoadingButton,
    SvgIcon
  },

  props: {
    document: {
      type: Object,
      required: true
    },

    editingAllowed: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: true
    },

    onDelete: {
      type: Function,
      required: true
    }
  },

  methods: {
    deleteDocument() {
      if (!this.editingAllowed) { return; }

      LoadingService.loading('deleteDocument');
      return this.onDelete(this.document.id).finally(() => {
        LoadingService.done('deleteDocument');
      });
    },

    save(editedDocument) {
      if (!this.editingAllowed) { return; }

      return this.onSave(editedDocument);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .base-icon.delete-btn {
    width: 12px;
    height: 12px;
    margin-bottom: 3px;
    fill: $k-blue;
  }

  .vertical-center-lg {
    @media (min-width: $screen-lg-min) {
      display: flex;
      align-items: center;
      min-height: 100%;
    }
  }

  .edit-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    @media (min-width: $screen-md-min) {
      justify-content: flex-end;
      margin-top: 0;
    }
  }

</style>
