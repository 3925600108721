<template>
  <validation-observer ref="clientInfo">
    <form role="form" name="invited-sign-up" class="a-form" autocomplete="nope" method="POST" novalidate>
      <div class="text-center bottom-30">
        <div class="size-text-24px bottom-10">
          Client Sign Up
        </div>

        <div class="size-text-13px dark-gray-text">
          Please complete the sign up process.
        </div>
      </div>

      <div class="bottom-20">
        <text-input-vertical label="Email Address" id="email" input-name="email" :value="client.email" disabled></text-input-vertical>
      </div>

      <div class="row">
        <div class="col-sm-6 bottom-20">
          <text-input-vertical label="First Name" id="fname" input-name="firstName" placeholder="First Name" rules="required" v-model="client.firstName"></text-input-vertical>
        </div>

        <div class="col-sm-6 bottom-20">
          <text-input-vertical label="Last Name" id="lname" input-name="lastName" placeholder="Last Name" rules="required" v-model="client.lastName"></text-input-vertical>
        </div>
      </div>

      <div class="bottom-20">
        <text-input-vertical v-if="businessNameIsDisabled" label="Company Name" id="bname" input-name="businessName" :value="client.businessName" disabled></text-input-vertical>
        <text-input-vertical v-else label="Company Name" id="bname" input-name="businessName" placeholder="Company Name" v-model="client.businessName"></text-input-vertical>
      </div>

      <div v-if="!client.isScout">
        <div class="row">
          <div class="col-sm-8 bottom-20">
            <text-input-vertical input-type="text" label="Phone Number" id="phone" input-name="phoneNumber" placeholder="Phone Number" :rules="invitedToTeam ? '' : 'required'" v-model="client.phoneNumber"></text-input-vertical>
          </div>

          <div class="col-sm-4 bottom-20">
            <text-input-vertical input-type="text" label="Phone Extension" id="phone-ext" input-name="phoneExt" placeholder="Phone Extension" v-model="client.phoneExt"></text-input-vertical>
          </div>
        </div>
      </div>

      <password-setup-form :validate="submitted" :on-password-change="onPasswordChange"></password-setup-form>

      <div v-if="!client.isScout && termsOfServiceRequired">
        <div class="checkbox">
          <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
            <label>
              <input type="checkbox" id="agree" name="agreeToTerms" v-model="client.agreeToTerms">
              I have read and agree to the <a :href="termsOfServiceUrl" target="_blank">Terms &amp; Conditions</a> and <a href="https://www.priorilegal.com/privacy-policy-dmca-copyright-policy" target="_blank">Privacy Policy</a>
            </label>

            <div class="inline-help pr-error" v-if="errors.length">
              You must agree before signing up.
            </div>
          </validation-provider>
        </div>
      </div>

      <div class="row top-20">
        <div class="col-sm-6 col-sm-offset-3">
          <loading-button name="submitSignUp" lb-class="nv-button-purple-red a-button-size" @lb-click="submit">Submit</loading-button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PasswordSetupForm from 'vue-app/shared/components/password-setup-form.vue';

export default {
  name: 'ClientSignUpInvitedForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    PasswordSetupForm
  },

  props: {
    client: {
      type:     Object,
      required: true
    },

    invitedToTeam: {
      type:     Boolean,
      required: true
    },

    onSubmit: {
      type:     Function,
      required: true
    },

    termsOfServiceRequired: {
      type:     Boolean,
      default:  true
    },

    termsOfServiceUrl: {
      type:     String,
      default:  'https://www.priorilegal.com/terms-of-use'
    },

    businessNameIsPrefilled: {
      type:     Boolean,
      default:  false
    }
  },

  data() {
    return {
      submitted: false
    };
  },

  computed: {
    businessNameIsDisabled() {
      return (this.invitedToTeam && this.client.businessName.length) || this.businessNameIsPrefilled;
    }
  },

  methods: {
    submit() {
      this.submitted = true;

      this.$refs.clientInfo.validate().then((valid) => {
        if (valid) { this.onSubmit(); }
      });
    },

    onPasswordChange(password, passwordConfirmation) {
      this.client.password             = password;
      this.client.passwordConfirmation = passwordConfirmation;
    }
  }
};
</script>
