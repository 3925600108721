<template>
  <edit-modal title="Editing Law Firm Information" :input="firm" :on-save="save" :on-cancel="cancelEdit" v-if="canEditProfile">
    <template #modal-trigger="{ openModal: openLawFirmDetailModal }">
      <a href="" class="semibold-weight size-text-13px" @click.prevent="openLawFirmDetailModal">Edit</a>
    </template>

    <template #default="{ inputCopy: firmCopy }">
      <form role="form" name="form" class="a-form" novalidate>
        <div class="bottom-20">
          <text-input-vertical id="firm-name" name="firmName" label="Firm Name" autocomplete="nope" rules="required" v-model="firmCopy.name"></text-input-vertical>
        </div>

        <dropdown-select
          ref="firmSizeDropdown"
          label="Law Firm Size"
          id-label="law-firm-size"
          :initial-label="firm.firmSize"
          :options="firmSizes"
          v-model="firmCopy.firmSize">
        </dropdown-select>
      </form>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import SharedListService from 'vue-app/shared/services/shared-lists-service.js';
import DataLock from 'resources/data-lock';

export default {
  name: 'EditFirmDetails',

  components: {
    EditModal,
    TextInputVertical,
    DropdownSelect
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  computed: {
    firmSizes() {
      return SharedListService.firmSizes;
    }
  },

  methods: {
    save(editedFirm) {
      const vueInstance = this;

      LoadingService.loading('firm-details');

      if (vueInstance.firm.firmSize !== editedFirm.firmSize || vueInstance.firm.name !== editedFirm.name) {
        vueInstance.onSave(editedFirm, ()=> {
          LoadingService.done('firm-details');

          DataLock.save({
            dataLock: {
              flag:         this.getChangedFlags(vueInstance.firm, editedFirm),
              resourceId:   vueInstance.firm.id,
              resourceType: 'law_firm'
            }
          });
        });
      }
      else {
        LoadingService.done('firm-details');
      }
    },

    hasValueChanged(originalValue, editedValue) {
      return originalValue !== editedValue;
    },

    getChangedFlags(originalFirm, editedFirm) {
      const flags = [];

      if (this.hasValueChanged(originalFirm.firmSize, editedFirm.firmSize)) {
        flags.push('firm_size');
      }

      if (this.hasValueChanged(originalFirm.name, editedFirm.name)) {
        flags.push('name');
      }

      return flags;
    },

    cancelEdit() {
      this.$refs.firmSizeDropdown.reset();
    }
  }
};
</script>
