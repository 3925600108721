<template>
  <span :class="['selection-badge', variant]"><svg-icon :name="iconName" class="base-icon right-10"></svg-icon>{{ selectionText }}</span>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'SelectionBadge',

  components: {
    SvgIcon
  },

  props: {
    variant: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      variants: ['selected', 'declined']
    };
  },

  computed: {
    iconName() {
      return {
        'selected': 'checkmark',
        'declined': 'x-circle'
      }[this.variant];
    },

    selectionText() {
      return {
        'selected': 'Quote Selected',
        'declined': 'Quote Declined'
      }[this.variant];
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .selection-badge {
    display: inline-block;
    padding: 11px 15px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 20px;
    border-radius: $border-radius-base;

    &.selected {
      color: $scout-green;
      background-color: rgba($scout-green, .1);

      .base-icon {
        fill: $scout-green;
      }
    }

    &.declined {
      color: $scout-red;
      background-color: rgba($scout-red, .1);

      .base-icon {
        fill: $scout-red;
      }
    }
  }
</style>
