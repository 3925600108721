<template>
  <div :id="`answer-${quote.id}-${question.id}`" class="answer-text" ref="answer">
    <div v-if="hasAnswer">
      <div v-if="!isFileUploadQuestion">
        {{ answerValue }}
      </div>

      <div v-else>
        <ul class="compact">
          <li class="list-spacing-2 bold-weight" v-for="attachment in answer.attachments"><a :href="attachment.url" target="_blank" rel="noopener">{{ attachment.name }}</a></li>
        </ul>
      </div>
    </div>

    <div v-else class="missing-text">
      {{ noAnswerText }}
    </div>
  </div>
</template>

<script>
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import { compact, startCase } from 'lodash';

export default {
  name: 'AnswerCell',

  props: {
    quote: {
      type: Object,
      required: true
    },

    question: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      noAnswerText: 'No answer provided'
    };
  },

  computed: {
    answer() {
      return this.quote.answers.find(a => a.scoutRfqQuestionId === this.question.id);
    },

    hasAnswer() {
      return (this.isFileUploadQuestion && this.hasAttachments) ||
        this.answerValue !== this.noAnswerText;
    },

    isFileUploadQuestion() {
      return this.question.questionType === 'file_upload';
    },

    answerValue() {
      if (!this.answer) { return this.noAnswerText; }

      switch (this.question.questionType) {
        case 'text':
          return this.textAnswer;
        case 'range':
          return this.rangeAnswer;
        case 'option':
          return this.optionAnswer;
        default:
          return this.noAnswerText;
      }
    },

    textAnswer() {
      return this.answer.value || this.noAnswerText;
    },

    rangeAnswer() {
      if (!this.answer.value?.length > 0 || this.answer.value === null) { return this.noAnswerText; }

      return compact([
        CurrencyFilter.filter(this.answer.value[0]),
        CurrencyFilter.filter(this.answer.value[1])
      ]).join(' - ');
    },

    optionAnswer() {
      return this.answer.value ? startCase(this.answer.value) : this.noAnswerText;
    },

    attachments() {
      return this.answer?.attachments || [];
    },

    hasAttachments() {
      return this.attachments.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  .answer-text {
    height: 100%;
    overflow: hidden;
  }
</style>
