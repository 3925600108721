<template>
  <div>
    <rating-circle-with-range class="center-block bottom-20" :rating="reviewScore" :max-rating="10" :range-min="rangeMin" :range-max="rangeMax" :comp-score="compScore" :gradient-color="gradientColor">
      <div v-if="hasReviews">
        <span class="size-text-32px">{{ reviewScore }}</span>
        <span class="size-text-18px dark-gray-text">/10</span>
      </div>

      <div v-else class="size-text-32px">
        N/A
      </div>
    </rating-circle-with-range>

    <chart-legend
      :review-type="reviewType"
      :score="reviewScore"
      :range-min="rangeMin"
      :range-max="rangeMax"
      :comp-score="compScore"
      :gradient-color="gradientColor">
    </chart-legend>
  </div>
</template>

<script>
import RatingCircleWithRange from 'vue-app/scout/shared/rating-circle-with-range.vue';
import ChartLegend from './reviews-average-score-with-range-legend.vue';

export default {
  name: 'ReviewsAverageScoreWithRange',

  components: {
    RatingCircleWithRange,
    ChartLegend
  },

  props: {
    reviewsSummary: {
      type: Object,
      required: true
    },

    compSummary: {
      type: Object,
      required: true
    },

    reviewType: {
      type: String,
      required: true,
      validator: (value) => { return ['Law Firm', 'Lawyer'].includes(value); }
    },

    gradientColor: {
      type: String,
      default: null
    }
  },

  computed: {
    recommendationField() {
      return this.reviewsSummary.scoreAverages.find((score) => {
        return score.field === 'recommendation';
      }) || {};
    },

    reviewScore() {
      return this.recommendationField.score || 0;
    },

    rangeMin() {
      return this.recommendationField.min || 0;
    },

    rangeMax() {
      return this.recommendationField.max || 0;
    },

    hasReviews() {
      return this.reviewsSummary.count > 0 && this.reviewScore;
    },

    compScores() {
      return this.compSummary.scoreAverages;
    },

    compScore() {
      return this.compScores.find(score => score.field === 'recommendation')?.score || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  .center-block {
    left: -5px;
  }
</style>
