<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-7 text-uppercase">
          Weekly Review Reminder Emails
        </div>

        <div class="col-sm-5 text-right-not-xs">
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            Allow Weekly Review Reminder Emails
          </div>

          <div class="dark-gray-text">
            When this setting is ON, users will be able to receive weekly email notifications with reminders to leave reviews. When this setting is OFF, users will not be able to receive weekly review reminder emails.
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <span data-testid="allow-emails">{{ allowEmails ? "On" : "Off" }}</span>
        </div>
      </div>

      <hr>

      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            Allow Users to Turn Off Weekly Review Reminders
          </div>

          <div class="dark-gray-text">
            When this setting is ON, users will be able to choose whether or not they wish to receive weekly review reminder emails. When this setting is OFF, users will not have the ability to turn off weekly review reminder emails.
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <span data-testid="allow-disable-emails">{{ allowDisableEmails ? "On" : "Off" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReviewReminderEmailSettings',

  props: {
    allowEmails: {
      type: Boolean,
      required: true
    },

    allowDisableEmails: {
      type: Boolean,
      required: true
    }
  }
};
</script>
