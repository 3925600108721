<template>
  <div>
    <label v-if="label">{{ label }}</label>

    <dropdown ref="dropdown" tag="div" :id="idLabel" class="k-dropdown" :disabled="isDisabled" v-model="dropdownOpen">
      <button type="button" role="button" :id="`k-toggle-button-${idLabel}`" :class="['dropdown-toggle', { 'placeholder': showPlaceholder, 'invalid': invalid, 'disabled': isDisabled }]">
        <span v-if="showPlaceholder">{{ placeholder }}</span>
        <span v-if="!showPlaceholder" :class="['text-capitalize', 'semibold-weight', visibleLabel, wrapperClass]">{{ visibleLabel }}</span>
        <svg-icon name="caret-down" class="caret-down"></svg-icon>
        <svg-icon name="caret-up" class="caret-up"></svg-icon>
      </button>

      <template #dropdown>
        <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
          <li role="menuitem" class="menu-item empty" @click="onSelect(null)" v-if="allowNull">
            <div class="dropdown-row-inner">
              &nbsp;
            </div>
          </li>

          <li role="menuitem" class="menu-item" v-for="color in colors" :key="color">
            <div class="dropdown-row-inner">
              <label class="check-option">
                <span :class="['text-capitalize', color, wrapperClass]">{{ color }}</span>
                <input type="radio" name="color" :id="`color-${color}-${inputId}`" :value="color" v-model="selectedColor" @change="onSelect(color)">
                <span class="check c-radio"></span>
              </label>
            </div>
          </li>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import uivScrollableDropdown from 'src/vue-app/shared/directives/uiv-scrollable-dropdown.js';
import SvgIcon from 'src/vue-app/shared/components/svg-icon.vue';
import { uniqueId } from 'lodash';

export default {
  name: 'DropdownSelectColor',

  components: {
    SvgIcon
  },

  directives: {
    uivScrollableDropdown
  },

  props: {
    label: {
      type: String,
      required: false
    },

    colors: {
      type: Array,
      default: () => []
    },

    idLabel: {
      type: String,
      required: true
    },

    placeholder: {
      type: String,
      default: 'Select One'
    },

    initialLabel: {
      type: String,
      default: null
    },

    isDisabled:  {
      type: Boolean,
      default: false
    },

    allowNull: {
      type: Boolean,
      default: false
    },

    invalid: {
      type: Boolean,
      default: false
    },

    wrapperClass: {
      type: String,
      default: 'scout-tag'
    }
  },

  data: function () {
    return {
      dropdownOpen: false,
      hasSelected: false,
      selectedColor: null,
      inputId: uniqueId()
    };
  },

  computed: {
    visibleLabel: function () {
      return this.hasSelected ? this.selectedColor : this.initialLabel;
    },

    showPlaceholder: function () {
      return !this.hasSelected && !this.initialLabel;
    }
  },

  watch: {
    dropdownOpen: function () {
      if (!this.dropdownOpen) {
        this.$emit('blur');
      }
    }
  },

  methods: {
    onSelect: function (selectedColor) {
      this.hasSelected = true;
      this.selectedColor = selectedColor;

      this.$emit('input', selectedColor);
      this.$emit('change', selectedColor);
    },

    reset: function () {
      this.hasSelected = false;
      this.selectedColor = null;
    }
  }
};
</script>

<style lang="scss" scoped>
  .k-dropdown {
    .dropdown-toggle {
      @media (min-width: 768px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .dropdown-menu {
      .menu-item-container {
        padding: 8px 0;
      }

      .menu-item {
        &:hover {
          background-color: transparent;
          cursor: default;
        }

        &.empty:hover {
          cursor: pointer;
        }

        .dropdown-row-inner {
          border-bottom: 0;
          padding: 8px 0;
        }
      }
    }
  }
</style>
