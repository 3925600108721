import BaseResource from 'resources/base-resource';

class Ethnicity extends BaseResource {
  static baseUrl = '/ethnicities/:id.json';

  static sortForDisplay(ethnicities) {
    return ethnicities.sort((a, b) => {
      if (a.internalName === 'prefer_not_to_disclose') {
        return 1;
      }

      if (b.internalName === 'prefer_not_to_disclose') {
        return -1;
      }

      return a.internalName.localeCompare(b.internalName);
    });
  }
}

export default Ethnicity;
