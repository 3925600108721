<template>
  <dropdown-menu>
    <li v-if="canRemove" class="menu-item" role="menuitem">
      <a href="" @click.prevent="remove"><svg-icon name="reject" class="base-icon"></svg-icon>Remove {{ firmOrLawyer }}</a>
    </li>

    <li v-if="canRetire" class="menu-item" role="menuitem">
      <a href="" @click.prevent="retire"><svg-icon name="reject" class="base-icon"></svg-icon>Retire {{ firmOrLawyer }}</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a :href="`/admin-app/lawyers/${possibleLawyer.lawyerId}/proposal/${counselRequest.id}`" target="_blank"><svg-icon name="document" class="base-icon"></svg-icon>View {{ isLawFirm ? lawyer.fullName : 'Lawyer' }}</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a :href="`/admin-app/lawyers/${possibleLawyer.lawyerId}/projects/new?counselRequestId=${counselRequest.id}`" target="_blank" v-if="isRequesterClient"><svg-icon name="writing" class="base-icon"></svg-icon>Open Matter (Silent)</a>
    </li>
  </dropdown-menu>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProposalOptionsMenu',

  components: {
    SvgIcon
  },

  props: {
    counselRequest: {
      type: Object,
      required: true
    },

    possibleLawyer: {
      type: Object,
      required: true
    },

    isNewQuote: {
      type: Boolean,
      required: true
    },

    onRemove: {
      type: Function,
      required: true
    },

    onRetire: {
      type: Function,
      required: true
    }
  },

  computed: {
    lawyer() {
      return this.possibleLawyer.lawyer;
    },

    lawFirm() {
      return this.possibleLawyer.lawFirm || {};
    },

    isLawFirm() {
      return !!this.possibleLawyer.lawFirm;
    },

    firmOrLawyer() {
      return this.isLawFirm ? 'Firm' : 'Lawyer';
    },

    isRetired() {
      return this.possibleLawyer.retired;
    },

    isClosedRequest() {
      return this.counselRequest.adminClosed();
    },

    canRemove() {
      return this.isNewQuote && !this.isRetired && !this.isClosedRequest;
    },

    canRetire() {
      return !this.isNewQuote && !this.isRetired && !this.isClosedRequest;
    },

    isRequesterClient() {
      return this.counselRequest.requesterType === 'Client';
    }
  },

  methods: {
    remove() {
      if (!this.canRemove) { return; }
      this.onRemove();
    },

    retire() {
      if (!this.canRetire) { return; }
      this.onRetire();
    }
  }
};
</script>
