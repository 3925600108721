<template>
  <div :class="['name-plate photo-overflow-container', { 'small-plate': showSmallIcon }]">
    <svg-icon name="star-filled" :class="['favorite-icon', { 'favorited': firm.isFavorite }]"></svg-icon>

    <div :class="['relative-container inline-block right-10-not-xs', { 'small-container': showSmallIcon }]">
      <svg-icon name="law-firm" :class="['firm-icon', iconSize]"></svg-icon>
    </div>

    <div class="text-img-adjacent">
      <div class="size-text-17px text-ellipsis">
        <slot name="firm-name-element">
          <span :class="['target', { 'text-italic': italic || firm.isBillingFirm(), 'dark-gray-text': gray }]">{{ firm.name }}</span>
        </slot>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'FirmNamePlate',

  components: {
    SvgIcon
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    iconSize: {
      type: String,
      default: 'medium',
      validator: (value) => {
        return ['small', 'medium'].indexOf(value) !== -1;
      }
    },

    italic: {
      type: Boolean,
      default: false
    },

    gray: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showSmallIcon() {
      return this.iconSize === 'small';
    }
  }
};
</script>

<style lang="scss" scoped>
  .name-plate.small-plate {
    padding-top: 10px;
  }
</style>
