<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          {{ sectionTitle }}
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <a :href="editPath" class="size-text-13px normal-weight" target="_blank">Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row bottom-30" v-if="fromInteractive">
        <div class="col-sm-4 semibold-weight">
          Legal Professional
        </div>

        <div class="col-sm-8">
          {{ counselRequest.professionalType || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Years&rsquo; Experience
        </div>

        <div class="col-sm-8">
          {{ counselRequest.experienceYears || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30" v-if="fromInteractive && counselRequest.professionalType === 'Lawyer'">
        <div class="col-sm-4 semibold-weight">
          Bar Licenses
        </div>

        <div class="col-sm-8">
          {{ requiredBars || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30" v-if="fromInteractive">
        <div class="col-sm-4 semibold-weight">
          Practice Areas
        </div>

        <div class="col-sm-8">
          {{ practiceAreas || '&mdash;' }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Experience Required
        </div>

        <div class="col-sm-8">
          {{ counselRequest.experienceRequired() }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Other Experience
        </div>

        <div class="col-sm-8">
          {{ counselRequest.experienceOther || '&mdash;' }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Insurance Required?
        </div>

        <div class="col-sm-8">
          {{ counselRequest.insuranceNeeded() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestQualifications',

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  },

  computed: {
    editPath: function () {
      return `/law_simplified/counsel_requests/${this.counselRequest.id}/edit/#lawyer-qualifications`;
    },

    sectionTitle: function () {
      return this.fromInteractive ? 'Qualifications' : 'Lawyer Qualifications';
    },

    fromInteractive: function () {
      return this.counselRequest.fromInteractive();
    },

    practiceAreas: function () {
      if (!this.counselRequest.practiceAreas?.length) { return null; }

      return this.counselRequest.practiceAreas.join('; ');
    },

    requiredBars: function () {
      if (!this.counselRequest.requiredBars?.length) { return null; }

      return this.counselRequest.requiredBars.map(bar => (bar.state || bar.country)).join('; ');
    }
  }
};
</script>
