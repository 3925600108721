// This component has been implemented with the idea of having a password validation component that can be reused in the different forms where it is needed,
// it watches the "validate" prop to trigger validations when changes as a way to hook it's validations into angular's validation lifecycle.

// Once the forms that use this component are completely migrated to Vue, the fields it contains and all of its behavior should be moved to be directly there,
// eliminating the need to use the "validate" watched property and the complexity that it adds.

<template>
  <div :class="`row ${rowClass}`">
    <validation-observer tag="form" ref="passwordSetupForm">
      <div class="col-sm-6">
        <label for="password-confirmation">Password</label>

        <validation-provider vid="password" v-slot="{ errors, failedRules, changed }" :bails="false" rules="required|oneNumber|min:15|oneUppercaseChar|oneLowercaseChar|oneSpecialChar">
          <input type="password" label="Password" name="password" id="password" :class="['form-control', { 'has-error': errors.length && submitted }]" placeholder="Password" autocomplete="off" v-model="password" @input="passwordChanged()">

          <div class="error-text top-5" v-if="errors.length && submitted">
            <span v-if="password && password.length">* Password doesn&rsquo;t meet requirements</span>
            <span v-else>{{ errors[0] }}</span>
          </div>

          <div class="top-15 size-text-12px dark-gray-text semibold-weight">
            <div class="bottom-5">
              Password must have:
            </div>

            <ul>
              <li v-for="(constraint, rule) in rules" :key="rule" class="bottom-5">
                <svg-icon v-if="isValid(rule, failedRules) && changed" name="checkmark" class="base-icon success"></svg-icon>
                <svg-icon v-else name="exclamation" class="base-icon"></svg-icon>
                {{ constraint }}
              </li>
            </ul>
          </div>
        </validation-provider>
      </div>

      <div class="col-sm-6">
        <label for="password-confirmation">Confirm Password</label>

        <validation-provider vid="passwordConfirmation" v-slot="{ errors }" rules="required|confirmed:password">
          <input type="password" label="Password Confirmation" name="passwordConfirmation" id="password-confirmation" :class="['form-control', { 'has-error': errors.length && submitted }]" placeholder="Password Confirmation" autocomplete="off" v-model="passwordConfirmation" @input="passwordChanged()">

          <div class="error-text top-5" v-if="errors.length && submitted">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'PasswordSetupForm',

  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: {
    validate: {
      type: Boolean,
      default: false
    },

    onPasswordChange: {
      type: Function,
      required: true
    },

    rowClass: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      rules: {
        min: 'Minimum of 15 characters',
        oneNumber: 'At least one number',
        oneUppercaseChar: 'At least one uppercase character',
        oneLowercaseChar: 'At least one lowercase character',
        oneSpecialChar: 'At least one special character'
      },
      submitted: false,
      password: '',
      passwordConfirmation: ''
    };
  },

  watch: {
    validate: function () {
      this.submitted = true;
      this.passwordChanged();
    }
  },

  methods: {
    async passwordChanged() {
      const form = this.$refs.passwordSetupForm;

      await form.validate();

      const valid = Object.values(form.errors).every(errors => !errors.length);

      this.onPasswordChange(this.password, this.passwordConfirmation, valid);
    },

    isValid: function (rule, failedRules) {
      return !Object.keys(failedRules).includes(rule);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "stylesheets/globals/variables";
ul {
  list-style: none;
  padding-inline-start: 0;

  li {
    display: flex;
    column-gap: 7px;

    .base-icon {
      fill: $k-dark-gray;
      width: 16px;

      &.success {
        fill: $k-green;
      }
    }
  }
}
</style>
