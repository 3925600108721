<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-6 text-uppercase vertical-center">
          Matters by Practice Area
          <svg-icon v-if="toolTipText" id="matter-pa-tooltip" name="info" class="base-icon smaller left-5"></svg-icon>
          <tooltip target="#matter-pa-tooltip" placement="bottom" custom-class="tooltip-white" :text="toolTipText"></tooltip>
        </div>

        <div class="col-sm-6 size-text-12px normal-weight text-right-not-xs">
          <a href="" :class="['text-link right-20', { 'bold-weight': isActiveGraphic('distribution') }]" @click.prevent="setGraphic('distribution')">Total Matters</a>

          <a href="" :class="['text-link', { 'bold-weight': isActiveGraphic('dollarsSpent') }]" @click.prevent="setGraphic('dollarsSpent')">Dollars Spent</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <matter-distribution v-if="isActiveGraphic('distribution')" :datapoints="formattedPracticeAreas"></matter-distribution>

      <matter-dollars-spent v-if="isActiveGraphic('dollarsSpent')" :datapoints="formattedPracticeAreas"></matter-dollars-spent>
    </div>
  </div>
</template>

<script>
import MatterDistribution from 'vue-app/scout/client/law-firms/matter-distribution.vue';
import MatterDollarsSpent from 'vue-app/scout/client/law-firms/matter-dollars-spent.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'MatterGraphics',

  components: {
    SvgIcon,
    MatterDistribution,
    MatterDollarsSpent
  },

  props: {
    formattedPracticeAreas: {
      type:     Array,
      required: true
    }
  },

  data() {
    return { activeGraphic: 'distribution' };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    toolTipText() {
      return this.currentClient.workspace.scoutMattersByPaTooltipText;
    }
  },

  methods: {
    setGraphic(name) {
      this.activeGraphic = name;
    },

    isActiveGraphic(name) {
      return this.activeGraphic === name;
    }
  }
};
</script>
