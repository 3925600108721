<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Matching Criteria
    </div>

    <div class="box-content">
      <loading-section name="lawyerProfile">
        <div class="bottom-15">
          <div class="semibold-weight">
            Typical Client Size & Type
          </div>

          <div>
            {{ clientPreferenceString || '&mdash;' }}
          </div>
        </div>

        <div class="bottom-15">
          <div class="semibold-weight">
            Types of Projects
          </div>

          <div>
            {{ projectTypesString || '&mdash;' }}
          </div>
        </div>

        <div class="bottom-15">
          <div class="semibold-weight">
            Time Commitment
          </div>

          <div>
            {{ timeCommitmentString || '&mdash;' }}
          </div>
        </div>

        <div class="bottom-15">
          <div class="semibold-weight">
            Work Location
          </div>

          <div>
            {{ workLocationString || '&mdash;' }}
          </div>
        </div>

        <div class="bottom-15">
          <div class="semibold-weight">
            Fee & Rate Structures
          </div>

          <div>
            {{ feeStructureString || '&mdash;' }}
          </div>
        </div>

        <div>
          <div class="semibold-weight">
            Geographic Reach
          </div>

          <div>
            {{ geographicReachString || '&mdash;' }}
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import { compact, map } from 'lodash';

export default {
  name: 'AccountMatching',

  components: {
    LoadingSection
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    lawyerProfile: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      clientPreferences: {
        individualPreference: 'Individuals',
        startupPreference: 'Startups & High Growth Businesses',
        smBizPreference: 'Small Businesses',
        medBizPreference: 'Midsize Companies',
        largeBizPreference: 'Enterprise Companies',
        nonprofitPreference: 'Nonprofits'
      },

      timeCommitments: {
        fullTimePreference: 'Full-time',
        partTimePreference: 'Part-time',
        onDemandPreference: 'On-demand'
      },

      workLocations: {
        onsitePreference: 'On-site',
        remotePreference: 'Remote'
      },

      feeStructures: {
        perHourPreference: 'Per Hour',
        perDayPreference: 'Per Day',
        perWeekPreference: 'Per Week',
        perMonthPreference: 'Per Month',
        perYearPreference: 'Per Year',
        perDocumentPreference: 'Per Document',
        fixedPreference: 'Project-based Fee',
        contingencyPreference: 'Contingency/Success Fee'
      },

      geographicReach: {
        localPreference: 'Within Licensed Jurisdictions',
        nationalPreference: 'U.S. Matters Outside Jurisdictions',
        internationalPreference: 'International Matters'
      }
    };
  },

  computed: {
    projectTypes: function () {
      return SharedLists.projectTypes;
    },

    lawyerProjectTypes: function () {
      return this.lawyer.projectTypes;
    },

    clientPreferenceString: function () {
      return this.stringFromCollection(this.clientPreferences);
    },

    projectTypeLabels: function () {
      const labels = this.projectTypes.map((projectType) => (this.projectTypeLabel(projectType)));
      return compact(labels);
    },

    projectTypesString: function () {
      return this.projectTypeLabels.join(', ');
    },

    timeCommitmentString: function () {
      return this.stringFromCollection(this.timeCommitments);
    },

    workLocationString: function () {
      return this.stringFromCollection(this.workLocations);
    },

    feeStructureString: function () {
      return this.stringFromCollection(this.feeStructures);
    },

    geographicReachString: function () {
      return this.stringFromCollection(this.geographicReach);
    }
  },

  methods: {
    projectTypeLabel: function (projectType) {
      const field = projectType.slug + 'Preference';
      return this.lawyerProfile[field] ? projectType.label.replace(/\s\(.*\)/, '') : null;
    },

    stringFromCollection(collection) {
      const labels = map(collection, (value, key) => (this.lawyerProfile[key] ? value : null));
      return compact(labels).join(', ');
    }
  }
};
</script>
