<template>
  <boxed-content title="General" v-if="displayComponent">
    <template #links v-if="canEditProfile">
      <edit-modal title="Editing General" :input="lawyer" :on-save="save" :on-cancel="resetMetaDataToDestroy" @on-modal-open="editModalOpen">
        <template #default="{ inputCopy: lawyerCopy }">
          <div class="small-bold-header">
            Location
          </div>

          <hr class="margin-top-5">

          <div class="row tight-columns">
            <div class="col-sm-4 bottom-15">
              <typeahead-vertical
                id="lawyer-country"
                input-name="lawyerCountry"
                label="Country"
                rules="required"
                v-model="lawyerCopy.address.country"
                @input="countrySelected(lawyerCopy)"
                :initial-value="lawyerCopy.address.country"
                :force-select="true"
                :options="countries">
              </typeahead-vertical>
            </div>

            <div class="col-sm-4 bottom-15">
              <typeahead-vertical
                id="lawyer-state"
                input-name="lawyerState"
                label="State"
                v-model="lawyerCopy.address.state"
                :disabled="stateSelectionDisabled(lawyerCopy)"
                :initial-value="lawyerCopy.address.state"
                :force-select="true"
                :options="stateNames"
                :placeholder="togglePlaceholder(lawyerCopy)"
                :rules="stateRequired(lawyerCopy)">
              </typeahead-vertical>
            </div>

            <div class="col-sm-4 bottom-15">
              <text-input-vertical id="lawyer-city" name="lawyerCity" label="City" autocomplete="nope" rules="required" v-model="lawyerCopy.address.city"></text-input-vertical>
            </div>
          </div>

          <div class="small-bold-header top-15">
            Experience
          </div>

          <hr class="margin-top-5">

          <div class="row">
            <div class="col-xs-9 col-sm-4">
              <text-input-vertical
                input-type="number"
                label="Years of Experience"
                name="custYearsExperience"
                rules="required|minValue:0|maxValue:99"
                v-model="editableExperience"
                @input="updateCustomYearsExperience(lawyerCopy, $event)">
              </text-input-vertical>
            </div>
          </div>

          <div class="top-30">
            <edit-lawyer-languages
              :languages="lawyerCopy.languages"
              :languages-to-destroy="languagesToDestroy">
            </edit-lawyer-languages>
          </div>

          <div class="top-30">
            <edit-lawyer-bar-admissions
              :bar-admissions="lawyerCopy.barAdmissions"
              :bar-admissions-to-destroy="barAdmissionsToDestroy"
              :countries="countries"
              :state-names="stateNames">
            </edit-lawyer-bar-admissions>
          </div>

          <div class="top-30">
            <edit-lawyer-court-admissions
              :court-admissions="lawyerCopy.courtAdmissions"
              :court-admissions-to-destroy="courtAdmissionsToDestroy"
              :firm-courts="firmCourts">
            </edit-lawyer-court-admissions>
          </div>

          <div class="top-30">
            <edit-lawyer-jurisdictional-experience
              :jurisdictional-experience="lawyerCopy.jurisdictionalExperience"
              :jurisdictional-experience-to-destroy="jurisdictionalExperienceToDestroy">
            </edit-lawyer-jurisdictional-experience>
          </div>
        </template>

        <template #errorMessage>
          * One or more required fields are empty. Please fill in the required fields indicated above or remove those entries from the form.
        </template>
      </edit-modal>
    </template>

    <loading-section name="general">
      <div class="row tight-columns container-row">
        <display-rules-section-wrapper class="col-sm-6 bottom-30" :data="location" :editing-allowed="canEditProfile">
          <data-with-icon icon-name="location" title="Location">
            <display-rules-data-wrapper :data="location">
              {{ location }}
            </display-rules-data-wrapper>
          </data-with-icon>
        </display-rules-section-wrapper>

        <display-rules-section-wrapper class="col-sm-6 bottom-30" :data="lawyer.rate" :editing-allowed="canEditProfile" v-if="hasBillingData">
          <data-with-icon icon-name="rate" :title="rateTitle">
            <display-rules-data-wrapper :data="lawyer.rate">
              {{ lawyer.rate }}/hr
            </display-rules-data-wrapper>
          </data-with-icon>
        </display-rules-section-wrapper>

        <display-rules-section-wrapper class="col-sm-6 bottom-30" :data="experienceData" :editing-allowed="canEditProfile">
          <data-with-icon icon-name="experience" title="Experience">
            <display-rules-data-wrapper :data="experienceData">
              {{ displayedExperience }} Years
            </display-rules-data-wrapper>
          </data-with-icon>
        </display-rules-section-wrapper>

        <display-rules-section-wrapper class="col-sm-6 bottom-30" :data="lawyer.languages" :editing-allowed="canEditProfile">
          <data-with-icon icon-name="language" title="Languages">
            <display-rules-data-wrapper :data="lawyer.languages">
              {{ displayedLanguages }}
            </display-rules-data-wrapper>
          </data-with-icon>
        </display-rules-section-wrapper>

        <display-rules-section-wrapper class="col-sm-6 bottom-30" :data="lawyer.barAdmissions" :editing-allowed="canEditProfile">
          <data-with-icon icon-name="ribbon" title="Bar Admissions">
            <display-rules-data-wrapper :data="lawyer.barAdmissions">
              <div v-for="bar in lawyer.barAdmissions" :key="bar.id">
                {{ bar.state || bar.country }}<span v-if="bar.admitDate">, {{ dateToYear(bar.admitDate) }}</span>
              </div>
            </display-rules-data-wrapper>
          </data-with-icon>
        </display-rules-section-wrapper>

        <display-rules-section-wrapper class="col-sm-6 bottom-30" :data="lawyer.courtAdmissions" :editing-allowed="canEditProfile">
          <data-with-icon icon-name="court" title="Court Admissions">
            <display-rules-data-wrapper :data="lawyer.courtAdmissions">
              <div v-for="court in lawyer.courtAdmissions" :key="court.id">
                {{ court.courtName }}<span v-if="court.admitDate">, {{ dateToYear(court.admitDate) }}</span>
              </div>
            </display-rules-data-wrapper>
          </data-with-icon>
        </display-rules-section-wrapper>

        <display-rules-section-wrapper class="col-sm-6 bottom-30" :data="lawyer.jurisdictionalExperience" :editing-allowed="canEditProfile">
          <data-with-icon icon-name="globe" title="Jurisdictional Experience">
            <display-rules-data-wrapper :data="lawyer.jurisdictionalExperience">
              <div v-for="experience in lawyer.jurisdictionalExperience" :key="experience">
                {{ experience }}
              </div>
            </display-rules-data-wrapper>
          </data-with-icon>
        </display-rules-section-wrapper>
      </div>
    </loading-section>
  </boxed-content>
</template>

<script>
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import ScoutFirmCourt from 'resources/scout/scout-firm-court.js';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesSectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import { stateAbbreviationFromName } from 'vue-app/shared/misc/shared-lists/states';
import DateFilter from 'vue-app/shared/lib/date-filter';
import DataWithIcon from 'vue-app/shared/components/data-with-icon.vue';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import EditLawyerBarAdmissions from 'vue-app/scout/shared/lawyers/edit-lawyer-bar-admissions.vue';
import EditLawyerCourtAdmissions from 'vue-app/scout/shared/lawyers/edit-lawyer-court-admissions.vue';
import EditLawyerLanguages from 'vue-app/scout/shared/lawyers/edit-lawyer-languages.vue';
import EditLawyerJurisdictionalExperience from 'vue-app/scout/shared/lawyers/edit-lawyer-jurisdictional-experience.vue';
import DataLock from 'resources/data-lock';
import { changes } from 'misc/resource-helpers';
import { compact, includes, map } from 'lodash';

export default {
  name: 'LawyerGeneral',

  components: {
    DisplayRulesSectionWrapper,
    DisplayRulesDataWrapper,
    BoxedContent,
    DataWithIcon,
    EditModal,
    LoadingSection,
    TypeaheadVertical,
    TextInputVertical,
    EditLawyerBarAdmissions,
    EditLawyerCourtAdmissions,
    EditLawyerLanguages,
    EditLawyerJurisdictionalExperience
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:    Function,
      default: () => {}
    }
  },

  data() {
    return {
      states: SharedLists.listOfStateObjects(),
      countries: SharedLists.listOfCountries(),
      firmCourts: [],
      languagesToDestroy: [],
      barAdmissionsToDestroy: [],
      courtAdmissionsToDestroy: [],
      jurisdictionalExperienceToDestroy: [],
      editableExperience: null,
      lockables: {
        address:                  'location',
        languages:                'language',
        barAdmissions:            'bar_admissions',
        courtAdmissions:          'court_admissions',
        jurisdictionalExperience: 'jurisdictional_experience'
      }
    };
  },

  computed: {
    hasBillingData() {
      return this.lawyer.klass !== 'Lawyer' && this.lawyer.hasBillingData();
    },

    location() {
      if (!this.lawyer.address) { return null; }
      return compact([this.lawyer.address.city, this.stateOrCountry()]).join(', ');
    },

    displayedLanguages() {
      return map(this.lawyer.languages, 'name').join(', ');
    },

    rateTitle() {
      return this.lawyer.isLawyerViewing ? 'Rack Rate' : 'Rate';
    },

    displayedExperience() {
      return this.lawyer.customYearsExperience || this.lawyer.yearsExperience;
    },

    experienceData() {
      return (this.displayedExperience || 0) > 0 ? this.displayedExperience : null;
    },

    stateNames() {
      return this.states.map(stateObject => stateObject.name);
    },

    displayComponent() {
      const { barAdmissions, courtAdmissions, languages, rate, jurisdictionalExperience } = this.lawyer;

      return this.canEditProfile || this.anyDataIsPresent(
        [this.location, this.experienceData, barAdmissions, courtAdmissions, languages, rate, jurisdictionalExperience]
      );
    }
  },

  methods: {
    editModalOpen() {
      this.editableExperience = this.lawyer.customYearsExperience || this.lawyer.yearsExperience;

      if (!this.firmCourts.length) {
        LoadingService.loading('courts');

        ScoutFirmCourt.query().then(courts => {
          this.firmCourts = courts;
          LoadingService.done('courts');
        });
      }
    },

    updateCustomYearsExperience(lawyerCopy, value) {
      value = parseInt(value);

      if (lawyerCopy.yearsExperience !== value) {
        lawyerCopy.customYearsExperience = value;
      }
      else {
        lawyerCopy.customYearsExperience = null;
      }
    },

    stateOrCountry() {
      return this.lawyer.address.country === 'United States' ? stateAbbreviationFromName(this.lawyer.address.state) : this.lawyer.address.country;
    },

    dateToYear(date) {
      return DateFilter.filter(date, { year: 'numeric', timeZone: 'UTC' });
    },

    isValidCountry(country) {
      return this.countries.includes(country);
    },

    isUnitedStates(countryName) {
      return countryName === 'United States';
    },

    countrySelected(lawyerCopy) {
      let country = lawyerCopy.address.country;

      if (this.isValidCountry(country) && !this.isUnitedStates(country)) {
        lawyerCopy.address.state = '';
      }
    },

    stateRequired(lawyerCopy) {
      if (this.isUnitedStates(lawyerCopy.address.country)) {
        return 'required';
      }
      else {
        return null;
      }
    },

    stateSelectionDisabled(lawyerCopy) {
      return !this.isUnitedStates(lawyerCopy.address.country);
    },

    togglePlaceholder(lawyerCopy) {
      let country = lawyerCopy.address.country;

      if (this.isValidCountry(country) && !this.isUnitedStates(country)) {
        return 'N/A';
      }
      else {
        return '';
      }
    },

    save(editedLawyer) {
      const vueInstance = this;

      if (vueInstance.canEditProfile) {
        const changedKeys = Object.keys(changes(vueInstance.lawyer, editedLawyer));

        const flagsToSet = Object.keys(this.lockables).reduce((flags, key) => {
          if (includes(changedKeys, key)) {
            flags.push(this.lockables[key]);
          }
          return flags;
        }, []);

        LoadingService.loading('general');
        const cleanLanguages = editedLawyer.languages.filter(languageObject => languageObject.name.length > 1);
        const payload = {
          lawyerProfileAttributes: {
            id: editedLawyer.lawyerProfileId,
            customYearsExperience: editedLawyer.customYearsExperience,
            jurisdictionalExperience: editedLawyer.jurisdictionalExperience,
            addressesAttributes: editedLawyer.address,
            barAdmissionsAttributes: [...editedLawyer.barAdmissions, ...vueInstance.barAdmissionsToDestroy],
            languagesAttributes: [...cleanLanguages, ...vueInstance.languagesToDestroy],
            scoutFirmCourtAdmissionsAttributes: [...editedLawyer.courtAdmissions, ...vueInstance.courtAdmissionsToDestroy]
          }
        };

        vueInstance.onSave(payload,
          () => {
            vueInstance.resetMetaDataToDestroy();

            if (flagsToSet.length > 0) {
              DataLock.save({
                dataLock: {
                  flag:         flagsToSet,
                  resourceId:   vueInstance.lawyer.id,
                  resourceType: 'lawyer'
                }
              });
            }
            LoadingService.done('general');
          },
          () => {
            LoadingService.done('general');
          }
        );
      }
    },

    resetMetaDataToDestroy() {
      this.languagesToDestroy = [];
      this.barAdmissionsToDestroy = [];
      this.courtAdmissionsToDestroy = [];
    }
  }
};
</script>

<style lang="scss" scoped>
  .margin-top-5 {
    margin-top: 5px;
  }

  .container-row {
    margin-bottom: -30px;
  }
</style>
