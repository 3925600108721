<template>
  <edit-modal title="Editing D&I Statistics" :input="firm" :on-save="save" :on-cancel="cancelEdit" @on-modal-open="openModal">
    <template #default="{ inputCopy: firmCopy }">
      <loading-section name="ethnicities">
        <form role="form" name="form" class="a-form" novalidate>
          <div class="row tight-columns">
            <div class="col-sm-3 small-bold-header">
              D&I Statistics
            </div>

            <div class="col-sm-9">
              <div class="row tight-columns">
                <div class="col-sm-4 text-right-not-xs">
                  <label class="check-option">
                    All Lawyers
                    <input type="radio" name="lawyerGroup" v-model="editingAttribute" value="allLawyers">
                    <span class="check c-radio"></span>
                  </label>
                </div>

                <div class="col-sm-4 text-right-not-xs">
                  <label class="check-option">
                    Partners
                    <input type="radio" name="lawyerGroup" v-model="editingAttribute" value="partners">
                    <span class="check c-radio"></span>
                  </label>
                </div>

                <div class="col-sm-4 text-right-not-xs">
                  <label class="check-option">
                    Other Lawyers
                    <input type="radio" name="lawyerGroup" v-model="editingAttribute" value="otherLawyers">
                    <span class="check c-radio"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div v-if="isSelected('allLawyers')">
            <edit-firm-diversity-statistic :statistic="allLawyers" :di-statistic-extra="diStatisticExtra"></edit-firm-diversity-statistic>
          </div>

          <div v-if="isSelected('partners')">
            <edit-firm-diversity-statistic :statistic="partners" :di-statistic-extra="diStatisticExtra"></edit-firm-diversity-statistic>
          </div>

          <div v-if="isSelected('otherLawyers')">
            <edit-firm-diversity-statistic :statistic="otherLawyers" :di-statistic-extra="diStatisticExtra"></edit-firm-diversity-statistic>
          </div>
        </form>
      </loading-section>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above or remove those entries from the form.
    </template>
  </edit-modal>
</template>

<script>
import EditModal from '../edit-modal';
import Ethnicity from 'resources/ethnicity';
import EditFirmDiversityStatistic from './edit-firm-diversity-statistic';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'EditFirmDiversity',

  components: {
    EditModal,
    EditFirmDiversityStatistic
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    selectedCountAttribute: {
      type: String,
      default: 'allLawyers'
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      ethnicities: [],
      editingAttribute: 'allLawyers',
      allLawyers: {},
      partners: {},
      otherLawyers: {},
      diStatisticExtra: {}
    };
  },

  methods: {
    resetForm() {
      this.allLawyers = this.buildStatistic(this.firm.diStatistics.allLawyers, 0);
      this.partners = this.buildStatistic(this.firm.diStatistics.partners, 1);
      this.otherLawyers = this.buildStatistic(this.firm.diStatistics.otherLawyers, 2);
      this.diStatisticExtra = this.buildExtra(this.firm.diStatisticExtra);
    },

    isSelected(attribute) {
      return this.editingAttribute === attribute;
    },

    save() {
      let diStatistics = [];

      diStatistics.push(this.allLawyers);
      diStatistics.push(this.partners);
      diStatistics.push(this.otherLawyers);

      let firmEdited = {
        ...this.firm,
        diStatisticsAttributes: diStatistics,
        diStatisticExtraAttributes: { dataBackground: this.diStatisticExtra.dataBackground }
      };

      this.onSave(firmEdited);
    },

    cancelEdit() {
      this.resetForm();
    },

    buildExtra(diStatisticExtraParam) {
      let diStatisticExtra = {
        dataBackground: diStatisticExtraParam.dataBackground
      };
      return diStatisticExtra;
    },

    buildStatistic(statistic, type) {
      let ethnicityStatistics = [];

      if (statistic) {
        _.each(this.ethnicities, (ethnicity) => {
          let ethnicityStatistic = statistic.ethnicityStatistics.find(es => es.ethnicityId === ethnicity.id);

          if (ethnicityStatistic) {
            ethnicityStatistics.push({
              id: ethnicityStatistic.id,
              ethnicityId: ethnicity.id,
              scoutLawFirmDiStatisticId: statistic.id,
              name: ethnicity.name,
              countFemale: ethnicityStatistic.countFemale,
              countMale: ethnicityStatistic.countMale,
              countNonBinary: ethnicityStatistic.countNonBinary,
              countNotDisclosed: ethnicityStatistic.countNotDisclosed
            });
          }
          else {
            this.buildEmptyStatistic(ethnicityStatistics, ethnicity);
          }
        });

        return {
          id: statistic.id,
          statisticType: type,
          countLgbtq: statistic.countLgbtq,
          countWithDisabilities: statistic.countWithDisabilities,
          countVeterans: statistic.countVeterans,
          amlawDiversityRank: statistic.amlawDiversityRank,
          ethnicityStatisticsAttributes: ethnicityStatistics
        };
      }
      else {
        _.each(this.ethnicities, (ethnicity) => {
          this.buildEmptyStatistic(ethnicityStatistics, ethnicity);
        });

        return {
          statisticType: type,
          countLgbtq: null,
          countWithDisabilities: null,
          countVeterans: null,
          amlawDiversityRank: null,
          ethnicityStatisticsAttributes: ethnicityStatistics
        };
      }
    },

    buildEmptyStatistic(ethnicityStatistics, ethnicity) {
      ethnicityStatistics.push({
        ethnicityId: ethnicity.id,
        countFemale: 0,
        countMale: 0,
        countNonBinary: 0,
        name: ethnicity.name,
        countNotDisclosed: 0
      });
    },

    openModal() {
      this.editingAttribute = this.selectedCountAttribute;

      if (!this.ethnicities.length) {
        LoadingService.loading('ethnicities');

        Ethnicity.query().then((response) => {
          this.ethnicities = Ethnicity.sortForDisplay(response);

          this.resetForm();

          LoadingService.done('ethnicities');
        });
      }
      else {
        this.resetForm();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
    font-weight: 400;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
</style>
