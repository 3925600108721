import BaseResource from 'resources/base-resource';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import { filter, map } from 'lodash';

class AdminLawFirm extends BaseResource {
  static baseUrl = '/admin/law_firms/:id.json';

  selectedAccreditations() {
    const lawFirm = this;
    const options = SharedLists.rfpAccreditationOptions();
    const selected = filter(options, (o) => { return lawFirm.firmAccreditations[o.name]; });

    return map(selected, 'label');
  }
}

export default AdminLawFirm;
