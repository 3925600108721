<template>
  <edit-modal title="Editing Rankings, Awards &amp; Certifications" :input="firm" :on-save="save" @on-modal-open="openModal">
    <template #default>
      <div>
        <div class="bottom-30">
          <div class="header">
            Rankings
          </div>

          <div class="text-italic pill-gray-text size-text-12px bottom-15">
            Please use the type-ahead field below to select your law firm’s relevant rankings, and/or add additional rankings to the available list.
          </div>

          <div class="row vertical-center bottom-10">
            <div class=" col-xs-1 col-sm-1"></div>

            <div class="bold-weight col-xs-6 col-sm-9">
              Ranking Name
            </div>

            <div class="bold-weight col-xs-5 col-sm-2">
              Ranking
            </div>
          </div>

          <div v-for="(rankingData, index) in rankings" class="row vertical-center bottom-10" :key="`ranking-${index}`">
            <button class="col-xs-1 col-sm-1 button-reset" role="button" @click="removeRankingData(index)">
              <svg-icon name="minus-circle" class="base-icon"></svg-icon>
            </button>

            <div class="col-xs-6 col-sm-9">
              <typeahead-vertical
                v-model="rankingData.name"
                :initial-value="rankingData.name"
                :force-select="false"
                :options="rankingsOptions"
                :id="`ranking-${index}`"
                :rules="{ required: true, noDuplicate: { list: rankingsMappedByName }}">
              </typeahead-vertical>
            </div>

            <div class="col-xs-5 col-sm-2">
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="text" :class="['form-control', 'text-center', { 'invalid': errors.length }]" v-model="rankingData.ranking" @keypress="isNumber($event)">

                <error-message v-if="errors.length" class="error-text" :errors="errors"></error-message>
              </validation-provider>
            </div>
          </div>

          <button class="pseudo-link-blue semibold-weight top-10" role="button" @click.prevent="addRanking">{{ `+ Add ${rankings.length ? 'Another' : 'Ranking'}` }}</button>
        </div>

        <div class="bottom-30">
          <div class="header">
            Awards
          </div>

          <div class="text-italic pill-gray-text size-text-12px bottom-15">
            Please use the fields below to indicate your law firm’s relevant awards. Please include the name of the award won by your law firm. Example: "One of HRC’s Best Places to Work"
          </div>

          <field-group class="bottom-20" v-for="(award, index) in awards" :key="award.id" :on-remove-item="() => { removeAward(index) }" item-type="award">
            <template #input>
              <div class="bold-weight bottom-5">
                Awards Title
              </div>

              <tiptap-editor v-model="award.value" :buttons="['bulletList', 'orderedList']"></tiptap-editor>
            </template>
          </field-group>

          <a href="" @click.prevent="addAward" class="semibold-weight">{{ `+ Add ${awards.length ? 'Another' : 'Award'}` }}</a>
        </div>

        <div class="bottom-30">
          <div class="header">
            Certifications
          </div>

          <div class="text-italic pill-gray-text size-text-12px bottom-15">
            Please use the dropdown menu below to select your law firm’s relevant certifications.
          </div>

          <loading-section name="firm-ownership">
            <div class="row bottom-10">
              <div class="col-sm-6">
                <dropdown class="k-dropdown" v-model="firmOwnershipsDropdownOpen" :not-close-elements="elementsToNotCloseOn">
                  <button type="button" role="button" class="dropdown-toggle">
                    <span>Select all that apply</span>
                    <svg-icon name="caret-down" class="caret-down"></svg-icon>
                    <svg-icon name="caret-up" class="caret-up"></svg-icon>
                  </button>

                  <template #dropdown>
                    <div class="menu-item-container" v-uiv-scrollable-dropdown="firmOwnershipsDropdownOpen">
                      <li role="menuitem" v-for="profileTagging in firmOwnershipProfileTaggings" :key="profileTagging.id" ref="dropdown-li">
                        <label class="check-option vertical medium-large">
                          {{ profileTagging.presentedName }}
                          <input type="checkbox" :id="`firm-ownership-tagging-${profileTagging.id}`" :value="profileTagging.internalName" v-model="selectedFirmOwnerships">
                          <span class="check c-box"></span>
                        </label>
                      </li>
                    </div>
                  </template>
                </dropdown>
              </div>
            </div>

            <div v-if="selectedFirmOwnerships.length">
              <tag-list :tags="selectedFirmOwnerships" :value-fn="getFirmOwnershipPresentedName" :on-remove="removeFirmOwnership"></tag-list>
            </div>
          </loading-section>
        </div>

        <div class="bottom-30">
          <div class="header">
            Member &amp; Signatory
          </div>

          <div class="text-italic pill-gray-text size-text-12px bottom-15">
            Please use the dropdown menu below to select your law firm’s relevant memberships.
          </div>

          <loading-section name="firm-diversity">
            <div class="row bottom-10">
              <div class="col-sm-6">
                <dropdown class="k-dropdown" v-model="diversitiesDropdownOpen" :not-close-elements="elementsToNotCloseOn">
                  <button type="button" role="button" class="dropdown-toggle">
                    <span>Select all that apply</span>
                    <svg-icon name="caret-down" class="caret-down"></svg-icon>
                    <svg-icon name="caret-up" class="caret-up"></svg-icon>
                  </button>

                  <template #dropdown>
                    <div class="menu-item-container" v-uiv-scrollable-dropdown="diversitiesDropdownOpen">
                      <li role="menuitem" v-for="profileTagging in diversityProfileTaggings" :key="profileTagging.id" ref="dropdown-li">
                        <label class="check-option vertical medium-large">
                          {{ profileTagging.presentedName }}
                          <input type="checkbox" :id="`diversity-profile-tagging-${profileTagging.id}`" :value="profileTagging.internalName" v-model="selectedDiversities">
                          <span class="check c-box"></span>
                        </label>
                      </li>
                    </div>
                  </template>
                </dropdown>
              </div>
            </div>

            <div v-if="selectedDiversities.length">
              <tag-list :tags="selectedDiversities" :value-fn="getDiversityPresentedName" :on-remove="removeDiversity"></tag-list>
            </div>
          </loading-section>
        </div>
      </div>
    </template>

    <template #errorMessage>
      <div class="size-text-14px">Form is not valid. Please review the fields.</div>
    </template>
  </edit-modal>
</template>

<script>
import { compact, cloneDeep, filter, uniqueId } from 'lodash';
import { ValidationProvider } from 'vee-validate';
import EditModal from 'vue-app/scout/shared/edit-modal';
import LoadingService from 'vue-app/shared/services/loading-service';
import ProfileTagging from 'resources/profile-tagging';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import TagList from 'vue-app/scout/shared/tag-list';
import DataLock from 'resources/data-lock';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import FieldGroup from 'vue-app/scout/shared/field-group.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import { matchingArrayValues } from 'misc/resource-helpers';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';

export default {
  name: 'EditFirmRankingsAwardsCertifications',

  components: {
    EditModal,
    ErrorMessage,
    FieldGroup,
    SvgIcon,
    TagList,
    TiptapEditor,
    TypeaheadVertical,
    ValidationProvider
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    firm: {
      type:     Object,
      required: true
    },

    onSave: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      elementsToNotCloseOn: [],
      rankingsDropdownOpen: false,
      rankings: [],

      awards: [],

      firmOwnershipsDropdownOpen: false,
      selectedFirmOwnerships: [],
      firmOwnershipProfileTaggings: [],

      diversitiesDropdownOpen: false,
      selectedDiversities: [],
      diversityProfileTaggings: []
    };
  },

  computed: {
    rankingsOptions() {
      return [
        'Am Law',
        'Am Law Global',
        'Chambers, Asia-Pacific Australia, Corporate/M&A',
        'Chambers, Asia-Pacific Australia, Dispute Resolution',
        'Chambers, Global: Multi-Jurisdictional, Dispute Resolution',
        'Chambers, Global-wide, Environmental, Social & Governance Risk (Law Firms)',
        'Chambers, Greater China Region China, Dispute Resolution: Litigation (International Firms)'
      ];
    },

    rankingsMappedByName() {
      return this.rankings.map(rankingData => rankingData.name);
    },

    rankingsWithoutAmLawGlobal() {
      return filter(this.rankings, (rankingData) => {
        return (rankingData.name !== 'Am Law' && rankingData.name !== 'Am Law Global');
      });
    }
  },

  mounted() {
    this.fetchFirmOwnershipProfileTaggings();
    this.fetchDiversityProfileTaggings();
    this.buildRankings();
  },

  methods: {
    setElementsToNotDisplayOn() {
      if (this.$refs['dropdown-li'] && !this.elementsToNotCloseOn.length) {
        this.elementsToNotCloseOn = this.$refs['dropdown-li'];
      }
    },

    openModal() {
      this.setElementsToNotDisplayOn();
      this.resetForm();
    },

    isNumber(event) {
      event = event || window.event;
      // TODO: these are deprecated. Should use event.key
      const charCode = event.which || event.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault();
      }
      else {
        return true;
      }
    },

    fetchFirmOwnershipProfileTaggings() {
      LoadingService.loading('firm-ownership');
      ProfileTagging.query({ category: 'awards_and_certifications', sub_category: 'firm_ownership' })
        .then(response => {
          this.firmOwnershipProfileTaggings = response;
          LoadingService.done('firm-ownership');
          this.buildFirmOwnerships();
        })
        .catch(() => {
          LoadingService.done('firm-ownership');
        });
    },

    fetchDiversityProfileTaggings() {
      LoadingService.loading('firm-diversity');
      ProfileTagging.query({ category: 'awards_and_certifications', sub_category: 'diversity' })
        .then(response => {
          this.diversityProfileTaggings = response;
          LoadingService.done('firm-diversity');
          this.buildDiversities();
        })
        .catch(() => {
          LoadingService.done('firm-diversity');
        });
    },

    buildFirmOwnerships() {
      let firmOwnerships = {};
      let selectedFirmOwnerships = [];

      this.firmOwnershipProfileTaggings.forEach(profileTagging => {
        firmOwnerships[profileTagging.id] = profileTagging;
      });

      this.firm.firmOwnerships.forEach(profileTagging => {
        if (firmOwnerships[profileTagging.id]) {
          selectedFirmOwnerships.push(profileTagging.internalName);
        }
      });

      this.selectedFirmOwnerships = selectedFirmOwnerships;
      this.originallySelectedFirmOwnerships = cloneDeep(selectedFirmOwnerships);
    },

    buildDiversities() {
      let diversities = {};
      let selectedDiversities = [];

      this.diversityProfileTaggings.forEach(profileTagging => {
        diversities[profileTagging.id] = profileTagging;
      });

      this.firm.diversities.forEach(profileTagging => {
        if (diversities[profileTagging.id]) {
          selectedDiversities.push(profileTagging.internalName);
        }
      });

      this.selectedDiversities = selectedDiversities;
      this.originallySelectedDiversities = cloneDeep(selectedDiversities);
    },

    getFirmOwnershipPresentedName(internalName) {
      const profileTagging = this.firmOwnershipProfileTaggings.find(profileTagging => profileTagging.internalName === internalName);

      if (profileTagging) {
        return profileTagging.presentedName;
      }
    },

    getDiversityPresentedName(internalName) {
      const profileTagging = this.diversityProfileTaggings.find(profileTagging => profileTagging.internalName === internalName);

      if (profileTagging) {
        return profileTagging.presentedName;
      }
    },

    removeFirmOwnership(index) {
      this.selectedFirmOwnerships.splice(index, 1);
    },

    removeDiversity(index) {
      this.selectedDiversities.splice(index, 1);
    },

    buildAwards() {
      this.awards = this.firm.sanitizedAwards.map(award => { return { id: uniqueId(), value: award }; });
      this.originalAwards = cloneDeep(this.awards);
    },

    buildRankings() {
      const amLawGlobalRankings = [];

      if (this.firm.amLawRanking) {
        amLawGlobalRankings.push({ name: 'Am Law', ranking: this.firm.amLawRanking });
      }

      if (this.firm.globalRanking) {
        amLawGlobalRankings.push({ name: 'Am Law Global', ranking: this.firm.globalRanking });
      }

      const otherRankings = this.firm.rankings.map(rankingData => {
        return {
          'id': rankingData.id,
          'name': rankingData.name,
          'ranking': rankingData.ranking
        };
      });

      this.rankings = amLawGlobalRankings.concat(otherRankings);
    },

    resetForm() {
      this.buildRankings();
      this.buildFirmOwnerships();
      this.buildDiversities();
      this.buildAwards();
    },

    addAward() {
      this.awards.push({ id: uniqueId(), value: '' });
    },

    addRanking() {
      this.rankings.push({ name: '', ranking: '' });
    },

    removeAward(index) {
      this.awards.splice(index, 1);
    },

    removeRankingData(index) {
      this.rankings.splice(index, 1);
    },

    getRankingByName(name) {
      return this.rankings.find(rankingData => rankingData.name === name)?.ranking;
    },

    buildScoutLawFirmRankings() {
      let rankings = [];
      const initialIds = this.firm.rankings.map(rankingData => rankingData.id);
      const editedRankingIds = compact(this.rankingsWithoutAmLawGlobal.map(rankingData => rankingData.id));

      initialIds.forEach(initialId => {
        if (editedRankingIds.includes(initialId)) { return; }
        rankings.push({ id: initialId, _destroy: true });
      });

      rankings = rankings.concat(this.rankingsWithoutAmLawGlobal);

      return rankings;
    },

    save(editedFirm) {
      const data = {
        lawFirm: {
          id: this.firm.id,
          amLawRanking: parseInt(this.getRankingByName('Am Law')),
          globalRanking: parseInt(this.getRankingByName('Am Law Global')),
          unsanitizedAwards: this.awards.map(award => award.value).filter(award => award.trim().length !== 0),
          firmOwnershipCodes: this.selectedFirmOwnerships,
          diversityCodes: this.selectedDiversities,
          scoutLawFirmRankingsAttributes: this.buildScoutLawFirmRankings(editedFirm.rankings)
        }
      };
      const flagsToSet = [];

      if (!matchingArrayValues(this.awards, this.originalAwards)) {
        flagsToSet.push('awards');
      }

      if (this.getRankingByName('Am Law') !== this.firm.amLawRanking || this.getRankingByName('Am Law Global') !== this.firm.globalRanking) {
        flagsToSet.push('rankings');
      }

      if (!matchingArrayValues(this.selectedDiversities, this.originallySelectedDiversities)) {
        flagsToSet.push('member_or_signatory_values');
      }

      if (!matchingArrayValues(this.selectedFirmOwnerships, this.originallySelectedFirmOwnerships)) {
        flagsToSet.push('certifications');
      }

      if (!matchingArrayValues(this.rankingsWithoutAmLawGlobal, this.firm.rankings)) {
        flagsToSet.push('scout_law_firm_rankings');
      }

      if (flagsToSet.length > 0) {
        DataLock.save({
          dataLock: {
            flag:         flagsToSet,
            resourceId:   this.firm.id,
            resourceType: 'law_firm'
          }
        });
      }

      this.onSave(data);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .header {
    border-bottom: 1px solid $k-light-gray;
    font-weight: 700;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .menu-item-container {
    padding: 5px;

    li {
      padding: 5px 10px;
    }
  }

  .invalid {
    border-color: $k-red;
  }
</style>
