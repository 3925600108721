<template>
  <priori-modal ref="modal" title="Edit Contact" @on-modal-close="reset">
    <template #modal-trigger="{ openModal}">
      <a href="" class="normal-weight" @click.prevent="openModal">Edit</a>
    </template>

    <template #default="{ closeModal }">
      <form role="form" name="contactForm" class="form a-form bottom-30" novalidate>
        <dropdown ref="dropdown" class="k-dropdown" v-model="dropdownOpen">
          <button type="button" role="button" id="k-toggle-button-user" :class="['dropdown-toggle', { 'placeholder': !hasSelectedContact }]">
            <span v-if="!hasSelectedContact">Select One</span>
            <span class="semibold-weight" v-if="hasSelectedContact">{{ selectedContact.fullName }}</span>
            <svg-icon name="caret-down" class="caret-down"></svg-icon>
            <svg-icon name="caret-up" class="caret-up"></svg-icon>
          </button>

          <template #dropdown>
            <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
              <li role="menuitem" class="menu-item" v-for="lawyer in lawyers" :key="lawyer.id" @click="selectContact(lawyer)">
                <div class="dropdown-row-inner">
                  <profile-photo :user="lawyer" :small="true"></profile-photo>
                  <span class="semibold-weight left-20">{{ lawyer.fullName }}</span>
                </div>
              </li>
            </div>
          </template>
        </dropdown>
      </form>

      <div class="row tight-columns">
        <div class="col-sm-4">
          <loading-button name="editContact" lb-class="nv-button-blue a-button-size" @lb-click="save">Save</loading-button>
        </div>

        <div class="col-sm-3 top-20-xs">
          <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'LawFirmContactModal',

  components: {
    LoadingButton,
    PrioriModal,
    SvgIcon
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  mixins: [
    modalToggle
  ],

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    lawyers: {
      type: Array,
      default: () => []
    },

    onSubmitted: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedContact: null,
      dropdownOpen: false
    };
  },

  computed: {
    hasSelectedContact() {
      return !!this.selectedContact;
    }
  },

  mounted() {
    this.selectedContact = this.lawFirm.contact;
  },

  methods: {
    reset() {
      this.selectedContact = this.lawFirm.contact;
    },

    selectContact(lawyer) {
      this.selectedContact = lawyer;
    },

    save() {
      LoadingService.loading('editContact');

      this.onSubmitted(
        { id: this.lawFirm.id, contactId: this.selectedContact.id },
        () => {
          LoadingService.done('editContact');
          this.$refs.modal.closeModal();
        },
        () => {
          LoadingService.done('editContact');
        }
      );
    }
  }
};
</script>
