<template>
  <div>
    <div id="recaptcha"></div>

    <div class="error-text top-5" v-if="recaptchaError">
      {{ recaptchaError }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueHead from 'vue-head';
import ConfigService from 'vue-app/shared/services/config-service.js';

Vue.use(VueHead);

export default {
  name: 'RecaptchaV2',

  data: function () {
    return {
      recaptchaError: undefined
    };
  },

  head: {
    script: [
      { src: 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnloadCallback&render=explicit', async: true, defer: true }
    ]
  },

  computed: {
    recaptchaSiteKey: function () {
      return ConfigService.recaptchaSiteKey;
    }
  },

  mounted() {
    const vueInstance = this;

    window.recaptchaOnloadCallback = function () {
      grecaptcha.render('recaptcha', {
        'sitekey': vueInstance.recaptchaSiteKey,
        'callback': vueInstance.successCallback,
        'error-callback': vueInstance.errorCallback,
        'expired-callback': vueInstance.expiredCallback
      });
    };
  },

  methods: {
    successCallback: function (response) {
      this.$emit('input', response);
      this.recaptchaError = undefined;
    },

    errorCallback: function (error) {
      this.$emit('input', '');
      this.recaptchaError = error;
    },

    expiredCallback: function () {
      this.$emit('input', '');
    },

    resetRecaptcha: function () {
      this.$emit('input', '');
      grecaptcha.reset();
    }
  }
};
</script>
