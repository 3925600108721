<template>
  <div>
    <scout-titlebar title="Account & Privacy">
      <template #actions>
        <button type="button" class="titlebar-button secondary-btn-blue" @click="logout">Sign Out</button>
      </template>
    </scout-titlebar>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-10 col-md-8">
              <div class="bottom-30">
                <scout-firm-account-info
                  :lawyer="user"
                  :sso-enabled="ssoEnabled"
                  :on-save="updateLawyer"
                  :password-authentication="passwordAuthentication"
                  :update-password="updateLawyerPassword">
                </scout-firm-account-info>
              </div>

              <div class="bottom-30" v-if="!ssoEnabled && authenticatable">
                <two-factor-authentication-settings
                  :save-changes="saveMfaChanges"
                  :verify-otp="verifyOtp"
                  :send-one-time-password="sendOneTimePassword"
                  :password-authentication="passwordAuthentication"
                  :authenticatable="authenticatable"
                  :section-title="'Account Security'">
                </two-factor-authentication-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoutFirmAccountInfo from 'vue-app/scout/firm/account/scout-firm-account-info.vue';
import ScoutTitlebar from 'vue-app/scout/shared/scout-titlebar.vue';
import TwoFactorAuthenticationSettings from 'vue-app/shared/components/two-factor-authentication/two-factor-authentication-settings.vue';
import Lawyer from 'resources/lawyer.js';
import TwoFactorAuthenticatable from 'resources/two-factor-authenticatable.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'AccountIndex',

  components: {
    ScoutFirmAccountInfo,
    ScoutTitlebar,
    TwoFactorAuthenticationSettings
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      authenticatable: this.initData.authenticatable
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser', ssoEnabled: 'ssoEnabled' })
  },

  methods: {
    ...mapActions(useCurrentUserStore, ['setCurrentUser']),

    logout() {
      document.getElementById('hidden-logout-button').click();
    },

    verifyOtp(otpAttempt, otpStrategy) {
      return TwoFactorAuthenticatable.verifyOtp({ otpAttempt, otpStrategy }).then(
        (response) => {
          this.authenticatable = { ...response, validOtp: true };
        },
        () => {
          this.authenticatable.validOtp = false;
        }
      );
    },

    saveMfaChanges(authenticatable, successFn) {
      return TwoFactorAuthenticatable.update(authenticatable).then(
        (response) => {
          this.authenticatable = response;
          NotificationService.success('Changes were successfully saved');
          successFn();
        },
        () => {
          NotificationService.error('Changes could not be saved');
        }
      );
    },

    passwordAuthentication(password) {
      return TwoFactorAuthenticatable.passwordVerification({ password }).then((resp) => {
        NotificationService.clear();
        return resp.data;
      });
    },

    sendOneTimePassword() {
      return TwoFactorAuthenticatable.sendOneTimePassword({}).then(
        () => {
          NotificationService.clear();
        },
        () => {
          NotificationService.error('Could not send one time password');
        }
      );
    },

    updateLawyer(lawyerAttributes, successFn, errorFn) {
      return Lawyer.update({ id: lawyerAttributes.id, lawyer: lawyerAttributes }).then(
        (lawyer) => {
          this.setCurrentUser(lawyer);
          if (successFn) { successFn(); }
        },
        () => {
          NotificationService.error('Unable to save changes. Please try again.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          if (errorFn) { errorFn(); }
        }
      );
    },

    updateLawyerPassword(lawyerAttributes) {
      return Lawyer.updatePassword({ id: this.user.id, lawyer: lawyerAttributes });
    }
  }
};
</script>
