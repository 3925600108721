<template>
  <span :class="['status-badge', badgeStyles]">{{ statusValue }}</span>
</template>

<script>
export default {
  name: 'StatusBadge',

  props: {
    quote: {
      type: Object,
      required: true
    }
  },

  computed: {
    badgeStyles() {
      if (this.quote.rfqStatus === 'closed') {
        return 'client-closed';
      }

      const styles = {
        'new_rfq': 'new-rfq',
        'draft': 'draft',
        'quote_submitted': 'submitted',
        'selected_by_client': 'selected',
        'declined_by_firm': 'declined',
        'declined_by_client': 'client-declined'
      };

      return styles[this.quote.status] || '';
    },

    statusValue() {
      if (this.quote.rfqStatus === 'closed') {
        return 'Closed by Client';
      }

      const values = {
        'new_rfq': 'New RFP',
        'draft': 'Draft',
        'quote_submitted': 'Quote Submitted',
        'selected_by_client': 'Selected by Client',
        'declined_by_firm': 'Declined by Firm',
        'declined_by_client': 'Declined by Client'
      };

      return values[this.quote.status] || '';
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .status-badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: $border-radius-base;
    flex-shrink: 0;

    &.new-rfq {
      background-color: $k-green;
      color: $white;
    }

    &.draft {
      background-color: $mid-match-score-blue;
      color: $white;
    }

    &.submitted {
      background-color: $k-blue;
      color: $white;
    }

    &.selected {
      background-color: $k-blue;
      color: $white;
    }

    &.declined {
      background-color: $k-purple;
      color: $white;
    }

    &.client-declined {
      background-color: $k-purple;
      color: $white;
    }

    &.client-closed {
      background-color: $k-purple;
      color: $white;
    }
  }
</style>
