import { isEmpty } from 'lodash';

const SearchFilter = {
  data() {
    return {
      defaultContext: this.filterParams.defaultContext === 'filter' ? 'filters' : 'queries',
      field: this.filterParams.field,
      isOrLogic: this.filterParams.logic === 'or',
      lockContext: !!this.filterParams.lockContext
    };
  },

  computed: {
    isFilterContext() {
      return this.filterContext === 'filters';
    },

    filterContext() {
      if (!isEmpty(this.selectedFilterTerms)) {
        return 'filters';
      }
      else if (!isEmpty(this.selectedQueryTerms)) {
        return 'queries';
      }
      else {
        return this.defaultContext;
      }
    },

    selectedTerms() {
      return [...this.selectedFilterTerms, ...this.selectedQueryTerms].sort();
    },

    hasSelectedTerms() {
      return this.searchService.hasFilter(this.field);
    },

    selectedFilterTerms() {
      return this.searchService.currentSelections(this.field, 'filters');
    },

    selectedQueryTerms() {
      return this.searchService.currentSelections(this.field, 'queries');
    }
  },

  methods: {
    switchFilterContext() {
      if (this.lockContext || !this.hasSelectedTerms) { return; }

      this.searchService.switchFilterContext(this.field, this.filterContext);
      this.onSearch();
    },

    termIsFilterContext(term) {
      return this.selectedFilterTerms.includes(term);
    },

    termIsQueryContext(term) {
      return this.selectedQueryTerms.includes(term);
    },

    switchTermContext(term) {
      if (this.lockContext) { return; }

      this.searchService.switchTermContext(this.field, term, this.termContext(term));
      this.onSearch();
    },

    termContext(term) {
      if (this.termIsFilterContext(term)) {
        return 'filters';
      }
      else if (this.termIsQueryContext(term)) {
        return 'queries';
      }
      else {
        return this.defaultContext;
      }
    },

    isTermSelected(term) {
      return this.selectedTerms.includes(term);
    }
  }
};

export default SearchFilter;
