<template>
  <boxed-content title="Biography" v-if="displayComponent">
    <template #links v-if="canEditProfile">
      <edit-modal title="Editing Biography" :input="lawyer" :on-save="save" @on-modal-open="onModalOpen" :on-cancel="initElementsToDestroy">
        <template #default="{ inputCopy: lawyerCopy }">
          <div class="small-bold-header">
            Biography
          </div>

          <hr class="top-5">

          <tiptap-editor v-model="lawyerCopy.sanitizedBio" class="bottom-30"></tiptap-editor>

          <loading-section name="education">
            <div class="small-bold-header">
              Education
            </div>

            <hr class="top-5">

            <div v-for="(school, index) in lawyerCopy.schools" :key="index" class="field-group bottom-20">
              <div class="row bottom-20">
                <div class="col-sm-5">
                  <typeahead-vertical
                    label="School"
                    rules="required"
                    :id="`school-${index}`"
                    :initial-value="school.schoolName"
                    :force-select="true"
                    :options="schoolsList.map(school => school.name)"
                    v-model="school.schoolName">
                  </typeahead-vertical>
                </div>

                <div class="col-sm-3 top-10-xs">
                  <typeahead-vertical
                    label="Degree"
                    option-label-key="label"
                    option-value-key="abbreviation"
                    v-model="school.degree"
                    :id="`degree-${index}`"
                    :initial-value="school.degree"
                    :force-select="true"
                    @input="setSchoolDegree(school)($event)"
                    :options="degreesList">
                  </typeahead-vertical>
                </div>

                <div class="col-sm-4 top-10-xs">
                  <year-select-vertical v-model="school.yearAttained" :id="`year-attained-${index}`" label="Graduation Year"></year-select-vertical>
                </div>
              </div>

              <div class="bottom-20">
                <textarea-input-vertical label="Educational Awards" :id="`awards-${index}`" v-model="school.awards" :rows="1"></textarea-input-vertical>
              </div>

              <div>
                <a href="" class="remove-btn" @click.prevent="removeElement('schools', lawyerCopy.schools, school)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon>Remove</a>
              </div>
            </div>

            <div class="bottom-30">
              <a href="" class="semibold-weight" @click.prevent="addSchool(lawyerCopy.schools)">+ {{ lawyerCopy.schools.length ? 'Add Another' : 'Add School' }}</a>
            </div>
          </loading-section>

          <div class="small-bold-header">
            Clerkships
          </div>

          <hr class="top-5">

          <div v-for="(clerkship, index) in lawyerCopy.clerkships" :key="clerkship.id" class="bottom-20">
            <div class="row clerkship tight-columns">
              <div class="col-xs-2 col-sm-1 remove-clerkship-btn">
                <a href="" class="" @click.prevent="removeElement('clerkships', lawyerCopy.clerkships, clerkship)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon></a>
              </div>

              <div class="col-xs-10 col-sm-11 no-left-gutter">
                <text-input-vertical label="Clerkship and Year" :id="`clerkship-${clerkship.id}-${index}`" v-model="clerkship.description" rules="required"></text-input-vertical>
              </div>
            </div>
          </div>

          <div class="bottom-30">
            <a href="" class="semibold-weight" @click.prevent="addClerkship(lawyerCopy.clerkships)">+ {{ lawyerCopy.clerkships.length ? 'Add Another' : 'Add Clerkship' }}</a>
          </div>
        </template>

        <template #errorMessage>
          * One or more required fields are empty. Please fill in the required fields indicated above or remove that entry from the form.
        </template>
      </edit-modal>
    </template>

    <loading-section name="lawyer-biography">
      <section-wrapper class="separated-list-items" :data="lawyer.sanitizedBio" :editing-allowed="canEditProfile">
        <!-- TODO: Scout; always show full bio? -->
        <data-wrapper :data="lawyer.sanitizedBio">
          <rich-text-display :content="lawyer.sanitizedBio"></rich-text-display>
        </data-wrapper>
      </section-wrapper>

      <section-wrapper class="separated-list-items" :data="lawyer.schools" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Education
        </div>

        <data-wrapper :data="lawyer.schools">
          <div v-for="school in lawyer.schools" :key="`school-${school.id}`" class="bottom-10">
            <div>
              {{ schoolAndDegree(school) }}{{ school.yearAttained ? ` (${school.yearAttained})` : '' }}
            </div>

            <div class="top-5" v-if="hasSchoolAwards(school)">
              <ul class="small-bullet">
                <li class="simple-text">{{ school.awards }}</li>
              </ul>
            </div>
          </div>
        </data-wrapper>
      </section-wrapper>

      <section-wrapper class="separated-list-items" :data="lawyer.clerkships" :editing-allowed="canEditProfile">
        <div class="bold-weight bottom-10">
          Clerkships
        </div>

        <data-wrapper :data="lawyer.clerkships">
          <div v-for="clerkship in lawyer.clerkships" :key="`clerkship-${clerkship.id}`">
            {{ clerkship.description }}
          </div>
        </data-wrapper>
      </section-wrapper>
    </loading-section>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content';
import ScoutFirmEducationalInstitute from 'resources/scout/scout-firm-educational-institute.js';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import SharedListsService from 'vue-app/shared/services/shared-lists-service';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TextareaInputVertical from 'vue-app/shared/components/textarea-input-vertical.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import YearSelectVertical from 'vue-app/shared/components/year-select-vertical.vue';
import DataLock from 'resources/data-lock';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import RichTextDisplay from 'vue-app/shared/components/rich-text-display.vue';

import { changes }           from 'misc/resource-helpers';
import { compact, includes } from 'lodash';

export default {
  name: 'LawyerBiography',

  components: {
    BoxedContent,
    SectionWrapper,
    DataWrapper,
    EditModal,
    TextInputVertical,
    TextareaInputVertical,
    TypeaheadVertical,
    YearSelectVertical,
    TiptapEditor,
    RichTextDisplay
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      schoolsList: [],
      elementsToDestroy: {
        schools: [],
        clerkships: []
      },
      lockables: {
        schools:      'education',
        clerkships:   'clerkships',
        sanitizedBio: 'biography'
      }
    };
  },

  computed: {
    displayComponent: function () {
      const { sanitizedBio, schools, clerkships } = this.lawyer;
      return this.anyDataIsPresent([sanitizedBio, schools, clerkships]) || this.canEditProfile;
    },

    degreesList: function () {
      return SharedListsService.degreesWithNames;
    }
  },

  methods: {
    // this function addresses a problem with UIV's typeahead.
    // when no value is selected, the typeahead returns undefined,
    // but undefined values are omitted from Rails's params, so
    // it will never set a degree to nil if its sent as
    // undefined. Here we set it to null instead, which passes
    // through the params. However, since the typeahead input
    // callback only returns the degree value, not the object
    // to which it is assigned, we need to generate a custom func
    // callback wrapper that provides the school to the function
    // that updates the school with the selected degree value.
    setSchoolDegree: function (school) {
      return (degree) => {
        school.degree = degree ? degree : null;
      };
    },

    onModalOpen: function () {
      if (!this.schoolsList.length) {
        LoadingService.loading('education');

        // TODO: Scout; do we fall back to our list? How?
        ScoutFirmEducationalInstitute.query().then(schools => {
          this.schoolsList = schools;
          LoadingService.done('education');
        });
      }
    },

    schoolAndDegree: function (school) {
      return compact([school.schoolName, school.degree]).join(', ');
    },

    hasSchoolAwards: function (school) {
      return school.awards && school.awards.length;
    },

    addSchool: function (schoolsCopy) {
      schoolsCopy.push({
        schoolName: '',
        degree: ''
      });
    },

    addClerkship: function (clerkshipsCopy) {
      clerkshipsCopy.push({
        description: ''
      });
    },

    removeElement: function (type, source, element) {
      const index = source.indexOf(element);

      if (index > -1 && element.id) {
        this.elementsToDestroy[type].push({ id: element.id, _destroy: true });
        source.splice(index, 1);
      }
      else if (index > -1) {
        source.splice(index, 1);
      }
    },

    findScoutFirmEducationalInstituteId(school) {
      return this.schoolsList.find((schoolFromList) => {
        return schoolFromList.name === school.schoolName;
      }).id;
    },

    buildLawyerAttributes: function (editedLawyer) {
      const editedSchools = editedLawyer.schools.map((school)=> {
        return {
          id: school.id,
          scoutFirmEducationalInstituteId: this.findScoutFirmEducationalInstituteId(school),
          name: school.schoolName,
          degree: school.degree?.abbreviation || school.degree,
          awards: school.awards,
          dateAttained: school.yearAttained ? new Date(school.yearAttained, 1, 1) : school.dateAttained
        };
      });

      editedLawyer.lawyerProfileAttributes = {
        practiceDescription: editedLawyer.sanitizedBio,
        scoutFirmSchoolsAttributes: [
          ...this.elementsToDestroy.schools,
          ...editedSchools
        ],
        clerkshipsAttributes: [
          ...this.elementsToDestroy.clerkships,
          ...editedLawyer.clerkships
        ]
      };

      return editedLawyer;
    },

    initElementsToDestroy: function () {
      this.elementsToDestroy = {
        schools: [],
        clerkships: []
      };
    },

    save: function (editedLawyer) {
      const vueInstance = this;

      if (vueInstance.canEditProfile) {
        LoadingService.loading('lawyer-biography');

        const changedKeys = Object.keys(changes(vueInstance.lawyer, editedLawyer));

        const flagsToSet = Object.keys(this.lockables).reduce((flags, key) => {
          if (includes(changedKeys, key)) {
            flags.push(this.lockables[key]);
          }
          return flags;
        }, []);

        vueInstance.onSave(this.buildLawyerAttributes(editedLawyer),
          () => {
            vueInstance.initElementsToDestroy();
            LoadingService.done('lawyer-biography');

            if (flagsToSet.length > 0) {
              DataLock.save({
                dataLock: {
                  flag: flagsToSet,
                  resourceType: 'lawyer',
                  resourceId: vueInstance.lawyer.id
                }
              });
            }
          },
          () => {
            LoadingService.done('lawyer-biography');
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .remove-btn {
    align-items: center;
    color: $k-darker-gray;
    display: flex;
    justify-content: right;
  }

  .clerkship {
    .no-left-gutter {
      padding-left: 0;
    }

    .remove-clerkship-btn {
      position: relative;
      top: 37px;

      @media (min-width: 768px) {
        top: 35px;
      }
    }
  }

  ul.small-bullet > li {
    text-indent: -10px;
  }
</style>
