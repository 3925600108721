<template>
  <div id="payment-setup" class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Billing & Invoicing Onboarding Status
        </div>

        <div class="col-sm-4 size-text-13px text-right-not-xs">
          <span v-if="editMode">
            <a href="" class="normal-weight right-15" @click.prevent="save" :disabled="isLoading">Save</a>
            <a href="" class="dark-gray-text normal-weight" @click.prevent="cancel">Cancel</a>
          </span>

          <a href="" class="normal-weight" @click.prevent="editing = true" v-else>Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <form role="form" name="paymentSetupForm" class="a-form" novalidate>
        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Client Name
          </div>

          <div class="col-sm-8">
            {{ matter.client.fullName }}
          </div>
        </div>

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Billing & Invoicing Status
          </div>

          <div class="col-sm-8">
            <div class="top-10-xs" v-if="editMode">
              <dropdown-select
                id-label="client-billing-status"
                placeholder="Select One"
                :options="clientBillingStatuses"
                :initial-label="matter.clientBillingStatus"
                v-model="matter.clientBillingStatus">
              </dropdown-select>
            </div>

            <div v-else>
              {{ matter.clientBillingStatus || '—' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 semibold-weight">
            Onboarding Status
          </div>

          <div class="col-sm-8">
            <div class="top-10-xs" v-if="editMode">
              <dropdown-select
                id-label="client-check-in"
                placeholder="Select One"
                label-key="label"
                value-key="value"
                :options="onboardingOptions"
                :initial-label="onboardingOptionLabel(matter.clientCheckIn, true)"
                v-model="matter.clientCheckIn">
              </dropdown-select>
            </div>

            <div v-else>
              {{ onboardingOptionLabel(matter.clientCheckIn) }}
            </div>
          </div>
        </div>

        <hr class="kenny-hr">

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Lawyer Name
          </div>

          <div class="col-sm-8">
            {{ matter.lawyer.fullName }}
          </div>
        </div>

        <div class="row bottom-20">
          <div class="col-sm-4 semibold-weight">
            Billing & Invoicing Status
          </div>

          <div class="col-sm-8">
            <div class="top-10-xs" v-if="editMode">
              <dropdown-select
                id-label="lawyer-billing-status"
                placeholder="Select One"
                :options="lawyerBillingStatuses"
                :initial-label="matter.lawyerBillingStatus"
                v-model="matter.lawyerBillingStatus">
              </dropdown-select>
            </div>

            <div v-else>
              {{ matter.lawyerBillingStatus || '—' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 semibold-weight">
            Onboarding Status
          </div>

          <div class="col-sm-8">
            <div class="top-10-xs" v-if="editMode">
              <dropdown-select
                id-label="lawyer-check-in"
                placeholder="Select One"
                label-key="label"
                value-key="value"
                :options="onboardingOptions"
                :initial-label="onboardingOptionLabel(matter.lawyerCheckIn, true)"
                v-model="matter.lawyerCheckIn">
              </dropdown-select>
            </div>

            <div v-else>
              {{ onboardingOptionLabel(matter.lawyerCheckIn) }}
            </div>
          </div>
        </div>

        <div class="row top-30" v-if="editMode">
          <div class="col-sm-5 col-md-4 ">
            <loading-button name="updateButton" lb-class="nv-button-blue a-button-size" @lb-click="save">Save</loading-button>
          </div>

          <div class="col-sm-5 col-md-4 top-10-xs">
            <button type="button" class="secondary-btn-blue a-button-size" @click="cancel">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import { isBoolean } from 'lodash';

export default {
  name: 'MatterPaymentSetup',

  components: {
    DropdownSelect
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    onSubmitted: {
      type: Function,
      required: true
    },

    onCancelEdit: {
      type: Function,
      required: true
    }
  },

  data: function () {
    return {
      editing: false
    };
  },

  computed: {
    clientBillingStatuses() {
      return SharedLists.clientBillingStatuses;
    },

    lawyerBillingStatuses() {
      return SharedLists.lawyerBillingStatuses;
    },

    onboardingOptions() {
      return [
        { label: 'Complete', value: false },
        { label: 'Incomplete', value: true }
      ];
    },

    editMode() {
      return this.editing;
    },

    isLoading() {
      return LoadingService.isLoading('updateButton');
    }
  },

  methods: {
    onboardingOptionLabel(value, allowNull) {
      if (isBoolean(value)) {
        return value ? 'Incomplete' : 'Complete';
      }
      else {
        return allowNull ? null : '—';
      }
    },

    save() {
      LoadingService.loading('updateButton');

      this.onSubmitted(
        () => {
          LoadingService.done('updateButton');
        },
        () => {
          LoadingService.done('updateButton');
          this.editing = false;
        }
      );
    },

    cancel() {
      this.editing = false;
      this.onCancelEdit();
    }
  }
};
</script>
