<template>
  <edit-modal title="Editing Diversity & Inclusion" :input="lawyer" :on-save="save" :on-cancel="cancel" @on-modal-open="openModal" @on-modal-close="cancel">
    <div>
      <loading-section name="ethnicity">
        <div>
          <span class="bold-weight">Do you identify as:</span>
          <span class="dark-gray-text">(select as many as apply)</span>
        </div>

        <div class="options-margin">
          <label class="check-option medium-large vertical" v-for="ethnicity in ethnicityOptions" :key="`ethnicity-${ethnicity.id}`">
            <span class="normal-weight">{{ ethnicity.name }}</span>
            <input type="checkbox" :id="`ethnicity-${ethnicity.id}`" :checked="ethnicityIsChecked(ethnicity)" :value="ethnicity.id" v-model="selectedEthnicityIds" @change="updateEthnicityIds($event)">
            <span class="check c-box"></span>
          </label>
        </div>
      </loading-section>

      <hr>

      <loading-section name="gender">
        <div>
          <span class="bold-weight">Do you identify as:</span>
          <span class="dark-gray-text">(select one)</span>
        </div>

        <div class="options-margin">
          <label class="check-option medium-large vertical" v-for="gender in genderOptions" :key="`gender-${gender.id}`">
            <span class="normal-weight">{{ gender.presentedName }}</span>
            <input type="radio" :id="`gender-${gender.id}`" :value="gender.internalName" v-model="selectedGender">
            <span class="check c-radio"></span>
          </label>
        </div>
      </loading-section>

      <hr>

      <div data-testid="lgbtq-identification" class="row tight-columns">
        <div class="col-sm-5 bold-weight">
          Do you identify as LGBTQ+?
        </div>

        <div class="col-sm-7 text-right-not-xs">
          <span class="radio-option" v-for="(enumObj, enumVal) in identifiesAsLgbtqMap" :key="`lgbtq-${enumObj.int}`">
            <label class="check-option medium-large">
              <span class="normal-weight">{{ enumObj.label }}</span>
              <input type="radio" :id="`lgbtq-${enumObj.int}`" :value="enumVal" v-model="identifiesAsLgbtq">
              <span class="check c-radio"></span>
            </label>
          </span>
        </div>
      </div>

      <hr>

      <div data-testid="differently-abled-identification" class="row tight-columns">
        <div class="col-sm-4 bold-weight">
          Do you identify as a person with a disability?
        </div>

        <div class="col-sm-8 text-right-not-xs">
          <span class="radio-option" v-for="(enumObj, enumVal) in identifiesAsDifferentlyAbledMap" :key="`diff-abled-${enumObj.int}`">
            <label class="check-option medium-large">
              <span class="normal-weight">{{ enumObj.label }}</span>
              <input type="radio" :id="`diff-abled-${enumObj.int}`" :value="enumVal" v-model="identifiesAsDifferentlyAbled">
              <span class="check c-radio"></span>
            </label>
          </span>
        </div>
      </div>

      <hr>

      <div data-testid="military-veteran-identification" class="row tight-columns">
        <div class="col-sm-4 bold-weight">
          Are you a military veteran?
        </div>

        <div class="col-sm-8 text-right-not-xs">
          <span class="radio-option" v-for="(enumObj, enumVal) in isMilitaryVeteranMap" :key="`military-vet-${enumObj.int}`">
            <label class="check-option medium-large">
              <span class="normal-weight">{{ enumObj.label }}</span>
              <input type="radio" :id="`vet-${enumObj.int}`" :value="enumVal" v-model="isMilitaryVeteran">
              <span class="check c-radio"></span>
            </label>
          </span>
        </div>
      </div>

      <hr>

      <div class="bold-weight bottom-15">
        Select all Scout users with whom you wish to share diversity information:
      </div>

      <div class="options-margin">
        <div class="bottom-10">
          <span class="bold-weight">Clients and potential clients</span>
          <span class="dark-gray-text">(select one)</span>
        </div>

        <div class="options-margin bottom-15">
          <label class="check-option medium-large vertical">
            <span class="normal-weight">Any client my law firm connects with on Scout</span>
            <input type="radio" id="view-perm-1" @change="selectAnyClientCanView" :checked="anyClientMyFirmConnectsToScoutSelected">
            <span class="check c-radio"></span>
          </label>

          <label class="check-option medium-large vertical">
            <span class="normal-weight">Any client selected below</span>
            <input type="radio" id="view-perm-2" @change="selectOnlySelectWorkspacesCanView" :checked="anyClientFromListSelected">
            <span class="check c-radio"></span>
          </label>

          <div class="bottom-15" v-if="selectWorkspacesCanView">
            <div class="row bottom-10">
              <div class="col-sm-6">
                <label>Client(s)</label>

                <dropdown class="k-dropdown" v-model="dropdownOpen" :not-close-elements="elementsToNotCloseOn">
                  <button type="button" role="button" class="dropdown-toggle">
                    <span>Select all that apply</span>
                    <svg-icon name="caret-down" class="caret-down"></svg-icon>
                    <svg-icon name="caret-up" class="caret-up"></svg-icon>
                  </button>

                  <template #dropdown>
                    <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
                      <li role="menuitem" v-for="workspace in workspaces" :key="workspace.id" ref="dropdown-li">
                        <label class="check-option vertical medium-large">
                          {{ workspace.name }}
                          <input type="checkbox" :id="`workspace-${workspace.id}`" :checked="workspaceIsChecked(workspace)" :value="workspace" v-model="selectedWorkspaces">
                          <span class="check c-box"></span>
                        </label>
                      </li>
                    </div>
                  </template>
                </dropdown>
              </div>
            </div>

            <div v-if="selectedWorkspaces.length">
              <tag-list :tags="selectedWorkspaces" :on-remove="removeWorkspace"></tag-list>
            </div>
          </div>

          <label class="check-option medium-large vertical">
            <span class="normal-weight">I decline to disclose any diversity information to clients and potential clients on Scout</span>
            <input type="radio" id="view-perm-3" @change="selectDeclineClients" :checked="declineClientsSelected">
            <span class="check c-radio"></span>
          </label>
        </div>

        <div class="bottom-10">
          <span class="bold-weight">My law firm</span>
          <span class="dark-gray-text">(select one)</span>
        </div>

        <div class="options-margin bottom-10">
          <label class="check-option medium-large vertical">
            <span class="normal-weight">Scout users at my law firm</span>
            <input type="radio" id="view-perm-4" @change="selectAnyLawyers" :checked="lawyersCanViewSelected">
            <span class="check c-radio"></span>
          </label>

          <label class="check-option medium-large vertical">
            <span class="normal-weight">I decline to disclose any diversity information to Scout users at my law firm. (Note that your law firm&rsquo;s Scout administrators will have access to this information.)</span>
            <input type="radio" id="view-perm-5" @change="selectDeclineLawyers" :checked="declineLawyersSelected">
            <span class="check c-radio"></span>
          </label>
        </div>

        <div class="pill-gray-text tight-lines">
          <i>Note: The selections made above will affect the way your diversity information is shared within Scout and to Scout users. You may change your sharing selections at any time.</i>
        </div>
      </div>
    </div>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import Ethnicity from 'resources/ethnicity';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfileTagging from 'resources/profile-tagging';
import LoadingService from 'vue-app/shared/services/loading-service';
import { each, find, includes, without } from 'lodash';
import { identifiesAsLgbtqMap, identifiesAsDifferentlyAbledMap, isMilitaryVeteranMap } from 'misc/diversity-inclusion-enums-key-map';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import TagList from 'vue-app/scout/shared/tag-list.vue';
import DataLock from 'resources/data-lock';

export default {
  name: 'EditLawyerDiversityInclusion',

  components: {
    EditModal,
    LoadingSection,
    SvgIcon,
    TagList
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    workspaces: {
      type:     Array,
      default:  () => []
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  data() {
    return {
      ethnicityOptions: [],
      selectedEthnicityIds: [],
      userEthnicities: {},

      genderOptions: [],
      selectedGender: null,
      genderNotDisclosedId: null,

      identifiesAsLgbtq: this.lawyer.identifiesAsLgbtq,
      identifiesAsDifferentlyAbled: this.lawyer.identifiesAsDifferentlyAbled,
      isMilitaryVeteran: this.lawyer.isMilitaryVeteran,

      dropdownOpen: false,
      elementsToNotCloseOn: [],
      clientsCanView: !!this.lawyer.diversityInclusionViewPermission?.clientsCanView,
      lawyersCanView: !!this.lawyer.diversityInclusionViewPermission?.lawyersCanView,
      selectWorkspacesCanView: !!this.lawyer.diversityInclusionViewPermission?.selectWorkspacesCanView,
      selectedWorkspaces: this.getInitialSelectedWorkspaces(),

      identifiesAsLgbtqMap: identifiesAsLgbtqMap,
      identifiesAsDifferentlyAbledMap: identifiesAsDifferentlyAbledMap,
      isMilitaryVeteranMap: isMilitaryVeteranMap,

      availableGenderKeys: ['female', 'male', 'non_binary', 'not_disclosed']
    };
  },

  computed: {
    prefersNotToDiscloseEthnicityId() {
      return find(this.ethnicityOptions, { internalName: 'prefer_not_to_disclose' })?.id;
    },

    ethnicityIdsIncludeNotDisclosedId() {
      return includes(this.selectedEthnicityIds, this.prefersNotToDiscloseEthnicityId);
    },

    anyClientMyFirmConnectsToScoutSelected() {
      return this.clientsCanView && !this.selectWorkspacesCanView;
    },

    anyClientFromListSelected() {
      return !this.clientsCanView && this.selectWorkspacesCanView;
    },

    declineClientsSelected() {
      return !this.clientsCanView && !this.selectWorkspacesCanView;
    },

    lawyersCanViewSelected() {
      return this.lawyersCanView;
    },

    declineLawyersSelected() {
      return !this.lawyersCanView;
    },

    flagsToSet() {
      const flags = [];

      if (this.selectedGender !== this.lawyer.genderProfileTaggings[0]?.internalName) {
        flags.push('gender_identity');
      }

      const existingEthnicityIds = this.lawyer.userEthnicities.map(ethnicity => ethnicity.id);

      const newEthnicityIds = [];

      this.buildUserEthnicitiesData().forEach(ethnicity => {
        if (ethnicity._destroy) {
          return;
        }

        newEthnicityIds.push(ethnicity.id);
      });

      if (existingEthnicityIds.sort().toString() !== newEthnicityIds.sort().toString()) {
        flags.push('race_identity');
      }

      if (this.identifiesAsLgbtq !== this.lawyer.identifiesAsLgbtq) {
        flags.push('identifies_as_lgbtq');
      }

      if (this.identifiesAsDifferentlyAbled !== this.lawyer.identifiesAsDifferentlyAbled) {
        flags.push('identifies_as_differently_abled');
      }

      if (this.isMilitaryVeteran !== this.lawyer.isMilitaryVeteran) {
        flags.push('is_military_veteran');
      }

      return flags;
    }
  },

  methods: {
    fetchEthnicityOptions() {
      LoadingService.loading('ethnicity');
      Ethnicity.query().then(response => {
        this.ethnicityOptions = Ethnicity.sortForDisplay(response);
        LoadingService.done('ethnicity');
        this.setUserEthnicitiesSelectedEthnicityIds();
      });
    },

    fetchGenderOptions() {
      LoadingService.loading('gender');

      ProfileTagging.query({ category: 'diversity_and_inclusion' }).then(response => {
        this.availableGenderKeys.forEach(gender => this.genderOptions.push(response.find(genderOption => genderOption.internalName === gender)));
        LoadingService.done('gender');

        this.setGenderNotDisclosedIdAndGenderIds();
      });
    },

    setUserEthnicitiesSelectedEthnicityIds() {
      const userEthnicities = {};
      const selectedEthnicityIds = [];

      this.lawyer.userEthnicities.forEach(userEthnicity => {
        userEthnicities[userEthnicity.ethnicityId] = userEthnicity;
        selectedEthnicityIds.push(userEthnicity.ethnicityId);
      });

      this.userEthnicities = userEthnicities;
      this.selectedEthnicityIds = selectedEthnicityIds;
    },

    setGenderNotDisclosedIdAndGenderIds() {
      this.selectedGender = null;

      this.genderOptions.forEach(genderOption => {
        if (genderOption.internalName === 'not_disclosed') {
          this.genderNotDisclosedId = genderOption.id;
        }

        this.lawyer.genderProfileTaggings.forEach(genderProfileTagging => {
          if (genderProfileTagging.id === genderOption.id) {
            this.selectedGender = genderOption.internalName;
          }
        });
      });
    },

    openModal() {
      if (!this.ethnicityOptions.length) {
        this.fetchEthnicityOptions();
      }

      if (!this.genderOptions.length) {
        this.fetchGenderOptions();
      }

      if (this.ethnicityOptions.length && this.genderOptions.length) {
        this.resetData();
      }
    },

    ethnicityIsChecked(ethnicity) {
      return includes(this.selectedEthnicityIds, ethnicity.id);
    },

    updateEthnicityIds(event) {
      const parsedId = parseInt(event.target.value);

      if (this.prefersNotToDiscloseEthnicityId === parsedId) {
        if (this.selectedEthnicityIds.includes(this.prefersNotToDiscloseEthnicityId)) {
          this.selectedEthnicityIds = [parsedId];
        }
      }
      else {
        this.selectedEthnicityIds = without(this.selectedEthnicityIds, this.prefersNotToDiscloseEthnicityId);
      }
    },

    buildUserEthnicitiesData() {
      const data = [];

      if (this.ethnicityIdsIncludeNotDisclosedId) {
        this.lawyer.userEthnicities.forEach(userEthnicityObj => {
          if (userEthnicityObj.ethnicityId !== this.prefersNotToDiscloseEthnicityId) {
            data.push({ id: userEthnicityObj.id, _destroy: true });
          }
        });

        if (!this.userEthnicities[this.prefersNotToDiscloseEthnicityId]) {
          data.push({ userId: this.lawyer.id, userType: 'Lawyer', ethnicityId: this.prefersNotToDiscloseEthnicityId });
        }
      }
      else {
        this.lawyer.userEthnicities.forEach(userEthnicity => {
          if (!this.selectedEthnicityIds.includes(userEthnicity.ethnicityId)) {
            data.push({ id: userEthnicity.id, _destroy: true });
          }
        });

        this.selectedEthnicityIds.forEach(ethnicityId => {
          if (!this.userEthnicities[ethnicityId]) {
            data.push({ userId: this.lawyer.id, userType: 'Lawyer', ethnicityId: ethnicityId });
          }
          else {
            data.push({ id: this.userEthnicities[ethnicityId].id, userType: 'Lawyer', userId: this.lawyer.id, ethnicityId: ethnicityId });
          }
        });
      }

      return data;
    },

    getInitialSelectedWorkspaces() {
      const { diversityInclusionViewPermission } = this.lawyer;
      let workspaces = [];

      if (diversityInclusionViewPermission) {
        each(this.workspaces, workspace => {
          if (includes(diversityInclusionViewPermission.workspaceIds, workspace.id)) {
            workspaces.push(workspace);
          }
        });
      }

      return workspaces;
    },

    workspaceIsChecked(workspace) {
      each(this.selectedWorkspaces, selectedWorkspace => {
        if (selectedWorkspace.id === workspace.id) {
          return true;
        }
      });

      return false;
    },

    selectAnyClientCanView() {
      this.clientsCanView = true;
      this.selectWorkspacesCanView = false;
      this.selectedWorkspaces = [];
    },

    selectOnlySelectWorkspacesCanView() {
      this.clientsCanView = false;
      this.selectWorkspacesCanView = true;
      this.selectedWorkspaces = [];
    },

    selectDeclineClients() {
      this.clientsCanView = false;
      this.selectWorkspacesCanView = false;
      this.selectedWorkspaces = [];
    },

    selectAnyLawyers() {
      this.lawyersCanView = true;
    },

    selectDeclineLawyers() {
      this.lawyersCanView = false;
    },

    removeWorkspace(index) {
      this.selectedWorkspaces.splice(index, 1);
    },

    buildPermissionData() {
      return {
        id: this.lawyer.diversityInclusionViewPermission?.id,
        clientsCanView: this.clientsCanView,
        lawyersCanView: this.lawyersCanView,
        selectWorkspacesCanView: this.selectWorkspacesCanView,
        workspaceIds: this.selectedWorkspaces.map(workspace => { return workspace.id; })
      };
    },

    buildLawyerAttributes() {
      return {
        lawyerProfileAttributes: {
          id: this.lawyer.lawyerProfileId,
          identifiesAsLgbtq: identifiesAsLgbtqMap[this.identifiesAsLgbtq]?.int,
          identifiesAsDifferentlyAbled: identifiesAsDifferentlyAbledMap[this.identifiesAsDifferentlyAbled]?.int,
          isMilitaryVeteran: isMilitaryVeteranMap[this.isMilitaryVeteran]?.int,
          genderCodes: [this.selectedGender]
        },
        userEthnicitiesAttributes: this.buildUserEthnicitiesData(),
        scoutDiversityInclusionViewPermissionAttributes: this.buildPermissionData()
      };
    },

    save() {
      const flags = this.flagsToSet;

      this.onSave(this.buildLawyerAttributes(), () => {
        if (flags.length) {
          DataLock.save({
            dataLock: {
              flag:         flags,
              resourceId:   this.lawyer.id,
              resourceType: 'lawyer'
            }
          });
        }
      });
    },

    cancel() {
      this.resetData();
    },

    resetData() {
      this.setUserEthnicitiesSelectedEthnicityIds();
      this.setGenderNotDisclosedIdAndGenderIds();
      this.identifiesAsLgbtq = this.lawyer.identifiesAsLgbtq;
      this.identifiesAsDifferentlyAbled = this.lawyer.identifiesAsDifferentlyAbled;
      this.isMilitaryVeteran = this.lawyer.isMilitaryVeteran;
      this.clientsCanView = !!this.lawyer.diversityInclusionViewPermission?.clientsCanView;
      this.lawyersCanView = !!this.lawyer.diversityInclusionViewPermission?.lawyersCanView;
      this.selectWorkspacesCanView = !!this.lawyer.diversityInclusionViewPermission?.selectWorkspacesCanView;
      this.selectedWorkspaces = this.getInitialSelectedWorkspaces();
    }
  }
};
</script>

<style lang="scss" scoped>
  .options-margin {
    margin-top: 10px;

    @media (min-width: 768px) {
      margin-left: 30px;
    }
  }

  .check-option {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .radio-option {
    display: block;

    &:not(:first-child) {
      margin-top: 10px;
    }

    @media (min-width: 768px) {
      display: inline;
      margin-top: 0;

      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }

  .menu-item-container {
    padding: 5px;

    li {
      padding: 5px 10px;
    }
  }
</style>
