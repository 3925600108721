<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Scout Status
    </div>

    <div class="box-content symmetrical">
      <loading-section name="scoutConfiguration">
        <div v-if="firm.isScout">
          <div class="scout-status">
            <span class="scout-purple-text semibold-weight">Onboarded</span>
            <span class="dark-gray-text left-5" v-if="firm.isConnectingFirm">(Connecting Firm)</span>
          </div>

          <hr>

          <div class="tiny-header-text bottom-10">
            Connected Teams
          </div>

          <div class="row list-spacing-5" v-for="workspace in orderedWorkspaces" :key="workspace.id">
            <div class="col-sm-6 col-md-5">
              <a :href="`/admin-app/workspaces/${workspace.id}`" target="_blank">{{ workspace.name }}</a>
            </div>

            <div class="col-sm-6 col-md-2 text-right-not-xs">
              <span class="green-text semibold-weight" v-if="workspace.isActive">Active</span>
              <span class="dark-gray-text" v-else>Inactive</span>
            </div>
          </div>

          <div class="gray-text size-text-16px" v-if="!firm.scoutTeams.length">
            No connected teams
          </div>
        </div>

        <div class="row scout-status" v-else>
          <div class="col-sm-4 semibold-weight dark-gray-text">
            Not Onboarded
          </div>

          <div class="col-sm-8 top-20-xs" v-if="permittedToConfigure">
            <button type="button" class="pseudo-link-blue text-left bottom-20" @click="openConfirmationModal(false)">Configure Scout Connected Law Firm</button>

            <button type="button" class="pseudo-link-blue text-left" @click="openConfirmationModal(true)">Configure Scout Connecting Law Firm</button>

            <confirmation-modal
              ref="confirmationModal"
              :header="`Configure Scout ${firmTypeText}`"
              :message="`Are you sure you want to configure ${firm.name} as a Scout ${firmTypeText}? This cannot be undone.`"
              :on-confirm-action="proceedToConfigure">
            </confirmation-modal>
          </div>
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ConfirmationModal from 'vue-app/shared/components/confirmation-modal.vue';
import { orderBy } from 'lodash';

export default {
  name: 'FirmScoutStatus',

  components: {
    LoadingSection,
    ConfirmationModal
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    onConfigure: {
      type: Function,
      required: true
    },

    permittedToConfigure: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      configureConnectingFirm: false
    };
  },

  computed: {
    orderedWorkspaces() {
      return orderBy(this.firm.scoutTeams, 'name');
    },

    firmTypeText() {
      return this.configureConnectingFirm ? 'Connecting Firm' : 'Connected Firm';
    }
  },

  methods: {
    openConfirmationModal(asConnectingFirm) {
      this.configureConnectingFirm = asConnectingFirm;
      this.$refs.confirmationModal.openModal();
    },

    proceedToConfigure() {
      this.onConfigure(this.configureConnectingFirm);
    }
  }
};
</script>
