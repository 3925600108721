<template>
  <span :class="['scout-tag wrap-word', scoutTag.color]"><span class="right-5">{{ scoutTag.name }}</span><span class="clickable" @click="onRemoveTag(scoutTag.id)"><svg-icon name="x3" class="base-icon smallest text-top"></svg-icon></span></span>
</template>

<script>
export default {
  name: 'EditableScoutTag',

  props: {
    scoutTag: {
      type: Object,
      required: true
    },

    onRemoveTag: {
      type: Function,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .scout-tag {
    &.blue {
      .base-icon {
        fill: $tag-blue;
      }
    }

    &.gray {
      .base-icon {
        fill: $tag-gray;
      }
    }

    &.green {
      .base-icon {
        fill: $tag-green;
      }
    }

    &.purple {
      .base-icon {
        fill: $tag-purple;
      }
    }

    &.red {
      .base-icon {
        fill: $tag-red;
      }
    }

    &.yellow {
      .base-icon {
        fill: $tag-yellow;
      }
    }
  }
</style>
