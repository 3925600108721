<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="`${titleColClass} text-uppercase`">
          {{ title }}
        </div>

        <div v-if="linksSlotPassed" class="col-sm-7 size-text-12px normal-weight text-right-not-xs">
          <slot name="links"></slot>
        </div>

        <div v-if="tooltipSlotPassed" class="col-sm-2 vertical-center right">
          <slot name="tooltip"></slot>
        </div>
      </div>
    </div>

    <div :class="['box-content', contentClass]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    contentClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    linksSlotPassed() {
      return this.$slots.links;
    },

    tooltipSlotPassed() {
      return this.$slots.tooltip;
    },

    titleColClass() {
      let colClass = 'col-xs-12';

      if (this.linksSlotPassed) {
        colClass = 'col-sm-5';
      }

      if (this.tooltipSlotPassed) {
        colClass = 'col-sm-10';
      }

      return colClass;
    }
  }
};
</script>

<style lang="scss" scoped>
  .right {
    justify-content: right;
  }
</style>
