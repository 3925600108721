<template>
  <edit-modal title="Editing Sustainability & Pro Bono" :input="firm" :on-save="save" :on-cancel="cancelEdit" @on-modal-open="openModal">
    <template #default="{ inputCopy: firmCopy }">
      <loading-section name="profileTaggings">
        <div class="bottom-30">
          <div class="bottom-20 text-italic pill-gray-text size-text-12px">
            Please use the fields below to indicate your firm’s approach to pro bono (program and partners) and sustainability initiatives.
          </div>

          <div class="list">
            <div v-for="tag in sustainabilityTaggings" :key="tag.internalName" class="row list-item">
              <div class="col-xs-12 col-sm-8 bold-weight">
                {{ tag.name }}
              </div>

              <div class="col-xs-6 col-sm-2 top-10-xs">
                <label class="check-option vertical">
                  <span class="normal-weight">Yes</span>
                  <input type="radio" :name="tag.internalName" v-model="tag.value" value="Yes">
                  <span class="check c-radio"></span>
                </label>
              </div>

              <div class="col-xs-6 col-sm-2 top-10-xs">
                <label class="check-option vertical">
                  <span class="normal-weight">No</span>
                  <input type="radio" :name="tag.internalName" v-model="tag.value" value="No">
                  <span class="check c-radio"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="small-bold-header">
          Key Pro Bono Partner Organizations
        </div>

        <hr>

        <div class="bottom-30">
          <div v-for="(item, index) in proBonoPartners" class="bottom-20" :key="index">
            <div class="organization-label">
              <label :for="`partner-${index}`">Organization</label>
            </div>

            <div class="flex">
              <div>
                <a href="" @click.prevent="removePartner(index)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon></a>
              </div>

              <div class="flex-1">
                <input type="text" :id="`partner-${index}`" class="form-control" v-model="item.value">
              </div>
            </div>
          </div>

          <a href="" @click.prevent="addPartner" class="semibold-weight">+ Add Another</a>
        </div>
      </loading-section>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above or remove those entries from the form.
    </template>
  </edit-modal>
</template>

<script>
import EditModal from '../edit-modal';
import ProfileTagging from 'resources/profile-tagging';
import LoadingService from 'vue-app/shared/services/loading-service';
import DataLock from 'resources/data-lock';
import { isEqual } from 'lodash';

export default {
  name: 'EditFirmSustainability',

  components: {
    EditModal
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      sustainabilityTaggings: [],
      profileTaggings: [],
      proBonoPartners: [],
      questionList: [
        {
          internalName: 'has_sustainability_initiatives',
          title: 'Does your law firm participate in sustainability initiatives?'
        },
        {
          internalName: 'has_pro_bono_program',
          title: 'Does your law firm have a pro bono program?'
        }
      ]
    };
  },

  methods: {
    resetForm() {
      this.buildProfileTaggings();
      this.buildProBonoPartners();
    },

    buildProfileTaggings() {
      this.sustainabilityTaggings = [];

      _.each(this.profileTaggings, (tag) => {
        var sustainabilityTag = this.firm.sustainabilityTaggings.find((sustainabilityTag) => sustainabilityTag.internalName === tag.internalName);
        var question = this.questionList.find((question) => question.internalName === tag.internalName);

        if (question) {
          this.sustainabilityTaggings.push({
            id: tag.id,
            internalName: tag.internalName,
            name: question.title,
            value: sustainabilityTag.value
          });
        }
      });
    },

    buildProBonoPartners() {
      this.proBonoPartners = _.map(this.firm.proBonoPartners, (partner) => {
        return { value: partner };
      });
    },

    save() {
      var proBonoPartners = this.proBonoPartners.map(partner => partner.value).filter(partner => partner.trim().length !== 0);
      var withSustainability = this.sustainabilityTaggings.filter(tag => tag.value === 'Yes').map(tag => tag.id);
      var withoutSustainability = this.sustainabilityTaggings.filter(tag => tag.value === 'No').map(tag => tag.id);
      var ids = this.firm.profileTaggingIds.filter((id) => withoutSustainability.indexOf(id) === -1);

      _.each(withSustainability, (id) => {
        if (ids.indexOf(id) === -1) {
          ids.push(id);
        }
      });

      var firmEdited = {
        ...this.firm,
        profileTaggingIds: ids,
        proBonoPartners: proBonoPartners
      };

      const flagsToSet = [];
      const sustainabilityInitiative = this.findSustainabilityTag('has_sustainability_initiatives');
      const proBonoProgram = this.findSustainabilityTag('has_pro_bono_program');

      if (!isEqual(this.firm.proBonoPartners, firmEdited.proBonoPartners)) {
        flagsToSet.push('pro_bono_partners');
      }

      if (this.firm.profileTaggingIds.find(tag => tag === sustainabilityInitiative.id) !== firmEdited.profileTaggingIds.find(tag => tag === sustainabilityInitiative.id)) {
        flagsToSet.push('has_sustainability_initiative');
      }

      if (this.firm.profileTaggingIds.find(tag => tag === proBonoProgram.id) !== firmEdited.profileTaggingIds.find(tag => tag === proBonoProgram.id)) {
        flagsToSet.push('has_pro_bono_program');
      }

      this.onSave(firmEdited, () => {
        if (flagsToSet.length > 0) {
          DataLock.save({
            dataLock: {
              flag:         flagsToSet,
              resourceId:   this.firm.id,
              resourceType: 'law_firm'
            }
          });
        }
      });
    },

    cancelEdit() {
      this.resetForm();
    },

    addPartner() {
      this.proBonoPartners.push({ value: '' });
    },

    removePartner(index) {
      this.proBonoPartners.splice(index, 1);
    },

    openModal() {
      if (!this.profileTaggings.length) {
        LoadingService.loading('profileTaggings');

        ProfileTagging.query({ category: 'sustainability' }).then((response) => {
          this.profileTaggings = response;
          this.resetForm();

          LoadingService.done('profileTaggings');
        });
      }
      else {
        this.resetForm();
      }
    },

    findSustainabilityTag(internalName) {
      return this.sustainabilityTaggings.find(tag => tag.internalName === internalName);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  hr {
    margin-top: 5px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .list {
    .list-item {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $k-light-gray;

      &:first-child {
        border-top: 1px solid $k-light-gray;
      }
    }
  }

  .organization-label {
    margin-left: 28px;
  }
</style>
