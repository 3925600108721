<template>
  <div class="grid-row workspace-list-item">
    <div class="gd workspace-id">
      <a :href="`/admin-app/workspaces/${workspace.id}`" target="_blank">{{ workspace.id }}</a>
    </div>

    <div class="gd">
      <a :href="`/admin-app/workspaces/${workspace.id}`" target="_blank">{{ workspace.name }}</a>
    </div>

    <div class="gd">
      <div class="list-spacing-5" v-for="client in clients" :key="client.id">
        <a :href="`/admin-app/clients/${client.id}`" target="_blank">{{ `${client.firstName} ${client.lastName}` }}</a>
      </div>

      <div v-if="!clients.length">
        &mdash;
      </div>
    </div>

    <div class="gd">
      <select :name="`select-${workspace.id}`" :id="`select-${workspace.id}`" v-model="selectedOption" @change="selectAction">
        <option value="null"></option>

        <option value="viewWorkspace" :name="`viewWorkspace-${workspace.id}`" :id="`view-workspace-${workspace.id}`">
          View Team
        </option>

        <option value="inviteTeamMember" :name="`inviteTeamMember-${workspace.id}`" :id="`invite-team-member-${workspace.id}`">
          Invite Team Member
        </option>

        <option v-if="!workspace.ssoEnabled && !workspace.isScout" value="copyInviteURL" :name="`copyInviteURL-${workspace.id}`" :id="`copy-invite-url-${workspace.id}`">
          Copy Invite URL
        </option>
      </select>

      <div class="text-center">
        <copy-to-clipboard
          ref="copyLink"
          :id="`copy-${workspace.id}`"
          :text-to-copy="inviteUrl"
          tooltip-text="Invite link copied to clipboard">
        </copy-to-clipboard>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';

export default {
  name: 'WorkspaceListItem',

  props: {
    workspace: {
      type: Object,
      required: true
    },

    onStartInvite: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedOption: null
    };
  },

  computed: {
    clients() {
      return orderBy(this.workspace.clients, ['lastName', 'firstName']);
    },

    inviteUrl() {
      return `${window.location.origin}/join-workspace/${this.workspace.uid}`;
    }
  },

  methods: {
    selectAction() {
      switch (this.selectedOption) {
        case 'viewWorkspace':
          this.viewWorkspace();
          break;
        case 'inviteTeamMember':
          this.startInvite();
          break;
        case 'copyInviteURL':
          this.copyInviteUrl();
          break;
      }

      this.selectedOption = null;
    },

    viewWorkspace() {
      window.open(`/admin-app/workspaces/${this.workspace.id}`, '_blank');
    },

    startInvite() {
      this.onStartInvite(this.workspace);
    },

    copyInviteUrl() {
      this.$refs.copyLink.$refs.copyLink.click();
    }
  }
};
</script>
