<template>
  <div :class="['shadowed-box', 'interface-note-box', variant]">
    <svg-icon :name="icon" class="base-icon note-icon"></svg-icon>

    <collapsable-text :text="noteText" :char-limit="maxChars">
      <template #viewMoreButton="slotProps">
        <div class="top-5" v-show="noteText.length > maxChars && !slotProps.viewMore">
          <button type="button" class="button pseudo-link-blue semibold-weight" @click.prevent="slotProps.toggleViewMore">View More</button>
        </div>
      </template>
    </collapsable-text>
  </div>
</template>

<script>

export default {
  name: 'InterfaceNote',

  props: {
    noteText: {
      type:     String,
      required: true
    },

    maxChars: {
      type:    Number,
      default: 300
    },

    icon: {
      type:    String,
      default: 'note'
    },

    variant: {
      type:    String,
      default: 'note'
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .note-icon {
    position: absolute;
    top: 19px;
    left: 15px;
  }

  .interface-note-box {
    padding: 15px 15px 15px 40px;
    border-left: 7px solid $k-blue;

    &.warning {
      background: $p-yellow-bg;
      border-left: 7px solid $p-yellow;

      .note-icon {
        fill: $p-yellow;
      }
    }
  }
</style>
