<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': canEditProfile }]">
          Sustainability &amp; Pro Bono
        </div>

        <div class="col-sm-3 text-right-not-xs" v-if="canEditProfile">
          <edit-firm-sustainability :firm="firm" :on-save="save"></edit-firm-sustainability>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="firm-sustainability">
        <section-wrapper :data="concatenatedData" :editing-allowed="canEditProfile">
          <data-wrapper :data="concatenatedData">
            <section-wrapper class="separated-list-items" :data="firm.sustainabilityTaggings" :editing-allowed="canEditProfile">
              <data-wrapper :data="firm.sustainabilityTaggings">
                <div v-for="(tagging, index) in firm.sustainabilityTaggings" :key="tagging.internalName">
                  <div class="panel-item">
                    <div class="bold-weight">
                      {{ tagging.presentedName }}
                    </div>

                    <div>
                      {{ tagging.value }}
                    </div>
                  </div>

                  <hr v-if="index !== firm.sustainabilityTaggings.length - 1">
                </div>
              </data-wrapper>
            </section-wrapper>

            <section-wrapper class="separated-list-items" :data="firm.proBonoPartners" :editing-allowed="canEditProfile">
              <div class="bold-weight bottom-10">
                Key Pro Bono Partner Organizations
              </div>

              <data-wrapper :data="firm.proBonoPartners">
                <ul class="list">
                  <collapsable-items :items="firm.proBonoPartners">
                    <template #items="slotProps">
                      <li v-for="(partner, index) in slotProps.renderingItems" :key="`${partner}-${index}`">
                        {{ partner }}
                      </li>
                    </template>
                  </collapsable-items>
                </ul>
              </data-wrapper>
            </section-wrapper>
          </data-wrapper>
        </section-wrapper>
      </loading-section>
    </div>
  </div>
</template>

<script>
import { concat } from 'lodash';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditFirmSustainability from './edit-firm-sustainability';
import LoadingService from 'vue-app/shared/services/loading-service';
import CollapsableItems from 'vue-app/shared/components/collapsable-items';

export default {
  name: 'FirmSustainability',

  components: {
    SectionWrapper,
    DataWrapper,
    EditFirmSustainability,
    CollapsableItems
  },

  mixins: [displayRules],

  props: {
    firm: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      showAll: false
    };
  },

  computed: {
    concatenatedData: function () {
      return concat(this.firm.sustainabilityTaggings, this.firm.proBonoPartners);
    },

    displayComponent: function () {
      return this.anyDataIsPresent(
        [
          this.firm.sustainabilityTaggings,
          this.firm.proBonoPartners
        ]
      ) || this.canEditProfile;
    }
  },

  methods: {
    showAllPartnerOrganizations: function () {
      this.showAll = true;
    },

    save: function (firmEdited, successFn) {
      if (!this.canEditProfile) { return; }

      LoadingService.loading('firm-sustainability');

      this.onSave(firmEdited,
        () => {
          LoadingService.done('firm-sustainability');
          if (successFn) { successFn(); }
        },
        () => {
          LoadingService.done('firm-sustainability');
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  div {
    line-height: 1.4;
  }

  hr {
    margin: 10px 0;
  }

  .panel-item {
    column-gap: 10px;
    display: flex;
    justify-content: space-between;
  }

  .list {
    list-style: none;
    padding-left: 15px;
    margin-bottom: 0;

    li {
      margin-top: 5px;
      padding-left: 10px;
      text-indent: -18px;

      &::before {
        content: "•";
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
</style>
