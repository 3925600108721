<template>
  <div>
    <div :class="['bold-weight tight-lines', { 'disabled-text' : !question.isIncluded }]">
      {{ question.questionText }}
      <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
    </div>

    <div v-if="hasAnswer">
      <span :class="['simple-text', { 'disabled-text' : !question.isIncluded }]">{{ answer.value }}</span>
    </div>

    <div v-else :class="['missing-text', { 'disabled-text' : !question.isIncluded }]">
      No answer provided
    </div>
  </div>
</template>

<script>
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import { startCase } from 'lodash';

export default {
  name: 'QuestionAnswerText',

  components: {
    ScoutTag
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    answer: {
      type: Object,
      required: false
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasAnswer() {
      return this.answer && this.answer.value && this.answer.value.length;
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  }
};
</script>
