let Draggable = {
  inserted: function (el, binding, _vnode) {
    el.draggable = true;

    el.addEventListener('dragstart', function (e) {
      e.dataTransfer.setData('text', binding.value.id);
      e.dataTransfer.effectAllowed = 'move';
      this.classList.add('dragging');
    }, false);

    el.addEventListener('dragend', function () {
      this.classList.remove('dragging');
    }, false);
  }
};

export default Draggable;
