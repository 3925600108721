<template>
  <div class="shadowed-box clearfix">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-xs-6 text-uppercase">
          Team
        </div>

        <div class="col-xs-6 normal-weight size-text-13px text-right" v-if="!hideLink">
          <a :href="`/admin-app/workspaces/${workspace.id}`" target="_blank">View Team</a>
        </div>
      </div>
    </div>

    <div :class="['top-30 left-30 right-30 bottom-20', { 'bottom-30': isLoading }]">
      <loading-section name="workspaceUsers">
        <div class="row tight-columns">
          <div class="col-sm-6 bottom-10" v-for="user in workspaceUsers" :key="user.id">
            <admin-workspace-user :user="user" :is-scout="workspace.isScout"></admin-workspace-user>
          </div>
        </div>

        <div class="size-text-base gray-text text-center bottom-30" v-if="!workspaceUsers.length">
          Client has not added any team members
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import AdminWorkspaceUser from './admin-workspace-user';

export default {
  name: 'AdminWorkspaceUsers',

  components: {
    LoadingSection,
    AdminWorkspaceUser
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    workspaceUsers: {
      type: Array,
      required: true
    },

    hideLink: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isLoading() {
      return LoadingService.isLoading('workspaceUsers');
    }
  }
};
</script>
