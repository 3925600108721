<template>
  <div>
    <div class="score-selector">
      <button type="button" :class="['score-item', { 'selected': isSelected(option) }]" v-for="option in options" :key="`question-rec-${option}`" @click="select(option)">{{ option }}</button>
    </div>

    <div class="row top-5">
      <div class="col-xs-4">
        Not at all<span class="hidden-xs"> likely</span>
      </div>

      <div class="col-xs-4 text-center">
        Neutral
      </div>

      <div class="col-xs-4 text-right">
        Extremely<span class="hidden-xs"> likely</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionRecommendation',

  props: {
    value: {
      default: null
    }
  },

  data() {
    return {
      score: this.value
    };
  },

  computed: {
    options() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    }
  },

  methods: {
    isSelected(score) {
      return this.score === score;
    },

    select(score) {
      this.score = score;
      this.$emit('update-recommendation', score);
    }
  }
};
</script>
