<template>
  <div class="resource-list static-list">
    <reviews-list-item
      v-for="review in reviews"
      :key="review.id"
      :review="review"
      :review-template="reviewTemplate"
      :reviews-type="reviewsType">
    </reviews-list-item>
  </div>
</template>

<script>
import ReviewsListItem from './reviews-list-item.vue';

export default {
  name: 'ReviewsList',

  components: {
    ReviewsListItem
  },

  props: {
    reviews: {
      type: Array,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    reviewsType: {
      type: String,
      required: true
    }
  }
};
</script>
