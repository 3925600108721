<template>
  <div>
    <div v-if="isSectionSelected('firm_size')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="clients" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-10 text-ellipsis">
          <div class="row tight-columns">
            <div class="col-sm-4">
              <span class="semibold-weight">Firm Size</span>
            </div>

            <div class="col-sm-8 top-10-xs">
              <select name="firmSize" :id="`firm-size-${possibleLawyer.id}`" class="form-control" v-model="possibleLawyer.firmSize" @change="broadcastChanges">
                <option disabled value="">Select one</option>
                <option v-for="size in firmSizes" :value="size">{{ size }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-xs-2 col-sm-1 text-right" v-if="!isRetiredOrRejected">
          <div class="delete-icon" @click.prevent="removeSections(['firm_size'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></div>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('firm_rate')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="rate" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-10 text-ellipsis">
          <div class="row tight-columns">
            <div class="col-sm-4">
              <span class="semibold-weight">Firm Rate</span>
            </div>

            <div class="col-sm-8 top-10-xs">
              <textarea :id="`firm-rate-${possibleLawyer.id}`" class="form-control" v-model.lazy="possibleLawyer.firmRate" @change="broadcastChanges"></textarea>
            </div>
          </div>
        </div>

        <div class="col-xs-2 col-sm-1 text-right" v-if="!isRetiredOrRejected">
          <div class="delete-icon" @click.prevent="removeSections(['firm_rate'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></div>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('firm_location')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="location" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-10 text-ellipsis">
          <div class="row tight-columns">
            <div class="col-sm-4">
              <span class="semibold-weight">Firm Location</span>
            </div>

            <div class="col-sm-8 top-10-xs">
              <select name="firmLocation" :id="`firm-location-${possibleLawyer.id}`" class="form-control" v-model="possibleLawyer.firmLocation" @change="broadcastChanges">
                <option disabled value="">Select one</option>
                <option v-for="location in firmLocations" :value="location">{{ location }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-xs-2 col-sm-1 text-right" v-if="!isRetiredOrRejected">
          <div class="delete-icon" @click.prevent="removeSections(['firm_location'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></div>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div v-if="isSectionSelected('firm_featured_lawyers')">
      <div class="row tight-columns">
        <div class="col-xs-2 col-sm-1">
          <svg-icon name="clients" class="base-icon"></svg-icon>
        </div>

        <div class="col-xs-8 col-sm-10 text-ellipsis">
          <div class="row tight-columns">
            <div class="col-sm-4">
              <span class="semibold-weight">Featured People</span>
            </div>

            <div class="col-sm-8 top-10-xs">
              <div class="scrollable-checklist form-control">
                <loading-section :name="`firmLawyerOptions${possibleLawyer.id}`">
                  <label class="check-option vertical medium-large" :for="`selected-${index}-${possibleLawyer.id}`" v-for="(lawyer, index) in firmLawyerOptions" :key="lawyer.id">
                    {{ lawyer.fullName }}
                    <input type="checkbox" :name="`selected${index}`" :id="`selected-${index}-${possibleLawyer.id}`" v-model="lawyer.selected" @change="broadcastChanges">
                    <span class="check c-box"></span>
                  </label>
                </loading-section>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-2 col-sm-1 text-right" v-if="!isRetiredOrRejected">
          <div class="delete-icon" @click.prevent="removeSections(['firm_featured_lawyers'])"><svg-icon name="x-circle" class="base-icon"></svg-icon></div>
        </div>
      </div>

      <hr class="kenny-hr">
    </div>

    <div class="row" v-if="!isRetiredOrRejected">
      <div class="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
        <edit-sections-modal
          :modal-id="`edit-sections-${possibleLawyer.id}`"
          :selected-sections="possibleLawyer.displaySections"
          :available-sections="availableSections"
          :on-change="onSectionsEdited">
        </edit-sections-modal>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import EditSectionsModal from './edit-sections-modal.vue';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import LoadingService from 'vue-app/shared/services/loading-service';
import { difference, filter, find, xor } from 'lodash';

export default {
  name: 'ProposalFirmSections',

  components: {
    SvgIcon,
    LoadingSection,
    EditSectionsModal
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    isRfqQuote: {
      type: Boolean,
      default: false
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      firmSizes: SharedLists.organizationSizes(),
      firmLawyerOptions: [],
      allAvailableSections: [
        { slug: 'firm_size',             label: 'Firm Size' },
        { slug: 'firm_rate',             label: 'Firm Rate' },
        { slug: 'firm_location',         label: 'Firm Location' },
        { slug: 'firm_featured_lawyers', label: 'Featured People' }
      ]
    };
  },

  computed: {
    isRetiredOrRejected() {
      return this.possibleLawyer.retiredOrRejected();
    },

    displaySections() {
      return this.possibleLawyer.displaySections;
    },

    lawFirm() {
      return this.possibleLawyer.lawFirm;
    },

    firmLocations() {
      return this.lawFirm.lawFirmLocationsAttributes.map((location) => {
        const stateOrCountry = (location.country === 'United States' ? SharedLists.stateAbbreviationFromName(location.state) : location.country);
        return `${location.city}, ${stateOrCountry}`;
      });
    },

    firmLawyers() {
      return this.lawFirm.alspAccount ? this.lawFirm.alspMembers : this.lawFirm.lawyers;
    },

    featuredLawyersAttributes() {
      const selected = filter(this.firmLawyerOptions, { selected: true }).map(el => ({ id: el.featuredLawyerId, featurableType: el.klass, featurableId: el.id }));
      const notSelected = filter(this.firmLawyerOptions, { selected: false }).map(el => ({ id: el.featuredLawyerId, _destroy: '1' }));

      return [...selected, ...notSelected];
    },

    availableSections() {
      if (this.isRfqQuote) {
        return this.allAvailableSections.filter(section => !['firm_rate', 'firm_featured_lawyers'].includes(section.slug));
      }
      else {
        return this.allAvailableSections;
      }
    }
  },

  mounted() {
    this.updateFirmLawyerOptions(this.possibleLawyer);
  },

  methods: {
    isSectionSelected(slug) {
      return this.displaySections.includes(slug);
    },

    removeSections(slugs) {
      this.possibleLawyer.displaySections = difference(this.displaySections, slugs);
      this.onChange({ displaySections: this.possibleLawyer.displaySections });
    },

    onSectionsEdited(editedSections) {
      if (xor(editedSections, this.possibleLawyer.displaySections).length) {
        this.possibleLawyer.displaySections = editedSections;
        this.onChange({ displaySections: this.possibleLawyer.displaySections })
          .then((possibleLawyer) => {
            this.updateFirmLawyerOptions(possibleLawyer);
          });
      }
    },

    updateFirmLawyerOptions(possibleLawyer) {
      this.firmLawyerOptions = this.firmLawyers.map((lawyer) => {
        const featured = find(possibleLawyer.featuredLawyers, { featurableType: lawyer.klass, featurableId: lawyer.id });
        return Object.assign(lawyer, { featuredLawyerId: featured?.id, selected: !!featured });
      });

      LoadingService.done(`firmLawyerOptions${this.possibleLawyer.id}`);
    },

    broadcastChanges() {
      const params = {
        displaySections: this.possibleLawyer.displaySections,
        firmRate: this.possibleLawyer.firmRate,
        firmLocation: this.possibleLawyer.firmLocation,
        firmSize: this.possibleLawyer.firmSize,
        featuredLawyersAttributes: this.featuredLawyersAttributes
      };

      LoadingService.loading(`firmLawyerOptions${this.possibleLawyer.id}`);

      this.onChange(params).then((possibleLawyer) => {
        this.updateFirmLawyerOptions(possibleLawyer);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .delete-icon {
    cursor: pointer;
  }
</style>
