<template>
  <priori-modal ref="modal" title="Delete Template">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">Delete Template</a>
    </template>

    <template #default="{ closeModal }">
      <div class="size-text-14px">
        Are you sure you want to delete this template? This action cannot be undone.
      </div>

      <div class="row tight-columns top-30">
        <div class="col-sm-5">
          <loading-button name="deleteTemplate" lb-class="primary-btn-red" @lb-click="deleteTemplate">Delete Template</loading-button>
        </div>

        <div class="col-sm-3 top-10-xs">
          <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import RFQTemplate from 'resources/scout/rfq-template.js';

export default {
  name: 'DeleteTemplateModal',

  components: {
    LoadingButton,
    PrioriModal
  },

  props: {
    template: {
      type: Object,
      required: true
    },

    onDeleteTemplate: {
      type: Function,
      required: true
    }
  },

  methods: {
    deleteTemplate() {
      LoadingService.loading('deleteTemplate');
      return RFQTemplate.delete({ id: this.template.id }).then(() => {
        this.onDeleteTemplate(this.template);
        this.$refs.modal.closeModal();
        LoadingService.done('deleteTemplate');
      });
    }
  }
};
</script>
