<template>
  <div class="row">
    <div class="col-sm-4">
      <label v-if="label" :class="{ 'no-margin': !editMode }">{{ label }} <span v-if="required" class="inline-help required">*</span></label>
    </div>

    <div v-show="editMode" class="col-sm-4">
      <dropdown ref="dropdown" tag="div" :id="idLabel" class="k-dropdown" :disabled="isDisabled" v-model="dropdownOpen">
        <button type="button" role="button" :id="`k-toggle-button-${idLabel}`" :class="['dropdown-toggle', { 'placeholder': showPlaceholder, 'invalid': invalid, 'disabled': isDisabled }]">
          <slot name="icon"></slot>
          <span v-if="showPlaceholder">{{ placeholder }}</span>
          <span v-if="!showPlaceholder" class="semibold-weight">{{ visibleLabel }}</span>
          <svg-icon name="caret-down" class="caret-down"></svg-icon>
          <svg-icon name="caret-up" class="caret-up"></svg-icon>
        </button>

        <template #dropdown>
          <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
            <li role="menuitem" class="menu-item" @click.prevent="onNullSelect" v-if="allowNull">
              <div class="dropdown-row-inner missing-text">
                -- {{ placeholder || 'Select One' }} --
              </div>
            </li>

            <li role="menuitem" class="menu-item" v-for="option in options" :key="generateKey(option)" @click="onSelect(option)">
              <div class="dropdown-row-inner semibold-weight">
                {{ optionLabel(option) }}
              </div>
            </li>
          </div>
        </template>
      </dropdown>
    </div>

    <div v-if="!editMode" class="col-sm-8">
      {{ visibleLabel || '—' }}
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown.js';
import { isUndefined } from 'lodash';

export default {
  name: 'DropdownSelectHorizontal',

  components: {
    SvgIcon
  },

  directives: {
    uivScrollableDropdown
  },

  props: {
    label: {
      type: String,
      required: false
    },

    value: {
      type: [String, Number, Object, Boolean],
      required: false
    },

    options: {
      type: Array,
      default: () => []
    },

    idLabel: {
      type: String,
      required: true
    },

    placeholder: {
      type: String,
      default: 'Select One'
    },

    initialLabel: {
      type: String,
      default: null
    },

    labelKey: {
      type: String,
      default: null
    },

    valueKey: {
      type: String,
      default: null
    },

    isDisabled:  {
      type: Boolean,
      default: false
    },

    allowNull: {
      type: Boolean,
      default: false
    },

    invalid: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    editMode: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      dropdownOpen: false,
      hasSelected: false,
      selectedOption: null
    };
  },

  computed: {
    visibleLabel() {
      if (this.hasSelected) {
        return this.optionLabel(this.selectedOption);
      }
      else if (this.selectedOption && this.allowNull && this.selectedOption[this.valueKey] === null) {
        return (this.editMode ? this.placeholder : null);
      }
      else {
        return this.initialLabel;
      }
    },

    showPlaceholder() {
      return !this.hasSelected && !this.initialLabel;
    }
  },

  watch: {
    dropdownOpen() {
      if (!this.dropdownOpen) {
        this.$emit('blur');
      }
    },

    value(newValue) {
      const selectedOption = this.options.find(o => (this.valueKey ? o[this.valueKey] : o) === newValue);

      this.selectedOption = selectedOption;
      this.hasSelected = !!selectedOption;
    }
  },

  methods: {
    optionLabel(option) {
      return option[this.labelKey] || option;
    },

    onSelect(selectedOption) {
      this.hasSelected = true;
      this.selectedOption = selectedOption;

      const value = isUndefined(this.selectedOption[this.valueKey]) ? selectedOption : this.selectedOption[this.valueKey];

      this.$emit('input', value);
      this.$emit('change', value);
    },

    onNullSelect() {
      this.hasSelected = false;

      if (this.valueKey) {
        const emptyOption = {};
        emptyOption[this.valueKey] = null;
        emptyOption[this.labelKey] = null;
        this.selectedOption = emptyOption;
      }
      else {
        this.selectedOption = null;
      }

      this.$emit('input', null);
      this.$emit('change', null);
    },

    generateKey(option) {
      const keyAttributes = [option[this.labelKey], option[this.valueKey]].filter((e) => e);
      if (keyAttributes.length) { return keyAttributes.join('-'); }
      return option;
    },

    reset() {
      this.hasSelected = false;
      this.selectedOption = null;
    }
  }
};
</script>
