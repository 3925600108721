<template>
  <div class="box-content contrasting">
    <div class="row clickable" @click="toggleHighlights">
      <div class="col-xs-10 semibold-weight">
        Keyword Search Highlights ({{ numResultsText }})
      </div>

      <div class="col-xs-2 text-right">
        <a href="" class="darker-gray-text" @click.prevent><svg-icon name="plus" class="base-icon smaller" v-if="!expanded"></svg-icon><svg-icon name="minus" class="base-icon smaller" v-if="expanded"></svg-icon></a>
      </div>
    </div>

    <div v-show="expanded">
      <hr class="kenny-hr">

      <div class="tiny-header-text bottom-5">
        Highlights
      </div>

      <div class="search-highlights list-spacing-5" v-for="(highlights, fieldName, index) in searchHighlights" :key="index">
        <span class="semibold-weight">{{ fieldLabel(fieldName) }}:</span>
        <span v-for="(highlight, inner) in highlights" :key="inner"><span v-html="highlight"></span><span v-if="inner !== highlights.length - 1">; </span></span>
      </div>

      <div class="dark-gray-text" v-if="numHighlights === 0">
        No Highlights
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { reduce, startCase, uniqueId } from 'lodash';

export default {
  name: 'LeadSearchHighlights',

  components: {
    SvgIcon
  },

  props: {
    searchResult: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      expanded: false
    };
  },

  computed: {
    searchHighlights() {
      return this.searchResult.searchHighlights;
    },

    numHighlights() {
      return reduce(this.searchHighlights, (sum, highlightArray) => {
        return sum + highlightArray.length;
      }, 0);
    },

    numResultsText() {
      if (this.numHighlights === 0) {
        return 'No Results';
      }
      else {
        return this.$pluralize('Result', this.numHighlights, true);
      }
    }
  },

  methods: {
    keyFor(prefix) {
      return uniqueId(prefix);
    },

    fieldLabel(label) {
      return startCase(label.replace('lawyerProfile', '')).replace('Linked In', 'LinkedIn');
    },

    toggleHighlights() {
      this.expanded = !this.expanded;
    }
  }
};
</script>
