<template>
  <div>
    <div class="bold-weight size-text-14px bottom-15">
      {{ groupHeading }}
    </div>

    <div v-for="(tagsInRange, alphabetRange, index) in groupedAndSortedTags" :key="alphabetRange">
      <div :class="['bold-weight size-text-12px bottom-10', { 'top-20': index > 0 }]">
        {{ alphabetRange }}
      </div>

      <div class="d-flex wrap col-gap-8 row-gap-10">
        <scout-tag
          v-for="scoutTag in tagsInRange"
          :scout-tag="scoutTag"
          :key="scoutTag.id"
          ref="tags">
        </scout-tag>
      </div>
    </div>
  </div>
</template>

<script>
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import { orderBy, filter, omitBy, isEmpty, toLower, startCase } from 'lodash';

export default {
  name: 'GroupedTags',

  components: {
    ScoutTag
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    groupBy: {
      type: String,
      required: true
    }
  },

  computed: {
    sortedByNameTags() {
      const tags = filter(this.firm.scoutTags, tag => tag.tagType === this.groupBy);
      return orderBy(tags, 'name', 'asc');
    },

    groupedAndSortedTags() {
      return omitBy({
        'A–F': this.byAlphabetRangeTags(this.sortedByNameTags, 'A', 'F'),
        'G–L': this.byAlphabetRangeTags(this.sortedByNameTags, 'G', 'L'),
        'M–R': this.byAlphabetRangeTags(this.sortedByNameTags, 'M', 'R'),
        'S–Z': this.byAlphabetRangeTags(this.sortedByNameTags, 'S', 'Z')
      }, isEmpty);
    },

    groupHeading() {
      return `All ${startCase(toLower(this.groupBy))} Tags`;
    }
  },

  methods: {
    byAlphabetRangeTags(tags, startChar, finishChar) {
      return filter(tags, (tag) => {
        const firstChar = tag.name.charAt(0).toUpperCase();
        return firstChar >= startChar && firstChar <= finishChar;
      });
    }
  }
};
</script>
