<template>
  <div>
    <div class="rating-circle bottom-20">
      <rating-circle :rating="rating" :size="'large'" :color-class="colorClass">
        <div v-if="scoreDataPresent">
          <span class="size-text-36px">{{ recommendationScore }}</span>
          <span class="size-text-18px dark-gray-text"> / 10</span>
        </div>

        <div v-else class="size-text-36px">
          N/A
        </div>
      </rating-circle>
    </div>

    <div v-if="scoreDataPresent" class="text-center">
      <span class="size-text-14px" v-if="customText">
        {{ customText }}
      </span>

      <span class="size-text-16px" v-else>
        Score is based on <b>{{ reviewsCountText }}</b> submitted by <b>{{ reviewersCount }} of your peers</b>
      </span>
    </div>

    <div v-else class="size-text-16px text-center dark-gray-text">
      Not yet reviewed by any of your peers
    </div>
  </div>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';

export default {
  name: 'ReviewsAverageScore',

  components: {
    RatingCircle
  },

  props: {
    reviewsSummary: {
      type: Object,
      required: true
    },

    colorClass: {
      type: String,
      default: null
    },

    customText: {
      type: String,
      required: false
    }
  },

  computed: {
    rating: function () {
      const percent = this.recommendationScore ? this.recommendationScore * 10 : null;
      return { percent: percent };
    },

    recommendationScore: function () {
      const recData = this.reviewsSummary.scoreAverages.find((score) => {
        return score.field === 'recommendation';
      }) || {};

      return recData.score;
    },

    reviewsCount: function () {
      return this.reviewsSummary.count;
    },

    reviewsCountText: function () {
      return `${this.reviewsCount} ${this.$pluralize('review', this.reviewsCount)}`;
    },

    reviewersCount: function () {
      return this.reviewsSummary.uniqueReviewersCount;
    },

    scoreDataPresent: function () {
      return this.reviewsCount > 0 && this.recommendationScore;
    }
  }
};
</script>

<style lang="scss" scoped>
  .rating-circle {
    display: flex;
    justify-content: center;
  }
</style>
