<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <form class="a-form" novalidate>
        <div class="size-text-18px bold-weight bottom-20">
          Confirm Budget
        </div>

        <div v-if="showInputBudget || !validBudget" class="next-steps-content">
          <div class="row vertical-center-not-xs">
            <div class="col-sm-5">
              <div class="vertical-center-not-xs gap-15">
                <div class="svg-circle">
                  <svg-icon name="calc-check" class="base-icon medium stroke"></svg-icon>
                </div>

                <div class="top-20-xs">
                  <div class="bold-weight size-text-15px">
                    Confirm Budget
                  </div>

                  <div class="size-text-14px">
                    Input the total budget for this RFP.
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!showInputBudget" class="col-sm-3 col-sm-offset-4 top-20-xs">
              <button type="button" class="nv-button-blue" @click="showBudgetFields">Input Budget</button>
            </div>

            <div v-if="showInputBudget" class="col-sm-7 top-20-xs">
              <validation-observer v-slot="{ handleSubmit }">
                <div class="row tight-columns">
                  <div class="col-sm-6 col-lg-7">
                    <currency-input-vertical
                      v-model.number="confirmedBudgetAmount"
                      id="confirmed-budget"
                      name="confirmedBudget"
                      placeholder="Input Final Budget"
                      rules="required|minValue:0.01"
                      validation-mode="eager">
                    </currency-input-vertical>
                  </div>

                  <div class="col-sm-6 col-lg-5 top-20-xs">
                    <div class="row tight-columns">
                      <div class="col-sm-6">
                        <loading-button name="saveConfirmationBudget" lb-class="nv-button-blue" @lb-click="handleSubmit(save)">Save</loading-button>
                      </div>

                      <div class="col-sm-6 top-10-xs">
                        <button type="button" class="nv-button-white" @click="cancel">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </validation-observer>
            </div>
          </div>
        </div>

        <div v-else class="next-steps-content completed">
          <div class="row">
            <div class="col-sm-5">
              <div class="vertical-center-not-xs gap-15">
                <div class="svg-circle">
                  <svg-icon name="calc-check" class="base-icon medium stroke"></svg-icon>
                </div>

                <div class="top-20-xs">
                  <div class="bold-weight size-text-15px">
                    Confirmed Budget
                  </div>

                  <div class="size-text-14px">
                    Input the total budget for this RFP.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-7 top-20-xs">
              <div class="confirmed-content">
                <div class="row tight-columns vertical-center-not-xs">
                  <div class="col-sm-10 vertical-center-not-xs">
                    <span class="right-10">Confirmed Budget:</span>
                    <div class="visible-xs-block top-10-xs"></div>
                    <span class="bold-weight size-text-18px">{{ formatBudget }}</span>
                  </div>

                  <div class="col-sm-2 text-right-not-xs">
                    <a href="" class="bold-weight" @click.prevent="editBudget">Edit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { ValidationObserver } from 'vee-validate';
import RFQ from 'resources/scout/rfq.js';
import CurrencyFormatter from 'vue-app/shared/misc/currency-formatter.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

export default {
  name: 'ConfirmBudget',

  components: {
    CurrencyInputVertical,
    LoadingButton,
    SvgIcon,
    ValidationObserver
  },

  props: {
    rfq: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showInputBudget: false,
      confirmedBudgetAmount: this.rfq.confirmedBudgetInUsCents ? this.rfq.confirmedBudgetInUsCents / 100 : null
    };
  },

  computed: {
    validBudget() {
      const decimalPattern = /^\d*\.?\d+$/;
      return this.confirmedBudgetAmount && decimalPattern.test(this.confirmedBudgetAmount);
    },

    formatBudget() {
      return CurrencyFormatter.dynamicDecimals().format(this.confirmedBudgetAmount);
    }
  },

  methods: {
    showBudgetFields() {
      this.showInputBudget = true;
    },

    editBudget() {
      this.showInputBudget = true;
    },

    save() {
      LoadingService.loading('saveConfirmationBudget');

      return RFQ.confirmBudget({ id: this.rfq.id, confirmed_budget_in_us_cents: this.confirmedBudgetAmount * 100 })
        .then(() => {
          this.rfq.confirmedBudgetInUsCents = this.confirmedBudgetAmount * 100;
          this.showInputBudget = false;
          LoadingService.done('saveConfirmationBudget');
        });
    },

    cancel() {
      this.showInputBudget = false;
      this.confirmedBudgetAmount = (this.rfq.confirmedBudgetInUsCents || 0) / 100;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .confirmed-content {
    padding: 15px 20px;
    border: 1px solid $gray-6;
    border-radius: $border-radius-base;
    background: $bg-color;
  }
</style>
