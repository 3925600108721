<template>
  <div class="shadowed-box">
    <div class="box-header compact text-uppercase">
      Admin Links
    </div>

    <div class="box-content size-text-13px">
      <div class="semibold-weight bottom-10">
        RFP Tracking
      </div>

      <div class="left-10 bottom-20">
        <div class="bottom-5">
          <a href="/admin-app/matching">Request Matching</a>
        </div>

        <div>
          <a href="/admin-app/requests/trackers">Incomplete Requests</a>
        </div>
      </div>

      <div class="semibold-weight bottom-10">
        Support
      </div>

      <div class="left-10 bottom-20">
        <div class="bottom-5">
          <a href="/admin-app/toolbox/inquiries">Open Sales Inquiries</a>
        </div>

        <div>
          <a href="/admin-app/request-questions">Request Questions</a>
        </div>
      </div>

      <div class="semibold-weight bottom-10">
        Scout Lawyers
      </div>

      <div class="left-10">
        <div class="bottom-5">
          <a href="/admin-app/scout-lawyers/search">Search</a>
        </div>

        <div>
          <a href="/admin-app/scout-lawyers">Index</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminLinks'
};
</script>
