<template>
  <div class="text-center">
    <div class="dark-gray-text count-label">
      {{ label }}
    </div>

    <div class="size-text-30px count-content">
      <span v-if="hasDiversityValue">{{ value }}</span>
      <span v-else>&mdash;</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FirmDiversityDetailCount',

  props: {
    label: {
      type: String,
      required: true
    },

    value: {
      type: Number,
      required: false
    }
  },

  computed: {
    hasDiversityValue() {
      return this.value !== null;
    }
  }
};
</script>

<style lang="scss" scoped>
  .count-label {
    height: 40px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.4;
  }

  .count-content {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
