<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div class="bottom-15">
        <div class="semibold-weight">
          Account Type
        </div>

        <div :class="{ 'purple-text': !isConverted, 'green-text': isConverted }">
          Lawyer Lead <span v-if="isConverted">&mdash; Converted</span>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Salesforce Lead ID
        </div>

        <div>
          <a :href="`https://priori.my.salesforce.com/${lawyerLead.salesforceLeadId}`" target="_blank" v-if="lawyerLead.salesforceLeadId">{{ lawyerLead.salesforceLeadId }}</a>
          <span v-else>&mdash;</span>
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Lead Source
        </div>

        <div>
          {{ lawyerLead.source || '&mdash;' }}
        </div>
      </div>

      <div class="bottom-15">
        <div class="semibold-weight">
          Lead Source Detail
        </div>

        <div>
          {{ lawyerLead.sourceDetail || '&mdash;' }}
        </div>
      </div>

      <div>
        <div class="semibold-weight">
          Date Created
        </div>

        <div>
          {{ createdAt }}
        </div>
      </div>

      <div v-if="isConverted || canBeConverted">
        <hr class="kenny-hr normal">
      </div>

      <div v-if="canBeConverted">
        <form role="form" class="a-form" novalidate>
          <label for="lead-status">Convert To:</label>

          <dropdown-select
            ref="statusSelect"
            name="leadStatus"
            id-label="lead-status"
            placeholder="Select New Status"
            value-key="slug"
            label-key="label"
            v-model="convertTo"
            :options="convertOptions"
            @change="openConfirmationModal">
          </dropdown-select>
        </form>
      </div>

      <div v-if="isConverted">
        <a :href="`/admin-app/lawyers/${lawyerLead.lawyerId}`" class="semibold-weight">View Lawyer Details</a>
      </div>
    </div>

    <request-confirmation
      ref="confirmationModal"
      :message="confirmMessage"
      @on-modal-yes="confirmFunction"
      @on-modal-close="reset">
    </request-confirmation>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import { startCase, find } from 'lodash';

export default {
  name: 'LeadStatus',

  props: {
    lawyerLead: {
      type: Object,
      required: true
    },

    onLeadConverted: {
      type: Function,
      required: true
    }
  },

  data: function () {
    return {
      // TODO: add the base profile options back when we have a proper flow
      convertOptions: [
        { slug: 'la', label: 'Lawyer - Applying', accountType: 'lawyer', status: 'applying' },
        // { slug: 'lb', label: 'Lawyer - Base Profile', accountType: 'lawyer', status: 'base_profile' },
        { slug: 'pa', label: 'Paralegal - Applying', accountType: 'paralegal', status: 'applying' }
        // { slug: 'pb', label: 'Paralegal - Base Profile', accountType: 'paralegal', status: 'base_profile' }
      ],

      convertSelected: null,

      convertTo: null
    };
  },

  computed: {
    isConverted: function () {
      return !!this.lawyerLead.lawyerId;
    },

    canBeConverted: function () {
      return this.lawyerLead.id && !this.isConverted;
    },

    accountTypeLabel: function () {
      return startCase(this.convertSelected?.accountType);
    },

    statusLabel: function () {
      return startCase(this.convertSelected?.status);
    },

    confirmMessage: function () {
      return `This will bring the lead into the network (as a ${this.accountTypeLabel} with ${this.statusLabel} status) and convert the Salesforce Lead into a Contact. Do you want to proceed?`;
    },

    createdAt: function () {
      return DateFilter.longWithAt(this.lawyerLead.createdAt);
    }
  },

  methods: {
    openConfirmationModal: function () {
      if (!this.canBeConverted) { return; }

      this.convertSelected = find(this.convertOptions, { slug: this.convertTo });
      this.$refs.confirmationModal.openModal();
    },

    confirmFunction: function () {
      this.onLeadConverted(
        this.convertSelected.accountType,
        this.convertSelected.status,
        this.reset
      );
    },

    reset: function () {
      this.convertTo = null;
      this.convertSelected = null;
      this.$refs.statusSelect.reset();
    }
  }
};
</script>
