<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-4 text-uppercase">
          Search Filters
        </div>

        <div class="col-sm-8">
        </div>
      </div>
    </div>

    <div class="box-content">
      <div v-for="(filters, key, index) in filterGroups" :key="`${key}-filters`">
        <div class="size-text-14px bold-weight bottom-5">
          {{ filterTitle(key) }} Search Filters
        </div>

        <div class="dark-gray-text bottom-20">
          Scout includes a wide range of search filters in {{ filterText(key) }} search, some of which will be displayed as default filters when users arrive on the search page
        </div>

        <div class="row tight-columns">
          <div class="col-sm-6 col-md-3 list-spacing-10" v-for="filter in filters" :key="`${key}-${filter.slug}`">
            <div class="search-filter">
              <div class="row no-gutter">
                <div class="col-xs-2 col-md-4 col-lg-3">
                  <svg-icon name="checkmark" class="base-icon lighter-gray"></svg-icon>
                </div>

                <div class="col-xs-10 col-md-8 col-lg-9">
                  {{ filter.label }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr v-if="index === 0">
      </div>
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash';

export default {
  name: 'SearchFilters',

  props: {
    lawyerFilters: {
      type: Array,
      default: () => []
    },

    firmFilters: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    filterGroups() {
      return {
        'lawyer': this.lawyerFilters,
        'lawFirm': this.firmFilters
      };
    }
  },

  methods: {
    filterTitle(key) {
      return startCase(key);
    },

    filterText(key) {
      return this.filterTitle(key).toLowerCase();
    }
  }
};
</script>

<style scoped lang="scss">
  .search-filter {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    line-height: 1.3;
  }
</style>
