<template>
  <div>
    <div class="dash-call-section shadowed-box">
      <div class="box-header text-uppercase">
        Scheduled Appointments
      </div>

      <div class="box-content">
        <loading-section name="consultations">
          <div v-if="consultationsPage.length">
            <div class="dash-call-content">
              <div class="dash-call-schedule" v-for="(consultation, index) in consultationsPage" :key="consultation.id">
                <call-schedule-item
                  :consultation="consultation"
                  :on-message-user="onMessageUser"
                  :on-cancel-consultation="startCancelling"
                  :on-reschedule-consultation="startRescheduling">
                </call-schedule-item>

                <hr class="kenny-hr" v-if="index + 1 !== consultationsPage.length">
              </div>
            </div>

            <div class="top-20" v-if="consultations.length > 2">
              <hr class="kenny-hr no-margin">

              <div class="dash-call-pager" v-if="consultations.length > 2">
                <div class="row">
                  <div class="col-sm-6 col-sm-offset-3 text-center">
                    <button type="button" class="page-button right-15" :disabled="isFirstPage" @click="previousPage"><svg-icon name="caret-up" class="base-icon"></svg-icon></button>

                    <button type="button" class="page-button" :disabled="isLastPage" @click="nextPage"><svg-icon name="caret-down" class="base-icon"></svg-icon></button>
                  </div>

                  <div class="col-sm-3">
                    <div class="pager-info">
                      {{ pageStatus }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="empty-call-schedule" v-if="!consultations.length">
            You have no scheduled appointments.
          </div>
        </loading-section>
      </div>
    </div>

    <rescheduling-modal
      v-if="rescheduling"
      ref="reschedulingModal"
      :consultation="consultationToUpdate"
      :appointment="appointmentToUpdate"
      :other-participants="participantsList"
      :on-submit="onRescheduleConsultation"
      :on-dismiss-confirmation="dismissConfirmation">
    </rescheduling-modal>

    <cancelling-modal
      v-if="cancelling"
      ref="cancellingModal"
      :appointment="appointmentToUpdate"
      :other-participants="participantsList"
      :on-submit="onCancelConsultation"
      :on-dismiss-confirmation="dismissConfirmation">
    </cancelling-modal>
  </div>
</template>

<script>
import CallScheduleItem from 'vue-app/marketplace/shared/call-schedule/call-schedule-item.vue';
import ReschedulingModal from 'vue-app/marketplace/shared/appointments/rescheduling-modal.vue';
import CancellingModal from 'vue-app/marketplace/shared/appointments/cancelling-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { slice } from 'lodash';

export default {
  name: 'CallSchedule',

  components: {
    CallScheduleItem,
    ReschedulingModal,
    CancellingModal,
    LoadingSection,
    SvgIcon
  },

  props: {
    consultations: {
      type: Array,
      default: () => []
    },

    onMessageUser: {
      type: Function,
      required: true
    },

    onCancelConsultation: {
      type: Function,
      required: true
    },

    onRescheduleConsultation: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      page: 1,
      rescheduling: false,
      cancelling: false,
      consultationToUpdate: null,
      appointmentToUpdate: null,
      participantsList: []
    };
  },

  computed: {
    consultationsPage() {
      const i = (this.page - 1) * 2;
      return slice(this.consultations, i, i + 2);
    },

    isLastPage() {
      return this.page === Math.ceil(this.consultations.length / 2);
    },

    isFirstPage() {
      return this.page === 1;
    },

    pageStatus() {
      const first = (this.page * 2) - 1;
      let status = 'Viewing ' + first;

      if (!this.isLastPage || first < this.consultations.length) {
        status = status + '-' + (first + 1);
      }

      return status + ' of ' + this.consultations.length;
    }
  },

  methods: {
    nextPage() {
      if (!this.isLastPage) {
        this.goToPage(this.page + 1);
      }
    },

    previousPage() {
      if (!this.isFirstPage) {
        this.goToPage(this.page - 1);
      }
    },

    goToPage(page) {
      this.page = page;
    },

    startRescheduling(consultation, appointment, participantsList) {
      this.consultationToUpdate = consultation;
      this.appointmentToUpdate = appointment;
      this.participantsList = participantsList;

      this.rescheduling = true;

      this.$nextTick(() => { this.$refs.reschedulingModal.initModal(); });
    },

    startCancelling(appointment, participantsList) {
      this.appointmentToUpdate = appointment;
      this.participantsList = participantsList;

      this.cancelling = true;

      this.$nextTick(() => { this.$refs.cancellingModal.initModal(); });
    },

    dismissConfirmation() {
      this.rescheduling = false;
      this.cancelling = false;
      this.consultationToUpdate = null;
      this.appointmentToUpdate = null;
      this.participantsList = [];
    }
  }
};
</script>
