<template>
  <div class="size-text-13px">
    <div v-if="hasVisibleLawyers" class="bottom-15">
      <button type="button" class="pseudo-link-blue" @click="expandAll">Expand All</button>
      <span class="left-5 right-5">|</span>
      <button type="button" class="pseudo-link-blue" @click="collapseAll">Collapse All</button>
    </div>

    <div class="row">
      <div class="col-sm-8">
        <div class="list-spacing-30" v-for="(possibleLawyer, index) in visibleLawyers" :key="possibleLawyer.id">
          <quote-lawyer
            ref="quotes"
            :counsel-request="counselRequest"
            :possible-lawyer="possibleLawyer"
            :order-number="index + 1"
            :on-update-possible-lawyer="onUpdatePossibleLawyer"
            :on-delete-possible-lawyer="onDeletePossibleLawyer"
            :on-retire-possible-lawyer="onRetirePossibleLawyer"
            :on-update-file-answer="onUpdateFileAnswer"
            :on-submit-quote="onSubmitQuote"
            :on-add-back-to-quote="onAddBackToQuote">
          </quote-lawyer>
        </div>

        <div v-if="!hasVisibleLawyers">
          <div class="shadowed-box">
            <div class="box-content symmetrical gray-text size-text-16px text-center">
              Add lawyers or law firms from the Search tab
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 top-20-xs">
        <proposal-admin-activity
          :proposal="proposal"
          :proposal-versions="proposalVersions"
          :admins="admins">
        </proposal-admin-activity>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteLawyer from './quote-lawyer.vue';
import ProposalAdminActivity from './proposal-admin-activity.vue';
import { each } from 'lodash';

export default {
  name: 'RequestQuotes',

  components: {
    QuoteLawyer,
    ProposalAdminActivity
  },

  props: {
    counselRequest: {
      type: Object,
      required: true
    },

    proposal: {
      type: Object,
      required: true
    },

    proposalVersions: {
      type: Array,
      required: true
    },

    admins: {
      type: Array,
      required: true
    },

    onUpdatePossibleLawyer: {
      type: Function,
      required: true
    },

    onDeletePossibleLawyer: {
      type: Function,
      required: true
    },

    onRetirePossibleLawyer: {
      type: Function,
      required: true
    },

    onUpdateFileAnswer: {
      type: Function,
      required: true
    },

    onSubmitQuote: {
      type: Function,
      required: true
    },

    onAddBackToQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    visibleLawyers() {
      return this.proposal.possibleLawyers
        .filter(pl => !pl.retired)
        .concat(
          this.proposal.possibleLawyers.filter(pl => pl.retired) // Filter retired lawyers later
        );
    },

    hasVisibleLawyers() {
      return this.visibleLawyers.length > 0;
    }
  },

  methods: {
    expandAll() {
      each(this.$refs.quotes, q => q.expandDetails());
    },

    collapseAll() {
      each(this.$refs.quotes, q => q.collapseDetails());
    }
  }
};
</script>
