<template>
  <validation-observer v-slot="{ validate, handleSubmit, invalid, failed: formInvalid }">
    <div v-if="editing" class="bottom-30 size-text-15px bold-weight">
      Opportunities Page Information
    </div>

    <div v-if="!editing" class="bottom-30 row vertical-center-not-xs">
      <div class="col-sm-4">
        <opportunity-status-tag :opportunity="opportunity"></opportunity-status-tag>
      </div>

      <div v-if="!!opportunity.publishedAt" class="col-sm-8 top-20-xs">
        <a :href="`/public-app/opportunities/${opportunity.id}`" target="_blank">{{ opportunityLinkText(opportunity) }}</a>
      </div>
    </div>

    <form role="form" name="form" class="a-form" novalidate>
      <div class="bottom-20">
        <dropdown-select-horizontal
          ref="statusDropdown"
          id-label="opportunity-status"
          v-model="opportunity.status"
          label="Status"
          :options="statuses"
          :required="true"
          label-key="text"
          value-key="value"
          :edit-mode="editing"
          :initial-label="defaultText('statuses', opportunity.status)">
        </dropdown-select-horizontal>
      </div>

      <div class="bottom-20">
        <dropdown-select-horizontal
          ref="statusTagDropdown"
          id-label="opportunity-status-tag"
          v-model="opportunity.statusTag"
          label="Status Flag"
          :value="opportunity.statusTag"
          :options="statusTags"
          :allow-null="true"
          label-key="text"
          value-key="value"
          :edit-mode="editing"
          placeholder="Select Status Flag"
          :initial-label="defaultText('statusTags', opportunity.statusTag, true)">
        </dropdown-select-horizontal>
      </div>

      <div class="bottom-20">
        <text-input-horizontal
          label="Post Title"
          name="postTitle"
          id="post-title"
          :current-value="opportunity.postTitle"
          :required="true"
          :edit-mode="editing"
          v-model="opportunity.postTitle">
        </text-input-horizontal>
      </div>

      <div class="bottom-20">
        <wysiwyg-horizontal
          label="Opportunity Description"
          name="opportunityDescription"
          :current-value="opportunity.opportunityDescription"
          :required="true"
          :show-error-message="false"
          placeholder="Enter Opportunity Description"
          :edit-mode="editing"
          @input="(value) => opportunity.opportunityDescription = value">
        </wysiwyg-horizontal>
      </div>

      <opportunity-practice-areas
        :editing="editing"
        :selected-practice-areas="selectedPracticeAreas"
        :practice-areas="practiceAreas"
        :update-practice-areas="updatePracticeAreas"
        :remove-practice-area-name="removePracticeAreaName">
      </opportunity-practice-areas>

      <opportunity-location
        :opportunity="opportunity"
        :editing="editing">
      </opportunity-location>

      <validation-provider :rules="'required'" v-slot="{ errors }" :mode="passiveAggressive">
        <div class="bottom-20">
          <dropdown-select-horizontal
            ref="timeCommitmentDropdown"
            id-label="opportunity-time-commitment"
            v-model="opportunity.timeCommitment"
            label="Time Commitment"
            :options="timeCommitments"
            :invalid="errors.length > 0"
            :required="true"
            label-key="text"
            value-key="value"
            :edit-mode="editing"
            placeholder="Select Time Commitment"
            :initial-label="defaultText('timeCommitments', opportunity.timeCommitment, true)">
          </dropdown-select-horizontal>
        </div>
      </validation-provider>

      <validation-provider :rules="'required'" v-slot="{ errors }" :mode="passiveAggressive">
        <div class="bottom-20">
          <dropdown-select-horizontal
            ref="siteDropdown"
            id-label="opportunity-site"
            v-model="opportunity.site"
            label="On-Site/Remote"
            :options="sites"
            :invalid="errors.length > 0"
            :required="true"
            label-key="text"
            value-key="value"
            :edit-mode="editing"
            placeholder="Select On-Site/Remote"
            :initial-label="defaultText('sites', opportunity.site, true)">
          </dropdown-select-horizontal>
        </div>
      </validation-provider>

      <validation-provider :rules="'required'" v-slot="{ errors }" :mode="passiveAggressive">
        <div class="bottom-20">
          <dropdown-select-horizontal
            ref="feeStructureDropdown"
            id-label="fee-structure"
            v-model="opportunity.feeStructure"
            label="Preferred Pricing Model"
            :options="feeStructureOptions"
            :invalid="errors.length > 0"
            :required="true"
            label-key="text"
            value-key="value"
            :edit-mode="editing"
            placeholder="Select Preferred Pricing Model"
            :initial-label="defaultText('feeStructureOptions', opportunity.feeStructure, true)">
          </dropdown-select-horizontal>
        </div>
      </validation-provider>

      <div class="row bottom-20">
        <div class="col-sm-4">
          <label :class="['semibold-weight', { 'no-margin': !editing }]">Rate <span class="inline-help required">*</span></label>
        </div>

        <div class="col-sm-5">
          <div v-if="editing" class="row tight-columns">
            <div class="col-xs-5">
              <validation-provider rules="required|positive" :mode="passiveAggressive" v-slot="{ errors }">
                <div class="dark-gray-text size-text-12px semibold-weight">
                  Min. amount
                </div>

                <div class="input-with-icon top-2">
                  <input type="number" name="opportunity.rateMin" id="rateMin" :class="['form-control', { 'has-error': errors.length }]" min="0" step="0.01" placeholder="From" v-model="opportunity.rateMin">
                  <div class="form-icon">$</div>
                </div>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </validation-provider>
            </div>

            <div class="col-xs-2 text-center">
              <div class="top-30">&mdash;</div>
            </div>

            <div class="col-xs-5">
              <validation-provider :rules="budgetRangeUpperRules" :mode="passiveAggressive" v-slot="{ errors }">
                <div class="dark-gray-text size-text-12px semibold-weight">
                  Max. amount
                </div>

                <div class="input-with-icon top-2">
                  <input type="number" name="opportunity.rateMax" id="rateMax" :class="['form-control', { 'has-error': errors.length }]" min="0" step="0.01" placeholder="To" v-model="opportunity.rateMax">
                  <div class="form-icon">$</div>
                </div>

                <div class="error-text top-5" v-if="errors.length">
                  <error-message :errors="errors"></error-message>
                </div>
              </validation-provider>
            </div>
          </div>

          <div v-else>
            {{ rateDisplay }}
          </div>
        </div>
      </div>

      <opportunity-bar-licenses
        :editing="editing"
        :required-bars="requiredBars"
        :on-add-bar="addBar">
      </opportunity-bar-licenses>

      <div class="bottom-20">
        <wysiwyg-horizontal
          label="Key Details"
          name="keyDetails"
          :current-value="opportunity.keyDetails"
          :novalidate="true"
          :edit-mode="editing"
          @input="(value) => opportunity.keyDetails = value">
        </wysiwyg-horizontal>
      </div>

      <div class="bottom-30">
        <wysiwyg-horizontal
          label="Additional Status Details"
          name="additionalStatusDetails"
          :current-value="opportunity.additionalStatusDetails"
          :novalidate="true"
          :edit-mode="editing"
          @input="(value) => opportunity.additionalStatusDetails = value">
        </wysiwyg-horizontal>
      </div>

      <opportunity-questions
        :editing="editing"
        :opportunity-questions="opportunityQuestions">
      </opportunity-questions>

      <opportunity-actions
        class="top-30"
        :editing="editing"
        :opportunity="opportunity"
        :handle-submit="handleSubmit"
        :invalid="invalid"
        :validate="validate"
        :on-save="save"
        :on-cancel="cancel"
        :on-unpublish="unpublish"
        :on-publish="publish">
      </opportunity-actions>

      <div v-if="editing && formInvalid" class="error-text top-10">
        * One or more required fields are empty. Please fill in the required fields indicated above.
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DropdownSelectHorizontal from 'vue-app/shared/components/dropdown-select-horizontal.vue';
import TextInputHorizontal from 'vue-app/shared/components/text-input-horizontal.vue';
import WysiwygHorizontal from 'vue-app/shared/components/wysiwyg-horizontal.vue';
import OpportunityStatusTag from 'vue-app/admin/opportunities/status-tag.vue';
import OpportunityPracticeAreas from 'vue-app/admin/opportunities/practice-areas.vue';
import OpportunityLocation from 'vue-app/admin/opportunities/location.vue';
import OpportunityBarLicenses from 'vue-app/admin/opportunities/bar-licenses.vue';
import OpportunityQuestions from 'vue-app/admin/opportunities/questions.vue';
import OpportunityActions from 'vue-app/admin/opportunities/actions.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { cloneDeep, uniq } from 'lodash';
import HtmlTextSanitizer from 'vue-app/shared/lib/html-text-sanitizer';

export default {
  name: 'OpportunityForm',

  components: {
    DropdownSelectHorizontal,
    TextInputHorizontal,
    WysiwygHorizontal,
    OpportunityStatusTag,
    OpportunityPracticeAreas,
    OpportunityLocation,
    OpportunityBarLicenses,
    OpportunityQuestions,
    OpportunityActions,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    opportunity: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onCancel: {
      type: Function,
      required: true
    },

    editing: {
      type: Boolean,
      default: true
    },

    practiceAreas: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedPracticeAreaIds: [],
      requiredBars: [],
      opportunityQuestions: [],
      errorMessage: '',
      statuses: [
        { text: 'Open', value: 'open' },
        { text: 'Client Reviewing', value: 'client_reviewing' },
        { text: 'Closed', value: 'closed' }
      ],
      statusTags: [
        { text: 'New', value: 'new' },
        { text: 'Updated', value: 'updated' }
      ],
      timeCommitments: [
        { text: 'Full-Time', value: 'full_time' },
        { text: 'Part-Time', value: 'part_time' },
        { text: 'Outside Counsel', value: 'outside_counsel' },
        { text: 'W-2', value: 'w2' }
      ],
      sites: [
        { text: 'On-Site', value: 'onsite' },
        { text: 'Remote', value: 'remote' },
        { text: 'Hybrid', value: 'hybrid' }
      ],
      feeStructureOptions: [
        { text: 'Hourly Rate', value: 'per_hour' },
        { text: 'Weekly Rate', value: 'per_week' },
        { text: 'Monthly Rate', value: 'per_month' },
        { text: 'Annual Rate', value: 'per_year' },
        { text: 'Flat Fee', value: 'per_project' }
      ]
    };
  },

  computed: {
    selectedPracticeAreas() {
      return this.practiceAreas.filter(tag => this.selectedPracticeAreaIds.includes(tag.id));
    },

    budgetRangeUpperRules() {
      const rules = { positive: true };

      if (this.opportunity.rateMin) {
        rules['minValue'] = parseInt(this.opportunity.rateMin);
      }

      return rules;
    },

    rateDisplay() {
      if (this.opportunity.rateMin && this.opportunity.rateMax) {
        return `$${this.opportunity.rateMin} - $${this.opportunity.rateMax}`;
      }
      else if (this.opportunity.rateMin) {
        return `$${this.opportunity.rateMin}`;
      }
      else {
        return '—';
      }
    }
  },

  mounted() {
    this.initData();
  },

  methods: {
    initData() {
      this.selectedPracticeAreaIds = this.opportunity?.opportunityPracticeAreas?.map(opa => opa.practiceAreaId) || [];
      this.requiredBars            = this.opportunity.requiredBars;
      this.opportunityQuestions    = JSON.parse(JSON.stringify(this.opportunity.opportunityQuestions));
      this.errorMessage            = '';
    },

    defaultText(functionName, value, allowNull = false) {
      const initialLabel = this[functionName].find((option) => option.value === value)?.text;

      if (!initialLabel && allowNull) {
        return null;
      }
      else {
        return initialLabel || this[functionName][0].text;
      }
    },

    updatePracticeAreas(tagNames) {
      this.selectedPracticeAreaIds = tagNames.sort().map(name => this.practiceAreas.find(tag => tag.name === name).id);
    },

    removePracticeAreaName(index) {
      this.selectedPracticeAreaIds.splice(index, 1);
    },

    removeQuestion(index) {
      this.opportunityQuestions.splice(index, 1);
    },

    addQuestion(type) {
      this.opportunityQuestions.push({ questionType: type, questionText: ''  });
    },

    addBar(newBar) {
      const reqBarCopy = cloneDeep(this.requiredBars);
      reqBarCopy.push(newBar);

      this.requiredBars = reqBarCopy;
    },

    save(status) {
      const sanitizer = new HtmlTextSanitizer;
      LoadingService.loading('saveOpportunity');
      this.opportunity.keyDetails = sanitizer.sanitize(this.opportunity.keyDetails);
      this.opportunity.additionalStatusDetails = sanitizer.sanitize(this.opportunity.additionalStatusDetails);

      let additionalParams = {
        opportunityPracticeAreasAttributes: this.formattedPracticeAreas(),
        opportunityQuestionsAttributes: this.formattedQuestions(),
        requiredBars: this.requiredBars
      };

      // we only want to add the published flag if the status is published to avoid creating an
      // OpportunityPublishingHistory record when published changes from nil to false.
      if (status === 'published') {
        additionalParams.published = true;
      }

      return this.onSave({ ...this.opportunity, ...additionalParams }).finally(() => {
        LoadingService.done('saveOpportunity');
      });
    },

    cancel() {
      this.$refs.statusDropdown.reset();
      this.$refs.statusTagDropdown.reset();
      this.$refs.timeCommitmentDropdown.reset();
      this.$refs.siteDropdown.reset();

      this.initData();

      this.onCancel();
    },

    unpublish() {
      LoadingService.loading('unpublishOpportunity');
      return this.onSave({ id: this.opportunity.id, published: false }).finally(() => {
        LoadingService.done('unpublishOpportunity');
      });
    },

    publish() {
      LoadingService.loading('publishOpportunity');
      return this.onSave({ id: this.opportunity.id, published: true }).finally(() => {
        LoadingService.done('publishOpportunity');
      });
    },

    formattedQuestions() {
      const opportunityQuestionsAttributes = [];

      this.opportunityQuestions.forEach((question) => {
        if (question.questionText.trim() !== '') {
          opportunityQuestionsAttributes.push({ id: question.id, questionType: question.questionType, questionText: question.questionText.trim() });
        }
      });

      this.opportunity.opportunityQuestions?.forEach((question) => {
        const updatedQuestion = this.opportunityQuestions.find(oppQuestion => oppQuestion.id === question.id);

        if (!updatedQuestion || updatedQuestion.questionText.trim() === '') {
          opportunityQuestionsAttributes.push({ id: question.id, _destroy: true });
        }
      });

      return opportunityQuestionsAttributes;
    },

    formattedPracticeAreas() {
      const opportunityPracticeAreas = this.opportunity?.opportunityPracticeAreas || [];
      const existingOpportunityPracticeAreaIds = opportunityPracticeAreas.map(opa => opa.practiceAreaId);
      const allPracticeAreaIds = uniq(this.selectedPracticeAreaIds.concat(existingOpportunityPracticeAreaIds));
      const opportunityPracticeAreasAttributes = [];

      allPracticeAreaIds.forEach(practiceAreaId => {
        if (this.opportunity.id && !this.selectedPracticeAreaIds.includes(practiceAreaId)) {
          const opportunityPracticeArea = opportunityPracticeAreas.find(opa => opa.practiceAreaId === practiceAreaId);
          opportunityPracticeAreasAttributes.push({ id: opportunityPracticeArea.id, _destroy: true });
        }
        else if (existingOpportunityPracticeAreaIds.includes(practiceAreaId) && this.opportunity.id) {
          // only skip existing if we have an opportunity id and therefore its existing, if we skip in create case we will not pass these
          return;
        }
        else {
          opportunityPracticeAreasAttributes.push({ 'practiceAreaId': practiceAreaId });
        }
      });
      return opportunityPracticeAreasAttributes;
    },

    opportunityLinkText(opportunity) {
      return `${window.location.protocol}//${window.location.host}/public-app/opportunities/${opportunity.id}`;
    }
  }
};
</script>
