<template>
  <div class="request-lawyers status-keys">
    <span class="right-10">Key:</span>
    <span class="lawyer"><a href="" class="no-link sent">Sent</a></span>
    <span class="left-10 right-10">|</span>
    <span class="lawyer"><a href="" class="no-link unsent">Unsent</a></span>
    <span class="left-10 right-10">|</span>
    <span class="lawyer"><a href="" class="no-link applied">Applied</a></span>
    <span class="left-10 right-10">|</span>
    <span class="lawyer"><a href="" class="no-link invited">Invited</a></span>
    <span class="left-10 right-10">|</span>
    <span class="lawyer"><a href="" class="no-link tracked">Tracked</a></span>
    <span class="left-10 right-10">|</span>
    <span class="text-italic">Italics</span> = Leads
  </div>
</template>

<script>
export default {
  name: 'StatusKeys'
};
</script>
