<template>
  <boxed-content v-if="displayComponent" title="Contact">
    <template #links>
      <a :href="`/scout/lawyers/${lawyer.lawyerId}/v_card`" target="_self" v-if="!asLawyer">Download&nbsp;vCard</a>

      <edit-lawyer-contact-info v-if="canEditProfile" :lawyer="lawyer" :on-save="onSave"></edit-lawyer-contact-info>
    </template>

    <section-wrapper :data="lawyer.email" :editing-allowed="canEditProfile">
      <div class="bold-weight">
        Email
      </div>

      <data-wrapper :data="lawyer.email" class="wrap-word top-2">
        <a :href="`mailto:${lawyer.email}`">{{ lawyer.email }}</a>
      </data-wrapper>
    </section-wrapper>

    <div class="top-20" v-if="lawyer.officeNumber">
      <div class="bold-weight">
        Phone
      </div>

      <div class="top-2">
        {{ lawyer.officeNumber }}
      </div>
    </div>

    <section-wrapper class="top-20" :data="lawyer.pronouns" :editing-allowed="canEditProfile">
      <div class="bold-weight">
        Pronouns
      </div>

      <data-wrapper :data="lawyer.pronouns" class="top-2">
        {{ lawyer.pronouns }}
      </data-wrapper>
    </section-wrapper>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import EditLawyerContactInfo from 'vue-app/scout/shared/lawyers/edit-lawyer-contact-info';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import SectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';

export default {
  name: 'LawyerContactInfo',

  components: {
    BoxedContent,
    EditLawyerContactInfo,
    SectionWrapper,
    DataWrapper
  },

  mixins: [displayRules],

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    asLawyer: {
      type:    Boolean,
      default: false
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:    Function,
      default: () => {}
    }
  },

  computed: {
    displayComponent() {
      const { firmIsConnecting, email, officeNumber, pronouns } = this.lawyer;

      return this.canEditProfile || (
        !firmIsConnecting && this.anyDataIsPresent([email, officeNumber, pronouns])
      );
    }
  }
};
</script>
