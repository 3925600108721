import BaseResource from 'resources/base-resource';

class FavoriteLawyer extends BaseResource {
  static baseUrl = '/scout/favorite_lawyers/:lawyer_bridge_id.json';
  static urlTransformations = ['lawyer_bridge_id'];

  static transformedCollectionUrl() {
    return this.baseUrl.replace('/:lawyer_bridge_id', '');
  }
}

export default FavoriteLawyer;
