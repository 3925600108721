<template>
  <priori-modal ref="modal" modal-id="modalId" title="Select Biography">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="pseudo-link-blue light-gray-link" :disabled="isDisabled" @click="initModal(openModal)">Select Profile Bio</button>
    </template>

    <template #default="{ closeModal }">
      <form role="form" name="addBioForm" class="a-form" novalidate>
        <div class="row bottom-15">
          <div class="col-sm-6 tiny-header-text">
            Default Biography
          </div>

          <div class="col-sm-6 text-right-not-xs">
            <label class="radio-inline">
              <input type="radio" name="selectedBio" id="selected-bio-0" :value="profileBio" required v-model="selectedBio">
              <span :class="['normal-weight', { 'light-blue-k-text': selectedBio === profileBio }]">Select for Proposal</span>
            </label>
          </div>
        </div>

        <div>
          <span class="simple-text">{{ profileBio }}</span>
        </div>

        <hr class="kenny-hr">

        <div v-for="(bio, index) in alternateBios">
          <div class="row bottom-15">
            <div class="col-sm-6 tiny-header-text">
              Specialty Biography {{ index + 1 }}
            </div>

            <div class="col-sm-6 text-right-not-xs">
              <label class="radio-inline">
                <input type="radio" name="selectedBio" :id="`selected-bio-${index + 1}`" :value="bio" required v-model="selectedBio">
                <span :class="['normal-weight', { 'light-blue-k-text': selectedBio === bio }]">Select for Proposal</span>
              </label>
            </div>
          </div>

          <div>
            <span class="simple-text">{{ bio }}</span>
          </div>

          <hr class="kenny-hr">
        </div>
      </form>

      <div class="row top-30">
        <div class="col-xs-12 col-sm-8 col-sm-offset-2">
          <loading-button name="insertButton" lb-class="nv-button-blue a-button-size" @lb-click="save" :lb-disabled="!selectedBioIsValid">Insert Selected Biography</loading-button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'ProposalBioSelector',

  components: {
    PrioriModal,
    LoadingButton
  },

  props: {
    modalId: {
      type: String,
      required: true
    },

    isDisabled: {
      type: Boolean,
      required: true
    },

    possibleLawyer: {
      type: Object,
      required: true
    },

    onSelect: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedBio: null
    };
  },

  computed: {
    proposalBio() {
      return this.possibleLawyer.proposalBio;
    },

    profileBio() {
      return this.possibleLawyer.profileBio;
    },

    alternateBios() {
      return this.possibleLawyer.profileBios || [];
    },

    isProposalBioSelected() {
      return this.proposalBio === this.profileBio || this.alternateBios.includes(this.proposalBio);
    },

    selectedBioIsValid() {
      return this.selectedBio && this.selectedBio.length > 0;
    }
  },

  methods: {
    initModal(openModalFn) {
      if (this.isDisabled) { return; }

      this.selectedBio = (this.isProposalBioSelected ? this.proposalBio : null);
      openModalFn();
    },

    save() {
      if (!this.selectedBioIsValid) { return; }

      LoadingService.loading('insertButton');

      this.onSelect(this.selectedBio)
        .then(() => {
          this.$refs.modal.closeModal();
        })
        .finally(() => {
          LoadingService.done('insertButton');
        });
    }
  }
};
</script>
