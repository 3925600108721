<template>
  <div @mouseenter="hover = true" @mouseleave="hover = false" class="column-header" @click="onSort(orderBy)">
    <slot></slot>

    <span v-if="reverse" class="left-5 light-gray-background">
      <svg-icon v-if="order[orderBy] === 'asc'" name="arrow-down" class="base-icon smaller"></svg-icon>
      <svg-icon v-else-if="order[orderBy] === 'desc'" name="arrow-up" class="base-icon smaller"></svg-icon>
      <svg-icon v-else :name="initialArrow" :class="['base-icon smaller arrow-hidden', { 'arrow-gray-hover': hover }]"></svg-icon>
    </span>
    <span v-else class="left-5 light-gray-background">
      <svg-icon v-if="order[orderBy] === 'desc'" name="arrow-down" class="base-icon smaller"></svg-icon>
      <svg-icon v-else-if="order[orderBy] === 'asc'" name="arrow-up" class="base-icon smaller"></svg-icon>
      <svg-icon v-else :name="initialArrow" :class="['base-icon smaller arrow-hidden', { 'arrow-gray-hover': hover }]"></svg-icon>
    </span>
  </div>
</template>

<script>
import SvgIcon from 'src/vue-app/shared/components/svg-icon';
export default {
  name: 'DynamicOrderArrows',
  components: {
    SvgIcon
  },

  props: {
    order: {
      type:     Object,
      required: true
    },

    orderBy: {
      type:     String,
      required: true
    },

    initialOrder: {
      type:     String,
      required: true,
      validator: (value) => { return ['asc', 'desc'].includes(value); }
    },

    onSort: {
      type:     Function,
      required: true
    },

    reverse: {
      type:     Boolean,
      default:  false
    }
  },

  data() {
    return {
      hover: false
    };
  },

  computed: {
    initialArrow() {
      if (this.reverse) {
        return this.initialOrder === 'asc' ? 'arrow-down' : 'arrow-up';
      }
      else {
        return this.initialOrder === 'asc' ? 'arrow-up' : 'arrow-down';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .arrow-hidden {
    fill: transparent
  }

  .arrow-gray-hover {
    fill: $pill-gray;
  }

  .column-header {
    cursor: pointer;
  }

  .light-gray-background {
    // Fix bug in Firefox & Safari; redundant in Chromium browsers
    background-color: transparent
  }
</style>
