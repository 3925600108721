<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div class="row">
        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            Email Address
          </div>

          <div class="wrap-word">
            {{ lawyerLead.email }}
          </div>
        </div>

        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            Location
          </div>

          <div>
            {{ location || '&mdash;' }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            LinkedIn ID
          </div>

          <div>
            {{ lawyerLead.linkedinId || '&mdash;' }}
          </div>
        </div>

        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            LinkedIn URL
          </div>

          <div class="wrap-word">
            {{ lawyerLead.linkedinUrl || '&mdash;' }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            Phone
          </div>

          <div>
            {{ lawyerLead.phone || lawyerLead.phoneSf || '&mdash;' }}
          </div>
        </div>

        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            Malpractice Insurance
          </div>

          <div :class="{ 'green-text': lawyerLead.malpractice, 'red-text': lawyerLead.malpractice === false }">
            <span v-if="lawyerLead.malpractice !== null">{{ lawyerLead.malpractice ? 'Yes': 'No' }}</span>
            <span v-else>&mdash;</span>
          </div>
        </div>
      </div>

      <div class="top-5 bottom-20">
        <hr class="kenny-hr no-margin">
      </div>

      <div class="tiny-header-text bottom-15">
        Company
      </div>

      <div class="row">
        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            Name
          </div>

          <div>
            {{ lawyerLead.company || '&mdash;' }}
          </div>
        </div>

        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            Website
          </div>

          <div class="wrap-word">
            {{ lawyerLead.website || '&mdash;' }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 bottom-15">
          <div class="semibold-weight">
            Number of Employees
          </div>

          <div class="wrap-word">
            {{ lawyerLead.numEmployees || '&mdash;' }}
          </div>
        </div>
      </div>

      <div class="top-5 bottom-20">
        <hr class="kenny-hr no-margin">
      </div>

      <div class="tiny-header-text bottom-15">
        Law School
      </div>

      <div v-if="lawyerLead.lawSchool">
        <span class="semibold-weight">{{ lawyerLead.lawSchool }}</span>
        <span v-if="lawyerLead.gradYear">({{ lawyerLead.gradYear }})</span>
      </div>

      <div v-if="!lawyerLead.lawSchool">
        &mdash;
      </div>

      <hr class="kenny-hr normal">

      <div class="tiny-header-text bottom-15">
        Bar Admissions
      </div>

      <div>
        {{ lawyerLead.barLicenses || '&mdash;' }}
      </div>

      <hr class="kenny-hr normal">

      <div class="tiny-header-text bottom-15">
        Practice Areas
      </div>

      <div>
        {{ lawyerLead.practiceAreas || '&mdash;' }}
      </div>
    </div>
  </div>
</template>

<script>
import { compact } from 'lodash';

export default {
  name: 'LeadDetails',

  props: {
    lawyerLead: {
      type:     Object,
      required: true
    }
  },

  computed: {
    location() {
      if (!this.lawyerLead.city) {
        return null;
      }

      return compact([this.lawyerLead.city, this.stateOrCountry]).join(', ');
    },

    stateOrCountry() {
      return this.lawyerLead.country === 'United States' ? this.lawyerLead.state : this.lawyerLead.country;
    }
  }
};
</script>
