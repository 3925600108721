<template>
  <priori-modal ref="addQuestionModal" modal-id="add-question-modal" :title="formTitle" @on-modal-close="clearForm">
    <template #modal-trigger="{ openModal }">
      <button v-if="!isEditing" type="button" class="pseudo-link-blue bold-weight" @click="initForm(openModal)">+ Add Question</button>

      <button v-else type="button" class="pseudo-link-blue bold-weight d-flex gap-4 size-text-13px" @click="initForm(openModal)"><svg-icon name="pencil" class="base-icon blue-4-fill"></svg-icon>Edit</button>
    </template>

    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form role="form" class="a-form" novalidate>
        <div class="bottom-20">
          <text-input-vertical label="Enter your question" id="rfq-question-text" input-name="rfqQuestionText" rules="required" v-model="questionForm.questionText"></text-input-vertical>
        </div>

        <validation-provider ref="typeValidator" rules="required" v-slot="{ errors }" :mode="passiveAggressive">
          <dropdown-select
            ref="typeDropdown"
            :required="true"
            label="Select answer type"
            placeholder="Please Select"
            id-label="rfq-question-type"
            :initial-label="initialLabel"
            :options="typeOptions"
            value-key="code"
            label-key="text"
            :invalid="errors.length > 0"
            v-model="questionForm.questionType">
          </dropdown-select>

          <div class="error-text top-5" v-if="errors.length">
            {{ errors[0] }}
          </div>
        </validation-provider>

        <div v-if="!isEditing" class="row tight-columns top-30">
          <div class="col-sm-5">
            <button type="button" class="primary-btn-night-blue" @click="handleSubmit(addQuestion)">Add Question</button>
          </div>

          <div class="col-sm-4 top-20-xs">
            <button type="button" class="secondary-btn-blue" @click="cancel">Cancel</button>
          </div>
        </div>

        <div v-else class="row tight-columns vertical-center-not-xs top-30">
          <div class="col-sm-4">
            <button type="button" class="primary-btn-night-blue" @click="handleSubmit(updateQuestion)">Save</button>
          </div>

          <div class="col-sm-3 top-20-xs">
            <button type="button" class="secondary-btn-blue" @click="cancel">Cancel</button>
          </div>

          <div class="col-sm-5 text-right top-20-xs">
            <button type="button" class="pseudo-link-blue pill-gray-text semibold-weight" @click="deleteQuestion"><svg-icon name="trash" class="base-icon smaller right-10"></svg-icon>Delete Question</button>
          </div>
        </div>
      </form>
    </validation-observer>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'RfqQuestionForm',

  components: {
    PrioriModal,
    SvgIcon,
    TextInputVertical,
    DropdownSelect,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    rfq: {
      type: Object,
      required: true
    },

    questionGroupId: {
      type: Number,
      default: null
    },

    question: {
      type: Object,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    onAddQuestion: {
      type: Function,
      default: () => {}
    },

    onUpdateQuestion: {
      type: Function,
      default: () => {}
    },

    onDeleteQuestion: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      questionForm: {
        questionType: this.question?.questionType || '',
        questionText: this.question?.questionText || null
      },
      typeOptions: [
        { code: 'text', text: 'Short Answer Text' },
        { code: 'range', text: 'Dollar Amount' }
      ]
    };
  },

  computed: {
    formTitle() {
      return this.isEditing ? 'Edit Custom Question' : 'Add Custom Question';
    },

    initialLabel() {
      switch (this.questionForm.questionType) {
        case 'text':
          return 'Short Answer Text';
        case 'range':
          return 'Dollar Amount';
        default:
          return '';
      }
    }
  },

  methods: {
    initForm(openModal) {
      this.questionForm.questionType = this.question?.questionType || '';
      this.questionForm.questionText = this.question?.questionText || null;
      openModal();
    },

    addQuestion() {
      const question = {
        scoutRfqId: this.rfq.id,
        scoutRfqQuestionGroupId: this.questionGroupId,
        questionText: this.questionForm.questionText,
        questionType: this.questionForm.questionType
      };
      this.onAddQuestion(question).then(this.cancel);
    },

    updateQuestion() {
      const question = {
        id: this.question.id,
        scoutRfqQuestionGroupId: this.question.scoutRfqQuestionGroupId,
        isIncluded: this.question.isIncluded,
        questionText: this.questionForm.questionText,
        questionType: this.questionForm.questionType
      };
      this.onUpdateQuestion(question, this.questionGroupId).then(this.cancel);
    },

    deleteQuestion() {
      this.onDeleteQuestion(this.question);
    },

    cancel() {
      this.$refs.addQuestionModal.closeModal();
      this.clearForm();
    },

    clearForm() {
      this.questionForm.questionType = '';
      this.questionForm.questionText = null;
      this.$refs.form.reset();
      this.$refs.typeDropdown.reset();
    }
  }
};
</script>
