<template>
  <div>
    <div class="row tight-columns bottom-10">
      <div class="col-sm-3">
        <label :for="`proposal-bio-${possibleLawyer.id}`" class="no-margin">{{ isLawFirm ? 'Firm Overview' : 'Lawyer Bio' }}</label>
      </div>

      <div class="col-sm-9 text-right-not-xs" v-if="canEdit && !isLawFirm">
        <proposal-bio-selector
          class="inline-block"
          :modal-id="`bio-selector-${possibleLawyer.id}`"
          :is-disabled="!canImportProfileBio"
          :possible-lawyer="possibleLawyer"
          :on-select="importProfileBio">
        </proposal-bio-selector>

        <span class="left-10 right-10">&#8231;</span>

        <button type="button" class="pseudo-link-blue light-gray-link" :disabled="!canImportDefaultBio" @click="openConfirmImportDefaultModal">Import Proposal Bio</button>

        <request-confirmation
          ref="confirmImportDefaultModal"
          message="This will import the lawyer's default proposal bio, replacing any changes you've made here. Are you sure?"
          @on-modal-yes="importDefaultBio">
        </request-confirmation>
      </div>
    </div>

    <textarea-with-undo
      :object="possibleLawyer"
      :field-name="'proposalBio'"
      :field-id="`proposal-bio-${possibleLawyer.id}`"
      :rows="6"
      :is-disabled="isRetiredOrRejected"
      :on-start-editing="startEditing"
      :on-finish-editing="finishEditing"
      :on-change="onChange">
    </textarea-with-undo>

    <div v-if="!isLawFirm && !isRetiredOrRejected" class="top-5">
      <button v-if="!isEditing" type="button" class="pseudo-link-blue light-gray-link size-text-12px" :disabled="!canSaveDefaultBio" @click="openConfirmSaveDefaultModal">Save As Default Proposal Bio</button>
      <span v-else>&nbsp;</span>

      <request-confirmation
        ref="confirmSaveDefaultModal"
        message="This will save your changes as the lawyer's default proposal bio. Are you sure?"
        @on-modal-yes="saveDefaultBio">
      </request-confirmation>
    </div>
  </div>
</template>

<script>
import ProposalBioSelector from './proposal-bio-selector.vue';
import RequestConfirmation from 'vue-app/shared/components/request-confirmation.vue';
import TextareaWithUndo from 'vue-app/shared/components/textarea-with-undo.vue';
import Lawyer from 'resources/lawyer';

export default {
  name: 'ProposalBioEditor',

  components: {
    ProposalBioSelector,
    RequestConfirmation,
    TextareaWithUndo
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isEditing: false
    };
  },

  computed: {
    isLawFirm() {
      return !!this.possibleLawyer.lawFirm;
    },

    isRetiredOrRejected() {
      return this.possibleLawyer.retiredOrRejected();
    },

    canEdit() {
      return !this.isEditing && !this.isRetiredOrRejected;
    },

    proposalBio() {
      return this.possibleLawyer.proposalBio;
    },

    hasProposalBio() {
      return this.proposalBio && this.proposalBio.length > 0;
    },

    hasDefaultBio() {
      return this.possibleLawyer.defaultBio && this.possibleLawyer.defaultBio.length > 0;
    },

    hasProfileBio() {
      return this.possibleLawyer.profileBio && this.possibleLawyer.profileBio.length > 0;
    },

    hasAlternateBios() {
      return this.possibleLawyer.profileBios && this.possibleLawyer.profileBios.length > 0;
    },

    bioDiffersFromProfile() {
      return this.proposalBio !== this.possibleLawyer.profileBio;
    },

    bioDiffersFromDefault() {
      return this.proposalBio !== this.possibleLawyer.defaultBio;
    },

    canImportProfileBio() {
      return this.hasAlternateBios || (this.hasProfileBio && this.bioDiffersFromProfile);
    },

    canImportDefaultBio() {
      return this.hasDefaultBio && this.bioDiffersFromDefault;
    },

    canSaveDefaultBio() {
      return this.hasProposalBio && this.bioDiffersFromDefault;
    }
  },

  methods: {
    importProfileBio(bio) {
      if (!this.canImportProfileBio || this.isRetiredOrRejected) { return; }

      this.possibleLawyer.proposalBio = bio;
      return this.onChange({ proposalBio: bio });
    },

    openConfirmImportDefaultModal() {
      this.$refs.confirmImportDefaultModal.openModal();
    },

    importDefaultBio() {
      if (!this.canImportDefaultBio || this.isRetiredOrRejected) { return; }

      this.possibleLawyer.proposalBio = this.possibleLawyer.defaultBio;
      return this.onChange({ proposalBio: this.possibleLawyer.defaultBio });
    },

    openConfirmSaveDefaultModal() {
      this.$refs.confirmSaveDefaultModal.openModal();
    },

    saveDefaultBio() {
      if (!this.canSaveDefaultBio || this.isRetiredOrRejected) { return; }

      this.possibleLawyer.defaultBio = this.possibleLawyer.proposalBio;
      Lawyer.update({ id: this.possibleLawyer.lawyerId, proposalBio: this.possibleLawyer.proposalBio });
    },

    startEditing() {
      this.isEditing = true;
    },

    finishEditing() {
      this.isEditing = false;
    }
  }
};
</script>
