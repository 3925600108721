<template>
  <div class="lawyer-pricing-grid top-practices-grid top-practices-table">
    <div class="grid-head">
      <div class="gh">
        Top 10 Practice Areas
      </div>

      <div class="gh">
        Top 10 Expertise
      </div>
    </div>

    <div
      v-for="(topPractice, index) in topPractices"
      :key="`top-practice-${index}`"
      class="grid-row">
      <div class="gd">
        <div class="tag-list">
          <div v-if="topPracticeArea(topPractice)" class="tag-list-item blue compact">
            {{ topPracticeArea(topPractice) }}
          </div>
        </div>
      </div>

      <div class="gd">
        <div class="tag-list">
          <div v-if="topProficiency(topPractice)" class="tag-list-item green compact">
            {{ topProficiency(topPractice) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopPracticesTable',

  props: {
    topPractices: {
      type: Array,
      required: true
    }
  },

  methods: {
    topPracticeArea(topPractice) {
      return topPractice[0];
    },

    topProficiency(topPractice) {
      return topPractice[1];
    }
  }
};
</script>
