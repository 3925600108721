<template>
  <div>
    <button type="button" class="priori-btn semibold-weight" @click="expandForm">Add a Firm</button>

    <loading-section name="firmConnection">
      <form role="form" class="a-form top-10" novalidate v-if="formVisible">
        <typeahead-vertical
          id="scout-firm-selecter"
          :options="availableFirms"
          option-label-key="nameAndId"
          placeholder="Law Firm Name"
          @input="confirmConnectToFirm"
          v-model="selectedFirm">
        </typeahead-vertical>

        <confirmation-modal
          ref="confirmationModal"
          :header="`Connect to Firm: ${selectedFirmName}`"
          :message="confirmationMessage"
          :on-confirm-action="connectToFirm">
        </confirmation-modal>
      </form>
    </loading-section>
  </div>
</template>

<script>
import ScoutFirm           from 'resources/scout/scout-firm.js';
import LoadingService      from 'vue-app/shared/services/loading-service';
import ScoutConnection     from 'resources/scout/connection.js';
import NotificationService from 'vue-app/shared/services/notification-service';

import LoadingSection    from 'vue-app/shared/components/loading-section.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';

import { clone, includes, map, orderBy, reject } from 'lodash';

export default {
  name: 'ConnectToFirm',

  components: {
    LoadingSection,
    TypeaheadVertical
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    useConnectingFirms: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      scoutFirms: null,
      formVisible: false,
      selectedFirm: null
    };
  },

  computed: {
    workspaceFirmIds() {
      return map(this.workspace.scoutFirms, 'id');
    },

    nonWorkspaceFirms() {
      return reject(this.scoutFirms, (firm) => includes(this.workspaceFirmIds, firm.id));
    },

    availableFirms() {
      return map(this.nonWorkspaceFirms, (firm) => {
        firm.nameAndId = `${firm.name} (${firm.id})`;
        return firm;
      });
    },

    selectedFirmName() {
      return this.selectedFirm?.name;
    },

    confirmationMessage() {
      return `Are you sure you want to connect this team (${this.workspace.name}) to Scout ${this.useConnectingFirms ? 'Connecting' : 'Connected'} Firm: ${this.selectedFirmName}?`;
    }
  },

  methods: {
    expandForm()  {
      if (!this.scoutFirms) {
        this.loadFirmOptions().then(this.showForm);
      }
      else {
        this.showForm();
      }
    },

    showForm() {
      this.formVisible = true;
    },

    loadFirmOptions() {
      LoadingService.loading('firmConnection');

      return ScoutFirm.query({ view: 'connectable' }).then((data) => {
        if (this.useConnectingFirms) {
          this.scoutFirms = data.filter(firm => firm.isConnectingFirm);
        }
        else {
          this.scoutFirms = data.filter(firm => !firm.isConnectingFirm);
        }
        this.scoutFirms = orderBy(this.scoutFirms, 'name');

        LoadingService.done('firmConnection');
      });
    },

    confirmConnectToFirm() {
      if (!this.selectedFirm) { return; }
      this.$refs.confirmationModal.openModal();
    },

    connectToFirm() {
      if (!this.selectedFirm) { return; }

      LoadingService.loading('firmConnection');

      ScoutConnection.save({ lawFirmId: this.selectedFirm.id, workspaceId: this.workspace.id })
        .then(this.addFirmToWorkspace)
        .catch(this.renderError)
        .finally(this.reset);
    },

    addFirmToWorkspace() {
      const firmToAdd = clone(this.selectedFirm);
      firmToAdd.isActive = true;

      this.workspace.scoutFirms.push(firmToAdd);
    },

    renderError() {
      NotificationService.error('Unable to process Scout connection. Please try again.');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    reset() {
      this.formVisible = false;
      this.selectedFirm = null;
      LoadingService.done('firmConnection');
    }
  }
};
</script>
