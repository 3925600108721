<template>
  <tr>
    <td>
      <a :href="`/admin-app/requests/${appointment.requestId}`">{{ appointment.requestName }}</a>
    </td>

    <td>
      <div>
        {{ clientParticipant.firstName }} {{ clientParticipant.lastName }}
      </div>

      <div>
        {{ clientParticipant.phoneNumber }}
      </div>

      <div>
        <span class="simple-text">{{ clientParticipant.email }}</span>
      </div>
    </td>

    <td>
      <div>
        {{ lawyerParticipant.fullName }}
      </div>

      <div>
        {{ lawyerParticipant.officeNumber }}
      </div>

      <div>
        <span class="simple-text">{{ lawyerParticipant.email }}</span>
      </div>
    </td>

    <td>
      <span class="simple-text">{{ appointmentStatus }}</span>
    </td>

    <td>
      {{ appointmentStartsAt }}
    </td>

    <td>
      {{ appointmentType }}
    </td>

    <td class="text-right">
      <div>
        <a :href="`/admin-app/appointments/${appointment.id}`">View Details</a>
      </div>

      <div>
        <a href="" @click.prevent="openCallModal">Reschedule</a>
      </div>
    </td>
  </tr>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import { startCase } from 'lodash';

export default {
  name: 'AdminAppointmentsTableRow',

  props: {
    appointment: {
      type: Object,
      required: true
    },

    onOpenCallModal: {
      type: Function,
      required: true
    }
  },

  computed: {
    client() {
      return this.appointment.clientParticipant();
    },

    clientParticipant() {
      return this.client.participant;
    },

    lawyer() {
      return this.appointment.lawyerParticipant();
    },

    lawyerParticipant() {
      return this.lawyer.participant;
    },

    appointmentStatus() {
      return startCase(this.appointment.status);
    },

    appointmentType() {
      return startCase(this.appointment.type);
    },

    appointmentStartsAt() {
      return DateFilter.short(this.appointment.startsAt);
    }
  },

  methods: {
    openCallModal() {
      this.onOpenCallModal(this.appointment);
    }
  }
};
</script>
