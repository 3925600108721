<template>
  <boxed-content title="Law Firm Status" v-if="displayComponent">
    <div class="row tight-columns">
      <div class="col-xs-2">
        <svg-icon name="rfq" class="base-icon"></svg-icon>
      </div>

      <div class="col-xs-6 bold-weight">
        RFP Enabled
      </div>

      <div class="col-xs-4 text-right">
        {{ firm.hasRfqEnabled ? 'Yes' : 'No' }}

        <popover-tooltip custom-class="semibold-weight">
          <span class="info-icon pill-gray-text"><svg-icon data-testid="info-tooltip" name="info" class="base-icon smaller text-top"></svg-icon></span>
          <template #tooltip>
            This status indicates whether or not the firm is eligible to receive RFPs. If RFP is not enabled, please contact your administrator to enable RFP for this firm.
          </template>
        </popover-tooltip>
      </div>
    </div>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FirmStatus',

  components: {
    BoxedContent,
    PopoverTooltip,
    SvgIcon
  },

  props: {
    firm: {
      type:     Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    displayComponent() {
      return this.currentClient.workspaceHasRfqEnabled;
    }
  }
};
</script>

<style scoped lang="scss">
  .info-icon .base-icon {
    margin-top: 3px;
  }
</style>
