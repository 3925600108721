<template>
  <div class="row tight-columns request-possible-lawyer vertical-center d-flex wrap row-gap-5">
    <div class="col-xs-3 col-sm-1">
      <profile-photo :user="possibleLawyer" :smallest="true"></profile-photo>
    </div>

    <div class="col-xs-9 col-sm-5">
      <span :class="['semibold-weight', { 'strikethrough': discarded }]">
        <a v-if="isLead" :href="`/admin-app/lawyer-leads/${possibleLawyer.lawyerLeadId}`" class="request-id" target="_blank">{{ fullName }}</a>
        <a v-else :href="`/admin-app/lawyers/${possibleLawyer.lawyerId}`" class="request-id" target="_blank">{{ fullName }}</a>
      </span>

      <div class="dark-gray-text size-text-12px text-italic">
        Hired: {{ hiredCount }}, Rejected: {{ rejectedCount }}, Proposed: {{ proposedCount }}
      </div>
    </div>

    <div class="col-xs-9 col-xs-offset-3 col-sm-3 col-sm-offset-0 col-md-2 vertical-center col-gap-5 text-capitalize">
      <span :class="['status-indicator', 'size-text-20px', 'no-shrink', status.color]">&#9679;</span>

      <span :class="['possible-lawyer-status size-text-12px', { 'text-italic': isLead }]">{{ status.label }}</span>
    </div>

    <div class="col-xs-9 col-xs-offset-3 col-sm-3 col-sm-offset-0 col-md-4 d-flex flex-end-not-xs" v-if="interactionStatusExists">
      <div :class="['rfp-badge', badgeColorClass]">
        {{ badgeText }}
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';

export default {
  name: 'RequestPossibleLawyer',

  components: {
    ProfilePhoto
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    lawyerInteractions: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      statusClassMap: {
        unsent: 'yellow',
        applied: 'purple',
        invited: 'blue',
        tracked: 'gray'
      },
      statusMappings: {
        'call_completed': 'Call Completed',
        'call_missed': 'Call Missed',
        'call_requested': 'Call Requested',
        'call_scheduled': 'Call Scheduled',
        'client_sent_message': 'Client Sent Message',
        'lawyer_sent_message': 'Lawyer Sent Message',
        'lawyer_declined_project': 'Lawyer Declined Project',
        'requested_proposal': 'Requested Proposal',
        'requesting_reschedule': 'Requesting Reschedule',
        'sent_proposal': 'Sent Proposal',
        'client_rejected_lawyer': 'Client Rejected Lawyer'
      }
    };
  },

  computed: {
    fullName() {
      return this.possibleLawyer.lawFirmId !== null ? this.possibleLawyer.firmName : `${this.possibleLawyer.firstName} ${this.possibleLawyer.lastName}`;
    },

    hiredCount() {
      return this.lawyerInteractions.hired;
    },

    rejectedCount() {
      return this.lawyerInteractions.rejected;
    },

    proposedCount() {
      return this.lawyerInteractions.proposed;
    },

    status() {
      if (!this.possibleLawyer.lawyerId) {
        return { label: 'lead', color: 'black' };
      }
      else if (['tracked', 'invited', 'applied', 'unsent'].includes(this.possibleLawyer.status)) {
        return { label: this.possibleLawyer.status, color: this.statusClassMap[this.possibleLawyer.status] };
      }
      else {
        return { label: 'sent', color: 'green' };
      }
    },

    isLead: function () {
      return !this.possibleLawyer.lawyerId;
    },

    discarded() {
      return this.possibleLawyer.retired || this.possibleLawyer.declinedAt || this.possibleLawyer.status === 'client_rejected' || this.possibleLawyer.status === 'lawyer_rejected';
    },

    interactionStatusExists() {
      return !!this.possibleLawyer.recentInteractionStatus;
    },

    lowerCaseInteractionStatus() {
      return this.possibleLawyer.recentInteractionStatus?.toLowerCase();
    },

    badgeText() {
      return this.statusMappings[this.lowerCaseInteractionStatus];
    },

    badgeColorClass() {
      if (['call_completed', 'call_scheduled', 'requested_proposal', 'sent_proposal'].includes(this.lowerCaseInteractionStatus)) {
        return 'green-badge';
      }
      else if (['call_requested', 'client_sent_message', 'lawyer_sent_message', 'requesting_reschedule'].includes(this.lowerCaseInteractionStatus)) {
        return 'gray-badge';
      }
      else if (['call_missed', 'lawyer_declined_project', 'client_rejected_lawyer'].includes(this.lowerCaseInteractionStatus)) {
        return 'red-badge';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  :deep {
    .missing-photo.smallest {
      padding: 0;
    }
  }

  .request-possible-lawyer {
    border-bottom: 1px solid $k-gray;
    min-height: 60px;
    padding: 10px 0px;

    &:last-child {
      border-bottom: none;
    }
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .rfp-badge {
    display: flex;
    min-height: 26px;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-small;
    align-items: center;
    padding: 3px 6px;
    font-size: 12px;
    line-height: 14px;
  }

  .green-badge {
    border-color: $k-green;
  }

  .red-badge {
    border-color: $k-red;
    color: $k-red;
  }

  .gray-badge {
    border-color: $k-dark-gray;
  }

  .no-shrink {
    flex-shrink: 0;
  }
</style>
