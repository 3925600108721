<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': canEditProfile }]">
          Representative Matters
        </div>

        <div class="col-sm-3 text-right-not-xs" v-if="canEditProfile">
          <edit-lawyer-representative-matters :lawyer="lawyer" :matters="matters" :on-save="onSave"></edit-lawyer-representative-matters>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="representative-matters">
        <display-rules-data-wrapper :data="matters">
          <div v-for="(matter, index) in displayedMatters" :key="matter.id">
            <div class="size-text-16px semibold-weight">
              {{ matter.name }}
            </div>

            <div class="dark-gray-text semibold-weight top-2" v-if="hasClientName(matter)">
              {{ matter.client }}
            </div>

            <div class="top-15" v-if="hasDescription(matter)">
              <rich-text-display :content="matter.sanitizedDescription"></rich-text-display>
            </div>

            <hr class="kenny-hr" v-if="!isLastMatter(index)">
          </div>

          <div class="top-20" v-if="!showAll && matters.length > 3">
            <a href="" class="semibold-weight size-text-13px" @click.prevent="toggleShowAll">Show all</a>
          </div>
          <div class="top-20" v-if="showAll && matters.length > 3">
            <a href="" class="semibold-weight size-text-13px" @click.prevent="toggleShowAll">Show less</a>
          </div>
        </display-rules-data-wrapper>
      </loading-section>
    </div>
  </div>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditLawyerRepresentativeMatters from './edit-lawyer-representative-matters';
import RichTextDisplay from 'vue-app/shared/components/rich-text-display.vue';

export default {
  name: 'LawyerRepresentativeMatters',

  components: {
    DisplayRulesDataWrapper,
    EditLawyerRepresentativeMatters,
    RichTextDisplay
  },

  mixins: [displayRules],

  props: {
    matters: {
      type: Array,
      default: () => []
    },

    lawyer: {
      type: Object
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      showAll: false
    };
  },

  computed: {
    displayedMatters() {
      if (this.showAll) {
        return this.matters;
      }
      else {
        return this.matters.slice(0, 3);
      }
    },

    displayComponent() {
      return this.anyDataIsPresent([this.matters]) || this.canEditProfile;
    }
  },

  methods: {
    hasClientName(matter) {
      return matter.client && matter.client.length;
    },

    hasDescription(matter) {
      return matter.description && matter.description.length;
    },

    isLastMatter(index) {
      return index === this.displayedMatters.length - 1;
    },

    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  }
};
</script>
