<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Rates
    </div>

    <div class="box-content">
      <div class="rate-list" v-if="rates.length">
        <div class="rate-row header">
          <div class="rate-cell">
            Name
          </div>

          <div class="rate-cell">
            Client Total
          </div>

          <div class="rate-cell">
            Legal Fee
          </div>

          <div class="rate-cell">
            Priori Fee
          </div>
        </div>

        <div class="rate-row" v-for="rate in rates" :key="rate.id">
          <div class="rate-cell text-ellipsis" :title="rate.name">
            {{ rate.name }}
          </div>

          <div class="rate-cell">
            {{ formattedCurrency(rate.amount) }}
          </div>

          <div class="rate-cell">
            {{ formattedCurrency(rate.lawyerAmount) }}
          </div>

          <div class="rate-cell">
            {{ formattedCurrency(rate.amount - rate.lawyerAmount) }}
          </div>
        </div>
      </div>

      <div class="text-center size-text-base gray-text" v-if="!rates.length">
        No rates added yet.
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from 'vue-app/shared/mixins/currency-filter.js';

export default {
  name: 'MatterRates',

  mixins: [currencyFilter],

  props: {
    rates: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .rate-list {
    .rate-row {
      border-bottom: 1px solid $k-light-gray;

      &:last-child {
        border-bottom: 0;
      }

      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1.3fr repeat(3, .9fr);
        grid-template-rows: 1fr;
        border-bottom: 0;

        .rate-cell:not(:last-child) {
          padding-right: 5px;
        }

        .rate-cell:not(:first-child) {
          padding-left: 5px;
        }

        &.header .rate-cell {
          padding-top: 0;
        }

        &:last-child .rate-cell {
          padding-bottom: 0;
        }
      }

      &.header {
        font-size: 11px;
        font-weight: 600;
      }

      .rate-cell {
        padding-top: 5px;
        padding-bottom: 5px;
        word-break: break-word;
      }
    }
  }
</style>
