const dateComparisons = {
  methods: {
    dateIsAfter(dateString, comparisonDateString) {
      if (!dateString || !comparisonDateString) { return false; }

      const date = new Date(dateString);
      const comparisonDate = new Date(comparisonDateString);

      if (isNaN(date.getTime()) || isNaN(comparisonDate.getTime())) { return false; }

      return date > comparisonDate;
    },

    dateIsBefore(dateString, comparisonDateString) {
      if (!dateString || !comparisonDateString) { return false; }

      const date = new Date(dateString);
      const comparisonDate = new Date(comparisonDateString);

      if (isNaN(date.getTime()) || isNaN(comparisonDate.getTime())) { return false; }

      return date < comparisonDate;
    }
  }
};

export default dateComparisons;
