<template>
  <priori-modal ref="modal" title="Create a List">
    <template #modal-trigger="{ openModal }">
      <loading-button
        name="loadWorkspaces"
        lb-class="secondary-btn-blue bottom-15"
        @lb-click="openListModal">
        Create a List
      </loading-button>
    </template>

    <template #default="{ closeModal }">
      <form role="form" class="a-form" novalidate>
        <div v-if="page === 1 && hasWorkspaces">
          <label>Select a Client <span class="red-text">*</span></label>

          <div v-if="error" class="error-text bottom-5">
            {{ error }}
          </div>

          <div class="bottom-20">
            <button type="button" :class="['select-button bottom-10', { 'selected': workspaceIsSelected(workspace) }]" @click="selectWorkspace(workspace)" v-for="workspace in scoutWorkspaces" :key="`create-list-workspace-${workspace.id}`">{{ workspace.name }}</button>
          </div>

          <div class="row tight-columns">
            <div class="col-xs-3">
              <button type="button" class="primary-btn-blue" @click="goToNextPage">Next</button>
            </div>

            <div class="col-xs-3">
              <button type="button" class="nv-button-white" @click="cancel">Cancel</button>
            </div>
          </div>
        </div>

        <div v-else-if="!hasWorkspaces">
          <div class="size-text-14px bottom-30">
            You don’t have any connected clients that are eligible to receive lists.
          </div>

          <div class="row tight-columns">
            <div class="col-xs-5">
              <button type="button" class="primary-btn-blue" @click="cancel">Close</button>
            </div>
          </div>
        </div>

        <div v-else>
          <label for="list-name">List Name <span class="red-text">*</span></label>

          <div v-if="error" class="error-text bottom-5">
            {{ error }}
          </div>

          <input type="text" id="list-name" name="listName" class="form-control bottom-30" v-model="listName">

          <div class="row tight-columns">
            <div class="col-xs-3">
              <loading-button
                name="createList"
                lb-class="primary-btn-blue"
                @lb-click="handleOnSubmit">
                Save
              </loading-button>
            </div>

            <div class="col-xs-3">
              <button type="button" class="nv-button-white" @click="cancel">Cancel</button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </priori-modal>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service.js';
import ResourceList from 'resources/scout/resource-list.js';
import ScoutWorkspace from 'resources/scout/scout-workspace.js';

export default {
  name: 'CreateList',

  data() {
    return {
      page: 1,
      selectedWorkspaceId: null,
      listName: '',
      error: null,
      workspaces: null
    };
  },

  computed: {
    hasWorkspaces() {
      return this.scoutWorkspaces.length > 0;
    },

    scoutWorkspaces() {
      return (this.workspaces || []).filter(w => !w.featureLab);
    }
  },

  methods: {
    selectWorkspace(workspace) {
      this.selectedWorkspaceId = workspace.id;
      this.error = null;
    },

    workspaceIsSelected(workspace) {
      return this.selectedWorkspaceId === workspace.id;
    },

    openListModal() {
      return LoadingService.around('loadWorkspaces', () => {
        return this.fetchWorkspaces().then(() => {
          this.$refs.modal.openModal();
        });
      });
    },

    fetchWorkspaces() {
      if (this.workspaces === null) {
        return ScoutWorkspace.query({ view: 'firm_show' }).then(workspaces => {
          this.workspaces = workspaces;
        });
      }

      return Promise.resolve();
    },

    goToNextPage() {
      if (this.validated()) {
        this.page += 1;
      }
      else {
        this.error = '* Please select a client';
      }
    },

    validated() {
      this.error = null;

      if (this.page === 1 && !this.selectedWorkspaceId) {
        return false;
      }
      else if (this.page === 2 && !this.listName) {
        return false;
      }

      return true;
    },

    cancel() {
      this.error = null;
      this.page = 1;
      this.selectedWorkspaceId = null;
      this.listName = '';
      this.$refs.modal.closeModal();
    },

    handleOnSubmit() {
      if (this.validated()) {
        const data = {
          name: this.listName,
          resourceType: 'LawyerBridge',
          workspaceId: this.selectedWorkspaceId,
          itemIds: []
        };

        LoadingService.loading('createList');

        return ResourceList.save({ resourceList: data }).then(list => {
          window.location.href = `/scout-firm/clients/${list.workspaceId}/lists/${list.id}`;
        });
      }
      else {
        this.error = '* Name cannot be blank';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .select-button {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
</style>
