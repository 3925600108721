<template>
  <div>
    <div v-if="hasLawyers" class="size-text-12px semibold-weight blue-text text-right-not-xs bottom-15">
      <copy-to-clipboard :text-to-copy="provisionedEmails.join(',')" tooltip-text="Addresses copied to clipboard">
        <svg-icon name="copy" class="base-icon right-5 text-top"></svg-icon>Copy All Provisioned Emails
      </copy-to-clipboard>
    </div>

    <div class="bold-weight vertical-center bottom-5">
      <svg-icon name="clients" class="base-icon no-shrink right-10"></svg-icon>Provisioned {{ firmName }} Recipients:
    </div>

    <div class="normal-weight pill-gray-text bottom-20">
      {{ firmName }} has provisioned the following users to receive and respond to RFPs.
    </div>

    <div v-if="hasLawyers">
      <div v-for="lawyer in sortedProvisionedLawyers" class="row tight-columns list-spacing-15">
        <div class="col-sm-4">
          {{ lawyer.firstName }} {{ lawyer.lastName }}
        </div>

        <div class="col-sm-8 wrap-word">
          {{ lawyer.email }}
        </div>
      </div>
    </div>

    <div v-else class="dark-gray-text size-text-14px">
      This firm has no assigned RFP managers.
    </div>

    <div v-if="showAdditionalRecipients" class="top-30">
      <div class="bold-weight bottom-5">
        Additional Notifications:
      </div>

      <div class="normal-weight pill-gray-text bottom-20">
        Your team has chosen to notify the additional individuals listed below about this RFP. Note they will need to request access to the RFP from their law firm.
      </div>

      <div v-for="additionalRecipient in sortedAdditionalRecipients" class="row tight-columns list-spacing-15">
        <div class="col-sm-4">
          {{ additionalRecipient.firstName }} {{ additionalRecipient.lastName }}
        </div>

        <div :class="['wrap-word', { 'col-sm-5 col-lg-6': onRemoveRecipient, 'col-sm-8': !onRemoveRecipient }]">
          {{ additionalRecipient.email }}
        </div>

        <div v-if="onRemoveRecipient" class="col-sm-3 col-lg-2 text-right-not-xs">
          <button type="button" class="pseudo-link" @click="onRemoveRecipient(additionalRecipient.id)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon>Remove</button>
        </div>
      </div>

      <div v-if="!hasAdditionalRecipients" class="dark-gray-text size-text-14px">
        None added
      </div>
    </div>

    <div v-if="onAddRecipients" class="bold-weight blue-text top-30">
      <a href="" @click.prevent="onAddRecipients">+ Additional Notification</a>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import CopyToClipboard from 'vue-app/shared/components/copy-to-clipboard.vue';

export default {
  name: 'RfqRecipientsList',

  components: {
    SvgIcon,
    CopyToClipboard
  },

  props: {
    firmName: {
      type: String,
      required: true
    },

    provisionedLawyers: {
      type: Array,
      required: true
    },

    additionalRecipients: {
      type: Array,
      default: () => []
    },

    onAddRecipients: {
      type: Function,
      required: false
    },

    onRemoveRecipient: {
      type: Function,
      required: false
    }
  },

  computed: {
    hasLawyers() {
      return this.provisionedLawyers.length > 0;
    },

    provisionedEmails() {
      return this.provisionedLawyers.map(l => l.email);
    },

    sortedProvisionedLawyers() {
      return this.provisionedLawyers.toSorted((a, b) => a.name.localeCompare(b.name));
    },

    hasAdditionalRecipients() {
      return this.additionalRecipients.length > 0;
    },

    sortedAdditionalRecipients() {
      return this.additionalRecipients.toSorted((a, b) => a.firstName.localeCompare(b.firstName));
    },

    showAdditionalRecipients() {
      return this.onAddRecipients || this.hasAdditionalRecipients;
    }
  }
};
</script>
