<template>
  <div class="a-form">
    <label v-if="label">{{ label }}</label>

    <dropdown ref="dropdown" tag="div" class="k-dropdown" v-model="dropdownOpen">
      <button type="button" role="button" :class="['dropdown-toggle', { 'placeholder': showPlaceholder }]">
        <span v-if="showPlaceholder">{{ placeholder }}</span>
        <span v-else class="semibold-weight text-ellipsis">{{ visibleLabel }}</span>

        <span v-if="showButtonTag" :class="['tag', 'left-5', visibleTagStyle]">{{ visibleTagText }}</span>

        <svg-icon name="caret-down" class="caret-down"></svg-icon>
        <svg-icon name="caret-up" class="caret-up"></svg-icon>
      </button>

      <template #dropdown>
        <div class="menu-item-container">
          <div class="options-section-container" v-for="spec in sections" :key="keyFor(spec.tagText)">
            <div class="padded-container">
              <span :class="['tag', spec.tagStyle]">{{ spec.tagText }}</span>
            </div>

            <div v-if="spec.options.length">
              <li role="menuitem" class="section-option text-ellipsis" v-for="option in spec.options" :key="keyFor(optionLabel(option))" @click="onSelect(option)">
                <span class="check-container"><svg-icon name="check" class="check base-icon blue-text" v-if="(option === selectedOption || optionLabel(option) === initialLabel)"></svg-icon></span>

                <span class="size-text-14px">{{ optionLabel(option) }}</span>
              </li>
            </div>

            <div v-else class="dark-gray-text top-5 left-10">
              No {{ itemsType }} Exist
            </div>
          </div>
        </div>

        <div class="padded-container with-top-border">
          <slot></slot>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'DropdownSelectSections',

  props: {
    label: {
      type: String,
      required: false
    },

    sections: {
      type: Array,

      default: () => (
        [
          {
            tagText: '',
            tagStyle: '',
            options: []
          }
        ]
      )
    },

    placeholder: {
      type: String,
      default: 'Select One'
    },

    initialTagText: {
      type: String,
      default: null
    },

    initialLabel: {
      type: String,
      default: null
    },

    labelKey: {
      type: String,
      default: null
    },

    valueKey: {
      type: String,
      default: null
    },

    itemsType: {
      type: String,
      required: true
    },

    clearTrigger: {
      type: Boolean,
      default: false
    },

    resetTrigger: {
      type: Boolean,
      default: false
    },

    onChange: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      dropdownOpen: false,
      hasSelected: false,
      selectedOption: null,
      isReset: false,
      isClear: false
    };
  },

  computed: {
    visibleLabel: function () {
      return this.hasSelected ? this.optionLabel(this.selectedOption) : this.initialLabel;
    },

    visibleTagText: function () {
      return this.hasSelected ? this.selectedSection.tagText : this.initialTagText;
    },

    visibleTagStyle: function () {
      if (this.hasSelected) {
        return this.selectedSection.tagStyle;
      }
      else {
        return this.initialSection.tagStyle;
      }
    },

    showPlaceholder: function () {
      return this.isClear || (!this.initialLabel && !this.hasSelected && !this.isReset);
    },

    showButtonTag: function () {
      return !this.isClear && !this.isReset && (this.initialTagText || this.selectedOption);
    },

    initialSection: function () {
      return this.sections.find((section) => section.tagText.toLowerCase() === this.initialTagText?.toLowerCase());
    },

    selectedSection: function () {
      return this.sections.find((section) => section.options.includes(this.selectedOption)) || this.sections[0];
    }
  },

  watch: {
    // Note: this is a workaround to reset the state of this dropdown from an angular parent component,
    // this should be removed and the reset behavior should be redone when the parent component is migrated to Vue.
    clearTrigger: function (value) {
      this.isClear = value;

      if (value) {
        this.clearSelection();
        this.isReset = false;
      }
    },

    resetTrigger: function (value) {
      this.isReset = value;

      if (value) {
        this.clearSelection();
        this.isClear = false;
      }
    }
  },

  methods: {
    optionLabel: function (option) {
      return option[this.labelKey] || option;
    },

    onSelect: function (selectedOption) {
      this.hasSelected = true;
      this.selectedOption = selectedOption;
      this.isClear = false;
      this.isReset = false;

      let value = this.selectedOption[this.valueKey] || selectedOption;

      this.onChange(value);
    },

    clearSelection: function () {
      this.hasSelected = false;
      this.selectedOption = null;

      this.onChange(null);
    },

    keyFor: function (prefix) {
      return uniqueId(prefix);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .k-dropdown {
    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-color: $k-gray;
    }
  }

  :deep(.open) {
    .dropdown-toggle {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .dropdown-menu {
      margin: 0;
      border: 1px solid $k-gray;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .menu-item-container {
        max-height: 400px;
      }
    }
  }

  .padded-container {
    width: 100%;
    padding: 10px;

    &.with-top-border {
      border-top: 1px solid $k-gray;
    }
  }

  .tag {
    padding: 0 4px 1px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: $border-radius-small;

    &.gray {
      background: $medium-gray;
    }

    &.black {
      background: $k-darker-gray;
      color: $white;
    }
  }

  .check-container {
    width: 18px;
    display: inline-block;

    .check {
      padding-bottom: 3px;
    }
  }

  .options-section-container {
    padding-bottom: 15px;

    &:not(:nth-last-of-type(1)) {
      border-bottom: 1px solid $k-gray;
    }

    .section-option {
      padding: 4px 10px;
      color: $k-darker-gray;

      &:hover {
        background-color: $k-light-gray;
        cursor: pointer;
      }
    }
  }
</style>
