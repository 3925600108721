import { camelCase, isEmpty } from 'lodash';

class PermissionsService {
  constructor(user) {
    this.user = user;
  }

  can(ability) {
    return this.#permissions().includes(ability);
  }

  #permissions() {
    const snakeCasedPermissions = this.user.permissions;

    if (isEmpty(snakeCasedPermissions)) { return []; }

    return snakeCasedPermissions.map(permission => camelCase(permission));
  }
}

export default PermissionsService;
