<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-8 text-uppercase">
          Budget
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <a :href="`/law_simplified/counsel_requests/${counselRequest.id}/edit/#budget`" class="size-text-13px normal-weight" target="_blank">Edit</a>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Budget
        </div>

        <div class="col-sm-8">
          {{ counselRequest.budget() }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Flexible Budget
        </div>

        <div class="col-sm-8">
          {{ counselRequest.flexibleBudget ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Add'l Budget Info
        </div>

        <div class="col-sm-8">
          <span class="simple-text">{{ counselRequest.budgetDescription || '&mdash;' }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Documents
        </div>

        <div class="col-sm-8">
          <div class="list-spacing-5" v-for="document in counselRequest.documents" :key="document.id">
            <a :href="document.url" target="_blank">{{ document.name }}</a>
          </div>

          <span v-if="!counselRequest.documents.length">&mdash;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestBudget',

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  }
};
</script>
