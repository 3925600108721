import Vue from 'vue';
import { camelCase, upperFirst } from 'lodash';

const vueComponents = require.context('./', true, /\.vue$/);

vueComponents.keys().forEach((fileName) => {
  const componentConfig = vueComponents(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName.split('/').pop().replace(/\.\w+$/, '')
    )
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

// Above was older approach of auto-including everything, I think, which we then
// decided to stray away from. Below are the components intentionally registered
// globally for practicality reasons.

import LoadingSection from 'vue-app/shared/components/loading-section.vue';

Vue.component(LoadingSection);
