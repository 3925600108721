import { render, staticRenderFns } from "./rich-text-display.vue?vue&type=template&id=cb5b4b36&scoped=true"
import script from "./rich-text-display.vue?vue&type=script&lang=js"
export * from "./rich-text-display.vue?vue&type=script&lang=js"
import style0 from "./rich-text-display.vue?vue&type=style&index=0&id=cb5b4b36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5b4b36",
  null
  
)

export default component.exports