<template>
  <div :class="['lawyer-container', { 'disable-lawyer-card': disabled }]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-xs-3 col-sm-1 minus-icon" @click="removeLawyer" v-if="!disabled">
        <svg-icon name="minus-circle" class="base-icon"></svg-icon>
      </div>

      <div :class="disabled ? 'col-xs-12' : 'col-xs-9 col-sm-11'">
        <rfq-suggested-lawyer :lawyer="lawyer.scoutLawyerBridge" :show-rate-title="true"></rfq-suggested-lawyer>
      </div>
    </div>
  </div>
</template>

<script>
import RfqSuggestedLawyer from 'vue-app/scout/client/rfqs/suggested-lawyer.vue';

export default {
  name: 'RfqRecipientLawyerItem',

  components: {
    RfqSuggestedLawyer
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    rfqLawFirmQuote: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      required: false
    }
  },

  methods: {
    removeLawyer() {
      this.rfqLawFirmQuote.suggestedLawyers = this.rfqLawFirmQuote.suggestedLawyers.filter((lawyer) => lawyer.scoutLawyerBridge.id !== this.lawyer.scoutLawyerBridge.id);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .lawyer-container {
    border: 1px solid $medium-gray;
    border-radius: $border-radius-base;
    background-color: $white;
    padding: 12px 20px;
  }

  .minus-icon {
    cursor: pointer;

    .base-icon {
      fill: $pill-gray;
    }

    &:hover .base-icon {
      fill: $k-darker-gray;
    }
  }

  .disable-lawyer-card {
    background-color: $k-lighter-gray;
  }
</style>
