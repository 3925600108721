<template>
  <div>
    <existing-user-terms-of-service-form
      v-if="isForExistingClient"
      :on-submit="onSubmitForExistingUser"
      :terms-of-service-required="termsOfServiceRequired"
      :terms-of-service-url="termsOfServiceUrl">
    </existing-user-terms-of-service-form>
    <div v-else class="kenny-styles">
      <div id="sign-up" class="row top-30 bottom-30">
        <div class="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
          <div class="shadowed-box">
            <div class="box-content symmetrical">
              <client-sign-up-invited-form
                :business-name-is-prefilled="true"
                :client="client"
                :invited-to-team="false"
                :on-submit="onSubmitSignUpForm"
                :terms-of-service-required="termsOfServiceRequired"
                :terms-of-service-url="termsOfServiceUrl">
              </client-sign-up-invited-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientSignUpInvitedForm from 'vue-app/public/invited-client-sign-up-form.vue';
import ExistingUserTermsOfServiceForm from 'vue-app/feature-lab/client/terms-of-service/existing-user-terms-of-service-form.vue';

export default {
  name: 'FeatureLabTermsOfService',

  components: {
    ClientSignUpInvitedForm,
    ExistingUserTermsOfServiceForm
  },

  props: {
    termsOfServiceEmailedEvent: {
      type: Object,
      required: true
    },

    onSubmitForExistingUser: {
      type: Function,
      required: true
    },

    onSubmitForNewUser: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      client: {
        agreeToTerms: false,
        email: this.termsOfServiceEmailedEvent.email,
        businessName: this.termsOfServiceEmailedEvent.termsOfService.workspaceName,
        password: '',
        passwordConfirmation: '',
        phoneExt: '',
        phoneNumber: ''
      }
    };
  },

  computed: {
    isForExistingClient() {
      return this.termsOfServiceEmailedEvent.isForExistingClient;
    },

    termsOfService() {
      return this.termsOfServiceEmailedEvent.termsOfService;
    },

    terms() {
      return this.termsOfService.terms;
    },

    termsIsNoTerms() {
      return this.terms === 'no_terms';
    },

    termsOfServiceAccepted() {
      return this.termsOfService.accepted;
    },

    termsOfServiceRequired() {
      return !this.termsOfServiceAccepted && !this.termsIsNoTerms;
    },

    termsOfServiceUrl() {
      if (this.terms === 'feature_lab') {
        return 'https://www.priorilegal.com/terms-of-use-feature-lab';
      }
      else if (this.terms === 'marketplace_and_feature_lab') {
        return 'https://www.priorilegal.com/terms-of-use';
      }
    }
  },

  methods: {
    onSubmitSignUpForm() {
      this.onSubmitForNewUser(this.client);
    }
  }
};
</script>
