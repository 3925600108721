<template>
  <div class="vertical-center">
    <div>
      <div class="size-text-21px bold-weight tight-lines">
        <a :href="`/scout-company/law-firms/${lawFirmBridge.id}`" class="darker-gray-link right-10" target="_blank">{{ lawFirmBridge.name }}</a>
        <slot name="additional-details"></slot>
      </div>

      <div v-if="lawFirmBridge.firmSize">
        {{ lawFirmBridge.firmSize }}
      </div>

      <div class="vertical-center top-5">
        <rating-circle class="right-5" :rating="rating"></rating-circle>
        <span class="semibold-weight right-5">{{ displayScore }}</span>
        <span class="pill-gray-text bold-weight right-5">&#8231;</span>
        <span class="pill-gray-text">{{ reviewCountDisplay }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';

export default {
  name: 'LawFirmNamePlate',

  components: {
    RatingCircle
  },

  props: {
    lawFirmBridge: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxScore: 10
    };
  },

  computed: {
    reviewCountDisplay() {
      return this.$pluralize('Review', this.reviewCount, true);
    },

    reviewCount() {
      return this.lawFirmBridge.reviewsSummary?.count || 0;
    },

    score() {
      return this.lawFirmBridge.reviewsSummary?.score;
    },

    rating() {
      const percent = this.score ? this.score / this.maxScore * 100 : 0;
      return { percent: percent };
    },

    displayScore() {
      return this.score ? `${Number(this.score).toFixed(1)}` : 'N/A';
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .base-icon:hover {
    fill: $k-darker-gray;
  }
</style>
