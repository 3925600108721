<template>
  <div class="wired-box">
    <div class="box-header vertical-center">
      <span class="text-uppercase bold-weight size-text-12px flex-6">All Lawyers</span>

      <span class="text-right size-text-13px flex-6" v-if="possibleLawyers.length > 0">
        <span class="bold-weight">{{ 100 - leadPercentage || '' }}%</span> in-network
      </span>
    </div>

    <div class="box-content">
      <div class="summary col-gap-20">
        <div class="canvas-container">
          <doughnut-chart :chart-data="chartData" width="116px" height="116px" :cutout-percentage="77">
            <div class="inset flex-center vertical-center text-center">
              <div>
                <div class="size-text-17px">
                  {{ possibleLawyers.length }}
                </div>

                <div>
                  Lawyers
                </div>
              </div>
            </div>
          </doughnut-chart>
        </div>

        <div class="legend">
          <chart-legends :legend-data="chartData" :column-count="1" :initial-max-legends="6"></chart-legends>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from 'vue-app/shared/components/doughnut-chart.vue';
import ChartLegends from 'vue-app/shared/components/chart-legends.vue';
import { groupBy, map } from 'lodash';

export default {
  name: 'PrioriLegalAllLawyersGraph',

  components: {
    DoughnutChart,
    ChartLegends
  },

  props: {
    possibleLawyers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      statusColorMap: {
        sent:    '#3daa2d', // $k-green
        unsent:  '#ffa21f', // $k-yellow-dark
        applied: '#af2e8d', // $k-purple
        invited: '#1484c9', // $k-blue
        tracked: '#999',    // $k-dark-gray
        lead:    '#333'     // $k-darker-gray
      },

      statusLabelMap: {
        sent:    'Sent',
        unsent:  'Unsent',
        applied: 'Applied',
        invited: 'Invited',
        tracked: 'Tracked',
        lead:    'Leads'
      }
    };
  },

  computed: {
    possibleLawyerStatuses() {
      return this.possibleLawyers.map((possibleLawyer) => {
        return this.status(possibleLawyer);
      });
    },

    groupedByStatus() {
      return groupBy(this.possibleLawyers, possibleLawyer => this.status(possibleLawyer));
    },

    chartData() {
      return map(this.statusColorMap, (color, status) => {
        return {
          name: this.statusLabelMap[status],
          color: color,
          value: (this.groupedByStatus[status] || []).length,
          italicized: status === 'lead'
        };
      });
    },

    leadPercentage() {
      return Math.round(((this.groupedByStatus.lead?.length || 0)  / this.possibleLawyers.length) * 100);
    }
  },

  methods: {
    status(possibleLawyer) {
      if (['tracked', 'invited', 'unsent'].includes(possibleLawyer.status)) {
        return possibleLawyer.status;
      }
      else if (possibleLawyer.status === 'applied' && possibleLawyer.lawyerId) {
        return 'applied';
      }
      else if (possibleLawyer.lawyerId) {
        return 'sent';
      }
      else {
        return 'lead';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wired-box .box-content {
  padding: 20px 30px;
}

:deep(.summary) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;

  .canvas-container .doughnut-chart {
    position: relative;
  }

  .legend {
    width: 100px;

    ul {
      margin: 0;
    }

    .bullet-container {
      width: 20px;

      .bullet {
        width: 8px;
        height: 8px;
      }
    }
  }
}

.inset {
  width: 116px;
  height: 116px;
  line-height: 1.2;
  position: absolute;
  top: 0;
}
</style>
