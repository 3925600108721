<template>
  <tooltip :text="text" placement="top" custom-class="k-tooltip tooltip-white small-text" append-to="body">
    <span class="tooltip-question">?</span>
  </tooltip>
</template>

<script>
export default {
  name: 'QuestionTooltip',

  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>
