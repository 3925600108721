<template>
  <div>
    <div class="bottom-30">
      <feature-lab-client-welcome-banner></feature-lab-client-welcome-banner>
    </div>

    <div v-if="hasRfqEnabled" class="row">
      <div class="col-sm-8">
        <rfq-notifications :notifications="notifications"></rfq-notifications>
      </div>

      <div class="col-sm-4 top-30-xs">
        <feature-lab-marketplace-actions></feature-lab-marketplace-actions>
      </div>
    </div>

    <div v-else class="row d-flex-not-xs">
      <div class="col-sm-4">
        <feature-lab-marketplace-actions></feature-lab-marketplace-actions>
      </div>

      <div class="col-sm-8 d-grid top-30-xs">
        <provision-rfp-access :workspace="currentUser.workspace"></provision-rfp-access>
      </div>
    </div>
  </div>
</template>

<script>
import RfqNotifications from 'vue-app/scout/client/dashboard/rfq-notifications.vue';
import FeatureLabClientWelcomeBanner from 'vue-app/feature-lab/client/dashboard/welcome-banner.vue';
import FeatureLabMarketplaceActions from 'vue-app/feature-lab/client/dashboard/marketplace-actions.vue';
import ProvisionRfpAccess from 'vue-app/feature-lab/client/dashboard/provision-rfp-access.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FeatureLabClientDashboard',

  components: {
    RfqNotifications,
    FeatureLabClientWelcomeBanner,
    FeatureLabMarketplaceActions,
    ProvisionRfpAccess
  },

  props: {
    notifications: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    hasRfqEnabled() {
      return this.currentUser.hasRfqEnabled;
    }
  }
};
</script>
