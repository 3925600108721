<template>
  <div>
    <div class="row bottom-15">
      <div class="col-sm-8">
        <span class="size-text-base semibold-weight right-10">{{ employment.firmName }}</span>
        <span class="dark-gray-text text-nowrap">{{ firmSize }}</span>
      </div>

      <div class="col-sm-4 text-right-not-xs">
        <span class="dark-gray-text">{{ dateRange }}</span>
      </div>
    </div>

    <div class="small-header-text bottom-5">
      {{ employment.title }}
    </div>

    <div v-if="employment.positionDescription && employment.positionDescription.length">
      <collapsable-text :text="employment.positionDescription" :char-limit="200"></collapsable-text>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'AccountEmployment',

  props: {
    employment: {
      type: Object,
      required: true
    }
  },

  computed: {
    dateRange() {
      const fromDate = DateFilter.filter(this.employment.fromDate, { year: 'numeric' });

      if (this.employment.currentPosition) {
        return fromDate ? fromDate + ' - Present' : 'Current Position';
      }
      else {
        const toDate = DateFilter.filter(this.employment.toDate, { year: 'numeric' });
        return toDate ? fromDate + ' - ' + toDate : fromDate;
      }
    },

    firmSize() {
      if (this.employment.firmSize) {
        return this.employment.firmSize.replace('employee', 'Employee');
      }
      else {
        return '';
      }
    }
  }
};
</script>
