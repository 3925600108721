<template>
  <div class="question-yes-no-selector">
    <label>{{ label }}</label>

    <div>
      <button
        type="button"
        :class="['select-button selectable bottom-10', { 'selected': responded && highlightableAnswerType }]"
        @click.prevent="select(true)">
        Yes
      </button>

      <button
        type="button"
        :class="['select-button selectable bottom-10', { 'selected': responded && !highlightableAnswerType }]"
        @click.prevent="select(false)">
        No
      </button>
    </div>
  </div>
</template>

<script>
import { isNull } from 'lodash';

export default {
  name: 'QuestionYesNoSelector',

  props: {
    field: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    highlightableAnswerType: {
      type: Boolean,
      required: false
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedScore: this.value
    };
  },

  computed: {
    responded() {
      return !isNull(this.highlightableAnswerType);
    }
  },

  methods: {
    select(booleanValue) {
      this.onChange(this.field, booleanValue);
    }
  }
};
</script>

<style lang="scss" scoped>
  .select-button:not(:last-child) {
    margin-right: 10px;
  }
</style>
