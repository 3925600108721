<template>
  <edit-modal title="Editing Highlights" :input="firm" :on-save="save" :on-cancel="cancelEdit" @on-modal-open="openModal">
    <template #default="{ inputCopy: firmCopy }">
      <form role="form" name="form" class="a-form" novalidate>
        <div class="bottom-30">
          <div class="small-bold-header">
            Representative Clients
          </div>

          <hr>

          <div class="bottom-20 text-italic pill-gray-text size-text-12px">
            Please use the input box below to add any representative clients that you want to appear on your law firm&rsquo;s profile.
          </div>

          <div class="row bottom-10">
            <div class="col-sm-6">
              <add-tag-input :on-add="addRepresentativeClient"></add-tag-input>
            </div>
          </div>

          <div>
            <tag-list :tags="representativeClients" :on-remove="removeRepresentativeClient"></tag-list>
          </div>
        </div>

        <div>
          <div class="small-bold-header">
            Industries
          </div>

          <hr>

          <div class="bottom-20 text-italic pill-gray-text size-text-12px">
            Please use the dropdown menu below to select any industries that you want to appear on your law firm&rsquo;s profile.
          </div>

          <div class="row bottom-10">
            <div class="col-sm-6">
              <dropdown ref="dropdown" v-model="dropdownOpen" :not-close-elements="elementsToNotCloseOn" class="k-dropdown bottom-10">
                <button type="button" role="button" class="dropdown-toggle">
                  <span>Select all that apply</span>
                  <svg-icon name="caret-down" class="caret-down"></svg-icon>
                  <svg-icon name="caret-up" class="caret-up"></svg-icon>
                </button>

                <template slot="dropdown">
                  <div class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
                    <li v-for="industry in industries" :key="industry.id">
                      <label class="check-option vertical medium-large">
                        {{ industry.name || industry.label }}
                        <input type="checkbox" v-model="selectedIndustries" :value="industry.id" :name="`check${ industry.id }`" :id="`check${ industry.id }`">
                        <span class="check c-box"></span>
                      </label>
                    </li>
                  </div>
                </template>
              </dropdown>
            </div>
          </div>

          <div>
            <tag-list :tags="selectedIndustries" :on-remove="removeIndustry" :value-fn="getIndustryName"></tag-list>
          </div>
        </div>
      </form>
    </template>

    <template #errorMessage>
      * One or more required fields are empty. Please fill in the required fields indicated above or remove those entries from the form.
    </template>
  </edit-modal>
</template>

<script>
import EditModal from '../edit-modal';
import AddTagInput from '../add-tag-input';
import TagList from '../tag-list';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import ScoutFirm from 'resources/scout/scout-firm';
import DataLock from 'resources/data-lock';
import { changes }  from 'misc/resource-helpers';
import { some, cloneDeep } from 'lodash';

export default {
  name: 'EditLawyerHighlights',

  components: {
    EditModal,
    AddTagInput,
    TagList
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      representativeClients: [],
      selectedIndustries: [],
      dropdownOpen: false,
      elementsToNotCloseOn: [],
      industries: [],
      originalClients: [],
      originalIndustries: []
    };
  },

  mounted() {
    this.resetForm();
    this.elementsToNotCloseOn = [this.$refs.dropdown.$refs.dropdown];
  },

  methods: {
    resetForm() {
      this.representativeClients = [...this.firm.representativeClients];
      this.selectedIndustries = this.firm.industries.map((industry) => industry.id);
      this.originalClients = cloneDeep(this.representativeClients);
      this.originalIndustries = cloneDeep(this.selectedIndustries);
    },

    addRepresentativeClient(client) {
      this.representativeClients.push({
        name: client
      });
    },

    removeRepresentativeClient(index) {
      this.representativeClients.splice(index, 1);
    },

    removeIndustry(index) {
      this.selectedIndustries.splice(index, 1);
    },

    getIndustryName(id) {
      var industry = this.industries.find((industry) => industry.id === id);

      if (industry) {
        return industry.name;
      }
    },

    save() {
      var firmEdited = {
        ...this.firm,
        lawFirmRepresentativeClientsAttributes: this.clientsWithPersistence(),
        scoutFirmIndustriesAttributes: this.industriesWithPersistence()
      };

      const flagsToSet = [];
      if (some(changes(this.originalClients, this.representativeClients))) {
        flagsToSet.push('representative_clients');
      }
      if (some(changes(this.originalIndustries, this.selectedIndustries))) {
        flagsToSet.push('industries');
      }

      this.onSave(firmEdited, ()=>{
        DataLock.save({
          dataLock: {
            flag:         flagsToSet,
            resourceId:   this.firm.id,
            resourceType: 'law_firm'
          }
        });
      });
    },

    cancelEdit() {
      this.resetForm();
    },

    clientsWithPersistence() {
      var clientsToDelete = this.markRepresentativeClientsToBeDeleted();

      return _.union(this.representativeClients, clientsToDelete);
    },

    markRepresentativeClientsToBeDeleted() {
      return this.firm.representativeClients.map((client) => {
        if (!this.representativeClients.find((representativeClient) => representativeClient.id === client.id)) {
          client._destroy = true;
        }

        return client;
      });
    },

    industriesWithPersistence() {
      var selectedIndustries = this.buildSelectedIndustries();
      var industriesToDelete = this.markIndustriesToBeHidden(selectedIndustries);

      return _.unionBy(industriesToDelete, selectedIndustries, 'id');
    },

    buildSelectedIndustries() {
      return this.selectedIndustries.map((id) => {
        var lawyerIndustry = this.industries.find((industry) => industry.id === id);

        return {
          id: lawyerIndustry.id,
          name: lawyerIndustry.name,
          displayOnProfile: true
        };
      });
    },

    markIndustriesToBeHidden(selectedIndustries) {
      return this.firm.industries.map((industry) => {
        if (!selectedIndustries.find((selectedIndustry) => selectedIndustry.id === industry.id)) {
          industry.displayOnProfile = false;
        }

        return industry;
      });
    },

    openModal() {
      if (!this.industries.length) {
        ScoutFirm.get({ id: 'profile', view: 'lists' }).then((response) => {
          this.industries = response.industries;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .menu-item-container {
    padding: 5px;

    li {
      padding: 5px 10px;
    }
  }
</style>
