import CurrencyFormatter from 'vue-app/shared/misc/currency-formatter.js';

const matterFeeFormatter = {
  methods: {
    formattedFee(feeTotalInUsCents) {
      if (typeof feeTotalInUsCents === 'number') {
        return CurrencyFormatter.standard().format(feeTotalInUsCents / 100);
      }
      else {
        return feeTotalInUsCents;
      }
    }

  }
};

export default matterFeeFormatter;
