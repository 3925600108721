<template>
  <div>
    <div class="small-bold-header">
      Court Admissions
    </div>

    <hr class="margin-top-5">

    <loading-section name="courts">
      <div class="flex" v-for="(courtAdmission, index) in courtAdmissions" :key="`courtAdmission_${courtAdmission.id}_${index}`">
        <div>
          <a href="" @click.prevent="removeCourtAdmission(courtAdmission, index)"><svg-icon name="minus-circle" class="base-icon remove-icon-spacing"></svg-icon></a>
        </div>

        <div class="row tight-columns width-95">
          <div class="col-sm-9 bottom-15">
            <typeahead-vertical
              label="Court Name"
              rules="required"
              :id="`court-admission-court-name-${courtAdmission.id}-${index}`"
              :initial-value="courtAdmission.courtName"
              :input-name="`courtAdmissionCourtName_${courtAdmission.id}_${index}`"
              :force-select="true"
              :options="courtNames"
              v-model="courtAdmission.courtName"
              @input="courtSelected(courtAdmission)">
            </typeahead-vertical>
          </div>

          <div class="col-sm-3 bottom-15">
            <dropdown-select
              label="Year"
              :id-label="`court-admission-year-${courtAdmission.id}-${index}`"
              :initial-label="dateToYear(courtAdmission.admitDate)"
              :options="yearOptions"
              @input="admissionYearUpdate(courtAdmission, $event)">
            </dropdown-select>
          </div>
        </div>
      </div>

      <div>
        <a href="" class="semibold-weight" @click.prevent="addCourtAdmission">+ {{ courtAdmissions.length ? 'Add Another' : 'Add Court Admission' }}</a>
      </div>
    </loading-section>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'EditLawyerCourtAdmissions',

  components: {
    LoadingSection
  },

  props: {
    courtAdmissions: {
      type: Array,
      required: true
    },

    courtAdmissionsToDestroy: {
      type: Array,
      required: true
    },

    firmCourts: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    courtNames() {
      return _.map(this.firmCourts, 'name').sort();
    },

    yearOptions: function () {
      const thisYear = new Date().getFullYear();
      return this.range(thisYear, 1900);
    }
  },

  methods: {
    courtSelected(courtAdmission) {
      if (!courtAdmission.courtName) { return; }

      const firmCourt = _.find(this.firmCourts, { name: courtAdmission.courtName });
      if (!firmCourt) { return; }

      courtAdmission.scoutFirmCourtId = firmCourt.id;
    },

    dateToYear: function (date) {
      return DateFilter.filter(date, { year: 'numeric', timeZone: 'UTC' });
    },

    addCourtAdmission: function () {
      this.courtAdmissions.push({ admitDate: null, courtName: null, scoutFirmCourtId: null });
    },

    removeCourtAdmission: function (courtAdmission, index) {
      if (index < 0) { return; }

      if (courtAdmission.id) {
        this.courtAdmissionsToDestroy.push({ id: courtAdmission.id, '_destroy': true });
      }

      this.courtAdmissions.splice(index, 1);
    },

    admissionYearUpdate: function (courtAdmission, value) {
      courtAdmission.admitDate = `${value}-01-01`;
    },

    range: function (start, end) {
      if (start === end) { return [start]; }
      return [start, ...this.range(start - 1, end)];
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
  }

  .width-95 {
    width: 95%;
  }

  .margin-top-5 {
    margin-top: 5px;
  }

  .remove-icon-spacing {
    margin-top: 40px;
    margin-right: 10px;

    @media (min-width: 768px) {
      margin-top: 38px;
    }
  }
</style>
