<template>
  <div name="form" class="a-form filter-popover">
    <div class="text-right bottom-15">
      <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
        Clear Filter
      </button>
    </div>

    <div class="row vertical-center">
      <div class="col-xs-6 semibold-weight">
        Required
      </div>

      <div class="col-xs-6 text-right">
        <button type="button" :class="['toggle-button md', { 'toggle-on': isFilterContext }]" @click="switchFilterContext">
          {{ isFilterContext ? 'Yes' : 'No' }}
        </button>
      </div>
    </div>

    <div class="row tight-columns top-20">
      <div class="col-xs-6 col-sm-5">
        <label :for="`${field}-min`">{{ minLabel }}</label>

        <div class="input-with-icon">
          <validation-provider :rules="`integer|numeric|minValue:${min}|maxValue:${upper}`" v-slot="{ errors }">
            <input
              type="number"
              :id="`${field}-min`"
              :name="`${field}Min`"
              :class="['form-control', { 'has-error': errors.length }]"
              autocomplete="off"
              :min="min"
              :max="upper"
              :step="step"
              required
              v-model="lower"
              @input="debouncedInputChange">
          </validation-provider>

          <div class="form-icon">$</div>
        </div>
      </div>

      <div class="col-xs-6 col-sm-5 col-sm-offset-2">
        <label :for="`${field}-max`">{{ maxLabel }}</label>

        <div class="input-with-icon">
          <validation-provider :rules="`integer|numeric|minValue:${lower}|maxValue:${max}`" v-slot="{ errors }">
            <input
              type="number"
              :id="`${field}}-max`"
              :name="`${field}}Max`"
              :class="['form-control', { 'has-error': errors.length }]"
              autocomplete="off"
              :min="lower"
              :max="max"
              :step="step"
              required
              v-model="upper"
              @input="debouncedInputChange">
          </validation-provider>

          <div class="form-icon">$</div>
        </div>
      </div>
    </div>

    <div class="top-20" v-if="!lockContext">
      <div class="filter-footer multiline semibold-weight">
        Set the “Required” toggle to “Yes” to ensure all search results meet the selected criteria.
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import SearchFilter from 'vue-app/shared/mixins/search-filter.js';
import { debounce, isEmpty } from 'lodash';

export default {
  name: 'FilterRangeCurrency',

  components: {
    ValidationProvider
  },

  mixins: [
    SearchFilter
  ],

  props: {
    searchService: {
      type: Object,
      required: true
    },

    filterParams: {
      type: Object,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    const { min, max, slug, field, step, lockContext } = this.filterParams;
    const isRateFilter = slug === 'rate';

    return {
      slug,
      field,
      min: min || 0,
      max: max || 1000,
      lower: min,
      upper: max,
      step,
      lockContext: !!lockContext,
      minLabel: `Min${isRateFilter ? ' Rate' : ''}`,
      maxLabel: `Max${isRateFilter ? ' Rate' : ''}`
    };
  },

  created() {
    this.debouncedInputChange = debounce(() => {
      const lower = Number(this.lower);
      const upper = Number(this.upper);

      if (this.lower === '' || this.upper === '' || lower < 0 || upper > this.max || lower > upper) {  return; }

      this.onchanged();
    }, 200).bind(this);
  },

  mounted() {
    const currentSelections = this.searchService.currentSelections(this.field, this.filterContext, true);

    if (!isEmpty(currentSelections)) {
      this.lower = currentSelections.gte / 100 || this.min;
      this.upper = currentSelections.lte / 100 || this.max;
    }
  },

  methods: {
    onchanged() {
      if (this.lower > this.min || this.upper < this.max) {
        this.searchService.setFilterParams(
          this.field,
          {
            gte: this.lower * 100,
            lte: this.upper * 100
          },
          this.filterContext
        );
      }
      else {
        this.searchService.unsetFilter(this.field);
      }

      this.onSearch();
    },

    clearFilter() {
      this.lower = this.min;
      this.upper = this.max;

      this.searchService.unsetFilter(this.field);
      this.onSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .slider-container {
    padding: 0 10px;
  }

  .k-form .has-error, .a-form .has-error {
    color: $k-darker-gray;
  }

  :deep(.vue-slider-dot-tooltip-top) {
    top: -6px;
  }
</style>
