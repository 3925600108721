<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div class="col-sm-3 size-text-17px bold-weight">
      <span v-if="isEditing">Edit </span>Your Quote
    </div>

    <div class="col-sm-9 top-20-xs">
      <div class="vertical-center-not-xs flex-end gap-20">
        <div>
          <svg-icon name="checkmark" class="base-icon"></svg-icon>
          <span v-if="hasSubmittedDate" class="bold-weight left-5">Quote last submitted on {{ dateResponded }}</span>
          <span v-else class="bold-weight left-5">Quote Submitted</span>
        </div>

        <div v-if="!isEditing" class="top-20-xs">
          <button type="button" class="primary-button-compact" @click="onEditQuote">Edit Quote</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';

export default {
  name: 'QuoteDetailHeader',

  props: {
    quote: {
      type: Object,
      required: true
    },

    isEditing: {
      type: Boolean,
      required: true
    },

    onEditQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    dateResponded() {
      return DateFilter.filter(this.quote.lastUpdatedAt || this.quote.dateResponded);
    },

    hasSubmittedDate() {
      return this.quote.dateResponded || this.quote.lastUpdatedAt;
    }
  }
};
</script>
