<template>
  <div>
    <div class="size-text-15px bold-weight bottom-15 d-flex-not-xs">
      <div class="right-15">
        Priori’s Marketplace Network
      </div>

      <rfq-add-to-marketplace
        v-if="canSendToMarketplace"
        class="top-5-xs"
        :rfq="rfq"
        :send-to-marketplace="sendToMarketplace">
      </rfq-add-to-marketplace>
    </div>

    <div v-if="sentToMarketplace">
      <div v-if="!hasMarketplaceQuotes" class="no-quotes dark-gray-text">
        You have not yet received any Marketplace quotes
      </div>

      <quote-group
        :rfq="rfq"
        :quotes="marketplaceLawFirmQuotes"
        :view-quote="viewQuote">
      </quote-group>
    </div>

    <div v-else class="quote-container">
      <div class="vertical-center-not-xs">
        <div class="relative-container inline-block right-10">
          <svg-icon name="priori-purple" class="generic-icon small"></svg-icon>
        </div>

        <div class="size-text-13px top-10-xs">
          <span class="bold-weight">Submit this RFP to Priori’s global legal marketplace platform.</span> We’ll identify the best-fit options in our 8000+ legal network and send you a short-list to compare with your firms.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteGroup from 'vue-app/scout/client/rfqs/quote-group.vue';
import RfqAddToMarketplace from 'vue-app/scout/client/rfqs/add-to-marketplace.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RfqRecipientsMarketplace',

  components: {
    QuoteGroup,
    RfqAddToMarketplace,
    SvgIcon
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    canEditRfq: {
      type: Boolean,
      required: true
    },

    marketplaceLawFirmQuotes: {
      type: Array,
      required: true
    },

    sendToMarketplace: {
      type: Function,
      required: true
    },

    viewQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentProduct: 'currentProduct', user: 'currentUser' }),

    sentToMarketplace() {
      return this.rfq.sentToMarketplace;
    },

    canSendToMarketplace() {
      return this.canEditRfq && !this.sentToMarketplace &&
        (this.currentProduct.isFeatureLab || this.user.workspace.marketplaceRfqsEnabled);
    },

    hasMarketplaceQuotes() {
      return this.marketplaceLawFirmQuotes.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .quote-container {
    border: 1px solid $medium-gray;
    border-radius: $border-radius-base;
    padding: 10px 15px;
    border-left: 3px solid $k-purple;
  }

  .no-quotes {
    padding: 21px 15px;
    text-align: center;
    border-radius: $border-radius-large;
    background-color: $k-lighter-gray;
    color: $pill-gray;
  }
</style>
