<template>
  <edit-modal title="Editing Practice Areas &amp; Proficiencies" :input="lawyerPracticeAreas" :on-save="save" :on-cancel="resetForm" @on-modal-open="openModal">
    <template #default="{ inputCopy: lawyerPracticeAreasCopy }">
      <div class="small-bold-header">
        Practice Areas
      </div>

      <hr class="top-5 bottom-5">

      <div class="text-italic pill-gray-text size-text-12px bottom-15">
        Please use the dropdown menu below to add any practice areas that you want to appear on your profile. After selecting a practice area, use the ‘Edit’ button to add proficiencies within that practice area.
      </div>

      <div class="row">
        <div class="bottom-20 col-sm-6">
          <dropdown ref="dropdown" tag="div" id="addPracticeArea" class="k-dropdown" v-model="dropdownOpen">
            <button type="button" role="button" id="addPracticeAreaButton" class="dropdown-toggle">
              <span>Add a Practice Area</span>
              <svg-icon name="caret-down" class="caret-down"></svg-icon>
              <svg-icon name="caret-up" class="caret-up"></svg-icon>
            </button>

            <template slot="dropdown" class="dropdown-menu" role="menu" aria-labelledby="addPracticeAreaButton">
              <div data-testid="unselected-practice-areas" class="menu-item-container" v-uiv-scrollable-dropdown="dropdownOpen">
                <li role="menuitem" class="menu-item" v-for="firmPracticeArea in unselectedPracticeAreas" :key="firmPracticeArea.name" @click="addPracticeArea(firmPracticeArea, lawyerPracticeAreasCopy)">
                  <div class="dropdown-row-inner">
                    {{ firmPracticeArea.name }}
                  </div>
                </li>
              </div>
            </template>
          </dropdown>
        </div>
      </div>

      <div data-testid="lawyer-practice-areas">
        <div v-for="(practiceArea, index) in lawyerPracticeAreasCopy" :key="practiceArea.id">
          <edit-practice-area
            :remove-practice-area="() => removePracticeArea(lawyerPracticeAreasCopy, practiceArea)"
            :practice-area="practiceArea"
            :first-item="index == 0"
            :last-item="index == lawyerPracticeAreasCopy.length - 1"
            :ref="practiceArea.name">
          </edit-practice-area>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import DataLock from 'src/resources/data-lock.js';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import EditPracticeArea from 'vue-app/scout/shared/lawyers/edit-practice-area.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown.js';
import { isEqual, filter, find, findIndex, sortBy, pull, remove, forEach } from 'lodash';

export default {
  name: 'LawyerPracticeAreas',

  directives: {
    uivScrollableDropdown
  },

  components: {
    EditModal,
    EditPracticeArea,
    SvgIcon
  },

  props: {
    lawyer: {
      type: Object,
      // when rendered for a client view, there is no lawyer
      required: false
    },

    onSave: {
      type: Function,
      required: true
    },

    firmPracticeAreas: {
      type: Array,
      required: true
    },

    lawyerPracticeAreas: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      practiceAreasToDestroy: [],
      dropdownOpen: false,
      unselectedPracticeAreas: []
    };
  },

  methods: {
    openModal() {
      this.resetForm();
      this.unselectedPracticeAreas = this.alphabetaizedPracticeAreas();
    },

    filterSelectedPracticeAreas() {
      return filter(this.firmPracticeAreas, firmPracticeArea => {
        return find(this.lawyerPracticeAreas, lawyerPracticeArea => lawyerPracticeArea.name == firmPracticeArea.name) == undefined;
      });
    },

    alphabetaizedPracticeAreas() {
      return sortBy(this.filterSelectedPracticeAreas(), pa => { return pa.name; });
    },

    resetForm() {
      this.practiceAreasToDestroy = [];
      this.collapseAll();
    },

    save(lawyerPracticeAreasCopy) {
      let lock = false;

      LoadingService.loading('practice-areas');

      const practiceAreaUpdates = lawyerPracticeAreasCopy.concat(this.practiceAreasToDestroy);

      const payload = {
        lawyerProfileAttributes: {
          scoutLawyerPracticeAreasAttributes: practiceAreaUpdates
        }
      };

      if (!isEqual(this.lawyerPracticeAreas, lawyerPracticeAreasCopy)) {
        lock = true;
      }

      this.onSave(payload,
        () => {
          LoadingService.done('practice-areas');
          if (!lock) { return; }

          DataLock.save({
            dataLock: {
              flag:         'practice_areas_&_proficiencies',
              resourceId:   this.lawyer.id,
              resourceType: 'lawyer'
            }
          });
        }
      );
    },

    addPracticeArea: function (firmPracticeArea, lawyerPracticeAreasCopy) {
      let paIndex = findIndex(this.unselectedPracticeAreas, { id: firmPracticeArea.id });
      let addedPracticeArea = this.unselectedPracticeAreas.splice(paIndex, 1)[0];

      remove(this.unselectedPracticeAreas, unSelectedPracticeArea => unSelectedPracticeArea.name == addedPracticeArea.name);
      lawyerPracticeAreasCopy.push({
        scoutFirmPracticeAreaId:  addedPracticeArea.id,
        name:                     addedPracticeArea.name,
        displayOnProfile:         true,
        proficiencies:            []
      });
    },

    removePracticeArea: function (lawyerPracticeAreasCopy, practiceArea) {
      this.practiceAreasToDestroy.push({ ...practiceArea, '_destroy': true });
      pull(lawyerPracticeAreasCopy, practiceArea);
      this.unselectedPracticeAreas.push({
        ...practiceArea,
        id: practiceArea.scoutFirmPracticeAreaId
      });

      this.unselectedPracticeAreas = sortBy(this.unselectedPracticeAreas, pa => { return pa.name; });
    },

    collapseAll() {
      forEach(this.lawyerPracticeAreas, pa => {
        const component = this.$refs[pa.name][0];
        if (component) { component.collapse(); }
      });
    }
  }
};
</script>
