<template>
  <div class="page-controls" v-if="pages > 1">
    <a href="" class="move-previous" @click.prevent="movePrevious()"><svg-icon name="caret-left" class="base-icon"></svg-icon></a>

    <a href="" :class="{ 'right-5': index !== pageControls.length - 1 }" v-for="(p, index) in pageControls" :key="index" @click.prevent="goToPage(index + 1)"><svg-icon name="circle" class="base-icon circle-icon" :class="[{ 'selected': atPage(index + 1) }]"></svg-icon></a>

    <a href="" class="move-next" @click.prevent="moveNext()"><svg-icon name="caret-right" class="base-icon"></svg-icon></a>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'CardPager',

  components: {
    SvgIcon
  },

  props: {
    collection: Array,

    initialPage: {
      type: Number,
      default: 1
    },

    perPage: {
      type: Number,
      default: 1
    },

    pageChanged: Function
  },

  data() {
    return {
      page: this.initialPage
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.collection.length / this.perPage);
    },

    pageControls() {
      return new Array(this.pages);
    }
  },

  methods: {
    atFirstPage() {
      return this.page === 1;
    },

    atLastPage() {
      return this.page === this.pages;
    },

    atPage(page) {
      return this.page === page;
    },

    movePrevious() {
      this.goToPage(this.page - 1);
    },

    moveNext() {
      this.goToPage(this.page + 1);
    },

    goToPage(page) {
      if (page < 1) {
        this.page = this.pages;
      }
      else if (page > this.pages) {
        this.page = 1;
      }
      else {
        this.page = page;
      }

      this.$emit('page-changed', this.page);
    }
  }
};
</script>
