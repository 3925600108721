<template>
  <boxed-content v-if="displayComponent" title="Law Firm Status">
    <div v-if="displayRFQStatus" class="row tight-columns">
      <div class="col-xs-2">
        <svg-icon name="rfq" class="base-icon"></svg-icon>
      </div>

      <div class="col-xs-6 bold-weight tight-lines">
        RFP Enabled Firm
      </div>

      <div class="col-xs-4 text-right">
        {{ lawyer.firmHasRfqEnabled ? 'Yes' : 'No' }}

        <popover-tooltip custom-class="semibold-weight">
          <span class="info-icon pill-gray-text"><svg-icon data-testid="info-tooltip" name="info" class="base-icon smaller text-top"></svg-icon></span>
          <template #tooltip>
            This status indicates whether or not this lawyer’s firm is eligible to receive RFPs. If RFP is not enabled, please contact your administrator to enable RFP for this firm.
          </template>
        </popover-tooltip>
      </div>
    </div>

    <hr v-if="displayRFQStatus && displayScoutTags" class="kenny-hr">

    <div v-if="displayScoutTags">
      <div class="semibold-weight">
        Law Firm Tags
      </div>

      <div class="dark-gray-text text-italic top-2 bottom-20">
        Your company’s Scout admins have applied the following law firm tags to this attorney’s firm.
      </div>

      <scout-tags-list-with-modal
        :scout-tags="lawyer.scoutTags"
        :firm-name="lawyer.firmName"
        :max-height="90">
      </scout-tags-list-with-modal>
    </div>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import ScoutTagsListWithModal from 'vue-app/scout/shared/scout-tags/scout-tags-list-with-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'LawyerLawFirmStatus',

  components: {
    BoxedContent,
    PopoverTooltip,
    ScoutTagsListWithModal,
    SvgIcon
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    displayComponent() {
      return this.displayScoutTags || this.displayRFQStatus;
    },

    displayScoutTags() {
      return this.lawyer.scoutTags.length;
    },

    displayRFQStatus() {
      return this.currentClient.workspaceHasRfqEnabled;
    }
  }
};
</script>

<style scoped lang="scss">
  .info-icon .base-icon {
    margin-top: 3px;
  }
</style>
