import { isEqual, uniq, reduce } from 'lodash';

const changes = function (original, changed) {
  const keys = uniq(Object.keys(original).concat(Object.keys(changed)));
  const modifiedKeys = keys.filter(key => !isEqual(original[key], changed[key]));

  return reduce(modifiedKeys, (collectedChanges, key) => {
    collectedChanges[key] = [original[key], changed[key]];
    return collectedChanges;
  }, {});
};

const matchingArrayValues = (array, other) => {
  return isEqual([...array].sort(), [...other].sort());
};

export { changes, matchingArrayValues };
