import axios from 'axios';
import BaseResource from 'resources/base-resource';

class RequestTracker extends BaseResource {
  static baseUrl = '/request_trackers/:id.json';

  static stats() {
    return axios.get('/request_trackers/stats.json');
  }
}

export default RequestTracker;
