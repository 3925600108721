<template>
  <div>
    <div class="breadcrumbs-container">
      <div class="container-fluid">
        <a :href="breadcrumbDestination" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>{{ breadcrumbLabel }}</a>
      </div>
    </div>

    <div class="sidenav-content with-breadcrumbs">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="top-10 bottom-30">
            <document-sharing-editor :is-lawyer="true" :project-id="projectId"></document-sharing-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentSharingEditor from 'vue-app/marketplace/shared/documents/document-sharing-editor.vue';

export default {
  name: 'LawyersShare',

  components: {
    DocumentSharingEditor
  },

  data() {
    return {
      projectId: null
    };
  },

  computed: {
    breadcrumbDestination() {
      return this.projectId ? `/lawyer-app/projects/${this.projectId}` : '/lawyer-app/documents';
    },

    breadcrumbLabel() {
      return this.projectId ? 'Project Details' : 'Document Center';
    }
  },

  mounted() {
    const urlParts  = window.location.pathname.split('/');
    const projectId = urlParts[urlParts.indexOf('documents') + 1];

    this.projectId = parseInt(projectId) ? projectId : null;
  }
};
</script>
