<template>
  <div class="shadowed-box" id="firm-users-section">
    <div class="box-header text-uppercase">
      Firm Users
    </div>

    <div class="box-content">
      <loading-section name="firmUsers">
        <div class="row tight-columns top-10" v-if="firmUsers.length">
          <div class="col-sm-6 bottom-10" v-for="user in usersSortedByName" :key="user.id">
            <div class="clear-panel no-padding">
              <div class="right-10 left-10">
                <div class="row tight-columns top-10 bottom-10">
                  <div class="col-md-4">
                    <profile-photo :user="user" :small="true"></profile-photo>
                  </div>

                  <div class="col-md-8 top-10-xs-sm">
                    <div class="text-ellipsis">
                      <a :href="`/admin-app/lawyers/${user.id}`" target="_blank">{{ user.fullName }}</a>
                    </div>

                    <div class="dark-gray-text text-ellipsis">
                      {{ user.firmTitle || '&mdash;' }}
                    </div>
                  </div>
                </div>

                <div class="size-text-9px bottom-5" v-if="firmIsScout">
                  <span :class="['text-uppercase bold-weight right-10', { 'scout-purple-text': user.isScout, 'gray-text': !user.isScout }]">Scout</span>
                  <span :class="['text-uppercase bold-weight', { 'marketplace-blue-text': user.isMarketplace, 'gray-text': !user.isMarketplace }]">Marketplace</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="gray-text size-text-base top-10 bottom-10">
          No Users Added
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import { sortBy } from 'lodash';

export default {
  name: 'LawFirmUsers',

  components: {
    ProfilePhoto,
    LoadingSection
  },

  props: {
    firmUsers: {
      type: Array,
      required: true
    },

    firmIsScout: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    usersSortedByName() {
      return sortBy(this.firmUsers, ['lastName', 'firstName']);
    }
  }
};
</script>
