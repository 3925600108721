<template>
  <div class="d-flex wrap row-gap-8 col-gap-8">
    <editable-scout-tag
      v-for="scoutTag in orderedTags"
      :scout-tag="scoutTag"
      :key="scoutTag.id"
      :on-remove-tag="onRemoveTag">
    </editable-scout-tag>
  </div>
</template>

<script>
import EditableScoutTag from 'vue-app/scout/client/law-firms/editable-scout-tag.vue';
import { sortScoutTags } from 'resources/utils/tag-sorter.js';

export default {
  name: 'ScoutTagsList',

  components: {
    EditableScoutTag
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    },

    onRemoveTag: {
      type: Function,
      required: true
    }
  },

  computed: {
    orderedTags() {
      return sortScoutTags(this.scoutTags);
    }
  }
};
</script>
