import axios from 'axios';
import BaseResource from 'resources/base-resource';

class ScoutClient extends BaseResource {
  static baseUrl = '/scout/clients/:id.json';
  static urlTransformations = ['id'];

  static queryData(params) {
    return axios.get('/scout/clients/index_data.json', { params: params }).then(response => response.data);
  }
}

export default ScoutClient;
