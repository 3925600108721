<template>
  <div class="clear-panel">
    <div class="row tight-columns">
      <div class="col-sm-9">
        <div class="text-ellipsis">
          <span class="semibold-weight">{{ user.firstName }} {{ user.lastName }}</span>
          <span class="purple-text left-10">({{ isLead ? 'Lead' : 'Lawyer' }})</span>
        </div>

        <div class="text-ellipsis">
          {{ user.email }}
        </div>
      </div>

      <div class="col-sm-3 top-20-xs">
        <div class="semibold-weight">
          Sent:
        </div>

        <div>
          {{ createdAt }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'RequestInvitation',

  props: {
    invitation: {
      type: Object,
      required: true
    }
  },

  computed: {
    user() {
      return this.invitation.lawyer || this.invitation.lawyerLead;
    },

    isLead() {
      return !this.invitation.lawyer;
    },

    createdAt() {
      return moment(String(this.invitation.createdAt)).format('MM/DD/YYYY');
    }
  }
};
</script>
