<template>
  <div>
    <div class="semibold-weight yellow-5-text feature-lab-status bottom-30">
      Onboarding - Feature Lab
    </div>

    <div class="row tight-columns bottom-20">
      <div class="col-sm-6 semibold-weight size-text-13px">
        Terms of Service Recipients
      </div>

      <div class="col-sm-6 size-text-13px top-10-xs">
        <div v-if="workspace.termsOfService && workspace.termsOfService.recipients.length">
          <div v-for="(recipient, index) in workspace.termsOfService.recipients" :key="index">
            {{ recipient }}
          </div>
        </div>

        <div v-else>
          &mdash;
        </div>
      </div>
    </div>

    <div class="row tight-columns">
      <div class="col-sm-6 semibold-weight size-text-13px">
        Terms Selected
      </div>

      <div class="col-sm-6 size-text-13px top-10-xs">
        {{ formattedTerms || '&mdash;' }}
      </div>
    </div>

    <hr class="kenny-hr">

    <div class="bold-weight text-uppercase size-text-11px bottom-15">
      Terms of Service
    </div>

    <priori-modal
      :backdrop="false"
      modal-id="terms-of-service"
      @on-modal-close="reset"
      ref="termsOfServiceModal"
      size="md"
      title="Send Terms">
      <template #modal-trigger="{ openModal }">
        <button type="button" class="priori-btn semibold-weight size-text-11px" @click="openModal">Send Terms of Service</button>
      </template>

      <div class="text-italic size-text-12px pill-gray-text bottom-10">
        This Feature Lab workspace will not be accessible to users until one workspace user accepts the ToS.
      </div>

      <div class="text-italic size-text-12px pill-gray-text bottom-30">
        You may send the Feature Labs Terms of Service (ToS) to either an existing user or a new user. New users will be prompted to create an account and accept the ToS.  Existing users will only be prompted to accept the ToS.
      </div>

      <validation-observer v-slot="{ handleSubmit }">
        <form role="form" class="a-form" novalidate>
          <div class="bottom-20">
            <div class="bold-weight text-uppercase bottom-10">
              Add Existing User
            </div>

            <dropdown-select
              :allow-null="true"
              id-label="add-existing-user"
              :is-disabled="!!newUserEmail"
              label-key="nameEmail"
              :options="formattedExistingUserOptions"
              placeholder="Select User"
              ref="addExistingUser"
              v-model="selectedExistingUserId"
              value-key="id">
            </dropdown-select>
          </div>

          <div v-if="!workspace.ssoEnabled" class="bottom-20">
            <div class="bold-weight text-uppercase bottom-10">
              Invite New User
            </div>

            <text-input-vertical
              :disabled="!!selectedExistingUserId"
              placeholder="Enter email address"
              rules="email"
              type="email"
              v-model="newUserEmail">
            </text-input-vertical>
          </div>

          <div>
            <div class="bold-weight text-uppercase bottom-10">
              Select Terms
            </div>

            <label
              v-for="terms in termsOptions"
              class="check-option medium-large vertical"
              :key="terms.value">
              <input class="input" type="radio" v-model="selectedTerms" :value="terms.value">
              <span class="normal-weight">{{ terms.label }}</span>
              <span class="check c-radio"></span>
            </label>
          </div>

          <div v-if="error" class="error-text top-20">{{ error }}</div>

          <div class="row top-30">
            <div class="col-sm-4">
              <button
                type="button"
                class="primary-btn-night-blue a-button-size"
                @click.prevent="handleSubmit(submit)"
                :disabled="!canSubmit">
                Submit
              </button>
            </div>

            <div class="col-sm-4 top-20-xs">
              <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
            </div>
          </div>
        </form>
      </validation-observer>
    </priori-modal>
  </div>
</template>

<script>
import { find, sortBy } from 'lodash';
import { ValidationObserver } from 'vee-validate';

import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical';

export default {
  name: 'FeatureLabOnboarding',

  components: {
    DropdownSelect,
    PrioriModal,
    TextInputVertical,
    ValidationObserver
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    sendTermsOfService: {
      type: Function,
      required: true
    },

    error: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      termsOptions: [
        { label: 'Feature Lab Terms Only', value: 'feature_lab' },
        { label: 'Marketplace and Feature Lab Terms', value: 'marketplace_and_feature_lab' },
        { label: 'No Terms', value: 'no_terms' }
      ],
      newUserEmail: '',
      selectedExistingUserId: null,
      selectedTerms: null
    };
  },

  computed: {
    formattedExistingUserOptions() {
      const clients = this.workspace.clients;

      return sortBy(clients, ['email', 'firstName', 'lastName']).map(client => {
        return {
          id: client.id,
          nameEmail: `${client.email}, ${client.firstName} ${client.lastName}`
        };
      });
    },

    formattedTerms() {
      return find(this.termsOptions, ['value', this.workspace.termsOfService?.terms])?.label;
    },

    canSubmit() {
      return (this.selectedExistingUserId || this.newUserEmail) && this.selectedTerms;
    }
  },

  methods: {
    submit() {
      let isForExistingClient = false;
      let email = this.newUserEmail;

      if (this.selectedExistingUserId) {
        isForExistingClient = true;
        email = find(this.workspace.clients, ['id', this.selectedExistingUserId]).email;
      }

      let data = {
        termsOfService: {
          workspaceId: this.workspace.id,
          terms: this.selectedTerms
        },
        client: {
          isForExistingClient: isForExistingClient,
          email: email
        }
      };

      if (this.workspace.termsOfService) {
        data.id = this.workspace.termsOfService.id;
      }

      this.sendTermsOfService(data, this.closeModal);
    },

    closeModal() {
      this.$refs.termsOfServiceModal.closeModal();
    },

    reset() {
      this.newUserEmail = '';
      this.selectedExistingUserId = null;
      this.$refs.addExistingUser.reset();
      this.selectedTerms = null;
    }
  }
};
</script>
