<template>
  <div>
    <div class="pill-switcher-container bottom-10">
      <div v-for="(option, index) in options">
        <div
          :class="['option', cssClass(index, option)]"
          @click="select(option.value)">
          {{ option.label }}
        </div>
      </div>
    </div>

    <slot name="note"></slot>
  </div>
</template>

<script>
export default {
  name: 'PillSwitcher',

  props: {
    options: {
      type: Array,
      required: true
    },

    defaultOptionValue: {
      type:     String,
      default:  null
    },

    onSelect: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      previousOption: null,
      selectedOption: this.defaultOptionValue ? this.defaultOptionValue : this.options[0].value
    };
  },

  methods: {
    cssClass(index, option) {
      return  {
        'option-left': index === 0,
        'option-between': index > 0 && index < this.options.length - 1,
        'option-right': index === this.options.length - 1,
        'selected': this.isSelected(option.value)
      };
    },

    isSelected(option) {
      return this.selectedOption === option;
    },

    select(option, callback = true) {
      if (this.isSelected(option)) { return; }

      this.previousOption = this.selectedOption;
      this.selectedOption = option;

      if (callback) {
        this.onSelect(option);
      }
    },

    reset() {
      this.selectedOption = this.defaultOptionValue ? this.defaultOptionValue : this.options[0].value;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .pill-switcher-container {
    display: flex;
    justify-content: center;
    border-radius: 20px;
  }

  .option {
    color: $k-blue;
    font-size: 13px;
    font-weight: 700;
    padding: 8px 15px;
    text-align: center;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background: $k-blue;
      color: $white;
    }
  }

  .option-left {
    border-bottom: 1px solid $k-blue;
    border-left: 1px solid $k-blue;
    border-top: 1px solid $k-blue;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding-left: 20px;
  }

  .option-between {
    border-bottom: 1px solid $k-blue;
    border-left: 1px solid $k-blue;
    border-top: 1px solid $k-blue;
  }

  .option-right {
    border: 1px solid $k-blue;
    padding-right: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
</style>
