<template>
  <edit-modal ref="modal" title="Close RFP" :input="rfq" :backdrop="true" :keyboard="true" :on-save="closeRfq" :on-cancel="resetForm" :save-button-text="'Close RFP'">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">Close RFP</a>
    </template>

    <template #default="{ inputCopy: rfqCopy }">
      <div class="size-text-14px bottom-30">
        Are you sure you want to close this RFP?
      </div>

      <validation-provider rules="required" v-slot="{ errors }">
        <label :for="`close-reason-${rfq.id}`">
          Select reason for closing RFP <span class="inline-help required">*</span>
        </label>

        <dropdown-select
          ref="dropdown"
          :id-label="`close-reason-${rfq.id}`"
          placeholder="Select Reason"
          :options="closeReasons"
          v-model="rfqCopy.closingReason"
          :required="true">
        </dropdown-select>

        <div class="error-text top-5" v-if="errors.length">
          {{ errors[0] }}
        </div>
      </validation-provider>

      <div class="top-30" v-if="rfqCopy.closingReason === 'Other'">
        <text-input-vertical
          label="Input Reason"
          :id="`custom-closing-reason-${rfq.id}`"
          :name="`customClosingReason${rfq.id}`"
          :maxlength="25"
          v-model="rfqCopy.customClosingReason"
          rules="required">
        </text-input-vertical>
        <span class="normal-weight pill-gray-text size-text-12px">Limited to 25 characters</span>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import { ValidationProvider } from 'vee-validate';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';

export default {
  name: 'CloseRFQForm',

  components: {
    TextInputVertical,
    DropdownSelect,
    ValidationProvider,
    EditModal
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    closeRfq: {
      type: Function,
      required: true
    }
  },

  computed: {
    closeReasons() {
      return [
        'Complete',
        'On Hold',
        'Inactive Project',
        'Other'
      ];
    }
  },

  methods: {
    resetForm() {
      this.$refs.dropdown.reset();
    }
  }
};
</script>
