<template>
  <div :class="['quote-container', visualStatus]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-5">
        <div class="vertical-center-not-xs">
          <div class="relative-container inline-block right-10">
            <profile-photo v-if="quote.fromMarketplace && !quote.marketplaceLawyer.addedAsFirm" :user="quote.marketplaceLawyer" :small="true"></profile-photo>
            <svg-icon v-else name="law-firm" class="firm-icon small"></svg-icon>
          </div>

          <div class="size-text-15px semibold-weight text-ellipsis top-10-xs">
            <div>
              {{ quoteLabel }}
            </div>

            <div v-if="newMessageCount">
              <span class="icon-wrapper right-5">
                <svg-icon name="envelope" class="base-icon icon"></svg-icon>
              </span>

              <div class="inline-block pill-gray-text normal-weight size-text-12px">
                {{ $pluralize('New Message', newMessageCount, true) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-5 quote-status size-text-12px vertical-center col-gap-5 top-10-xs">
        <div class="updated" v-if="hasRecentUpdates"></div>
        {{ statusText }}
      </div>

      <div v-if="quoteReceived" class="col-sm-3 col-md-2 text-right-not-xs">
        <a href="" @click.prevent="viewQuote(quote)" class="bold-weight size-text-12px">View Quote</a>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'QuoteItem',

  components: {
    ProfilePhoto,
    SvgIcon
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    quote: {
      type: Object,
      required: true
    },

    viewQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    quoteLabel() {
      return (this.quote.fromMarketplace && !this.quote.marketplaceLawyer.addedAsFirm) ? this.quote.marketplaceLawyer.fullName : this.nonMarketplaceProviderName;
    },

    nonMarketplaceProviderName() {
      return this.quote.lawFirm?.name || this.quote.lawFirmBridge.name;
    },

    quoteReceived() {
      return ['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(this.quote.status);
    },

    quotePending() {
      return ['new_rfq', 'draft'].includes(this.quote.status);
    },

    quoteDeclined() {
      return ['declined_by_firm'].includes(this.quote.status);
    },

    statusText() {
      if (this.hasRecentUpdates) {
        return `Quote Updated ${DateFilter.filter(this.quote.lastUpdatedAt)}`;
      }
      else if (this.quoteReceived) {
        return `Quote Received ${DateFilter.filter(this.quote.dateResponded) || ''}`;
      }
      else if (this.quotePending) {
        return 'Pending Response';
      }
      else if (this.quoteDeclined) {
        return 'Declined';
      }
    },

    hasRecentUpdates() {
      return this.quoteReceived && this.quote.lastUpdatedAt;
    },

    visualStatus() {
      if (this.quoteReceived) {
        return this.quote.fromMarketplace ? 'received-marketplace' : 'received-scout';
      }

      if (this.quotePending) {
        return 'pending';
      }

      if (this.quoteDeclined) {
        return 'declined';
      }
    },

    newMessageCount() {
      return this.rfq.unreadMessageCountsPerQuote[this.quote.id];
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .quote-container {
    border: 1px solid $medium-gray;
    border-radius: $border-radius-base;
    padding: 10px 15px;

    &.received-scout {
      border-left: 3px solid $high-match-score-blue;
    }

    &.received-marketplace {
      border-left: 3px solid $k-purple;
    }

    &.pending {
      border-left: 3px solid $scout-gray;

      .quote-status {
        color: $pill-gray;
      }
    }

    &.declined {
      border-left: 3px solid $scout-red;

      .quote-status {
        color: $scout-red;
      }
    }
  }

  .updated {
    height: 6px;
    width: 6px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $p-yellow;
  }

  .icon-wrapper {
    position: relative;
    width: 22px;

    .base-icon {
      width: 18px;
      height: 18px;
      fill: $k-dark-gray;
    }

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 1px;
      right: -4px;
      background-color: $scout-red;
      border-radius: 50%;
    }
  }
</style>
