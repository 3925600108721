<template>
  <div>
    <div class="small-bold-header bottom-10">
      Recently Viewed
    </div>

    <ul class="row" v-if="hasViewedItems & listType === 'lawyer'">
      <li v-for="lawyer in items" :key="lawyer.id" class="col-sm-4 bottom-20">
        <a :href="'/scout-company/lawyers/' + lawyer.id"><lawyer-name-plate :lawyer="lawyer" :photo-size="'small'"></lawyer-name-plate></a>
      </li>
    </ul>

    <ul class="row" v-if="hasViewedItems & listType === 'lawFirm'">
      <li v-for="firm in items" :key="firm.id" class="col-sm-4 bottom-20">
        <a :href="'/scout-company/law-firms/' + firm.id"><firm-name-plate :firm="firm" icon-size="small" :italic="firm.isBillingFirm()"></firm-name-plate></a>
      </li>
    </ul>

    <div v-if="!hasViewedItems" class="gray-text size-text-16px">
      None recently viewed
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import FirmNamePlate from 'vue-app/scout/shared/firm-name-plate.vue';

export default {
  name: 'RecentlyViewed',

  components: {
    LawyerNamePlate,
    FirmNamePlate
  },

  props: {
    listType: {
      type: String,
      required: true
    },

    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    hasViewedItems: function () {
      return this.items.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  ul {
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;

    a {
      color: $k-darker-gray;
      text-decoration: none;
      background-color: $white;

      width: 100%;
      height: 100%;
      display: inline-block;
      padding: 10px 30px;
      border: 1px solid $k-light-gray;
      border-radius: $border-radius-base;

      &:hover {
        text-decoration: none;
        background-color: $blue-hover;
        border-color: $k-blue;
      }
    }
  }
</style>
