<template>
  <div :class="['name-plate photo-overflow-container', { 'compact': compact }]">
    <svg-icon v-if="showFavorite" name="star-filled" :class="['favorite-icon', { 'favorited': lawyer.isFavorite }]"></svg-icon>

    <div :class="['relative-container inline-block right-10-not-xs', { 'small-container': showSmallPhoto }]">
      <profile-photo :user="lawyer" :small="showSmallPhoto" :medium="showMediumPhoto"></profile-photo>
    </div>

    <div class="text-img-adjacent">
      <div class="name-text text-ellipsis">
        <slot name="lawyer-name-element">
          <a v-if="showProfileLink" target="_blank" :href="lawyerProfilePath">{{ lawyer.fullName }}</a>
          <span v-else class="target">{{ lawyer.fullName }}</span>
        </slot>
      </div>

      <div :class="['text-ellipsis', { 'text-italic': lawyer.isBillingLawyer() }]">
        <a :href="`/scout-company/law-firms/${lawyer.lawFirmBridgeId}`" v-if="lawyer.lawFirmBridgeId && showFirmLink">{{ titleAndFirm }}</a>
        <span v-else>{{ titleAndFirm }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

import RoutingService from 'services/shared/routing-service.js';

import useCurrentUserStore  from 'vue-app/stores/current-user.js';
import { compact } from 'lodash';
import { mapState } from 'pinia';
export default {
  name: 'LawyerNamePlate',

  components: {
    ProfilePhoto,
    SvgIcon
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    hideFirm: {
      type: Boolean,
      default: false
    },

    photoSize: {
      type: String,
      default: 'medium',
      validator: (value) => {
        return ['small', 'medium'].indexOf(value) !== -1;
      }
    },

    showFavorite: {
      type: Boolean,
      default: true
    },

    showFirmLink: {
      type: Boolean,
      default: false
    },

    showLawyerLink: {
      type: Boolean,
      default: false
    },

    compact: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    lawyerProfilePath() {
      let id = this.lawyer.id;
      if (this.currentUser.klass === 'Lawyer') { id = this.lawyer.lawyerId; }
      return `${RoutingService.appRoute(this.currentUser)}/lawyers/${id}`;
    },

    hasFirmName() {
      return this.lawyer.firmName && this.lawyer.firmName !== this.lawyer.fullName;
    },

    showFirm() {
      return this.hasFirmName && !this.hideFirm;
    },

    titleAndFirm() {
      let display = this.lawyer.firmTitle || '';

      if (this.showFirm) {
        display = compact([display, this.lawyer.firmName]).join(' at ');
      }

      return display;
    },

    showSmallPhoto() {
      return this.photoSize === 'small';
    },

    showMediumPhoto() {
      return this.photoSize === 'medium';
    },

    showProfileLink() {
      return this.showLawyerLink && this.lawyer.hasVisibleProfile && this.lawyer.lawyerId;
    }
  }
};
</script>

<style lang="scss" scoped>
  .name-text {
    font-size: 17px;
  }

  .name-plate.compact {
    min-height: auto;

    .name-text {
      font-size: 13px;
      font-weight: 600;
    }
  }
</style>
