<template>
  <form role="form" class="a-form" novalidate>
    <div class="box-header large-header">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-9">
          You are reviewing <span class="semibold-weight">{{ lawyer.fullName }}</span>
          <span v-if="signedInClient">
            <br>for your <span class="semibold-weight">{{ matter.nameForClientsReference }}</span> project.
          </span>
        </div>

        <div class="col-sm-3 text-right-not-xs">
          <profile-photo :user="lawyer"></profile-photo>
        </div>
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="semibold-weight size-text-24px">
        Lawyer Review
      </div>

      <div class="top-10 bottom-30">
        <hr class="kenny-hr no-margin">
      </div>

      <div class="bottom-20">
        <label :for="q2.slug">{{ q2.label }}</label>

        <textarea autofocus :name="q2.slug" :id="q2.slug" class="form-control" placeholder="Please describe..." rows="4" v-model="form[q2.slug]"></textarea>
      </div>

      <div class="bottom-20">
        <label :for="q1.slug">
          {{ q1.label }}
          <span class="inline-help required">*</span>
          <span class="inline-help pr-error">{{ errorsFor(q1.slug) }}</span>
        </label>

        <input type="text" :name="q1.slug" :id="q1.slug" :class="['form-control', { 'ng-invalid': hasErrorsFor(q1.slug) }]" placeholder="e.g. Contract Review; Trademark Application" v-model="form[q1.slug]">
      </div>

      <div class="bottom-20">
        <label>
          {{ q3.label }}
          <span class="inline-help required">*</span>
          <span class="inline-help pr-error">{{ errorsFor(q3.slug) }}</span>
        </label>

        <dropdown-select
          v-model="form[q3.slug]"
          :id-label="q3.slug"
          :options="q3.possibleValues"
          @change="recommendLawyerChanged">
        </dropdown-select>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <label :for="q5.slug">{{ q5.label }}</label>

          <input type="text" :name="q5.slug" :id="q5.slug" class="form-control" placeholder="Enter name" v-model="form[q5.slug]">
        </div>

        <div class="col-sm-6 top-20-xs">
          <label>
            {{ q4.label }}
            <span class="inline-help required">*</span>
            <span class="inline-help pr-error">{{ errorsFor(q4.slug) }}</span>
          </label>

          <dropdown-select
            v-model="form[q4.slug]"
            :id-label="q4.slug"
            :options="q4.possibleValues"
            @change="companySizeChanged">
          </dropdown-select>
        </div>
      </div>
    </div>

    <hr class="kenny-hr no-margin">

    <div v-if="signedInClient">
      <div class="box-content symmetrical">
        <div class="semibold-weight size-text-24px">
          Priori Review
        </div>

        <div class="top-10 bottom-30">
          <hr class="kenny-hr no-margin">
        </div>

        <div class="bottom-20">
          <label>
            {{ q6.label }}
            <span class="inline-help required">*</span>
            <span class="inline-help pr-error">{{ errorsFor(q6.slug) }}</span>
          </label>

          <dropdown-select
            v-model="form[q6.slug]"
            :id-label="q6.slug"
            :options="q6.possibleValues"
            @change="willUsePrioriChanged">
          </dropdown-select>
        </div>

        <div>
          <label :for="q7.slug" class="bottom-15">{{ q7.label }}</label>

          <textarea :name="q7.slug" :id="q7.slug" class="form-control" placeholder="Please explain..." rows="4" v-model="form[q7.slug]"></textarea>
        </div>
      </div>

      <hr class="kenny-hr no-margin">
    </div>

    <div v-if="!isAdmin">
      <div class="box-content symmetrical contrasting">
        <div class="normal-weight size-text-13px bottom-20">
          <span class="semibold-weight">NOTICE:</span> Your feedback will be displayed on your Priori lawyer&rsquo;s profile page for other prospective Priori clients to see. Your feedback may also be used by Priori in public-facing marketing or promotional materials. Your name will never be disclosed in such materials without your express consent - we only use the information entered on this form.
        </div>

        <div class="checkbox">
          <label>
            <input type="checkbox" name="optOut" id="opt-out" v-model="review.optOut">
            <span class="normal-weight">Please check this box if you do not want your feedback to be used by Priori in any public-facing marketing or promotional materials.</span>
          </label>
        </div>
      </div>

      <hr class="kenny-hr no-margin">
    </div>

    <div class="box-content symmetrical">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <loading-button name="submit" :lb-disabled="!formValid" lb-class="nv-button-purple-red a-button-size" @lb-click="reviewSubmitted">Submit Review</loading-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DropdownSelect from 'vue-app/shared/components//dropdown-select.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'ReviewLawyerForm',

  components: {
    DropdownSelect,
    LoadingButton,
    ProfilePhoto
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    matter: {
      type: Object,
      default: null
    },

    client: {
      type: Object,
      default: null
    },

    isAdmin: {
      type: Boolean,
      default: false
    },

    review: {
      type: Object,
      required: true
    },

    questions: {
      type: Array,
      required: true
    },

    signedOut: {
      type: Boolean,
      default: false
    },

    onReviewSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      form: this.review.form,
      recommendLawyer: null,
      companySize: null,
      willUsePriori: null,
      errors: {}
    };
  },

  computed: {
    signedInClient() {
      return !this.isAdmin && !this.signedOut;
    },

    typeOfWorkPresent() {
      return this.form[this.q1.slug]?.length > 0;
    },

    formValid() {
      return this.recommendLawyer &&
        this.companySize &&
        (this.willUsePriori || this.isAdmin || this.signedOut) &&
        this.typeOfWorkPresent;
    },

    q1() {
      return this.questions[0];
    },

    q2() {
      return this.questions[1];
    },

    q3() {
      return this.questions[2];
    },

    q4() {
      return this.questions[3];
    },

    q5() {
      return this.questions[4];
    },

    q6() {
      return this.questions[5];
    },

    q7() {
      return this.questions[6];
    }
  },

  mounted() {
    if (this.client) {
      this.$set(this.form, this.q5.slug, this.client.businessName);
      this.companySize = this.client.numEmployees;
    }
  },

  methods: {
    recommendLawyerChanged(choice) {
      this.recommendLawyer = choice;
      this.form[this.q3.slug] = choice;
    },

    companySizeChanged(choice) {
      this.companySize = choice;
      this.form[this.q4.slug] = choice;
    },

    willUsePrioriChanged(choice) {
      this.willUsePriori = choice;
      this.form[this.q6.slug] = choice;
    },

    hasErrorsFor(slug) {
      return !!this.errors[slug];
    },

    errorsFor(slug) {
      return this.hasErrorsFor(slug) ? this.errors[slug].join(', ') : '';
    },

    reviewSubmitted() {
      if (!this.formValid) { return; }

      this.errors = {};
      const vueInstance = this;

      this.onReviewSubmit(function (response) {
        vueInstance.errors = response.data.errors;
      });
    }
  }
};
</script>
