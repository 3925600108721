<template>
  <div :class="['request-item', { 'expanded': isExpanded }]">
    <div class="d-flex-not-xs col-gap-15">
      <button type="button" class="pseudo-link-blue expand-btn" @click="toggleDetails">
        <svg-icon class="base-icon" :name="isExpanded ? 'minimize' : 'expand'"></svg-icon>
      </button>

      <div class="flex-1 top-10-xs">
        <div class="row tight-columns d-flex wrap-only-xs h-100-not-xs vertical-center-not-xs">
          <div class="col-xs-12 col-sm-5">
            <div class="semibold-weight size-text-16px bottom-5">
              <div class="vertical-center">
                <a :href="`/admin-app/requests/${request.id}`" target="_blank">{{ request.nameForLawyersReference }}</a>

                <div v-if="request.scoutRfqId" class="scout-rfp-badge left-10">
                  Scout
                </div>
              </div>
            </div>

            <div class="bottom-5">
              Requested: <span class="semibold-weight">{{ requestedAt }}</span>

              <span class="left-5 right-5">&bullet;</span>

              Reference: <span class="semibold-weight request-id">#{{ request.id }}</span>
            </div>

            <div>
              <a :href="`/admin-app/workspaces/${request.clientWorkspaceId}`" target="_blank" v-if="request.clientWorkspaceId">{{ request.clientCompany }}</a>

              <span class="left-5 right-5" v-if="request.clientCompany">&bullet;</span>

              <a :href="`/admin-app/clients/${request.requester.id}`" target="_blank" v-if="request.requester.klass === 'Client'">{{ request.requester.fullName }}</a>
              <span v-else>{{ request.requester.fullName }}</span>

              <span class="wrap-word">({{ request.requester.email }})</span>
            </div>

            <div class="d-flex-not-xs col-gap-10 top-10">
              <div class="rfp-badge text-capitalize">
                <svg-icon v-if="request.requestType === 'custom'" name="gear" class="base-icon right-7"></svg-icon>
                {{ request.requestType }}
              </div>

              <div class="rfp-badge top-10-xs" v-if="hasPriority">
                <priority-icon :priority="request.priority" class="base-icon smaller no-shrink right-7"></priority-icon>
                {{ priorityBarText }}
              </div>

              <div class="rfp-badge top-10-xs" v-if="hasBusinessGoalType">
                {{ businessGoalName }}
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-4 h-100 badges-container">
            <div class="d-flex-not-xs col-gap-10">
              <div :class="['rfp-badge top-10', recentActivityStatusColor]" v-if="recentActivityStatusIsPresent">
                <div :class="['status-dot no-shrink right-7', recentActivityStatusColor]" v-if="showStatusDot"></div>
                <div>
                  {{ recentActivityStatusText }}
                </div>
              </div>

              <div class="rfp-badge red top-10" v-if="hasCriticalIssue">
                {{ criticalIssueFlagText }}
              </div>
            </div>
          </div>

          <div class="col-xs-8 col-sm-2 vertical-center nowrap flex-end-not-xs top-10-xs">
            <overlapping-admin-photos :admin-users="request.assignedTeamMembers" class="right-10"></overlapping-admin-photos>
          </div>

          <div class="col-xs-4 col-sm-1 vertical-center flex-end top-10-xs">
            <dropdown-menu>
              <li class="menu-item" role="menuitem">
                <a :href="`/admin-app/requests/${request.id}/proposal`" target="_blank">View Proposal</a>
              </li>

              <li class="menu-item" role="menuitem">
                <a :href="`/admin-app/requests/${request.id}`" target="_blank">View Request</a>
              </li>

              <li class="menu-item" role="menuitem">
                <a :href="`/admin-app/requests/${request.id}/notes`" target="_blank">View Notes</a>
              </li>

              <li class="menu-item" role="menuitem" v-if="request.opportunityId">
                <a :href="viewOnSalesforcePath" target="_blank">View on Salesforce</a>
              </li>

              <li class="menu-item" role="menuitem">
                <edit-request :request="request" :option-lists="optionLists" :on-save="onUpdate"></edit-request>
              </li>
            </dropdown-menu>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isExpanded">
      <div class="row top-15">
        <div class="col-xs-12 col-sm-4">
          <all-lawyers-graph :possible-lawyers="request.allPossibleLawyers"></all-lawyers-graph>
        </div>

        <div class="col-xs-12 col-sm-8 top-20-xs">
          <request-possible-lawyers
            title="Lawyers in Proposal"
            :open-by-default="addedPossibleLawyers.length > 0"
            :possible-lawyers="addedPossibleLawyers"
            :client-lawyer-interactions="request.clientLawyerInteractions"
            :expanded-by-parent="expandedByParent">
            <template #links>
              <a :href="`/admin-app/requests/${request.id}`">Edit Proposal</a>
            </template>
          </request-possible-lawyers>

          <request-possible-lawyers
            title="Others Considered"
            class="top-20"
            :possible-lawyers="otherConsideredPossibleLawyers"
            :client-lawyer-interactions="request.clientLawyerInteractions"
            :expanded-by-parent="expandedByParent">
          </request-possible-lawyers>

          <admin-comments-section
            class="top-20"
            resource-type="CounselRequest"
            :resource-id="request.id"
            :comments="request.adminComments"
            :expanded-by-parent="expandedByParent"
            :on-create-comment="createComment"
            :on-update-comment="updateComment"
            :on-delete-comment="deleteComment">
          </admin-comments-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AllLawyersGraph from 'vue-app/admin/requests/all-lawyers-graph.vue';
import RequestPossibleLawyers from 'vue-app/admin/requests/request-possible-lawyers.vue';
import EditRequest from 'vue-app/admin/requests/edit-request.vue';
import AdminCommentsSection from 'vue-app/admin/shared/admin-comments-section.vue';
import OverlappingAdminPhotos from 'vue-app/admin/shared/overlapping-admin-photos.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import PriorityIcon from 'vue-app/shared/components/svg-icons/priority-icon.vue';
import AdminComment from 'resources/admin/admin-comment.js';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import ConfigService from 'vue-app/shared/services/config-service.js';
import { map, find } from 'lodash';

export default {
  name: 'RequestListItem',

  components: {
    AdminCommentsSection,
    AllLawyersGraph,
    RequestPossibleLawyers,
    EditRequest,
    OverlappingAdminPhotos,
    SvgIcon,
    PriorityIcon
  },

  props: {
    request: {
      type: Object,
      required: true
    },

    expandedByParent: {
      type: Boolean,
      default: false
    },

    optionLists: {
      type: Object,
      required: true
    },

    onUpdate: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      controlledLocally: true,
      expandedLocally: this.expandedByParent
    };
  },

  computed: {
    requestedAt() {
      return DateFilter.shortDate(this.request.createdAt);
    },

    addedPossibleLawyers() {
      const possibleLawyerIds = map(this.request.possibleLawyers, 'id');
      return this.request.allPossibleLawyers.filter(pl => possibleLawyerIds.includes(pl.id));
    },

    otherConsideredPossibleLawyers() {
      const addedIds = this.addedPossibleLawyers.map(pl => pl.id);
      return this.request.allPossibleLawyers.filter(pl => !addedIds.includes(pl.id));
    },

    viewOnSalesforcePath() {
      return `${ConfigService.saleforceBaseURL}/lightning/r/Opportunity/${this.request.opportunityId}/view`;
    },

    hasPriority() {
      return this.request.priority !== null;
    },

    priorityName() {
      return find(this.optionLists.priorities, { value: this.request.priority }).label;
    },

    priorityBarText() {
      return this.priorityName === 'Dormant' ? this.priorityName : `${this.priorityName} Priority`;
    },

    hasBusinessGoalType() {
      return this.request.businessGoalType !== null;
    },

    businessGoalName() {
      return find(this.optionLists.goalTypes, { value: this.request.businessGoalType }).label;
    },

    hasCriticalIssue() {
      return this.request.criticalIssueFlag;
    },

    criticalIssueFlagText() {
      const flagCode = this.request.criticalIssueFlag.toLowerCase();
      const codeMapping = {
        'due': 'Due',
        'past_due': 'Past Due',
        'no_client_action': 'No Client Action'
      };
      return codeMapping[flagCode];
    },

    hasOpenedMatter() {
      return this.request.status === 'matter_opened';
    },

    activityStatus() {
      if (this.request.closeType) { return 'closed'; }
      if (this.hasOpenedMatter) { return 'matter_opened'; }

      return this.request.recentActivityStatus?.toLowerCase();
    },

    recentActivityStatusText() {
      const statusMapping = {
        'proposal_sent': 'Proposal Sent',
        'proposal_needed': 'Proposal Needed',
        'invites_sent': 'Invites Sent',
        'lawyers_tracked': 'Lawyers Tracked',
        'rfp_posted': 'RFP Posted',
        'closed': 'Closed',
        'matter_opened': 'Matter Opened'
      };

      return statusMapping[this.activityStatus];
    },

    recentActivityStatusColor() {
      const statusColorMapping = {
        'proposal_sent': 'green',
        'proposal_needed': 'red',
        'invites_sent': 'green',
        'lawyers_tracked': 'green',
        'rfp_posted': 'green',
        'closed': 'gray',
        'matter_opened': 'gray'
      };

      return statusColorMapping[this.activityStatus];
    },

    showStatusDot() {
      if (this.hasOpenedMatter) { return false; }

      return !['proposal_needed', 'closed'].includes(this.activityStatus);
    },

    recentActivityStatusIsPresent() {
      if (this.request.closeType || this.hasOpenedMatter) { return true; }

      return (typeof this.request.recentActivityStatus !== 'undefined' && this.request.recentActivityStatus !== null);
    },

    isExpanded() {
      return this.controlledLocally ? this.expandedLocally : this.expandedByParent;
    }
  },

  watch: {
    expandedByParent() {
      this.expandedLocally = this.expandedByParent;
      this.controlledLocally = false;
    }
  },

  methods: {
    toggleDetails() {
      this.expandedLocally = !this.expandedLocally;
      this.controlledLocally = true;
    },

    createComment(text) {
      const adminComment = {
        resourceType: 'CounselRequest',
        resourceId: this.request.id,
        body: text
      };

      return AdminComment.save({ adminComment: adminComment }).then((response) => {
        this.request.adminComments.unshift(response);
        this.isAdding = false;
        this.newComment = '';
      });
    },

    updateComment(comment) {
      const adminComment = {
        resourceType: comment.resourceType,
        resourceId: comment.resourceId,
        body: comment.body
      };

      return AdminComment.update({ id: comment.id, adminComment: adminComment }).then((updated) => {
        const index = this.request.adminComments.findIndex(c => c.id === updated.id);
        this.$set(this.request.adminComments, index, updated);
      });
    },

    deleteComment(comment) {
      return AdminComment.delete({ id: comment.id }).then(() => {
        this.request.adminComments.splice(this.request.adminComments.indexOf(comment), 1);
      });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .request-item {
    background-color: $white;
    border: 1px solid $k-gray;
    column-gap: 5px;
    padding: 20px 30px;

    &.expanded {
      border-left: 3px solid $k-blue;
      padding-left: 28px;
    }

    &:first-child {
      border-top-left-radius: $border-radius-large;
      border-top-right-radius: $border-radius-large;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius-large;
      border-bottom-right-radius: $border-radius-large;
    }

    &:not(:last-child) {
      border-bottom: none;
    }
  }

  .expand-btn, .expand-btn .base-icon {
    width: 20px;
    height: 20px;
  }

  .badges-container {
    @media (min-width: 768px) {
      padding-top: 15px;
      padding-bottom: 25px;
    }
  }

  .d-flex {
    &.wrap-only-xs {
      flex-wrap: wrap;

      @media (min-width: 768px) {
        flex-wrap: nowrap;

      }
    }
  }

  .rfp-badge {
    display: flex;
    min-height: 26px;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-small;
    align-items: center;
    padding: 3px 6px;
    font-size: 12px;
    line-height: 14px;

    &.gray {
      border-color: $medium-gray;
      color: $pill-gray;
    }

    &.green {
      border-color: $k-green;
    }

    &.red {
      border-color: $k-red;
      color: $k-red;
    }

    &.blue {
      border-color: $high-match-score-blue;
      background-color: $high-match-score-blue;
      color: $white;
    }
  }

  .scout-rfp-badge {
    display: block;
    height: 22px;
    border: 1px solid $high-match-score-blue;
    border-radius: $border-radius-small;
    align-items: center;
    padding: 3px 6px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 700;
    background-color: $high-match-score-blue;
    color: $white;
  }

  .status-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;

    &.green {
      background: $k-green;
    }
  }

  .no-shrink {
    flex-shrink: 0;
  }
</style>
