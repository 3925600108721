<template>
  <table id="apts" class="table fixed-table auto-height no-margin">
    <thead>
      <th>Request</th>
      <th>Client</th>
      <th>Lawyer</th>
      <th>Status</th>
      <th>Starts At</th>
      <th>Type</th>
      <th></th>
    </thead>

    <tbody>
      <appointments-table-row :appointment="appointment" :on-open-call-modal="onOpenCallModal" v-for="appointment in appointments" :key="`appt-${appointment.id}`"></appointments-table-row>
    </tbody>
  </table>
</template>

<script>
import AppointmentsTableRow from './appointments-table-row.vue';

export default {
  name: 'AdminAppointmentsTable',

  components: {
    AppointmentsTableRow
  },

  props: {
    appointments: {
      type: Array,
      required: true
    },

    onOpenCallModal: {
      type: Function,
      required: true
    }
  }
};
</script>
