<template>
  <feature-lab-onboarding-configuration
    v-if="workspace.isOnboardingFeatureLab"
    :error="error"
    :send-terms-of-service="sendTermsOfService"
    :workspace="workspace">
  </feature-lab-onboarding-configuration>
  <feature-lab-configuration
    v-else
    :permitted-to-configure="permittedToConfigure"
    :workspace="workspace">
  </feature-lab-configuration>
</template>

<script>
import FeatureLabOnboardingConfiguration from 'vue-app/admin/workspaces/feature-lab-onboarding-configuration.vue';
import FeatureLabConfiguration from 'vue-app/admin/workspaces/feature-lab-configuration.vue';

export default {
  name: 'FeatureLabStatus',

  components: {
    FeatureLabConfiguration,
    FeatureLabOnboardingConfiguration
  },

  props: {
    workspace: {
      type: Object,
      required: true
    },

    permittedToConfigure: {
      type: Boolean,
      default: false
    },

    sendTermsOfService: {
      type: Function,
      required: true
    },

    error: {
      type: String,
      default: null
    }
  }
};
</script>
