<template>
  <div v-if="modalOpen">
    <modal v-model="modalOpen" :size="size" :transition="0" :header="false" :footer="false" append-to-body>
      <div class="close-modal full-screen" data-toggle="modal" :data-target="modalId" @click="dismissModal()">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-modal">
        <div class="notice-header">
          <slot name="title">
            {{ title }}
          </slot>
        </div>

        <div class="notice-regular-text">
          <slot>
            {{ message }}
          </slot>

          <div class="buttons-container">
            <button type="button" class="primary-button-compact" @click.prevent="confirm">{{ yesBtnText }}</button>
            <button type="button" class="secondary-button-compact" @click.prevent="dismissModal">{{ noBtnText }}</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modalToggle from '../mixins/modal-toggle';

export default {
  name: 'ConfirmationModalSecondary',

  mixins: [
    modalToggle
  ],

  props: {
    title: {
      type: String,
      default: 'Confirm'
    },

    message: {
      type: String,
      default: 'Are you sure?'
    },

    onConfirmAction: {
      type: Function,
      required: true
    },

    size: {
      type: String,
      default: 'md-sm'
    },

    yesBtnText: {
      type: String,
      default: 'Yes'
    },

    noBtnText: {
      type: String,
      default: 'No'
    }
  },

  computed: {
    modalId: function () {
      return _.uniqueId('confirmation-modal-');
    }
  },

  methods: {
    confirm: function () {
      this.onConfirmAction();
      this.closeModal();
    }
  }
};
</script>

<style scoped lang="scss">
  .buttons-container {
    column-gap: 30px;
    row-gap: 20px;
    display: flex;
    flex-wrap: wrap;
  }
</style>
