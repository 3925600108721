<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div class="col-md-9 dash-project-container" @click="viewApplication">
      <div class="dash-project-name text-ellipsis">
        {{ opportunity.postTitle }}
        <span v-if="opportunity.location">({{ opportunity.location }})</span>
      </div>

      <div class="text-ellipsis">
        Status:
        <span class="semibold-weight">{{ opportunity.displayStatus || '&mdash;' }}</span>
      </div>
    </div>

    <div class="col-sm-6 col-md-3 top-20-xs">
      <a :href="detailsPath" class="nv-button-white smaller">View Details</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpportunityApplication',

  props: {
    opportunity: {
      type: Object,
      required: true
    }
  },

  computed: {
    detailsPath() {
      return `/lawyer-app/opportunity-applications/${this.opportunity.counselRequestId}`;
    }
  },

  methods: {
    viewApplication() {
      window.location.href = this.detailsPath;
    }
  }
};
</script>
