<template>
  <form role="form" name="form" class="a-form" novalidate>
    <div class="text-right bottom-15 size-text-13px">
      <button
        class="pseudo-link-gray"
        @click.prevent="clearFilter">
        Clear Filter
      </button>
    </div>

    <div v-if="showSearch" class="keyword-search-container">
      <input
        type="text"
        name="searchTerm"
        id="search-term"
        class="form-control keyword-search-input"
        placeholder="Search..."
        autocomplete="off"
        v-model="searchTerm"
        :disabled="disabled">

      <button
        v-if="searchTerm"
        class="keyword-search-icon clear-icon pseudo-link-gray"
        @click.stop="clearSearchTerm">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </button>

      <svg-icon
        v-else
        name="search"
        class="base-icon keyword-search-icon">
      </svg-icon>

      <div v-if="disabled" class="missing-text top-10">
        * Filter not available
      </div>

      <div v-if="noMatches" class="error-text top-10">
        * No matching options
      </div>
    </div>

    <div :class="{ 'top-15': showOptions, 'fade-scroll-container': isScrollable, 'scrolled-up': isScrolledUp, 'scrolled-down': isScrolledDown }" v-show="showOptions">
      <div ref="scrollable" @scroll="checkScroll" class="fade-scrollable">
        <loading-section name="filterOptions">
          <div v-if="searchTerm && searchTerm.length">
            <label
              v-if="option.label.toLowerCase().includes(searchTerm.toLowerCase())"
              v-for="(option, index) in filteredOptions"
              :key="option.slug"
              :for="`${field}-filtered-${index}`"
              class="check-option vertical medium-large">
              <span class="highlighted" v-html="highlight(option.label)"></span>

              <input
                type="checkbox"
                :name="`filtered${index}`"
                :id="`${field}-filtered-${index}`"
                :checked="isSelected(option)"
                @change="onChange(option)">

              <span class="check c-box"></span>
            </label>
          </div>

          <div v-else>
            <div v-if="selected.length" class="check-options">
              <label
                class="check-option vertical medium-large"
                :for="`${field}-selected-${index}`"
                v-for="(option, index) in selected"
                :key="option.slug">
                {{ option.label }}

                <input
                  type="checkbox"
                  :name="`selected${index}`"
                  :id="`${field}-selected-${index}`"
                  @change="onChange(option)"
                  checked>

                <span class="check c-box"></span>
              </label>
            </div>

            <div class="top-15" v-if="selected.length && available.length">
              <hr class="no-margin">
            </div>

            <div v-if="available.length" :class="{ 'top-15': selected.length }">
              <label
                class="check-option vertical medium-large"
                :for="`${field}-available-${index}`"
                v-for="(option, index) in available"
                :key="option.slug">
                {{ option.label }}

                <input
                  type="checkbox"
                  :name="`available${index}`"
                  :id="`${field}-available-${index}`"
                  :checked="isSelected(option)"
                  @change="onChange(option)">

                <span class="check c-box"></span>
              </label>
            </div>
          </div>
        </loading-section>
      </div>
    </div>
  </form>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import checkScroll from 'vue-app/shared/mixins/check-scroll.js';
import { difference, zip } from 'lodash';

export default {
  name: 'FilterMultiselect',

  components: {
    SvgIcon,
    LoadingSection
  },

  mixins: [checkScroll],

  props: {
    searchService: {
      type: Object,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    field: {
      type: String,
      required: true
    },

    showSearch: {
      type: Boolean,
      default: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      dropdownElement: null,
      dropdownOpen: false,
      searchTerm: '',
      optionsLoaded: false
    };
  },

  computed: {
    showOptions() {
      return !this.disabled && this.filteredOptions.length;
    },

    disabled() {
      return !this.options.length;
    },

    selected() {
      return this.options.filter(option => this.currentSelection?.includes(option.slug));
    },

    currentSelection() {
      return this.searchService.searchLogic.filters[this.field];
    },

    available() {
      return difference(this.options, this.selected);
    },

    filteredOptions() {
      return this.options.filter(option => option.slug.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },

    noMatches() {
      return this.searchTerm && !this.filteredOptions.length;
    }
  },

  methods: {
    onChange(option) {
      if (this.isSelected(option)) {
        this.searchService.removeSearchParam(this.field, option.slug);
      }
      else {
        this.searchService.addSearchParam(this.field, option.slug);
      }

      this.onSearch();
    },

    highlight(text) {
      const sectionsInbetween = text.split(new RegExp(this.searchTerm, 'i'));
      const highlights = text.match(new RegExp(this.searchTerm, 'gi')).map(highlight => `<strong>${highlight}</strong>`);
      const zipped = zip(sectionsInbetween, highlights);

      return zipped.flat().join('');
    },

    clearSearchTerm() {
      this.searchTerm = '';
    },

    clearFilter() {
      this.clearSearchTerm();
      this.searchService.unsetSearchParam(this.field);
      this.onSearch();
    },

    isSelected(option) {
      return this.currentSelection?.includes(option.slug);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .fade-scroll-container {
    .fade-scrollable {
      max-height: 170px;
    }
  }

  .highlighted {
    :deep {
      strong {
        color: $k-purple;
      }
    }
  }

  :deep(.check-option) {
    font-weight: 400;

    input:checked + .check.c-box {
      border-color: $purple-5;
      background-color: $purple-5;
    }
  }
</style>
