<template>
  <div class="list-item matter-row no-hover">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="clickable col-sm-3 top-10-xs">
        <a :href="resourcePath">
          <lawyer-name-plate :lawyer="reviewResource" photo-size="small" v-if="isLawyerReview"></lawyer-name-plate>

          <firm-name-plate :firm="reviewResource" icon-size="small" :italic="reviewResource.isBillingFirm()" v-else>
            <div>{{ reviewResource.firmSize }}</div>
          </firm-name-plate>
        </a>
      </div>

      <div class="col-sm-3 top-10-xs">
        <div v-if="isMatterReview">
          <div class="semibold-weight text-ellipsis">
            <a :href="`/scout-company/matters/${review.matter.id}`" target="_blank">{{ matterOrProjectName }}</a>
          </div>

          <div class="pill-gray-text text-italic size-text-12px text-ellipsis" v-if="matterCode">
            #{{ matterCode }}
          </div>
        </div>

        <div class="semibold-weight text-ellipsis" v-else>
          General Review
        </div>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="size-text-12px semibold-weight">
          Score
        </div>

        <div>
          {{ displayScore }}
        </div>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="size-text-12px semibold-weight">
          Last Update
        </div>

        <div>
          {{ updatedAt }}
        </div>
      </div>

      <div class="col-sm-2 top-10-xs text-right-not-xs">
        <a :href="`/scout-company/reviews/${review.id}/edit`" class="semibold-weight" target="_self">Continue Review</a>
      </div>
    </div>
  </div>
</template>

<script>
import FirmNamePlate from 'vue-app/scout/shared/firm-name-plate';
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge';

export default {
  name: 'DraftReviewListItem',

  components: {
    FirmNamePlate,
    LawyerNamePlate
  },

  props: {
    review: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      maxScore: 10
    };
  },

  computed: {
    score() {
      return this.review.recommendation;
    },

    displayScore() {
      return this.score ? `${this.score}/10` : 'N/A';
    },

    isLawyerReview() {
      return this.review.resourceType === 'Scout::LawyerBridge';
    },

    reviewResource() {
      if (this.isLawyerReview) {
        return new ScoutLawyerBridge(this.review.resource);
      }
      else {
        return new ScoutFirmBridge(this.review.resource);
      }
    },

    isMatterReview() {
      return this.review.scoutMatterId;
    },

    matterOrProjectName() {
      return this.review.matter?.name;
    },

    matterCode() {
      return this.review.matter?.code;
    },

    updatedAt() {
      return DateFilter.filter(this.review.updatedAt);
    },

    resourcePath() {
      if (this.isLawyerReview) {
        return `/scout-company/lawyers/${this.review.resource.id}`;
      }
      else {
        return `/scout-company/law-firms/${this.review.resource.id}`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.clickable {
  a {
    text-decoration: none;
    color: inherit;
  }
}
</style>
