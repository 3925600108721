<template>
  <div>
    <div class="editable-text-with-icon" v-if="!isEditing && share.note.length">
      <div class="editable-text">
        {{ share.note }}
      </div>

      <div class="edit-icon" v-if="isShareEditable" @click="startEditNote()">
        <svg-icon name="pencil" class="base-icon text-top" :id="`edit-share-${share.id}`"></svg-icon>
      </div>
    </div>

    <div class="comment-editor top-15" v-if="isEditing">
      <validation-observer v-slot="{ handleSubmit }">
        <div class="bottom-10">
          <textarea-vertical
            v-model="share.note"
            label="Note"
            :id="`edit-share-note-${share.id}`"
            :name="`editShareNote${share.id}`"
            :rows="4"
            rules="required">
          </textarea-vertical>
        </div>

        <div class="row no-gutter size-text-12px">
          <div class="col-xs-7 col-md-8 col-xs-push-5 col-md-push-4 text-right">
            <a href="" class="bold-link" :id="`cancel-note-${share.id}`" @click.prevent="cancelEditNote">Cancel</a>

            <a href="" class="bold-link left-20" :id="`save-note-${share.id}`" @click.prevent="handleSubmit(saveNote)">Save</a>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import TextareaVertical from 'vue-app/shared/components/textarea-input-vertical.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'ListSharingEditorNote',

  components: {
    ValidationObserver,
    TextareaVertical
  },

  props: {
    share: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isEditing: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    isShareEditable() {
      return this.share.sharerId === this.currentUser.id &&
        this.share.sharerType === this.currentUser.klass;
    }
  },

  methods: {
    startEditNote() {
      this.share.noteCopy = this.share.note;
      this.isEditing = true;
    },

    cancelEditNote() {
      this.share.note = this.share.noteCopy;
      this.isEditing = false;
    },

    saveNote() {
      this.isEditing = false;
    }
  }
};
</script>
