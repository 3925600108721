<template>
  <div class="gray-panel" v-if="hasSuggestedLawyers">
    <div class="row tight-columns">
      <div class="col-sm-7 size-text-15px bold-weight">
        Included in the Quote
      </div>

      <div class="col-sm-5 bold-weight text-right-not-xs">
        Expected hourly rate
      </div>
    </div>

    <div class="top-5 bottom-10">
      <hr class="no-margin">
    </div>

    <div v-if="hasIncludedLawyers">
      <div class="list-spacing-10" v-for="option in includedLawyersOrdered" :key="option.id">
        <suggested-lawyer :suggested-lawyer="option" :viewing-as-client="viewingAsClient"></suggested-lawyer>

        <div class="top-10">
          <hr class="no-margin">
        </div>
      </div>
    </div>

    <div class="missing-text" v-else>
      No lawyers included
    </div>

    <div class="top-30" v-if="hasExcludedLawyers">
      <div class="size-text-15px bold-weight">
        Not Included in the Quote
      </div>

      <div class="top-5 bottom-10">
        <hr class="no-margin">
      </div>

      <div class="list-spacing-10" v-for="option in excludedLawyersOrdered" :key="option.id">
        <suggested-lawyer :suggested-lawyer="option" :viewing-as-client="viewingAsClient"></suggested-lawyer>

        <div class="top-10">
          <hr class="no-margin">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuggestedLawyer from './suggested-lawyer.vue';
import { orderBy } from 'lodash';

export default {
  name: 'SuggestedLawyers',

  components: {
    SuggestedLawyer
  },

  props: {
    suggestedLawyers: {
      type: Array,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasSuggestedLawyers() {
      return this.suggestedLawyers.length > 0;
    },

    includedLawyers() {
      return this.suggestedLawyers.filter(l => l.includedInQuote);
    },

    hasIncludedLawyers() {
      return this.includedLawyers.length > 0;
    },

    includedLawyersOrdered() {
      return orderBy(this.includedLawyers, [
        'scoutLawyerBridge.lastName', 'scoutLawyerBridge.firstName', 'scoutLawyerBridge.fullName',
        'lawyer.lastName', 'lawyer.firstName', 'lawyer.fullName',
        'lawyerName'
      ]);
    },

    excludedLawyers() {
      return this.suggestedLawyers.filter(l => !l.includedInQuote);
    },

    hasExcludedLawyers() {
      return this.excludedLawyers.length > 0;
    },

    excludedLawyersOrdered() {
      return orderBy(this.excludedLawyers, [
        'scoutLawyerBridge.lastName', 'scoutLawyerBridge.firstName', 'scoutLawyerBridge.fullName',
        'lawyer.lastName', 'lawyer.firstName', 'lawyer.fullName',
        'lawyerName'
      ]);
    }
  }
};
</script>
