<template>
  <div class="grid-row client-list-item">
    <div class="gd client-id">
      <a :href="`/admin-app/clients/${client.id}`" target="_blank">{{ client.id }}</a>
    </div>

    <div class="gd">
      <a :href="`/admin-app/clients/${client.id}`" target="_blank">{{ client.lastName }}, {{ client.firstName }}</a>
    </div>

    <div class="gd">
      <span class="simple-text">{{ client.email }}</span>
    </div>

    <div class="gd">
      {{ client.phoneNumber || '&mdash;' }}
    </div>

    <div class="gd">
      {{ client.contactId || '&mdash;' }}
    </div>

    <div class="gd">
      {{ client.leadId || '&mdash;' }}
    </div>

    <div class="gd">
      <a :href="`/admin-app/workspaces/${client.workspace.id}`" target="_blank">{{ client.workspace.name || '&mdash;' }}</a>
    </div>

    <div class="gd">
      <select :name="`select-${client.id}`" :id="`select-${client.id}`" v-model="selectedOption" @change="selectAction">
        <option value="null"></option>

        <option value="viewClient" :name="`viewClient-${client.id}`" :id="`viewClient-${client.id}`">
          View Client
        </option>

        <option value="messageClient" :name="`messageClient-${client.id}`" :id="`messageClient-${client.id}`">
          Message Client
        </option>

        <option value="impersonateClient" :name="`impersonateClient-${client.id}`" :id="`impersonateClient-${client.id}`">
          Impersonate Client
        </option>
      </select>

      <impersonate-modal
        :user="client"
        :target="'_self'"
        :modal-trigger="impersonateClient"
        :on-cancel="cancelImpersonation">
      </impersonate-modal>
    </div>
  </div>
</template>

<script>
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';

export default {
  name: 'ClientListItem',

  components: {
    ImpersonateModal
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    sendMessageService: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedOption: null,
      impersonateClient: false
    };
  },

  methods: {
    selectAction() {
      switch (this.selectedOption) {
        case 'viewClient':
          this.viewClient();
          break;
        case 'messageClient':
          this.sendMessage();
          break;
        case 'impersonateClient':
          this.impersonateClient = true;
          break;
      }

      this.selectedOption = null;
    },

    viewClient() {
      window.open(`/admin-app/clients/${this.client.id}`, '_blank');
    },

    sendMessage() {
      this.sendMessageService.modal([this.client]);
    },

    cancelImpersonation() {
      this.impersonateClient = false;
    }
  }
};
</script>
