<template>
  <div id="law-firm-titlebar" class="titlebar-with-sections split-60-40">
    <div class="titlebar">
      <div class="vertical-center">
        <div class="size-text-24px text-ellipsis">
          {{ lawFirm.name }}

          <div class="dark-gray-text size-text-13px">
            {{ lawFirm.alspAccount ? 'Law/Tech Company' : 'Law Firm' }}
          </div>
        </div>
      </div>
    </div>

    <div class="titlebar-switcher text-center">
      <a href="" :class="{ 'selected': isSelected(tabKey) }" @click.prevent="selectTab(tabKey)" v-for="tabKey in tabs" :key="`tab-${tabKey}`">{{ tabLabels[tabKey] }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LawFirmTitlebar',

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    tabs: {
      type: Array,
      required: true
    },

    initialTab: {
      type: String,
      required: true
    },

    onTabChanged: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      tab: this.initialTab,
      tabLabels: {
        'profile': 'Profile',
        'notes': 'Notes',
        'history': 'History'
      }
    };
  },

  methods: {
    isSelected: function (tab) {
      return this.tab === tab;
    },

    selectTab: function (tab) {
      if (this.isSelected(tab)) { return; }

      this.tab = tab;
      this.onTabChanged(tab);
    }
  }
};
</script>
