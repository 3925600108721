<template>
  <div>
    <div v-if="hasActions" class="titlebar with-button">
      <div class="row vertical-center-not-xs">
        <div class="col-sm-6 medium-page-heading">
          {{ title }}
        </div>

        <div class="col-sm-6 text-right-not-xs">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>

    <div v-else class="titlebar">
      <div class="medium-page-heading">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoutTitlebar',

  props: {
    title: {
      type: String,
      required: true
    }
  },

  computed: {
    hasActions() {
      return !!this.$slots.actions;
    }
  }
};
</script>
