import Message from 'resources/marketplace/message.js';
import Conversation from 'resources/marketplace/conversation.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';

class MessagingService {
  constructor() {
    this.rfq = null;
    this.quote = null;
    this.conversationId = null;
    this.selectedConversation = null;
    this.isVisible = false;
  }

  loadConversation() {
    return Conversation.get({ id: this.conversationId }).then((conversation) => {
      this.selectedConversation = conversation;
      return conversation;
    });
  }

  syncConversation() {
    return Conversation.syncRfq({ id: this.conversationId, quoteId: this.quote.id }).then((conversation) => {
      this.selectedConversation = conversation;
      return conversation;
    });
  }

  openMessaging(rfq, quote) {
    if (this.isVisible) { return; }

    const messagingCenter = document.getElementById('messaging-center');
    messagingCenter.classList.remove('off');
    messagingCenter.classList.add('on');

    quote.unreadMessageCount = 0;
    if (rfq.unreadMessageCountsPerQuote) {
      rfq.unreadMessageCountsPerQuote[quote.id] = 0;
    }

    this.rfq = rfq;
    this.quote = quote;
    this.conversationId = quote.conversationId;
    this.isVisible = true;

    LoadingService.around('selectedConversation', () => {
      return this.syncConversation();
    });
  }

  closeMessaging() {
    if (!this.isVisible) { return; }

    const messagingCenter = document.getElementById('messaging-center');
    messagingCenter.classList.remove('on');
    messagingCenter.classList.add('off');

    this.isVisible = false;
    this.rfq = null;
    this.quote = null;
    this.conversationId = null;
    this.selectedConversation = null;
  }

  refresh() {
    if (!this.conversationId) { return Promise.resolve(); }

    LoadingService.loading('receipts');

    const createOrUpdateMessage = this.createOrUpdateMessage();
    const loadConversation = this.loadConversation();

    return Promise.all([createOrUpdateMessage, loadConversation]).finally(() => {
      LoadingService.done('receipts');
    });
  }

  createOrUpdateMessage(sending = false) {
    if (sending) { this.selectedConversation.draftMessage.draft = false; }

    const params = {
      id: this.selectedConversation.draftMessage.id,
      conversationId: this.selectedConversation.id,
      recipients: this.selectedConversation.accessibleParticipantPostIds(),
      message: this.selectedConversation.draftMessage
    };

    return Message.save(params).then((receipt) => {
      if (this.selectedConversation && this.selectedConversation.draftMessage.id && receipt.message.id !== this.selectedConversation.draftMessage.id) {
        this.loadConversation();
      }
      else if (this.selectedConversation) {
        this.selectedConversation.setMostRecentReceipt(receipt);

        if (receipt.message.draft) {
          this.selectedConversation.draftSubject = receipt.message.body.substr(0, 256);
        }
        else {
          this.selectedConversation.subject = receipt.message.body.substr(0, 256);
        }
      }
    });
  }
}

export default new MessagingService();
