<template>
  <div class="shadowed-box size-text-13px">
    <div class="box-header text-uppercase">
      Lawyers
    </div>

    <div class="box-content">
      <div class="row">
        <div class="col-sm-4 semibold-weight">
          <a :href="lawyer.path">{{ lawyer.fullName }}</a>
        </div>

        <div class="col-sm-8 top-10-xs">
          <impersonate-modal :user="lawyer" :target="'_self'">
            <a href="">Impersonate</a>
          </impersonate-modal>
        </div>
      </div>

      <hr v-if="hasLawyers" class="kenny-hr">

      <div v-for="(matterLawyer, index) in lawyers" :key="matterLawyer.id">
        <div class="row">
          <div class="col-sm-4 semibold-weight">
            <a :href="matterLawyer.path">{{ matterLawyer.fullName }}</a>
          </div>

          <div class="col-sm-8 top-10-xs">
            <impersonate-modal :user="matterLawyer" :target="'_self'">
              <a href="">Impersonate</a>
            </impersonate-modal>
          </div>
        </div>

        <hr class="kenny-hr" v-if="!isLast(index)">
      </div>
    </div>
  </div>
</template>

<script>
import ImpersonateModal from 'vue-app/admin/impersonate-modal.vue';

export default {
  name: 'MatterListLawyers',

  components: {
    ImpersonateModal
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    lawyers: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    hasLawyers() {
      return this.lawyers.length > 0;
    }
  },

  methods: {
    isLast(index) {
      return index === this.lawyers.length - 1;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
