<template>
  <div class="tag-list" v-if="options.length">
    <div class="tag-list-item black compact delete-variant" v-for="(option, index) in options" :key="index">
      <span class="right-10">{{ option }}</span>
      <a href="" class="tag-list-item-delete" @click.prevent="removeOption(option)"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreeningTermOptions',

  props: {
    question: {
      type: Object,
      required: true
    },

    onRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    options() {
      return this.question.value.options;
    }
  },

  methods: {
    removeOption(option) {
      this.onRemove(this.question, option);
    }
  }
};
</script>
