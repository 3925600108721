<template>
  <div class="gray-lighter-panel">
    <div class="row tight-columns vertical-center-not-xs bottom-15">
      <div :class="[{ 'col-xs-12': !editingAllowed, 'col-sm-10': editingAllowed }, 'dark-gray-text size-text-12px text-italic']">
        Last Updated {{ note.updatedAt }} by {{ note.createdBy }}
      </div>

      <div v-if="editingAllowed" class="col-sm-2 d-flex flex-end-not-xs col-gap-15 top-5-xs">
        <edit-note-modal
          title="Edit Note"
          :note="note"
          :on-save="save">
        </edit-note-modal>

        <priori-modal title="Delete Note">
          <template #modal-trigger="{ openModal }">
            <a href="" @click.prevent="openModal"><svg-icon name="trash" class="delete-btn base-icon"></svg-icon></a>
          </template>

          <template #default="{ closeModal }">
            <div class="size-text-14px bottom-30">
              Are you sure that you want to delete this note?
            </div>

            <div class="row tight-columns">
              <div class="col-sm-4">
                <loading-button
                  name="deleteNote"
                  lb-class="primary-btn-red"
                  @lb-click="removeNote">
                  Delete Note
                </loading-button>
              </div>

              <div class="col-sm-3 top-20-xs">
                <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
              </div>
            </div>
          </template>
        </priori-modal>
      </div>
    </div>

    <div v-if="note.title" class="bold-weight size-text-15px bottom-5">
      {{ note.title }}
    </div>

    <collapsable-space :max-height="115">
      <div v-html="note.content" class="no-margin"></div>
    </collapsable-space>
  </div>
</template>

<script>
import EditNoteModal from 'vue-app/scout/client/law-firms/edit-note-modal.vue';
import CollapsableSpace from 'vue-app/shared/components/collapsable-space.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingService from 'vue-app/shared/services/loading-service';

export default {
  name: 'FirmNote',

  components: {
    EditNoteModal,
    CollapsableSpace,
    PrioriModal,
    LoadingButton,
    SvgIcon
  },

  props: {
    note: {
      type: Object,
      required: true
    },

    editingAllowed: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      longTextLength: 600
    };
  },

  methods: {
    removeNote() {
      if (!this.editingAllowed) { return; }

      LoadingService.loading('deleteNote');

      return this.onSave({ ...this.note, _destroy: true }).finally(() => {
        LoadingService.done('deleteNote');
      });
    },

    save(editedNote) {
      if (!this.editingAllowed) { return; }

      return this.onSave(editedNote);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .base-icon.delete-btn {
    width: 12px;
    height: 12px;
    margin-bottom: 3px;
    fill: $k-blue;
  }

  :deep(.faded-section.fade-down)::after {
    background: linear-gradient(180deg, rgba($white, 0) 0%, $k-lighter-gray 100%);
  }
</style>
