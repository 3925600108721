<template>
  <div id="matter-invoice-summary" class="shadowed-box size-text-13px">
    <div class="box-header text-uppercase">
      Invoice Summary
    </div>

    <div class="box-content">
      <div class="row">
        <div class="col-sm-6 col-md-5">
          <div class="tiny-header-text bottom-15">
            Invoices
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Sent Invoices (Paid)
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.sentPaidInvoices) }}
            </div>
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Sent Invoices (Unpaid)
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.sentUnpaidInvoices) }}
            </div>
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Unsent Invoices
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.unsentInvoices) }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-7">
              Unsent Line Items
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.unsentLineItems) }}
            </div>
          </div>

          <div class="visible-xs-block">
            <hr class="kenny-hr">

            <div class="row semibold-weight">
              <div class="col-sm-7">
                Total (All)
              </div>

              <div class="col-sm-5 text-right-not-xs">
                {{ filteredCurrency(summary.totalInvoices) }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-5 col-md-offset-2 top-30-xs">
          <div class="tiny-header-text bottom-15">
            Fee Breakdown
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Legal Fees
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.legalFees) }}
            </div>
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Priori Management Fees
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.prioriFees) }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-7">
              Disbursements
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.disbursements) }}
            </div>
          </div>

          <div class="visible-xs-block">
            <hr class="kenny-hr">

            <div class="row semibold-weight">
              <div class="col-sm-7">
                Total (All)
              </div>

              <div class="col-sm-5 text-right-not-xs">
                {{ filteredCurrency(summary.totalFeeBreakdown) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row bottom-60 hidden-xs">
        <div class="col-sm-6 col-md-5">
          <hr class="kenny-hr">

          <div class="row semibold-weight">
            <div class="col-sm-7">
              Total (All)
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.totalInvoices) }}
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-5 col-md-offset-2">
          <hr class="kenny-hr">

          <div class="row semibold-weight">
            <div class="col-sm-7">
              Total (All)
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.totalFeeBreakdown) }}
            </div>
          </div>
        </div>
      </div>

      <div class="row top-30-xs">
        <div class="col-sm-6 col-md-5">
          <div class="tiny-header-text bottom-15">
            Lawyer Deposits
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Sent Invoices (Paid)
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.sentPaidInvoices) }}
            </div>
          </div>

          <div class="row tight-columns bottom-15">
            <div class="col-sm-7">
              Less Priori Management Fee
            </div>

            <div class="col-sm-5 text-right-not-xs">
              ({{ filteredCurrency(summary.prioriFeesPaidInvoices) }})
            </div>
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Less Processing Fees
            </div>

            <div class="col-sm-5 text-right-not-xs">
              ({{ filteredCurrency(summary.processingFees) }})
            </div>
          </div>

          <hr class="kenny-hr">

          <div class="row semibold-weight">
            <div class="col-sm-7">
              Amount Deposited
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.lawyerAmountDeposited) }}
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-5 col-md-offset-2 top-30-xs">
          <div class="tiny-header-text bottom-15">
            Other Amounts
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Credits Applied
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.creditsApplied) }}
            </div>
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Retainer Remaining
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.retainerRemaining) }}
            </div>
          </div>

          <div class="row bottom-15">
            <div class="col-sm-7">
              Cancelled
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.cancelled) }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-7">
              Written Off
            </div>

            <div class="col-sm-5 text-right-not-xs">
              {{ filteredCurrency(summary.writtenOff) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';

export default {
  name: 'MatterInvoiceSummary',

  props: {
    summary: {
      type:     Object,
      required: true
    }
  },

  methods: {
    filteredCurrency(amount) {
      return CurrencyFilter.filter(amount);
    }
  }
};
</script>
