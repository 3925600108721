<template>
  <div v-if="showValues" :class="['search-filter-value semibold-weight tight-lines', { 'top-5': hasValues }]">
    {{ searchedValues() }}
  </div>
</template>

<script>
import moment from 'moment';
import { join, map, first, compact, flatMap, orderBy, sortBy } from 'lodash';
import SharedListService from 'vue-app/shared/services/shared-lists-service.js';

let STATUSES = {
  'applying': 'Status: Applying',
  'application_complete': 'Status: Application Complete',
  'base_profile': 'Status: Base Profile',
  'approved': 'Status: Approved',
  'on_hold': 'Status: On Hold',
  'rejected': 'Status: Rejected'
};

let ACCOUNT_TYPES = {
  'lawyer': 'Account Type: Lawyer',
  'paralegal': 'Account Type: Paralegal',
  'admin': 'Account Type: Firm Admin',
  'legal_operations_professional': 'Account Type: Legal Operations Professional'
};

let HIGHEST_RATE = 80000;
let HIGHEST_YEARS_EXPERIENCE = 60;

export default {
  name: 'AdminSearchFilterValues',

  props: {
    fieldName: {
      type: String,
      default: null
    },

    fields: {
      type: Array,
      default: () => []
    },

    searchService: {
      type: Object,
      required: true
    },

    hidden: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      hasValues: false
    };
  },

  computed: {
    queries() {
      return this.searchLogic.scores;
    },

    filters() {
      return this.searchLogic.filters;
    },

    keywords() {
      return this.searchLogic.keywords;
    },

    searchType() {
      return this.searchLogic.includes;
    },

    searchLogic() {
      return this.searchService.searchLogic;
    },

    clear() {
      return this.searchService.clear;
    },

    showValues() {
      return !this.hidden && !this.clear;
    }
  },

  watch: {
    'searchService.clear': function (value) {
      if (value) {
        this.searchedValues();
      }
    }
  },

  methods: {
    searchedValues() {
      let orderedLabels = orderBy(this.getLabels());
      this.hasValues = !!orderedLabels.length;
      return join(orderedLabels, '; ');
    },

    getLabels() {
      if (this.fields.length > 0) {
        return flatMap(this.fields, field => this.formatField(field));
      }
      else if (this.fieldName) {
        return this.formatField(this.fieldName);
      }
      else {
        return [];
      }
    },

    searchParamsFor(field) {
      if (this.searchService.isQueryParamSet(field)) {
        return this.queries[field];
      }
      else if (this.searchService.isFilterParamSet(field)) {
        return this.filters[field];
      }
      else {
        return null;
      }
    },

    formatField(field) {
      if (field === 'includes') {
        // TODO: I removed this from the input for now because it has weird
        // interactions throughout. So this code will never be reached. Need to
        // rethink the top-level lawyer/firm choice.
        return this.formatSearchType();
      }
      else if (field === 'keywords') {
        return this.formatKeywords();
      }
      else {
        let searchParams = this.searchParamsFor(field);

        if (searchParams === null) {
          return [];
        }
        else if (typeof searchParams === 'boolean') {
          return this.formatBooleanField(searchParams);
        }
        else {
          return this.formatArrayField(searchParams, field);
        }
      }
    },

    formatArrayField(searchParams, field) {
      let arrayField = this.getArrayField(searchParams);

      switch (field) {
        case 'status':
          return map(arrayField, status => STATUSES[status]);

        case 'available':
        case 'unavailable': {
          let hoursPerWeekArrayField = this.queries['availability_hours_per_week'];
          let hoursPerWeek = '';

          if (hoursPerWeekArrayField) {
            hoursPerWeek = [` ${join(this.getArrayField(hoursPerWeekArrayField), '-')} hr/wk`];
          }

          return map(arrayField, status => status ? `Availability: Available${hoursPerWeek}` : 'Availability: Currently Unavailable');
        }

        case 'account_type':
          return map(arrayField, accountType => ACCOUNT_TYPES[accountType]);

        case 'has_malpractice':
          return map(arrayField, mpi => {
            switch (mpi) {
              case 'yes': return 'Malpractice Insurance: Yes';
              case 'no': return 'Malpractice Insurance: No';
              default: return 'Malpractice Insurance: Not Answered';
            }
          });

        case 'last_active': {
          let lastActive = first(arrayField);
          return lastActive ? [`Last ${lastActive} days`] : [];
        }

        case 'default_rate':
          return this.formatRange(arrayField, HIGHEST_RATE, true);

        case 'years_experience':
          return this.formatRange(arrayField, HIGHEST_YEARS_EXPERIENCE, false);

        case 'date_applied':
        case 'interview_requested_on':
        case 'reference_contacts_requested_on':
        case 'reference_contacts_received_on':
        case 'references_submitted_on':
          return [join(map(compact(arrayField), date => moment(String(date)).format('YYYY/MM/DD')), ' - ')];

        case 'firm_size':
          return [join(sortBy(arrayField, firmSize => SharedListService.firmSizes.indexOf(firmSize)), '; ')];

        default:
          return compact(arrayField);
      }
    },

    formatSearchType() {
      return map(this.searchType, (value) => {
        if (value === 'lawyers') {
          return 'Individuals';
        }
        else {
          return 'Firms';
        }
      });
    },

    formatKeywords() {
      return map(this.keywords, value => `"${value}"`);
    },

    formatBooleanField(searchParams) {
      return searchParams ? ['Yes'] : ['No'];
    },

    formatRange(arrayField, maxValue, isCurrency) {
      let gte = (arrayField[0] > 0 ? arrayField[0] : null);
      let lte = (arrayField[1] < maxValue ? arrayField[1] : null);
      let symbol = (isCurrency ? '$' : '');

      if (isCurrency) {
        gte = gte / 100;
        lte = lte / 100;
      }

      if (gte && lte) {
        return [`${symbol}${gte} - ${symbol}${lte}`];
      }
      else if (gte) {
        return [`${symbol}${gte}+`];
      }
      else if (lte) {
        return [`${symbol}0 - ${symbol}${lte}`];
      }
      else {
        return [];
      }
    },

    getArrayField(searchParams) {
      if (Array.isArray(searchParams)) {
        return searchParams;
      }
      else if (searchParams.radius && searchParams.locations) {
        let locations = map(searchParams.locations, location => location.name).join('; ');
        return [`${locations} (${searchParams.radius})`];
      }
      else if (searchParams.gte || searchParams.lte) {
        return [searchParams.gte, searchParams.lte];
      }
      else {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .search-filter-value {
    color: $k-blue;
    font-size: 12px;

    &::first-letter {
      text-transform: capitalize;
    }
  }
</style>
