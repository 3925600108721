import axios from 'axios';
import BaseResource from 'resources/base-resource';

class ScoutMatterManager extends BaseResource {
  static baseUrl = '/scout/matter_managers/:id.json';

  static groupedByExternalId(params) {
    return axios.get('/scout/matter_managers/grouped_by_external_id.json', { params: params }).then(data => {
      return data.data;
    });
  }
}

export default ScoutMatterManager;
