<template>
  <edit-modal ref="editModal" title="Editing Office Locations" :disabled="saving" :input="officeLocations" @on-modal-open="onModalOpen" :on-save="save" :on-cancel="cancel" modal-size="lg" variant="centeredButtons">
    <template #default="{ inputCopy: editedOfficeLocations }">
      <div class="small-bold-header">
        Law Firm Office Locations
      </div>

      <hr class="hr">

      <div class="text-italic pill-gray-text size-text-12px bottom-30">
        Please use the fields below to list the office locations that you want to appear on your law firm profile.
      </div>

      <div class="row bottom-40">
        <div class="col-md-8 col-md-offset-2 text-center">
          <div class="bold-weight bottom-10">
            Data Input Type
          </div>

          <pill-switcher-with-confirmation
            ref="pillSwitcherWithConfirmation"
            :options="switcherOptions"
            :should-confirm-change="true"
            :on-select="(inputType) => changeInputType(inputType, editedOfficeLocations)"
            :default-option-value="selectedInputType">
            <template #note>
              <div class="pill-gray-text size-text-12px tight-lines">
                Please note data may be reported as either a numerical value OR percentage, but must be consistent across all fields.
              </div>
            </template>

            <template #confirmation-message>
              <div>
                Are you sure you want to switch data input type?
              </div>
              <div>
                All existing data of this type will be deleted.
              </div>
            </template>
          </pill-switcher-with-confirmation>
        </div>
      </div>

      <div v-for="location in editedOfficeLocations" :key="location.id" class="d-flex col-gap-10 row-gap-10 vertical-center-not-xs bottom-10">
        <div class="flex-1 remove-button-container">
          <a href="" @click.prevent="removeLocation(editedOfficeLocations, location)">
            <svg-icon name="minus-circle" class="base-icon"></svg-icon>
          </a>
        </div>

        <div class="flex-6">
          <typeahead-vertical
            :id="`location-country-${location.id}`"
            input-name="locationCountry"
            label="Country"
            rules="required"
            v-model="location.country"
            @input="countrySelected(location)"
            :initial-value="location.country"
            :force-select="true"
            :options="countries">
          </typeahead-vertical>
        </div>

        <div class="flex-6">
          <typeahead-vertical
            :id="`location-state-${location.id}`"
            input-name="locationState"
            label="State"
            v-model="location.state"
            :disabled="location.country !== 'United States'"
            :initial-value="location.state"
            :force-select="true"
            :options="stateNames"
            :placeholder="togglePlaceholder(location.country)"
            :rules="stateRequired(location.country)">
          </typeahead-vertical>
        </div>

        <div class="flex-6">
          <text-input-vertical
            :id="`location-city-${location.id}`"
            name="locationCity"
            label="City"
            autocomplete="nope"
            rules="required"
            v-model="location.city">
          </text-input-vertical>
        </div>

        <div class="flex-3">
          <text-input-vertical
            :id="`location-total-lawyers-${location.id}`"
            name="locationTotalLawyers"
            :input-type="location.lawyersCountIsPercentage ? 'percentage' : 'number'"
            label="Total Lawyers"
            autocomplete="nope"
            :rules="location.lawyersCountIsPercentage ? 'positive' : 'integer|positive'"
            :value="formattedLawyersCount(location)"
            @input="location.lawyersCount = $event">
          </text-input-vertical>
        </div>

        <div class="flex-4 text-center-not-xs">
          <label>Office is HQ?</label>

          <div class="radio-container">
            <input type="radio" name="headquarters" v-model="headquartersId" :value="location.id" :checked="location.isHeadquarters">
            <span class="check checked c-radio"></span>
          </div>
        </div>
      </div>

      <text-input-vertical
        v-if="inputTypeIsPercentage"
        id="total-lawyers"
        name="totalLawyers"
        input-type="hidden"
        label=""
        autocomplete="nope"
        rules="percentage"
        class="hidden"
        :value="lawyersCount(editedOfficeLocations)">
      </text-input-vertical>

      <div>
        <button type="button" class="pseudo-link-blue semibold-weight" @click="addLocation(editedOfficeLocations)">
          {{ editedOfficeLocations.length > 0 ? '+ Add Another' : '+ Add Office Location' }}
        </button>
      </div>
    </template>

    <template #errorMessage>
      <div v-if="inputTypeIsPercentage && lawyersCount($refs.editModal?.inputCopy) > 100">
        Total Lawyers column must sum to 100%.
      </div>
    </template>
  </edit-modal>
</template>

<script>
import { changes } from 'misc/resource-helpers';
import { each, includes, indexOf, some, uniqueId, reduce, isEmpty } from 'lodash';

import DataLock                     from 'resources/data-lock';
import EditModal                    from 'vue-app/scout/shared/edit-modal.vue';
import PillSwitcherWithConfirmation from 'vue-app/shared/components/pill-switcher-with-confirmation.vue';
import SvgIcon                      from 'vue-app/shared/components/svg-icon.vue';
import TextInputVertical            from 'vue-app/shared/components/text-input-vertical.vue';
import TypeaheadVertical            from 'vue-app/shared/components/typeahead-vertical.vue';
import SharedLists                  from 'vue-app/shared/services/shared-lists-service';
import LoadingService               from 'vue-app/shared/services/loading-service';

export default {
  name: 'EditFirmOfficeLocations',

  components: {
    EditModal,
    PillSwitcherWithConfirmation,
    SvgIcon,
    TextInputVertical,
    TypeaheadVertical
  },

  props: {
    firm: {
      type: Object,
      required: true
    },

    officeLocations: {
      type: Array,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      locationsToDestroy: [],
      inputTypeChangeConfirmationVisible: false,
      countries: SharedLists.listOfCountries(),
      stateNames: SharedLists.listOfStateObjects().map(s => s.name),
      headquartersId: this.officeLocations.find(l => l.isHeadquarters)?.id,
      selectedInputType: some(this.officeLocations, (l) => l.lawyersCountIsPercentage) ? 'percentage' : 'numerical',
      switcherOptions: [
        { label: 'Numerical (#)', value: 'numerical' },
        { label: 'Percentage (%)', value: 'percentage' }
      ]
    };
  },

  computed: {
    inputTypeIsPercentage() {
      return this.selectedInputType === 'percentage';
    },

    saving() {
      return LoadingService.isLoading('firm-office-locations');
    }
  },

  methods: {
    removeLocation(editedLocations, location) {
      if (!this.isNewLocation(location)) {
        this.locationsToDestroy = [...this.locationsToDestroy, { id: location.id, _destroy: true }];
      }

      editedLocations.splice(indexOf(editedLocations, location), 1);
    },

    addLocation(editedLocations) {
      editedLocations.push({
        id: uniqueId('new_location_'),
        city: null,
        state: null,
        country: null,
        lawyersCount: null,
        lawyersCountIsPercentage: this.inputTypeIsPercentage
      });
    },

    changeInputType(inputType, editedLocations) {
      if (this.selectedInputType === inputType) { return; }

      this.selectedInputType = inputType;

      each(editedLocations, location => {
        location.lawyersCount             = null;
        location.lawyersCountIsPercentage = this.inputTypeIsPercentage;
      });

      this.inputTypeChangeConfirmationVisible = false;
    },

    dismissInputTypeChange() {
      this.inputTypeChangeConfirmationVisible = false;
    },

    isValidCountry(country) {
      return this.countries.includes(country);
    },

    isUnitedStates(country) {
      return country === 'United States';
    },

    countrySelected(location) {
      const country = location.country;

      if (this.isValidCountry(country) && !this.isUnitedStates(country)) {
        location.state = '';
      }
    },

    stateRequired(country) {
      if (this.isUnitedStates(country)) {
        return 'required';
      }
      else {
        return null;
      }
    },

    togglePlaceholder(country) {
      if (this.isValidCountry(country) && !this.isUnitedStates(country)) {
        return 'N/A';
      }
      else {
        return '';
      }
    },

    formattedLawyersCount(location) {
      if (isEmpty(location.lawyersCount)) { return null; }

      const decimalPlaces = location.lawyersCount % 1 !== 0 ? 1 : 0;

      return Number(location.lawyersCount).toFixed(decimalPlaces);
    },

    lawyersCount(editedLocations) {
      return reduce(editedLocations, (total, location) => total + Number(location.lawyersCount || 0), 0);
    },

    save(editedLocations) {
      each(editedLocations, location => {
        location.isHeadquarters = location.id === this.headquartersId;
        location.id            = this.isNewLocation(location) ? null : location.id;
      });

      editedLocations = [...editedLocations, ...this.locationsToDestroy];

      if (some(changes(this.officeLocations, editedLocations))) {
        this.firm.lawFirmLocationsAttributes = editedLocations;

        LoadingService.loading('firm-office-locations');

        this.onSave(this.firm, (firm) => {
          this.selectedInputType  = some(firm.locations, (location) => location.lawyersCountIsPercentage) ? 'percentage' : 'numerical';
          LoadingService.done('firm-office-locations');
        });

        DataLock.save({
          dataLock: {
            flag:         'office_locations',
            resourceType: 'law_firm',
            resourceId:    this.firm.id
          }
        });
      }
    },

    isNewLocation(location) {
      return includes(location.id, 'new_location_');
    },

    onModalOpen() {
      this.$refs.pillSwitcherWithConfirmation.reset();
    },

    cancel() {
      this.locationsToDestroy = [];
      this.selectedInputType  = some(this.officeLocations, (location) => location.lawyersCountIsPercentage) ? 'percentage' : 'numerical';
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .hr {
    margin: 5px 0;
  }

  .d-flex {
    flex-wrap: wrap;

    @media (min-width: 768px) {
      align-items: baseline;
      flex-wrap: nowrap;
    }

    div {
      flex-basis: 100vw;

      @media (min-width: 768px) {
        flex-basis: 0;
      }
    }
  }

  .remove-button-container {
    align-self: flex-start;
    padding-top: 10px;

    @media (min-width: 768px) {
      padding-top: 35px;
    }
  }

  .menu-item-container {
    padding: 5px;

    li {
      padding: 5px 10px;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .radio-container {
    @media (min-width: 768px) {
      margin-top: 10px;
    }
  }
</style>
