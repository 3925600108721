<template>
  <ul>
    <li v-for="(item, index) in legendData" :key="index">
      <div class="legend-label">
        <span class="bullet" :style="{ 'backgroundColor': item.color }"></span>
        {{ item.name }}
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ChartInlineLegend',

  props: {
    legendData: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
  ul {
    margin: 0 auto;
    padding: 0;
    text-align: center;
    list-style-type: none;

    li {
      line-height: 16px;
      margin-left: 20px;
      display: inline;

      &:first-child {
        margin-left: 0;
      }

      .bullet, .legend-label {
        display: inline-block;
        font-size: 11px;
        vertical-align: middle;
      }

      .bullet {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        margin-bottom: 1px;
        border-radius: 2px;
      }
    }
  }
</style>
