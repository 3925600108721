<template>
  <priori-modal ref="addSectionModal" modal-id="add-section-modal" :title="modalTitle" @on-modal-close="clearForm">
    <template #modal-trigger="{ openModal }">
      <a v-if="!isEditing" href="" @click.prevent="openModal">Add Custom Section</a>

      <button v-else type="button" class="pseudo-link-blue bold-weight d-flex gap-4 size-text-13px" @click="initForm(openModal)">Edit</button>
    </template>

    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }" :disabled="true">
      <form role="form" class="a-form" novalidate @submit.prevent>
        <div class="bottom-30">
          <text-input-vertical
            label="Enter the name of the section"
            id="custom-section-name"
            input-name="customSectionName"
            :rules="{ required: true, unique: { list: existingGroupNames }}"
            v-model="sectionForm.name">
          </text-input-vertical>
        </div>

        <div class="row tight-columns">
          <div class="col-sm-5">
            <button v-if="!isEditing" type="button" class="primary-btn-blue" @click="handleSubmit(addSection)">Add Section</button>

            <button v-else type="button" class="primary-btn-blue" @click="handleSubmit(updateSection)">Save</button>
          </div>

          <div class="col-sm-4 top-20-xs">
            <button type="button" class="nv-button-white" @click="dismissModal">Cancel</button>
          </div>
        </div>
      </form>
    </validation-observer>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { ValidationObserver } from 'vee-validate';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'RfqTemplateAddCustomSection',

  components: {
    PrioriModal,
    TextInputVertical,
    ValidationObserver
  },

  props: {
    group: {
      type: Object,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    existingGroups: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      sectionForm: {
        name: null
      }
    };
  },

  computed: {
    ...mapState(useRfqTemplateStore, {
      rfqTemplate: 'rfqTemplate',
      nextSectionPosition: 'nextSectionPosition'
    }),

    existingGroupNames() {
      if (this.isEditing) {
        return this.existingGroups.map((group) => group.name).filter(groupName => groupName !== this.group.name);
      }
      return this.existingGroups.map((group) => group.name);
    },

    modalTitle() {
      return this.isEditing ? 'Edit Custom Section' : 'Add Custom Section';
    }
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      addGroup: 'addGroup',
      updateGroup: 'updateGroup'
    }),

    initForm(openModal) {
      this.sectionForm.name = this.group.name || null;
      openModal();
    },

    updateSection() {
      const updatedGroup = {
        ...this.group,
        name: this.sectionForm.name.trim()
      };
      this.updateGroup(updatedGroup);
      this.dismissModal();
    },

    addSection() {
      const section = {
        handlesLawyers: false,
        name: this.sectionForm.name.trim(),
        position: this.nextSectionPosition,
        isShown: true,
        questionTemplatesAttributes: []
      };
      this.addGroup(section);
      this.dismissModal();
    },

    dismissModal() {
      this.$refs.addSectionModal.closeModal();
      this.clearForm();
    },

    clearForm() {
      this.sectionForm.name = null;
      this.$refs.validationObserver.reset();
    }
  }
};
</script>
