<template>
  <div>
    <div class="titlebar with-button">
      <div class="row">
        <div class="col-sm-8 col-lg-9">
          <div class="medium-page-heading top-6">
            Data Uploads
          </div>
        </div>

        <div class="col-sm-4 col-lg-3 top-20-xs">
          <upload-modal
            :upload-initializer="uploadInitializer"
            :on-creation="addUpload"
            :upload-in-progress="uploadIsInProgress"
            :current-upload="currentUpload"
            :upload-type="uploadType">
          </upload-modal>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-30">
            <a href="/scout-company/settings" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to Admin Settings</a>
          </div>

          <loading-section name="eBillingUploadItems">
            <upload-list
              :uploads="uploads"
              :url-constructor="urlConstructor">
            </upload-list>

            <div class="top-15" v-if="uploads.length">
              <priori-pagination
                class="scout-pagination"
                :current-page="page"
                :total-entries="itemCount"
                :per-page="10"
                @change="changePage">
              </priori-pagination>
            </div>
          </loading-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadModal from 'vue-app/scout/shared/uploads/upload-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import UploadList from 'vue-app/scout/shared/uploads/upload-list.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import EBillingUpload from 'resources/scout/client/e-billing-upload.js';
import EBillingUploadInitializer from 'services/scout/client/ebilling-upload-initializer.js';

export default {
  name: 'DataUploadsIndex',

  components: {
    UploadModal,
    SvgIcon,
    LoadingSection,
    UploadList,
    PrioriPagination
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      uploads: this.initData.uploads,
      itemCount: this.initData.itemCount,
      page: 1,
      uploadInitializer: EBillingUploadInitializer,
      currentUpload: EBillingUpload,
      uploadType: 'eBilling'
    };
  },

  computed: {
    uploadIsInProgress() {
      return this.uploads.some(upload => upload.status <= 2);
    }
  },

  methods: {
    urlConstructor(id) {
      return `/scout-company/data-uploads/${id}`;
    },

    loadUploads() {
      LoadingService.loading('eBillingUploadItems');
      EBillingUpload.queryData({ page: this.page, view: 'list' }).then((resp) => {
        this.uploads = resp.data.uploads;
        this.itemCount = resp.data.metadata.total;
        LoadingService.done('eBillingUploadItems');
      });
    },

    changePage(page) {
      if (this.page === page || page < 1) { return; }

      this.page = page;
      this.loadUploads();
    },

    addUpload(upload) {
      this.uploads.unshift(upload);
    }
  }
};
</script>
