<template>
  <div>
    <div v-if="editing" class="row">
      <div class="col-sm-4">
        <loading-button name="saveOpportunity" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(saveAndPublish)">Save & Publish</loading-button>
      </div>

      <div v-if="!opportunity.publishedAt" class="col-sm-4 top-20-xs">
        <loading-button name="saveOpportunity" lb-class="nv-button-blue a-button-size" @lb-click="saveAsDraft">Save As Draft</loading-button>
      </div>

      <div class="col-sm-3 top-20-xs">
        <button type="button" class="secondary-btn-blue a-button-size" @click="onCancel">Cancel</button>
      </div>
    </div>

    <div v-else>
      <div v-if="opportunity.publishedAt" class="row top-10 vertical-center-not-xs">
        <div class="col-sm-9 size-text-12px">
          <i>Last Updated on {{ lastPublishedDate }}</i>
        </div>

        <div class="col-sm-3 top-20-xs">
          <priori-modal ref="unpublishModal" modal-id="unpublish-modal" title="Unpublish Opportunity Post">
            <template #modal-trigger="{ openModal }">
              <button type="button" class="nv-button-red inverse a-button-size" @click="openModal">Unpublish Post</button>
            </template>

            <div class="size-text-14px bottom-10">
              You will remove this opportunity post.
            </div>

            <div class="size-text-14px bottom-30">
              Are you sure?
            </div>

            <div class="row tight-columns">
              <div class="col-sm-4">
                <loading-button name="unpublishOpportunity" lb-class="nv-button-red a-button-size" @lb-click="unpublish">Unpublish</loading-button>
              </div>

              <div class="col-sm-3 top-20-xs">
                <button type="button" class="nv-button-white a-button-size" @click="cancel">Cancel</button>
              </div>
            </div>
          </priori-modal>
        </div>
      </div>

      <div v-else class="row top-20 vertical-center-not-xs">
        <div class="col-sm-9 size-text-12px">
          <i>Last Updated on {{ lastUpdatedDate }}</i>
        </div>

        <div class="col-sm-3 top-20-xs">
          <loading-button name="publishOpportunity" lb-class="primary-btn-blue smaller" :lb-disabled="invalid" @lb-click="onPublish">Publish Post</loading-button>
        </div>
      </div>

      <div v-if="!opportunity.publishedAt && invalid" class="error-text top-10">
        <div class="text-right-not-xs">
          Required fields are missing; please go back and edit the form before publishing.
        </div>
      </div>

      <div v-if="!opportunity.publishedAt && hasPublishingHistory">
        <div class="top-20">
          <b>Publishing History - Live Dates:</b>
        </div>

        <div class="top-10">
          <div v-for="(history, index) in publishingHistories" :key="`history-${index}`">
            <span class="right-5">{{ index + 1 }}.</span>{{ formatDate(history.publishStartedAt) }} – {{ formatDate(history.publishEndedAt) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { DateTime } from 'luxon';

export default {
  name: 'OpportunityActions',

  components: {
    PrioriModal,
    LoadingButton
  },

  props: {
    opportunity: {
      type: Object,
      required: true
    },

    editing: {
      type: Boolean,
      default: true
    },

    invalid: {
      type: Boolean,
      default: false
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },
    validate: {
      type: Function,
      required: true
    },

    onCancel: {
      type: Function,
      required: true
    },

    onUnpublish: {
      type: Function,
      required: true
    },

    onPublish: {
      type: Function,
      required: true
    }
  },

  computed: {
    lastPublishedDate() {
      return this.formatDate(this.opportunity.publishedAt);
    },

    lastUpdatedDate() {
      return this.formatDate(this.opportunity.updatedAt);
    },

    publishingHistories() {
      return this.opportunity.opportunityPublishingHistories;
    },

    hasPublishingHistory() {
      return this.publishingHistories.length > 0;
    }
  },

  methods: {
    saveAsDraft() {
      this.onSave('draft');
      this.validate();
    },

    formatDate(date) {
      return DateTime.fromISO(date).toFormat('MM/dd/yyyy');
    },

    cancel() {
      this.$refs.unpublishModal.closeModal();
      this.onCancel();
    },

    saveAndPublish() {
      this.onSave('published');
    },

    unpublish() {
      this.$refs.unpublishModal.closeModal();
      this.onUnpublish();
    }
  }
};
</script>

