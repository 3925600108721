<template>
  <div>
    <rfq-question-item
      v-for="question in orderedQuestions" :key="question.position"
      class="list-spacing-10"
      :show-edit="true"
      :is-editable="isEditable"
      :question="question"
      :group="group">
    </rfq-question-item>
  </div>
</template>

<script>
import RfqQuestionItem from 'vue-app/scout/client/settings/rfqs/templates/new/question-item.vue';
import { orderBy } from 'lodash';

export default {
  name: 'OrderedQuestionGroup',

  components: {
    RfqQuestionItem
  },

  props: {
    group: {
      type: Object,
      required: true
    },

    isEditing: {
      type: Boolean,
      required: true
    },

    isEditable: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    viewableQuestions() {
      return this.group.questionTemplatesAttributes.filter(q => !q.prerequisiteQuestionId && !q._destroy);
    },

    orderedQuestions() {
      return orderBy(this.viewableQuestions, 'position');
    }
  }
};
</script>
