<template>
  <div class="rating-bar-container">
    <div class="rating-bar">
      <div v-if="showBudget" :class="barClass" :style="fillBarStyle" :id="`bar-${quote.id}`"></div>
      <div v-else class="no-data-text">{{ noDataText(quote.status) }}</div>
    </div>

    <popover-tooltip
      :target="`#bar-${quote.id}`"
      custom-class="tooltip-white">
      <template #tooltip>
        <div class="semibold-weight">{{ quoteLabel }}</div>
        <div class="text-muted">{{ formattedCost }}</div>
      </template>
    </popover-tooltip>
  </div>
</template>

<script>
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'BudgetChartBar',

  components: {
    PopoverTooltip
  },

  props: {
    quote: {
      type: Object,
      required: true
    },

    rangeData: {
      type: Object,
      required: true
    },

    legalFeeQuestionIncluded: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    quoteLabel() {
      return this.quote.fromMarketplace ? this.quote.marketplaceLawyer.fullName : this.quote.lawFirm.name;
    },

    estimatedLegalFees() {
      return this.quote.estimatedLegalFees?.value || [];
    },

    fillBarWidthPercent() {
      if (this.estimatedLegalFees.length === 0) {
        return 0;
      }
      else {
        const lastFee = this.estimatedLegalFees.slice(-1)[0];

        return (lastFee - this.rangeData.min) / this.range * 100;
      }
    },

    leftEdgePercent() {
      if (this.estimatedLegalFees.length === 0) {
        return 0;
      }
      else {
        return (this.estimatedLegalFees[0] - this.rangeData.min) / this.range * 100;
      }
    },

    fillBarStyle() {
      if (this.estimatedLegalFees.length === 1) {
        return {
          left: `${this.leftEdgePercent}%`
        };
      }
      else if (this.estimatedLegalFees.length === 2) {
        return {
          width: `${this.fillBarWidthPercent - this.leftEdgePercent}%`,
          left: `${this.leftEdgePercent}%`
        };
      }
    },

    hasBudget() {
      return this.estimatedLegalFees?.length > 0;
    },

    range() {
      return this.rangeData.max - this.rangeData.min;
    },

    barClass() {
      if (this.estimatedLegalFees.length == 1) {
        return ['dot', { 'from-marketplace': this.quote.fromMarketplace }];
      }
      else if (this.estimatedLegalFees.length == 2) {
        return ['rating-fill', { 'from-marketplace': this.quote.fromMarketplace }];
      }
      else {
        return '';
      }
    },

    formattedCost() {
      const formattedLegalFees = this.estimatedLegalFees.map(cost => CurrencyFilter.filter(cost));

      return formattedLegalFees.join(' - ');
    },

    quoteSubmitted() {
      return ['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(this.quote.status);
    },

    showBudget() {
      return this.legalFeeQuestionIncluded && this.hasBudget && this.quoteSubmitted;
    }
  },

  methods: {
    noDataText(status) {
      if (!this.legalFeeQuestionIncluded) {
        return 'Pricing data not requested';
      }
      else if (status === 'declined_by_firm') {
        return 'Declined';
      }
      else if (status === 'draft' || status === 'new_rfq') {
        return 'Pending Response';
      }
      else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rating-bar-container {
    position: relative;

    .rating-fill {
      background: $high-match-score-blue;

      &.from-marketplace {
        background: $k-purple;
      }
    }

    .rating-bar, .rating-fill {
      position: relative;
      z-index: 1;

      .no-data-text {
        position: absolute;
        top: -6px;
        width: 100%;
        text-align: center;
        font-size: 11px;
        font-style: italic;
        color: $pill-gray;
      }

      .dot {
        position: absolute;
        top: 50%;
        right: 0;
        width: 8px;
        height: 8px;
        margin-left: 0.5px;
        transform: translate(-50%, -50%);
        background: $high-match-score-blue;
        border-radius: 50%;

        &.from-marketplace {
          background: $k-purple;
        }
      }
    }

    .range-bar {
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      height: 23px;
      background-color: $grey-3;
      border-radius: $border-radius-small;
      z-index: 0;
    }
  }
</style>
