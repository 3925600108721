<template>
  <div>
    <div class="titlebar with-switcher">
      <div class="medium-page-heading">
        Usage & Analytics
      </div>

      <div class="titlebar-switcher">
        <a href="" :class="{ 'selected': isTabSelected('member') }" @click.prevent="selectTab('member')">Member Usage</a>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div v-if="isTabSelected('member')">
            <usage-analytics-member-usage
              :client-usage-analytics="clientUsageAnalytics"
              :query-member-usage="queryMemberUsage">
            </usage-analytics-member-usage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsageAnalyticsMemberUsage from 'vue-app/scout/client/usage-analytics/usage-analytics-member-usage.vue';
import ScoutClientUsageAnalytics from 'resources/scout/client/scout-client-usage-analytics.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'UsageAnalyticsIndex',

  components: {
    UsageAnalyticsMemberUsage
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      clientUsageAnalytics: this.initData.clientUsageAnalytics,
      selectedTab: 'member'
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' })
  },

  methods: {
    isTabSelected(tab) {
      return this.selectedTab === tab;
    },

    selectTab(tab) {
      if (this.selectedTab !== tab) {
        this.selectedTab = tab;
      }
    },

    queryMemberUsage(params) {
      LoadingService.loading('memberUsage');

      ScoutClientUsageAnalytics.memberUsage({ workspace_id: this.user.workspaceId, ...params })
        .then(response => {
          this.clientUsageAnalytics = response.data;
          LoadingService.done('memberUsage');
        });
    }
  }
};
</script>
