<template>
  <boxed-content title="Quick Actions">
    <a v-if="lawyer.hasVisibleProfile" href="/scout-firm/my-profile" class="secondary-btn-blue bottom-15">Edit Profile</a>

    <create-list v-if="lawyerIsPartner"></create-list>

    <suggest-a-client></suggest-a-client>
  </boxed-content>
</template>

<script>
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import CreateList from 'vue-app/scout/firm/dashboard/create-list.vue';
import SuggestAClient from 'vue-app/scout/firm/dashboard/suggest-a-client.vue';

export default {
  name: 'QuickActions',

  components: {
    BoxedContent,
    CreateList,
    SuggestAClient
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      workspaces: [],
      workspacesLoaded: false
    };
  },

  computed: {
    lawyerIsPartner() {
      return this.lawyer.partner;
    }
  }
};
</script>
