<template>
  <div class="container padded-section">
    <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <div class="shadowed-box">
        <div class="box-content symmetrical">
          <div class="text-center bottom-20">
            <svg-icon name="priori-logo" class="priori-brand-icon"></svg-icon>
          </div>

          <loading-section name="profileAccessRequest">
            <div v-if="responded" class="semibold-weight">
              <div class="bottom-20 size-text-14px">
                {{ notice }}
              </div>

              <div class="text-center">
                <button
                  type="button"
                  class="nv-button-white fit-content semibold-weight"
                  @click="goToDashboard">
                  Go To Dashboard
                </button>
              </div>
            </div>

            <div v-else>
              <div class="semibold-weight size-text-14px top-20 bottom-20">
                {{ promptText }}
              </div>

              <div class="row">
                <div class="col-sm-6 ">
                  <button
                    type="button"
                    class="nv-button-blue a-button-size semibold-weight"
                    @click="onSave(true)">
                    Yes
                  </button>
                </div>

                <div class="col-sm-6 top-10-xs">
                  <button
                    type="button"
                    class="nv-button-white a-button-size semibold-weight"
                    @click="onSave(false)">
                    No
                  </button>
                </div>
              </div>
            </div>
          </loading-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ProfileDataPermission',

  components: {
    LoadingSection
  },

  props: {
    profileAccessRequest: {
      type:     Object,
      required: true
    },

    onSave: {
      type:     Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentLawyer: 'currentUser' }),

    responded() {
      return this.profileAccessRequest.responded;
    },

    responderIsViewer() {
      return this.profileAccessRequest.responder.id === this.currentLawyer.id;
    },

    requesterName() {
      return this.profileAccessRequest.requester.fullName;
    },

    requestingCompanyName() {
      return this.profileAccessRequest.requester.workspaceName;
    },

    promptText() {
      return `${ this.requesterName } at ${ this.requestingCompanyName } has added your firm as a potential recipient of RFPs. Would you like to grant users at ${ this.requestingCompanyName } permission to see profile data?`;
    },

    notice() {
      if (this.responderIsViewer && !this.profileAccessRequest.firmApproved) {
        return `You have denied users at ${this.requestingCompanyName} permission to view your firm's profile data. If this needs to be changed, please reach out to a Priori representative.`;
      }

      if (this.responderIsViewer && this.profileAccessRequest.firmApproved) {
        return `You have granted permissions for ${this.requestingCompanyName} to view your firm's profile data. Priori will kick off the data sharing process, which can take a few days to complete. If you have any questions, please reach out to a Priori representative.`;
      }

      return `Permission for users at ${this.requestingCompanyName} to view profile data has been ${this.profileAccessRequest.firmApproved ? 'granted' : 'denied'} by an admin on your team. If this needs to be changed, please reach out to a Priori representative.`;
    }
  },

  methods: {
    goToDashboard() {
      window.location.href = '/scout-firm/dashboard';
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  height: 100vh;

  .priori-brand-icon {
    width: 140px;
    height: 26px;
  }
}
</style>
