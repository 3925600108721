<template>
  <div class="dormant"></div>
</template>

<script>

export default {
  name: 'PriorityDormant'
};
</script>

<style scoped>
  .dormant {
    height: 4px;
    width: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #767676;
  }
</style>
