<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Open Projects
    </div>

    <div class="box-content normal-weight">
      <loading-section name="matters">
        <div v-for="matter in matters" :key="matter.id">
          <recent-project
            :matter="matter"
            :send-message-service="sendMessageService"
            :close-project="closeProject">
          </recent-project>

          <hr class="kenny-hr">
        </div>

        <div v-if="!hasMatters">
          <div class="top-30 bottom-50 gray-text tight-lines text-center">
            You have no recent projects. <a href="/lawyer-app/projects/new" class="dark-gray-text">Click here</a> to create one!
          </div>

          <hr class="kenny-hr">
        </div>
      </loading-section>

      <div class="view-all-container">
        <a href="/lawyer-app/projects" class="purple-text">View All Projects</a>
      </div>
    </div>
  </div>
</template>

<script>
import RecentProject from './recent-project.vue';

export default {
  name: 'RecentProjects',

  components: {
    RecentProject
  },

  props: {
    matters: {
      type: Array,
      default: () => []
    },

    sendMessageService: {
      type: Object,
      required: true
    },

    closeProject: {
      type: Function,
      required: true
    }
  },

  computed: {
    hasMatters() {
      return this.matters.length > 0;
    }
  }
};
</script>
