<template>
  <div class="upload-details">
    <div class="titlebar">
      <div class="medium-page-heading">
        FILE #{{ upload.id }}
      </div>

      <div class="row top-30">
        <div class="col-sm-3">
          <div class="bold-weight bottom-5">DATE</div>
          <div>{{ receivedDate }}</div>
        </div>

        <div class="col-sm-3 top-20-xs">
          <div class="bold-weight bottom-5">TIME</div>
          <div>{{ receivedTime }}</div>
        </div>

        <div class="col-sm-3 top-20-xs">
          <div class="bold-weight bottom-5">STATUS</div>
          <status-box :status-code="upload.status"></status-box>
        </div>

        <div class="col-sm-3 top-20-xs">
          <div class="bold-weight bottom-5"># ERRORS</div>
          <div>{{ errors.length }}</div>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-30">
            <a href="/scout-company/data-uploads" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to All Uploads</a>
          </div>

          <div class="resource-list">
            <div class="list-header">
              <div class="row tight-columns">
                <div class="col-sm-3">ERROR TYPE</div>
                <div class="col-sm-5 top-10-xs">ERROR MESSAGE</div>
                <div class="col-sm-4 top-10-xs">ERROR LOCATION</div>
              </div>
            </div>

            <div class="list-item" v-for="error in errors">
              <upload-error-item :error-data="error"></upload-error-item>
            </div>

            <div class="list-item empty gray-text size-text-16px" v-if="!hasErrors">
              No errors
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBox from 'vue-app/scout/shared/status-box.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import UploadErrorItem from 'vue-app/scout/shared/uploads/upload-error-item.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'DataUploadsShow',

  components: {
    StatusBox,
    SvgIcon,
    UploadErrorItem
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      upload: this.initData.upload
    };
  },

  computed: {
    receivedDate() {
      return DateFilter.filter(this.upload.receivedAt);
    },

    receivedTime() {
      return DateFilter.shortTime(this.upload.receivedAt);
    },

    errors() {
      return this.upload.lastRunErrors;
    },

    hasErrors() {
      return this.errors.length > 0;
    }
  }
};
</script>
