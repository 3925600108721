import CurrencyFilter from 'vue-app/shared/lib/currency-filter';

const currencyFilter = {
  methods: {
    formattedCurrency(amount) {
      return CurrencyFilter.filter(amount);
    }
  }
};

export default currencyFilter;
