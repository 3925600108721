<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Scout Permissions
    </div>

    <div class="box-content">
      <div class="bottom-15">
        <div class="semibold-weight bottom-5">
          Platforms
        </div>

        <div class="left-10">
          <div class="bottom-5">
            <span :class="['right-5', (lawyer.isScout ? 'green-text' : 'red-text')]">{{ lawyer.isScout ? '&#10004;' : '&#10005;' }}</span>Scout
          </div>

          <div>
            <span :class="['right-5', (lawyer.isMarketplace ? 'green-text' : 'red-text')]">{{ lawyer.isMarketplace ? '&#10004;' : '&#10005;' }}</span>Marketplace
          </div>
        </div>
      </div>

      <div>
        <div class="semibold-weight bottom-5">
          Permissions
        </div>

        <div class="left-10">
          <div class="bottom-5">
            <span :class="['right-5', (lawyer.hasVisibleProfile ? 'green-text' : 'red-text')]">{{ lawyer.hasVisibleProfile ? '&#10004;' : '&#10005;' }}</span>Has Profile
          </div>

          <div class="bottom-5">
            <span :class="['right-5', (lawyer.isEditor ? 'green-text' : 'red-text')]">{{ lawyer.isEditor ? '&#10004;' : '&#10005;' }}</span>Is Editor
          </div>

          <div class="bottom-5">
            <span :class="['right-5', (lawyer.isScoutAdmin ? 'green-text' : 'red-text')]">{{ lawyer.isScoutAdmin ? '&#10004;' : '&#10005;' }}</span>Is Admin
          </div>

          <div class="bottom-5">
            <span :class="['right-5', (lawyer.partner ? 'green-text' : 'red-text')]">{{ lawyer.partner ? '&#10004;' : '&#10005;' }}</span>Is Partner
          </div>

          <div>
            <span :class="['right-5', (lawyer.hasRfqEnabled ? 'green-text' : 'red-text')]">{{ lawyer.hasRfqEnabled ? '&#10004;' : '&#10005;' }}</span>RFP Enabled
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountPermissions',

  props: {
    lawyer: {
      type: Object,
      required: true
    }
  }
};
</script>
