<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="text-uppercase">
        Referral Information
      </div>
    </div>

    <div class="box-content symmetrical">
      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Referring URL
        </div>

        <div class="col-sm-8">
          {{ counselRequest.referringUrl || '&mdash;' }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Request Page URL
        </div>

        <div class="col-sm-8">
          {{ counselRequest.requestPageUrl || '&mdash;' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestReferralInfo',

  props: {
    counselRequest: {
      type: Object,
      required: true
    }
  }
};
</script>
