<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Client Feedback
    </div>

    <div class="box-content">
      <div v-for="rule in rules" :key="`feedback-${rule.name}`">
        <div class="size-text-13px bottom-5">
          {{ rule.label }}
          <span v-if="feedbackCountFor(rule.name) > 0">(x{{ feedbackCountFor(rule.name) }})</span>
        </div>

        <div class="row tight-columns">
          <div class="col-xs-6">
            <button type="button" :id="`${rule.name}-add`" class="feedback-button plus-sign right-5" @click="createScoringEvent(rule.name)"></button>

            <button type="button" :id="`${rule.name}-delete`" class="feedback-button minus-sign" @click="deleteScoringEvent(rule.name)" :disabled="!latestEventFor(rule.name)"></button>
          </div>

          <div class="col-xs-6 text-right">
            <div :class="['size-text-13px', textCSS(feedbackScoreFor(rule.name))]">
              {{ feedbackScoreFor(rule.name) > 0 ? '+' : '' }}{{ feedbackScoreFor(rule.name) }}
            </div>
          </div>
        </div>

        <div class="gray-text text-italic size-text-11px top-5" v-if="latestEventFor(rule.name)">
          Last applied on {{ parseDate(latestEventFor(rule.name).createdAt) }} by {{ latestEventFor(rule.name).createdBy }}
        </div>

        <div class="top-10 bottom-10">
          <hr class="kenny-hr no-margin">
        </div>
      </div>

      <div class="row tight-columns size-text-16px">
        <div class="col-xs-7">
          Total Score
        </div>

        <div class="col-xs-5 text-right">
          <span id="feedback-score-total" :class="textCSS(totalFeedbackScore)">{{ totalFeedbackScore > 0 ? '+' : '' }}{{ totalFeedbackScore }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import { sumBy } from 'lodash';

export default {
  name: 'AccountClientFeedback',

  props: {
    lawyerScoreService: {
      type:     Object,
      required: true
    }
  },

  computed: {
    rules() {
      return this.lawyerScoreService.rulesLike('feedback', 'client_work');
    },

    totalFeedbackScore() {
      return sumBy(this.lawyerScoreService.clientFeedbackScoring(), 'score');
    }
  },

  methods: {
    textCSS(value) {
      return {
        'green-text': value > 0,
        'red-text': value < 0
      };
    },

    scoreFor(ruleName) {
      return this.lawyerScoreService.scoreFor(ruleName);
    },

    feedbackCountFor(ruleName) {
      return this.scoreFor(ruleName)?.count || 0;
    },

    feedbackScoreFor(ruleName) {
      return this.scoreFor(ruleName)?.score || 0;
    },

    latestEventFor(ruleName) {
      return this.lawyerScoreService.latestEventFor(ruleName);
    },

    parseDate(date) {
      return DateFilter.filter(date);
    },

    createScoringEvent(ruleName) {
      this.lawyerScoreService.createScoringEvent(ruleName);
    },

    deleteScoringEvent(ruleName) {
      if (this.latestEventFor(ruleName)) {
        this.lawyerScoreService.deleteScoringEvent(this.latestEventFor(ruleName));
      }
    }
  }
};
</script>
