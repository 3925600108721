<template>
  <div>
    <div class="bottom-15">
      <div class="bold-weight size-text-14px">
        All Practice Areas
      </div>

      <div v-if="countAttributeIsCount && groupTotal > 0">
        <span class="bold-weight">Total {{ capitalizedGroup }}:</span> {{ formattedGroupTotal }}
      </div>
    </div>

    <div>
      <template v-for="(practiceAreasData, group) in collapsedOrAllFormattedPracticeAreas">
        <div class="size-text-12px list-spacing-20" :key="`grouped-practice-areas-${group}`" v-if="practiceAreasData.length > 0">
          <div class="bold-weight bottom-10">
            {{ group }}
          </div>

          <div class="d-flex wrap col-gap-15 row-gap-15">
            <multi-piece-tag
              v-for="practiceArea in sortByName(practiceAreasData)"
              :key="practiceArea.name"
              :text="practiceArea.name"
              :number="practiceArea[countAttribute]"
              :unit="tagUnit">
            </multi-piece-tag>
          </div>
        </div>
      </template>
    </div>

    <pseudo-link-button
      v-if="showShowAllButton"
      class="top-30"
      :text="showAllOrLess"
      :on-click="toggleShowAll">
    </pseudo-link-button>
  </div>
</template>

<script>
import { capitalize, sortBy, sumBy } from 'lodash';
import MultiPieceTag from 'src/vue-app/shared/components/multi-piece-tag.vue';
import PseudoLinkButton from 'src/vue-app/shared/components/pseudo-link-button.vue';

export default {
  name: 'FirmAllPracticeAreaTags',

  components: {
    MultiPieceTag,
    PseudoLinkButton
  },

  props: {
    practiceAreas: {
      type:     Array,
      required: true
    },

    selectedGroup: {
      type:     String,
      required: true
    },

    lawyerCountAttribute: {
      type:     String,
      default:  null
    },

    partnerCountAttribute: {
      type:     String,
      default:  null
    },

    collapsable: {
      type:     Boolean,
      default:  false
    }
  },

  data() {
    return {
      showAll: false
    };
  },

  computed: {
    countAttribute() {
      return this.selectedGroupIsLawyers ? this.lawyerCountAttribute : this.partnerCountAttribute;
    },

    countAttributeIsCount() {
      return this.lawyerCountAttribute === 'lawyerCount';
    },

    countAttributeIsPercent() {
      return this.lawyerCountAttribute === 'lawyerPercent';
    },

    tagUnit() {
      return this.countAttributeIsPercent ? '%' : null;
    },

    capitalizedGroup() {
      return capitalize(this.selectedGroup);
    },

    selectedGroupIsLawyers() {
      return this.selectedGroup === 'lawyers';
    },

    selectedGroupIsPartners() {
      return this.selectedGroup === 'partners';
    },

    showShowAllButton() {
      const practiceAreaExistsBeyondAF = this.formattedPracticeAreas['G–L'].length > 0 || this.formattedPracticeAreas['M–R'].length > 0 || this.formattedPracticeAreas['S–Z'].length > 0;
      return this.collapsable && practiceAreaExistsBeyondAF;
    },

    formattedPracticeAreas() {
      const practiceAreas = {
        'A–F': [],
        'G–L': [],
        'M–R': [],
        'S–Z': []
      };

      this.practiceAreas.forEach(practiceArea => {
        const code = practiceArea.name.charCodeAt(0);

        if (code >= 65 && code <= 70) {
          practiceAreas['A–F'].push(practiceArea);
        }
        else if (code >= 71 && code <= 76) {
          practiceAreas['G–L'].push(practiceArea);
        }
        else if (code >= 77 && code <= 82) {
          practiceAreas['M–R'].push(practiceArea);
        }
        else {
          practiceAreas['S–Z'].push(practiceArea);
        }
      });

      return practiceAreas;
    },

    collapsedFormattedPracticeAreas() {
      return {
        'A–F': this.formattedPracticeAreas['A–F'].slice(0, 20)
      };
    },

    collapsedOrAllFormattedPracticeAreas() {
      return this.collapsable && !this.showAll ? this.collapsedFormattedPracticeAreas : this.formattedPracticeAreas;
    },

    groupTotal() {
      return sumBy(this.practiceAreas, this.countAttribute);
    },

    formattedGroupTotal() {
      return this.groupTotal.toLocaleString();
    },

    showAllOrLess() {
      return this.showAll ? 'Show less' : 'Show all';
    }
  },

  methods: {
    sortByName(practiceAreas) {
      return sortBy(practiceAreas, (practiceArea) => practiceArea.name.toLowerCase());
    },

    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  }
};
</script>
