<template>
  <section class="uiv">
    <modal v-model="modalOpen" size="md-sm" :header="false" :footer="false" :backdrop="false" :keyboard="false" append-to-body>
      <modal-close-button :on-dismiss="cancel"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          {{ title }}
        </div>

        <div class="notice-regular-text">
          <loading-section name="updateLawyerProfilePhoto">
            <validation-observer v-slot="{ handleSubmit }" ref="validationObserver">
              <div v-if="showCropper" class="center-block">
                <img :src="imageUrl" class="img-responsive center-block" alt="Profile Photo">

                <div class="row top-15">
                  <div class="col-sm-4 col-sm-offset-2">
                    <label for="file-input-1" class="primary-btn-white right-10 clickable">
                      CHANGE
                    </label>

                    <input id="file-input-1" type="file" accept=".jpg,.jpeg,.gif,.png,.bmp" class="hidden" @change="onFileSelected">
                  </div>

                  <div class="col-sm-4">
                    <button type="button" @click="deleteProfilePhoto" class="primary-btn-white">DELETE</button>
                  </div>
                </div>
              </div>

              <div v-else-if="tempImageUrl" class="center-block">
                <vue-cropper
                  ref="cropper"
                  :aspect-ratio="1 / 1"
                  :zoomable="false"
                  :view-mode="1"
                  :min-crop-box-width="180"
                  :min-crop-box-height="180"
                  :max-crop-box-width="maxCropBoxWidth"
                  :max-crop-box-height="maxCropBoxHeight"
                  :toggle-drag-mode-on-dblclick="false"
                  @ready="onCropperReady"
                  :src="tempImageUrl">
                </vue-cropper>

                <div class="text-center">
                  <label for="file-input-2" class="secondary-button-compact top-15 clickable">
                    SELECT NEW IMAGE
                  </label>

                  <input id="file-input-2" type="file" accept=".jpg,.jpeg,.gif,.png,.bmp" class="hidden" @change="onFileSelected">
                </div>
              </div>

              <div v-else class="bottom-20">
                <div class="gray-text size-text-17px text-center top-20 bottom-30">
                  Please select an image to get started.
                </div>

                <div class="button-container">
                  <label for="file-input-3" class="primary-btn-white top-5 clickable">
                    SELECT IMAGE
                  </label>

                  <input id="file-input-3" type="file" accept=".jpg,.jpeg,.gif,.png,.bmp" class="hidden" @change="onFileSelected">
                </div>
              </div>

              <div v-if="showFooterButtons">
                <hr>

                <div class="row tight-columns">
                  <div class="col-sm-4">
                    <button type="button" class="primary-btn-night-blue" @click="handleSubmit(save)">Save</button>
                  </div>

                  <div class="col-sm-3 col-sm-offset-1 top-20-xs">
                    <button type="button" class="secondary-button-compact" @click="cancel">Cancel</button>
                  </div>
                </div>
              </div>

              <div class="error-text" v-if="hasError">
                {{ errorText }}
              </div>
            </validation-observer>
          </loading-section>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import { ValidationObserver } from 'vee-validate';
import VueCropper from 'vue-cropperjs';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import LawyerProfilePhoto from 'resources/lawyer-profile-photo';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'ProfilePhotoEditModal',

  components: {
    LoadingSection,
    ModalCloseButton,
    ValidationObserver,
    VueCropper
  },

  mixins: [
    modalToggle
  ],

  props: {
    profileId: {
      type: Number,
      default: null
    },

    title: {
      type: String,
      required: true
    },

    initialImageUrl: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      imageUrl: this.initialImageUrl,
      tempImageUrl: null,
      selectedFile: null,
      maxCropBoxWidth: 0,
      maxCropBoxHeight: 0,
      errorText: null
    };
  },

  computed: {
    hasError() {
      return this.errorText !== null;
    },

    showCropper() {
      return this.imageUrl && !this.tempImageUrl;
    },

    showFooterButtons() {
      return this.showCropper || this.tempImageUrl;
    }
  },

  methods: {
    deleteProfilePhoto() {
      LoadingService.loading('updateLawyerProfilePhoto');
      LawyerProfilePhoto.delete({ id: this.profileId })
        .then(() => {
          this.$emit('profile-photo-updated', null);
          this.tempImageUrl = null;
          this.imageUrl = null;
        })
        .catch(() => {
          this.errorText = 'Something went wrong while attempting to delete. Please try again.';
          this.tempImageUrl = null;
          this.imageUrl = this.initialImageUrl;
        })
        .finally(() => {
          LoadingService.done('updateLawyerProfilePhoto');
        });
    },

    onCropperReady() {
      const cropper = this.$refs.cropper.cropper;
      const imageData = cropper.getImageData();

      this.maxCropBoxWidth = imageData.naturalWidth;
      this.maxCropBoxHeight = imageData.naturalHeight;
    },

    save() {
      this.data = this.$refs.cropper.getCropBoxData();

      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const img = new Image();
        // Set the src attribute to a Blob URL created from the cropped canvas blob
        img.src = URL.createObjectURL(blob);

        // Wait for the image to load
        img.onload = () => {
          const cropBoxData = this.$refs.cropper.getCropBoxData();
          const canvasData = this.$refs.cropper.getCanvasData();
          const image = this.$refs.cropper.getImageData();
          const ratio = image.naturalWidth / canvasData.width;

          const x = cropBoxData.left * ratio;
          const y = cropBoxData.top * ratio;

          const lawyer_profile = {
            id: this.profileId,
            file: this.selectedFile,
            cropX: x,
            cropY: y,
            cropH: img.naturalHeight,
            cropW: img.naturalWidth
          };

          LoadingService.loading('updateLawyerProfilePhoto');
          LawyerProfilePhoto.postForm(lawyer_profile)
            .then((response) => {
              this.$emit('profile-photo-updated', response);
              this.tempImageUrl = null;
              this.imageUrl = response.photo.large.url;
              this.closeModal();
            })
            .catch(() => {
              this.errorText = 'Something went wrong with your upload. Please try again.';
              this.tempImageUrl = null;
              this.imageUrl = this.initialImageUrl;
            })
            .finally(() => {
              LoadingService.done('updateLawyerProfilePhoto');
            });
        };
      });
    },

    cancel() {
      this.tempImageUrl = null;
      this.imageUrl = this.initialImageUrl;
      this.selectedFile = null;
      this.$refs.validationObserver.reset();
      this.closeModal();
    },

    onFileSelected(event) {
      const reader = new FileReader();

      this.errorText = null;

      this.selectedFile = event.target.files[0];
      reader.readAsDataURL(this.selectedFile);

      // has an image already been uploaded?
      // if so we need to rebuild the cropper with .replace
      if (this.tempImageUrl) {
        reader.onload = (event) => {
          const cropper = this.$refs.cropper.cropper;

          this.tempImageUrl = reader.result;
          cropper.replace(event.target.result);
        };
      }
      else {
        reader.onload = () => {
          this.tempImageUrl = reader.result;
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .error-text {
    color: $error-red;
    font-size: 11px;
    font-weight: 600;
  }

  .button-container {
    margin: 0 auto;
    width: 150px;
  }
</style>
