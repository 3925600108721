<template>
  <editing-box
    title="E-Billing Information"
    :resource="billingNote"
    @on-edit-start="loadBillingApplications"
    :on-save="save">
    <template #default="{ isEditing: isEditing, resourceCopy: billingNoteCopy }">
      <div class="row bottom-30">
        <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
          <div class="col-sm-4 semibold-weight">
            Software<span class="red-text" v-if="isEditing"> *</span>

            <div class="error-text top-5" v-if="errors.length">
              <error-message :errors="errors"></error-message>
            </div>
          </div>

          <div class="col-sm-8">
            <div v-if="!isEditing">
              {{ billingApplicationName || '&mdash;' }}
            </div>

            <div class="top-10-xs" v-if="isEditing">
              <loading-section name="billingApplications">
                <dropdown-select
                  v-model="billingNoteCopy.billingApplicationId"
                  id-label="billing-note-software"
                  label-key="name"
                  value-key="id"
                  :initial-label="billingApplicationName"
                  :options="billingApplications"
                  :invalid="errors.length > 0">
                </dropdown-select>
              </loading-section>
            </div>
          </div>
        </validation-provider>
      </div>

      <div class="row bottom-30">
        <div class="col-sm-4 semibold-weight">
          Submission Requirements
        </div>

        <div class="col-sm-8">
          <div v-if="!isEditing">
            <span class="simple-text">{{ submissionRequirements || '&mdash;' }}</span>
          </div>

          <div class="top-10-xs" v-if="isEditing">
            <textarea name="billingNoteSubmissionRequirements" id="billing-note-submission-requirements" class="form-control" rows="3" v-model="billingNoteCopy.submissionRequirements"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 semibold-weight">
          Additional Notes
        </div>

        <div class="col-sm-8">
          <div v-if="!isEditing">
            <span class="simple-text">{{ additionalNotes || '&mdash;' }}</span>
          </div>

          <div class="top-10-xs" v-if="isEditing">
            <textarea name="billingNoteAdditionalNotes" id="billing-note-additional-notes" class="form-control" rows="3" v-model="billingNoteCopy.additionalNotes"></textarea>
          </div>
        </div>
      </div>
    </template>
  </editing-box>
</template>

<script>
import EditingBox from 'vue-app/admin/shared/editing-box.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import { ValidationProvider } from 'vee-validate';
import BillingApplication from 'resources/admin/billing-application';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { orderBy } from 'lodash';

export default {
  name: 'WorkspaceBillingNote',

  components: {
    EditingBox,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    billingNote: {
      type: Object,
      default: () => ({})
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      billingApplications: []
    };
  },

  computed: {
    billingApplicationName() {
      if (this.billingNote && this.billingNote.billingApplication) {
        return this.billingNote.billingApplication.name;
      }
      else {
        return null;
      }
    },

    submissionRequirements() {
      if (this.billingNote) {
        return this.billingNote.submissionRequirements;
      }
      else {
        return null;
      }
    },

    additionalNotes() {
      if (this.billingNote) {
        return this.billingNote.additionalNotes;
      }
      else {
        return null;
      }
    }
  },

  methods: {
    loadBillingApplications() {
      if (this.billingApplications.length === 0) {
        LoadingService.loading('billingApplications');

        BillingApplication.query().then((response) => {
          this.billingApplications = orderBy(response, 'name');
          LoadingService.done('billingApplications');
        });
      }
    },

    save(billingNoteCopy) {
      return this.onSave(billingNoteCopy);
    }
  }
};
</script>
