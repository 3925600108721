<template>
  <div class="editor">
    <div v-if="editor" :class="['toolbar', { 'has-error': hasError }]">
      <button type="button" v-if="showBold" @click="toggleBold()" :disabled="boldDisabled" :class="['button', { 'is-active': editor.isActive('bold') }]" title="Bold">
        <svg-icon name="text-bold" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showItalic" @click="toggleItalic()" :disabled="italicDisabled" :class="['button', { 'is-active': editor.isActive('italic') }]" title="Italic">
        <svg-icon name="text-italic" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showUnderline" @click="toggleUnderline()" :disabled="underlineDisabled" :class="['button', { 'is-active': editor.isActive('underline') }]" title="Underline">
        <svg-icon name="text-underline" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showStrike" @click="toggleStrike()" :disabled="strikeDisabled" :class="['button', { 'is-active': editor.isActive('strike') }]" title="Strike">
        <svg-icon name="strikethrough" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showLink" @click="setLink" :disabled="linkDisabled" :class="['button', { 'is-active': editor.isActive('link') }]" title="Link">
        <svg-icon name="add-link" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showHeading" @click="toggleHeading()" :disabled="headingDisabled" :class="['button', { 'is-active': editor.isActive('heading') }]" title="Heading">
        <svg-icon name="text-heading" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showQuote" @click="toggleQuote()" :disabled="quoteDisabled" :class="['button', { 'is-active': editor.isActive('quote') }]" title="Quote">
        <svg-icon name="text-quote" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showBulletList" @click="toggleBulletList()" :disabled="bulletListDisabled" :class="['button', { 'is-active': editor.isActive('bulletList') }]" title="Bullets">
        <svg-icon name="list-bullets" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showOrderedList" @click="toggleOrderedList()" :disabled="orderedListDisabled" :class="['button', { 'is-active': editor.isActive('orderedList') }]" title="Numbers">
        <svg-icon name="ordered-list" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showTextAlign" @click="toggleTextAlign('left')" :disabled="textAlignDisabled" :class="['button', { 'is-active': editor.isActive('textAlign') }]" title="Align Left">
        <svg-icon name="text-align-left" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showTextAlign" @click="toggleTextAlign('center')" :disabled="textAlignDisabled" :class="['button', { 'is-active': editor.isActive('textAlign') }]" title="Align Center">
        <svg-icon name="text-align-center" class="base-icon"></svg-icon>
      </button>

      <button type="button" v-if="showTextAlign" @click="toggleTextAlign('right')" :disabled="textAlignDisabled" :class="['button', { 'is-active': editor.isActive('textAlign') }]" title="Align Right">
        <svg-icon name="text-align-right" class="base-icon"></svg-icon>
      </button>

      <button type="button" @click="undoChanges()" :disabled="undoDisabled" class="button" title="Undo">
        <svg-icon name="undo" class="base-icon"></svg-icon>
      </button>

      <button type="button" @click="redoChanges()" :disabled="redoDisabled" class="button" title="Redo">
        <svg-icon name="redo" class="base-icon"></svg-icon>
      </button>
    </div>

    <editor-content :class="['content', { 'has-error': hasError }]" :editor="editor" spellcheck="false"></editor-content>
  </div>
</template>

<script>

import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { Editor, EditorContent } from '@tiptap/vue-2';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import BlockQuote from '@tiptap/extension-blockquote';
import TextAlign from '@tiptap/extension-text-align';

export default {
  name: 'TiptapEditor',

  components: {
    EditorContent,
    SvgIcon
  },

  props: {
    value: {
      type: String,
      default: null
    },

    buttons: {
      type: Array,
      default: () => [
        'bold',
        'italic',
        'underline',
        'bulletList',
        'orderedList'
      ]
    },

    hasError: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: 'Enter content'
    }
  },

  data() {
    const extensionMap = {
      bold: Bold,
      italic: Italic,
      underline: Underline,
      bulletList: BulletList,
      orderedList: OrderedList,
      strike: Strike,
      heading: Heading,
      quote: BlockQuote
    };

    return {
      editor: new Editor({
        content: this.value,

        // Initialize the editor with just the necessary extensions based on `buttons` prop.
        // Using the extensions bundled into the StarterKit (https://tiptap.dev/api/extensions/starter-kit) would achieve a similar result,
        // but it would add extra hidden formatting options we don't need with their corresponding keyboard shorcuts.
        // We want keyboard shorcuts to mirror the options in the UI.

        extensions: [
          ...this.buttons.map(button => (extensionMap[button])),
          Document,
          ListItem,
          Paragraph,
          Text,
          History,
          TextAlign.configure({
            types: ['heading', 'paragraph']
          }),
          Link.configure({
            openOnClick: false,
            defaultProtocol: 'https'
          }),
          Placeholder.configure({
            emptyEditorClass: 'is-editor-empty',
            placeholder: this.placeholder
          })
        ],

        onUpdate: () => {
          // sanitize html here
          this.$emit('input', this.$sanitize(this.editor.getHTML()));
        },

        editorProps: {
          attributes: {
            class: 'edit-area'
          }
        }
      })
    };
  },

  computed: {
    boldDisabled() {
      return !this.editor.can().chain().focus().toggleBold().run();
    },

    italicDisabled() {
      return !this.editor.can().chain().focus().toggleItalic().run();
    },

    underlineDisabled() {
      return !this.editor.can().chain().focus().toggleUnderline().run();
    },

    bulletListDisabled() {
      return !this.editor.can().chain().focus().toggleBulletList().run();
    },

    orderedListDisabled() {
      return !this.editor.can().chain().focus().toggleOrderedList().run();
    },

    undoDisabled() {
      return !this.editor.can().chain().focus().undo().run();
    },

    redoDisabled() {
      return !this.editor.can().chain().focus().redo().run();
    },

    strikeDisabled() {
      return !this.editor.can().chain().focus().toggleStrike().run();
    },

    linkDisabled() {
      return !this.editor.can().chain().focus().toggleLink().run();
    },

    headingDisabled() {
      return !this.editor.can().chain().focus().toggleHeading({ level: 1 }).run();
    },

    quoteDisabled() {
      return !this.editor.can().chain().focus().toggleBlockquote().run();
    },

    textAlignDisabled() {
      return !this.editor.can().chain().focus().setTextAlign('right').run();
    },

    showBold() {
      return this.buttons.includes('bold');
    },

    showItalic() {
      return this.buttons.includes('italic');
    },

    showUnderline() {
      return this.buttons.includes('underline');
    },

    showBulletList() {
      return this.buttons.includes('bulletList');
    },

    showOrderedList() {
      return this.buttons.includes('orderedList');
    },

    showStrike() {
      return this.buttons.includes('strike');
    },

    showLink() {
      return this.buttons.includes('link');
    },

    showHeading() {
      return this.buttons.includes('heading');
    },

    showQuote() {
      return this.buttons.includes('quote');
    },

    showTextAlign() {
      return this.buttons.includes('textAlign');
    }
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) { return; }

      this.editor.commands.setContent(value, false);
    }
  },

  methods: {
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },

    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },

    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },

    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },

    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },

    undoChanges() {
      this.editor.chain().focus().undo().run();
    },

    redoChanges() {
      this.editor.chain().focus().redo().run();
    },

    toggleStrike() {
      this.editor.chain().focus().toggleStrike().run();
    },

    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      // update link
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();

      // this.editor.chain().focus().toggleLink({ href: 'https://example.com' }).run();
    },

    toggleHeading() {
      this.editor.chain().focus().toggleHeading({ level: 1 }).run();
    },

    toggleQuote() {
      this.editor.chain().focus().toggleBlockquote().run();
    },

    toggleTextAlign(direction) {
      this.editor.chain().focus().setTextAlign(direction).run();
    }
  }
};

</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  $border-button-color: #e5e9ec;

  .editor {
    .toolbar {
      padding: 15px;
      border: 1px solid $k-gray;
      border-top-left-radius: $border-radius-large;
      border-top-right-radius: $border-radius-large;
      background-color: #f0f4f6;

      .button {
        width: 30px;
        height: 30px;
        border: 1px solid $border-button-color;
        border-radius: $border-radius-base;
        background-color: $white;

        &:hover {
          border: 1px solid $k-blue;
        }

        &.is-active {
          border: 1px solid $k-blue;
        }

        &:disabled {
          &:hover {
            border: 1px solid $border-button-color;
          }

          svg {
            opacity: .2;
          }
        }

        svg {
          display: block;
          width: 14px;
          height: 14px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    .content {
      border: 1px solid $k-gray;
      border-top: none;
      border-bottom-left-radius: $border-radius-large;
      border-bottom-right-radius: $border-radius-large;
      padding: 15px;
    }
  }

  :deep(.edit-area) {
    line-height: 20px;
    outline: none;
    min-height: 30px;

    ul, ol {
      margin-top: 0;
      margin-bottom: 9.5px;
      padding-inline-start: 16px;
    }

    p.is-editor-empty:first-child::before {
      color: $k-dark-gray !important;
      font-size: 13px;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
</style>
