<template>
  <div class="response-group">
    <div :class="['response-group-title vertical-center', { 'disabled': disableExpand }]" @click="toggleExpanded()">
      <div class="flex-1">
        <span class="response-group-title-text">{{ title }}</span> ({{ groupCount }})
      </div>

      <div>
        <svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded rotate-90': isExpanded }]"></svg-icon>
      </div>
    </div>

    <div class="bottom-15" v-if="isExpanded">
      <div v-if="showEmptyState && !hasQuotes">
        <slot name="emptyState"></slot>
      </div>

      <div v-else>
        <name-card
          v-for="quote in quotes"
          :key="quote.id"
          class="list-spacing-10"
          @change-selected-quote="changeSelectedQuote(quote.id)"
          :quote="quote"
          :rfq="rfq"
          :selected-quote="selectedQuote">
        </name-card>

        <a v-if="showCompareButton && hasComparableQuotes" :href="`/scout-company/rfps/${rfq.id}/comparison`" class="primary-btn-blue smaller top-15" target="_blank">Compare Responses</a>
      </div>
    </div>
  </div>
</template>

<script>
import NameCard from 'vue-app/scout/client/rfqs/name-card.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ReviewResponseGroup',

  components: {
    NameCard,
    SvgIcon
  },

  props: {
    quotes: {
      type: Array,
      default: () => []
    },

    rfq: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    selectedQuote: {
      type: Object
    },

    showEmptyState: {
      type: Boolean,
      default: false
    },

    showCompareButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isExpanded: this.isExpandedDefault()
    };
  },

  computed: {
    disableExpand() {
      return !this.hasQuotes && !this.showEmptyState;
    },

    hasQuotes() {
      return this.quotes.length > 0;
    },

    groupCount() {
      return this.quotes.length;
    },

    hasComparableQuotes() {
      return this.rfq.hasComparableQuotes();
    }
  },

  methods: {
    toggleExpanded() {
      if (this.disableExpand) { return; }
      this.isExpanded = !this.isExpanded;
    },

    changeSelectedQuote(id) {
      this.$emit('change-selected-quote', id);
    },

    isExpandedDefault() {
      if (this.quotes.length > 0 && this.selectedQuote) {
        return this.quotes.map(quote => quote.id).includes(this.selectedQuote.id);
      }
      else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .response-group {
    border-top: 1px solid $medium-gray;

    &:last-child {
      border-bottom: 1px solid $medium-gray;
    }

    .response-group-title {
      padding-top: 15px;
      padding-bottom: 15px;
      cursor: pointer;
      user-select: none;

      &:hover .response-group-title-text {
        text-decoration: underline;
      }

      &.disabled {
        cursor: default;
        opacity: .5;

        &:hover .response-group-title-text {
          text-decoration: none;
        }
      }
    }
  }
</style>
