<template>
  <div class="shadowed-box settings-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-7 text-uppercase">
          Search Priori’s Network
        </div>

        <div class="col-sm-5 text-right-not-xs">
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            Allow users to expand searches with Priori’s Marketplace?
          </div>

          <div class="dark-gray-text">
            Scout enables in-house users to send searches into Priori’s Marketplace product as needed
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          <span v-if="allowMarketplace">On</span>
          <span v-else>Off</span>
        </div>
      </div>

      <hr>

      <div class="row tight-columns">
        <div class="col-sm-8">
          <div class="size-text-14px bold-weight bottom-5">
            Notify all admins of this settings change?
          </div>

          <div class="dark-gray-text">
            If an admin makes changes to Marketplace availability, Scout enables notifications to be sent automatically
          </div>
        </div>

        <div class="col-sm-4 text-right-not-xs">
          Off
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MarketplaceSearchSettings',

  props: {
    allowMarketplace: {
      type: Boolean,
      default: false
    }
  }
};
</script>
