<template>
  <div v-if="currentLawyer.hasRfqEnabled">
    <div class="bottom-20">
      <span class="semibold-weight">{{ totalCount }}</span>
      <span>{{ pluralizedResultsString }}</span>
    </div>

    <div class="resource-list">
      <div class="list-header">
        <div class="row tight-columns">
          <div class="col-sm-7">
            <div class="row tight-columns">
              <div v-for="(column, index) in columnGroupOne" :class="[column.class, 'vertical-center', { 'top-10-xs': index > 0 }]">
                <dynamic-order-arrows
                  :order="order"
                  :order-by="column.key"
                  :initial-order="initialSortOrder(column.key)"
                  :on-sort="sort"
                  :reverse="!!column.reverse"
                  v-if="column.sortable">
                  {{ column.label }}
                </dynamic-order-arrows>
              </div>
            </div>
          </div>

          <div class="col-sm-5">
            <div class="row tight-columns">
              <div v-for="column in columnGroupTwo" :class="[column.class, 'vertical-center top-10-xs']">
                <dynamic-order-arrows
                  :order="order"
                  :order-by="column.key"
                  :initial-order="initialSortOrder(column.key)"
                  :on-sort="sort"
                  :reverse="!!column.reverse"
                  v-if="column.sortable">
                  {{ column.label }}
                </dynamic-order-arrows>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasQuotes">
        <div class="list-item" v-for="quote in quotes" :key="quote.id">
          <div :class="['row tight-columns vertical-center-not-xs', { 'bold-weight': shouldBeBold(quote) }]">
            <div class="col-sm-7">
              <div class="row tight-columns vertical-center-not-xs">
                <div class="col-sm-3">
                  <div>
                    {{ dateReceivedText(quote) || '—' }}
                  </div>

                  <div v-if="quote.rfqLatestActivity && latestActivityAfterReceivedDate(quote)" class="dark-gray-text italic size-text-12px text-italic">
                    {{ updatedText(quote) }}
                  </div>
                </div>

                <div class="col-sm-6 top-10-xs">
                  <div class="text-ellipsis">
                    <a :href="quotePath(quote)" class="bold-weight">{{ quoteName(quote) }}</a>
                  </div>

                  <div>
                    Reference #{{ quote.rfqId }}
                  </div>
                </div>

                <div class="col-sm-3 top-10-xs">
                  <div class="text-ellipsis">{{ quote.rfqCreatorFullName }}</div>
                  <div class="text-ellipsis">{{ quote.rfqWorkspaceName }}</div>
                </div>
              </div>
            </div>

            <div class="col-sm-5">
              <div class="row tight-columns vertical-center-not-xs">
                <div :class="['col-sm-4 top-10-xs', { 'red-text': shouldBeRed(quote) }]">
                  {{ formatDate(quote.rfqDueDate) || '—' }}
                </div>

                <div class="col-sm-6 top-10-xs">
                  <status-badge :quote="quote"></status-badge>

                  <div v-if="quote.unreadMessagesCount" class="top-5">
                    <span class="icon-wrapper right-5">
                      <svg-icon name="envelope" class="base-icon icon"></svg-icon>
                    </span>

                    <div class="inline-block pill-gray-text normal-weight size-text-12px">
                      {{ $pluralize('New Message', quote.unreadMessagesCount, true) }}
                    </div>
                  </div>
                </div>

                <div class="col-sm-2 bold-weight text-right-not-xs">
                  <a :href="quotePath(quote)" class="right-7" v-if="quote.status === 'draft'">Edit</a>
                  <a :href="quotePath(quote)" v-else>View</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <priori-pagination
          class="scout-pagination top-15"
          :current-page="currentPage"
          :total-entries="totalCount"
          :per-page="perPage"
          @change="changePage">
        </priori-pagination>
      </div>

      <div v-else>
        <div class="list-item empty gray-text size-text-16px">
          No Results
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <div class="provision-rfq-access-wrapper">
        <div class="provision-rfq-access">
          <div class="icon">
            <svg-icon name="check-and-dollar" class="icon-lg"></svg-icon>
          </div>
          <div class="provision-rfq-access-text">You are not currently provisioned to view RFPs in Scout.</div>
          <div class="request-access-text">
            <span>To request access, please </span>
            <span>
              <priori-modal class="inline-block" ref="modal" title="Administrator Contact Information" modal-id="contact-info">
                <template #modal-trigger="{ openModal }">
                  <a href="" class="size-text-12px" @click.prevent="openModal">contact your firm administrators.</a>
                </template>

                <template #default="{ closeModal }">
                  <div class="top-5 size-text-14px bold-weight contact-title">
                    Contact
                  </div>
                  <div class="top-15" v-for="lawFirmAdmin in lawFirmAdmins" :key="lawFirmAdmin.email">
                    <div class="size-text-15px">{{ lawFirmAdmin.fullName }}</div>
                    <div class="semibold-weight size-text-14px">{{ lawFirmAdmin.email }}</div>
                  </div>

                  <div>
                    <button type="button" class="top-30 secondary-button-compact" @click="closeModal()">Close</button>
                  </div>
                </template>
              </priori-modal>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import dateComparisons from 'vue-app/shared/mixins/date-comparisons.js';

import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';
import DynamicOrderArrows from 'vue-app/shared/components/dynamic-order-arrows.vue';
import StatusBadge from './status-badge.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';

export default {
  name: 'RFQLawFirmQuotesIndexTable',

  components: {
    DynamicOrderArrows,
    StatusBadge,
    SvgIcon,
    PrioriModal,
    PrioriPagination
  },

  mixins: [dateComparisons],

  props: {
    quotes: {
      type: Array,
      required: true
    },

    currentPage: {
      type: Number,
      required: true
    },

    changePage: {
      type: Function,
      required: true
    },

    totalCount: {
      type: Number,
      required: true
    },

    onSort: {
      type: Function,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    },

    workspaceId: {
      type: Number,
      required: false
    },

    lawFirmAdmins: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      order: { 'date_received': 'desc' }
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentLawyer: 'currentUser' }),

    columnGroupOne() {
      return [
        { key: 'date_received', label: 'Received', class: 'col-sm-3', sortable: true, reverse: false },
        { key: 'rfq_id', label: 'RFP Name', class: 'col-sm-6', sortable: true, reverse: true },
        { key: 'rfq_creator_full_name', label: 'Created By', class: 'col-sm-3', sortable: true, reverse: true }
      ];
    },

    columnGroupTwo() {
      return [
        { key: 'rfq_due_date', label: 'Due', class: 'col-sm-4', sortable: true, reverse: false },
        { key: 'status', label: 'Status', class: 'col-sm-6', sortable: true, reverse: true },
        { key: 'actions', label: '', class: 'col-sm-2', sortable: false }
      ];
    },

    hasQuotes() {
      return this.quotes.length > 0;
    },

    pluralizedResultsString() {
      return this.$pluralize('result', this.totalCount);
    }
  },

  methods: {
    dateReceivedText(quote) {
      return DateFilter.filter(quote.dateReceived);
    },

    updatedText(quote) {
      return `Updated ${DateFilter.filter(quote.rfqLatestActivity.createdAt)}`;
    },

    quoteName(quote) {
      return quote.rfqName || `Reference #${quote.rfqId}`;
    },

    latestActivityAfterReceivedDate(quote) {
      return this.dateIsAfter(quote.rfqLatestActivity.createdAt, quote.dateReceived);
    },

    formatDate(rfqDate) {
      return DateFilter.filterDate(rfqDate);
    },

    shouldBeBold(quote) {
      return this.isNew(quote) && this.rfqIsOpen(quote);
    },

    shouldBeRed(quote) {
      return this.rfqIsOpen(quote) && this.isPastDue(quote) && this.isNewOrDraft(quote);
    },

    rfqIsOpen(quote) {
      return quote.rfqStatus !== 'closed';
    },

    isNew(quote) {
      return quote.status === 'new_rfq';
    },

    isNewOrDraft(quote) {
      return ['new_rfq', 'draft'].includes(quote.status);
    },

    isPastDue(quote) {
      return moment().isAfter(quote.rfqDueDate);
    },

    initialSortOrder(sortBy) {
      return {
        'date_received': 'desc',
        'rfq_id': 'asc',
        'rfq_creator_full_name': 'asc',
        'rfq_workspace_name': 'asc',
        'rfq_due_date': 'desc',
        'status': 'asc'
      }[sortBy];
    },

    quotePath(quote) {
      if (this.workspaceId) {
        return `/scout-firm/clients/${this.workspaceId}/rfps/${quote.id}`;
      }
      else {
        return `/scout-firm/rfps/${quote.id}`;
      }
    },

    sort(sortBy) {
      let sortOrder;

      if (this.order[sortBy]) {
        sortOrder = this.order[sortBy] === 'asc' ? 'desc' : 'asc';
      }
      else {
        sortOrder = this.initialSortOrder(sortBy);
      }

      this.order = { [sortBy]: sortOrder };
      this.onSort({ sort_by: sortBy, sort_direction: sortOrder });
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .provision-rfq-access-wrapper {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .provision-rfq-access {
      padding: 20px 30px;
      background: $gray-8;
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: $screen-sm-min) {
        padding: 40px 64px;
      }

      .icon {
        display: flex;
        background: $newblue-10;
        border-radius: 100px;
      }

      .icon-lg {
        width: 64px;
        height: 64px;
      }
    }

    .provision-rfq-access-text {
      padding: 0 0 5px;
      margin-top: 20px;
      color: $gray-1;
      font-size: 15px;
      font-weight: 700;
      word-wrap: break-word;

      @media (min-width: $screen-sm-min) {
        padding: 0 20px 5px;
        text-align: center;
      }
    }

    .request-access-text {
      color: $gray-1;
      font-size: 13px;
      font-style: italic;
      font-weight: 400;
    }
  }

  .contact-title {
    border-top: 1px solid $gray-8;
    border-bottom: 1px solid $gray-8;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .icon-wrapper {
    position: relative;
    width: 22px;

    .base-icon {
      width: 18px;
      height: 18px;
      fill: $k-dark-gray;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: 0;
      right: -4px;
      border-radius: 50%;
      background-color: $scout-red;
    }
  }
</style>
