<template>
  <editing-box
    id="anonymized-project-description"
    :title="'Anonymized Project Description'"
    :resource="rfq"
    :on-save="onSave">
    <template #default="{ isEditing, resourceCopy: rfqCopy }">
      <div v-if="!isEditing">
        <span class="simple-text">{{ rfq.anonymizedDescriptionOfWork || '&mdash;' }}</span>
      </div>

      <div class="top-10-xs" v-if="isEditing">
        <textarea
          name="anonymizedDescriptionOfWork"
          id="anonymized-description-of-work"
          class="form-control"
          rows="3"
          v-model="rfqCopy.anonymizedDescriptionOfWork">
        </textarea>
      </div>
    </template>
  </editing-box>
</template>

<script>
import EditingBox from 'vue-app/admin/shared/editing-box.vue';

export default {
  name: 'RfqAnonymizedProjectDescription',
  components: {
    EditingBox
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  }
};
</script>
