<template>
  <div>
    <notification-banner v-if="totalUnreadMessageCount" icon="envelope" variant="info" class="bottom-30">
      {{ totalUnreadMessageCount }} New {{ $pluralize('Message', totalUnreadMessageCount) }}
      <button type="button" class="left-10 pseudo-link-blue bold-weight" @click="onSelectTab('responses')">View Messages</button>
    </notification-banner>

    <status-banner icon="checkmark" variant="selected" class="bottom-30" v-if="hasSelectedQuotes">
      <div class="green-text text-uppercase bold-weight">
        Selected Firms
      </div>

      <div class="bold-weight">
        <span v-for="(quote, index) in selectedQuotes"><a href="" @click.prevent="viewQuote(quote)">{{ quote.lawFirmBridge.name }}</a><span v-if="index < selectedQuotes.length - 1">, </span></span>
      </div>
    </status-banner>

    <div class="shadowed-box bottom-30">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-6 text-uppercase">
            Quote Comparison
          </div>

          <div class="col-sm-6 text-right-not-xs d-flex-not-xs flex-end col-gap-30 normal-weight size-text-12px">
            <div v-if="!onlySentToMarketplace">
              <div class="dot blue"></div>

              <span>
                Your Firms
              </span>
            </div>

            <div v-if="rfq.sentToMarketplace">
              <div class="dot purple"></div>

              <span>
                Priori&rsquo;s Marketplace Network
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="quote-compare-wrapper symmetrical">
        <div v-if="hasLawFirmQuotes">
          <div class="row">
            <div class="col-sm-6">
              <div class="height-100">
                <div class="bottom-20 bold-weight text-center">
                  Total Estimated Legal Fees
                </div>
              </div>

              <budget-chart
                :legal-fee-question-included="legalFeeQuestionIncluded"
                :rendered-quotes="renderedQuotes"
                :all-quotes="rfq.lawFirmQuotes"
                :rfq="rfq"
                :view-quote="viewQuote">
              </budget-chart>
            </div>

            <div class="col-sm-6 top-20-xs">
              <div class="height-100">
                <div class="bottom-20 bold-weight text-center">
                  Included Hourly Rate
                </div>
              </div>

              <hourly-rate-chart
                :fee-structure-question-included="feeStructureQuestionIncluded"
                :rendered-quotes="renderedQuotes"
                :all-quotes="rfq.lawFirmQuotes"
                :rfq="rfq"
                :view-quote="viewQuote">
              </hourly-rate-chart>
            </div>
          </div>

          <div class="row" v-if="showDisplayToggle">
            <div class="col-sm-12 text-center">
              <a href="" class="semibold-weight" @click.prevent="toggleShowAll">{{ showAllOrFewer }}</a>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="text-center missing-text">
            No quotes found for this RFP
          </div>
        </div>

        <div v-if="hasComparableQuotes" class="row top-30">
          <div class="col-sm-4 col-sm-offset-4">
            <a :href="`/scout-company/rfps/${rfq.id}/comparison`" class="primary-btn-blue smaller" target="_blank">Compare Responses</a>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-30">
      <rfq-recipients
        :rfq="rfq"
        :available-firms="availableFirms"
        :can-edit-rfq="canEditRfq"
        :only-sent-to-marketplace="onlySentToMarketplace"
        :add-recipients="addRecipients"
        :send-to-marketplace="sendToMarketplace"
        :view-quote="viewQuote">
      </rfq-recipients>
    </div>

    <div class="row">
      <div class="col-sm-8">
        <rfq-project-details
          class="bottom-30"
          :update-project-details="updateProjectDetails"
          :can-edit-rfq="canEditRfq"
          :rfq="rfq">
        </rfq-project-details>

        <div class="shadowed-box">
          <div class="box-header">
            <div class="row tight-columns">
              <div class="col-sm-11">
                <div class="text-uppercase">
                  Law Firm Questions
                </div>
              </div>

              <div v-if="canEditRfq" class="col-sm-1 text-right-not-xs">
                <edit-rfq-questions
                  :rfq="rfq"
                  :update-questions="updateQuestions">
                </edit-rfq-questions>
              </div>
            </div>
          </div>

          <div class="box-content symmetrical">
            <div v-for="group in orderedQuestionGroups" :key="group.id" class="list-spacing-30">
              <div class="bold-weight bottom-5">
                {{ group.name }}
              </div>

              <div v-for="question in orderedQuestionsFor(group)" :key="question.id" class="list-spacing-5">
                {{ question.questionText }} <span v-if="question.isRequired" class="red-text">*</span>
              </div>
            </div>

            <div class="missing-text size-text-14px" v-if="!hasIncludedQuestions">
              No questions added
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 top-30-xs">
        <div class="shadowed-box">
          <div class="box-header text-uppercase">
            Updates
          </div>

          <div class="box-content symmetrical">
            <rfq-team-activity
              v-if="hasActivity"
              :activities="rfq.activities">
            </rfq-team-activity>

            <div v-else class="missing-text">
              No activity found for this RFP
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BudgetChart from 'vue-app/scout/client/rfqs/budget-chart.vue';
import HourlyRateChart from 'vue-app/scout/client/rfqs/hourly-rate-chart.vue';
import StatusBanner from 'vue-app/scout/shared/rfqs/status-banner.vue';
import EditRfqQuestions from 'vue-app/scout/client/rfqs/edit-rfq-questions.vue';
import RfqTeamActivity from 'vue-app/scout/client/rfqs/team-activity.vue';
import RfqProjectDetails from 'vue-app/scout/client/rfqs/rfq-project-details.vue';
import RfqRecipients from 'vue-app/scout/client/rfqs/rfq-recipients.vue';
import NotificationBanner from 'vue-app/shared/components/notification-banner.vue';
import { orderBy, groupBy, some, partition, compact, flatMap } from 'lodash';

export default {
  name: 'RfqOverview',

  components: {
    BudgetChart,
    HourlyRateChart,
    StatusBanner,
    EditRfqQuestions,
    RfqTeamActivity,
    RfqProjectDetails,
    RfqRecipients,
    NotificationBanner
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    updateProjectDetails: {
      type: Function,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    addRecipients: {
      type: Function,
      default: () => {}
    },

    canEditRfq: {
      type: Boolean,
      required: true
    },

    onlySentToMarketplace: {
      type: Boolean,
      required: true
    },

    updateQuestions: {
      type: Function,
      required: true
    },

    sendToMarketplace: {
      type: Function,
      required: true
    },

    viewQuote: {
      type: Function,
      required: true
    },

    onSelectTab: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      statusColorMap: {
        quoteReceived: '#2331bd', // $high-match-score-blue
        pendingResponse: '#b8b8b8', // $scout-gray
        declined: '#cd034c' // $scout-red
      },

      statusLabelMap: {
        quoteReceived: 'Quote Received',
        pendingResponse: 'Pending Response',
        declined: 'Declined'
      },

      showAll: true,
      initialDisplayCount: 7
    };
  },

  computed: {
    lawFirmQuotes() {
      return this.rfq.lawFirmQuotes || [];
    },

    sortedLawFirmQuotes() {
      const groups = groupBy(this.lawFirmQuotes, quote => {
        if (quote.status === 'declined_by_firm') {
          return 'declined';
        }
        else if (quote.feeStructure?.value === 'alternative_fee') {
          return 'receivedAlternativeFee';
        }
        else if (quote.suggestedLawyers.some(lawyer => lawyer.proposedHourlyRate !== undefined)) {
          return 'received';
        }
        else if (quote.status === 'draft' || quote.status === 'new_rfq') {
          return 'pendingResponse';
        }
        else if (!quote.suggestedLawyers.length) {
          return 'receivedNoSuggestedLawyers';
        }
        else if (!this.feeStructureQuestionIncluded) {
          return 'receivedPricingDataNotRequested';
        }
        else {
          return 'unclassified';
        }
      });

      const statusOrder  = ['received', 'receivedAlternativeFee', 'receivedPricingDataNotRequested', 'receivedNoSuggestedLawyers', 'pendingResponse', 'declined', 'unclassified'];
      const sortedQuotes = compact(flatMap(statusOrder, status => groups[status]));

      return partition(sortedQuotes, quote => !quote.fromMarketplace).flat();
    },

    renderedQuotes() {
      return this.showAll ? this.sortedLawFirmQuotes : this.sortedLawFirmQuotes.slice(0, this.initialDisplayCount);
    },

    hasLawFirmQuotes() {
      return this.lawFirmQuotes?.length > 0;
    },

    hasComparableQuotes() {
      return this.rfq.hasComparableQuotes();
    },

    selectedQuotes() {
      return this.rfq.lawFirmQuotes.filter(q => q.status === 'selected_by_client');
    },

    hasSelectedQuotes() {
      return this.selectedQuotes.length > 0;
    },

    hasActivity() {
      return this.rfq.activities?.length > 0;
    },

    quoteCount() {
      return this.lawFirmQuotes.length;
    },

    showAllOrFewer() {
      return this.showAll ? 'Show less' : 'Show all';
    },

    showDisplayToggle() {
      return this.quoteCount > this.initialDisplayCount;
    },

    legalFeeQuestionIncluded() {
      return this.hasQuestion('estimated_legal_fees');
    },

    feeStructureQuestionIncluded() {
      return this.hasQuestion('fee_structure');
    },

    groupsWithQuestions() {
      return this.rfq.questionGroups.filter(group => group.questions.some(question => question.isIncluded && !question.prerequisiteQuestionId));
    },

    hasIncludedQuestions() {
      return this.groupsWithQuestions.length > 0;
    },

    orderedQuestionGroups() {
      return orderBy(this.groupsWithQuestions, 'position');
    },

    allIncludedQuestions() {
      return flatMap(this.rfq.questionGroups, questionGroup => questionGroup.questions).filter(question => question.isIncluded);
    },

    totalUnreadMessageCount() {
      return Object.values(this.rfq.unreadMessageCountsPerQuote).reduce((total, count) => total + count, 0);
    }
  },

  methods: {
    orderedQuestionsFor(group) {
      return orderBy(group.questions.filter(q => q.isIncluded && !q.prerequisiteQuestionId), 'position');
    },

    hasQuestion(shortname) {
      return some(this.allIncludedQuestions, (question) => question.shortname === shortname);
    },

    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .quote-compare-wrapper {
    padding: 30px 55px;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

    &.blue {
      background: $high-match-score-blue;
    }

    &.purple {
      background: $k-purple;
    }
  }
</style>
