import BaseResource from 'resources/base-resource';

class ResourceList extends BaseResource {
  static baseUrl = '/scout/resource_lists/:id.json';

  isLawyerList() {
    return this.resourceType === 'LawyerBridge';
  }

  isLawFirmList() {
    return this.resourceType === 'LawFirmBridge';
  }

  isSharedWith(recipient) {
    return _.some(this.shares || [], { recipientType: recipient.klass, recipientId: recipient.id });
  }

  isCreatedBy(user) {
    return this.createdBy.klass === user.klass && this.createdBy.id === user.id;
  }
}

export default ResourceList;
