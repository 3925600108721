<template>
  <div>
    <div v-for="score in positiveScores" :key="score.field" class="category-wrapper">
      <div class="category-name tight-lines">
        {{ score.field }}
      </div>

      <div class="rating-bar-container">
        <rating-bar
          :rating="score.value"
          :max-rating="5"
          :legend-name="legendItemFor(score).legend"
          fill-color-class="purple-background">
        </rating-bar>
      </div>
    </div>
  </div>
</template>

<script>
import RatingBar from './rating-bar.vue';
import { compact, filter } from 'lodash';

export default {
  name: 'PerformanceScoreWithLeftLabel',

  components: {
    RatingBar
  },

  props: {
    scores: {
      type: Array,
      required: true
    },

    legend: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    positiveScores() {
      return compact(filter(this.scores, (score) => {
        return score.value > 0;
      }));
    }
  },

  methods: {
    legendItemFor(score) {
      return this.legend.find(legendItem => legendItem.value === score.value);
    }
  }
};
</script>

<style lang="scss" scoped>
  .category-wrapper:not(:last-child) {
    margin-bottom: 15px;
  }

  .category-name {
    margin-bottom: 5px;
  }

  @media (min-width: 992px) {
    .category-wrapper {
      align-items: center;
      display: flex;
    }

    .category-name {
      margin-bottom: 0;
      overflow: hidden;
      text-align: right;
      text-overflow: ellipsis;
      width: 220px;
    }

    .rating-bar-container {
      margin-left: 15px;
      width: calc(100% - 205px);
    }
  }
</style>
