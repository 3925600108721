<template>
  <validation-observer v-slot="{ handleSubmit }">
    <form role="form" name="form" class="a-form" novalidate>
      <div class="bottom-20">
        <label for="dollars">Amount $ <span class="inline-help required">*</span></label>

        <validation-provider rules="notBlank|minValue:0|maxValue:9999999" v-slot="{ errors }">
          <input type="number" name="dollars" id="dollars" class="form-control" min="0" step="0.01" placeholder="$" v-model="newCredit.dollars">

          <div class="error-text top-5" v-if="errors.length">
            <error-message :errors="errors"></error-message>
          </div>
        </validation-provider>
      </div>

      <div class="bottom-30">
        <label for="management-fee-only" class="check-option vertical">
          <span class="normal-weight">Management Fee Only</span>
          <input type="checkbox" id="management-fee-only" name="managementFeeOnly" v-model="newCredit.managementFeeOnly">
          <span class="check c-box"></span>
        </label>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <button type="button" class="nv-button-blue a-button-size" @click="handleSubmit(save)">Save</button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'NewCreditForm',

  components: {
    ErrorMessage,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      newCredit: {
        managementFeeOnly: false,
        dollars: null
      }
    };
  },

  methods: {
    save() {
      this.onSave(this.newCredit);
    }
  }
};
</script>
