<template>
  <div>
    <div class="titlebar with-button">
      <div class="row tight-columns">
        <div class="col-sm-8 col-md-9">
          <div class="medium-page-heading top-6">
            RFP Question Templates
          </div>
        </div>

        <div class="col-sm-4 col-md-3 top-20-xs">
          <a href="/scout-company/settings/question-templates/new" class="secondary-btn-blue">Create New Template</a>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <loading-section name="templatesIndex">
            <div class="bottom-30">
              <a href="/scout-company/settings" class="back-link"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to Admin Settings</a>
            </div>

            <div class="resource-list static-list">
              <div class="list-header">
                <div class="row tight-columns size-text-13px">
                  <div class="col-sm-4">
                    Name
                  </div>

                  <div class="col-sm-2 top-10-xs">
                    # Questions
                  </div>

                  <div class="col-sm-3 top-10-xs">
                    Created By
                  </div>

                  <div class="col-sm-2 top-10-xs">
                    Updated
                  </div>

                  <div class="col-sm-1 top-10-xs">
                  </div>
                </div>
              </div>

              <div v-for="template in rfpTemplates" :key="template.id" class="list-item size-text-13px">
                <div v-if="template.id === null" class="row tight-columns list-item-inner vertical-center-not-xs">
                  <div class="col-sm-4 text-ellipsis standard-question-wrapper semibold-weight">
                    <a href="/scout-company/settings/question-templates/standard">Standard Questions</a>

                    <color-tag color-pattern="blue padding-sm size-text-13px">
                      Default
                    </color-tag>
                  </div>

                  <div class="col-sm-2 top-10-xs">
                    {{ standardTemplate?.questionCount }}
                  </div>

                  <div class="col-sm-3 top-10-xs">
                    —
                  </div>

                  <div class="col-sm-2 top-10-xs">
                    —
                  </div>

                  <div class="col-sm-1 top-10-xs">
                    <dropdown-menu :compact="true">
                      <li class="menu-item" role="menuitem">
                        <a href="/scout-company/settings/question-templates/standard">View Template</a>
                      </li>

                      <li class="menu-item" role="menuitem">
                        <a href="" @click.prevent="confirmDuplicateTemplate(standardTemplate)">Duplicate Template</a>
                      </li>
                    </dropdown-menu>
                  </div>
                </div>

                <div v-else class="row tight-columns list-item-inner vertical-center-not-xs">
                  <div class="col-sm-4 text-ellipsis semibold-weight">
                    <a :href="`/scout-company/settings/question-templates/${template.id}`">{{ template.name }}</a>
                  </div>

                  <div class="col-sm-2 text-ellipsis top-10-xs">
                    {{ template.questionCount }}
                  </div>

                  <div class="col-sm-3 text-ellipsis top-10-xs">
                    {{ createdBy(template) }}
                  </div>

                  <div class="col-sm-2 text-ellipsis top-10-xs">
                    {{ formatDate(template.updatedAt) }}
                  </div>

                  <div class="col-sm-1 top-10-xs">
                    <dropdown-menu :compact="true">
                      <li class="menu-item" role="menuitem">
                        <a :href="`/scout-company/settings/question-templates/${template.id}`">View Template</a>
                      </li>

                      <li class="menu-item" role="menuitem">
                        <a href="" @click.prevent="confirmDuplicateTemplate(template)">Duplicate Template</a>
                      </li>

                      <li class="menu-item" role="menuitem">
                        <delete-template-modal
                          :template="template"
                          :on-delete-template="onDeleteTemplate">
                        </delete-template-modal>
                      </li>
                    </dropdown-menu>
                  </div>
                </div>
              </div>
            </div>

            <priori-pagination
              v-if="rfpTemplates.length"
              class="scout-pagination top-15"
              :current-page="page"
              :total-entries="totalCount"
              :per-page="perPage"
              @change="changePage">
            </priori-pagination>
          </loading-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import ColorTag from 'vue-app/shared/components/color-tag.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';
import DateFilter from 'vue-app/shared/lib/date-filter';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import DeleteTemplateModal from 'vue-app/scout/client/settings/rfqs/templates/delete-template-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import RFQTemplate from 'resources/scout/rfq-template.js';

export default {
  name: 'ScoutClientSettingsRfpTemplatesIndex',

  components: {
    DropdownMenu,
    ColorTag,
    LoadingSection,
    DeleteTemplateModal,
    SvgIcon,
    PrioriPagination
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      rfpTemplates: this.initData.templates || [],
      standardTemplate: this.initData.templates.find(template => template.id === null) || null,
      page: this.initData.currentPage || 1,
      totalCount: this.initData.totalCount,
      perPage: this.initData.perPage
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, {
      currentClient: 'currentUser'
    })
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      duplicateTemplate: 'duplicateTemplate'
    }),

    changePage(page) {
      if (this.page !== page && page >= 1) {
        this.page = page;
        this.loadTemplates();
      }
    },

    loadTemplates() {
      const params = { view: 'index', page: this.page };

      LoadingService.loading('templatesIndex');
      return RFQTemplate.query(params)
        .then((response) => this.rfpTemplates = response.templates)
        .finally(() => LoadingService.done('templatesIndex'));
    },

    confirmDuplicateTemplate(template) {
      this.duplicateTemplate(template).then((response) => {
        window.location.href = `/scout-company/settings/question-templates/${response.data.id}`;
      });
    },

    onDeleteTemplate(template) {
      this.rfpTemplates = this.rfpTemplates.filter(t => t.id !== template.id);
    },

    createdBy(template) {
      return this.creatorName(template);
    },

    creatorName(template) {
      if (this.createdByCurrentUser(template)) {
        return template.creator.name + ' (You)';
      }

      return template.creator.name;
    },

    createdByCurrentUser(template) {
      return this.currentClient.id === template.creator.id;
    },

    formatDate(updatedAt) {
      return DateFilter.filterDate(updatedAt);
    }
  }
};
</script>

<style lang="scss" scoped>
  .standard-question-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
</style>
