<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical text-center data-disclaimer">
      <div v-if="isFeatureLab" class="feature-lab-logo-container center-block bottom-40">
        <svg-icon name="feature-lab-logo" class="feature-lab-logo brand-icon top-5"></svg-icon>
      </div>

      <div v-else class="logo-container center-block bottom-40">
        <svg-icon name="scout-logo" class="brand-icon"></svg-icon>
      </div>

      <div v-if="isFeatureLab">
        <div class="semibold-weight bottom-15">
          This law firm has been added to your Feature Lab environment, but has not yet provided profile data.
        </div>

        <div>
          Your team can send RFPs and create reviews for this law firm. When this law firm adds profile data, it will be available here.
        </div>
      </div>

      <div v-else>
        <div class="bold-weight bottom-15">
          Some profile data may not yet be available
        </div>

        <div class="bottom-10">
          Some lawyer and law firm profiles are not yet populated or are missing certain data elements. Profile data may not be available because the law firm wasn’t invited to join Scout, hasn’t yet onboarded to Scout, or hasn’t yet provided data for a particular lawyer.
        </div>

        <div class="bottom-40">
          Please notify your Scout administrator if you encounter missing data on a lawyer or law firm profile and would like more information about that lawyer or law firm.
        </div>

        <div class="row">
          <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
            <data-disclaimer-modal :firm-name="firmName" :lawyer-name="lawyerName"></data-disclaimer-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataDisclaimerModal from './data-disclaimer-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'DataDisclaimerBox',

  components: {
    DataDisclaimerModal,
    SvgIcon
  },

  props: {
    firmName: {
      type: String,
      required: true
    },

    lawyerName: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .data-disclaimer {
    line-height: 1.3;

    @media(min-width: 768px) {
      padding: 50px 115px;
    }

    .feature-lab-logo {
      fill: $newblue-5;
    }

    .feature-lab-logo-container {
      max-width: 200px;
    }

    .logo-container {
      max-width: 120px;
    }
  }
</style>
