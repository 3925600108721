<template>
  <div class="shadowed-box" v-if="displayComponent">
    <div class="tab-wrapper">
      <div class="row tight-columns vertical-center-not-xs">
        <div class="col-xs-8 col-sm-4 text-uppercase tab-header bold-weight">
          Practice Areas
        </div>

        <div class="col-xs-4 col-sm-1 col-sm-push-7 tab-header text-right" v-if="canEditProfile">
          <loading-section name="all-firm-practice-areas" :render-after-loading="true">
            <edit-firm-practice-areas
              :firm="firm"
              :practice-areas="allPracticeAreas"
              :on-save="save">
            </edit-firm-practice-areas>
          </loading-section>
        </div>

        <div :class="['vertical-center flex-end-not-xs gap-5 top-10-xs', { 'col-sm-7 col-sm-pull-1': canEditProfile, 'col-xs-12 col-sm-8': !canEditProfile }]">
          <div :class="['tab-item', { 'selected-tab': lawyerSelected }]" @click.prevent="selectTab('lawyers')">All Lawyers</div>

          <div :class="['tab-item', { 'selected-tab': partnerSelected }]" @click.prevent="selectTab('partners')">Partners</div>

          <div v-if="tagDataPresent" :class="['tab-item blue-tab', { 'selected-tab': tagSelected }]" @click.prevent="selectTab('tagged')"><span class="tagged-tab blue-tab">Tagged Practice Areas</span></div>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="firm-practice-areas">
        <display-rules-section-wrapper :data="firm.practiceAreas" :editing-allowed="canEditProfile">
          <display-rules-data-wrapper :data="firm.practiceAreas">
            <div v-if="lawyerSelected || partnerSelected">
              <div class="bold-weight size-text-14px text-center bottom-15" v-if="!allPracticeAreasMissingCountData">
                Top Practice Areas
              </div>

              <div v-if="lawyerSelected">
                <bar-chart :chart-data="renderedLawyerData" :unit-symbol="barChartUnit"></bar-chart>
              </div>

              <div v-if="partnerSelected">
                <bar-chart :chart-data="renderedPartnerData" :unit-symbol="barChartUnit"></bar-chart>
              </div>

              <div class="top-40" v-if="shouldRenderFirmPracticeAreaTags">
                <firm-all-practice-area-tags
                  :practice-areas="sortedPracticeAreas"
                  :selected-group="selectedTab"
                  :lawyer-count-attribute="lawyerCountAttribute"
                  :partner-count-attribute="partnerCountAttribute"
                  :collapsable="allPracticeAreasMissingCountData">
                </firm-all-practice-area-tags>
              </div>
            </div>

            <div v-if="tagSelected">
              <collapsable-space :max-height="190">
                <grouped-tags :firm="firm" :group-by="'practice_area'"></grouped-tags>
              </collapsable-space>
            </div>

            <pseudo-link-button
              v-if="showDisplayToggle && !tagSelected"
              class="top-30"
              :text="showAllOrFewer"
              :on-click="toggleShowAll">
            </pseudo-link-button>
          </display-rules-data-wrapper>
        </display-rules-section-wrapper>
      </loading-section>
    </div>

    <div class="box-footer pill-gray-text" v-if="!allPracticeAreasMissingCountData && !tagSelected">
      <svg-icon name="info" class="base-icon smaller right-10"></svg-icon><span class="vertical-middle">Displays {{ selectedTab }} per practice area</span>
    </div>
  </div>
</template>

<script>
import { orderBy, some, sortBy, filter } from 'lodash';
import displayRules from 'vue-app/shared/mixins/display-rules';
import BarChart from 'vue-app/shared/components/bar-chart.vue';
import DisplayRulesSectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditFirmPracticeAreas from 'vue-app/scout/shared/law-firms/edit-firm-practice-areas.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import FirmAllPracticeAreaTags from 'vue-app/scout/shared/law-firms/firm-all-practice-area-tags.vue';
import PseudoLinkButton from 'vue-app/shared/components/pseudo-link-button.vue';
import ScoutFirmPracticeArea from 'resources/scout/scout-firm-practice-area';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import GroupedTags from 'vue-app/scout/shared/law-firms/grouped-tags.vue';

export default {
  name: 'FirmPracticeAreas',

  components: {
    BarChart,
    DisplayRulesSectionWrapper,
    DisplayRulesDataWrapper,
    EditFirmPracticeAreas,
    FirmAllPracticeAreaTags,
    PseudoLinkButton,
    SvgIcon,
    GroupedTags
  },

  mixins: [displayRules],

  props: {
    firm: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:    Function,
      default: () => {}
    },

    initialDisplayCount: {
      type:    Number,
      default: 7
    }
  },

  data() {
    return {
      selectedTab: 'lawyers',
      lawyerCountAttribute: null,
      partnerCountAttribute: null,
      dataType: null,
      selectedAttribute: null,
      allPracticeAreas: [],
      showAll: false
    };
  },

  computed: {
    lawyerSelected() {
      return this.selectedTab === 'lawyers';
    },

    partnerSelected() {
      return this.selectedTab === 'partners';
    },

    tagSelected() {
      return this.selectedTab === 'tagged';
    },

    countAttributeIsCount() {
      return this.lawyerCountAttribute === 'lawyerCount';
    },

    lawyerData() {
      return this.chartDataFor(this.lawyerCountAttribute);
    },

    partnerData() {
      return this.chartDataFor(this.partnerCountAttribute);
    },

    sortedLawyerPracticeAreas() {
      return orderBy(this.firm.practiceAreas, [this.lawyerCountAttribute, (p) => p.name.toLowerCase()], ['desc']);
    },

    sortedPartnerPracticeAreas() {
      return orderBy(this.firm.practiceAreas, [this.partnerCountAttribute, (p) => p.name.toLowerCase()], ['desc']);
    },

    sortedPracticeAreas() {
      return this.lawyerSelected ? this.sortedLawyerPracticeAreas : this.sortedPartnerPracticeAreas;
    },

    renderedLawyerData() {
      return this.lawyerData.slice(0, this.initialDisplayCount);
    },

    renderedPartnerData() {
      return this.partnerData.slice(0, this.initialDisplayCount);
    },

    barChartUnit() {
      return this.countAttributeIsCount ? null : '%';
    },

    showAllOrFewer() {
      return this.showAll ? 'Show less' : 'Show all';
    },

    showDisplayToggle() {
      if (this.lawyerSelected) {
        return (this.lawyerData.length > this.initialDisplayCount || this.anyPracticeAreaMissingCountData) && !this.allPracticeAreasMissingCountData && !this.renderCollapsableFirmTags;
      }
      else {
        return (this.partnerData.length > this.initialDisplayCount || this.anyPracticeAreaMissingCountData) && !this.allPracticeAreasMissingCountData && !this.renderCollapsableFirmTags;
      }
    },

    allPracticeAreasMissingCountData() {
      if (this.lawyerCountAttribute === null) {
        return true;
      }
      else {
        return !some(this.sortedPracticeAreas, this.selectedAttribute);
      }
    },

    anyPracticeAreaMissingCountData() {
      return this.allPracticeAreasMissingCountData || some(this.sortedPracticeAreas, [this.selectedAttribute, null]);
    },

    shouldRenderFirmPracticeAreaTags() {
      return this.showAll || this.allPracticeAreasMissingCountData;
    },

    displayComponent() {
      return this.anyDataIsPresent([this.firm.practiceAreas]) || this.canEditProfile;
    },

    tagDataPresent() {
      return filter(this.firm.scoutTags, tag => tag.tagType === 'practice_area').length > 0;
    }
  },

  created() {
    this.canEditProfile ? this.fetchPracticeAreas() : this.setCountAttribute(this.firm.practiceAreas);
  },

  methods: {
    fetchPracticeAreas() {
      LoadingService.loading('all-firm-practice-areas');
      ScoutFirmPracticeArea.query()
        .then(response => {
          this.allPracticeAreas = sortBy(response, (pa) => { return pa.name.toLowerCase(); });
          this.setCountAttribute(this.allPracticeAreas);
        })
        .finally(() => {
          LoadingService.done('all-firm-practice-areas');
        });
    },

    setCountAttribute(practiceAreas) {
      if (some(practiceAreas, 'lawyerCount') || some(practiceAreas, 'partnerCount')) {
        this.lawyerCountAttribute = 'lawyerCount';
        this.partnerCountAttribute = 'partnerCount';
      }
      else if (some(practiceAreas, 'lawyerPercent') || some(practiceAreas, 'partnerPercent')) {
        this.lawyerCountAttribute = 'lawyerPercent';
        this.partnerCountAttribute = 'partnerPercent';
      }

      this.selectedAttribute = this.lawyerCountAttribute;
    },

    selectTab(tab) {
      this.selectedTab = tab;
      this.updateSelectedAttribute();
      this.showAll = false;
    },

    updateSelectedAttribute() {
      this.selectedAttribute = this.lawyerSelected ? this.lawyerCountAttribute : this.partnerCountAttribute;
    },

    chartDataFor(attribute) {
      const colors = ['#02427C', '#015198', '#0069C7', '#3590E1', '#52A8F4', '#86C2F8', '#AAD4FA'];
      let data = [];
      let colorIndex = 0;
      this.sortedPracticeAreas.forEach(practiceArea => {
        if (!practiceArea[attribute]) { return; }

        data.push(
          {
            name: practiceArea.name,
            color: colors[colorIndex],
            value: practiceArea[attribute]
          }
        );
        colorIndex++;
      });

      return data;
    },

    save(data, successFunc) {
      this.onSave(data, this.fetchPracticeAreas);
      if (successFunc) { successFunc(); }
    },

    toggleShowAll() {
      this.showAll = !this.showAll;
    },

    updateCountAttributes(attribute) {
      this.lawyerCountAttribute = attribute === 'numerical' ? 'lawyerCount' : 'lawyerPercent';
      this.partnerCountAttribute = attribute === 'numerical' ? 'partnerCount' : 'partnerPercent';
      this.updateSelectedAttribute();
    }
  }
};
</script>
