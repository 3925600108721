<template>
  <div>
    <div class="titlebar-sm">
      <div class="medium-page-heading top-2">
        Incomplete Requests
      </div>
    </div>

    <div class="sidenav-content full-width">
      <div class="sidenav-content">
        <div class="container-fluid">
          <div class="padded-container size-text-13px">
            <div class="shadowed-box">
              <div class="box-content">
                <table class="table auto-height">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Requester Name</th>
                      <th>SF ID</th>
                      <th>Email</th>
                      <th>Drop-Off Stage</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="tracker in requestTrackers" :key="tracker.id">
                      <td>
                        {{ tracker.id }}
                      </td>

                      <td>
                        {{ formattedDate(tracker.createdAt) }}
                      </td>

                      <td>
                        <a :href="`/admin-app/clients/${tracker.requesterId}`" v-if="tracker.requesterKlass === 'Client'">{{ tracker.requesterName }}</a>
                        <span v-else>{{ tracker.requesterName }}</span>
                      </td>

                      <td>
                        <a :href="`https://priori.my.salesforce.com/${tracker.salesforceId}`" target="_blank" v-if="tracker.salesforceId">{{ tracker.salesforceId }}</a>
                        <span v-else>&mdash;</span>
                      </td>

                      <td>
                        {{ tracker.email }}
                      </td>

                      <td>
                        {{ tracker.lastClick }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="top-15">
              <priori-pagination
                :current-page="paginationService.currentPage"
                :total-entries="paginationService.totalEntries"
                :per-page="paginationService.perPage"
                @change="changePage">
              </priori-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import AdminPaginationService from 'vue-app/admin/shared/services/admin-pagination-service';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import RequestTracker from 'resources/marketplace/request-tracker';

export default {
  name: 'TrackersIndex',

  components: {
    PrioriPagination
  },

  data() {
    return {
      paginationService: new AdminPaginationService(RequestTracker, 'requestTrackers'),
      requestTrackers: []
    };
  },

  mounted() {
    this.paginationService.loadResources().then((response) => {
      this.requestTrackers = response;
    });
  },

  methods: {
    formattedDate(date) {
      return DateFilter.shortDate(date);
    },

    changePage(page) {
      this.paginationService.pageChanged(page).then((response) => {
        this.requestTrackers = response || [];
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .table {
    margin-bottom: 0;
  }
</style>
