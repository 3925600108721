<template>
  <edit-modal title="Editing Affiliations" :input="lawyer" :on-save="handleOnSave" @on-modal-open="openModal">
    <template #default>
      <div class="bottom-30">
        <div class="section-header">
          Board Experience
        </div>

        <div class="text-italic pill-gray-text size-text-12px bottom-15">
          Please list any board experience that you want to appear on your profile.
        </div>

        <field-group class="bottom-20" v-for="(experience, index) in boardExperience" :key="index" :on-remove-item="() => { removeItem('boardExperience', index) }" item-type="experience">
          <template #input>
            <div class="bold-weight bottom-5">
              Board Experience
            </div>

            <tiptap-editor v-model="experience.description" :buttons="['bulletList', 'italic', 'orderedList']"></tiptap-editor>
          </template>
        </field-group>

        <a href="" @click.prevent="addItem('boardExperience')" class="semibold-weight">{{ `+ Add ${boardExperience.length ? 'Another' : 'Experience'}` }}</a>
      </div>

      <div class="bottom-30">
        <div class="section-header">
          Pro Bono/Community Affiliations
        </div>

        <div class="text-italic pill-gray-text size-text-12px bottom-15">
          Please list any pro bono work or community affiliations that you want to appear on your profile.
        </div>

        <field-group class="bottom-20" v-for="(affiliation, index) in proBonoAffiliations" :key="index" :on-remove-item="() => { removeItem('proBonoAffiliations', index) }" item-type="affiliation">
          <template #input>
            <div class="bold-weight bottom-5">
              Pro Bono/Community Affiliation
            </div>

            <tiptap-editor v-model="affiliation.description" :buttons="['bulletList', 'italic', 'orderedList']"></tiptap-editor>
          </template>
        </field-group>

        <a href="" @click.prevent="addItem('proBonoAffiliations')" class="semibold-weight">{{ `+ Add ${proBonoAffiliations.length ? 'Another' : 'Affiliation'}` }}</a>
      </div>

      <div class="bottom-30">
        <div class="section-header">
          Industry Group Experience
        </div>

        <div class="text-italic pill-gray-text size-text-12px bottom-15">
          Please list any industry group experience that you want to appear on your profile.
        </div>

        <field-group class="bottom-20" v-for="(experience, index) in industryGroupExperience" :key="index" :on-remove-item="() => { removeItem('industryGroupExperience', index) }" item-type="experience">
          <template #input>
            <div class="bold-weight bottom-5">
              Industry Group Experience
            </div>

            <tiptap-editor v-model="experience.description" :buttons="['bulletList', 'italic', 'orderedList']"></tiptap-editor>
          </template>
        </field-group>

        <a href="" @click.prevent="addItem('industryGroupExperience')" class="semibold-weight">{{ `+ Add ${industryGroupExperience.length ? 'Another' : 'Experience'}` }}</a>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import FieldGroup from 'vue-app/scout/shared/field-group.vue';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import DataLock from 'resources/data-lock.js';
import { map, uniqueId } from 'lodash';

export default {
  name: 'EditLawyerAffiliations',

  components: {
    EditModal,
    FieldGroup,
    TiptapEditor
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    onSave: {
      type:     Function,
      default:  () => {}
    }
  },

  data() {
    return {
      boardExperience: [...this.lawyer.sanitizedBoardExperience],
      proBonoAffiliations: [...this.lawyer.sanitizedProBonoAffiliations],
      industryGroupExperience: [...this.lawyer.sanitizedIndustryGroupExperience]
    };
  },

  methods: {
    openModal() {
      this.resetForm();
    },

    handleOnSave() {
      const editedBoardExperience =  map(this.boardExperience, 'description');
      const editedProBonoAffiliations =  map(this.proBonoAffiliations, 'description');
      const editedIndustryGroupExperience =  map(this.industryGroupExperience, 'description');

      const lawyerAttributes = {
        lawyerProfileAttributes: {
          id: this.lawyer.lawyerProfileId,
          unsanitizedBoardExperience: editedBoardExperience,
          unsanitizedProBonoAffiliations: editedProBonoAffiliations,
          industryGroupExperience: editedIndustryGroupExperience
        }
      };

      this.onSave(lawyerAttributes, () => {
        DataLock.save({
          dataLock: {
            flag: ['board_member_experience', 'pro_bono_affiliations', 'industry_group_experience'],
            resourceId: this.lawyer.id,
            resourceType: 'lawyer'
          }
        });
      });
    },

    resetForm() {
      this.boardExperience = this.getExperiences(this.lawyer.sanitizedBoardExperience, this.lawyer.sanitizedBoardExperience.length);
      this.proBonoAffiliations = this.getExperiences(this.lawyer.sanitizedProBonoAffiliations, this.lawyer.sanitizedProBonoAffiliations.length);
      this.industryGroupExperience = this.getExperiences(this.lawyer.sanitizedIndustryGroupExperience, this.lawyer.sanitizedIndustryGroupExperience.length);
    },

    getExperiences(experiences, isPresent) {
      if (!isPresent) { return [{ description: '' }]; }

      return experiences.map(experience => ({ id: uniqueId(), description: experience }));
    },

    addItem(item) {
      this[item].push({ description: '' });
    },

    removeItem(item, index) {
      this[item].splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .section-header {
    border-bottom: 1px solid $k-light-gray;
    font-weight: 700;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
</style>
