<template>
  <priori-modal ref="modal" modal-id="success-modal">
    <template #title>
      <div class="text-center bottom-20">
        <div class="rating-indicator circle">
          <svg-icon name="checkmark-no-circle" class="base-icon checkmark"></svg-icon>
        </div>
      </div>

      Request Submitted Successfully
    </template>

    You will receive a follow-up email shortly with more information.
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RequestSubmittedSuccessModal',

  components: {
    PrioriModal,
    SvgIcon
  },

  methods: {
    openModal() {
      this.$refs.modal.openModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .checkmark {
    margin-top: 16px;
    width: 20px;
    height: 20px;
    fill: $white;
  }

  .circle {
    height: 50px;
    width: 50px;
  }
</style>
