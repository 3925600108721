<template>
  <div class="shadowed-box clearfix">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-xs-6 text-uppercase">
          Law Firm
        </div>

        <div class="col-xs-6 normal-weight size-text-13px text-right">
          <a :href="`/admin-app/law-firms/${lawFirm.id}`" target="_blank">View Firm</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="row bottom-30">
        <div class="col-xs-8 col-xs-offset-2 col-sm-3 col-sm-offset-0 logo-container" v-if="firmLogo">
          <div class="vertical-center">
            <img :src="firmLogo" alt="Firm Logo" class="img-responsive">
          </div>
        </div>

        <div :class="['col-xs-12', { 'col-sm-9 logo-container': firmLogo }]">
          <div :class="basicInfoContainerClasses">
            <div class="size-text-base semibold-weight">
              {{ lawFirm.name }}
            </div>

            <div class="dark-gray-text">
              {{ lawFirm.firmSize }}
            </div>
          </div>
        </div>
      </div>

      <div class="tiny-header-text bottom-10">
        About Firm
      </div>

      <div>
        <div class="list-two-levels" v-html="lawFirm.sanitizedDescription || '&mdash;'"></div>
      </div>

      <hr class="kenny-hr">

      <div class="tiny-header-text bottom-10">
        Office Locations
      </div>

      <div v-for="location in locations" class="list-element" :key="location.id">
        {{ locationText(location) }}
      </div>

      <div class="missing-text" v-if="!(locations && locations.length)">
        None Added
      </div>

      <hr class="kenny-hr">

      <loading-section name="firmUsers">
        <div class="tiny-header-text bottom-10">
          Firm Users
        </div>

        <div class="row">
          <div class="col-sm-6 list-element" v-for="user in firmUsers" :key="user.id">
            <a :href="`/admin-app/lawyers/${user.id}`" target="_blank">{{ user.fullName }}</a>
          </div>
        </div>

        <div class="missing-text" v-if="!(firmUsers && firmUsers.length)">
          None Added
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import SharedLists from 'vue-app/shared/services/shared-lists-service.js';

export default {
  name: 'AccountFirm',

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    firmUsers: {
      type: Array,
      required: true
    }
  },

  computed: {
    locations() {
      return this.lawFirm.lawFirmLocationsAttributes;
    },

    firmSizePresent() {
      return this.lawFirm.firmSize?.length;
    },

    firmLogo() {
      return this.lawFirm.logo;
    },

    basicInfoContainerClasses() {
      return {
        'left-15-not-xs': this.firmLogo,
        'top-18': this.firmSizePresent && this.firmLogo,
        'top-25': !this.firmSizePresent && this.firmLogo
      };
    }
  },

  methods: {
    locationText: function (location) {
      let locationText = `${location.city}, `;

      if (location.country === 'United States') {
        locationText += SharedLists.stateAbbreviationFromName(location.state);
      }
      else {
        locationText += location.country;
      }

      return locationText;
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-element:not(:last-child) {
    margin-bottom: 5px;
  }
</style>
