<template>
  <div class="a-form filter-popover">
    <div class="text-right bottom-15">
      <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
        Clear Filter
      </button>
    </div>

    <div class="keyword-search-container">
      <input
        type="text"
        name="keyword"
        id="keyword-search"
        class="form-control keyword-search-input"
        placeholder="Search..."
        autocomplete="off"
        v-model="keywordModel"
        @blur="addKeyword"
        @keyup.enter="addKeyword">

      <svg-icon name="search" class="base-icon keyword-search-icon"></svg-icon>
    </div>

    <div class="tag-list set-filters-container top-15" v-if="selectedOptions.length">
      <div class="tag-list-item compact blue" v-for="(keyword, index) in selectedOptions" :key="index">
        <span class="right-5">{{ keyword }}</span>

        <a href="" class="tag-list-item-delete" @click.prevent.stop="removeKeyword(keyword)"><svg-icon name="x3" class="base-icon smallest"></svg-icon></a>
      </div>
    </div>

    <div class="missing-text top-15" v-if="!selectedOptions.length">
      Type to add keywords.
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import SearchFilter from 'vue-app/shared/mixins/search-filter.js';

export default {
  name: 'FilterKeyword',

  components: {
    SvgIcon
  },

  mixins: [
    SearchFilter
  ],

  props: {
    searchService: {
      type: Object,
      required: true
    },

    filterParams: {
      type: Object,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      keywordModel: ''
    };
  },

  computed: {
    selectedOptions() {
      return this.searchService.currentSelections(this.field, this.filterContext);
    }
  },

  methods: {
    addKeyword() {
      if (this.keywordModel) {
        this.searchService.addSearchTerms(this.field, this.keywordModel, this.filterContext);
        this.keywordModel = '';

        this.onSearch();
      }
    },

    removeKeyword(keyword) {
      this.searchService.removeSearchTerm(this.field, keyword, this.filterContext);

      this.onSearch();
    },

    clearFilter() {
      this.searchService.unsetFilter(this.field);
      this.onSearch();
    }
  }
};
</script>
